// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import React from 'react'

import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import { BACKGROUND_COLORS, sortObjectByIntValue } from './utils'

function getTopCareIssuesPercentages(topCareIssues) {
  const chartData = {
    labels: [],
    dataPercentages: [],
    topCareIssues: [],
  }

  const sortedIssues = sortObjectByIntValue(topCareIssues)
  sortedIssues.forEach((row) => {
    const [issue, count] = row

    let label = issue
    if (issue === '') {
      label = 'Maps Issue'
    }
    chartData.labels.push(label)
    chartData.dataPercentages.push(count)
    chartData.topCareIssues.push({
      count,
      name: label,
    })
  })

  return {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Group Level Care Issue Types',
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: '#dee2e6',
        data: chartData.dataPercentages,
        backgroundColor: BACKGROUND_COLORS.slice(0, topCareIssues.length),
      },
    ],
    topCareIssues: chartData.topCareIssues,
  }
}

export default function TopCareIssuesPercentage({ topCareIssues, isDateRangeSelected, totalCareIssues }) {
  const careIssuesPercentages = getTopCareIssuesPercentages(topCareIssues)
  const chartData = _.pick(careIssuesPercentages, ['labels', 'datasets'])

  const title = `${'TOP CARE ISSUES'} ${!isDateRangeSelected ? ' - Last 30 Days' : ''}`
  return (
    <DoughnutWithPercentage
      title={title}
      totalLabel="Total"
      totalRecords={totalCareIssues}
      sortedChartData={chartData}
      sortedRawData={careIssuesPercentages.topCareIssues}
    />
  )
}
