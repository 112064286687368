import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { arraySort, strings, telemetry } from 'assets/utils';

// reactstrap components
import {
    Input, Button,
    Card, CardHeader, CardBody, CardTitle, CardFooter,
    Row, Col,
    Popover, PopoverHeader, PopoverBody
  } from "reactstrap";

import Parser from 'html-react-parser';

import ActivityCard from "./ActivityCard.js";
//import PersonCard from "components/PersonCard/PersonCard.js";
import ProjectCard from "components/ProjectCard/ProjectCard.jsx";
import QLSResults from "components/QLS/QLS.js";
import TeamCard from "components/TeamCard/TeamCard.js";
import TextEditor from "components/TextEditor/TextEditor.js";

import logo from "assets/img/goConnectIcon.png";

const uuid = require('uuid');

class Employee extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
          isLoading: true,
          employeeID: this.props.employeeID,
          managerID: this.props.user.personID, //'26565b9a-fa7a-4f10-aee6-2d9edc7929a5',// //
          manager: {},
          activeTab: 'all',
          activity: [],
          filtered: [],
          cycleFilter: '22h1',
          typeFilter: 'all',
          person: {},
          projects: [],
          teams: [],
          careerPlan: {},
          managerNotes: {},
          editStrengths: false
      }

      console.log(this.props)
    }

    componentDidMount() {

        db.getPerson(this.props.employeeID).then(person => {
            
            this.setState({person: person, isLoading: false});

            db.getManagerNotes(person.id, this.props.user.person.id).then(managerNotes => {
                if(managerNotes) 
                    this.setState({managerNotes: managerNotes}); // eventually want to have history
                else 
                    this.setState({managerNotes: { id: uuid.v4(), personID: person.id, managerID: this.props.user.person.id }});
                
            })

            db.getCareer(person.id).then(careerPlan => { 
                if(careerPlan)
                    this.setState({careerPlan: careerPlan});
            });
    

            db.getPeopleActivityByPersonID(person.id).then(activity => {
                
                var people = [];
                activity.forEach(row => {
    
                    var isFound = false;
                    for(var x = 0; x < people.length; x++) {
                        if(people[x] === row.personID) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound) 
                        people.push(row.personID);
    
                    isFound = false;
                    for(var y = 0; y < people.length; y++) {
                        if(people[y] === row.authorID) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound) 
                        people.push(row.authorID);
                })
    
                var promises = [];
                people.forEach(personID => { promises.push(db.getPerson(personID)) })
    
                Promise.all(promises).then(people => {
    
                    activity.forEach(row => {

                        if(row.type === 'learning') {
                            
                        }

                        for(var i = 0; i < people.length; i++) {
                            if(row.personID === people[i].id) 
                                row.person = people[i];
                                
                            if(row.managerID === people[i].id)
                                row.manager = people[i];
    
                            if(row.authorID === people[i].id)
                                row.author = people[i];
                        }
                    });
                    activity.sort((a,b) => { return b.created - a.created });
    
                    this.setState({activity: activity},this.filterActivity);
                });                    
            });
        })

        db.getTeamsByPerson(this.props.employeeID).then((teamMembers) => {
                
            var promises = [];
            for(var i = 0; i < teamMembers.length; i++) 
                promises.push(db.getTeam(teamMembers[i].teamID));
            
            Promise.all(promises).then(teams => {

                for(var k = teams.length -1; k > -1; k--)
                    if(!teams[k])
                        teams.splice(k);

                teams.sort(arraySort.byName);
                this.setState({teams: teams});
            })
        });

        db.getProjectsByPerson(this.props.employeeID).then((projectMembers) => {
            var promises = [];
            for(var i = 0; i < projectMembers.length; i++) 
                promises.push(db.getProject(projectMembers[i].projectID));
            
            Promise.all(promises).then(projects => {
                projects.sort(arraySort.byName);
                this.setState({projects: projects});
            })
        });

        
    }

    addActivity(newActivity) {

        var activity = this.state.activity;

        activity.push(newActivity);

        activity.sort((a,b) => { return b.created - a.created });

        this.setState({activity: activity}, this.filterActivity);
    }

    filterActivity(filter, value) {

        var filtered = [];

        var cycleFilter = this.state.cycleFilter;
        if(filter === 'cycle')
            cycleFilter = value;

        var typeFilter = this.state.typeFilter;
        if(filter === 'type')
            typeFilter = value;

        this.setState({cycleFilter: cycleFilter, typeFilter: typeFilter});


        this.state.activity.forEach(row => {
            if(row.cycle === cycleFilter)
                if(row.type === typeFilter || typeFilter === 'all')
                    filtered.push(row);
               
        })

        this.setState({filtered: filtered, isLoading: false});
    }


    updateManagerNotes(property, value) {
        var obj = this.state.managerNotes;
        obj[property] = value;
        this.setState({managerNotes: obj});
    }

    showLinkedIn() {
        if(this.state.person.linkedIn)
            return "inline-block";
        else
            return "none";
    }

    showInstagram() {
        if(this.state.person.instagram)
            return "inline-block";
        else
            return "none";
    }

    showTwitter() {
        if(this.state.person.twitter)
            return "inline-block";
        else
            return "none";
    }

    showGithub() {
        if(this.state.person.github)
            return "inline-block";
        else
            return "none";
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    showStrengths() {
        if(!this.state.editStrengths)
            return "block";
        else 
            return "none";
    }
    showStrengthsEditor() {
        if(this.state.editStrengths)
            return "block";
        else 
            return "none";
    }
    showWeaknesses() {
        if(!this.state.editWeaknesses)
            return "block";
        else 
            return "none";
    }
    showWeaknessesEditor() {
        if(this.state.editWeaknesses)
            return "block";
        else 
            return "none";
    }
    getHTML(value) {
        if(value)
            return Parser(value);
        else 
            return "";
    }

    onTextEditorBlur(property) {

        if(property === 'strengths')
            this.setState({editStrengths: false});

        if(property === 'weaknesses')
            this.setState({editWeaknesses: false});

        db.updateManagerNotes(this.state.managerNotes.id, this.state.managerNotes).then(() => {
            
        });
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (
            <div style={{padding:"10px"}}>
               
               <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                <Row>
                    <Col xs="12">
                        <div style={loadingDiv}>
                            <div className="spin">
                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{display:this.showResults()}}>
                    <Row>
                        <Col xs="12" sm="12" md="8">
                            <Card style={{padding:"20px"}}>
                                <CardHeader>
                                    <Row>
                                        <Col xs="2">
                                            <div style={{height:"123px", width:"100%", backgroundSize: "cover", backgroundColor:"#aaaaaa", borderRadius:"3px", padding:"0px",
                                                backgroundImage:`url(${this.state.person.profilePhoto})`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}
                                                title={this.state.person.name} >
                                            </div>
                                        </Col>
                                        <Col xs="10">
                                            <CardTitle tag="h4">{this.state.person.name}</CardTitle>
                                            <div style={{color:"green", marginTop:"-10px"}}>
                                                {this.state.person.title}
                                            </div>
                                            <Row style={{marginTop:"10px"}}>
                                                <Col xs="12" style={{fontSize:"19px", marginBottom:"17px", }}>
                                                    <div style={{marginLeft:"10px", cursor:"pointer", display:this.showLinkedIn()}} onClick={() => window.open(this.state.person.linkedIn)}>
                                                        <i className="socialIcon fab fa-linkedin"></i>
                                                    </div>
                                                    <div style={{marginLeft:"10px", cursor:"pointer", display:this.showInstagram()}} onClick={() => window.open(this.state.person.instagram)}>
                                                        <i className="socialIcon fab fa-instagram"></i>
                                                    </div>
                                                    <div style={{marginLeft:"10px", cursor:"pointer", display:this.showTwitter()}} onClick={() => window.open(this.state.person.twitter)}>
                                                        <i className="socialIcon fab fa-twitter"></i>
                                                    </div>
                                                    <div style={{marginLeft:"10px", cursor:"pointer", display:this.showGithub()}} onClick={() => window.open(this.state.person.github)}>
                                                        <i className="socialIcon fab fa-github"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    
                                </CardHeader>
                                <CardBody>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12">
                                            <h6 style={{color:"orange"}}>Manager Notes</h6>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Strengths</h6>
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"10px", color:"#aaa", cursor:"pointer"}} onClick={() => this.setState({editStrengths: true})}>
                                                <i className="fas fa-edit"></i>
                                            </div>
                                            <br />
                                            <div style={{display:this.showStrengths()}}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.managerNotes.strengths)}*/}
                                            </div>
                                            <div style={{display:this.showStrengthsEditor(), minHeight:"200px"}}>
                                                <TextEditor content={this.state.managerNotes.strengths} onChange={(e) => this.updateManagerNotes("strengths", e)} onBlur={() => this.onTextEditorBlur('strengths') }  />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Weaknesses</h6>
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"10px", color:"#aaa", cursor:"pointer"}} onClick={() => this.setState({editWeaknesses: true})}>
                                                <i className="fas fa-edit"></i>
                                            </div>
                                            <br />
                                            <div style={{display:this.showWeaknesses()}}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.managerNotes.weaknesses)}*/}
                                            </div>
                                            <div style={{display:this.showWeaknessesEditor(), minHeight:"200px"}}>
                                                <TextEditor content={this.state.managerNotes.weaknesses} onChange={(e) => this.updateManagerNotes("weaknesses", e)} onBlur={() => this.onTextEditorBlur('weaknesses') }  />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12">
                                            <h6 style={{color:"orange"}}>Employee Notes</h6>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Strengths</h6>
                                            </div>
                                            <br />
                                            <div>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.careerPlan.strengths)}*/}
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Weaknesses</h6>
                                            </div>
                                            <br />
                                            <div>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.careerPlan.weaknesses)}*/}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Goals</h6>
                                            </div>
                                            <br />
                                            <div>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.careerPlan.goals)}*/}
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <div style={{display:"inline-block"}}>
                                                <h6>Focus Areas</h6>
                                            </div>
                                            <br />
                                            <div>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui. Duis luctus ligula et felis sollicitudin, in dictum eros viverra. Maecenas dapibus dolor nec ex sodales pretium. Donec interdum lectus sit amet purus tempus euismod. 
                                                {/*{this.getHTML(this.state.careerPlan.focusAreas)}*/}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <br />

                            <Row>
                                <Col xs="12">
                                    <QLSResults managerID="26565b9a-fa7a-4f10-aee6-2d9edc7929a5" />
                                </Col>
                            </Row>


                            <Card style={{padding:"20px"}}>
                                <CardBody>
                                    <Row style={{marginTop:"10px"}}>
                                        <Col xs="12">
                                            <h5>Activity</h5>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom:"20px"}}>
                                        <Col xs="5">
                                            Cycle
                                            <Input placeholder="" 
                                                type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                                value={this.state.cycleFilter} onChange={(e) => this.filterActivity("cycle", e.target.value)} >
                                                    <option value="21h1">2021 H1</option>
                                                    <option value="21h2">2021 H2</option>
                                                    <option value="22h1">2022 H1</option>
                                            </Input>
                                        </Col>
                                        <Col xs="5">
                                            Type
                                            <Input placeholder="" 
                                                type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                                value={this.state.typeFilter} onChange={(e) => this.filterActivity("type", e.target.value)} >
                                                    <option value="all">All</option>
                                                    <option value="achievement">Achievements</option>
                                                    <option value="feedback">Feedback</option>
                                                    <option value="checkin">Checkins</option>
                                                    <option value="note">Notes</option>
                                            </Input>
                                        </Col>
                                        <Col xs="2" style={{textAlign:"right", paddingTop:"7px"}}>
                                            <ActivityCard onCreated={(a) => this.addActivity(a)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <div style={{marginTop:"20px"}}> {/**maxHeight:"432px", overflow:"auto" */}
                                                {this.state.filtered.map((activity, index) => (
                                                    <ActivityCard key={index} activity={activity} />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            &nbsp;
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="4">
                            
                            <Row style={{display:"none"}}>
                                <Col>
                                    <Card className="card-stats" >
                                        <CardBody>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big icon-warning" style={{color:"red"}}>
                                                        <i class="fas fa-comments"></i>
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">
                                                        <p className="card-category">Communication</p>
                                                            <CardTitle tag="p">0</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-handshake"></i>
                                                </Col>
                                                <Col xs="6">
                                                    <div className="stats">
                                                        1:1's 
                                                    </div>
                                                </Col>
                                                <Col xs="5" style={{textAlign:"right"}}>
                                                    Weekly
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-handshake"></i>
                                                </Col>
                                                <Col xs="6">
                                                    <div className="stats">
                                                        Check-ins
                                                    </div>
                                                </Col>
                                                <Col xs="5" style={{textAlign:"right"}}>
                                                    Bi-monthly
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-handshake"></i>
                                                </Col>
                                                <Col xs="6">
                                                    <div className="stats">
                                                        Career Discussions
                                                    </div>
                                                </Col>
                                                <Col xs="5" style={{textAlign:"right"}}>
                                                    Quarterly
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <Card className="card-stats" >
                                        <CardBody>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big icon-warning" style={{color:"orange"}}>
                                                        <i className="fas fa-layer-group" />
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">
                                                        <p className="card-category">Level</p>
                                                            <CardTitle tag="p">10</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <Row>
                                                <Col xs="1">
                                                    <i className="fas fa-tachometer-alt" />
                                                </Col>
                                                <Col xs="8">
                                                    <div className="stats">
                                                        Promotion Velocity
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div style={{textAlign:"right"}}>
                                                        1.0
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i className="fas fa-calendar" />
                                                </Col>
                                                <Col xs="8">
                                                    <div className="stats">
                                                        Months in Level
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div style={{textAlign:"right"}}>
                                                        14
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card className="card-stats" >
                                        <CardBody>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big icon-warning" style={{color:"green"}}>
                                                        <i className="fas fa-money-bill-wave-alt"></i>
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">
                                                        <p className="card-category">Comp Ratio</p>
                                                            <CardTitle tag="p">1.1</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <Row>
                                                <Col xs="1">
                                                    <i className="fas fa-calendar" />
                                                </Col>
                                                <Col xs="6">
                                                    <div className="stats">
                                                        Last Increment
                                                    </div>
                                                </Col>
                                                <Col xs="5">
                                                    <div style={{textAlign:"right"}}>
                                                        6% <span style={{fontSize:"11px", color:"#232323"}}>on 1/6/2022</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i className="fas fa-calendar" />
                                                </Col>
                                                <Col xs="6">
                                                    <div className="stats">
                                                        Last Bonus
                                                    </div>
                                                </Col>
                                                <Col xs="5">
                                                    <div style={{textAlign:"right"}}>
                                                        20% <span style={{fontSize:"11px", color:"#232323"}}> on 1/1/2022</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card className="card-stats" >
                                        <CardBody>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big icon-warning" style={{color:"red"}}>
                                                        <i class="fas fa-heartbeat"></i>
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">
                                                        <p className="card-category">Workgroup Health</p>
                                                            <CardTitle tag="p">1.1</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-user-friends"></i>
                                                </Col>
                                                <Col xs="9">
                                                    <div className="stats">
                                                        Direct Reports
                                                    </div>
                                                </Col>
                                                <Col xs="2" style={{textAlign:"right"}}>
                                                    11
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-users"></i>
                                                </Col>
                                                <Col xs="9">
                                                    <div className="stats">
                                                        Total Org Size
                                                    </div>
                                                </Col>
                                                <Col xs="2" style={{textAlign:"right"}}>
                                                    21
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-heartbeat"></i>
                                                </Col>
                                                <Col xs="9">
                                                    <div className="stats">
                                                        Org Health
                                                    </div>
                                                </Col>
                                                <Col xs="2" style={{textAlign:"right"}}>
                                                    1.0
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Card className="card-stats" >
                                        <CardBody>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big icon-warning" style={{color:"navy"}}>
                                                        <i class="fas fa-graduation-cap"></i>
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">
                                                        <p className="card-category">Hours This Month</p>
                                                            <CardTitle tag="p">8</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <Row>
                                                <Col xs="1">
                                                    <i class="fas fa-clock"></i>
                                                </Col>
                                                <Col xs="9">
                                                    <div className="stats">
                                                        Average Per Month
                                                    </div>
                                                </Col>
                                                <Col xs="2" style={{textAlign:"right"}}>
                                                    11
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12">
                                    <Card style={{padding:"20px"}}>
                                        <CardHeader>
                                            <h5>Work Scope</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row style={{marginTop:"-5px"}}>
                                                <Col xs="12">
                                                    <h6>Teams</h6>
                                                    {this.state.teams.map((team, index) => (
                                                        <Row key={index} style={{marginTop:"5px"}}>
                                                            <Col xs="3" sm="2">
                                                                <TeamCard team={team} showLargeThumb />
                                                            </Col>
                                                            <Col xs="9" sm="10" >
                                                                <div style={{marginLeft:"10px", fontWeight:"600"}}>
                                                                    {strings.trim(team.name,23)}
                                                                </div>
                                                                <div style={{marginLeft:"10px", fontSize:"11px"}}>
                                                                    {strings.trim(team.description,76)}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    
                                                    ))}
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:"15px"}}>
                                                <Col xs="12">
                                                    <h6>Projects</h6>
                                                    {this.state.projects.map((project, index) => (
                                                    <div key={index} style={{display:"inline-block", marginLeft:"2px", marginRight:"2px"}}>
                                                        <ProjectCard project={project} showThumb />
                                                    </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            
                            <Row style={{display:"none"}}>
                                <Col xs="12">
                                    <Card style={{padding:"20px"}}>
                                        <CardHeader>
                                            <h5>Checkins</h5>
                                        </CardHeader>
                                        <CardBody>
                                            
                                            <Row>
                                                <Col xs="6">
                                                    1/11/2021
                                                    <div style={{fontSize:"10px", color:"green"}}>DATE</div>
                                                </Col>
                                                <Col xs="6">
                                                    4
                                                    <div style={{fontSize:"10px", color:"green"}}>TRACKING TO</div>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:"10px"}}>
                                                <Col xs="12">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui...
                                                </Col>
                                            </Row>

                                            <Row style={{marginTop:"24px"}}>
                                                <Col xs="6">
                                                    4/8/2021
                                                    <div style={{fontSize:"10px", color:"green"}}>DATE</div>
                                                </Col>
                                                <Col xs="6">
                                                    4
                                                    <div style={{fontSize:"10px", color:"green"}}>TRACKING TO</div>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:"10px"}}>
                                                <Col xs="12">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui...
                                                </Col>
                                            </Row>

                                            <Row style={{marginTop:"24px"}}>
                                                <Col xs="6">
                                                    4/5/2021
                                                    <div style={{fontSize:"10px", color:"green"}}>DATE</div>
                                                </Col>
                                                <Col xs="6">
                                                    3
                                                    <div style={{fontSize:"10px", color:"green"}}>TRACKING TO</div>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:"10px"}}>
                                                <Col xs="12">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et nunc fermentum, volutpat eros quis, imperdiet dui...
                                                </Col>
                                            </Row>
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            

                            <Row>
                                <Col xs="12">
                                    <Card style={{padding:"20px"}}>
                                        <CardHeader>
                                            <h5>Role History</h5>
                                        </CardHeader>
                                        <CardBody>
                                            
                                            No former roles @ Gojek.
                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            

                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Employee = connect(mapStateToProps)(Employee);
export default Employee;