import React from "react";

import { connect } from 'react-redux';
import { slack } from 'assets/utils';

import { db } from 'assets/firebase';
import Lottie from 'react-lottie';
import PersonSearch from "components/PersonSearch/PersonSearch.js"
import categoryList from "variables/valuesCategory.js";

// reactstrap components
import {
    Button,
    Card, CardTitle, CardBody,
    Row, Col, Spinner,
    Modal, ModalBody, Input
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard";

const uuid = require('uuid');

class Appreciate extends React.Component {
    constructor() {

        super();
        this.state =
        {
            openPersonSearch: false,
            isCommentModalOpen: false,
            isNotificationModalOpen: false,
            userFromCard: [],
            // openPeopleSearch:false
            Appreciation: [{ appreciatorID: '', recipientID: '', appreciationCategory: '', comment: '' }],

        }

        this.getNotifyText = this.getNotifyText.bind(this);
        this.openPeopleSearch = this.openPeopleSearch.bind(this);
        this.onPersonChosen = this.onPersonChosen.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.onCommentSubmit = this.onCommentSubmit.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.showRecipient = this.showRecipient.bind(this);
        this.changePeopleSearch = this.changePeopleSearch.bind(this);
    }

    componentDidMount() {
        document.getElementsByClassName("wrapper")[0].scrollTo(0, 0);
        this.setState({
            userFromCard: this.props.userFromCard,
            user: this.props.userFromCard
        })
    }

    toggleModal() {
        this.setState({ isCommentModalOpen: !this.state.isCommentModalOpen });
    }

    toggleNotificationModal() {
        this.setState({ isNotificationModalOpen: !this.state.isNotificationModalOpen });
    }

    onPersonChosen(person) {
        this.setState({ openPersonSearch: false, isCommentModalOpen: true, user: person });
    }

    openPeopleSearch(categoryObject) {

        if (!this.state.user)

            return () => {

                this.setState({ openPersonSearch: true, selectedCategory: categoryObject }, () => {
                    setTimeout(() => {
                        this.setState({ openPersonSearch: false });
                    }, 500);
                });
            }
        else

            return () => {
                this.setState({ isCommentModalOpen: true, openPersonSearch: false, selectedCategory: categoryObject }
                );
            }

    }

    changePeopleSearch() {

        this.setState({ user: null,userFromCard: null , isCommentModalOpen: false });
    }

    handleCommentChange(e) {
        this.setState({
            comment: e.target.value
        })
    }

    getNotifyText() {

        if (!this.state.comment) {
            return "Please tell us why you're sending a Kudo!";
        }

        if (this.props.user && this.state.user) {

            if (this.props.user.person.id === this.state.user.id) {
                return "You cannot send a kudo to yourself!";
            }
            else {

                return ("Thanks for sending Kudos! We have notified " + this.state.user.name + ".");
            }
        }
    }

    async onCommentSubmit(e) {

        this.setState({
            isCommentModalOpen: false,
            isNotificationModalOpen: true
        });

        var appreciateObj = {
            id: uuid.v4(),
            appreciatorID: this.props.user.person.id,
            recipientID: this.state.user.id,
            comment: this.state.comment,
            category: this.state.selectedCategory.id,
            createdOn: new Date().getTime()
        };

        if (appreciateObj.recipientID === appreciateObj.appreciatorID) {

            setTimeout(() => {
                this.setState({ isNotificationModalOpen: false });
            }, 1800);
        }
        else {

            if (this.state.comment) {

                // write to db
                db.updateAppreciation(appreciateObj.id, appreciateObj);

                // notify recipient
                var text = "Congratulations, " + this.props.user.person.name + " sent you a Kudo for '" + this.state.selectedCategory.title + "'.\n - " + this.state.comment;

                var blocks = [
                    {
                        "type": "section",
                        "text": {
                            "type": "mrkdwn",
                            "text": text + "\n\nCheck your profile on <https://www.gocnnct.com/kudos" + "|GoConnect Kudos>."
                        }
                    },
                ]
                slack.sendMessage(this.state.user.slackID, text, blocks);

                // log the alert
                var alert = {
                    sender: this.props.user.person.email,
                    created: new Date().getTime(),
                    personID: this.state.user.id,
                    personName: this.state.user.name,
                    slackID: this.state.user.slackID,
                    text: text,
                    type: 'kudos',
                    blocks: blocks
                }
                db.updateAlert(alert.id, alert);

                // notify manager
                await db.getPersonForKudos(this.state.user.managerID).then(manager => {

                    var managerSlackText = "Your employee " + this.state.user.firstName + " " + this.state.user.lastName + " has received a Kudo from " + this.props.user.person.name + " for '" + this.state.selectedCategory.title + "'.\n - " + this.state.comment;

                    var managerSlackBlocks = [
                        {
                            "type": "section",
                            "text": {
                                "type": "mrkdwn",
                                "text": managerSlackText + "\n\nCheck their profile on <https://www.gocnnct.com/kudos" + "|GoConnect Kudos>."
                            }
                        },
                    ]

                    slack.sendMessage(manager.slackID, managerSlackText, managerSlackBlocks);

                    // log the alert
                    var managerAlert = {
                        sender: this.props.user.person.email,
                        created: new Date().getTime(),
                        personID: manager.id,
                        personName: manager.name,
                        slackID: manager.slackID,
                        text: managerSlackText,
                        type: 'kudos',
                        blocks: managerSlackBlocks
                    }
                    db.updateAlert(managerAlert.id, managerAlert);
                });


                setTimeout(() => {
                    this.setState({ isNotificationModalOpen: false });
                    //Calling Kudos Landing page as true
                    this.props.kudosLanding(true);
                }, 2300);
            }
            else
                setTimeout(() => {
                    this.setState({ isNotificationModalOpen: false });

                }, 1800);
        }
    }


    showRecipient() {
        if (this.state.user) {
            return (
                <div style={{ paddingBottom: "20px", whiteSpace: "nowrap", overflow: "hidden" }}>
                    {/* <div class="RecipientPerson"> */}
                    <Button style={{ marginLeft: "0px", paddingTop: "0px", paddingLeft: "2px", whitespace: "normal", cursor: "pointer", position: "absolute", height: "10px", marginTop: "52px", width: "9px", paddingRight: "2px", paddingBottom: "0px", marginBottom: "0px", fontSize: "6px", display: this.showCancelButton() }} onClick={this.changePeopleSearch}>X</Button>
                    <PersonCard person={this.state.user} showLargeThumb />
                    {/* </div> */}
                    {/* <div style={{paddingBottom:"20px"}}> */}

                    {/* </div> */}
                </div>)

        }
        if (this.state.userFromCard) {

            return (

                <div style={{ paddingBottom: "20px", whiteSpace: "nowrap", overflow: "hidden" }}>
                    {/* <div class="RecipientPerson"> */}

                    <Button style={{ marginLeft: "0px", paddingTop: "0px", paddingLeft: "2px", whitespace: "normal", cursor: "pointer", position: "absolute", height: "10px", marginTop: "52px", width: "9px", paddingRight: "2px", paddingBottom: "0px", marginBottom: "0px", fontSize: "6px", display: this.showCancelButton() }} onClick={this.changePeopleSearch}>X</Button>

                    <PersonCard person={this.state.userFromCard} showLargeThumb />
                </div>

            )
        }
    }

    showCancelButton() {
        if (this.state.user || this.state.userFromCard)
            return;
        else
            return "none";
    }

    render() {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            // animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };


        return (

            <div id="sendkudo" >
                <Modal isOpen={this.state.isCommentModalOpen} toggle={this.toggleModal} autoFocus={false} >
                    <ModalBody>
                        <div style={{ marginTop: "20px", marginBottom: "13px" }}>
                            Tell us what your colleague did or what they do that deserves this kind of recognition?
                        </div>

                        <Input type="textarea" maxLength={150} onChange={this.handleCommentChange} style={{ paddingLeft: "12px", paddingRight: "12px", lineHeight: "18px", height: "200px" }} required />
                        <Button onClick={this.onCommentSubmit}>Send</Button>

                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.isNotificationModalOpen} toggle={this.isNotificationModalOpen} >
                    <ModalBody>
                        <h4> {this.getNotifyText()} </h4>
                    </ModalBody>
                </Modal>

                <Row>
                    <Col xs="12">
                        <CardTitle tag="h6">Send a Kudo</CardTitle >
                        <CardTitle tag="h7">Choose the type of Kudo you would like to send to your colleague.</CardTitle >
                    </Col>
                </Row>
                <Row>
                    <Col style={{ paddingBottom: "0px", display: "flex", justifyContent: "flex-end", flexDirection: "column", textAlign: "right", paddingTop: "20px", paddingRight: "25px" }} lg="1" xs="12" md="1" sm="1">
                        {this.showRecipient()}
                    </Col>
                </Row>

                <Row>

                    {/* <div>
                        <PersonSearch
                            isOpen={this.state.openPersonSearch}
                            hideButton={true}
                            onPersonChosen={this.onPersonChosen}
                            dialogTitle="Who do you want to send this Kudo to?"
                        />
                    </div> */}
                    {categoryList.map((categoryObject) => {
                        return <Col xs="12" sm="12" md="6" lg="4" xl="3">

                            <Card onClick={this.openPeopleSearch(categoryObject)} style={{ cursor: "pointer" }} >

                                <CardBody style={{ padding: "0px", display: 'flex', flexDirection: 'column', justifyContent: 'start', minHeight:'300px' }} >
                                    <Row>
                                        <Col md="12" style={{ color: "green", padding: "0px", paddingLeft: "15px", paddingRight: "15px" }}>
                                            <div>
                                                <Lottie sm="12" style={{borderTopRightRadius: "12px", borderTopLeftRadius: "12px",height:"100%",width:"100%"}}
                                                    options={{ defaultOptions, animationData: categoryObject.image }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                                        <Col xs="12" >
                                            <div style={{ fontSize: "16px", color: "#db258f", marginTop: "20px", lineSpacing:"20px" }}>
                                                {categoryObject.title}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingLeft: "25px", paddingRight: "25px", paddingBottom: "20px", minHeight: "100px", fontSize: "12px", lineSpacing:"15px", marginTop: "8px" }} >
                                        <Col xs="12" >
                                            {categoryObject.description}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    })}

                </Row>
            </div >
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Appreciate = connect(mapStateToProps)(Appreciate);
export default Appreciate;