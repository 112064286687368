// NOTE: Can't install quill as dependency due to this error:
//    Critical dependency: the request of a dependency is an expression
//    ./node_modules/node-sass/lib/binding.js
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'

import React from 'react'
import { connect } from 'react-redux'
// reactstrap components
import {
  Button, Card, CardBody, CardTitle,
  Col, Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'

import { db } from '../../assets/firebase'
import logo from '../../assets/img/goConnectIcon.png'
import {
  arraySort, telemetry,
} from '../../assets/utils'
import MetricCard from '../../components/MetricCard/MetricCard'
import BusinessTab from '../../components/ProjectCard/Tabs/BusinessTab'
import LinksTab from '../../components/ProjectCard/Tabs/LinksTab'
import MembersTab from '../../components/ProjectCard/Tabs/MembersTab'
import MilestonesTab from '../../components/ProjectCard/Tabs/MilestonesTab'
import NotesTab from '../../components/ProjectCard/Tabs/NotesTab'
import OverviewTab from '../../components/ProjectCard/Tabs/OverviewTab'
import RisksTab from '../../components/ProjectCard/Tabs/RisksTab'
import StatusTab from '../../components/ProjectCard/Tabs/StatusTab'
import TasksTab from '../../components/ProjectCard/Tabs/TasksTab'
import TeamsTab from '../../components/ProjectCard/Tabs/TeamsTab'
import ProjectEditor from '../../components/ProjectEditor/ProjectEditor'
import StandardAdoptionMatrix from '../../components/StandardAdoptionMatrix/StandardAdoptionMatrix'

let authors = []

class ProjectPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      activeTab: 'overview',
      project: {
        name: '',
        problemStatement: '',
        vision: '',
        solutionConcept: '',
        links: [],
        photos: [],
        successCriteria: [],
      },
      members: [],
      statusReports: [],
      metrics: [],
      milestones: [],
      filteredMilestones: [],
      issues: [],
      filtered: [],
      sortBy: 'value',
      notes: [],
      filteredNotes: [],
      noteTypeFilter: 'all',
      risks: [],
      taskStatusFilter: 'Active',
      milestoneStatusFilter: 'all',
      milestoneSort: 'desc',
      issueTags: [],
      projectTags: [],
      teams: [],
    }

    this.displaySidebar = this.displaySidebar.bind(this)
    this.displayMainContents = this.displayMainContents.bind(this)
    this.filterIssues = this.filterIssues.bind(this)
    this.filterNotes = this.filterNotes.bind(this)
    this.sortIssues = this.sortIssues.bind(this)

    this.onIssueCreated = this.onIssueCreated.bind(this)
    this.onIssueDeleted = this.onIssueDeleted.bind(this)
    this.onIssueUpdated = this.onIssueUpdated.bind(this)
    this.onNoteCreated = this.onNoteCreated.bind(this)
    this.onNoteDeleted = this.onNoteDeleted.bind(this)
    this.onNoteUpdated = this.onNoteUpdated.bind(this)
    this.onRiskCreated = this.onRiskCreated.bind(this)
    this.onRiskUpdated = this.onRiskUpdated.bind(this)
    this.onStatusCreated = this.onStatusCreated.bind(this)
    this.onStatusUpdated = this.onStatusUpdated.bind(this)
  }

  componentDidMount() {
    db.getProject(this.props.projectID).then((project) => {
      document.title = `${project.name} - GoConnect`

      const projectRef = project
      if (!projectRef.photos) projectRef.photos = []

      this.setState({ project }, () => {
        const projectPromises = []
        projectPromises.push(this.getProjectMilestones())
        projectPromises.push(this.getProjectNotes())
        projectPromises.push(this.getProjectTags())
        projectPromises.push(this.getTeams())
        projectPromises.push(this.getProjectRisks())
        projectPromises.push(this.getProjectStatusReports())

        Promise.all(projectPromises).then(() => {
          const { milestones } = this.state
          const { notes } = this.state
          const { risks } = this.state
          const status = this.state.statusReports

          this.findUniqueAuthors(milestones)
          this.findUniqueAuthors(notes)
          this.findUniqueAuthors(risks)
          this.findUniqueAuthors(status)

          const authorPromises = []
          authors.forEach((author) => {
            authorPromises.push(db.getPerson(author))
          })

          Promise.all(authorPromises).then((people) => {
            // remove any missing people
            for (let p = people.length - 1; p > -1; p -= 1) {
              if (!people[p]) people.splice(p, 1)
            }

            authors = people

            this.attachAuthors(milestones)
            this.attachAuthors(notes)
            this.attachAuthors(risks)
            this.attachAuthors(status)

            this.filterMilestones()
            this.filterNotes()

            this.setState({ isLoading: false })
          })
        })
      })
    })

    db.getProjectMembers(this.props.projectID).then((projectMembers) => {
      const promises = []
      for (let i = 0; i < projectMembers.length; i += 1) { promises.push(db.getPerson(projectMembers[i].personID)) }

      Promise.all(promises).then((people) => {
        const members = []
        people.forEach((person) => { // necessary to handle missing employees
          if (person) members.push(person)
        })

        members.sort(arraySort.byName)
        this.setState({ members })
      })
    })

    db.getTagsByType('issueType').then((issueTags) => {
      issueTags.forEach((tag) => {
        const tagRef = tag
        tagRef.name = tag.name.toLowerCase()
      })

      db.getIssuesByProjectID(this.props.projectID).then((issues) => {
        this.setState({ issues, filtered: issues, issueTags }, () => this.filterIssues())
      })
    })

    db.getMetricsByProject(this.props.projectID).then((metrics) => {
      this.setState({ metrics })
    })

    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'projectPage', 'pageView')
  }

  findUniqueAuthors(objs) {
    objs.forEach((obj) => {
      let isFound = false
      for (let a = 0; a < authors.length; a += 1) {
        if (authors[a] === obj.authorID) {
          isFound = true
          break
        }
      }
      if (!isFound) { authors.push(obj.authorID) }
    })
  }

  attachAuthors(objs) {
    objs.forEach((obj) => {
      for (let b = 0; b < authors.length; b += 1) {
        if (obj.authorID === authors[b].id) {
          const objRef = obj
          objRef.author = authors[b]
          break
        }
      }
    })
  }

  getProjectMilestones() {
    return new Promise((resolve) => {
      db.getMilestonesByProject(this.props.projectID).then((milestones) => {
        // eslint-disable-next-line no-param-reassign
        milestones.forEach((milestone) => { milestone.dateStamp = new Date(milestone.date).getTime() })
        milestones.sort((a, b) => b.dateStamp - a.dateStamp)
        this.setState({ milestones }, resolve)
      })
    })
  }

  getProjectTags() {
    return new Promise((resolve) => {
      db.getTagsByType('project').then((projectTags) => {
        projectTags.forEach((tag) => {
          const tagRef = tag
          tagRef.name = tagRef.name.toLowerCase()
        })
        this.setState({ projectTags }, resolve)
      })
    })
  }

  getTeams() {
    return new Promise((resolve) => {
      db.getTeamsByProject(this.props.projectID).then((projectTeams) => {
        const promises = []
        for (let i = 0; i < projectTeams.length; i += 1) { promises.push(db.getTeam(projectTeams[i].teamID)) }

        Promise.all(promises).then((results) => {
          const teams = [] // because sometimes teams are deleted
          results.forEach((team) => {
            if (team) teams.push(team)
          })

          teams.sort(arraySort.byName)
          this.setState({ teams }, resolve)
        })
      })
    })
  }

  getProjectNotes() {
    return new Promise((resolve) => {
      db.getNotesByProject(this.props.projectID).then((notes) => {
        // eslint-disable-next-line no-param-reassign
        notes.forEach((note) => { note.dateStamp = new Date(note.published).getTime() })
        notes.sort((a, b) => b.dateStamp - a.dateStamp)
        this.setState({ notes }, resolve)
      })
    })
  }

  getProjectStatusReports() {
    return new Promise((resolve) => {
      db.getStatusReportsByProject(this.props.projectID).then((statusReports) => {
        statusReports.sort((a, b) => arraySort.byPublishedDateString(a, b) || arraySort.byCreatedDesc(a, b))
        this.setState({ statusReports }, resolve)
      })
    })
  }

  getProjectRisks() {
    return new Promise((resolve) => {
      db.getRisksByProject(this.props.projectID).then((risks) => {
        risks.sort(arraySort.byExposure)
        this.setState({ risks }, resolve)
      })
    })
  }

  onUpdated(project, members) {
    this.setState({ project, members })
  }

  showLoading() {
    if (this.state.isLoading) return 'block'
    return 'none'
  }

  changeTab(tab) {
    this.setState({ activeTab: tab }, () => {

    })
  }

  filterAttribute(value, filter) {
    if (value === filter || filter === 'all') { return true }

    return false
  }

  // issues/tasks
  filterIssues(filter, value) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let statusFilter = this.state.taskStatusFilter
    if (filter === 'status') { statusFilter = value }

    this.setState({ taskStatusFilter: statusFilter })

    const filtered = []
    for (let i = 0; i < this.state.issues.length; i += 1) {
      if (this.filterAttribute(this.state.issues[i].status, statusFilter)) {
        filtered.push(this.state.issues[i])
      }
    }

    this.setState({ filtered }, this.sortIssues)
  }

  onIssueCreated(issue) {
    const { issues } = this.state
    issues.push(issue)
    this.setState({ issues }, () => this.filterIssues())
  }

  onIssueUpdated(issue) {
    const { issues } = this.state
    for (let i = 0; i < issues.length; i += 1) {
      if (issues[i].id === issue.id) {
        issues[i] = issue
        break
      }
    }
    this.setState({ issues }, () => this.filterIssues())
  }

  onIssueDeleted(issue) {
    const { issues } = this.state
    for (let i = 0; i < issues.length; i += 1) {
      if (issues[i].id === issue.id) {
        issues.splice(i, 1)
        break
      }
    }
    this.setState({ issues }, () => this.filterIssues())
  }

  sortIssues(sortBy) {
    let issues = this.state.filtered
    this.setState({ filtered: [] }, () => {
      if (sortBy === this.state.sortBy) { // reverse
        const reversed = []
        for (let i = issues.length - 1; i > -1; i -= 1) { reversed.push(issues[i]) }
        issues = reversed
      } else {
        // eslint-disable-next-line no-param-reassign
        if (!sortBy) sortBy = this.state.sortBy

        if (sortBy === 'value') {
          issues.sort((a, b) => Number(b.value) - Number(a.value))
        } else if (sortBy === 'urgency') {
          issues.sort((a, b) => Number(b.urgency) - Number(a.urgency))
        }
      }

      this.setState({ filtered: issues, sortBy })
    })
  }

  // status reports
  onStatusCreated(statusReport) {
    const { statusReports } = this.state
    statusReports.push(statusReport)
    statusReports.sort((a, b) => arraySort.byPublishedDateString(a, b) || arraySort.byCreatedDesc(a, b))
    this.setState({ statusReports })
  }

  onStatusUpdated(statusReport) {
    const { statusReports } = this.state
    for (let i = 0; i < statusReports.length; i += 1) {
      if (statusReports[i].id === statusReport.id) {
        statusReports[i] = statusReport
        break
      }
    }
    statusReports.sort((a, b) => arraySort.byPublishedDateString(a, b) || arraySort.byCreatedDesc(a, b))
    this.setState({ statusReports })
  }

  // notes
  filterNotes(filter, value) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let typeFilter = this.state.noteTypeFilter
    if (filter === 'type') { typeFilter = value }

    this.setState({ noteTypeFilter: typeFilter, filteredNotes: [] }, () => {
      const filtered = []
      for (let i = 0; i < this.state.notes.length; i += 1) {
        if (this.filterAttribute(this.state.notes[i].category, typeFilter)) {
          filtered.push(this.state.notes[i])
        }
      }

      this.setState({ filteredNotes: filtered }, this.sortNotes)
    })
  }

  sortNotes() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const notes = this.state.filteredNotes
    notes.forEach((note) => {
      const noteRef = note
      noteRef.dateStamp = new Date(note.published).getTime()
    })
    notes.sort(arraySort.byPublishedDateString)

    this.setState({ filteredNotes: notes })
  }

  onNoteCreated(note) {
    const noteRef = note
    noteRef.author = this.props.user.person

    const { notes } = this.state
    this.setState({ notes: [] }, () => {
      notes.push(noteRef)
      notes.sort(arraySort.byPublishedDateString)
      this.setState({ notes }, this.filterNotes)
    })
  }

  onNoteUpdated(note) {
    const noteRef = note
    noteRef.author = this.props.user.person

    const { notes } = this.state
    this.setState({ notes: [] }, () => {
      for (let i = 0; i < notes.length; i += 1) {
        if (notes[i].id === noteRef.id) {
          notes[i] = noteRef
          break
        }
      }
      notes.sort(arraySort.byPublishedDateString)
      this.setState({ notes }, this.filterNotes)
    })
  }

  onNoteDeleted(id) {
    const { notes } = this.state
    this.setState({ notes: [] }, () => {
      for (let i = notes.length - 1; i > -1; i -= 1) {
        if (notes[i].id === id) {
          notes.splice(i, 1)
          break
        }
      }
      this.setState({ notes }, this.filterNotes)
    })
  }

  // risks
  onRiskCreated(risk) {
    const riskRef = risk
    riskRef.author = this.props.user.person

    const { risks } = this.state
    risks.push(risk)
    risks.sort(arraySort.byExposure)
    this.setState({ risks })
  }

  onRiskUpdated(risk) {
    const { risks } = this.state
    for (let i = 0; i < risks.length; i += 1) {
      if (risks[i].id === risk.id) {
        risks[i] = risk
        break
      }
    }
    risks.sort(arraySort.byExposure)
    this.setState({ risks })
  }

  // metrics
  metricCreated(metric) {
    const { metrics } = this.state
    metrics.push(metric)
    this.setState({ metrics })
  }

  metricUpdated(metric) {
    const { metrics } = this.state
    for (let i = 0; i < metrics.length; i += 1) {
      if (metrics[i].id === metric.id) {
        metrics[i] = metric
        break
      }
    }
    this.setState({ metrics })
  }

  metricDeleted(id) {
    const { metrics } = this.state
    for (let i = metrics.length - 1; i > -1; i -= 1) {
      if (metrics[i].id === id) {
        metrics.splice(i, 1)
        break
      }
    }
    this.setState({ metrics })
  }

  // milestones
  onMilestoneCreated(milestone) {
    const { milestones } = this.state

    const milestoneRef = milestone
    milestoneRef.dateStamp = new Date(milestone.date).getTime()
    milestones.push(milestoneRef)

    this.setState({ milestones }, () => this.filterMilestones())
  }

  onMilestoneUpdated(milestone) {
    const { milestones } = this.state

    const milestoneRef = milestone
    milestoneRef.dateStamp = new Date(milestoneRef.date).getTime()

    for (let i = 0; i < milestones.length; i += 1) {
      if (milestones[i].id === milestoneRef.id) {
        milestones[i] = milestoneRef
        break
      }
    }

    this.setState({ milestones }, () => this.filterMilestones())
  }

  onMilestoneDeleted(id) {
    const { milestones } = this.state
    for (let i = milestones.length - 1; i > -1; i -= 1) {
      if (milestones[i].id === id) {
        milestones.splice(i, 1)
        break
      }
    }
    this.setState({ milestones }, () => this.filterMilestones())
  }

  filterMilestones(property, value) {
    let { milestoneStatusFilter } = this.state
    if (property === 'status') { milestoneStatusFilter = value }

    let { milestoneSort } = this.state
    if (property === 'sort') { milestoneSort = value }

    this.setState({ milestoneStatusFilter, milestoneSort })

    const filtered = []
    const { milestones } = this.state
    milestones.forEach((milestone) => {
      if (milestone.status === milestoneStatusFilter || milestoneStatusFilter === 'all') { filtered.push(milestone) }
    })

    if (milestoneSort === 'asc') {
      filtered.sort((b, a) => b.dateStamp - a.dateStamp)
    } else {
      filtered.sort((a, b) => b.dateStamp - a.dateStamp)
    }

    this.setState({ filteredMilestones: [] }, () => {
      this.setState({ filteredMilestones: filtered })
    })
  }

  slackEm() {
    if (this.state.project.slackID) { window.open(`slack://channel?team=T02T4D001&id=${this.state.project.slackID}`) }
  }

  showSlackButton() {
    if (this.state.project.slackID) return 'inline-block'
    return 'none'
  }

  displaySidebar() {
    return (
      <Col xs="12" sm="12" md="12" lg="3">

        <Card style={{
          marginTop: '20px', padding: '10px', paddingTop: '20px', paddingBottom: '20px',
        }}
        >
          <CardBody style={{ paddingTop: '0px' }}>
            <MembersTab members={this.state.members} />
          </CardBody>
        </Card>

        {this.state.metrics.map((metric, index) => (
          <Row key={index}>
            <Col xs="12">
              <MetricCard
                projectID={this.state.project.id}
                metric={metric}
                onDeleted={(m) => this.metricDeleted(m)}
                onUpdated={(m) => this.metricUpdated(m)}
              />
            </Col>
          </Row>
        ))}

        <Row>
          <Col xs="6" />
          <Col xs="6">
            <MetricCard projectID={this.state.project.id} onCreated={(m) => this.metricCreated(m)} />
          </Col>
        </Row>
      </Col>
    )
  }

  /* eslint-disable complexity */
  displayMainContents() {
    const link = {
      color: '#434343',
      fontWeight: 500,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeLink = {
      color: 'white',
      fontWeight: 700,
      fontSize: '12px',
    }
    const activeTab = {
      backgroundColor: 'green',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: 'green',
    }
    const tab = {
      backgroundColor: '#dedede',
      marginLeft: '1px',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    return (
      <Col xs="12" sm="12" md="12" lg="9">

        <Card style={{ marginTop: '20px' }}>
          <div style={{
            backgroundSize: 'cover',
            height: '200px',
            width: '100%',
            backgroundColor: '#aaaaaa',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px',
            backgroundImage: `url(${this.state.project.profilePhoto})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderBottom: '1px solid green',
          }}
          />
          <CardBody style={{ padding: '50px', paddingTop: '0px' }}>

            <Row>
              <Col xs="9">
                <CardTitle tag="h4">{this.state.project.name}</CardTitle>
              </Col>
              <Col xs="3" style={{ marginTop: '23px', textAlign: 'right' }}>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => this.slackEm()}
                    style={{ display: this.showSlackButton() }}
                  >
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                      <i style={navIcon} className="nc-icon nc-chat-33" />
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                      Slack
                    </div>
                  </Button>
                </div>
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <ProjectEditor
                    project={this.state.project}
                    members={this.state.members}
                    onUpdated={(p, m) => this.onUpdated(p, m)}
                  />
                </div>
              </Col>
            </Row>

            <Nav style={{ marginTop: '10px' }}>
              <NavItem style={(this.state.activeTab === 'overview' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'overview' ? activeLink : link)}
                  onClick={() => this.changeTab('overview')}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'business' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'business' ? activeLink : link)}
                  onClick={() => this.changeTab('business')}
                >
                  Business
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'links' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'links' ? activeLink : link)}
                  onClick={() => this.changeTab('links')}
                >
                  Links
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'teams' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'teams' ? activeLink : link)}
                  onClick={() => this.changeTab('teams')}
                >
                  Teams
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'milestones' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'milestones' ? activeLink : link)}
                  onClick={() => this.changeTab('milestones')}
                >
                  Milestones
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'notes' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'notes' ? activeLink : link)}
                  onClick={() => this.changeTab('notes')}
                >
                  Notes
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'risks' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'risks' ? activeLink : link)}
                  onClick={() => this.changeTab('risks')}
                >
                  Risks
                </NavLink>
              </NavItem>
              <NavItem style={(this.state.activeTab === 'status' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'status' ? activeLink : link)}
                  onClick={() => this.changeTab('status')}
                >
                  Status
                </NavLink>
              </NavItem>
              {this.state.project.enableAdoptionMatrix
                && (
                <NavItem style={(this.state.activeTab === 'adoption' ? activeTab : tab)}>
                  <NavLink
                    style={(this.state.activeTab === 'adoption' ? activeLink : link)}
                    onClick={() => this.changeTab('adoption')}
                  >
                    Adoption
                  </NavLink>
                </NavItem>
                )}
              <NavItem style={(this.state.activeTab === 'tasks' ? activeTab : tab)}>
                <NavLink
                  style={(this.state.activeTab === 'tasks' ? activeLink : link)}
                  onClick={() => this.changeTab('tasks')}
                >
                  Tasks
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent
              activeTab={this.state.activeTab}
              style={{ borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#888888' }}
            >
              <TabPane tabId="overview" style={{ minHeight: '210px', padding: '17px' }}>
                <OverviewTab
                  projectRef={this.state.project}
                  allTagsRef={this.state.projectTags}
                  isLoading={this.props.isLoading}
                />
              </TabPane>

              <TabPane tabId="business" style={{ minHeight: '210px', paddingTop: '17px' }}>
                <BusinessTab projectRef={this.state.project} />
              </TabPane>

              <TabPane tabId="links" style={{ minHeight: '210px' }}>
                <LinksTab
                  projectLinks={this.state.project.links}
                  styles={{ padding: '30px', backgroundColor: 'white', minHeight: '89px' }}
                />
              </TabPane>

              <TabPane tabId="teams" style={{ minHeight: '210px', backgroundColor: '#efefef', padding: '20px' }}>
                <TeamsTab teams={this.state.teams} />
              </TabPane>

              <TabPane
                tabId="milestones"
                style={{
                  minHeight: '210px',
                  padding: '17px',
                  paddingRight: '23px',
                  fontSize: '12px',
                  backgroundColor: '#efefef',
                }}
              >
                <MilestonesTab
                  projectRef={this.state.project}
                  filteredMilestones={this.state.filteredMilestones}
                  filterMilestones={this.filterMilestones}
                  onMilestoneCreated={this.onMilestoneCreated}
                  onMilestoneDeleted={this.onMilestoneDeleted}
                  onMilestoneUpdated={this.onMilestoneUpdated}
                  milestoneStatusFilter={this.state.milestoneStatusFilter}
                  milestoneSort={this.state.milestoneSort}
                />
              </TabPane>

              <TabPane
                tabId="status"
                style={{
                  minHeight: '210px', padding: '20px', paddingTop: '17px', fontSize: '12px', backgroundColor: '#efefef',
                }}
              >
                <StatusTab
                  projectID={this.props.projectID}
                  personID={this.props.user.person.id}
                  isLoading={this.state.isLoading}
                  statusReports={this.state.statusReports}
                  onStatusCreated={this.onStatusCreated}
                  onStatusUpdated={this.onStatusUpdated}
                />
              </TabPane>

              <TabPane tabId="adoption" style={{ minHeight: '210px', padding: '20px', paddingTop: '37px' }}>
                {this.state.project.enableAdoptionMatrix && <StandardAdoptionMatrix project={this.state.project} />}
              </TabPane>

              <TabPane
                tabId="notes"
                style={{
                  minHeight: '210px', padding: '20px', paddingTop: '17px', fontSize: '12px', backgroundColor: '#efefef',
                }}
              >
                <NotesTab
                  projectID={this.props.projectID}
                  isLoading={this.props.isLoading}
                  filterNotes={this.filterNotes}
                  filteredNotes={this.state.filteredNotes}
                  noteTypeFilter={this.state.noteTypeFilter}
                  onNoteCreated={this.onNoteCreated}
                  onNoteDeleted={this.onNoteDeleted}
                  onNoteUpdated={this.onNoteUpdated}
                />
              </TabPane>

              <TabPane
                tabId="risks"
                style={{
                  minHeight: '210px', padding: '20px', paddingTop: '17px', fontSize: '12px', backgroundColor: '#efefef',
                }}
              >
                <RisksTab
                  projectID={this.props.projectID}
                  isLoading={this.props.isLoading}
                  risks={this.state.risks}
                  onRiskCreated={this.onRiskCreated}
                  onRiskUpdated={this.onRiskUpdated}
                />
              </TabPane>

              <TabPane tabId="tasks" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                <TasksTab
                  projectID={this.props.projectID}
                  isLoading={this.props.isLoading}
                  allTagsRef={this.state.issueTags}
                  filtered={this.state.filtered}
                  filterIssues={this.filterIssues}
                  sortIssues={this.sortIssues}
                  taskStatusFilter={this.state.taskStatusFilter}
                  onIssueCreated={this.onIssueCreated}
                  onIssueUpdated={this.onIssueUpdated}
                  onIssueDeleted={this.onIssueDeleted}
                />
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
      </Col>
    )
  }
  /* eslint-enable complexity */

  render() {
    const loadingDiv = {
      display: this.showLoading(),
      position: 'absolute',
      top: '150px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }

    return (
      <div style={{ padding: '30px' }}>

        <style>
          {
                        `
                        @keyframes spinning {
                            from { transform: rotate(0deg) }
                            to { transform: rotate(360deg) }
                        }
                        .spin {
                            animation-name: spinning;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                            /* linear | ease | ease-in | ease-out | ease-in-out */
                            animation-timing-function: linear;
                        }
                        `
                    }
        </style>

        <Row>
          <Col xs="12">
            <div style={loadingDiv}>
              <div className="spin">
                <img src={logo} alt="logo" style={{ height: '43px', width: '43px' }} />
              </div>
            </div>
          </Col>
        </Row>

        {!this.props.isLoading && (
          <div>
            <br />
            <Row>
              { this.displayMainContents() }
              { this.displaySidebar() }
            </Row>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-class-assign
ProjectPage = connect(mapStateToProps)(ProjectPage)
export default ProjectPage
