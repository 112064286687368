import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, dateTime, strings, telemetry } from 'assets/utils';

// reactstrap components
import {
    Card, CardBody, CardTitle,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Row,
    Col
  } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import ProjectCard from "components/ProjectCard/ProjectCard.jsx";
import Tag from "components/Tag/Tag.js"; 
//import TeamCard from "components/TeamCard/TeamCard.js";

import asanaLogo from "assets/img/asana.png";
import logo from "assets/img/goConnectIcon.png";

class ProjectTracker extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          filtered: [],
          projects: [],
          teams: [],
          people: [],
          filteredPeople: [],
          xrefs: [],
          isLoading: true,
          queryText: '',
          teamFilter: 'All',
          personFilter: 'All',
          tagFilter: [],
          view: 'summary'
        }

        if(this.props.teamID)
            this.state.teamFilter = this.props.teamID;
    }

    componentDidMount() {
        
        this.getTeamsForCurrentUser().then(teams => {

            this.getTeamMembers(teams).then(result => {

                this.setState({teams: result.teams, people: result.people, filteredPeople : result.people}, () => {
                    
                    this.getProjects(result.teams, result.people).then(projectResults => {

                        this.getProjectMembers(projectResults.projects).then(projects => {

                            this.getProjectStatus(projects).then(() => {

                                this.setState({
                                    xrefs: projectResults.xrefs, 
                                    projects: projects
                                }, () => {
                                    this.filterProjects();
                                });
                            });
                        }); 
                    });
                });
            })
        })
         
        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "projectTracker", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    getTeamsForCurrentUser() {

        return new Promise((resolve, reject) => {

            db.getTeamsByPerson(this.props.user.personID).then(xrefs => {
                var promises = [];
                xrefs.forEach(xref => {
                    promises.push(db.getTeam(xref.teamID));
                });
                if(this.props.teamID) {
                    
                    var isFound = false;
                    for(var i = 0; i < xrefs.length; i++) {
                        if(xrefs[i].teamID === this.props.teamID) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound) promises.push(db.getTeam(this.props.teamID));
                }
    
                Promise.all(promises).then(teams => {
                    
                    teams.sort(arraySort.byName);
                    resolve(teams);
                });
            });
        });
    }

    getTeamMembers(teams) {

        return new Promise((resolve, reject) => {

            var promises = [];

            teams.forEach(team => promises.push(db.getTeamMembers(team.id)) );

            Promise.all(promises).then(results => {
                
                promises = [];
                var peopleIDs = [];
                
                results.forEach(result => {

                    result.forEach(row => {

                        var isFound = false;
                        for(var i = 0; i < peopleIDs.length; i++) {
                            if(peopleIDs[i] === row.personID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) peopleIDs.push(row.personID); 
                    }); 
                })

                peopleIDs.forEach(personID => {
                    promises.push(db.getPerson(personID));
                });

                Promise.all(promises).then(people => {

                    for(var x = people.length-1; x > -1; x--)
                        if(!people[x]) 
                            people.splice(x,1);

                    people.sort(arraySort.byName);
                    
                    results.forEach(result => {
                        
                        if(result.length > 0) { // handing the scenarios where there are no team members
                        
                            for(var j = 0; j < teams.length; j++) {

                                if(result[0].teamID === teams[j].id) {

                                    teams[j].people = [];

                                    for(var r = 0; r < result.length; r++) {
                                        for(var k = 0; k < people.length; k++) {
                                            if(people[k].id === result[r].personID) {
                                                teams[j].people.push(people[k]);
                                                break;
                                            }
                                        }
                                    }

                                    teams[j].people.sort(arraySort.byName);

                                    break;
                                }
                            }
                        }
                    })
                    
                    resolve({ teams: teams, people: people });
                });
            });
        });
    }

    getProjects(teams, people) {

        return new Promise((resolve, reject) => {

            var projectIDs = [];
            var xrefs = [];

            this.getProjectXREFsForTeams(teams).then(teamXrefs => {

                teamXrefs.forEach(teamXref => {

                    teamXref.forEach(row => {

                        xrefs.push({ 
                            teamID: row.teamID,
                            projectID: row.projectID    
                        });
            
                        var isTeamProjectFound = false;
                        for(var i = 0; i < projectIDs.length; i++) {
                            if(row.projectID === projectIDs[i]) {
                                isTeamProjectFound = true;
                                break;
                            }
                        }
                        if(!isTeamProjectFound) projectIDs.push(row.projectID);
                    });
                });

                this.getProjectXREFsForPeople(people).then(peopleXrefs => {

                    peopleXrefs.forEach(personXref => {

                        personXref.forEach(row => {
    
                            xrefs.push({ 
                                personID: row.personID,
                                projectID: row.projectID    
                            });
                
                            var isPersonProjectFound = false;
                            for(var i = 0; i < projectIDs.length; i++) {
                                if(row.projectID === projectIDs[i]) {
                                    isPersonProjectFound = true;
                                    break;
                                }
                            }
                            if(!isPersonProjectFound) projectIDs.push(row.projectID);
                                        
                        });
                    });
                    
                    var promises = [];
                    projectIDs.forEach(projectID => promises.push(db.getProject(projectID)));
                    Promise.all(promises).then(allProjects => {
                
                        var projects = [];
                        allProjects.forEach(project => {
                            if(project.isActive) {
                                if(!project.successCriteria)
                                    project.successCriteria = [];
                                project.asana = {
                                    current_status: {
                                        color:"",
                                        title:""
                                    }
                                };
                                if(!project.urgency) project.urgency = 1;
                                if(!project.impact) project.impact = 2;
                                projects.push(project);
                            }
                        });

                        projects.sort(arraySort.byName);
                        resolve({ xrefs: xrefs, projects: projects});
                    });
                });
            });  
        });
    }

    getProjectMembers(projects) {

        return new Promise((resolve, reject) => {

            var promises = [];

            projects.forEach(project => {
                project.people = [];
                promises.push(db.getProjectMembers(project.id)) 
            });

            Promise.all(promises).then(results => {
                
                promises = [];
                var peopleIDs = [];
                
                results.forEach(result => {

                    result.forEach(row => {

                        var isFound = false;
                        for(var i = 0; i < peopleIDs.length; i++) {
                            if(peopleIDs[i] === row.personID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) peopleIDs.push(row.personID); 
                    }); 
                })

                peopleIDs.forEach(personID => {
                    promises.push(db.getPerson(personID));
                });

                Promise.all(promises).then(people => {

                    for(var x = people.length -1; x> -1; x--) {
                        if(!people[x])
                            people.splice(x,1);
                    }

                    people.sort(arraySort.byName);
                    
                    results.forEach(result => {

                        if(result.length > 0) {

                            for(var j = 0; j < projects.length; j++) {
                                if(result[0].projectID === projects[j].id) {
    
                                    result.forEach(row => {
                                        for(var k = 0; k < people.length; k++) {
                                            if(people[k].id === row.personID) {
                                                projects[j].people.push(people[k]);
                                                break;
                                            }
                                        }
                                    })
    
                                    projects[j].people.sort(arraySort.byName);
    
                                    break;
                                }
                            }
                        }
                    })
                    
                    resolve(projects);
                });
            });
        });
    }

    getProjectXREFsForTeams(teams) {

        return new Promise((resolve, reject) => {

            var promises = [];

            teams.forEach(team => promises.push(db.getTeamProjects(team.id)) );

            Promise.all(promises).then(xrefs => {

                resolve(xrefs);
            })
        })

    }

    getProjectXREFsForPeople(people) {

        return new Promise((resolve, reject) => {

            var promises = [];

            people.forEach(person => promises.push(db.getProjectsByPerson(person.id)) );

            Promise.all(promises).then(xrefs => {
                
                resolve(xrefs);
            })
        })

    }

    getProjectStatus(projects) {
        return new Promise((resolve, reject) => {

            var promises = [];
            projects.forEach(project => {
                if(project.asanaProjectID) {
                    promises.push(db.getAsanaProject(project.asanaProjectID));
                }
            });

            Promise.all(promises).then(results => {
                
                results.forEach(result => {
                    for(var i =0; i < projects.length; i++) {
                        if(projects[i].asanaProjectID) {   
                            if(result.id === projects[i].asanaProjectID.toString()) {

                                projects[i].asana = result;                            
                                break;
                            }
                        }
                    }
                })

                resolve();
            });

        });
    }

    byPriority(a,b) {

        var x = Number(a.impact) * Number(a.urgency);
        var y = Number(b.impact) * Number(b.urgency);
      
        if (x > y)
          return -1;
        if (x < y)
          return 1;
        return 0;
    }

    filterProjects(filter, value) {

        this.setState({isLoading: true}, () => {
    
            var personFilter = this.state.personFilter;
            if(filter === 'person')
              personFilter = value;

            var teamFilter = this.state.teamFilter;
            if(filter === 'team') {
                teamFilter = value;

                // update the person filter drop-down
                if(value === 'All') 
                    this.setState({filteredPeople : this.state.people});
                else 
                    for(var t = 0; t < this.state.teams.length; t++) {
                        if(this.state.teams[t].name === teamFilter) {
                            this.setState({filteredPeople: this.state.teams[t].people});
                            break;
                        }
                    }
            }

            var tagFilter = this.state.tagFilter;
            if(filter === 'tags')
                tagFilter = value;

    
            this.setState({teamFilter: teamFilter, personFilter: personFilter, tagFilter: tagFilter });

            var filtered = [];
            var xrefs = this.state.xrefs;
            this.state.projects.forEach(project => {

                var matchesTeamFilter = false;
                if(teamFilter === 'All') 
                    matchesTeamFilter = true;
                else 
                    for(var i = 0; i < xrefs.length; i++) {
                        if(xrefs[i].teamID === teamFilter && xrefs[i].projectID === project.id) {
                            matchesTeamFilter = true;
                            break;
                        }
                    }
                
                var matchesPersonFilter = false;
                if(personFilter === 'All') 
                    matchesPersonFilter = true;
                else 
                    for(var j = 0; j < xrefs.length; j++) {
                        if(xrefs[j].personID === personFilter && xrefs[j].projectID === project.id) {
                            matchesPersonFilter = true;
                            break;
                        }
                    }
                if(this.filterSet(project.tags, tagFilter))
                    if(matchesTeamFilter && matchesPersonFilter)
                        filtered.push(project);
            })

            filtered.sort(arraySort.byName);
            this.setState({filtered: filtered, isLoading: false});

        });        
    }

    filterSet(values, filter) {
        if(filter.length === 0) return true;

        if(!values) return false;

        for(var i = 0; i < values.length; i++) {
            for(var j = 0; j < filter.length; j++) {
                if(values[i] === filter[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    projectMembers(project) {
        
        
        var people = [];

        // get team members first
        this.state.xrefs.forEach(xref => {
            if(xref.projectID === project.id && xref.personID) {
                for(var i = 0; i < this.state.people.length; i++) {
                    if(this.state.people[i].id === xref.personID) {
                        people.push(this.state.people[i]);
                        break;
                    }
                }
            }
        })    
        
        // add other people on the project until we have 4
        project.people.forEach(person => {
           // if(people.length < 4) {
                var isFound = false;
                for(var j = 0; j < people.length; j++) {
                    if(people[j].id === person.id) {
                        isFound = true;
                        break;
                    }
                }
                if(!isFound) people.push(person);
           // }
        })
    
        return (
            <div style={{marginTop:"5px"}}>
                {people.map((person, index) => (
                <div key={index} style={{display:"inline-block", marginLeft:"2px", marginRight:"2px"}}>
                    <PersonCard person={person} showLargeThumb />
                </div>
                ))}
            </div>   
        )
    }

    getStatusText(status) {
        if(status) {
            if(status.title) {
                return (
                    <div style={{borderColor: status.color, borderLeftStyle: "solid", borderWidth:"6px"}}>
                        <div style={{fontWeight:600, marginLeft:"10px", marginTop:"4px"}}>
                            Most Recent Status - {status.title} : <span style={{fontWeight:400}}>{strings.trim(status.text,900)}</span>        
                        </div>
                        <div style={{fontSize:"11px", fontWeight:400, marginTop:"6px", marginLeft:"10px", marginBottom:"21px", color:"#434343"}}>
                            {dateTime.dateConverter(status.created_at)}
                        </div>
                    </div>
                )
            }
        }
    }

    showFilters() {
        if(this.state.queryText.length > 0)
            return "none";
        else
            return "block";
    }
    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showSuccessCriteria(project) {
        if(project.successCriteria.length > 0)
            return "block";
        else 
            return "none";
    }
    showAsanaLink(project) {
        if(project.asanaProjectID)
            return "block";
        else 
            return "none";
    }
    openAsanaPage(project) {
        window.open('https://app.asana.com/0/' + project.asanaProjectID);
    }
    
    getCols() {
        if(this.state.view === 'summary')
            return 6;
        else 
            return 12;
    }
    showStatus() {
        if(this.state.view === 'status')
            return "block";
        else 
            return "none";
    }
    showSummary() {
        if(this.state.view === 'summary')
            return "block";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div style={{padding:"30px"}}>

                <style>
                    {
                        `
                        @keyframes spinning {
                            from { transform: rotate(0deg) }
                            to { transform: rotate(360deg) }
                        }
                        .spin {
                            animation-name: spinning;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                            /* linear | ease | ease-in | ease-out | ease-in-out */
                            animation-timing-function: linear;
                        }
                        `
                    }
                </style>

                <Row>
                    <Col sm="12" >
                        <br />
                        <Row>
                            <Col sm="6">
                                <CardTitle tag="h4">Project Tracker</CardTitle>
                            </Col>
                            <Col sm="6" style={{textAlign:"right"}}>
                                
                            </Col>
                        </Row>

                        <div style={{display:this.showFilters(), padding:"10px", width:"100%"}}>
                            <Row>
                                <Col xs="12">
                                    <Card style={{padding:"10px"}}>
                                        <CardBody>
                                            <Row>
                                                <Col sm="4">
                                                    Team <br />
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                                <i style={icon} className="nc-icon nc-bank" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder="" 
                                                            type="select" 
                                                            value={this.state.teamFilter} onChange={(e) => this.filterProjects("team", e.target.value)} >
                                                                <option>All</option>
                                                                {this.state.teams.map((team, index) => (
                                                                <option value={team.id} key={index}>{team.name}</option>
                                                                ))}
                                                        </Input>
                                                    </InputGroup>
                                                    <br />
                                                </Col>
                                                <Col sm="4" >
                                                    People <br />
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                                <i style={icon} className="nc-icon nc-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder="" 
                                                            type="select" 
                                                            value={this.state.personFilter} onChange={(e) => this.filterProjects("person", e.target.value)} >
                                                                <option>All</option>
                                                                {this.state.filteredPeople.map((person, index) => (
                                                                <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </InputGroup>
                                                    <br />
                                                </Col>
                                                <Col sm="4" >
                                                    View <br />
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                                <i style={icon} className="nc-icon nc-tile-56" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder="" 
                                                            type="select" 
                                                            value={this.state.view} onChange={(e) => this.setState({view: e.target.value})} >
                                                                <option value="summary">Summary</option>
                                                                <option value="status">Status Reports</option>
                                                        </Input>
                                                    </InputGroup>
                                                    <br />
                                                </Col>
                                                <Col xs="12" sm="12" >
                                                    Tags <br />
                                                    <Tag type="project" icon="nc-tag-content" placeholder="All" readOnly
                                                        selected={this.state.tagFilter} 
                                                        onChange={(tags) => this.filterProjects('tags',tags)} />
                                                    <br />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <Row>
                            <Col xs="12">
                                <div style={loadingDiv}>
                                    <div className="spin">
                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div style={{display:this.showResults(), padding:"10px"}}>
                            <Row>
                            
                                {this.state.filtered.map((project, index) => (
                                    <Col xs="12" sm={this.getCols()}>

                                        <Card key={index} style={{padding:"0px", marginBottom:"20px"}} >

                                            <CardBody style={{display:this.showStatus()}}>
                                                <Row  style={{fontSize:"12px", fontFamily:'arial'}}>
                                                    <Col xs="12" sm="12" md="4" lg="3" style={{padding:"10px", paddingLeft:"17px", paddingRight:"17px"}}>
                                                        <ProjectCard project={project} showXLargeThumb />
                                                        {this.projectMembers(project)}
                                                    </Col>
                                                    <Col xs="12" sm="12" md="8" lg="4" style={{padding:"10px", paddingLeft:"17px", paddingRight:"17px"}}>
                                                        <div style={{fontSize:"19px", color:"green", display:"inline-block"}}>
                                                            {project.name}
                                                        </div>
                                                        <div style={{fontSize:"12px"}}>
                                                            {project.description}
                                                        </div>
                                                        
                                                        <div style={{display:this.showSuccessCriteria(project)}}>
                                                            <br /><b>Success Criteria</b><br />
                                                            {project.successCriteria.map((criterion, index) => (    
                                                                <div key={index} style={{color:"#232323"}}>- {criterion.description}<br/></div>
                                                            ))}
                                                        </div>
                                                        <div style={{marginTop:"13px", marginBottom:"10px", display:this.showAsanaLink(project)}}>
                                                            <img src={asanaLogo} style={{height:"13px", cursor:"pointer"}} onClick={() => this.openAsanaPage(project)} />
                                                        </div>
                                                    </Col>

                                                    
                                                    <Col md="4" className='d-xs-none d-sm-none d-md-block d-lg-none d-xl-none' ></Col>
                                                    <Col xs="12" sm="12" md="8" lg="5" style={{padding:"10px", paddingLeft:"17px", paddingRight:"17px"}}>
                                                        <div style={{verticalAlign:"top", fontSize:"12px", paddingRight:"6px", height:"100%"}}>
                                                            {this.getStatusText(project.asana.current_status)}
                                                        </div>
                                                    </Col>
                                                    
                                                </Row>
                                            </CardBody>

                                            <CardBody style={{display:this.showSummary(), minHeight:"500px"}}>
                                                <Row  style={{fontSize:"12px", fontFamily:'arial'}}>
                                                    <Col xs="12" sm="12" style={{padding:"10px", paddingLeft:"17px", paddingRight:"17px"}}>
                                                        <ProjectCard project={project} showXLargeThumb />
                                                        {this.projectMembers(project)}
                                                    </Col>
                                                    <Col xs="12" sm="12" style={{padding:"10px", paddingLeft:"17px", paddingRight:"17px"}}>
                                                        <div style={{fontSize:"19px", color:"green", display:"inline-block"}}>
                                                            {project.name}
                                                        </div>
                                                        <div style={{fontSize:"12px"}}>
                                                            {strings.trim(project.description,200)}
                                                        </div>
                                                        
                                                        <div style={{display:this.showSuccessCriteria(project)}}>
                                                            <br /><b>Success Criteria</b><br />
                                                            {project.successCriteria.map((criterion, index) => (    
                                                                <div key={index} style={{color:"#232323"}}>- {criterion.description}<br/></div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                ))}
                            
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

ProjectTracker = connect(mapStateToProps)(ProjectTracker);
export default ProjectTracker;