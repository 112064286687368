import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, tables, telemetry } from 'assets/utils';

//components
import { 
    Badge,
    CustomInput,
    Button, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row, Col, 
} from "reactstrap";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

const uuid = require('uuid');

const colorOptions = ["primary", "success", "danger", "warning", "info"];

class Tag extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            searchText:"",
            tags: [],
            potentialMatches: [],
            selected: []
        }

        this.textInput = React.createRef();
    }

    componentDidMount() {
        this.getTags().then(() => {
            this.matchTags(this.props.selected);
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.selected !== this.props.selected) {
            this.matchTags(this.props.selected);
        }
    }

    getTags() {
        return new Promise((resolve, reject) => {
            db.getTagsByType(this.props.type).then(tags => {
                tags.sort(arraySort.byName);
                this.setState({tags: tags}, () => {
                    resolve();
                });
            });
        });
    }

    matchTags(tags) {

        if(tags) {
            if(!Array.isArray(tags)) tags=[]; //protection becaues data type changed
            
            var selected = [];
            tags.forEach(tag => {
                for(var i = 0; i < this.state.tags.length; i++) {
                    if(tag === this.state.tags[i].id) {
                        selected.push(this.state.tags[i]);
                        break;
                    }
                }
            })
            
            selected.sort(arraySort.byName);
            this.setState({selected: selected});
        }
    }

    toggleModal() {
        
        if(!this.state.modal) {
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "tagEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    updateSearchText(text) {
        
        if(text.length > 0) {
   
            text = text.toLowerCase();

            var potentialMatches = [];
            this.state.tags.forEach(tag => {
                if(text === tag.name.substr(0,text.length).toLowerCase()) {
                    var isFound = false;
                    for(var i = 0; i < this.state.selected.length; i++) {
                        if(tag.id === this.state.selected[i].id) {
                            isFound = true;
                            break;
                        }
                    }
                
                    if(!isFound) potentialMatches.push(tag);
                }
            })
            
            this.setState({potentialMatches: potentialMatches, searchText: text});
        } else {
            this.setState({potentialMatches: [], searchText: text});
        }
    }


    keystroke(e) {

        if (e.keyCode === 13 && !this.props.readOnly) { // (enter) if you can create tags
    
            var selected = this.state.selected;
            var tags = this.state.tags;

            var isFound = false;
            for(var i = 0; i < tags.length; i++) {
                if(tags[i].name === this.state.searchText) {
                    isFound = true;
                    break;
                }
            }

            if(!isFound) { // add the tag
                var tag = {
                    id: uuid.v4(),
                    name: this.state.searchText,
                    color: this.getNextTagColor(),
                    type: this.props.type
                }

                tags.push(tag);
                tags.sort(arraySort.byName);
                selected.push(tag);
                this.setState({tags: tags, selected: selected, searchText: ''}, () => {
                    this.props.onChange(this.selectedTags());
                    
                });

                db.updateTag(tag.id, tag);
            }
        }
        else if (e.keyCode === 9) { // (tab) if you are selecting tags

            e.preventDefault();
            if(this.state.potentialMatches.length > 0) {
                this.selectTag(this.state.potentialMatches[0]);
            }
        }
        
    }

    showPotentialMatches() {
        if(this.state.potentialMatches.length > 0) 
            return "block";
        else 
            return "none";
    }

    getNextTagColor() {

        var options = [];
        colorOptions.forEach(option => {
            options.push( { name: option, count: 0});
        });

        this.state.tags.forEach(tag => {
            for(var i = 0; i < options.length; i++) {
                if(options[i].name === tag.color) {
                    options[i].count = options[i].count + 1;
                    break;
                }
            }
        })
        options.sort((a,b) => {return a.count - b.count});
        
        return options[0].name;
    }

    removeTag(tag) {
        var selected = this.state.selected;
        for(var i = 0; i < selected.length; i++) {
            if(selected[i].id === tag.id) {
                selected.splice(i,1);
                break;
            }
        }
        this.setState({selected: selected});
        this.props.onChange(this.selectedTags());
    }

    selectTag(tag) {
        var selected = this.state.selected;
        selected.push(tag);
        this.setState({selected: selected, potentialMatches:[], searchText: ''}, () => {
            this.props.onChange(this.selectedTags());
            this.textInput.current.focus();
        });
    }

    selectedTags() {
        var tags = [];
        this.state.selected.forEach(tag => {
            tags.push(tag.id);
        })
        return tags;
    }

    getSelected(id) {

        for(var i = 0; i < this.state.selected.length; i++) {
            if(this.state.selected[i].id === id) {
                return true;
            }
        }
        
        return false;
    }

    toggleSelect(tag) {
        var isFound = false;
        var selected = this.state.selected;

        for(var i = 0; i < selected.length; i++) {
            if(selected[i].id === tag.id) {
                selected.splice(i,1);
                isFound = true;
                break;
            }
        };

        if(!isFound) 
            selected.push(tag);
        
        this.setState({selected: selected}, () => {
            this.props.onChange(this.selectedTags());
        });
    }

    showDeleteTag() {
        if(this.props.user.isAdmin) 
            return "";
        else 
            return "";
    }

    deleteTag(tag) {
        db.deleteTag(tag.id)

        var tags = this.state.tags;
        for(var i = 0; i < tags.length; i++) {
            if(tags[i].id === tag.id) {
                tags.splice(i,1);
                break;
            }
        }

        this.setState({tags: tags});
    }

    className() {
        return "nc-icon " + this.props.icon;
    }

    placeholder() {
        if(this.state.selected.length > 0)
            return "";
        else if(this.props.placeholder)
            return this.props.placeholder;
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#777777"
        }
        const detailsIcon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#ababab",
            cursor:"pointer"
        }

        return (
            <div> 

                <div style={{border:"solid 1px #dedede", borderRadius:"4px", paddingTop:"3px", paddingLeft:"10px", paddingBottom:"6px", width:"100%", minHeight:"41px", display:"flex", flexWrap:"nowrap" }}>
                    <div style={{display:"flex", paddingTop:"8px", width:"21px"}}>
                        <i style={icon} className={this.className()} />
                    </div>
                    <div style={{display:"flex", flexWrap:"wrap", marginLeft:"8px", paddingTop:"6px", width:"calc(100% - 54px)"}}>
                        
                        {this.state.selected.map((tag, index) => (
                            <div key={index} style={{marginRight:"8px", marginBottom:"4px", display:"flex"}}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px"}} >{tag.name} <span style={{cursor:"pointer", color:"#dedede", fontSize:"10px", marginLeft:"10px"}} onClick={() => this.removeTag(tag)}>x</span></Badge>
                            </div>
                        ))} 
                        <div style={{display:"flex"}}>
                            <input type="text" style={{border:"0px", outline:"none", padding:"0px", fontSize:"11px", width:"50px", backgroundColor:"#ffffff00"}} 
                                value={this.state.searchText} placeholder={this.placeholder()} 
                                ref={this.textInput}
                                onChange={(e) => this.updateSearchText(e.target.value)} onKeyDown={(e) => this.keystroke(e)} />
                        </div>
                    </div>
                    <div style={{display:"flex", paddingTop:"8px", width:"21px"}} onClick={() => this.toggleModal()}>
                        <i style={detailsIcon} className="nc-icon nc-zoom-split" />
                    </div>
                </div>

                <div style={{display:this.showPotentialMatches(), paddingTop:"6px"}}>
                    {this.state.potentialMatches.map((tag, index) => (
                        <div key={index} style={{marginLeft:"8px", display:"inline-block"}}>
                            <Badge color={tag.color} pill style={{marginBottom:"0px", cursor:"pointer"}} 
                                onClick={() => this.selectTag(tag)}
                                >{tag.name}</Badge>
                        </div>
                    ))}
                </div>

                <Modal isOpen={this.state.modal} size="md" toggle={() => this.toggleModal()} >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Tags
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.tags.map((tag, index) => (
                        <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px"}}>
                            <Col xs={1}>
                                <CustomInput type="switch" id={index} name="customSwitch"
                                    checked={this.getSelected(tag.id)} onChange={(e) => this.toggleSelect(tag, e.target.checked)}
                                />
                            </Col>
                            <Col xs={10}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px", cursor:"pointer"}} 
                                    >{tag.name}</Badge>
                            </Col>
                            <Col xs={1} style={{display:this.showDeleteTag(), paddingTop:"4px"}} >
                                <ConfirmDialog 
                                    title="delete tag" 
                                    header="CONFIRM" 
                                    description="Are you sure you want to delete this tag? This action cannot be undone." 
                                    confirmed={() => this.deleteTag(tag)} />
                            </Col>
                        </Row>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Col xs="12" style={{textAlign:"right"}}>
                            <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                Close
                            </Button>
                        </Col>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Tag = connect(mapStateToProps)(Tag);
export default Tag;

