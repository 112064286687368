import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Card,
  CardBody,
} from 'reactstrap'

const chartColor = '#FFFFFF'
const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}


export default function LineSummaryChart({
  summary, label, lineColor, chartHeading,
}) {
  const lineSummaryChart = {
    data: (canvas) => {
      const ctx = canvas.getContext('2d')

      const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
      gradientStroke.addColorStop(0, '#18ce0f')
      gradientStroke.addColorStop(1, chartColor)

      const gradientFill = ctx.createLinearGradient(0, 170, 0, 50)
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)')
      gradientFill.addColorStop(1, hexToRGB('#18ce0f', 0.4))

      return {
        labels: summary.labels,
        datasets: [
          {
            label,
            borderColor: lineColor,
            pointHoverRadius: 0,
            pointRadius: 0,
            fill: false,
            backgroundColor: gradientFill,
            borderWidth: 3,
            barPercentage: 1,
            data: summary.data,
          },
        ],
      }
    },
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        intersect: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: '#9f9f9f',
              beginAtZero: false,
              maxTicksLimit: 5,
            },
            gridLines: {
              drawBorder: false,
              zeroLineColor: 'transparent',
              color: 'rgba(255,255,255,0.05)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: 'transparent',
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: '#9f9f9f',
            },
          },
        ],
      },
    },
  }

  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          {chartHeading}
        </h6>
        <Line
          data={lineSummaryChart.data}
          options={lineSummaryChart.options}
          height={380}
          width={828}
        />
      </CardBody>
    </Card>
  )
}
