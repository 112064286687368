
import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected} from 'redux/actions';

//utils
import { presence, telemetry } from 'assets/utils';

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from "react-router-dom";
import Navbar from "components/Navbars/GoCodeNavbar";
//import GoCodeAPIs from "views/goCode/GoCodeAPIs.js";
import GoCodeStandards from "views/goCode/GoCodeStandards.js";
import Synapse from "views/goCode/Synapse.js";


var ps;

class GoCodeLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      searchQuery: "",
      view: this.props.view 
    };

    this.searchText = this.searchText.bind(this);
    document.title = 'GoTo Developer Platform';
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      //ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "gdp", "pageView").then(activity => {
      setInterval(() => telemetry.trackDwellTime(activity),60000);
    });

    this.getPresence();
    setInterval(() => this.getPresence(),60000);
    
  }

  getPresence() {
    presence.getOthersPresent(this.props.user.personID, 'gdp').then(result => {
      this.setState({othersPresent: result.people, totalOthers: result.totalOthers});
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if(e.view !== this.props.view) {
      this.setState({view: this.props.view});
    }
    
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  searchText(query) {
    this.setState({searchQuery: query});
  }

  showAPIs() {
    if(this.state.view === "apis")
      return "block";
    else
      return "none";
  }
  
  standards() {
    if(this.state.view === 'standards')
      return <div style={{marginTop:"50px", padding:"10px"}}>
                <GoCodeStandards 
                  standardID={this.props.match.params["standardID"]}/>
            </div>
  }

  synapse() {
    if(this.state.view === 'synapse')
      return <div style={{marginTop:"63px"}}>
                <Synapse />
            </div>  
  }

  

  render() {

    if (!this.props.user.authenticated) {
      this.props.onUserRedirected('/' + this.state.view);
      return <Redirect to='/login' />
    }

    return (
      <div className="wrapper" style={{backgroundColor:"#efefef"}}>
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick} 
            onSearch={this.searchText} view={this.state.view}
            searchQuery={this.state.searchQuery}
            onAPIViewClick={() => this.setState({view:"apis", searchQuery:""})}
            onStandardsViewClick={() => this.setState({view:"standards", searchQuery:""})}
            othersPresent={this.state.othersPresent} 
            totalOthers={this.state.totalOthers}
          />
        <div style={{marginTop:"63px", display:this.showAPIs()}}>
            {/*<GoCodeAPIs />*/}
        </div>
        {this.synapse()}
        {this.standards()}
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      onUserRedirected: UserRedirected
       }, dispatch);  
}

GoCodeLayout = connect(mapStateToProps, mapDispatchToProps)(GoCodeLayout);
export default GoCodeLayout;
