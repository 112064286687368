import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import categoryList from "variables/appreciateCategory.js";
// reactstrap components
import {
    Row, Col, Input
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import { telemetry } from "assets/utils";

import logo from "assets/img/goConnectIcon.png";

class KudosWall extends React.Component {
    constructor() {

        super();
        const categoryByID = {};
        categoryList.forEach(element => {
            categoryByID[element.id] = element;
        });

        const categoryByTitle = {}; 
        categoryList.forEach(element => {
            categoryByTitle[element.title] = element;
        });

        this.state =
        {
            isLoading: true,
            categoryByID: categoryByID,
            categoryByTitle: categoryByTitle,
            openPersonSearch: false,
            isCommentModalOpen: false,

            isNotificationModalOpen: false,

            appreciationList: [],
            appreciationListLength : 0,
            kudosList: [],

            categoryName: "All"
        }

        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    componentDidMount() {
  
        //Log user activity for Kudos Wall Navbar
        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "KudosWall", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity), 60000);
        });

        db.getRecentAppreciations().then((res) => {

            try {

                Promise.all(res.map(async (i, index) => {

                    res[index].recipient = await db.getPersonForKudos(i.recipientID);
                    res[index].sender = await db.getPersonForKudos(i.appreciatorID);

                    return;
                }))
                .then(() => {

                    this.setState({ appreciationList: res, isLoading: false });
                })
               
            }
            catch (err) {
                console.log(err);
            }
        });
    }


    handleCommentChange(e) {
        this.setState({
            comment: e.target.value
        })
    }

    updateFilterCategory(value) {

        this.setState({ categoryName: value });

        if (value !== "All") {

            db.getKudosByCategory(this.state.categoryByTitle[value].id).then((res) => {

                try {

                    Promise.all(res.map(async (i, index) => {

                        res[index].recipient = await db.getPersonForKudos(i.recipientID);
                        res[index].sender = await db.getPersonForKudos(i.appreciatorID);

                        return;
                    }))
                    .then(() => {

                        this.setState({ appreciationList: res, isLoading: false });
                    })
                    
                }
                catch (err) {
                    console.log(err);
                }

            });
        }
        else {
            
            db.getRecentAppreciations().then((res) => {

                try {

                    Promise.all(res.map(async (i, index) => {

                        res[index].recipient = await db.getPersonForKudos(i.recipientID);
                        res[index].sender = await db.getPersonForKudos(i.appreciatorID);

                        return;
                    }))
                    .then(() => {

                        this.setState({ appreciationList: res, isLoading: false  });
                    })

                }
                catch (err) {
                    console.log(err);
                }

            });
        }
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (

            <div style={{ padding: "20px" }}>

                <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                <Row>
                    <Col xs="12">
                        <div style={loadingDiv}>
                            <div className="spin">
                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{display:this.showResults()}}>
                    <Row>
                        <Col xs="2">
                            <Input placeholder=""
                                type="select"
                                value={this.state.categoryName} onChange={(e) => this.updateFilterCategory(e.target.value)} >
                                <option value="All">All</option>
                                <option value="It's Not About You">It's Not About You</option>
                                <option value="Stand Up For What You Believe In">Stand Up For What You Believe In</option>
                                <option value="Collaborate With Compassion">Collaborate With Compassion</option>
                                <option value="Be Fast And Fearless">Be Fast And Fearless</option>
                                <option value="Earn Your Title">Earn Your Title</option>
                                <option value="Become A Scientist">Become A Scientist</option>
                                <option value="Always Be Prepared">Always Be Prepared</option>
                                <option value="Criticism Is A Gift">Criticism Is A Gift</option>
                                <option value="Shoot For Greatness">Shoot For Greatness</option>
                                <option value="Put Customers First">Put Customers First</option>
                                <option value="Empower the Small">Empower the Small</option>
                                <option value="Communicate With Purpose">Communicate With Purpose</option>
                            </Input>
                        </Col>
                    </Row>

                    <Row>

                        {this.state.appreciationList.map((appreciationObject, index) => {

                            if (appreciationObject.recipient && appreciationObject.sender)
                            {
                            
                                return (

                                    <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={2} key={index}>
                                        <div style={{ overflowX: "auto", overflowY: "hidden" }}>

                                            <div style={{ display: "inline-block", verticalAlign: "top", paddingLeft:"5px", marginTop:"10px", marginBottom: "20px" }} key={index}>

                                                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                                                    <PersonCard person={appreciationObject.recipient} />
                                                </div>
                                                <div style={{ display: "inline-block", marginLeft: "8px", padding: "10px", width: "170px" }}>
                                                    <div style={{ color: "green", fontWeight: 600 }}>
                                                        {this.state.categoryByID[appreciationObject.category].title}
                                                    </div>
                                                    <div style={{ fontSize: "11px", maxWidth: "123px", whiteSpace: "pre-wrap" }}>
                                                        {appreciationObject.comment}
                                                    </div>
                                                    <div style={{ marginTop: "24px" }}>
                                                        <div style={{ display: "inline-block", verticalAlign: "top" }}>
                                                            <PersonCard person={appreciationObject.sender} showThumb />
                                                        </div>
                                                        
                                                        <div style={{ display: "inline-block", verticalAlign: "top", marginLeft: "8px", fontSize: "12px", marginTop: "3px", maxWidth: "123px", whiteSpace: "pre-wrap" }}>
                                                            {appreciationObject.sender.name}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                
                                )
                            } 
                        })}
                    </Row>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

KudosWall = connect(mapStateToProps)(KudosWall);
export default KudosWall;