import React from "react";

//redux
import { connect } from 'react-redux'

//
import { db } from 'assets/firebase';

import { slack, telemetry } from 'assets/utils';

// reactstrap components
import {
        Button, Input, CustomInput,
        Card, CardBody,
        Row, Col, 
        Modal, ModalBody, ModalHeader, ModalFooter, 
        Nav, NavItem, NavLink, TabContent, TabPane
    } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard";

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate()) + ' ' + time;
}

class ActivityCard extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
          modal: false,
          activity: {
              authorID: this.props.user.person.id,
              person: this.props.user.person,
              manager: {
                  name: ''
              },
              shareWithEmployee: false,
            },
          personID: this.props.user.person.id,
          person: this.props.user.person,
          activeTab: 'achievement',
          directReports: [],
          cycle: '22h1',
          achievement: '',
          feedback: '',
          note: '',
          checkin: ''
        };

        this.toggleModal = this.toggleModal.bind(this);

        if(this.props.activity) {
            
            this.state.activity = this.props.activity;
            db.getPerson(this.props.activity.managerID).then(manager => {
                this.state.activity.manager = manager;
            });
            this.state.activeTab = this.state.activity.type;

            console.log(this.state.activity)


        } else {
            db.getPerson(this.props.user.person.managerID).then(manager => {
                this.state.activity.manager = manager;
            })
        }
        if(this.props.directReports) this.state.directReports = this.props.directReports;
    }

    componentDidUpdate(prevProps) {

        if(prevProps.directReports !== this.props.directReports) {
            this.setState({directReports: this.props.directReports});
        }
        else if (prevProps.activity !== this.props.activity) {
            this.setState({activity: this.props.activity}, () => {

            });
        }       
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    chooseEmployee(personID) {
        var activity = this.state.activity;
        for(var i = 0; i < this.state.directReports.length; i++) {
            if(this.state.directReports[i].id === personID) {
                activity.person = this.state.directReports[i];
                this.setState({personID: personID, person: this.state.directReports[i], activity: activity});
                break;
            }
        }
    }

    addActivity() {

        var activity = this.state.activity;

        activity.type = this.state.activeTab;
        activity.cycle = this.state.cycle;
        activity.created = new Date().getTime();
        activity.createdBy= this.props.user.person.name;
        
        if(this.state.directReports.length > 0) { // MANAGER VIEW

            activity.personID = this.state.person.id;
            activity.managerID = this.state.person.managerID;

        } else { // EMPLOYEE VIEW

            if(this.state.activeTab === 'feedback') {

                activity.person = this.state.activity.manager;
                activity.personID = this.state.person.managerID;
                activity.managerID = this.state.activity.manager.managerID;
            }
            else {

                activity.personID = this.props.user.person.id;
                activity.managerID = this.props.user.person.managerID;
            }
        }

        //if(this.state.activeTab === 'achievement')
          //  activity.achievement = this.state.achievement;
        //else if(this.state.activeTab === 'feedback')
            //activity.feedback = this.state.feedback;
       // else if(this.state.activeTab === 'goal')
         //   activity.goal = this.state.goal;
        //else if(this.state.activeTab === 'note')
          //  activity.note = this.state.note;
        //else if(this.state.activeTab === 'checkin') {
          //  activity.checkin = this.state.checkin;
        //    activity.trackingTo = this.state.trackingTo;
       // }
       // else if (this.state.activeTab === 'learning') {
         //   activity.learningNotes = this.state.learningNotes;
         //   activity.timeSpent = this.state.timeSpent;
       // }

        var person = JSON.stringify(activity.person);
        var author = JSON.stringify(activity.author);
        var manager = JSON.stringify(activity.manager);

        delete activity.person;
        delete activity.author;
        delete activity.manager;
        
        db.updatePersonActivity(activity.id, activity).then((result) => {

            activity.id = result.id;

            if(person)
                activity.person = JSON.parse(person);

            if(author)
                activity.author = JSON.parse(author);

            if(manager)
                activity.manager = JSON.parse(manager);

            this.props.onCreated(activity);
            
            if(activity.shareWithEmployee) { //

                var slackID = this.state.activity.manager.slackID; // if someone logs their own activity
                if(this.state.activity.personID !== this.state.activity.authorID) // if someone is writing about their manager/employee
                    slackID = this.state.activity.person.slackID;

               // slackID = 'U01C68P5D4P'; // testing

                this.sendSlackMessage(slackID);
            }

            this.setState({
                activity: {
                    authorID: this.props.user.person.id,
                    person: this.props.user.person,
                    manager: {
                        name: ''
                    },
                    shareWithEmployee: false
                },
                achievement: '',
                feedback: '',
                note: '',
                checkin: '',
                learningNotes: ''
            })

            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "personActivity", "created");

            this.toggleModal();
        })
    }

    updateActivity(property, value) {
        
        var obj = this.state.activity;
        
        if(property === 'shareWithEmployee')
            obj.shareWithEmployee = !obj.shareWithEmployee;
        else 
            obj[property] = value;

        this.setState({activity: obj});
    }

    sendSlackMessage(slackID) {

        var text = this.props.user.person.name + " has added an activity on their career website.";
        if(this.state.activity.personID !== this.state.activity.authorID) 
            text = this.props.user.person.name + " has added an activity to your career website.";
        
        var blocks = [
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": text + "\n\nClick <https://www.gocnnct.com/career|here> to see it."
                    }
                },
            ]
                    
        slack.sendMessage(slackID, text, blocks);
    }

    toggleShare() {

        var activity = this.state.activity;

        var person = JSON.stringify(activity.person);
        var author = JSON.stringify(activity.author);
        var manager = JSON.stringify(activity.manager);

        delete activity.person;
        delete activity.author;
        delete activity.manager;

        activity.shareWithEmployee = !activity.shareWithEmployee;

        db.updatePersonActivity(activity.id, activity).then(() => {

            if(activity.shareWithEmployee) {
                this.sendSlackMessage();
            }

            if(person)
                activity.person = JSON.parse(person);

            if(author)
                activity.author = JSON.parse(author);

            if(manager)
                activity.manager = JSON.parse(manager);

            this.setState({activity: activity});
        });
       
    }

    changeTab(tab) {

        this.setState({activeTab: tab}, () => {
            
        });
    }

    getDescription() {

        if(this.props.activity) {
            if(this.state.activity.type === 'achievement')
                return this.state.activity.achievement;
            else if(this.state.activity.type === 'feedback')
                return this.state.activity.feedback;
            else if(this.state.activity.type === 'goal')
                return this.state.activity.goal;
            else if(this.state.activity.type === 'note')
                return this.state.activity.note;
            else if(this.state.activity.type === 'checkin')
                return this.state.activity.checkin;
            else if(this.state.activity.type === 'learning')
                return this.state.activity.learningNotes;
        }
    }

    getTypeDescriptor() {
        if(this.props.activity) {
            if(this.state.activity.type === 'achievement')
                return "ACHIEVEMENT";
            else if(this.state.activity.type === 'feedback')
                return "FEEDBACK";
            else if(this.state.activity.type === 'goal')
                return "GOAL";
            else if(this.state.activity.type === 'note')
                return "NOTE";
            else if(this.state.activity.type === 'checkin')
                return "CHECK-IN";
            else if(this.state.activity.type === 'learning')
                return "LEARNING";
        }
    }

    getAttribution() {
        
        if(this.state.activity.author && this.state.activity.author.id !== this.state.activity.person.id) {

            return <div style={{marginTop:"15px", fontSize:"11px", color:"#666"}} >
                        <div style={{display:"inline-block", marginRight:"10px"}}>
                            {this.authorCard(this.state.activity.author)}
                        </div>
                        <div style={{display:"inline-block"}}>
                            <div style={{color:"green", fontWeight:600}}>
                                {this.personName(this.state.activity.author)}
                            </div>
                            {getFormattedDate(this.state.activity.created)}
                        </div>
                    </div>
        } else {
            return <div style={{marginTop:"15px", fontSize:"11px", color:"#666"}} >
                        {getFormattedDate(this.state.activity.created)}
                    </div>
        }
    }

    personCard() {
        if(this.state.activity.person)
            return <PersonCard person={this.state.activity.person} showLargeThumb />
    }
    authorCard(person) {
        if(person)
            return <PersonCard person={person} showThumb />
    }

    showAuthor(person) {
        if(person.id !== this.props.user.person.id)
            return "block";
        else 
            return "none";
    }

    personName(person) {
        if(person)
            return person.name;
    }

    showButtons() {
        if(this.props.activity)
            return "none";
        else
            return "block";
    }

    showCard() {
        if(this.props.activity)
            return "block";
        else
            return "none";
    }

    showTrackingTo() {
        if(this.props.activity) {
            if(this.props.activity.trackingTo)
                return "block";
            else
                return "none";
        }
        else return "none";
    }
    showTimeSpent() {
        if(this.props.activity) {
            if(this.props.activity.timeSpent)
                return "block";
            else
                return "none";
        }
        else return "none";
    }

    employeeCard() {
        if(this.state.person)
        return (
            <PersonCard person={this.state.person} showThumb />
        )
    }

    showInEmployeeView() {
        if(this.state.directReports.length === 0)
            return "block";
        else
            return "none";
    }
    showInManagerView() {
        if(this.state.directReports.length > 0)
            return "block";
        else
            return "none";
    }
    showInAuthorView() {
        if(this.state.activity.authorID === this.props.user.person.id)
            return "block";
        else
            return "none";
    }
    showInReaderView() {
        if(this.state.activity.authorID !== this.props.user.person.id)
            return "block";
        else
            return "none";
    }
    getSharedToName() {
       
        if(this.state.activity.authorID === this.props.user.person.id) { // if the viewer wrote it

            if(this.state.activity.authorID === this.state.activity.person.id) { // if they wrote it about themselves
                return this.state.activity.manager.name;
            } else { // if they wrote it about someone else
                return this.state.activity.person.name;
            }
        } else {
            return ""
        }
    }
    showShareButton() {
        if(this.state.activity.authorID === this.props.user.person.id && !this.state.activity.shareWithEmployee)
            return "block";
        else
            return "none";
    }

    editActivity() {
        this.setState({modal: true});

    }

  render() {

    const link = {
        color:"#434343",
        fontWeight:500,
        fontSize:"12px",
        cursor:"pointer"
    }
    const activeLink = {
        color:"white",
        fontWeight:700,
        fontSize:"12px"
    }
    const activeTab = {
        backgroundColor:'green',
        borderStyle: 'none',
        borderWidth: '1px 1px 0px 1px',
        borderColor: 'green'
    }
    const tab = {
        backgroundColor:"#efefef",
        marginLeft:"1px",   
    }

    return (
        
        <div>
        
            <Card style={{backgroundColor:"#efefef", border:"solid 0.8px #888888", cursor:"pointer", display:this.showCard()}} onClick={() => this.editActivity()}> 

                <div style={{backgroundColor:"#666", borderTopLeftRadius:"10px", borderTopRightRadius:"9px", fontWeight:600, fontSize:"10px", color:"#ffffff", padding:"6px", paddingLeft:"15px"}}>
                    {this.getTypeDescriptor()}
                </div>

                <CardBody style={{whiteSpace:"nowrap"}} >
                    
                    <div style={{display:"inline-block", marginRight:"17px", verticalAlign:"top"}}>
                        {this.personCard()}
                    </div>

                    <div style={{display:"inline-block", paddingBottom:"10px", fontSize:"12px", paddingRight:"80px", whiteSpace:"normal"}}>
                        
                        <div style={{fontSize:"12px", fontWeight:"600"}}>
                            {this.personName(this.state.activity.person)}
                        </div>

                        <div style={{display:this.showTrackingTo(), paddingBottom:"5px", fontSize:"10px", fontWeight:600, color:"green"}}>
                            TRACKING TO: {this.state.activity.trackingTo}
                        </div>

                        <div style={{display:this.showTimeSpent(), paddingBottom:"5px", fontSize:"10px", fontWeight:600, color:"green"}}>
                            TIME SPENT: {this.state.activity.timeSpent}
                        </div>

                        {this.getDescription()}
                        {this.getAttribution()}

                        
                        <div style={{display:this.showShareButton(), marginTop:"10px", fontSize:"11px", color:"#343434"}}>
                            <Button size="sm" color="secondary" onClick={() => this.toggleShare()}>Share with {this.getSharedToName()}</Button>
                        </div> 
                    </div>
                       
                </CardBody>
            </Card>

            <div style={{display:this.showButtons()}}>
                <Button size="sm" color="secondary" onClick={this.toggleModal}>
                    New Activity
                </Button>
            </div>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                <ModalHeader>
                    <Row>
                        <Col sm="12" style={{textAlign:"left", fontSize:"19px"}}>
                            New Activity
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{paddingLeft:"23px", paddingRight:"23px"}}>
                    <Row>
                        <Col xs="12">
                            <Nav style={{marginTop:"10px"}}>
                                <NavItem style={(this.state.activeTab === 'achievement' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'achievement' ? activeLink : link )}
                                        onClick={() => this.changeTab('achievement') }
                                    >
                                        Achievement
                                    </NavLink>
                                </NavItem>
                                <NavItem style={(this.state.activeTab === 'checkin' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'checkin' ? activeLink : link )}
                                        onClick={() => this.changeTab('checkin') }
                                    >
                                        Check-In
                                    </NavLink>
                                </NavItem>
                                <NavItem style={(this.state.activeTab === 'feedback' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'feedback' ? activeLink : link )}
                                        onClick={() => this.changeTab('feedback') }
                                    >
                                        Feedback
                                    </NavLink>
                                </NavItem>
                                <NavItem style={(this.state.activeTab === 'goal' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'goal' ? activeLink : link )}
                                        onClick={() => this.changeTab('goal') }
                                    >
                                        Goal
                                    </NavLink>
                                </NavItem>
                                <NavItem style={(this.state.activeTab === 'learning' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'learning' ? activeLink : link )}
                                        onClick={() => this.changeTab('learning') }
                                    >
                                        Learning 
                                    </NavLink>
                                </NavItem>
                                <NavItem style={(this.state.activeTab === 'note' ? activeTab : tab )}>
                                    <NavLink
                                        style={(this.state.activeTab === 'note' ? activeLink : link )}
                                        onClick={() => this.changeTab('note') }
                                    >
                                        Note
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <Row style={{padding:"10px", paddingTop:"20px", margin:"0px", marginBottom:"10px", borderStyle: 'solid', borderWidth: '1px 1px 1px 1px', borderColor: 'green'}}>
                                <Col xs="12">
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="achievement" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Keep track of employee achievements throughout the year, so that 
                                                    when the review cycle starts you have the evidence you need to 
                                                    get your employee the review they want. 
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Make note of your contributions along the way so when it's time for 
                                                    reviews your manager will be well equipped to help you get the review you want.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            
                                                            <option value="21h2">2021 H2</option>
                                                            <option value="22h1">2022 H1</option>
                                                            <option value="22h2">2022 H2</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    Description<br />
                                                    <Input placeholder="describe what was achieved" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.activity.achievement} onChange={(e) => this.setState({achievement: e.target.value})} />
                                                </Col> 
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="checkin" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    It's important to periodically touch base with employees about how they're doing.
                                                    To make sure you are both clear, ask them what score they think they're tracking to
                                                    and tell them how you would rate them today if you had to.
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    If you're not sure how you're doing, you can request a check-in with your manager.
                                                    To make sure you're both clear, tell them what score you think you are tracking to
                                                    so you can see if you're both on the same page.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            <option value="21h2">2021 H2</option>
                                                            <option value="22h1">2022 H1</option>
                                                            <option value="22h2">2022 H2</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">    
                                                    Tracking To<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.trackingTo} onChange={(e) => this.setState({"trackingTo": e.target.value})} >
                                                            <option value=""></option>
                                                            <option value="5">5</option>
                                                            <option value="4">4</option>
                                                            <option value="3">3</option>
                                                            <option value="2">2</option>
                                                            <option value="1">1</option>
                                                    </Input>
                                                </Col> 
                                            
                                                <Col xs="10">    
                                                    Description<br />
                                                    <Input placeholder="why is this the score?" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.activity.checkin} onChange={(e) => this.setState({checkin: e.target.value})} />
                                                </Col> 
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="feedback" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Feedback is most helpful when it's delivered quickly. You can share your feedback 
                                                    immediately (e.g. for a quick positive note) or keep the note private 
                                                    until you have an opportunity to discuss it during your next one-on-one meeting. 
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Managers value getting feedback too! You can share feedback with your manager 
                                                    immediately (e.g. for a quick positive note) or keep the note private 
                                                    until you have an opportunity to discuss it during your next one-on-one meeting. 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            <option value="21h2">2021 H2</option>
                                                            <option value="22h1">2022 H1</option>
                                                            <option value="22h2">2022 H2</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    Description<br />
                                                    <Input placeholder="what is your feedback" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.activity.feedback} onChange={(e) => this.setState({feedback: e.target.value})} />
                                                </Col> 
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="goal" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Feedback is most helpful when it's delivered quickly. You can share your feedback 
                                                    immediately (e.g. for a quick positive note) or keep the note private 
                                                    until you have an opportunity to discuss it during your next one-on-one meeting. 
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Your goals should be as specific, vivid, colorful and exciting as possible. 
                                                    Think big and outside the box. They should be motivational while remaining achievable.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            <option value="22h1">2022 H1</option>
                                                            <option value="22h2">2022 H2</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    Description<br />
                                                    <Input placeholder="format: an action you took which led to a specific result" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.activity.goal} onChange={(e) => this.setState({goal: e.target.value})} />
                                                </Col> 
                                            </Row>
                                            <Row>
                                                <Col xs="12" style={{fontWeight:500, marginTop:"20px", marginBottom:"20px"}}>
                                                    Write your goals to look like the best bullets you have ever seen on a resume. 
                                                    They should be written in the past tense and they describe the impact that was made, 
                                                    not the tasks that were completed. The idea is to visualize what success looks like 
                                                    in as much detail as possible. Not only does this help clarify expectations but it 
                                                    often helps inform aspects of the plan.
                                                </Col>
                                            </Row>
                                            
                                        </TabPane>
                                        <TabPane tabId="learning" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Encourage your employees to set aside time out of work to learn new things and develop new skills.
                                                    If you've arranged a training event, you can help keep their learning journal up-to-date by adding 
                                                    activities here.
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    We want you to make time out of work to learn new things and develop your skills.
                                                    Keep a learning journal of time you spend taking courses, reading books, attending formal training and so on.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            <option value="21h1">2021 H1</option>
                                                            <option value="21h2">2021 H2</option>
                                                            <option value="22h1">2022 H1</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="4">
                                                    Resource<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.resource} onChange={(e) => this.setState({"resource": e.target.value})} >
                                                            <option value="book">Book</option>
                                                            <option value="coaching">Coaching</option>
                                                            <option value="documentary">Documentary</option>
                                                            <option value="english">English Course</option>
                                                            <option value="goLead">GoLead</option>
                                                            <option value="linkedIn">LinkedIn Learning</option>
                                                            <option value="otherExternal">Other External Resource</option>
                                                            <option value="otherInternal">Other Internal Resource</option>
                                                            <option value="podcast">Podcast</option>
                                                            <option value="youtube">YouTube</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    Date<br />
                                                    <Input
                                                        type="date"
                                                        placeholder=""
                                                        value={this.state.activity.learningDate}
                                                        onChange={(e) => this.setState( {"learningDate": e.target.value} )}
                                                        style={{height:"30px", fontSize:"11px"}}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="4">
                                                    Time Spent<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.activity.timeSpent} onChange={(e) => this.setState({"timeSpent": e.target.value})} >
                                                            <option value="15min">15 Minutes</option>
                                                            <option value="30min">30 Minutes</option>
                                                            <option value="45min">45 Minutes</option>
                                                            <option value="1hour">1 Hour</option>
                                                            <option value="2hours">2 Hours</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                                
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    Description<br />
                                                    <Input placeholder="" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.activity.learningNotes} onChange={(e) => this.setState({learningNotes: e.target.value})} />
                                                    <br />
                                                </Col> 
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="note" style={{minHeight:"240px", paddingTop:"0px", fontSize:"12px"}}>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Sometimes there are things you want to remember about employees 
                                                    or perhaps make sure future managers are aware of. Make those notes 
                                                    here and optionallly share them with your employee.
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Sometimes there are things you want to make sure your manager is aware of. 
                                                    You can make those notes here but they won't see them until you share them. 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6">
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.cycle} onChange={(e) => this.setState({"cycle": e.target.value})} >
                                                            <option value="21h1">2021 H1</option>
                                                            <option value="21h2">2021 H2</option>
                                                            <option value="22h1">2022 H1</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    Description<br />
                                                    <Input placeholder="" 
                                                        type="textarea" style={{lineHeight:"14px", minHeight:"64px", padding:"10px", fontSize:"11px"}} 
                                                        value={this.state.note} onChange={(e) => this.setState({note: e.target.value})} />
                                                </Col> 
                                            </Row>
                                        </TabPane>
                                    </TabContent> 
                                </Col> 
                            </Row>             
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row style={{width:"100%", paddingLeft:"10px", paddingRight:"10px"}}>
                        <Col xs="6" >
                            <div style={{fontSize:"11px", color:"#434343", marginTop:"10px"}} 
                                title="Recipient will be notified via Slack and they will see this activity on their dashboard.">
                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                    <CustomInput type="switch" id="shareWithEmployee" name="customSwitch"
                                        checked={this.state.activity.shareWithEmployee} onChange={(e) => this.updateActivity("shareWithEmployee", e.target.value)}
                                        />
                                </div>
                                <div style={{display:"inline-block", verticalAlign:"middle"}} >
                                    Share with {this.getSharedToName()}
                                </div>
                            </div>
                        </Col>
                        <Col xs="6" style={{textAlign:"right"}}>
                            <div style={{display:"inline-block", marginRight:"10px"}}>
                                <Button size="sm" color="secondary" onClick={() => this.toggleModal()} >Cancel</Button>
                            </div>
                            <div style={{display:"inline-block"}}>
                                <Button size="sm" color="success" onClick={() => this.addActivity()} >Save</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        
        </div>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

ActivityCard = connect(mapStateToProps)(ActivityCard);
export default ActivityCard;