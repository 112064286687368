import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'

const options = {
  responsive: true,
  legend: {
    display: true,
  },
  tooltips: {
    enabled: true,
    intersect: false,
    callbacks: {
      title(tooltipItems) {
        return `${tooltipItems[0].xLabel} SLA Adherence`
      },
      label(tooltipItems, data) {
        return `${data.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel}%`
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: 'linear',
      from: 0,
      to: 1,
      loop: true,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: '#9f9f9f',
          beginAtZero: false,
          maxTicksLimit: 5,
          callback(value) {
            return `${value}%`
          },
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: 'transparent',
          color: 'rgba(255,255,255,0.05)',
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          zeroLineColor: 'transparent',
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: '#9f9f9f',
        },
      },
    ],
  },
}

export default function SLAResolutionResponseTimeBarGraph({
  labels, timeToResolution, timeToRespond,
}) {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Time to Respond',
        data: timeToRespond,
        backgroundColor: '#ef8156',
      },
      {
        label: 'Time to Resolution',
        data: timeToResolution,
        backgroundColor: 'green',
      },
    ],
  }

  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          Monthly mitigation SLA
        </h6>
        <Bar options={options} data={chartData} />
      </CardBody>
    </Card>
  )
}
