import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import { strings } from '../../assets/utils'
import {
  COLORS,
  getTeamNames, groupSLAResolutionPercentageDataByTeam, groupSLAResponsePercentageDataByTeam,
} from '../nexusReports/utils'

export default function SLAReportByPod({
  issues, teams, labels, team,
}) {
  const SLAResolutionPercentageDataByTeam =
    groupSLAResolutionPercentageDataByTeam(issues, labels)
  const SLAResponsePercentageDataByTeam =
    groupSLAResponsePercentageDataByTeam(issues, labels)

  const ResolutionPercentageDataTeamNames = getTeamNames(SLAResolutionPercentageDataByTeam, teams, team.id)
  const ResponsePercentageDataTeamNames = getTeamNames(SLAResponsePercentageDataByTeam, teams, team.id)

  const getAdherenceColor = (value) => {
    let adherenceColor = COLORS.green

    if (value === 0) {
      adherenceColor = COLORS.grey
    } else if (value < 75) {
      adherenceColor = COLORS.red
    } else if (value >= 75 && value < 90) {
      adherenceColor = COLORS.orange
    }

    return adherenceColor
  }

  return (
    <>
      <Card>
        <CardBody>
          <h6 className="big-title">
            SLA Adherence
            <br />
            <span style={{ fontSize: '10px', color: '#666666' }}>Time To Respond</span>
          </h6>
          <Table striped>
            <thead>
              <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
                <td>Pod</td>
                {labels.map((month) => <td key={month}>{month}</td>)}
              </tr>
            </thead>
            <tbody>
              {Object.keys(ResponsePercentageDataTeamNames).sort().map((teamName) => (
                <tr style={{ fontSize: '11px' }} key={teamName}>
                  <th title={teamName}>{strings.trim(teamName, 21)}</th>
                  {labels.map((month, index) => (
                    <td
                      key={month}
                      style={{ color: getAdherenceColor(ResponsePercentageDataTeamNames[teamName][index]) }}
                    >
                      {ResponsePercentageDataTeamNames[teamName][index]}
                      <span>&#37;</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardBody>
          <h6 className="big-title">
            SLA Adherence
            <br />
            <span style={{ fontSize: '10px', color: '#666666' }}>Time To Resolution</span>
          </h6>
          <Table striped>
            <thead>
              <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
                <td>Pod</td>
                {labels.map((month) => <td key={month}>{month}</td>)}
              </tr>
            </thead>
            <tbody>
              {Object.keys(ResolutionPercentageDataTeamNames).sort().map((teamName) => (
                <tr style={{ fontSize: '11px' }} key={teamName}>
                  <th title={teamName}>{strings.trim(teamName, 21)}</th>
                  {labels.map((month, index) => (
                    <td
                      key={month}
                      style={{ color: getAdherenceColor(ResolutionPercentageDataTeamNames[teamName][index]) }}
                    >
                      {ResolutionPercentageDataTeamNames[teamName][index]}
                      <span>&#37;</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
