import React from "react";

//redux
import { connect } from 'react-redux'

import { db } from 'assets/firebase';
import { telemetry } from 'assets/utils';

import { 
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

import OKRCard from './OKRCard.js';

class OKRDialog extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
          modal: false,
          okrID: props.okrID,
          krID: props.krID,
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.okrID !== this.props.okrID) {
            this.setState({okrID: this.props.okrID, krID: this.props.krID}, this.getOKR);
        }
        if(prevProps.isOpen !== this.props.isOpen) {
            if(this.props.isOpen) {
                this.setState({modal: true});    
            }
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    getOKR() {

        db.getOKR(this.state.okrID).then(okr => {

            if(okr) {

                this.setState({okr: okr}, () => {

                });
            }
            
        })
    }

    getOKRCard() {
        if(this.state.okr) 
            return <OKRCard okr={this.state.okr} highlightKR={this.props.krID}  />
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalHeader>

                    </ModalHeader>
                    <ModalBody style={{paddingTop:"17px", paddingLeft:"43px", paddingRight:"43px", backgroundColor:"#efefef"}}>
                        {this.getOKRCard()}
                    </ModalBody>
                    <ModalFooter style={{paddingRight:"43px"}}>
                        <Button color="secondary" size="sm" onClick={this.toggleModal}>Close</Button>
                    </ModalFooter>
                </Modal>
             </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

OKRDialog = connect(mapStateToProps)(OKRDialog);
export default OKRDialog;