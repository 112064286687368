import React from 'react'
import {
  Card, CardBody, CardHeader, Col, Input, Row,
} from 'reactstrap'

import NoteEditor from '../../NoteEditor/NoteEditor'
import { getHTML, getNoteTypeDescriptor } from '../../utils'
import ProjectAuthorCard from '../ProjectAuthorCard'
import ProjectCardLinks from '../ProjectCardLink'

function NoteRow({
  note, index, showEditButton, onNoteDeleted, onNoteUpdated,
}) {
  const {
    author, category, createdBy, details, links, published, title,
  } = note

  return (
    <Row key={index}>
      <Col sm="12">
        <Card>

          <CardHeader style={{
            height: '28px',
            padding: '5px',
            backgroundColor: 'gray',
            fontWeight: 600,
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
            color: 'white',
            paddingLeft: '20px',
          }}
          >
            <Row>
              <Col xs="8">
                {title}
              </Col>
              <Col xs="4" style={{ textAlign: 'right', paddingRight: '30px', color: '#BDFF90' }}>
                {getNoteTypeDescriptor(category)}
              </Col>
            </Row>

          </CardHeader>

          <CardBody style={{ padding: '20px', fontSize: '13px', fontWeight: 500 }}>

            <div className="ql-editor" style={{ marginTop: '8px', marginBottom: '0px' }}>
              {getHTML(details)}
            </div>

            <ProjectCardLinks links={links} />

            <ProjectAuthorCard
              author={author}
              createdBy={createdBy}
              publishedAt={published}
            />

            {showEditButton && (
              <NoteEditor
                note={note}
                onDeleted={(currNote) => onNoteDeleted(currNote)}
                onUpdated={(currNote) => onNoteUpdated(currNote)}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

function NotesTab({
  projectID, personID, isLoading,
  filterNotes, filteredNotes, noteTypeFilter,
  onNoteCreated, onNoteDeleted, onNoteUpdated,
}) {
  return projectID && (
    <>
      <Row>
        <Col xs="6">
          Category
          <Input
            placeholder=""
            type="select"
            style={{ border: '1px solid #888888', fontSize: '11px' }}
            value={noteTypeFilter}
            onChange={(e) => filterNotes('type', e.target.value)}
          >
            <option value="all">All</option>
            <option value="faq">Frequently Asked Question (FAQ)</option>
            <option value="meeting">Meeting Notes</option>
            <option value="planning">Planning</option>
            <option value="topic">Topic</option>
            <option value="other">Other</option>
          </Input>
          <br />
        </Col>
        <Col xs="6" style={{ textAlign: 'right' }}>
          <NoteEditor projectID={projectID} onCreated={(note) => onNoteCreated(note)} />
        </Col>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Col xs="12">
          <div style={{ minHeight: '89px' }}>
            {!isLoading && (
              <div>
                {filteredNotes.map((note, index) => (
                  <NoteRow
                    note={note}
                    idx={index}
                    showEditButton={note.authorID === personID}
                    onNoteDeleted={onNoteDeleted}
                    onNoteUpdated={onNoteUpdated}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}
export default NotesTab
