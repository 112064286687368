import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { 
    Button, CardTitle,
    Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";

import { slack, telemetry } from 'assets/utils';

const uuid = require('uuid');

class ManagerApproval extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {

        if(!this.state.manager) {
            db.getPerson(this.props.user.person.managerID).then(manager => {
                this.setState({manager: manager});
                console.log('manager Slack ID:' + this.state.manager.slackID);
            })
        }

        this.setState({modal: !this.state.modal});
    }

    handleClose = () => {
        this.setState({ modal: false });
    };
    
    yes = () => {
          this.props.confirmed();
          this.setState({ modal: false });
    }
    
    requestApproval() {
        
        var blocks = [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "_*" + this.props.title + "*_ \n\n"
                } // `
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": this.props.managerText + "\n\n"
                }
            },
            {
                "type": "section",
                text: {
                    "type": "mrkdwn",
                    "text": "<https://www.gocnnct.com/approval/" + this.props.user.personID + "|Approve or Deny>"
                }
            }
        ]

        
        slack.sendMessage(this.state.manager.slackID, this.props.title, blocks);

        var person = this.props.user.person;
        person[this.props.type] = 'requested';
        db.updatePerson(person.id, person);

        this.props.confirmed();
        this.setState({ modal: false });

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "managerApprovalRequested", "pageView");
    }

    

    render() {

        return (
            <div>
                
                <div style={{display:this.showButton}}>
                    <Button color="success" style={{width:"100%"}} onClick={() => this.toggleModal()}>{this.props.buttonText}</Button>
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal}  >
                    <ModalHeader style={{textAlign:"left", padding:"20px", paddingBottom:"0px"}}>
                        <CardTitle >{this.props.title}</CardTitle>
                    </ModalHeader>
                    <ModalBody style={{padding:"20px"}}>
                        <p>{this.props.line1}</p>
                        <p>{this.props.line2}</p>
                        <p>{this.props.line3}</p>
                    </ModalBody>
                    <ModalFooter style={{padding:"10px", paddingRight:"20px"}}>
                        <Button onClick={this.handleClose} color="secondary">Cancel</Button>
                        <Button onClick={() => this.requestApproval()} color="success" autoFocus>Request Manager Approval</Button>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    const mapStateToProps = state => {
        return state;
    }
    
    ManagerApproval = connect(mapStateToProps)(ManagerApproval);
    export default ManagerApproval;
