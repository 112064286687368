import 'react-awesome-lightbox/build/style.css'
import './CollaborationRequestEditor.css'

// import 'filepond/dist/filepond.css'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import React, { useEffect, useState } from 'react'
// import { FilePond, registerPlugin } from 'react-filepond'
import Select from 'react-select'
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'

import { getTeam } from '../../assets/firebase/db'
import PersonCard from '../PersonCard/PersonCard'
import LinkEditor from '../TeamEditor/LinkEditor'
import TeamSearch from '../TeamSearch/TeamSearch'
import { CountryOptions, PriorityOptions } from './constants'

// registerPlugin(FilePondPluginImagePreview)

export default function CollaborationRequestEditor({ onCreated, person, request }) {
  const getDefaultValue = (field, defaultValue) => (request && request.id ? request[field] : defaultValue)

  const [showModal, setShowModal] = useState(false)
  const [openFromTeamSearch, setOpenFromTeamSearch] = useState(false)
  const [openToTeamSearch, setOpenToTeamSearch] = useState(false)

  const [title, setTitle] = useState(getDefaultValue('title', ''))
  const [fromTeam, setFromTeam] = useState({})
  const [toTeam, setToTeam] = useState({})
  const [defaultFromTeam, setDefaultFromTeam] = useState({})
  const [defaultToTeam, setDefaultToTeam] = useState({})
  const [links, setLinks] = useState(getDefaultValue('links', []))
  const [attachments, setAttachments] = useState(getDefaultValue('attachments', []))
  const [description, setDescription] = useState(getDefaultValue('description', ''))
  const [impactAssessment, setImpactAssessment] = useState(getDefaultValue('impactAssessment', ''))
  const [countries, setCountries] = useState(getDefaultValue('countries', []))
  const [targetDate, setTargetDate] = useState('')
  const [created, setCreated] = useState(getDefaultValue('created', -1))
  const [severity, setSeverity] = useState(getDefaultValue('severity', {}))


  useEffect(() => {
    async function fetchData() {
      if (request) {
        const fromTeamInfo = await getTeam(request.fromTeamID)
        const toTeamInfo = await getTeam(request.toTeamID)
        setFromTeam(fromTeamInfo)
        setToTeam(toTeamInfo)
        setDefaultFromTeam(fromTeamInfo)
        setDefaultToTeam(toTeamInfo)

        setTargetDate(dayjs(request.targetDate))
      }
    }
    fetchData()
  }, [])

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const resetFormState = () => {
    setTitle(getDefaultValue('title', ''))
    setLinks(getDefaultValue('links', []))
    setAttachments(getDefaultValue('attachments', []))
    setDescription(getDefaultValue('description', ''))
    setImpactAssessment(getDefaultValue('impactAssessment', ''))
    setCountries(getDefaultValue('countries', []))
    setTargetDate(request ? dayjs(request.targetDate) : '')
    setSeverity(getDefaultValue('severity', {}))
    setFromTeam(defaultFromTeam)
    setToTeam(defaultToTeam)
  }

  const handleCancelButton = () => {
    resetFormState()

    toggleModal()
  }

  const openFromTeamSearchTimeout = () => {
    setOpenFromTeamSearch(true)
    setTimeout(() => {
      setOpenFromTeamSearch(false)
    }, 500)
  }

  const openToTeamSearchTimeout = () => {
    setOpenToTeamSearch(true)
    setTimeout(() => {
      setOpenToTeamSearch(false)
    }, 500)
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
  }

  const handleSubmitRequest = () => {

  }

  return (
    <div>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Button
          color={onCreated ? 'success' : 'secondary'}
          size="sm"
          onClick={() => toggleModal()}
          style={{ margin: 0 }}
        >
          {onCreated ? 'New Request' : 'Edit'}
        </Button>
      </div>

      <Modal isOpen={showModal} size="lg">
        <ModalHeader>
          <Col sm="12" style={{ textAlign: 'left' }}>
            Collaboration Request
          </Col>
        </ModalHeader>
        <ModalBody>
          <Row className="request-editor-row">
            <Col md="6">
              <p className="row-title required">Username</p>
              <div
                style={{
                  border: 'solid 1px #cdcdcd',
                  borderRadius: '4px',
                  paddingTop: '6px',
                  paddingLeft: '10px',
                  width: '100%',
                  height: '38px',
                }}
              >
                <div
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                >
                  <PersonCard person={person} showThumb />
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '15px',
                    verticalAlign: 'middle',
                    color: '#666666',
                  }}
                >
                  {person.name}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="request-editor-block">
            <Col md="12">
              <p className="row-title required">Feature/Task Requirement</p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ width: '50px' }}>
                    <i
                      style={{
                        fontSize: '17px',
                        marginTop: '0px',
                        color: '#000000',
                      }}
                      className="nc-icon nc-badge"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={title}
                  onChange={(e) => {setTitle(e.target.value)}}
                  placeholder="Briefly explain the feature"
                />
              </InputGroup>
            </Col>
          </Row>
          {/* <Row className="request-editor-row">
            <Col xs="6" sm="6" md="6">
              <p className="row-title required">Your Team</p>
              <div
                onClick={() => openFromTeamSearchTimeout()}
                style={{
                  border: 'solid 1px #cdcdcd',
                  borderRadius: '4px',
                  paddingLeft: '10px',
                  width: '100%',
                  height: '38px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'rgb(102, 102, 102)',
                }}
              >
                <i
                  style={{
                    fontSize: '17px',
                  }}
                  className="nc-icon nc-single-02"
                />
                <div
                  style={{
                    marginLeft: '15px',
                  }}
                >
                  {fromTeam.name}
                </div>
                <div style={{ display: 'none' }}>
                  <TeamSearch
                    label="Assign Team"
                    isOpen={openFromTeamSearch}
                    onTeamChosen={(team) => setFromTeam(team)}
                  />
                </div>
              </div>
            </Col>
            <Col xs="6" sm="6" md="6">
              <p className="row-title required">Assigned Team</p>
              <div
                onClick={() => openToTeamSearchTimeout()}
                style={{
                  border: 'solid 1px #cdcdcd',
                  borderRadius: '4px',
                  paddingLeft: '10px',
                  width: '100%',
                  height: '38px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'rgb(102, 102, 102)',
                }}
              >
                <i
                  style={{
                    fontSize: '17px',
                  }}
                  className="nc-icon nc-single-02"
                />
                <div
                  style={{
                    marginLeft: '15px',
                  }}
                >
                  {toTeam.name}
                </div>
                <div style={{ display: 'none' }}>
                  <TeamSearch
                    label="Assign Team"
                    isOpen={openToTeamSearch}
                    onTeamChosen={(team) => setToTeam(team)}
                  />
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className="request-editor-row">
            <Col xs="6">
              <p className="row-title required">Priority</p>
              <Select
                defaultValue=""
                isClearable
                name="severity"
                options={PriorityOptions}
                className="basic-single"
                classNamePrefix="select"
                onChange={(value) => {setSeverity(value)}}
                styles={customStyles}
                placeholder="Select Priority"
                value={severity}
              />
            </Col>
          </Row>
          <Row className="request-editor-block">
            <Col md="12">
              <div className="row-title">
                <p className="required">Description</p>
                <p className="row-subtext"> Background & Problem Statement</p>
              </div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    style={{ width: '50px', verticalAlign: 'top' }}
                  >
                    <i
                      style={{
                        fontSize: '17px',
                        position: 'absolute',
                        top: '20px',
                        color: '#000000',
                      }}
                      className="nc-icon nc-single-copy-04"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="textarea"
                  style={{ minHeight: '200px', lineHeight: '19px' }}
                  value={description}
                  onChange={(e) => {setDescription(e.target.value)}}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="request-editor-block">
            <Col md="12">
              <div className="row-title">
                <p className="required">Impact Assessment</p>
                <p className="row-subtext">
                  Impact Assessment is a means of measuring the potential positive
                  and negative effects of the proposed request.
                </p>
              </div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    style={{ width: '50px', verticalAlign: 'top' }}
                  >
                    <i
                      style={{
                        fontSize: '17px',
                        position: 'absolute',
                        top: '20px',
                        color: '#000000',
                      }}
                      className="nc-icon nc-chart-bar-32"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Let us know the SUCCESS METRICS, BASELINE, IMPROVEMENT EXPECTATIONS
                  and TIMELINE for this improvement.
                  This is critical for us to prioritize this request.
                  Without it we will not be able to consider the request."
                  type="textarea"
                  style={{ minHeight: '200px', lineHeight: '19px' }}
                  value={impactAssessment}
                  onChange={(e) => {setImpactAssessment(e.target.value)}}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="request-editor-row">
            <Col xs="6">
              <p className="row-title">Country</p>
              <Select
                defaultValue=""
                isMulti
                name="country"
                options={CountryOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(value) => {setCountries(value)}}
                styles={customStyles}
                placeholder="Select Country"
                value={countries}
              />
            </Col>
          </Row>
          <Row className="request-editor-row">
            <Col xs="6">
              <p className="row-title">Target Date</p>
              <DatePicker
                style={{
                  width: '100%', height: 38, borderRadius: 5, borderColor: '#cccccc',
                }}
                onChange={(value) => {setTargetDate(value.$d)}}
                className="date-picker"
                format="DD MMMM YYYY"
                placeholder="Select Date"
                disabledDate={(current) => current < new Date()}
                value={targetDate}
              />
            </Col>
          </Row>
          <Row className="request-editor-row">
            <Col xs="12">
              <div className="row-title">
                <p>Links / Attachments</p>
                <p className="row-subtext">
                  File sharing permissions are not handled by Nexus.
                  Kindly grant permissions with your team mates on Google Docs
                </p>
              </div>
              <br />
              <LinkEditor
                links={links}
                user={person}
                setLinks={setLinks}
                updateInitialLinks={() => {}}
                toggleEditMode={() => {}}
                isNexusCollaborationForm
              />
              <br />
              {/* <FilePond
                attachments={attachments}
                onupdatefiles={setAttachments}
                allowMultiple
                maxFiles={3}
                name="attachments"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              /> */}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row style={{ width: '100%' }}>
            <Col sm="12" style={{ textAlign: 'right' }}>
              <Button color="secondary" onClick={() => handleCancelButton()} size="sm">
                Cancel
              </Button>
              <Button color="success" onClick={() => handleSubmitRequest()} size="sm">
                {onCreated ? 'Submit' : 'Save'}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  )
}
