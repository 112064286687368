
import React from "react";

//redux
import { connect } from 'react-redux'

//utils
import { presence, telemetry } from 'assets/utils';

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from "react-router-dom";
import Navbar from "components/Navbars/CleverTapNavbar.js";
import CleverTap from "views/CleverTap.js";

var ps;

class CleverTapLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      othersPresent: []
    };

    document.title = 'GoCode';
  }
  componentDidMount() {
    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "cleverTapEvents", "pageView").then(activity => {
      setInterval(() => telemetry.trackDwellTime(activity),60000);
    });

    this.getPresence();
    setInterval(() => this.getPresence(),60000);
    
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    */
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  
  getPresence() {
    presence.getOthersPresent(this.props.user.personID, 'cleverTapEvents').then(result => {
      this.setState({othersPresent: result.people, totalOthers: result.totalOthers});
    });
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };


  render() {

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <div className="wrapper" style={{backgroundColor:"#efefef"}}>
        <Navbar {...this.props} 
          othersPresent={this.state.othersPresent} 
          totalOthers={this.state.totalOthers}
          />
        <div style={{marginTop:"63px", padding:"34px"}}>
            <CleverTap />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

CleverTapLayout = connect(mapStateToProps)(CleverTapLayout);
export default CleverTapLayout;
