const CRITICAL = 'rgb(234, 82, 82)'
const HIGH = 'rgb(239, 129, 87)'
const LOW = 'rgb(0, 128, 0)'
const MEDIUM = 'rgb(255, 165, 0)'
const IN_PROGRESS = 'rgb(50, 82, 222)'
const DEFAULT = 'rgb(56, 57, 66)'

export const CARE_TEAM_ID = 'Phk8ZD8CkG0MpHPREQta'
export const COMMERCE_ENABLEMENT_TEAM_ID = 'FgC5nG92pi0izoCD9Xfc'
export const CUSTOMER_PLATFORM_TEAM_ID = '9mWNGXqf4YmL4ehh99Bg'
export const ENGINEERING_PLATFORM_TEAM_ID = 'Pbw1c8zHo4M56v1R03Ic'
export const GOJEK_PRODUCT_SECURITY_TEAM_ID = '8K4TNIYyq9HAS4wTGkYQ'
export const GO_CLUB_TEAM_ID = 'Z6RGsU2AtWXoHP9Qp2qF'

export const getPriorityColor = (priority) => {
  const priorityValue = priority.split(':')[0].toLowerCase()
  switch (priorityValue) {
    case 'highest':
      return CRITICAL
    case 'high':
      return HIGH
    case 'medium':
      return MEDIUM
    case 'low':
      return LOW
    default:
      return DEFAULT
  }
}

export const getStatusColor = (status) => {
  switch (status) {
    case 'To Do':
      return CRITICAL
    case 'In Progress':
      return IN_PROGRESS
    case 'Waiting for Information':
      return HIGH
    case 'Done':
      return LOW
    default:
      return DEFAULT
  }
}

export const PriorityOptions = [
  {
    label: 'HIGHEST: Blocks must-haves',
    value: 'Highest',
  },
  {
    label: 'HIGH: Significantly impacts must-haves',
    value: 'High',
  },
  {
    label: 'MEDIUM: Should consider',
    value: 'Medium',
  },
  {
    label: 'LOW: Nice to Have',
    value: 'Low',
  },
]

export const CountryOptions = [
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Vietnam', value: 'Vietnam' },
]
