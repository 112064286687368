import React from "react";

import { connect } from 'react-redux';

// import PersonCard from "components/PersonCard/PersonCard.js";

import Sendkudos from 'views/Appreciate/Appreciate.js';
import Kudoswall from 'views/Appreciate/kudosWall.js';
import categoryList from "variables/appreciateCategory.js";
// reactstrap components
import {
    Button, Row, Col, CardTitle
} from "reactstrap";

class KudosLanding extends React.Component {
    constructor() {

        super();
        const categoryByID = {};
        categoryList.forEach(element => {
            categoryByID[element.id] = element;
        });

        this.state =
        {
            kudosWallVisible: ""
        }
        this.sendKudos = this.sendKudos.bind(this);

    }

    componentDidMount() {

        document.title = 'Kudos - GoConnect';

        if (this.props && this.props.sendKudo) {
            this.setState({
                kudosWallVisible: false
            });

        }

        else {

            this.setState({
                kudosWallVisible: true
            });
        }
    }

    sendKudos() {
        window.location.href = 'https://onegoto.gotocompany.com/s/recognition-wall'
    }

    kudosWallStatus = (status) => {
        this.setState({ kudosWallVisible: status },

        );
    }

    showWall () {
        if(this.state.kudosWallVisible)
            return "block";
        else 
            return "none";
    }

    showSendKudo() {
        if(!this.state.kudosWallVisible)
        return "block";
    else 
        return "none";
    }

    render() {

        return (

            <div style={{ padding: "40px" }}>
                <Row>
                    <Col xs="6" style={{verticalAlign:"top"}}>
                        <CardTitle tag="h4">Kudos</CardTitle >
                    </Col>
                </Row>
                <Row style={{marginTop:"20px"}}>
                    <Col xs="12" sm="9">
                        <p>
                            Kudos are an expression of appreciation and gratitude, things we all need in our lives to be happy and healthy. 
                            Sending well-deserved Kudos to your colleagues can strengthen teams, make work meaningful and yes, 
                            even help to make GoTo more successful.
                        </p>
                        <p>
                            Kudos celebrate those individuals who live and breathe Gojek's values. Who do you know that <b>Collaborates with Compassion</b>,&nbsp;
                            <b>Earns Their Title</b>, <b>Puts Customers First</b> or any of our other values? Call them out!
                        </p>
                        <p>
                            When you send a Kudo we'll notify the recipient, their manager, add them to the wall and their Kudo will be part of their profile too! 
                        </p>
                    </Col>
                    <Col xs="12" md="3" style={{textAlign:"right"}}>
                        <Button color="success" style={{ marginTop:"5px", marginRight:"20px" }} onClick={this.sendKudos}>Send a Kudo</Button>
                    </Col>
                </Row>
                
                <Row style={{marginTop:"20px", display: this.showWall()}}>
                    <Col xs="12">
                        <CardTitle tag="h6">Most Recent Kudos</CardTitle >
                        <Kudoswall />
                    </Col>
                </Row>

                <div style={{ display:this.showSendKudo(), marginTop:"20px" }}>
                    <Sendkudos key={this.props.key} kudosLanding={this.kudosWallStatus} userFromCard={this.props.personFromCard} />
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

KudosLanding = connect(mapStateToProps)(KudosLanding);
export default KudosLanding;