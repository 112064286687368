import React from "react";

import { connect } from 'react-redux';

//firebase
import { db, storage } from 'assets/firebase';  

import FileUploader from 'react-firebase-file-uploader';

//utils
import { telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import TextEditor from "components/TextEditor/TextEditor.js";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

class ResourceEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            resource: {
                title: '',
                details: '',
                url: "",
                imageURL: '',
                role: 'all'
            },
        }

        if(props.resource)
            this.state.resource = props.resource;
    }

    componentDidMount() {
        
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
        
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "resourceEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }
   
    updateResource(property, value) {
        var obj = this.state.resource;
        obj[property] = value;
        this.setState({resource: obj});
    }

    saveLearningResource() {

        var resource = this.state.resource;

        if(!resource.created) { 
            resource.created = new Date().getTime(); 
            resource.createdBy = this.props.user.person.name;
            resource.authorID = this.props.user.person.id;
        }

        resource.modified = new Date().getTime(); 
        resource.modifiedBy = this.props.user.person.name;

        
        db.updateLearningResource(resource.id, resource).then((result) => {

            if(this.props.onCreated) { // 
                resource.id = result.id;
                this.props.onCreated(resource);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "learningResource", "createLearningResource");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "learningResource", "updateLearningResource");
            }

            if(this.props.onCreated) { // reset for new report

                this.setState({ 
                    resource: {
                        title: '',
                        details: '',
                        url: "",
                        imageURL: '',
                        role: 'all'
                    },
                    author: {}
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(resource);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    deleteLearningResource = () => {
        db.deleteLearningResource(this.state.resource.id).then(() => {
            this.setState({modal: false});
            this.props.onDeleted(this.state.resource);
        });
    }

    // photos
    photoUploadStart = () => {
        this.setState({isUploading: true, progress: 0});
    };
    photoUploadProgress = (progress) => {
        this.setState({progress});
    };
    photoUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    photoUploadSuccess = (filename) => {
        console.log(filename)
        this.setState({progress: 100, isUploading: false});
        storage.content.child(filename).getDownloadURL().then(url => {
            console.log(url)
            this.updateResource('imageURL', url);
        });
    };
    

    buttonText() {
        if(this.props.onCreated)
            return "New Resource";
        else
            return "Edit";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Learning Resource
                            </Col>
                        </Row>   
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <label style={{color: "#696969", cursor:"pointer"}}>
                                    {this.state.resource.imageURL ? 
                                        <img src={this.state.resource.imageURL} alt="upload" /> :  
                                        <div style={{display: "flex", justifyContent: "space-between", color: "black"}}>
                                            <i style={{fontSize: "17px", opacity: "0.5", paddingTop: "3px"}} className="nc-icon nc-cloud-upload-94" alt="upload image"/>
                                            <p style={{margin: "0px", marginLeft: "5px"}}>Upload Image</p>
                                        </div>
                                    }
                                
                                    <FileUploader
                                        hidden 
                                        accept="image/*"
                                        name="photo"
                                        randomizeFilename
                                        storageRef={storage.content}
                                        onUploadStart={this.photoUploadStart}
                                        onUploadError={this.photoUploadError}
                                        onUploadSuccess={this.photoUploadSuccess}
                                        onProgress={this.photoUploadProgress}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row style={{marginTop:"10px"}}>
                            <Col xs="12" sm="8">
                                Title <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-badge" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={this.state.resource.title} onChange={(e) => this.updateResource("title", e.target.value)} />
                                </InputGroup>
                            </Col> 
                            <Col xs="12" sm="4" >
                                Role <br />  
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                            <i style={icon} className="nc-icon nc-sound-wave" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" 
                                    type="select" 
                                    value={this.state.resource.role} 
                                    onChange={(e) => this.updateResource("role", e.target.value)} >
                                        <option value="all">All</option>
                                        <option value="engineer">Engineer</option>
                                        <option value="manager">Manager</option>
                                        <option value="pm">PM</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                Details <br />
                                <TextEditor content={this.state.resource.details} onChange={(e) => this.updateResource("details", e)} hideToolbar={false} />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" >
                                URL <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-badge" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={this.state.resource.url} onChange={(e) => this.updateResource("url", e.target.value)} />
                                </InputGroup>
                            </Col> 
                        </Row>
                        <Row>
                            <Col xs="12">
                                <br />
                            </Col>
                        </Row>
                           
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6">
                                <ConfirmDialog 
                                    buttonText="Delete"
                                    title="Delete Learning Resource" 
                                    header="CONFIRM" 
                                    description="Are you sure you want to delete this learning resource?" 
                                    confirmed={() => this.deleteLearningResource(this.state.resource)} 
                                /> 
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveLearningResource()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    ResourceEditor = connect(mapStateToProps)(ResourceEditor);
    export default ResourceEditor;
    