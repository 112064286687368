import React from "react";

import { 
    Button, 
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, 
} from "reactstrap";

import { algolia } from 'assets/algolia';

import PersonCard from 'components/PersonCard/PersonCard.js';

import logo from "assets/img/goConnectIcon.png";


class PersonSearch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            queryText: '',
            searchResults: [],
            searchInProgress: false,
            dialogTitle: 'People Search',
        }

        this.onPersonChosen = this.onPersonChosen.bind(this)
        this.searchPeople = this.searchPeople.bind(this)
        this.personCardDisabled = this.personCardDisabled.bind(this)
        this.toggleModal = this.toggleModal.bind(this)

        if(this.props.dialogTitle)
            this.state.dialogTitle = this.props.dialogTitle;
        
        this.state.excludedObjectIDs = new Set(this.props.excludedObjectIDs)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isOpen !== this.props.isOpen) {
            if(this.props.isOpen)
                this.setState({modal: true});    
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    setSearchTimer(queryText) {

        clearTimeout(this.searchPeople);
        this.setState({queryText: queryText, searchResults: [], isLoading: true}, () => {
            setTimeout(this.searchPeople,3400);
        });
    }

    personCardDisabled(person) {
        return this.state.excludedObjectIDs.has(person.objectID)
    }
    
    searchPeople() {
        if(!this.state.searchInProgress) {
            this.setState({ searchResults: [], searchInProgress: true }, () => {
                if(this.state.queryText.length > 0) {
                    algolia.searchPeople(this.state.queryText).then((results) => {
                        this.setState({
                            searchResults: results,
                            searchInProgress: false,
                            isLoading: false,
                        })
                    })
                }
            })
        } else console.log('skipping search')
    }

    onPersonChosen(person) {
        this.props.onPersonChosen(person);
        this.setState({searchResults:[], queryText: '', modal:false});
    }

    getButtonText() {
        if(!this.props.label) 
            return "Add Member";
        else 
            return this.props.label;
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (
            <div>
                {
                    this.props.hideButton ? null : (
                        <Button outline color="secondary" onClick={this.toggleModal}  >
                            + {this.getButtonText()}
                        </Button>
                    )
                }
                
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" autoFocus={false}  >
                    <ModalBody>
                        <Row>
                            <Col sm={12}>
                                <br />{this.state.dialogTitle}<br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-zoom-split" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input autoFocus={true} placeholder="search by name" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        <style>
                        {
                            `
                            @keyframes spinning {
                                from { transform: rotate(0deg) }
                                to { transform: rotate(360deg) }
                            }
                            .spin {
                                animation-name: spinning;
                                animation-duration: 3s;
                                animation-iteration-count: infinite;
                                /* linear | ease | ease-in | ease-out | ease-in-out */
                                animation-timing-function: linear;
                            }
                            `
                        }
                        </style>

                        <Row>
                            <Col xs="12">
                                <div style={loadingDiv}>
                                    <div className="spin">
                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row style={{minHeight:"350px", marginBottom:"20px"}}>
                            {this.state.searchResults.map((person, index) => (
                                <Col sm={3} key={index}>
                                    <PersonCard
                                        person={person}
                                        onSelected={this.onPersonChosen}
                                        disabled={this.personCardDisabled(person)}
                                        hideExpander
                                    />
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
                
            </div>
        )
    }
}

export default PersonSearch;