import React from "react";

//redux
import { connect } from 'react-redux'

import { algolia } from "assets/algolia";
import { db, func } from 'assets/firebase';

import { telemetry } from 'assets/utils';
import { dateTime } from 'assets/utils';

// reactstrap components
import { Button, CustomInput,
        Row, Col, 
        Modal, ModalHeader, ModalBody, ModalFooter,
        Input, InputGroup, InputGroupAddon, InputGroupText, 
        TabContent, TabPane, Nav, NavItem, NavLink,
        Table, Spinner
    } from "reactstrap";

import IssueSearch from "components/IssueSearch/IssueSearch.js"; 

import gojek from 'assets/img/logo.png';
import PersonCard from "components/PersonCard/PersonCard";
import IssueEditor from "components/IssueEditor/IssueEditor";

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

class ReviewCard extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
          modal: false,
          review: props.review,
          response: "",
          comment: { deviceMetadata: {} },
          showDeviceData: true,
          issues: [],
          activeTab: 'review',
          isWorking: false,
          replyPermissions: false,
          includeSupportDetails: false,
          careCode: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.review !== this.props.review) {
                this.setState({review: this.props.review});
        }
    }

    toggleModal() {
        
        if(!this.state.modal) {
   
            db.getReview(this.props.review.id).then(review => {

                if(review) {

                    if(review.review.comments[review.review.comments.length - 1].userComment)
                        review.responded = false;
                    else 
                        review.responded = true;

                    this.setState({review: review}, () => {

                        /*
                        var isEligible = true;
                        const comments = review.review.comments;
                        for(var i = 0; i < comments.length; i++) {
                            if(comments[i].developerComment) {
                                isEligible = false;
                                break;
                            }
                        }

                        if(isEligible) {

                            var reviewerLanguage = this.state.review.review.comments[0].userComment.reviewerLanguage;
                            var careCode = this.getCareCode();
                            var replyText;

                            if(reviewerLanguage === 'en')
                                replyText = "If the problem persists, please share us the detail via email at customerservice@go-jek.com and write this code #" + careCode + " as the email subject. We'll be glad to help.";
                            else if(reviewerLanguage === 'id')
                                replyText = "Jika masih mengalami kendala, silakan laporkan melalui email ke customerservice@go-jek.com dan mencantumkan kode #" + careCode + " pada subjek email yang dikirimkan.";
                            
                            this.setState({supportDetails: replyText});
                        }    
                        */
                    });
                }
                else {
                    //console.log('missing review: ' + this.props.review.id);
                    //console.log(this.props.review);
                    // a handful of reviews in index not in db
                    db.updateReview(this.props.review.id, this.props.review);
                }
                    
            })

            if(this.state.review.platform === 'android') {

                this.getRespondents(this.props.review).then(review => {
                    
                    var comment = { deviceMetadata: {} };
                    if (this.props.review.review.comments[0].userComment) {
                        comment = this.props.review.review.comments[0].userComment;
            
                        if(!comment.deviceMetadata) comment.deviceMetadata = {};
                    }

                    this.setState({review: review, activeTab:'review', comment: comment});
                });

                this.getUserPermissions();

            }

            db.getReviewIssues(this.props.review.id).then(xrefs => {
                
                var promises = [];
                for(var i = 0; i < xrefs.length; i++) 
                    promises.push(db.getIssue(xrefs[i].issueID));
                
                Promise.all(promises).then(issues => {
                    this.setState({issues: issues});
                })
            });

            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "review", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getUserPermissions() {

        db.getPerson(this.props.user.personID).then(person => {

            if (person.managerApprovalAppReviews) {
                if (person.managerApprovalAppReviews === 'approved') {
                    this.setState({replyPermissions: true});
                }
            }
        })
    }

    getRespondents(review) {
        
        return new Promise(function(resolve, reject) {

            if(review.respondents) {

                var promises = [];

                for(var i = 0; i < review.respondents.length; i++) 
                    promises.push(db.getPerson(review.respondents[i].personID));    
                
                Promise.all(promises).then(people => {

                    for(var j = 0; j < review.review.comments.length; j++) {
                        if(review.review.comments[j].developerComment) {
                            for(var k = 0; k < review.respondents.length; k++) 
                                if(review.review.comments[j].developerComment.lastModified.seconds === review.respondents[k].timeStamp) {
                                    for(var l = 0; l < people.length; l++) {
                                        if(people[l].id === review.respondents[k].personID) {
                                            review.review.comments[j].developerComment.person = people[l];
                                            break;
                                        }
                                    }
                                }
                        }
                    }

                    resolve(review);
                })

            } else resolve(review);
        });
    }

    getStars(rating) {

        if(rating === 1)
            return <div >
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                </div>
        else if (rating === 2)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                </div>
        else if (rating === 3)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                </div>
        else if (rating === 4)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#eee"}}></i>
                </div>
        else if (rating === 5)
            return <div style={{color:"gold"}}>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
            </div>
    }

    originalText(comment) {
        if(comment.userComment)
            if(comment.userComment.originalText)
                return <div>{comment.userComment.originalText} <hr /></div>

        return "";
    }

    updateResponseText(value) {

        if(value.length > 350)
            value = value.substr(0,350);

        this.setState({response: value});
    }

    responseCharacterCount() {
        return this.state.response.length;
    }

    getReviewerLanguage() {
        
        if(this.state.review.platform === 'iOS') return '';

        var reviewerLanguage = this.state.review.review.comments[0].userComment.reviewerLanguage;
        
        if(reviewerLanguage === 'en')
            return "This review was submitted in English.";
        else if(reviewerLanguage === 'cs')
            return "This review was submitted in Czech.";
        else if(reviewerLanguage === 'eo')
            return "This review was submitted in Esperanto.";
        else if(reviewerLanguage === 'fr')
            return "This review was submitted in French.";
        else if(reviewerLanguage === 'id')
            return "This review was submitted in Indonesian.";
        else if(reviewerLanguage === 'jv')
            return "This review was submitted in Javanese.";
        else if(reviewerLanguage === 'ms')
            return "This review was submitted in Malay.";
        else if(reviewerLanguage === 'th')
            return "This review was submitted in Thai.";
        else if(reviewerLanguage === 'tr')
            return "This review was submitted in Turkish.";
        else if(reviewerLanguage === 'vi')
            return "This review was submitted in Vietnamese.";
    }

    updateReview(property, value) {
        var obj = this.state.review;
        obj[property] = value;
        this.setState({review: obj});
    }

    showResponseSection() {
        if(this.state.review.platform === 'iOS') return 'none';

        //const comments = this.state.review.review.comments;
        //const lastComment = comments[comments.length - 1];
        //if(lastComment.userComment && !this.state.isWorking && this.state.replyPermissions)
        if(!this.state.review.responded && !this.state.isWorking && this.state.replyPermissions)
            return "flex";
        else 
            return "none";
    }

    showResponseSentText() {
        if(this.state.review.replied)
            return "flex";
        else 
            return "none";
    }

    showDeviceData() {
        if(this.state.showDeviceData)
            return "block";
        else
            return "none";
    }

    showDeviceDataPrompt() {
        if(!this.state.showDeviceData)
            return "inline-block";
        else
            return "none";
    }

    showWorking() {
        if(this.state.isWorking)
            return "block";
        else
            return "none";
    }

    replyToReview() {

        if(this.state.response.length > 0) {

            this.setState({isWorking: true}, () => {
                
                func.replyPlayStoreReview(this.state.review.id, this.state.response, this.state.review.packageName, location.pathname).then(json => {

                    console.log(json)

                    var review = this.state.review;
                    review.review = json;
                    review.responded = true;

                    var timeStamp = new Date().getTime();

                    review.review.comments.push( {
                        developerComment: {
                            lastModified: {
                                seconds: timeStamp
                            },
                            text: this.state.response
                        }
                    })

                    //var timeStamp = review.review.comments[review.review.comments.length -1].developerComment.lastModified.seconds;
                    

                    if(!review.respondents) review.respondents = [];
                    review.respondents.push({personID: this.props.user.personID, timeStamp: timeStamp});


                        db.updateReview(review.id, review).then(() => {
                            // algolia.upsertFeedback(review);
                            this.props.onUpdated(review);
    
                            this.getRespondents(review).then(review => {
                                console.log(review);
                                this.setState({review: review, isWorking: false});
                            })
                        });
                    });
                    
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "review", "replyToReview");
                  
            })
        }
    }

    sendReply(body) {

        return new Promise((resolve, reject) => {

            fetch('https://us-central1-goconnect.cloudfunctions.net/replyToPlayStoreReview', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        console.log('error (1): ');
                        console.log(response);
                    }
                })
                .then((json) => {
                    console.log(json);
                    resolve(json.data);
                })
                .catch((error) => {
                    // If there is any error you will catch them here
                    console.log('error (2):  ' + error);
                    reject();
                });
        });
    }

    getCareCode() {

        // start with app or play store
        var code = 'psr-'
        if(this.state.review.platform === 'iOS') 
            code = 'asr-';

        var rightNow = new Date();
        code = code + rightNow.getFullYear().toString().substr(2,2) + '-';

        var day = (Date.UTC(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate()) - Date.UTC(rightNow.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
        if(day < 10)
            day = '0' + day;
        else if(day < 100)
            day = '00' + day;
        
        var hours = rightNow.getUTCHours();
        if(hours < 10)
            hours = '0' + hours;

        var minutes = rightNow.getUTCMinutes();
        if(minutes < 10)
            minutes = '0' + minutes;

        var seconds = rightNow.getUTCSeconds();
        if(seconds < 10)
            seconds = '0' + seconds;

        var milliseconds = rightNow.getUTCMilliseconds();
        
        if(milliseconds < 10)
            milliseconds = '0' + milliseconds;
        else if(milliseconds < 100)
            milliseconds = '00' + milliseconds;
        
        code = code + day + '-' + hours + minutes + '-' + seconds + milliseconds;

        return code;
    }

    updateComment(comment) {
        console.log('updateComment()');
        console.log(comment);
    }

    issueChosen(issue) {
        
        var issueReview = { issueID: issue.id, reviewID: this.state.review.id, 
            created: new Date().getTime(), createdBy: this.props.user.person.name };
        db.updateIssueReviews(issueReview.id, issueReview);

        var issues = this.state.issues;
        issues.push(issue);
        this.setState({issues: issues});

        // get reply text
        if(this.state.review.platform === 'android') {
   
            var reviewerLanguage = this.state.review.review.comments[0].userComment.reviewerLanguage;
            
            var responseText = "";
            if(reviewerLanguage === 'en' && issue.responseTextEnglish)
                responseText = issue.responseTextEnglish;
            else if(reviewerLanguage === 'id' && issue.responseTextIndonesian)
                responseText = issue.responseTextIndonesian;
            else if(reviewerLanguage === 'th' && issue.responseTextThai)
                responseText = issue.responseTextThai;
            else if(reviewerLanguage === 'vi' && issue.responseTextVietnamese)
                responseText = issue.responseTextVietnamese;

            this.setState({response: responseText});
        }
    }

    getAuthor(comment) {
        if(comment.person) {
            return <PersonCard person={comment.person} showThumb />
        } else {
            return <img src={gojek} style={{height:"23px"}} alt="logo" />
        }
    }

    getDeveloperComment(comment) {

        var isAuthor = false;
        if(comment.person)
            if(comment.person.id)
                if(comment.person.id === this.props.user.person.id)
                    isAuthor = true;

        //isAuthor = true; // comment unless testing
        isAuthor = false; // disable textbox until the rest is done!

        if(isAuthor) {   
            
            return <div>
                        
                    <Input placeholder={this.getReviewerLanguage()} 
                        type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                        value={comment.text}  />
                    <Row>
                        <Col xs="6" style={{color:"#898989", fontSize:"10px", marginTop:"2px"}}>
                            {dateTime.timeConverter(comment.lastModified.seconds * 1000)}
                        </Col>
                        <Col xs="6" style={{textAlign:"right"}}>
                            <Button size="sm" onClick={this.updateComment(comment)}>Update</Button>
                        </Col>
                    </Row>
                </div>
            
        } else {
            return <div>
                    {comment.text}
                    <div style={{color:"#898989", fontSize:"10px", marginTop:"2px"}}>
                        {dateTime.timeConverter(comment.lastModified.seconds * 1000)}
                    </div>
                </div>
        }
    }

    getDiscussion() {

        const label = {
            fontSize:"12px",
            textAlign:"right",
            fontWeight:500
        };
        const value = {
            fontSize:"12px",
            textAlign:"left",
            fontWeight:"bold"
        };

        if(this.state.review.platform === 'android') {

            return <div>
                {this.state.review.review.comments.map((comment, index) => {
                    return comment.userComment ?
                    <Row key={index} style={{paddingTop:"17px", paddingBottom:"17px"}}>
                        <Col sm="2">

                            {this.getStars(comment.userComment.starRating)}
                            
                            <div style={{fontSize:"12px", color:"#898989", marginTop:"11px"}}>
                                <span style={{marginLeft:"0px", verticalAlign:"middle"}}><i className="fas fa-thumbs-up"></i></span>
                                <span style={{marginLeft:"5px", verticalAlign:"middle"}}>{this.state.comment.thumbsUpCount}</span>
                                <span style={{marginLeft:"15px", verticalAlign:"middle"}}><i className="fas fa-thumbs-down"></i></span>
                                <span style={{marginLeft:"5px", verticalAlign:"middle"}}>{this.state.comment.thumbsDownCount}</span>
                            </div>
                        </Col>
                        <Col sm="10" style={{fontSize:"13px"}}>
                            <div style={{fontWeight:700, fontSize:"15px", color:"green"}}>{this.state.review.review.authorName}</div>
                            {this.originalText(comment)}
                            
                            {comment.userComment.text}
                            <div style={{color:"#898989", fontSize:"10px", marginTop:"2px"}}>
                                {dateTime.timeConverter(comment.userComment.lastModified.seconds * 1000)}
                                <span style={{color:"green", fontSize:"10px", marginTop:"10px", marginLeft:"5px", cursor:"pointer", display:this.showDeviceDataPrompt()}} onClick={() => this.setState({showDeviceData: true})}>
                                - Device Information
                                </span>   
                            </div>
                            
                        </Col>
                        <Col sm="2">
                        </Col>
                        <Col sm="10">
                            <div style={{backgroundColor:"#7cd179", color:"#efefef", padding:"20px", marginTop:"23px", display:this.showDeviceData()}}>
                                <Row>
                                    <Col sm="3" style={label} >
                                        App Version:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.review.review.comments[0].userComment.appVersionName}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        Device:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.device}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        Android OS 
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.androidOsVersion}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        CPU Make:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.deviceMetadata.cpuMake}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        Manufacturer:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.deviceMetadata.manufacturer}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        CPU Model:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.deviceMetadata.cpuModel}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        Type:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.deviceMetadata.deviceClass}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" style={label} >
                                        Platform:
                                    </Col>
                                    <Col sm="9" style={value} >
                                        {this.state.comment.deviceMetadata.nativePlatform}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    :
                    <Row key={index} style={{borderTop: "solid 1px #dedede", paddingTop:"17px", paddingBottom:"17px"}}>
                        <Col sm="2">
                            {this.getAuthor(comment.developerComment)}
                        </Col>
                        <Col sm="10" style={{fontSize:"13px"}}>
                            {this.getDeveloperComment(comment.developerComment)}
                        </Col>
                    </Row>
                    }
                )}
            </div>
        } else if (this.state.review.platform === 'iOS') {

            return <div>
                <Row>
                    <Col sm="2">

                    {this.getStars(this.state.review.rating)}

                    <div style={{fontSize:"12px", color:"#898989", marginTop:"11px"}}>
                        <span style={{marginLeft:"0px", verticalAlign:"middle"}}><i class="fas fa-thumbs-up"></i></span>
                        <span style={{marginLeft:"5px", verticalAlign:"middle"}}>{this.state.review.review.votes}</span>
                    </div>
                    </Col>
                    <Col sm="10" style={{fontSize:"13px"}}>
                        <div style={{fontWeight:700, fontSize:"15px", color:"green"}}>{this.state.review.review.authorName}</div>

                        <span style={{fontWeight:500, color:"#232323"}}>{this.state.review.review.title}</span> 
                        &nbsp;:&nbsp;

                        {this.state.review.review.description}
                       
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                    </Col>
                    <Col sm="10">
                        <div style={{backgroundColor:"#7cd179", color:"#efefef", padding:"20px", marginTop:"23px", display:this.showDeviceData()}}>
                            <Row>
                                <Col sm="3" style={label} >
                                    App Version:
                                </Col>
                                <Col sm="9" style={value} >
                                    {this.state.review.review.version}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        }

    }

    showInsertButton() {
        if(this.state.response.length < 321) 
            return "inline-block";
        else 
            return "none";
        
    }

    insertCareCode() {

        if(this.state.response.length < 350) {
            var text = this.state.response;
            text = text + ' ' + this.getCareCode();
            this.setState({response: text});
        }
    }

    /*
    toggleIncludeSupportDetails(include) {
        this.setState({includeSupportDetails: include});
    }

    showIncludeSupportToggle() {
        var reviewerLanguage = this.state.review.review.comments[0].userComment.reviewerLanguage;
        if(reviewerLanguage === 'en' || reviewerLanguage === 'id')
            return "inline-block";
        else 
            return "none";
    }

    showSupportDetails() {
        if(this.state.includeSupportDetails)
            return "block";
        else    
            return "none";
    }
    */

  render() {

    
    const iconTextArea = {
        fontSize:"17px",
        position:"absolute",
        top:"20px",
        color:"#000000"
    }
    const link = {
        color:"#434343",
        fontWeight:500,
        fontSize:"12px",
        cursor:"pointer"
    }
    const activeLink = {
        color:"green",
        fontWeight:700,
        fontSize:"12px"
    }

    return (

        <div style={{whiteSpace:"normal"}}>
        
            <Button onClick={() => this.toggleModal()} size="sm">
                Open
            </Button>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                <ModalHeader>
                    <Row>
                        <Col sm="12" style={{textAlign:"left"}}>
                            App / Play Store Review
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'review' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'review'}) }}
                                    >
                                        Review
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'issues' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'issues'}) }}
                                    >
                                        Issues ({this.state.issues.length})
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody style={{paddingTop:"17px", paddingLeft:"43px", paddingRight:"43px"}}>
                    
                    <TabContent activeTab={this.state.activeTab} >
                        <TabPane tabId="review" style={{minHeight:"360px", paddingTop:"0px"}}>

                            {this.getDiscussion()}

                            <Row style={{display:this.showWorking()}}>
                                <Col sm="12" style={{textAlign:"center", fontSize:"11px", padding:"20px"}}>
                                    <Spinner color="success" />
                                </Col>
                            </Row>
                            <Row style={{display:this.showResponseSentText()}}>
                                <Col sm="12" style={{textAlign:"center", fontSize:"11px", padding:"20px"}}>
                                    We have responded to this user.
                                </Col>
                            </Row>
                            <Row style={{display:this.showResponseSection()}}>
                                <Col sm="2">
                                
                                </Col>
                                <Col sm="10">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{width:"50px"}}>
                                                <i style={iconTextArea} className="nc-icon nc-chat-33" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder={this.getReviewerLanguage()} 
                                            type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                                            value={this.state.response} onChange={(e) => this.updateResponseText(e.target.value)} />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row style={{display:this.showResponseSection()}}>

                                <Col sm="2">
                                    
                                </Col>
                                <Col sm="10">
                                    <div style={{fontSize:"11px"}}>
                                        {this.responseCharacterCount()} / 350
                                    </div>
                                </Col>

                                <Col sm="2">
                                    
                                </Col>
                                <Col sm="10">
                                                                            
                                    <div style={{textAlign:"right", display:"inline-block", width:"100%"}}>
                                        <div style={{textAlign:"right", display:this.showInsertButton(), marginRight:"10px"}}>
                                            <Button size="sm" onClick={() => this.insertCareCode()} >Insert Care Code</Button>
                                        </div>
                                        {/* <div style={{textAlign:"right", display:"inline-block", marginRight:"10px" }}>
                                            <IssueSearch onIssueChosen={(e) => this.issueChosen(e)} label="Search Response Templates" />
                                        </div> */}
                                        <div style={{textAlign:"right", display:"inline-block" }}>
                                            <Button color="danger"  onClick={() => this.replyToReview()} size="sm">    
                                                Send Reply
                                            </Button>
                                        </div>
                                    </div>
                                    
                                </Col>

                            </Row>
                        </TabPane>
                        
                        <TabPane tabId="issues" style={{minHeight:"360px", paddingTop:"0px"}}>
                            <Table style={{border:"none"}}>
                                <thead>
                                    <tr style={{fontSize:"12px", color:"green"}}>
                                        <td style={{borderTop:"none"}}></td>
                                        <td style={{borderTop:"none"}}>Title</td>
                                        <td style={{borderTop:"none"}}>Last Modified</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.issues.map((issue, index) => (
                                    <tr key={index}>
                                        <td>
                                            <IssueEditor issue={issue} />
                                        </td>
                                        <td>
                                            {issue.title}
                                        </td>
                                        <td>
                                            {dateTime.dateConverter(issue.modified)}
                                        </td>
                                    </tr>
                                ))}       
                                </tbody> 
                            </Table>
                        
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter style={{backgroundColor:"#efefef"}}>
                    
                    <Row style={{marginTop:"20px", marginBottom:"10px", width:"100%"}}>
                        <Col sm="2" >
                            
                        </Col>
                        <Col sm="10" style={{textAlign:"right"}}>
                            {/* <div style={{display:"inline-block", marginRight:"10px"}}>
                                <IssueSearch onIssueChosen={(e) => this.issueChosen(e)} label="+ Add / Link Issue" />
                            </div> */}
                            <div style={{display:"inline-block", marginRight:"10px"}}>
                                <Button size="sm" onClick={() => this.toggleModal()}>
                                   Close
                                </Button>
                            </div>
                            
                        </Col> 
                    </Row>
                </ModalFooter>
            </Modal>
        
        </div>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

ReviewCard = connect(mapStateToProps)(ReviewCard);
export default ReviewCard;