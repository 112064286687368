import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { slack, telemetry } from 'assets/utils';

// reactstrap components
import {
    Alert,
    Button, Card, CardBody, CardFooter,
    Row, Col,
  } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
//import ProjectCard from "components/ProjectCard/ProjectCard.jsx";
//import TeamCard from "components/TeamCard/TeamCard.js";

import headerImg from "assets/img/mentoringHeader.png";

class Mentoring extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          plan: {
              id: this.props.user.personID,
          },
          manager: {},
          isLoading: true,
          alertVisible: false
        }
    }

    componentDidMount() {

       

        db.getPerson(this.props.user.person.managerID).then(manager => {
            this.setState({manager: manager});
        })
         
        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "mentoring", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }


    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    updatePlan(property, value) {
        var obj = this.state.plan;
        obj[property] = value;
        this.setState({plan: obj});
    }

    saveCareerDevelopmentPlan(notifyManager) {

        db.updateCareer(this.state.plan.id, this.state.plan).then(() => {
            this.setState({alertVisible: true});
            setTimeout(() => {
                this.setState({alertVisible: false});
            }, 5000);

            if(notifyManager) {
                this.sendSlackMessage();
            }
        });
    }

    sendSlackMessage() {
        var text = this.props.user.person.name + " has updated his or her Career Development Plan.";
        var blocks = [
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": text + "\n\n" + "Click here to see it."
                    }
                },
            ]
                    
        var slackID = 'U01C68P5D4P'; // replace with manager id
        slack.sendMessage(slackID, text, blocks);
                       
    }

    employeeCard() {
        if(this.props.user.person.id) {
            return (
                <PersonCard person={this.props.user.person} showThumb />
            )
        }
    }

    managerCard() {
        if(this.state.manager.id) {
            return (
                <PersonCard person={this.state.manager} showThumb />
            )
        }
    }
    
    render() {

       
        return (
            <div style={{padding:"20px"}}>
                
                <Row>
                    <Col xs="12" sm="9" >
                        <br />
                        <Card style={{padding:"0px"}}>
                            <CardBody style={{minHeight:"300px"}}>
                                <br />  <br />  
                                <Row>
                                    <Col md="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                        We want you to build a career at Gojek that you are excited about and can be proud of.
                                        The first step is to articulate your goals and realistic, concrete steps you can take to get there. 
                                        It's also important that your manager knows what your goals are, so they can help by providing you 
                                        with the opportunities and the feedback you need along the way. 
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col sm="3">
                                        
                                    </Col>
                                    <Col sm="9" style={{color:"#666666"}}>
                                        You can save your work to come back later. When you're ready to discuss it with your 
                                        manager, submit the plan so they can review and add comments.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" style={{textAlign:"right", marginTop:"20px"}}>
                                        <div style={{display:"inline-block", marginRight:"10px"}}>
                                            <Button color="secondary" onClick={() => this.saveCareerDevelopmentPlan(false)} size="md" >
                                                Save
                                            </Button>
                                        </div>
                                        <div style={{display:"inline-block"}}>
                                            <Button color="success" onClick={() => this.saveCareerDevelopmentPlan(true)} size="md" >
                                                Submit and Notify Manager
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        <Alert color="success" isOpen={this.state.alertVisible} toggle={() => this.setState({alertVisible: false})}>
                            Your changes have been saved.
                        </Alert>
                    </Col>
                    <Col xs="12" sm="3">
                        <Card style={{padding:"0px"}}>
                            <div style={{backgroundSize: "cover", height:"80px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                                backgroundImage:`url(${headerImg})`, backgroundRepeat:"no-repeat", backgroundPosition: "center", borderBottom:"1px solid green"}}>
                            </div>
                            <CardBody style={{minHeight:"300px"}}>
                                blah blah blah
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Mentoring = connect(mapStateToProps)(Mentoring);
export default Mentoring;