import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, dateTime, tables, telemetry } from 'assets/utils';

import { Bar, Line } from "react-chartjs-2";

// reactstrap components
import {
    Badge,
    Input, Button,
    Card, CardHeader, CardBody, 
    Row, Col, Table,
    Modal, ModalBody, ModalFooter
  } from "reactstrap";

import Parser from 'html-react-parser';

import PersonCard from "components/PersonCard/PersonCard";
import ResourceEditor from "views/learningJournal/ResourceEditor.js";
import TextEditor from "components/TextEditor/TextEditor.js";
import JournalEntriesTable from "components/JournalEntriesTable/JournalEntriesTable.js";

import logo from "assets/img/goConnectIcon.png";
import moment from 'moment';

let chartColor = "#FFFFFF";

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };


class LearningJournal extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
          isLoading: true,
          isEditor: false,
          journalEntries: [],
          totalTime: 0,
          totalMonthlyTimeByYear: {},
          content: {
            welcomeText: ''
          },
          oldContent: {
            welcomeText: ''
          },
          resources: [],
          editWelcomeText: false,
          directReports: [],
          cycle: "22h1",
          spanToDate: new Date().toISOString().substr(0,10),
          newActivity: {
            authorID: this.props.user.person.id,
            personID: this.props.user.person.id,
            learningNotes: '',
            learningDate: new Date().toISOString().substr(0,10),
            resource: 'book',
            timeSpent: '15'
          },
          timeSpentInHours: 0,
          timeSpentInMinutes: 15,
          timeSpentChart: {
            data: [],
            labels: []
          },
          peopleManager: false,
          resourceTypes: [
            { value: 'book', text: 'Book'},
            { value: 'coaching', text: 'Coaching'},
            { value: 'documentary', text: 'Documentary'},
            { value: 'otherExternal', text: 'Other External Resource'},
            { value: 'otherInternal', text: 'Other Internal Resource'},
            { value: 'peerLearning', text: 'Peer Learning'},
            { value: 'podcast', text: 'Podcast'},
            { value: 'youTube', text: 'YouTube'},
        ],
        reminderFrequency: 'monthly',
        pageViews: [],
        pageViewSummary: [],
        uniqueUsersSummary: [],
        visitors: [],
        users: [],
        errorModal: false,
        errorMessage: '',
        yearlyJournalEntries: {},
        totalYearlyTime: {},
      }

      db.getPerson(this.props.user.person.id).then(person => { 
        if(person.learningJournalReminders)
            this.state.reminderFrequency = person.learningJournalReminders;
        else { // Update learning journal reminders to 'monthly' by default
            person.learningJournalReminders = this.state.reminderFrequency;
            db.updatePerson(person.id, person);
        }
      });
    }

    hoursOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    minutesOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    componentDidMount() {
        
        db.getJournalEntriesByPerson(this.props.user.person.id).then(entries => {

            this.sortEntries(entries);

            this.setState({journalEntries: entries}, () => {
                this.calculateTime();
                this.getLearningJournalContent();
                this.getLDTeamMembers();
            });
        });

        if (this.props.user.person.isAdmin) {
            this.getSummaryData();
            this.getTopPageViews();
            this.getTopJournalEntries();
        }


        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "learningJournal", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    sortEntries(entries) {

        entries.forEach(entry => {
            entry.sortDate = new Date(entry.learningDate).getTime();
        })

        entries.sort( (a,b) => { return b.sortDate - a.sortDate } );
    }

    getLearningJournalContent() {

        this.getDirectReports().then(() => {

            db.getLearningJournalContent().then(content => {
                if(content.welcomeText)
                    this.setState({
                        content: content,
                        oldContent: content,
                    });

                this.setState({isLoading: false});
            });

            db.getLearningResources().then(results => {

                var resources = [];

                results.forEach(resource => {
                    if(resource.role === 'all' || (resource.role === 'manager' && this.state.peopleManager) || this.state.isEditor )
                        resources.push(resource);
                })

                this.setState({resources: resources});
            });
        });
    }

    calculateTime() {
        
        var totalTime = 0;
        const totalMonthlyTimeByYear = {};
        const totalYearlyTime = {};
        const yearlyJournalEntries = {};

        this.state.journalEntries.forEach(entry => {
            if(entry && entry.learningDate) { // Adding a check in case learning date is empty
                const year = entry.learningDate.split('-')[0];
                const month = entry.learningDate.split('-')[1];

                if(!(year in yearlyJournalEntries)) {
                    yearlyJournalEntries[year] = [...Array(12).fill([])];
                } 

                const monthlyEntries = yearlyJournalEntries[year];
                if(monthlyEntries[11-(+month-1)].length > 0) {
                    monthlyEntries[11-(+month-1)].push(entry);
                } else {
                    monthlyEntries[11-(+month-1)] = [entry];
                }

                if(entry.timeSpent) {
                    totalTime = totalTime + Number(entry.timeSpent);

                    if(!(year in totalYearlyTime)) {
                        totalYearlyTime[year] = 0;
                    }
                    totalYearlyTime[year] += Number(entry.timeSpent);

                    if(!(year in totalMonthlyTimeByYear)) {
                        totalMonthlyTimeByYear[year] = [...Array(12).fill(0)];
                    }
                    const total =  totalMonthlyTimeByYear[year][11-(+month-1)] + Number(entry.timeSpent);
                    totalMonthlyTimeByYear[year][11-(+month-1)] = total;
                }
            }
        })

        var weeksOfHistory = 5;

        var upperBound = new Date();
        upperBound.setHours(0); upperBound.setMinutes(0); upperBound.setSeconds(0); upperBound.setMilliseconds(0);
        upperBound = upperBound.getTime();
        upperBound = upperBound + (24*60*60*1000); // i.e. start with end of today
        
        var data = [];
        var labels = [];

        for(var i = 0; i < weeksOfHistory; i++) {

            var lowerBound = upperBound - (24*60*60*1000 * 7); // one week at a time
            
            var timeSpent = 0;
            const len = this.state.journalEntries.length;
            for (let i = 0; i < len; i++){
                const entry = this.state.journalEntries[i];
                if(entry.timeSpent) {
                    var activityDate = new Date(entry.learningDate).getTime();
                    if(activityDate >= lowerBound && activityDate <= upperBound) 
                        timeSpent = timeSpent + Number(entry.timeSpent);
                }
            }

            data.unshift( timeSpent );
            labels.unshift( dateTime.mmdd(lowerBound) );

            upperBound = lowerBound;
        }
        
        totalTime = dateTime.toHoursAndMinutes(totalTime);

        this.setState({
            totalTime, 
            timeSpentChart: { data, labels },
            totalMonthlyTimeByYear,
            totalYearlyTime,
            yearlyJournalEntries
        });
    }

    getLDTeamMembers() {
        db.getTeamMembers('pJWkFvMBsxBMcp99cZvj').then(teamMembers => { // hard-coded to L&D team
            for(var i = 0; i < teamMembers.length; i++) {
                if(teamMembers[i].personID === this.props.user.person.id) {
                    this.setState({isEditor: true}, () => {
                        if(this.state.isEditor) {
                            this.getSummaryData();
                            this.getTopPageViews();
                            this.getTopJournalEntries();
                        }
                    });

                    
                    break;
                }
            }
        });
    }

    getDirectReports() {

        return new Promise((resolve, reject) => {
            db.getPeopleByManagerID(this.props.user.person.id).then(people => {
      
                var directReports = [];
                people.forEach(person => {
                if(person) 
                    directReports.push(person);
                })
        
                if(directReports.length > 0) {
                    this.setState({peopleManager: true}, () => {

                        var resourceTypes = this.state.resourceTypes;
                        resourceTypes.push ({ value: 'goLead', text: 'GoLead'});
                        resourceTypes.sort(arraySort.byText);
                        this.setState({resourceTypes: resourceTypes});

                        resolve();
                    });
                } else resolve();
            });
        });
    }


    getSummaryData() {

        return new Promise(() => {
        
        var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var daysHistory = 14;

        var upperBound = new Date();
        upperBound.setHours(0); upperBound.setMinutes(0); upperBound.setSeconds(0); upperBound.setMilliseconds(0);
        upperBound = upperBound.getTime();
        upperBound = upperBound + (24*60*60*1000); // i.e. start with end of today

        var promises = [];
        for(var i = 0; i < daysHistory; i++) {

            var lowerBound = upperBound - (24*60*60*1000);
            promises.push(db.getUserActivityByTypeBetween('pageView', lowerBound, upperBound));
            upperBound = lowerBound;

        }

        Promise.all(promises).then(results => {

            var summaryData = [];
            var labels = [];
            var pageViews = [];
            var totalPageViews = [];
            var uniqueUsers = []; // per day

            results.forEach(result => {

            if(result.length > 0) {

                var dateStamp = new Date(result[0].created);

                var totalPageViews = 0;
                var uniqueUsers = [];

                result.forEach(pageView => {

                
                    if(pageView.pageID === 'learningJournal') {

                        
                        totalPageViews = totalPageViews + 1;

                        // counting users
                        var isFound = false;
                        for(var j = 0; j < uniqueUsers.length; j++) {
                            if(uniqueUsers[j] === pageView.personID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) {
                            uniqueUsers.push(pageView.personID);
                        } 

                        // counting pages
                        isFound = false;
                        for (var l=0; l < pageViews.length; l++) {
                            if(pageViews[l].name === pageView.pageID) {
                                pageViews[l].visits = pageViews[l].visits + 1;
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) {
                            pageViews.push({ name: pageView.pageID, visits: 0});
                        }
                    }
                });

                pageViews.sort( (b,a) => { return a.visits - b.visits } );

                summaryData.push({
                    label: days[dateStamp.getDay()],
                    timeStamp: dateStamp.getTime(),
                    totalPageViews: totalPageViews,
                    uniqueUsers: uniqueUsers.length,
                    pageViews: pageViews
                });
            }
            })

            summaryData.sort( (b,a) => { return b.timeStamp - a.timeStamp } );

            summaryData.forEach(data => {
                labels.push(data.label);
                totalPageViews.push(data.totalPageViews);
                uniqueUsers.push(data.uniqueUsers);
            })

            this.setState({
                pageViewSummary: { data: totalPageViews, labels: labels }, 
                uniqueUsersSummary: { data: uniqueUsers, labels: labels }, 
                pageViews: pageViews
            });
        })
        })
    }

    getTopPageViews() {
        return new Promise(() => {
            
        var upperBound = new Date();
        var daysHistory = upperBound.getDate(); // Calculate until the current day of the month

        upperBound.setHours(0); upperBound.setMinutes(0); upperBound.setSeconds(0); upperBound.setMilliseconds(0);
        upperBound = upperBound.getTime();
        upperBound = upperBound + (24*60*60*1000); // i.e. start with end of today

        var promises = [];
        for(var i = 0; i < daysHistory; i++) {

            var lowerBound = upperBound - (24*60*60*1000);
            promises.push(db.getUserActivityByTypeBetween('pageView', lowerBound, upperBound));
            upperBound = lowerBound;

        }

        Promise.all(promises).then(results => {

            var uniqueVisitors = []; // all days
            var peoplePromises = [];

            results.forEach(result => {

                if(result.length > 0) {
                    var uniqueUsers = [];

                    result.forEach(pageView => {
                        if(pageView.pageID === 'learningJournal') {

                            // counting users
                            var isFound = false;
                            for(var j = 0; j < uniqueUsers.length; j++) {
                                if(uniqueUsers[j] === pageView.personID) {
                                    isFound = true;
                                    break;
                                }
                            }
                            if(!isFound) {
                                uniqueUsers.push(pageView.personID);

                                var personFound = false;
                                for(var k = 0; k < uniqueVisitors.length; k++) {
                                    if(uniqueVisitors[k].personID === pageView.personID) {
                                        uniqueVisitors[k].pageViews = uniqueVisitors[k].pageViews + 1;
                                        personFound = true;
                                        break;
                                    }
                                }
                                if(!personFound) {
                                    uniqueVisitors.push({ 
                                        personID: pageView.personID,
                                        pageViews: 1
                                    });
                                    peoplePromises.push(db.getPerson(pageView.personID));
                                } 
                            } 
                        }
                    });
                }
            })

            Promise.all(peoplePromises).then(people => {


                // remove employees who have left the company
                // because we can't get their details anymore
                for(var p = people.length - 1; p > -1; p--) { 
                    if(!people[p])
                        people.splice(p,1);
                }

                people.forEach(person => {
                    for(var p = 0; p < uniqueVisitors.length; p++) {
                        if(person.id === uniqueVisitors[p].personID) {
                            person.pageViews = uniqueVisitors[p].pageViews;
                            break;
                        }
                    }
                })

                people.sort( (a,b) => { return b.pageViews - a.pageViews } );
                this.setState({visitors: people});           
            })
        })
    });
    }

    getTopJournalEntries() {
        return new Promise(() => {
            const startOfMonth = moment().startOf('month').valueOf(); 
            const endOfMonth   = moment().endOf('month').valueOf();

            const promises = [];
            promises.push(db.getJournalEntriesBetween(startOfMonth, endOfMonth));

            Promise.all(promises).then(results => {

                var uniqueVisitors = {};
                var peoplePromises = [];

                results.forEach(result => {

                    if(result.length > 0) {
                        result.forEach(entry => {
                            if(entry.hasOwnProperty('personID')) {
                                if(entry.hasOwnProperty('personID') && entry.personID in uniqueVisitors) {
                                    uniqueVisitors[entry.personID].journalEntries++;
                                } else {
                                    uniqueVisitors[entry.personID] = { 
                                        personID: entry.personID,
                                        journalEntries: 1
                                    };
                                
                                    peoplePromises.push(db.getPerson(entry.personID));
                                }
                            }
                        });
                    }
                })

                Promise.all(peoplePromises).then(people => {

                    // remove employees who have left the company
                    // because we can't get their details anymore
                    for(var p = people.length - 1; p > -1; p--) { 
                        if(!people[p])
                            people.splice(p,1);
                    }

                    people.forEach(person => {
                        if(person.id in uniqueVisitors) {
                            person.journalEntries = uniqueVisitors[person.id].journalEntries;
                        }
                    })

                    people.sort( (a,b) => { return b.journalEntries - a.journalEntries } );
                    this.setState({users: people});           
                })
            })
        });
    }

    updateWelcomeText(text) {
        this.setState({
            content: {
                welcomeText: text
            }
        });
    }

    saveWelcomeText() {
        db.updateLearningJournalContent(this.state.content.id, this.state.content).then(() => {
            this.setState({
                oldContent: {...this.state.content},
                editWelcomeText: false
            });
        });
    }

    cancelEditingWelcomeText() {
        this.setState({
            content: {...this.state.oldContent},
            editWelcomeText: false
        });
    }

    addActivity() {

        if (this.state.timeSpentInHours === 0 && this.state.timeSpentInMinutes === 0) {
           this.setState({
               errorModal: true,
               errorMessage: "Please enter valid value for time spent in hrs and mins (Time spent cannot be 0 mins)."
           });
           return;
        }

        if(moment(this.state.newActivity.learningDate).valueOf() < moment('2022-01-01').valueOf()) {
            this.setState({
                errorModal: true,
                errorMessage: "Please enter a learning activity after 1st January 2022."
            });
            return;
        }

        var dateEntered = new Date(this.state.newActivity.learningDate);
        var spanToDate = new Date(this.state.spanToDate);

        if(this.state.newActivity.learningNotes.length > 0) { 

            var activity = {...this.state.newActivity};
            activity.type = 'learning';
            activity.created = new Date().getTime(); 
            
            var numberOfDays = ((spanToDate.getTime() - dateEntered.getTime()) / (24*60*60*1000)) + 1;
            
            var entries = [...this.state.journalEntries];

            var promises = [];
            for(var i = 0; i < numberOfDays; i++) {

                activity.learningDate = new Date(dateEntered.getTime() + (24*60*60*1000*i)).toISOString().substr(0,10);

                var json = JSON.stringify(activity); // to address byref changes
                var entry = JSON.parse(json); 
                entries.push(entry);
                promises.push(db.updateLearningJournal(entry.id, entry));
            }

            Promise.all(promises).then(() => {

                this.sortEntries(entries);

                this.setState({   
                    journalEntries: entries, 
                    spanToDate: new Date().toISOString().substr(0,10),
                    newActivity: {
                        learningNotes: '',
                        learningDate: new Date().toISOString().substr(0,10),
                        resource: 'book',
                        timeSpent: '15'
                    },
                    timeSpentInHours: 0, 
                    timeSpentInMinutes: 15,
                }, () => this.calculateTime());

            });

        } else {
            this.setState({
                errorModal: true,
                errorMessage: "All fields are required."
            });
            return;
        }
    }

    deleteActivity(activity) {
        db.deleteLearningJournal(activity.id).then(() => {
            var entries = this.state.journalEntries;
            for(var i = 0; i < entries.length; i++) {
                if(entries[i].id === activity.id) {
                    entries.splice(i,1);
                    break;
                }
            }
            this.setState({journalEntries: entries}, () => this.calculateTime());
        });
    }

    updateFromDate(value) {
        this.updateNewActivity( "learningDate", value );
        if(value !== this.state.spanToDate) {
            this.setState({spanToDate: value});
        }
    }

    updateTimeSpent(unit, value) {
        const timeSpent = Number(value);
        let totalTimeSpent = 0;
       
        if (unit === "hours") {
            this.setState({timeSpentInHours: timeSpent});
            totalTimeSpent = (timeSpent * 60) + this.state.timeSpentInMinutes;
        } else {
            this.setState({timeSpentInMinutes: timeSpent});
            totalTimeSpent = (this.state.timeSpentInHours * 60) + timeSpent;
        }
        
        this.updateNewActivity("timeSpent", String(totalTimeSpent));
    }

    updateNewActivity(property, value) {
        this.setState({newActivity: {
            ...this.state.newActivity,
            [property]: value
        }});
    }

    exportToCSV() {

        this.setState({isLoading: true}, () => {

            db.getJournalEntries().then(rows => {


                var people = [];
                rows.forEach(row => {

                    row.employeeName = '';
                    row.learningNotes = '';

                    var isFound = false;
                    for(var i = 0; i < people.length; i++) {
                        if(people[i] === row.personID) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound) people.push(row.personID);
                })

                var promises = [];
                people.forEach(person => {
                    promises.push(db.getPerson(person));
                });

                Promise.all(promises).then((people) => {

                    people.forEach(person => {
                        if(person) {
                            rows.forEach(row => {
                                if(row.personID === person.id) {
                                    if(person.employeeID)
                                        row.employeeID = person.employeeID;
                                    if(person.name)
                                        row.employeeName = person.name.replace(/,/g, "");
                                }
                            })
                        }
                    })
                    let filename = "journalEntries.csv";
                    let contentType = "text/csv;charset=utf-8;";
                
                    var content = "employeeID, name, createdDate, activityDate, isFutureEntry, resourceType, timeSpent, notes \n";
                    for(var i = 0; i < rows.length; i++) {
                        if(!rows[i].employeeID && !rows[i].employeeName) {
                            rows[i].employeeName = 'Ex-Employee';
                            rows[i].employeeID = 'NA';
                        }

                        const isFutureEntry = rows[i].created < moment(rows[i].learningDate).valueOf() ? true : false;
                        content = content + rows[i].employeeID + ', ' + rows[i].employeeName + ', ' +  moment(rows[i].created).format("YYYY-MM-DD")  + ', ' + rows[i].learningDate + ', ' + isFutureEntry + ', ' + rows[i].resource  + ', ' + rows[i].timeSpent + ', ' + rows[i].learningNotes.replace(/,/g, "") + '\n';
                    }
                
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
                        navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        var a = document.createElement('a');
                        a.download = filename;
                        a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
        
                    this.setState({isLoading: false});   
                })
            });
        })
    }


    newResource(r) {
        var resources = this.state.resources;
        resources.push(r);
        this.setState({resources: resources});
    }
    updatedResource(r) {
        var resources = this.state.resources;
        for(var i = 0; i < resources.length; i++) {
            if(resources[i].id === r.id) {
                resources[i] = r;
                break;
            }
        }
        this.setState({resources: resources});
    }

    deleteResource(r) {
        let resources = this.state.resources;
        for(let i = 0; i < resources.length; i++) {
            if(resources[i].id === r.id) {
                resources.splice(i,1);
                break;
            }
        }
        this.setState({resources: resources});
    }

    getResourceEditor(r) {
        if((this.state.isEditor || this.props.user.person.isAdmin) && !this.state.isLoading) {
            if(r)
                return <ResourceEditor resource={r} onUpdated={(r) => this.updatedResource(r)} onDeleted={(r) => this.deleteResource(r)}/>
            else 
                return <ResourceEditor onCreated={(r) => this.newResource(r)} />
        }
    }


    getEditor() {
        if((this.state.isEditor || this.props.user.person.isAdmin) && !this.state.isLoading) {
            return <TextEditor 
                        content={this.state.content.welcomeText} 
                        onChange={(e) => this.updateWelcomeText(e)} 
                    />
        }
    }

    getHTML(value) {
        if(value)
            return Parser(value);
        else 
            return "";
    }

    employeeCard() {
        if(this.state.person)
        return (
            <PersonCard person={this.state.person} showThumb />
        )
    }
    showInEmployeeView() {
        if(this.state.directReports.length === 0)
            return "block";
        else
            return "none";
    }
    showInManagerView() {
        if(this.state.directReports.length > 0)
            return "block";
        else
            return "none";
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    getEditControls() {
        if(this.state.isEditor || this.props.user.person.isAdmin)
            return <div>
                <div style={{cursor:'pointer', fontSize:"13px", padding:"11px", display:"inline-block"}} title="Edit Welcome Text" onClick={() =>
                    this.setState({editWelcomeText: !this.state.editWelcomeText})}>
                    <i className="fas fa-edit"></i>
                </div>
                <div style={{cursor:'pointer', fontSize:"16px", fontWeight:800, padding:"11px", display:"inline-block"}} title="Export to CSV" onClick={() => this.exportToCSV()}>
                    <i className="nc-icon nc-cloud-download-93"  style={{marginTop:"4px"}} />
                </div>
            </div>
    }

    showWelcomeText() {
        if(!this.state.editWelcomeText)
            return "block";
        else 
            return "none";
    }
    
    showWelcomeTextEditor() {
        if(this.state.editWelcomeText)
            return "block";
        else 
            return "none";
    }

    getTypeDescriptor(type) {
        switch (type) {
            case 'book':
                return 'Book';
            case 'coaching':    
                return 'Coaching';
            case 'documentatary':
                return 'Documentary';
            case 'english':
                return 'English Course';
            case 'functionalTraining':
                return 'Functional Training';
            case 'generalLearning':
                return 'General Development Class';
            case 'goLead':
                return 'GoLead';
            case 'linkedIn':
                return 'LinkedIn Learning';
            case 'otherExternal':
                return 'Other External Resource';
            case 'otherInternal':
                return 'Other Internal Resource';
            case 'peerLearning':
                return 'Peer Learning';
            case 'podcast':
                return 'Podcast';
            case 'produgie':
                return 'Produgie';
            case 'youTube':
                return 'YouTube';
            default:
                return '';
        } 
    }

    updateReminderFrequency(frequency) {
        this.setState({reminderFrequency: frequency});
        
        db.getPerson(this.props.user.person.id).then(person => {
            person.learningJournalReminders = frequency;
            db.updatePerson(person.id, person);
        });
    }

    getActivityCharts() {
        if(this.state.isEditor  || this.props.user.person.isAdmin ) {

            const pageViewSummaryChart = {
                data: (canvas) => {
                  let ctx = canvas.getContext("2d");
              
                  let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                  gradientStroke.addColorStop(0, "#18ce0f");
                  gradientStroke.addColorStop(1, chartColor);
              
                  let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
                  gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
                  gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));
            
                  return {
                    labels: this.state.pageViewSummary.labels, 
                    datasets: [
                      {
                        label: "Page Views",
                        borderColor: "green",
                        pointHoverRadius: 0,
                        pointRadius: 0,
                        fill: false,
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                        barPercentage: 1,
                        data: this.state.pageViewSummary.data
                      },
                    ],
                  };
                },
                options: {
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: true,
                    intersect: false
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          fontColor: "#9f9f9f",
                          beginAtZero: false,
                          maxTicksLimit: 5,
                        },
                        gridLines: {
                          drawBorder: false,
                          zeroLineColor: "transparent",
                          color: "rgba(255,255,255,0.05)",
                        },
                      },
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          drawBorder: false,
                          color: "rgba(255,255,255,0.1)",
                          zeroLineColor: "transparent",
                          display: false,
                        },
                        ticks: {
                          padding: 20,
                          fontColor: "#9f9f9f",
                        },
                      },
                    ],
                  },
                },
            };
        
            const uniqueUsersSummaryChart = {
              data: (canvas) => {
                let ctx = canvas.getContext("2d");
            
                let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                gradientStroke.addColorStop(0, "#18ce0f");
                gradientStroke.addColorStop(1, chartColor);
            
                let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
                gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
                gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));
          
                return {
                  labels: this.state.uniqueUsersSummary.labels, 
                  datasets: [
                    {
                      label: "Unique Users",
                      borderColor: "orange",
                      pointHoverRadius: 0,
                      pointRadius: 0,
                      fill: false,
                      backgroundColor: gradientFill,
                      borderWidth: 3,
                      barPercentage: 1,
                      data: this.state.uniqueUsersSummary.data
                    },
                  ],
                };
              },
              options: {
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: true,
                  intersect: false
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        fontColor: "#9f9f9f",
                        beginAtZero: false,
                        maxTicksLimit: 5,
                      },
                      gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                        display: false,
                      },
                      ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f",
                      },
                    },
                  ],
                },
              },
            };


            return <div>
                
                <br />
                <Card>
                    
                    <CardHeader style={{height:"6px", backgroundColor:"gray"}}>

                    </CardHeader>
                    <CardBody>
                        <h6 className="big-title">
                            page views - last 2 weeks
                        </h6>
                        <Line
                            data={pageViewSummaryChart.data}
                            options={pageViewSummaryChart.options}
                            height={380}
                            width={828}
                        />
                    </CardBody>
                </Card> 
                <br />
                <Card>
                    <CardHeader style={{height:"6px", backgroundColor:"gray"}}>

                    </CardHeader>
                    <CardBody>
                        <h6 className="big-title">
                            unique users - last 2 weeks
                        </h6>
                        <Line
                            data={uniqueUsersSummaryChart.data}
                            options={uniqueUsersSummaryChart.options}
                            height={380}
                            width={828}
                        />
                    </CardBody>
                </Card>
                <br />
                <Card>
                    <CardHeader style={{height:"6px", backgroundColor:"gray"}}>

                    </CardHeader>
                    <CardBody>
                        <Row style={{marginBottom:"11px"}}>
                            <Col xs="7">
                                TOP PAGE VIEWS
                                <div style={{cursor:'pointer', fontSize:"16px", fontWeight:800, paddingLeft:"11px", display:"inline-block"}} title="Export Top Page Views" onClick={() => this.exportWidgetData("topPageViews", "pageViews", this.state.visitors)}>
                                    <i className="nc-icon nc-cloud-download-93" />
                                </div>
                            </Col>
                            <Col xs="5" style={{textAlign:"right", fontSize:"10px", color:"#343434"}}>
                            Unique Visitors: {this.state.visitors.length}
                            </Col>
                        </Row>
                        
                        <Table>
                            <thead>
                            <tr style={{fontSize:"12px", fontWeight:600}}>
                                <td>
                                Name
                                </td>
                                <td style={{textAlign:"right"}}>
                                Page Views
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.visitors.slice(0, 10).map((person, index) => (
                            <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                                <td>
                                {person.name}
                                </td>
                                <td style={{textAlign:"right"}}>
                                {person.pageViews}
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{height:"6px", backgroundColor:"gray"}}>

                    </CardHeader>
                    <CardBody>
                        <Row style={{marginBottom:"11px"}}>
                            <Col xs="7">
                                TOP JOURNAL ENTRIES
                                <div style={{cursor:'pointer', fontSize:"16px", fontWeight:800, paddingLeft:"11px", display:"inline-block"}} title="Export Top Journal Entries" onClick={() => this.exportWidgetData("topJournalEntries", "journalEntries", this.state.users)}>
                                    <i className="nc-icon nc-cloud-download-93"/>
                                </div>
                            </Col>
                            <Col xs="5" style={{textAlign:"right", fontSize:"10px", color:"#343434"}}>
                            Unique Users: {this.state.users.length}
                            </Col>
                        </Row>
                        
                        <Table>
                            <thead>
                            <tr style={{fontSize:"12px", fontWeight:600}}>
                                <td>
                                    Name
                                </td>
                                <td style={{textAlign:"right"}}>
                                    Journal Entries
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.users.slice(0, 10).map((person, index) => (
                            <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                                <td>
                                    {person.name}
                                </td>
                                <td style={{textAlign:"right"}}>
                                    {person.journalEntries}
                                </td>
                            </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        }
    }

    toggleErrorModal() {
        this.setState({errorModal: !this.state.errorModal}); 
    }

    handleErrorModalClose = () => {
        this.setState({ errorModal: false });
    }

    exportWidgetData(fileName, columnName, usersData) {

        this.setState({isLoading: true}, () => {

            const filename = `${fileName}.csv`;
            const contentType = "text/csv;charset=utf-8;";
        
            let content = `employeeID, name, ${columnName} \n`;
            for(let i = 0; i < usersData.length; i++) {
                content = content + usersData[i].employeeID + ', ' + usersData[i].name.replace(/,/g, "") + ', ' + usersData[i][columnName] + '\n';
            }
        
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                const blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                let a = document.createElement('a');
                a.download = filename;
                a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

            this.setState({isLoading: false});   
        })
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        
        const timeSpentTrendChart = {
            data: (canvas) => {
              let ctx = canvas.getContext("2d");
          
              let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, "#0bb313");
              gradientStroke.addColorStop(1, chartColor);
          
              let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
              gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.8));
        
              return {
                labels: this.state.timeSpentChart.labels, 
                datasets: [
                  {
                    label: "Minutes Spent",
                    borderColor: "#0bb313",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1,
                    data: this.state.timeSpentChart.data
                  },
                ],
              };
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                intersect: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "rgba(255,255,255,0.05)",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(255,255,255,0.1)",
                      zeroLineColor: "transparent",
                      display: false,
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f",
                    },
                  },
                ],
              },
            },
        };

        

        return (
            <div style={{padding:"10px"}}>
            
                <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    .tooltip {
                        position: relative;
                        display: inline-block;
                        opacity: 1;
                        padding-left: 5px;
                        font-size: 10px;
                        z-index: 1;
                    }
                    .tooltip-text {
                        visibility: hidden;
                        width: 250px;
                        background-color: #66615b;
                        color: #fff;
                        text-align: left;
                        padding: 5px;
                        border-radius: 6px;
                        font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
                        position: absolute;
                        left: 23px;
                        opacity: 0.95;
                        top: -22px;
                    }
                    .tooltip:hover .tooltip-text {
                        visibility: visible;
                    }
                    .tooltip .tooltip-text::after {
                        content: " ";
                        position: absolute;
                        top: 40%;
                        right: 100%;
                        margin-top: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent #66615b transparent transparent;
                    }
                    `
                }
                </style>

                <Row>
                    <Col xs="12">
                        <div style={loadingDiv}>
                            <div className="spin">
                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{display:this.showResults()}}>
                    <Row>
                        <Col xs="12" sm="12" md="9">

                            <Card style={{padding:"20px"}}>
                                <CardBody>
                                    <Row style={{marginTop:"10px"}}>
                                        <Col xs="10">
                                            <h5>Learning Journal</h5>
                                        </Col>
                                        <Col xs="2" style={{textAlign:"right"}} >
                                            {this.getEditControls()}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12">
                                            <div style={{display:this.showWelcomeText()}}>
                                                {this.getHTML(this.state.content.welcomeText)}
                                            </div>
                                            <div style={{display:this.showWelcomeTextEditor(), minHeight:"200px"}}>
                                                {this.getEditor()}
                                            </div>
                                            <div style={{display:this.showWelcomeTextEditor()}}>
                                                <Row style={{marginTop:"50px", justifyContent: "flex-end"}}>
                                                    <Col xs="3" style={{textAlign:"right"}}>
                                                        <Button color="secondary" onClick={() => this.cancelEditingWelcomeText()} size="md">
                                                            Cancel
                                                        </Button>
                                                        <Button color="success" onClick={() => this.saveWelcomeText()} size="md">
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{color:"white", marginTop:"20px", padding:"20px"}}>
                                        <Col xs="12" style={{backgroundColor:"#0bb313", padding:"20px", borderRadius:"8px"}}>
                                            <Row>
                                                <Col xs="12">
                                                    <h6>New Learning Activity</h6>
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInManagerView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    Encourage your employees to set aside time out of work to learn new things and develop new skills.
                                                    If you've arranged a training event, you can help keep their learning journal up-to-date by adding 
                                                    activities here.
                                                </Col>
                                            </Row>
                                            <Row style={{display:this.showInEmployeeView()}} >
                                                <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                                    We want you to make time out of work to learn new things and develop your skills.
                                                    Keep a learning journal of time you spend taking courses, reading books, attending formal training and so on.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" style={{display:this.showInManagerView()}} >
                                                    Employee<br />
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"calc(100% - 40px)"}}>
                                                        <Input placeholder="" 
                                                            type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px", marginBottom:"10px"}}
                                                            value={this.state.personID} onChange={(e) => this.chooseEmployee(e.target.value)} >
                                                                <option value="" >Choose an Employee</option>
                                                                {this.state.directReports.map((person, index) => (
                                                                    <option value={person.id} key={index}>{person.name}</option>
                                                                ))}
                                                        </Input>
                                                    </div>
                                                    <div style={{display:"inline-block", verticalAlign:"top", width:"20px", marginLeft:"20px"}}>
                                                        {this.employeeCard()}
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm="6" style={{display:"none"}}>
                                                    Cycle<br />
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.newActivity.cycle} onChange={(e) => this.updateNewActivity("cycle", e.target.value)} >
                                                            <option value="22h1">2022 H1</option>
                                                    </Input>
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="3">
                                                    Resource 
                                                    <div className="tooltip">
                                                        <i className="nc-icon nc-alert-circle-i"/>
                                                        <span className="tooltip-text">These are the available resources you can choose, kindly check the <strong>FAQ</strong> for the list of learning activities that will be reflected in your learning journal entry.</span>
                                                    </div> <br/>
                                                    <Input placeholder="" 
                                                        type="select" style={{fontSize:"11px"}}
                                                        value={this.state.newActivity.resource} onChange={(e) => this.updateNewActivity("resource", e.target.value)} >
                                                            {this.state.resourceTypes.map((entry, index) => (
                                                                <option value={entry.value} key={index}>{entry.text}</option>
                                                            ))}
                                                    </Input>
                                                    <br />
                                                </Col>
                                                <Col xs="12" sm="3">
                                                    From Date<br />
                                                    <Input
                                                        type="date"
                                                        placeholder=""
                                                        value={this.state.newActivity.learningDate}
                                                        onChange={(e) => this.updateFromDate(e.target.value)}
                                                        style={{height:"30px", fontSize:"11px"}}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="3">
                                                    To Date
                                                    <div className="tooltip">
                                                        <i className="nc-icon nc-alert-circle-i"/>
                                                        <span className="tooltip-text">If you spent time on multiple consecutive days, choose the last day and we will create entries for you for each day.</span>
                                                    </div> <br/>
                                                    <Input
                                                        type="date"
                                                        placeholder=""
                                                        value={this.state.spanToDate}
                                                        onChange={(e) => this.setState( {spanToDate: e.target.value} )}
                                                        style={{height:"30px", fontSize:"11px"}}
                                                    />
                                                </Col>
                                                <Col xs="12" sm="3">
                                                    <div>
                                                        Time Spent per day<br />
                                                    </div>
                                                    <div style={{
                                                        alignItems: "center",
                                                        display: "flex"
                                                    }}>
                                                        <Col xs="4" style={{padding:"0px"}}>
                                                            <Input
                                                                type="select"
                                                                style={{fontSize:"11px"}}
                                                                value={this.state.timeSpentInHours}
                                                                onChange={(e) => this.updateTimeSpent("hours", e.target.value)}
                                                            >
                                                                {this.hoursOptions.map((hours) => (
                                                                    <option value={hours} key={hours}>{hours}</option>
                                                                ))}
                                                            </Input>
                                                        </Col>
                                                        <Col xs="2" style={{padding:"0px"}}>
                                                            <div style={{ fontSize: "11px", paddingLeft: "5px" }}>Hrs</div>
                                                        </Col>
                                                        <Col xs="4" style={{padding:"0px"}}>
                                                            <Input
                                                                type="select" style={{fontSize:"11px"}}
                                                                value={this.state.timeSpentInMinutes} 
                                                                onChange={(e) => this.updateTimeSpent("minutes", e.target.value)} 
                                                            >
                                                                {this.minutesOptions.map((minutes) => (
                                                                    <option value={minutes} key={minutes}>{minutes}</option>
                                                                ))}
                                                            </Input>
                                                        </Col>
                                                        <Col xs="2" style={{padding:"0px"}}>
                                                            <div style={{ fontSize: "11px", paddingLeft: "5px" }}>Mins</div>
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">    
                                                    What did you learn about?<br />
                                                    <TextEditor content={this.state.newActivity.learningNotes} onChange={(e) => this.updateNewActivity("learningNotes", e)} hideToolbar={false} />
                                                    <br />
                                                </Col> 
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" style={{fontSize:"11px", fontWeight:600, marginTop:"12px"}}>
                                            Send Slack Reminders<br />
                                            <Input
                                                type="select"
                                                placeholder=""
                                                value={this.state.reminderFrequency}
                                                onChange={(e) => this.updateReminderFrequency(e.target.value)}
                                                style={{height:"30px", fontSize:"11px"}}
                                            >
                                                <option value="none">None</option>
                                                <option value="daily">Daily</option>
                                                <option value="weekly">Weekly</option>
                                                <option value="monthly">Monthly</option>
                                            </Input>
                                        </Col>
                                        <Col xs="6" style={{textAlign:"right"}}>
                                            <Button onClick={() => this.addActivity()}>Add</Button>
                                            <Modal isOpen={this.state.errorModal} toggle={this.toggleErrorModal} size="lg" >
                                                <ModalBody>
                                                    <div style={{fontWeight:600, color:"#eb6532"}}>ERROR</div>
                                                    {this.state.errorMessage}
                                                </ModalBody>
                                                <ModalFooter style={{padding:"10px", paddingRight:"20px"}}>
                                                    <Button onClick={this.handleErrorModalClose} color="secondary">Okay</Button>
                                                </ModalFooter>
                                            </Modal>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                          <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6">
                                            <h6>Journal Entries</h6>
                                        </Col>
                                        <Col xs="6" style={{textAlign:"right", fontSize:"12px", color:"green"}}>
                                            Total Time Invested: <b>{this.state.totalTime}</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            {
                                                Object.keys(this.state.yearlyJournalEntries).sort((a,b) => Number(b) - Number(a)).map(year => 
                                                    year && <JournalEntriesTable
                                                        key={year} 
                                                        year={year} 
                                                        yearlyJournalEntries={this.state.yearlyJournalEntries[year]}
                                                        totalYearlyTime={this.state.totalYearlyTime[year]} 
                                                        totalMonthlyTime={this.state.totalMonthlyTimeByYear[year]}
                                                        getTypeDescriptor = {this.getTypeDescriptor}
                                                        deleteActivity = {(e) => this.deleteActivity(e)}
                                                        getHTML = {this.getHTML}
                                                    />
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="12" sm="12" md="3">
  
                            <Card>
                                <CardHeader style={{display:"none"}}>
                                    <Row>
                                        <Col sm="7">
                                        <div className="numbers pull-left">8,960</div>
                                        </Col>
                                        <Col sm="5">
                                        <div className="pull-right">
                                            <Badge color="warning" pill>
                                            ~51%
                                            </Badge>
                                        </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody style={{padding:"20px"}}>
                                    <h6 className="big-title">My Learning Activity</h6>
                                    
                                    <Bar
                                        data={timeSpentTrendChart.data}
                                        options={timeSpentTrendChart.options}
                                        height={180}
                                        
                                    />
                                    
                                </CardBody>
                                
                            </Card>


                            <Row style={{marginTop:"50px"}}>
                                <Col xs="6">
                                    
                                </Col>
                                <Col xs="6" style={{textAlign:"right", verticalAlign:"top", marginTop:"-15px"}}>
                                    {this.getResourceEditor()}
                                </Col>
                            </Row>

                            {this.state.resources.map((resource, index) => (
                            <Card  key={index} style={{marginTop:"10px"}}>
                                 <div style={{backgroundSize: "cover", height:"150px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                                    backgroundImage:`url(${resource.imageURL})`, backgroundRepeat:"no-repeat", backgroundPosition: "center", borderBottom:"1px solid black", cursor:"pointer"}}
                                    onClick={() => { window.open(resource.url) }}
                                    >
                                        
                                </div>
                                <CardBody style={{padding:"20px"}}>
                                    <Row>
                                        <Col xs="12">
                                            <h5>{resource.title}</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="ql-editor" xs="12">
                                            {this.getHTML(resource.details)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            {this.getResourceEditor(resource)}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            ))}

                            {this.getActivityCharts()}
                        </Col>
                    </Row>
                  
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

LearningJournal = connect(mapStateToProps)(LearningJournal);
export default LearningJournal;