import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap'

import logo from '../../assets/img/goConnectIcon.png'
import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

class NexusNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      othersPresent: [],
      isPersonModalOpen: false,
    }
    this.showPersonModal = this.showPersonModal.bind(this)
    this.closePersonModal = this.closePersonModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }

    if (e.othersPresent !== this.props.othersPresent) {
      this.setState({ othersPresent: this.props.othersPresent })
    }
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white'
    } else {
      newState.color = 'navbar-transparent'
    }
    this.setState(newState)
  }

  showOtherPeople() {
    if (this.state.othersPresent.length > 0) return 'list-item'
    return 'none'
  }

  showTotalOthers() {
    if (this.props.totalOthers > 0) {return 'inline-block'}
    return 'none'
  }

  showPersonModal() {
    this.setState({
      isPersonModalOpen: true,
    })
  }

  closePersonModal() {
    this.setState({
      isPersonModalOpen: false,
    })
  }

  render() {
    return (
      <div>

        <Navbar
          className={classnames('navbar-absolute fixed-top', 'bg-white')}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{ cursor: 'pointer' }}>
              <div style={{ display: 'inline-block' }}>
                <img
                  src={logo}
                  alt="react-logo"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    marginLeft: '4px',
                    marginRight: '10px',
                    height: '34px',
                    paddingRight: '0px',
                  }}
                />
              </div>
              <div style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold' }}>
                Nexus Analytics
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <NavItem
                  style={{
                    display: this.showOtherPeople(),
                    backgroundImage: 'linear-gradient(to right, #ffffff, #efefef)',
                    borderRadius: '0px 10px 10px 0px',
                    marginRight: '20px',
                  }}
                  title="Other GoTroops here with you."
                >
                  <NavLink>
                    {this.state.othersPresent.map((person, index) => (
                      <div style={{ marginTop: '5px', marginRight: '10px', display: 'inline-block' }} key={index}>
                        <PersonCard person={person} showThumb />
                      </div>
                    ))}
                    <div style={{
                      display: this.showTotalOthers(),
                      height: '27px',
                      width: '27px',
                      borderRadius: '4px',
                      verticalAlign: 'top',
                      marginTop: '5px',
                      marginRight: '0px',
                      backgroundColor: '#aaaaaa',
                      textAlign: 'center',
                      color: '#dedede',
                      fontSize: '10px',
                    }}
                    >
                      +
                      {this.props.totalOthers}
                    </div>
                  </NavLink>
                </NavItem>

                <ProfileMenuDropdown user={this.props.user} showPersonModal={this.showPersonModal} />

                <PersonCard
                  person={this.props.user.person}
                  showPersonModal={this.state.isPersonModalOpen}
                  closePersonModal={this.closePersonModal}
                />

              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-class-assign
NexusNavbar = connect(mapStateToProps)(NexusNavbar)
export default NexusNavbar
