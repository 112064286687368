import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
} from 'reactstrap'

import { db } from '../../assets/firebase'
import logo from '../../assets/img/gojekIcon.png'
import AdminTools from '../../views/learningJournal/AdminTools'
import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

class LearningJournalNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
      isEditor: false,
      isPersonModalOpen: false,
    }

    this.showPersonModal = this.showPersonModal.bind(this)
    this.closePersonModal = this.closePersonModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor)

    this.getLDTeamMembers()
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993
      && e.history.location.pathname !== e.location.pathname
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }

    if (e.othersPresent !== this.props.othersPresent) {
      this.setState({ othersPresent: this.props.othersPresent })
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
      })
    } else {
      this.setState({
        color: 'navbar-transparent',
      })
    }
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white'
    } else {
      newState.color = 'navbar-transparent'
    }
    this.setState(newState)
  }

  getLDTeamMembers() {
    db.getTeamMembers('pJWkFvMBsxBMcp99cZvj').then((teamMembers) => { // hard-coded to L&D team
      for (let i = 0; i < teamMembers.length; i += 1) {
        if (teamMembers[i].personID === this.props.user.person.id) {
          this.setState({ isEditor: true })
          break
        }
      }
    })
  }

  getAdminTools() {
    if (this.state.isEditor || this.props.user.person.isAdmin) {
      return <AdminTools />
    }
  }

  showPersonModal() {
    this.setState({
      isPersonModalOpen: true,
    })
  }

  closePersonModal() {
    this.setState({
      isPersonModalOpen: false,
    })
  }

  render() {
    return (
      <div>

        <Navbar
          className={classnames('navbar-absolute fixed-top', 'bg-white')}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{ cursor: 'pointer' }}>
              <div style={{ display: 'inline-block' }}>
                <img
                  src={logo}
                  alt="gojek-logo"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    marginLeft: '4px',
                    marginRight: '10px',
                    height: '34px',
                    paddingRight: '0px',
                  }}
                />
              </div>
              <div style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold' }}>
                People and Culture
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>

                <ProfileMenuDropdown user={this.props.user} showPersonModal={this.showPersonModal} />

                <PersonCard
                  person={this.props.user.person}
                  showPersonModal={this.state.isPersonModalOpen}
                  closePersonModal={this.closePersonModal}
                />

                <div>
                  {this.getAdminTools()}
                </div>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

LearningJournalNavbar = connect(mapStateToProps)(LearningJournalNavbar)
export default LearningJournalNavbar
