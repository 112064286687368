import moment from 'moment'
import React from 'react'
import {
  Card, CardBody, Col,
  Pagination, PaginationItem, PaginationLink, Row, Table,
} from 'reactstrap'

import { strings } from '../../assets/utils'
import {
  COLORS, getSLARuleInMins, ISSUES_PAGE_LIMIT, SEVERITY_COLORS,
} from './utils'

export default function IssuesTable({
  offset, totalIssues, handlePaginationClick, issues, sortIssues, page,
}) {
  const getDaysSinceReportedColor = (createdDate, severity, status, fromTeamID, toPodID) => {
    let daysSinceReportedColor = COLORS.green
    const SLA_RULES = getSLARuleInMins(fromTeamID, toPodID)
    const currTime = moment()
    if (status === 'Active') {
      const timeDiff = currTime.diff(moment(createdDate))
      const breachTime = (SLA_RULES[severity].timeToResolution) * (60 * 1000)

      if (timeDiff >= breachTime) {
        daysSinceReportedColor = COLORS.red
      } else if (timeDiff > (breachTime - (0.17 * breachTime)) && timeDiff < breachTime) {
        daysSinceReportedColor = COLORS.orange
      }
    }

    return daysSinceReportedColor
  }

  const totalPages = Math.ceil(totalIssues / ISSUES_PAGE_LIMIT)
  const pageLimit = ((page + 1) * 15) >= totalPages ? (totalPages % 15) : 15

  const getDaysSinceReported = (createdDate) => {
    const currTime = moment()

    return currTime.diff(moment(createdDate), 'days')
  }

  const shouldDisableNext = () => {
    if ((offset === totalPages - 1) || ((page + 1) * 15) >= totalPages) {
      return true
    }
    return false
  }

  const shouldDisablePrev = () => {
    if ((offset === 0) || ((page - 1) * 15) < 0) {
      return true
    }
    return false
  }

  return (
    <Card>
      <CardBody>
        <Table striped style={{ padding: '10px' }}>
          <thead>
            <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
              <td
                style={{ width: '80px', color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('jira', issues)}
              >
                Jira Ticket

              </td>
              <td>Summary</td>
              <td
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('severity', issues)}
              >
                Severity

              </td>
              <td
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('pdg', issues)}
              >
                PDG

              </td>
              <td
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('pod', issues)}
              >
                Pod

              </td>
              <td
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('status', issues)}
              >
                Status

              </td>
              <td
                style={{ textAlign: 'center', color: 'green', cursor: 'pointer' }}
                onClick={() => sortIssues('created', issues)}
              >
                Days since reported

              </td>
              <td
                style={{
                  width: '80px', textAlign: 'center', color: 'green', cursor: 'pointer',
                }}
                onClick={() => sortIssues('created', issues)}
              >
                Created
              </td>
            </tr>
          </thead>
          <tbody>
            {issues.map((issue) => (
              <tr style={{ fontSize: '11px', position: 'relative' }} key={issue.jira.id}>
                <td style={{ verticalAlign: 'top' }}>
                  <a
                    href={`https://go-jek.atlassian.net/browse/${issue.jira.key}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: 'black',
                    }}
                  >
                    {issue.jira.key}
                  </a>
                </td>
                <td style={{ verticalAlign: 'top', textTransform: 'capitalize' }}>{issue.jira.title}</td>
                <td style={{
                  verticalAlign: 'top',
                  textTransform: 'capitalize',
                  color: SEVERITY_COLORS[issue.severity],
                }}
                >
                  {issue.severity}
                </td>
                <td style={{ verticalAlign: 'top' }} title={issue.toTeamName}>{strings.trim(issue.toTeamName, 21)}</td>
                <td style={{ verticalAlign: 'top' }} title={issue.toPodName}>{strings.trim(issue.toPodName, 21)}</td>
                <td style={{ verticalAlign: 'top', textTransform: 'capitalize' }}>{issue.nexusStatus}</td>
                <td style={{
                  verticalAlign: 'top',
                  color: getDaysSinceReportedColor(
                      issue.created,
                      issue.severity,
                      issue.status,
                      issue.fromTeamID,
                      issue.toPodID,
                  ),
                  textAlign: 'center',
                }}
                >
                  {getDaysSinceReported(issue.created)}
                </td>
                <td style={{ verticalAlign: 'top', textAlign: 'center' }}>
                  {moment.utc(issue.created).format('DD/MM/YYYY')}

                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col xs="10" style={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination>
              <PaginationItem disabled={shouldDisablePrev()}>
                <PaginationLink
                  tag="button"
                  previous
                  onClick={() => handlePaginationClick(offset, offset - 1, true)}
                >
                  {'<<'}
                </PaginationLink>
              </PaginationItem>
              {Array(pageLimit).fill(0).map((_, index) => (
                <PaginationItem active={(index + (page * 15)) === offset} key={(index + (page * 15))} size="lg">
                  <PaginationLink
                    tag="button"
                    onClick={() => handlePaginationClick(offset, (index + (page * 15)))}
                    className="page-link"
                    disabled={(index + (page * 15)) === offset}
                  >
                    {(index + (page * 15)) + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={shouldDisableNext()}>
                <PaginationLink
                  tag="button"
                  next
                  onClick={() => handlePaginationClick(offset, offset + 1, false)}
                >
                  {'>>'}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
          <Col xs="2" style={{ display: 'flex', paddingTop: '4px' }}>
            {`Page ${offset + 1} of ${totalPages}`}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
