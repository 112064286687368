import React from 'react'
import { Col, Row } from 'reactstrap'

import { getHTML } from '../../utils'
import iconStyle from '../constants'

function SuccessCriterionRow({ criterion, index }) {
  return (
    <div key={index} style={{ whiteSpace: 'nowrap' }}>
      <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
        <i style={iconStyle} className="nc-icon nc-check-2" />
      </div>
      <div style={{
        display: 'inline-block', marginLeft: '6px', whiteSpace: 'normal', verticalAlign: 'top',
      }}
      >
        <div style={{ color: '#232323' }}>{criterion.description}</div>
        <br />
      </div>
    </div>
  )
}

function BusinessTab({ projectRef }) {
  return projectRef && (
    <>
      <br />
      <Row>
        <Col xs="12" sm="12" md="3" lg="2">
          <b>PROBLEM STATEMENT</b>
        </Col>
        <Col className="ql-editor" xs="12" sm="12" md="9" lg="10">
          {getHTML(projectRef.problemStatement)}
        </Col>
      </Row>

      <br />
      <Row>
        <Col xs="12">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="3" lg="2">
          <b>VISION</b>
        </Col>
        <Col className="ql-editor" xs="12" sm="12" md="9" lg="10">
          {getHTML(projectRef.vision)}
        </Col>
      </Row>

      <br />
      <Row>
        <Col xs="12">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="3" lg="2">
          <b>SOLUTION CONCEPT</b>
        </Col>
        <Col className="ql-editor" xs="12" sm="12" md="9" lg="10">
          {getHTML(projectRef.solutionConcept)}
        </Col>
      </Row>

      <br />
      <Row>
        <Col xs="12">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="3" lg="2">
          <b>SUCCESS CRITERIA</b>
        </Col>
        {projectRef.successCriteria && (
          <Col xs="12" sm="12" md="9" lg="10" style={{ paddingTop: '10px', position: 'relative' }}>
            {
              projectRef.successCriteria.map((criterion, index) => (
                <SuccessCriterionRow criterion={criterion} index={index} key={index} />
              ))
            }
          </Col>
        )}
      </Row>
    </>
  )
}
export default BusinessTab
