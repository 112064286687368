import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { telemetry } from 'assets/utils';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row, Col,
  } from "reactstrap";

  
  import StandardAdoptionMatrix from "components/StandardAdoptionMatrix/StandardAdoptionMatrix";
  import StandardCard from "components/StandardCard/StandardCard.js"; 
  import StandardEditor from "components/StandardEditor/StandardEditor.js"; 
  
  class GoCodeStandards extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        scoreCard: {
            platforms: [],
            products: [],

            adoption: 0,
        },
        standards: [],
        isLoading: true,
        queryText: '',
        statusFilter: 'approved',
        tpms: [],
        teams: [],
        loopCounter: 0
      }

    }

    componentDidMount() {
        
        this.getLinkedStandard();

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "goCodeStandards", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    getLinkedStandard() {

        if(this.props.standardID) {
            db.getStandard(this.props.standardID).then(standard => {
                this.setState({linkedStandard: standard});
            });
        }
    }

    linkedStandard() {
        if(this.state.linkedStandard) {
            return ( 
                <div style={{display:"none"}}>
                    <StandardCard 
                        standard={this.state.linkedStandard} 
                        onUpdated={(standard) => this.standardUpdated(standard)} 
                        onDeleted={(standard) => this.standardDeleted(standard)} 
                        openByDefault />
                </div>
            )
        }   
    }

    standardCreated(standard) {
        var standards = this.state.standards;
        standards.push(standard);
        this.setState({standards: standards});
    }
    
    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    
    render() {

        return (
            <div style={{padding:"20px"}}>
                <Row>
                    <Col sm="12" >
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="6">
                                        <CardTitle tag="h4">Initiatives / Standards</CardTitle>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <div style={{textAlign:"right"}}>
                                            <StandardEditor onCreated={(standard) => this.standardCreated(standard)} />
                                        </div>
                                    </Col>
                                </Row>
                                
                            </CardHeader>
                            <CardBody style={{minHeight:"300px"}}>
                                <StandardAdoptionMatrix />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div style={{display:"none"}}>
                    {this.linkedStandard()}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

GoCodeStandards = connect(mapStateToProps)(GoCodeStandards);
export default GoCodeStandards;