import dayjs from 'dayjs'

export const TablePrefix = 'data-gojek-id-raw-internal.extstream'
export const IntegrationTablePrefix = 'g-data-gojek-id-raw-internal.mainstream'

export const AppOptions = [
  {
    label: 'Consumer',
    value: 'gojek',
    id: 'gojek',
    displayOnUI: true,
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.customer.identity',
    isPrefixed: false,
    idIsInteger: true,
    default: true,
    showFilterBy: false,
    countryFieldPath: 'meta.customer.current_country',
    deviceOperatingSystem: 'meta.device.operating_system',
  },
  {
    label: 'Consumer',
    value: 'gojek',
    id: 'service_gojek',
    displayOnUI: false,
    supportWildcardQuery: false,
    userIdentityFieldPath: 'customer.id',
    isPrefixed: false,
    idIsInteger: false,
    showFilterBy: false,
    countryFieldPath: false,
    deviceOperatingSystem: false,
  },
  {
    label: 'Driver',
    value: 'gopartner',
    id: 'gopartner',
    displayOnUI: true,
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.driver.id',
    isPrefixed: true,
    idIsInteger: false,
    showFilterBy: false,
    countryFieldPath: 'meta.driver.signed_up_country',
    deviceOperatingSystem: 'meta.device.operating_system',
  },
  {
    label: 'Merchant',
    value: 'gobiz',
    id: 'gobiz',
    displayOnUI: true,
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.merchant.user.identity',
    isPrefixed: true,
    idIsInteger: true,
    showFilterBy: false,
    countryFieldPath: 'meta.merchant.user.signed_up_country',
    deviceOperatingSystem: 'meta.device.operating_system',
  },
  {
    label: 'GoPay',
    value: 'gopay',
    id: 'gopay',
    displayOnUI: true,
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.customer.identity',
    isPrefixed: true,
    idIsInteger: true,
    showFilterBy: false,
    countryFieldPath: 'meta.customer.current_country',
    deviceOperatingSystem: 'meta.device.operating_system',
  },
  {
    label: 'GMA',
    id: 'gopay_merchant',
    displayOnUI: true,
    showFilterBy: false,
    value: 'gopay_merchant',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.merchant.user.identity',
    isPrefixed: true,
    idIsInteger: true,
    shouldRunUAVCron: true,
    countryFieldPath: 'meta.merchant.user.signed_up_country',
    deviceOperatingSystem: 'meta.device.operating_system',
    hasEventGuid: true,
  },
  {
    label: 'Consumer',
    value: 'gojek',
    id: 'web_risk',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.user.id',
    isPrefixed: true,
    displayOnUI: false,
    idIsInteger: true,
    showFilterBy: false,
    countryFieldPath: false,
    deviceOperatingSystem: false,
  },
  {
    label: 'Consumer',
    value: 'gojek',
    id: 'web_gofinance',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.user.user_id',
    isPrefixed: true,
    idIsInteger: false,
    showFilterBy: false,
    displayOnUI: false,
    countryFieldPath: false,
    deviceOperatingSystem: false,
  },
  {
    label: 'Gobiz Web',
    value: 'web_gobiz',
    showFilterBy: false,
    displayOnUI: true,
    id: 'web_gobiz',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.user.id',
    isPrefixed: true,
    idIsInteger: true,
    countryFieldPath: 'meta.merchant.user.signed_up_country',
    deviceOperatingSystem: false,
  },
  {
    label: 'Consumer',
    value: 'gojek',
    showFilterBy: false,
    displayOnUI: false,
    id: 'web_gopay',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.customer.identity',
    isPrefixed: true,
    idIsInteger: true,
    countryFieldPath: false,
    deviceOperatingSystem: false,
  },
  {
    label: 'Consumer',
    value: 'gojek',
    showFilterBy: false,
    displayOnUI: false,
    id: 'web_gofood',
    supportWildcardQuery: false,
    userIdentityFieldPath: 'meta.customer.identity',
    isPrefixed: true,
    idIsInteger: true,
    countryFieldPath: 'meta.customer.current_country',
    deviceOperatingSystem: false,
  },
  {
    label: 'Nexus',
    value: 'web_nexus',
    id: 'web_nexus',
    userIdentityFieldPath: 'meta.merchant.merchant_id',
  },
  {
    label: 'Snap',
    value: 'web_snap',
    id: 'web_snap',
    userIdentityFieldPath: 'meta.merchant.merchant_id',
  }
]

export const ExcludeTables = [
  // consumer
  'clickstream_com_gojek_clickstream_products_events_adcardevent_log',
  'clickstream_adcardeventt_log',
  'clickstream_adcard_log',
  'clickstream_droppedpropertiesbatch_log',
  'clickstream_health_log',
  'clickstream_web_risk_component_log',
  'clickstream_log_log',
  // web_snap
  'clickstream_web_snap_page_log',
  // web_nexus
  'clickstream_web_nexus_page_log',
  // gopay
  'clickstream_gopay_callsupporttoken_log',
  // gobiz
  'clickstream_gobiz_droppedpropertiesbatch_log',
  'clickstream_gobiz_pos_log_log',
  // gopartner
  'clickstream_gopartner_droppedpropertiesbatch_log',
  // goscreen
  'clickstream_goscreen_adplayer_log',
  // mokapos
  'clickstream_web_mokapos_component_log',
  'clickstream_web_mokapos_page_log',
  'clickstream_mokapos_useraccount_log',
  'clickstream_mokapos_component_log',
  'clickstream_mokapos_configuration_log',
  // web transport
  'clickstream_web_transport_component_log',
  'clickstream_midtrans_dashboard_page_log',
  // gopay merchant
  'clickstream_gopaymerchant_callsupporttoken_log'
]

export const RegexForExcludeTables = [
  /old_bckp/,
  /_health_log/,
  /gopay_merchant/,
  /service_gojek/
]


export const ExcludeProperties = [
  'meta.customer.email',
  'meta.customer.phone',
  'meta.merchant.user.email',
  'meta.merchant.user.phone',
]

export const appTablesForMetaProperties = {
  'gojek': 'clickstream_chat_log',
  'gopartner': 'clickstream_gopartner_chat_log',
  'gobiz': 'clickstream_gobiz_chat_log',
  'gopay': 'clickstream_gopay_page_log',
}

export const ExcludePropertiesRegex = [
  '^__kafka..*',
]

export const PivotsDuration = [
  1,
  7,
  14,
  28,
]

export const PivotsFilter = [
  { label: 'greater than equal to(>=)', value: '>=' },
  { label: 'less than equal to(<=)', value: '<=' },
  { label: 'equals to(=)', value: '=' },
  { label: 'greater than (>)', value: '>' },
  { label: 'less than (<)', value: '<' },
  { label: 'not equal to(!=)', value: '!=' },
]

export const PivotsBoolFilterCondition = [
  { label: '= (is)', value: 'is' },
  { label: '!= (is not)', value: 'is not' },
]

export const PivotsBoolDataType = [
  { label: 'True', value: 'TRUE' },
  { label: 'False', value: 'FALSE' },
  { label: 'Null', value: 'NULL' },
]

export const UAVDriverPropertiesFilterOption = [
  { label: 'event Name', value: 'event_name', data_type: 'string' },
  { label: 'event_guid', value: 'meta.event_guid', data_type: 'string' },
  { label: 'Operating System', value: 'meta.device.operating_system', data_type: 'string' },
  { label: 'Operating System Version', value: 'meta.device.operating_system_version', data_type: 'string' },
  { label: 'App Version', value: 'meta.app.version', data_type: 'string' },
  { label: 'Device Model', value: 'meta.device.device_model', data_type: 'string' },
  { label: 'Device Type', value: 'meta.device.device_type', data_type: 'string' },
]

export const rangePresets = [
  { label: 'In the last 7 days', value: [dayjs().add(-6, 'd'), dayjs()] },
  { label: 'In the last 10 days', value: [dayjs().add(-9, 'd'), dayjs()] },
  { label: 'In the last 14 days', value: [dayjs().add(-13, 'd'), dayjs()] },
  { label: 'In the last 28 days', value: [dayjs().add(-27, 'd'), dayjs()] },
  { label: 'In the last 30 days', value: [dayjs().add(-29, 'd'), dayjs()] },
  { label: 'In the last 60 days', value: [dayjs().add(-59, 'd'), dayjs()] },
  { label: 'In the last 90 days', value: [dayjs().add(-89, 'd'), dayjs()] },
]

const SUPPORTED_BQ_DATATYPES = ['STRING', 'FLOAT64', 'INT64', 'BOOL']

const CATALOG_DATATYPES = ['STRING', 'FLOAT', 'INTEGER', 'BOOLEAN']

export const filterSupportedProperties = (respSchema) => respSchema.filter(
    (schemaItem) => (schemaItem?.recordRepeatedPath?.length < 1 && !schemaItem?.isRepeated) || SUPPORTED_BQ_DATATYPES.includes(schemaItem.data_type),
)

export const filterSupportedDataTypeProperties = (respSchema) => respSchema.filter(
  (schemaItem) => CATALOG_DATATYPES.includes(schemaItem.dataType),
)

export const AppMap = {
  gojek: 'Consumer',
  gopartner: 'Driver',
  gobiz: 'Merchant',
  gopay: 'GoPay',
  goscreen: 'GoScreen',
  web_gobiz: 'Gobiz Web',
  gopay_merchant: 'GoPay Merchant',
  service_gojek: 'Service Gojek',
  web_gopay: 'GoPay Web',
  web_nexus: 'Nexus',
  web_snap: 'Snap'
}

export const MS_PER_DAY = 60 * 60 * 1000 * 24
export const SEVEN_DAYS_IN_MS = 60 * 60 * 1000 * 24 * 7

export const NavigationTabs = [
  // { tabID: 'overview', tabLabel: 'Overview' },
  { tabID: 'trendAndProperties', tabLabel: 'Event Analysis' },
  { tabID: 'userActionView', tabLabel: 'User Action View' },
  // { tabID: 'pivot', tabLabel: 'Pivot' },
  // { tabID: 'cohort', tabLabel: 'Cohort' },
]

export const dataRetentionPeriod = 7
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const DatasetId = 'user_action_view'

export const EventAnalysisOptions = [
  {
    label: 'Events',
    value: 'events',
    firestoreValue: 'total',
  },
  {
    label: 'Users',
    value: 'users',
    firestoreValue: 'uniqueUserCount',
  },
]

export const activeTabCss = {
  backgroundColor: 'green',
  borderStyle: 'none',
  borderWidth: '1px 1px 0px 1px',
  borderColor: 'green',
}

export const activeLinkCss = {
  color: 'white',
  fontWeight: 700,
  fontSize: '12px',
}

export const inputCss = {
  borderWidth: '1px',
  borderColor: 'hsl(0,0%,80%)',
  borderRadius: '4px',
  minHeight: '38px',
}

export const customStyles = {
  option: (provided) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
  singleValue: (provided) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
}

export const InputCss = {
  borderWidth: '1px',
  borderColor: 'hsl(0,0%,80%)',
  borderRadius: '4px',
  minHeight: '38px',
}

export const DisableMarketAndPlatformFilterTables = [
  /web/,
  /service/,
]

export const DefaultdaysForCharts = 7

export const DisabledAppsForWhitelisting = ['goscreen']

export const AppToPersonaMapping = {
  GoPay: 'consumer',
}

export const EventCategories = {
  EVENT_CATALOG: 'Event Catalog',
  EVENT_ANALYSIS: 'Event Analysis',
  DASHBOARD_VIEW: 'Dashboard View',
  CHART_VIEW: 'Chart View',
  USER_ACTION_VIEW: 'User Action view',
}

export const EventActions = {
  APP_SELECTION: 'App selected',
  PRODUCT_SELECTION: 'Product selected',
  EVENT_SEARCH: 'Event search',
  PROPERTY_VIEW: 'Property view',
  FILTER_BY: 'FilterBy clicked',
  APP_SELECTED: 'App selected',
  TABLE_SELECTED: 'Select Table',
  DURATION_SELECTED: 'Duration selected',
  RUN_LIVE_QUERY: 'Run live query',
  ANALYSIS_OPTIONS: 'analysisOption checkbox selected',
  HISTOGRAM: 'Show Histogram Button Clicked',
  GROUP_BY: 'Group By Property',
  PAGE_VIEWED: 'Page Viewed',
  CREATE_DASHBOARD: 'Create Dashboard',
  UPDATE_DASHBOARD: 'Update Dashboard',
  ASSIGN_TEAM: 'Team Assigned',
  DELETE_TEAM: 'Delete Team',
  SWITCH_TAB: 'Switch Tab',
  DASHBOARD_CANCELLED: 'Dashboard Cancelled',
  DASHBOARD_SAVED: 'Dashboard Saved',
  ADD_CHART: 'Add chart',
  EDIT_CANCELLED: 'Chart Editing Cancelled',
  CHART_SAVED: 'Chart Saved',
  CHART_DELETED: 'Chart Deleted',
  CHART_TYPE_SELECTED: 'Chart Type Selected',
  TAB_CLICKED: 'Tab Clicked',
  UAV_LIVE_QUERY: 'updatedRunLiveQuery',
  EVENT_ACTION_ENABLED: 'Enabled run live query',
  EVENT_ACTION_DISABLED: 'Disabled run live query',
}

export const PIIColumnsInClickstreamTables = {
  clickstream_service_gojek_booking_log: [ 'trip_details' ],
  clickstream_component_log: [ 'extension' ],
  clickstream_estimate_log: [ 'address', 'journeys', 'outlet', 'offer' ],
  clickstream_gopartner_component_log: [ 'address', 'driver_details', 'order_flow', 'contact_details', 'driver_preference' ],
  clickstream_order_log: [ 'address', 'outlet', 'offer' ],
  clickstream_page_log: [ 'extension' ],
  clickstream_page_log_test:  [ 'extension' ],
  clickstream_gopartner_driverorder_log: [ 'meta', 'order_flow', 'address', 'driver_order_details', 'service_info', 'chat_detail',
    'referrer', 'metrics', 'error', 'booking_details', 'feature_flag', 'experiment', 'gps' ],      
  clickstream_adcardevent_log: [ 'subscription', 'outlet' ],
  clickstream_apihealth_log: [ 'outlet' ],
  clickstream_apphealth_log: [ 'outlet' ],
  clickstream_chat_log: [ 'order_details',  'error', 'order_detail' ],
  clickstream_gobiz_apihealth_log: [ 'otp_detail' ],
  clickstream_gobiz_chat_log: [ 'error' ],
  clickstream_gobiz_component_log: [ 'otp_detail' ],
  clickstream_gobiz_user_log: [ 'meta' ],
  clickstream_gojek_clickstream_products_events_adcardevent_log: [ 'subscription', 'outlet' ],
  clickstream_gopartner_chat_log: [ 'error', 'chat_detail' ],
  clickstream_gopartner_devanalytics_log: [ 'order_flow' ],
  clickstream_gopartner_page_log: [ 'driver_details', 'order_flow' ],
  clickstream_gopartner_useraccount_log: [ 'meta', 'user_detail', 'experiments', 'countries', 'selected_country',
    'old_user_detail', 'country' ],
  clickstream_gopay_component_log: [ 'transfer_detail', 'user_profile', 'search_detail' ],
  clickstream_gopay_merchant_applifecycle_log: [ 'meta' ],
  clickstream_gopay_merchant_component_log: [ 'meta', 'banner', 'employee_detail', 'edit_employee_data', 'search_detail' ],
  clickstream_gopay_merchant_notificationinfo_log: [ 'notification_detail', 'meta' ],
  clickstream_gopay_merchant_page_log: [ 'meta' ],
  clickstream_gopay_merchant_web_component_log: [ 'meta' ],
  clickstream_gopay_merchant_web_page_log: [ 'meta' ],
  clickstream_gopay_page_log: [ 'transfer_detail' ],
  clickstream_gopay_userjourney_log: [ 'meta' ],
  clickstream_notificationinfo_log: [ 'outlet' ],
  clickstream_useraccount_log: [ 'meta', 'user_detail', 'experiments', 'countries', 'selected_country', 'old_user_detail',
    'country' ],
  clickstream_web_gobiz_useraccount_log: [ 'meta', 'roles_and_permissions' ],
  clickstream_web_gofinance_page_log: [ 'meta', 'page_detail' ],
  clickstream_web_gofood_component_log: [ 'meta' ],
  clickstream_web_gofood_estimate_log: [ 'meta' ],
  clickstream_web_gofood_order_log: [ 'meta' ],
  clickstream_web_gofood_page_log: [ 'meta' ],
  clickstream_web_gopay_component_log: [ 'meta' ],
  clickstream_web_gopay_merchant_component_log: [ 'meta', 'campaign' ],
  clickstream_web_gopay_merchant_page_log: [ 'meta' ],
  clickstream_web_gopay_order_log:  [ 'meta' ],
  clickstream_web_gopay_page_log:  [ 'meta' ],
  clickstream_web_gopay_transaction_log:  [ 'meta' ],
}

export const UAV_MAS_MAPPING = {
  apiPath: '/mercury/user-action-view',
  enabled: process.env.REACT_APP_ENABLE_UAV_MAS_INTEGRATION,
  numOfDaysInCalendar: 30,
  // maxNumOfDaysSelected is used in helperText
  maxNumOfDaysSelected: 3,
  numOfEventsToLoad: 30,
  // info text for freezing calendar window after start date is selected
  helperText: `Maximum of 3 days can be selected`
}

export const specialPrefixedApps = ['gojek', 'web_gobiz', 'service_gojek', 'web_gopay', 'web_nexus', 'web_snap']