import './NexusCollaboration.css'

import React, { useState } from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'

import MyRequestsSection from '../../components/Nexus/MyRequestsSection'
import SelectedRequestDetails from '../../components/Nexus/SelectedRequestDetails'

const requests = [
  {
    title: 'GoFood Post Booking Cards',
    key: 'PMR-1',
    // last created + 1? check out transaction locks - block all reads when a write is happening (always unique)
    status: 'In Progress',
    severity: {
      label: 'HIGHEST: Blocks must-haves',
      value: 'Highest',
    },
    created: 1672790400000,
    modified: 1680652800000,
    createdBy: 'Shripriya Bhat',
    modifiedBy: 'Shripriya Bhat',
    comments: [
      {
        created: 1672790400000,
        authorName: 'Shripriya Bhat',
        authorEmail: 'shripriya.bhat@gojek.com',
        updated: 1672790400000,
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing
           elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        id: 12345,
      },
    ],
    targetDate: 1680652800000,
    countries: [{ label: 'Indonesia', value: 'Indonesia' }, { label: 'Vietnam', value: 'Vietnam' }],
    impactAssessment: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    fromTeamID: 'D3yXmqIOUM16xSc39bKa',
    toTeamID: 'Ii2JI743gSnoF73ikgt9',
    links: [{
      'id': '370078e8-099f-4268-8b6f-4c1b508a2f42',
      'name': 'Nexus Requirements PRD',
      'url': 'https://docs.google.com/document/d/1u20veB57_pg_w7fkh8IzJCQ-qGKOD1EMfbf7cnA3Jto/edit',
      'created': 1683148434307,
    },
    {
      'id': '370078e8-099f-4268-8b6f-4c1b508a26872',
      'name': 'Nexus Onboarding SOP',
      'url': 'https://docs.google.com/spreadsheets/d/1p3M3FmuEIMqwX3g6clmiG0Cbf95GwSCk-b_LaePnYqI/edit#gid=470170888',
      'created': 1683148434307,
    }],
    attachments: [],
    id: 'bcnoiefnoivncirvnkerf',
  },
  {
    title: 'Request 2 Title',
    key: 'PMR-2',
    status: 'To Do',
    severity: {
      label: 'LOW: Nice to Have',
      value: 'Low',
    },
    created: 1672790400000,
    modified: 1680652800000,
    createdBy: 'Shripriya Bhat',
    modifiedBy: 'Shripriya Bhat',
    comments: [
      {
        created: 1672790400000,
        authorName: 'Shripriya Bhat',
        authorEmail: 'shripriya.bhat@gojek.com',
        updated: 1672790400000,
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing
         elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
         Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        id: 12345,
      },
    ],
    targetDate: 1680652800000,
    countries: [{ label: 'Indonesia', value: 'Indonesia' }, { label: 'Vietnam', value: 'Vietnam' }],
    impactAssessment: `Lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    fromTeamID: 'D3yXmqIOUM16xSc39bKa',
    toTeamID: 'Ii2JI743gSnoF73ikgt9',
    id: 'fesgvhteiohjbpoetjwbpojetropnj',
    links: [{
      'id': '370078e8-099f-4268-8b6f-4c1b508a2f42',
      'name': 'Nexus Requirements PRD',
      'url': 'https://docs.google.com/document/d/1u20veB57_pg_w7fkh8IzJCQ-qGKOD1EMfbf7cnA3Jto/edit',
      'created': 1683148434307,
    },
    {
      'id': '370078e8-099f-4268-8b6f-4c1b508a26872',
      'name': 'Nexus Onboarding SOP',
      'url': 'https://docs.google.com/spreadsheets/d/1p3M3FmuEIMqwX3g6clmiG0Cbf95GwSCk-b_LaePnYqI/edit#gid=470170888',
      'created': 1683148434307,
    }],
    attachments: [],
  },
  {
    title: 'Request 3 Title',
    key: 'PMR-3',
    status: 'Done',
    severity: {
      label: 'MEDIUM: Should consider',
      value: 'Medium',
    },
    created: 1672790400000,
    modified: 1680652800000,
    createdBy: 'Shripriya Bhat',
    modifiedBy: 'Shripriya Bhat',
    comments: [
      {
        created: 1672790400000,
        authorName: 'Shripriya Bhat',
        authorEmail: 'shripriya.bhat@gojek.com',
        updated: 1672790400000,
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing
           elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        id: 12345,
      },
    ],
    targetDate: 1680652800000,
    countries: [{ label: 'Indonesia', value: 'Indonesia' }, { label: 'Vietnam', value: 'Vietnam' }],
    impactAssessment: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    fromTeamID: 'D3yXmqIOUM16xSc39bKa',
    toTeamID: 'Ii2JI743gSnoF73ikgt9',
    id: 'hrguihetouihboiuwehnbo',
    links: [{
      'id': '370078e8-099f-4268-8b6f-4c1b508a2f42',
      'name': 'Nexus Requirements PRD',
      'url': 'https://docs.google.com/document/d/1u20veB57_pg_w7fkh8IzJCQ-qGKOD1EMfbf7cnA3Jto/edit',
      'created': 1683148434307,
    },
    {
      'id': '370078e8-099f-4268-8b6f-4c1b508a26872',
      'name': 'Nexus Onboarding SOP',
      'url': 'https://docs.google.com/spreadsheets/d/1p3M3FmuEIMqwX3g6clmiG0Cbf95GwSCk-b_LaePnYqI/edit#gid=470170888',
      'created': 1683148434307,
    }],
    attachments: [],
  },
]

export default function NexusCollaboration({ user }) {
  const [requestSelected, setRequestSelected] = useState(0)

  return (
    <>
      <Card>
        <CardBody className="collaboration-info">
          <p>
            <strong>Welcome!</strong>
          </p>
          <p>
            Nexus Collaboration simplifies how we work between teams by
            capturing your feature requests and requirements and seamlessly
            adding them to the Asana or Jira board of the right team for the
            job. When teams receive your request, it is properly categorized
            and they can set up to be notified within Slack.
          </p>
          <p>
            Having all of their requests in one place makes it easy for them
            to prioritize and track! Even better - when they make updates to
            the task, when comments are added or questions asked, they are
            automatically routed back to you.
          </p>
          <p>
            All you have to do is provide the information in the form below &
            assign it to the intended team. You can track the status of your
            requests on the right hand side and you will be notified via Slack
            if there are any updates to your requests!
          </p>
        </CardBody>
      </Card>
      <Row>
        <Col xs="4">
          <MyRequestsSection
            requests={requests}
            handleCollaborationRequestClick={(index) => setRequestSelected(index)}
            deselectRequest={requestSelected === -1}
            user={user}
          />
        </Col>
        <Col xs="8">
          <SelectedRequestDetails
            user={user}
            request={requests[requestSelected]}
          />
        </Col>
      </Row>
    </>
  )
}
