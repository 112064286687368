import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

//import { telemetry } from 'assets/utils';

// reactstrap components
import {
    Input, 
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
  } from "reactstrap";

  import OKRCard from './OKRCard.js';
  
  import logo from "assets/img/goConnectIcon.png";
  import OKREditor from "components/OKRs/OKREditor"
  class OKRs extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          isLoading: true,
          OKRs: [],
          filteredOKRs: [],
          okrStatusFilter: 'all',
          okrCycleFilter: '22h1',
          okrTypeFilter: 'team',
          isEditOKROpen: false,
      };

      this.toggleEditOKR = this.toggleEditOKR.bind(this);
      this.updateOKRs = this.updateOKRs.bind(this)
    }

    componentDidMount() {

        this.getOKRs();
    }

    getOKRs() {

        db.getTeamOKRs(this.props.team.id).then((OKRs) => {

            OKRs.sort( (a,b) => { return a.number - b.number } );

            var owners = [];
            var promises = [];

            OKRs.forEach(okr => {

                okr.KRs.forEach(kr => {

                    if(kr.ownerID) {
                        if(kr.ownerID.length > 0) {   
                            var isFound = false;
                            for(var o = 0; o < owners.length; o++) {
                                if(owners[o] === okr.ownerID) {
                                    isFound = true;
                                    break;
                                }
                            }
                            if(!isFound) promises.push(db.getPerson(kr.ownerID));
                        }
                    }

                    if(kr.statusReports) {
                        kr.statusReports.forEach(sr => {
                            var isFound = false;
                            for(var o = 0; o < owners.length; o++) {
                                if(owners[o] === sr.personID) {
                                    isFound = true;
                                    break;
                                }
                            }
                            if(!isFound) promises.push(db.getPerson(sr.personID));
                        })
                    } else kr.statusReports = [];
                }) 
            })

            Promise.all(promises).then(people => {

                for(var q = people.length - 1; q > -1; q--)
                    if(!people[q])
                        people.splice(q,1);

                OKRs.forEach(okr => {
                    okr.KRs.forEach(kr => {

                        kr.showStatus = false;
                        if(!kr.statusReports) kr.statusReports = [];

                        kr.statusReports.forEach(sr => {
                            for(var p = 0; p < people.length; p++) {
                                if(sr.personID === people[p].id) {
                                    sr.person = people[p];
                                    break;
                                }
                            }    
                        })

                        for(var p = 0; p < people.length; p++) {
                            if(kr.ownerID === people[p].id) {
                                kr.person = people[p];
                                break;
                            }
                        }
                    });
                    okr.statusColor = 'green';
                });

                this.setState({OKRs: OKRs, isLoading: false}, this.filterOKRs);
            });
        });

    }

    filterOKRs(filter, value) {

        var filtered = [];
        var okrs = this.state.OKRs;

        var cycleFilter = this.state.okrCycleFilter;
        if(filter === 'cycle')
            cycleFilter = value;

        var statusFilter = this.state.okrStatusFilter;
        if(filter === 'status')
            statusFilter = value;

        var typeFilter = this.state.okrTypeFilter;
        if(filter === 'type')
            typeFilter = value;

        this.setState({okrCycleFilter: cycleFilter, okrStatusFilter: statusFilter, okrTypeFilter: typeFilter});

        for(var i = 0; i < okrs.length; i++) {
            if(okrs[i].cycle === cycleFilter)
                if(okrs[i].status === statusFilter || statusFilter === 'all')
                    filtered.push(okrs[i]);                
        }

        this.setState({filteredOKRs: []}, () => {
            this.setState({filteredOKRs: filtered });
        })
    }

    updateOKR(okr) {
        var objs = this.state.OKRs;
        for(var i = 0; i < objs.length; i++) {
            if(objs[i].id === okr.id) {
                objs[i] = okr;
            }
        }
        this.setState({OKRs: objs});
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    toggleEditOKR() {
        this.setState({
            isEditOKROpen: !this.state.isEditOKROpen
        })
    }

    updateOKRs (okrs) {
        this.setState({OKRs: okrs}, this.filterOKRs);
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (
            <div>
                <style>
                    {
                        `
                        @keyframes spinning {
                            from { transform: rotate(0deg) }
                            to { transform: rotate(360deg) }
                        }
                        .spin {
                            animation-name: spinning;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                            /* linear | ease | ease-in | ease-out | ease-in-out */
                            animation-timing-function: linear;
                        }
                        `
                    }
                </style>
                {this.props.canEditOKR && <div style={{ textAlign: 'right'}}><Button color="secondary" size="sm" onClick={this.toggleEditOKR}>Edit OKRs</Button></div>}
                <Row>
                    <Col sm="12" >
                        <Row>
                            <Col xs="12">
                                <div style={loadingDiv}>
                                    <div className="spin">
                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{display:this.showResults(), padding:"10px"}}>
                            <Row style={{marginBottom:"20px"}}>
                                <Col xs="6" sm="3">
                                    Cycle
                                    <Input placeholder="" 
                                        type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                        value={this.state.okrCycleFilter} onChange={(e) => this.filterOKRs("cycle", e.target.value)} >
                                            <option value="21h2">2021 H2</option>
                                            <option value="22h1">2022 H1</option>
                                            <option value="22h2">2022 H2</option>
                                    </Input>
                                </Col>
                                <Col xs="6" sm="3">
                                    Status
                                    <Input placeholder="" 
                                        type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                        value={this.state.statusFilter} onChange={(e) => this.filterOKRs("status", e.target.value)} >
                                            <option value="all">All</option>
                                            <option value="draft">Draft</option>
                                            <option value="approved">Approved</option>
                                            <option value="cancelled">Cancelled</option>
                                            <option value="complete">Complete</option>
                                    </Input>
                                </Col>
                                <Col xs="12" sm="6">
                                    Type
                                    <Input placeholder="" 
                                        type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                        value={this.state.typeFilter} onChange={(e) => this.filterOKRs("type", e.target.value)} >
                                            <option value="team">OKRs for This Team</option>
                                            <option value="supporting">OKRs This Team Has Dependencies On</option> 
                                            <option value="supporting">OKRs That Have Dependencies On This Team</option> 
                                    </Input>
                                </Col>
                            </Row>
                            {this.state.filteredOKRs.map((okr, index) => (
                                <div key={index}>
                                    <OKRCard okr={okr} onUpdate={(okr) => this.updateOKR(okr)} />
                               </div>
                            ))}
                        </div>
                    </Col>
                </Row>
                {this.state.isEditOKROpen && <OKREditor team={this.props.team} isOpen={this.state.isEditOKROpen} toggleEditOKR={this.toggleEditOKR} updateOKRs={this.updateOKRs}/>}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

OKRs = connect(mapStateToProps)(OKRs);
export default OKRs;