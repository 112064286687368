import React from "react";

//redux
import { connect } from 'react-redux'

import { db } from 'assets/firebase';  

import { dateTime, tables } from 'assets/utils';

// reactstrap components
import { Button, 
        Row, Col, 
        Modal, ModalHeader, ModalBody, ModalFooter,
        TabContent, TabPane, Nav, NavItem, NavLink
    } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import StandardAdopter from "components/StandardAdopter/StandardAdopter"; 
import StandardEditor from "components/StandardEditor/StandardEditor.js"; 
import TeamCard from "components/TeamCard/TeamCard.js";

class StandardCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeIndex: 0,
            activeTab: 'overview',
            standard: {
                effort: '',
                value: '',
                links: [],
                tags: []
            },
            owner: {},
            isLoaded: false,
            adoption: [],
            team: {}
        }
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {

        if(!this.state.isLoaded)
            this.getStandard();

        this.setState({modal: !this.state.modal});
    }

    getStandard() {

        db.getStandard(this.props.standard.id).then(standard => {
            
            if(!standard.effort) standard.effort = '';
            if(!standard.value) standard.value = '';
            if(!standard.links) standard.links = [];

            this.setState({standard: standard}, () => {

                this.getOwner();
                this.getTeam();
                this.getAdopters();
            })
        });
    }

    getOwner() {
        if(this.state.standard.ownerID)
            db.getPerson(this.state.standard.ownerID).then((owner => {
                if(owner)
                    this.setState({owner: owner});
            }));
    }
    getTeam() {
        if(this.state.standard.teamID) {   
            db.getTeam(this.state.standard.teamID).then((team => {
                this.setState({team: team});
            }));
        }
    }
    getAdopters() {
        db.getStandardAdoption(this.state.standard.id).then(adoption => {
            this.setState({adoption: adoption});
        })
    }

    standardDeleted(standard) {
        this.props.onDeleted(standard);
        this.toggleModal();
    }

    standardUpdated(standard) {
        this.setState({standard: standard});
        if(this.props.onUpdated)
            this.props.onUpdated(standard);

        this.getOwner();
        this.getTeam();

        db.getStandardAdoption(this.props.standard.id).then(adoption => {
            this.setState({adoption: adoption});
        })
    }

    adopterCreated(adopter) {

        console.log('adopterCreated',adopter);

        var adoption = this.state.adoption;
        adoption.push(adopter);
        this.setState({adoption:adoption});


        if(this.props.onAdoptionCreated)
            this.props.onAdoptionCreated(adopter);
    }

    adopterDeleted(a) {
        var adoption = this.state.adoption;
        for(var i = 0; i < adoption.length; i++) {
            if(adoption[i].id === a.id) {
                adoption.splice(i, 1);
                break;
            }
        }
        this.setState({adoption: adoption});
    }
    
    adopterUpdated(a) {
        console.log('adopterUpdated',a);

        var adoption = this.state.adoption;
        for(var i = 0; i < adoption.length; i++) {
            if(adoption[i].id === a.id) {
                adoption[i] = a;
                break;
            }
        }
        this.setState({adoption: adoption});

        if(this.props.onAdoptionUpdated)
            this.props.onAdoptionUpdated(a);
    }

    showSponsor() {
        if(this.state.standard.teamID)
            return "block";
        else 
            return "none";
    }
    showOwner(){
        if(this.state.standard.ownerID)
            return "block";
        else
            return "none";
    }
    showEffort() {
        if(this.state.standard.effort.length > 0) 
            return "block";
        else 
            return "none";
    }
    showValue() {
        if(this.state.standard.value.length > 0) 
            return "block";
        else 
            return "none";
    }
    showLinks() {
        if(this.state.standard.links.length > 0)
            return "flex";
        else
            return "none";
    }
    showNewAdopterButton() {
        if(this.state.activeTab==='adoption')
          return "block";
        else
          return "none";
    }
    showTargetDate() {
        if(this.state.standard.targetDate) 
            return "block";
        else
            return "none";
    }

    ownerCard() {
        if(this.state.owner.id) {
            return (
                <PersonCard person={this.state.owner} showThumb />
            )
        }
    }
    teamCard() {
        if(this.state.team.id) {
            return (
                <TeamCard team={this.state.team} showThumb />
            )
        }
    }

    getStatusText(status) {
        if(status === 'proposed')
            return 'Proposed';
        else if (status === 'approved')
            return 'Approved';
        else if (status === 'rejected')
            return 'Rejected';
        else if (status === 'archived')
            return 'Archived';
    }


    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"3px",
            color:"#898989"
        }

        return (

            <div style={{whiteSpace:"normal"}}>
            
                <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            View
                        </Button>
                    </div>
                </div>

                <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col xs="12" style={{textAlign:"left", fontSize:"21px", marginBottom:"17px"}}>
                                {this.state.standard.title}
                            </Col>
                        </Row> 
                        <Row>
                            <Col xs="12">
                                <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'overview'}) }}
                                        >
                                            Overview
                                        </NavLink>
                                    </NavItem>{/*}
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'adoption' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'adoption'}) }}
                                        >
                                            Adoption
                                        </NavLink>
                                    </NavItem>*/}
                                    <NavItem style={{display:this.showLinks()}}>
                                        <NavLink
                                            style={(this.state.activeTab === 'links' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'links'}) }}
                                        >
                                            References
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                
                                <TabContent activeTab={this.state.activeTab} >
                                    <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>
                                        <Row>
                                            <Col xs="6" style={{display:this.showSponsor()}}>
                                                <div style={{display:"inline-block", maxWidth:"30px"}}>
                                                    {this.teamCard()}
                                                </div>
                                                <div style={{display:"inline-block", marginLeft:"15px", }}>
                                                    <div style={{color:"green", fontWeight:"600"}}>Sponsor</div>
                                                    <div style={{verticalAlign:"middle"}}>
                                                        {this.state.team.name}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs="6" style={{display:this.showOwner()}}>
                                                <div style={{display:"inline-block", maxWidth:"30px"}}>
                                                    {this.ownerCard()}
                                                </div>
                                                <div style={{display:"inline-block", marginLeft:"15px", }}>
                                                    <div style={{color:"green", fontWeight:"600"}}>Owner</div>
                                                    <div style={{verticalAlign:"middle"}}>
                                                        {this.state.owner.name}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div style={{color:"green", fontWeight:"600", marginTop:"12px"}}>Description</div>
                                                {this.state.standard.description}<br /><br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" style={{display:this.showValue()}}>
                                                <div style={{color:"green", fontWeight:"600"}}>Business Value</div>
                                                {this.state.standard.value}<br /><br />
                                            </Col>
                                        </Row>
                                        <Row style={{display:this.showEffort()}}>
                                            <Col xs="12">
                                                <div style={{color:"green", fontWeight:"600"}}>Expected Effort</div>
                                                {this.state.standard.effort}<br /><br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="3" style={{display:this.showTargetDate()}}>
                                                <div style={{color:"green", fontWeight:"600"}}>Target Date</div>
                                                {this.state.standard.targetDate}
                                            </Col>
                                            <Col xs="3">
                                                <div style={{color:"green", fontWeight:"600"}}>Status</div>
                                                {this.getStatusText(this.state.standard.status)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <br />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"34px", fontSize:"12px"}}>
                                        <Row style={{marginBottom:"10px", fontWeight:"bold"}}>
                                            <Col xs="1">
                                                
                                            </Col>
                                            <Col xs="6">
                                                Name
                                            </Col>
                                            <Col xs="5" style={{textAlign:"right", color:"#898989"}}>
                                                Added
                                            </Col>
                                        </Row>
                                        {this.state.standard.links.map((link, index) => (    
                                        <Row key={index}>
                                            <Col xs="1">
                                                <i style={icon} className="nc-icon nc-globe" title="link" />
                                            </Col>
                                            <Col xs="8">
                                                <a href={link.url} target="_new" style={{color:"#232323"}}>{link.name}</a><br />
                                            </Col>
                                            <Col xs="3" style={{textAlign:"right", color:"#898989"}}>
                                                {dateTime.dateConverter(link.created)}<br />
                                            </Col>
                                        </Row>
                                        ))}
                                    </TabPane>
                                    {/*}
                                    <TabPane tabId="adoption" style={{minHeight:"210px", paddingTop:"23px", fontSize:"12px"}}>
                                        
                                        <Row style={{padding:"6px", fontSize:"11px", fontWeight:600, color:"green"}}>
                                            <Col xs="2"></Col>
                                            <Col xs="4">Team</Col>
                                            <Col xs="4">Owner</Col>
                                            <Col xs="2">Status / Target</Col>
                                        </Row>

                                        {this.state.adoption.map((adopter, index) => (    
                                        <div key={index} style={{width:"100%", padding:"0px", paddingLeft:"4px", backgroundColor:tables.rowColor(index)}}>
                                            
                                            <StandardAdopter adopter={adopter} key={index} standard={this.state.standard}
                                                onDeleted={(a) => this.adopterDeleted(a)} onUpdated={(a) => this.adopterUpdated(a)} />
                                        
                                        </div>                                                    
                                        ))}
                                    </TabPane>
                                    */}
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{backgroundColor:"#efefef", padding:"10px"}}>
                        <div style={{display:this.showNewAdopterButton()}}>
                            <StandardAdopter standard={this.state.standard} onCreated={(a) => this.adopterCreated(a)} />
                        </div>
                        <StandardEditor standard={this.state.standard} onUpdated={(standard) => this.standardUpdated(standard)} onDeleted={(standard) => this.standardDeleted(standard)} />
                    </ModalFooter>
                </Modal>
            </div>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

StandardCard = connect(mapStateToProps)(StandardCard);
export default StandardCard;