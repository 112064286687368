import './MyRequestsSection.css'

import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import Chip from '../Chip/Chip'
import CollaborationRequestEditor from './CollaborationRequestEditor'
import { getPriorityColor, getStatusColor } from './constants'

export default function MyRequestsSection({
  requests,
  handleCollaborationRequestClick,
  deselectRequest,
  user,
}) {
  const [isSelected, setIsSelected] = useState(0)

  const handleRequestCardClick = (index) => {
    setIsSelected(index)
    handleCollaborationRequestClick(index)
  }

  useEffect(() => {
    if (deselectRequest) {
      setIsSelected(0)
    }
  })

  return (
    <Card className="myrequests-card-container">
      <div className="myrequests-card-title">
        <h1>My Requests</h1>
        <CollaborationRequestEditor onCreated={() => {}} person={user.person} />
      </div>
      <InputGroup className="myrequests-card-search-bar">
        <Input
          value=""
          placeholder="Search request"
          type="text"
          onChange={() => {}}
          id="search"
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <i className="nc-icon nc-zoom-split search-icon" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {requests.map((request, index) => (
        <RequestCard
          request={request}
          isSelected={isSelected === index}
          handleRequestCardClick={() => handleRequestCardClick(index)}
          key={request.key}
          person={user.person}
        />
      ))}
    </Card>
  )
}

function RequestCard({
  request, isSelected, handleRequestCardClick, person,
}) {
  const requestCardContainerClass = isSelected ?
    'request-card selected' :
    'request-card'
  const {
    title, key, status, severity, created,
  } = request
  return (
    <Card
      className={requestCardContainerClass}
      onClick={handleRequestCardClick}
    >
      <CardBody>
        <div>
          <div className="request-card-title-row">
            <p className="request-card-title">{title}</p>
            <p className="request-card-key">{key}</p>
          </div>
          <div className="request-card-info">
            <div className="tags">
              <Chip text={status} color={getStatusColor(status)} />
              <Chip
                text={severity.label.split(':')[0].toLowerCase()}
                color={getPriorityColor(severity.value)}
              />
            </div>
            <CollaborationRequestEditor person={person} request={request} />
          </div>
          <div className="request-card-timestamp">
            {`Ticket created on ${format(
                new Date(created),
                'dd MMM yyyy, hh:mm a',
            )}`}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
