
import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

import { strings, tables, telemetry } from 'assets/utils';

//reactstrap
import {InputGroup, InputGroupAddon, InputGroupText, Spinner} from "reactstrap";

// react plugin used to create charts
//import { Line, Bar, Doughnut } from "react-chartjs-2";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Table,
    Row,
    Col,
  } from "reactstrap";

import CleverTapEventEditor from "components/CleverTap/CleverTapEventEditor.js";
import { isNumeric } from "redoc";
  
  function numberWithCommas(x) {
    if(isNaN(x))
      return "";

    if(x) 
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
  }
  
  class CleverTap extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        events: [],
        accounts: [],
        owners: [],
        accountFilter: 'All',
        pdgFilter: 'All',
        yearFilter: 2022,
        monthFilter: 5, //new Date().getMonth(),
        drpFilter: 'All',
        queryText: '',
        total: 0,
        totalCost: 1000000,
        monthTotal : 0,
        pdgs: [
          { id: 'unclaimed', name: 'NO OWNER', total: 0 },
          { id: 'thirdParty', name: '3PP', total: 0 },
          { id: 'adTech', name: 'AdTech', total: 0 },
          { id: 'cartography', name: 'Cartography', total: 0 },
          { id: 'commerceEnablement', name: 'Commerce Enablement', total: 0 },
          { id: 'crm', name: 'CRM', total: 0 },
          { id: 'customer', name: 'Customer Platform', total: 0 },
          { id: 'devx', name: 'DevX', total: 0 },
          { id: 'driver', name: 'Driver Platform', total: 0 },
          { id: 'food', name: 'Food', total: 0 },
          { id: 'goclub', name: 'GoClub', total: 0 },
          { id: 'gofin', name: 'GoFin', total: 0 },
          { id: 'groceries', name: 'Groceries', total: 0 },
          { id: 'icp', name: 'ICP', total: 0 },
          { id: 'logistics', name: 'Logistics', total: 0 },
          { id: 'marketplace', name: 'Marketplace', total: 0 },
          { id: 'merchantPayment', name: 'Merchant Payment', total: 0 },
          { id: 'merchant', name: 'Merchant Platform', total: 0 },
          { id: 'ops', name: 'Ops Platform', total: 0 },
          { id: 'pay', name: 'Pay', total: 0 },
          { id: 'play', name: 'Play', total: 0 },
          { id: 'transport', name: 'Transport', total: 0 },
        ]
      }
    }

    componentDidMount() {


      this.getAccounts();
      this.getOwners().then(() => {
        this.getEvents();
        this.filterEvents();
      }) 


      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "cleverTapUsage", "pageView");
    }

    getAccounts() {
        
      db.getCleverTapAccounts().then(accounts => {
        this.setState({accounts: accounts});
      });
    }

    getOwners() {
      return new Promise((resolve, reject) => {
        db.getCleverTapOwners().then(owners => {
          this.setState({owners: owners}, resolve());  
        })
      })
    }

    getEvents() {
      return new Promise((resolve, reject) => {

       // db.getCleverTapEventsYearMonth(7, 2021).then(eventsJul => {
        //  db.getCleverTapEventsYearMonth(8, 2021).then(eventsAug => {

        db.getCleverTapEventsYearMonth(5, 2022).then(events => {

         // eventsJul.forEach(event => events.push(event));
          //eventsAug.forEach(event => events.push(event));

          var accounts = this.state.accounts;
          var owners = this.state.owners;
          var pdgs = this.state.pdgs;

          events.forEach(event => {

            for(var i = 0; i < accounts.length; i++) {
              if(event.accountName === accounts[i].name) {
                if(!accounts[i].total) 
                  accounts[i].total = Number(event.count);
                else 
                  accounts[i].total = Number(event.count) + accounts[i].total;

                break;
              }
            }

            for(var j = 0; j < owners.length; j++) {
              if(event.eventName === owners[j].eventName && owners[j].accountName === event.accountName) {
                
                var totalOwners = 0;

                pdgs.forEach(pdg => {
                  if(owners[j][pdg.id]) {
                    totalOwners = Number(totalOwners + 1);
                  }
                })

                var pdgShare = Number((Number(event.count) / totalOwners).toFixed(0));
                
                pdgs.forEach(pdg => {
                  if(owners[j][pdg.id]) 
                    pdg.total = Number(pdg.total) + Number(pdgShare);
                })

                if(totalOwners === 0) {
                  pdgs[0].total = Number(pdgs[0].total) + Number(event.count);
                }
              }
            }
          })

          

          var monthTotal = 0;

          for(var k = accounts.length - 1; k > -1; k--)
            if(!accounts[k].total)
              accounts.splice(k,1);

          accounts.forEach(account => {
            if(account.total)
              monthTotal = monthTotal + account.total;
          })
          accounts.forEach(account => {
            account.percentage = ((account.total / monthTotal) * 100).toPrecision(2);
            if(isNaN(account.percentage)) 
              account.percentage = 0;
            else if(account.percentage < 0.01) 
              account.percentage = '< 0.01';
          })
          pdgs.forEach(pdg => {
            pdg.percentage = ((pdg.total / monthTotal) * 100).toPrecision(2);
            if(isNaN(pdg.percentage)) 
              pdg.percentage = 0;
            else if(pdg.percentage < 0.01) 
              pdg.percentage = '< 0.01';
          })

          accounts.sort( (a,b) => { return b.total - a.total } );
          pdgs.sort( (a,b) => { return b.total - a.total } );
          

          this.setState({accounts: accounts, pdgs: pdgs, monthTotal: monthTotal}, () => {
            resolve();
          });
        });
      });
    //});
      //});
    }

    filterEvents(filter, value) {

      this.setState({isLoading: true}, () => {
  
        var timeStamp = new Date().getTime();

        var pdgFilter = this.state.pdgFilter;
        if(filter === 'pdg')
          pdgFilter = value;

        var accountFilter = this.state.accountFilter;
        if(filter === 'account')
          accountFilter = value;

        var monthFilter = this.state.monthFilter;
        if(filter === 'month')
          monthFilter = Number(value);

        var yearFilter = this.state.yearFilter;
        if(filter === 'year')
          yearFilter = Number(value);

        var drpFilter = this.state.drpFilter;
        if(filter === 'drp')
          drpFilter = value;

        this.setState({pdgFilter: pdgFilter, monthFilter: monthFilter, yearFilter: yearFilter, 
            accountFilter: accountFilter, drpFilter: drpFilter });

        db.getCleverTapEventsYearMonth(
            monthFilter, 
            yearFilter).then(events => {     
              
              console.log('events returned: ' + events.length);

          if(accountFilter !== 'All') {
            for(var a = events.length -1; a>-1; a--) {
              if(events[a].accountID !== accountFilter)
                events.splice(a,1);
            }
          }

          if(drpFilter !== 'All') {
            for(var b = events.length -1; b>-1; b--) {
              if(Number(events[b].drp) <= 31 || events[b].drp === "" || !events[b].drp)
                events.splice(b,1);
            }
          }

          if(pdgFilter !== 'All') {
            
            var unclaimed = [];

            for(var j = events.length -1; j>-1; j--) {
              var hasOwner = false;
              var matchesFilter = false;

              for(var i = 0; i < this.state.owners.length; i++) {
                
                if(events[j].eventName === this.state.owners[i].eventName && events[j].accountName === this.state.owners[i].accountName) {

                  if(this.state.owners[i].thirdParty ||
                    this.state.owners[i].cartography ||
                    this.state.owners[i].commerceEnablement ||
                    this.state.owners[i].crm ||
                    this.state.owners[i].customer ||
                    this.state.owners[i].devx ||
                    this.state.owners[i].driver ||
                    this.state.owners[i].food ||
                    this.state.owners[i].goclub ||
                    this.state.owners[i].gofin ||
                    this.state.owners[i].groceries ||
                    this.state.owners[i].icp ||
                    this.state.owners[i].logistics ||
                    this.state.owners[i].marketplace ||
                    this.state.owners[i].merchant ||
                    this.state.owners[i].merchantPayment ||
                    this.state.owners[i].ops ||
                    this.state.owners[i].pay ||
                    this.state.owners[i].play ||
                    this.state.owners[i].transport  
                    )
                  hasOwner = true;

                  if(
                    (pdgFilter === '3PP' && this.state.owners[i].thirdParty) ||
                    (pdgFilter === 'Cartography' && this.state.owners[i].cartography) ||
                    (pdgFilter === 'Commerce Enablement' && this.state.owners[i].commerceEnablement) ||
                    (pdgFilter === 'CRM' && this.state.owners[i].crm) ||
                    (pdgFilter === 'Customer Platform' && this.state.owners[i].customer) ||
                    (pdgFilter === 'DevX' && this.state.owners[i].devx) ||
                    (pdgFilter === 'Driver Platform' && this.state.owners[i].driver) ||
                    (pdgFilter === 'Food' && this.state.owners[i].food) ||
                    (pdgFilter === 'GoClub' && this.state.owners[i].goclub) ||
                    (pdgFilter === 'GoFin' && this.state.owners[i].gofin) ||
                    (pdgFilter === 'Groceries' && this.state.owners[i].groceries) ||
                    (pdgFilter === 'ICP' && this.state.owners[i].icp) ||
                    (pdgFilter === 'Logistics' && this.state.owners[i].logistics) ||
                    (pdgFilter === 'Marketplace' && this.state.owners[i].marketplace) ||
                    (pdgFilter === 'Merchant Payment' && this.state.owners[i].merchantPayment) ||
                    (pdgFilter === 'Merchant Platform' && this.state.owners[i].merchant) ||
                    (pdgFilter === 'Ops Platform' && this.state.owners[i].ops) ||
                    (pdgFilter === 'Pay' && this.state.owners[i].pay) ||
                    (pdgFilter === 'Play' && this.state.owners[i].play) ||
                    (pdgFilter === 'Transport' && this.state.owners[i].transport) 
                    ) {
                      matchesFilter = true;
                    }
                  
                  break;
                }
              }

              if(!hasOwner) {
                unclaimed.push(events[j]);
              }
              if(!matchesFilter) {
                events.splice(j,1);
              } 
            }

            if(pdgFilter === 'NO OWNER')
              events = unclaimed;
          }

          var total = 0;
          events.forEach(event => {
            if(isNaN(event.count))
              console.log('not a number: ', event);
            else 
              total = total + Number(event.count);
          });

          events.forEach(event => {

            event.percentage = ((event.count / total) * 100).toPrecision(2);

            //if(event.percentage < 0.01) 
              //event.percentage = '< 0.01';

            //event.cost = (totalCost * (event.count / total)).toPrecision(2);
            event.cost = 0;

            event.owner = {
              accountName: event.accountName,
              created: timeStamp,
              eventName: event.eventName
            }; // for those that don't have one yet

            for(var i = 0; i < this.state.owners.length; i++) {

              if(event.eventName === this.state.owners[i].eventName && event.accountName === this.state.owners[i].accountName ) {

                event.owner = this.state.owners[i];
                event.ownerName = this.getOwnerNames(this.state.owners[i]);
              }
            }
          });

          events.sort( (a,b) => { return b.count - a.count } );

          this.setState({ events: events, total: total, isLoading: false });         

        });
      });
    }

    getOwnerNames(owner) {
      var ownerName = '';

      if(owner.thirdParty)
        ownerName = ownerName + ', 3PP';
      if(owner.adTech)
        ownerName = ownerName + ', AdTech';
      if(owner.cartography)
        ownerName = ownerName + ', Cartography';
      if(owner.commerceEnablement)
        ownerName = ownerName + ', Commerce Enablement';
      if(owner.crm)
        ownerName = ownerName + ', CRM';
      if(owner.customer)
        ownerName = ownerName + ', Customer Platform';
      if(owner.devx)
        ownerName = ownerName + ', DevX';
      if(owner.driver)
        ownerName = ownerName + ', Driver Platform';
      if(owner.food)
        ownerName = ownerName + ', Food';
      if(owner.goclub)
        ownerName = ownerName + ', GoClub';
      if(owner.gofin)
        ownerName = ownerName + ', GoFin';
      if(owner.groceries)
        ownerName = ownerName + ', Groceries';
      if(owner.icp)
        ownerName = ownerName + ', ICP';
      if(owner.logistics)
        ownerName = ownerName + ', Logistics';
      if(owner.marketplace)
        ownerName = ownerName + ', Marketplace';
      if(owner.merchantPayment)
        ownerName = ownerName + ', Merchant Payment';
      if(owner.merchant)
        ownerName = ownerName + ', Merchant Platform';
      if(owner.ops)
        ownerName = ownerName + ', Ops Platform';
      if(owner.pay)
        ownerName = ownerName + ', Pay';
      if(owner.play)
        ownerName = ownerName + ', Play';
      if(owner.transport)
        ownerName = ownerName + ', Transport';

      return ownerName.substr(2);
    }

    ownerUpdated(owner) {
      
      var events = this.state.events;
      for(var i = 0; i < events.length; i++) {
        if(events[i].eventName === owner.eventName) {
          events[i].owner = owner;
          events[i].ownerName = this.getOwnerNames(owner);
          break;
        }
      }
      this.setState({events: events});
    }

    exportToCSV() {
      var rows = this.state.events;

      let filename = "cleverTapEvents.csv";
      let contentType = "text/csv;charset=utf-8;";
    
      var content = "Name, Owners, Percentage, Count \n";
      for(var i = 0; i < rows.length; i++) {
        content = content + rows[i].eventName.replace(/,/g, "") + ', ' + rows[i].ownerName + ', ' + rows[i].percentage  + ', ' + rows[i].count + '\n';
      }
    
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }

    bulkUpdate() {
      this.setState({isLoading: true}, () => {

        var rows = this.state.events;
        var promises = [];
        var timeStamp = new Date().getTime();
        rows.forEach(row => {
          row.owner = {
            accountName: this.state.accountFilter,
            created: timeStamp,
            play: true,
            eventName: row.eventName
          }
          promises.push(db.updateCleverTapOwner(row.id, row.owner));
        });

        Promise.all(promises).then(() => {
          this.setState({isLoading: false});
        })
      })
    }

    showFilters() {
      if(this.state.queryText.length >0)
          return "none";
      else
          return "flex";
    }
    showLoading() {
      if(this.state.isLoading)
          return "block";
      else 
          return "none";
    }
    showResults() {
      if(!this.state.isLoading)
          return "block";
      else 
          return "none";
    }

    getDRPColor(drp) {
      if(drp) {
        if(isNumeric(drp)) {
          if(drp <= 31)
            return "green";
          else 
            return "maroon";
        }
      }
    }

    render() {

      const icon = {
        fontSize:"17px",
        marginTop:"0px",
        color:"#000000"
      }
      const loadingDiv = {
        display: this.showLoading(),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
      const resultCountDiv = {
        display: this.showResults(),
        textAlign: 'right',
        color:"gray",
        fontSize:"11px"
    }

      return (
      
          <div style={{padding:"0px"}}>
            <Row>
              <Col xs="12" sm="9" >
                <br />
                <Card>
                  <CardHeader>
                      <Row>
                          <Col sm="6">
                              <CardTitle tag="h4">CleverTap Events</CardTitle>
                          </Col>
                          <Col sm="6" style={{textAlign:"right"}}>
                            <div style={{cursor:'pointer', fontSize:"13px", padding:"11px"}} title="Export to CSV" onClick={() => this.exportToCSV()}>
                              <i style={icon} className="nc-icon nc-cloud-download-93" />
                            </div>
                            <div style={{cursor:'pointer', fontSize:"13px", padding:"11px", display:"none"}} title="Bulk Updates" onClick={() => this.bulkUpdate()}>
                              <i style={icon} className="nc-icon nc-box-2" />
                            </div>
                          </Col>
                      </Row>
                  </CardHeader>
                  <CardBody style={{minHeight:"300px"}}>
                    <Row>
                      <Col md="12" style={{color:"green"}}>
                                    
                      </Col>
                    </Row>
                    <Row style={{display:this.showFilters()}}>
                      <Col sm="6" >
                          Account <br />
                          <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                      <i style={icon} className="nc-icon nc-shop" />
                                  </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="" 
                                  type="select" 
                                  value={this.state.accountFilter} onChange={(e) => this.filterEvents("account", e.target.value)} >
                                      <option>All</option>
                                      {this.state.accounts.map((account, index) => (
                                      <option value={account.id} key={index}>{account.name}</option>
                                      ))}
                              </Input>
                          </InputGroup>
                          <br />
                      </Col>
                      <Col sm="6">
                          PDG <br />
                          <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                      <i style={icon} className="nc-icon nc-app" />
                                  </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="" 
                                  type="select" 
                                  value={this.state.pdgFilter} onChange={(e) => this.filterEvents("pdg", e.target.value)} >
                                      <option>All</option>
                                      <option>NO OWNER</option>
                                      <option>3PP</option>
                                      <option>Cartography</option>
                                      <option>Commerce Enablement</option>
                                      <option>CRM</option>
                                      <option>Customer Platform</option>
                                      <option>DevX</option>
                                      <option>Driver Platform</option>
                                      <option>Food</option>
                                      <option>GoClub</option>
                                      <option>GoFin</option>
                                      <option>Groceries</option>
                                      <option>ICP</option>
                                      <option>Logistics</option>
                                      <option>Marketplace</option>
                                      <option>Merchant Payment</option>
                                      <option>Merchant Platform</option>
                                      <option>Ops Platform</option>
                                      <option>Pay</option>
                                      <option>Play</option>
                                      <option>Transport</option>
                              </Input>
                          </InputGroup>
                      </Col>
                      <Col sm="4" >
                        Year <br />
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                    <i style={icon} className="nc-icon nc-calendar-60" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="" 
                                type="select" 
                                value={this.state.yearFilter} onChange={(e) => this.filterEvents("year", e.target.value)} >
                                    <option>2022</option>
                                    <option>2021</option>
                                    <option>2020</option>
                            </Input>
                        </InputGroup>
                        <br />
                      </Col>
                      <Col sm="4" >
                        Month <br />
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                    <i style={icon} className="nc-icon nc-calendar-60" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="" 
                                type="select" 
                                value={this.state.monthFilter} onChange={(e) => this.filterEvents("month", e.target.value)} >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                            </Input>
                        </InputGroup>
                        <br />
                      </Col>
                      <Col sm="4" >
                        DRP <br />
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                    <i style={icon} className="nc-icon nc-calendar-60" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="" 
                                type="select" 
                                value={this.state.drpFilter} onChange={(e) => this.filterEvents("drp", e.target.value)} >
                                    <option>All</option>
                                    <option>Extended</option>
                            </Input>
                        </InputGroup>
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div style={loadingDiv}>
                            <div style={{marginTop:"89px"}}>
                                <Spinner color="success" />
                            </div>
                        </div>
                        <div style={resultCountDiv}>
                            <div>
                                {this.state.events.length} results
                            </div>
                        </div>
                        <div style={{display:this.showResults()}}>
                          <Table style={{borderColor:"transparent"}}>
                              <thead>
                                  <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                    <td></td>
                                    <td>Name</td>
                                    <td>Account</td>
                                    <td>Owner</td>
                                    <td style={{textAlign:"right"}} title="data retention policy (in days)">DRP</td>
                                    <td style={{textAlign:"right"}}>Percentage</td>
                                    <td style={{textAlign:"right"}}>Count</td>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr style={{fontWeight:600, fontFamily: 'arial', color:'green', fontSize:"11px"}}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{textAlign:"right"}}>100%</td>
                                  <td style={{textAlign:"right"}}>{numberWithCommas(this.state.total)}</td>
                                </tr>
                                {this.state.events.map((event, index) => (
                                <tr key={index} style={{fontSize:"12px", fontFamily:'arial', backgroundColor:tables.rowColor(index)}}>
                                  <td>
                                    <CleverTapEventEditor owner={event.owner} onUpdated={(e) => this.ownerUpdated(e)} />
                                  </td>
                                  <td>
                                    {event.eventName}
                                  </td>
                                  <td title={event.accountName}>
                                    {strings.trim(event.accountName,11)}
                                  </td>
                                  <td>
                                    {event.ownerName}
                                  </td>
                                  <td style={{color:this.getDRPColor(event.drp)}}>
                                    {event.drp}
                                  </td>
                                  <td style={{textAlign:"right"}}>
                                    {event.percentage}%
                                  </td>
                                  <td style={{textAlign:"right"}}>
                                    {numberWithCommas(event.count)}
                                  </td>
                                </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="3">
                <br />
                <Card>
                  <CardHeader>
                    <Row>
                      <Col sm="12">
                        <CardTitle tag="h4">PDGs</CardTitle>
                        <div style={{color:"green", fontSize:"11px"}}>May 2022</div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <Table>
                          <thead>
                              <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                <td>Name</td>
                                <td style={{textAlign:"right"}}>Percentage</td>
                                <td style={{textAlign:"right"}}>Count</td>
                                <td style={{textAlign:"right", display:"none"}}>Cost</td>
                              </tr>
                          </thead>
                          <tbody>
                            <tr style={{fontWeight:600, fontFamily: 'arial', color:'green', fontSize:"11px"}}>
                              <td></td>
                              <td></td>
                              <td style={{textAlign:"right"}}>{numberWithCommas(this.state.monthTotal)}</td>
                            </tr>
                            {this.state.pdgs.map((pdg, index) => (
                            <tr key={index} style={{fontSize:"12px", fontFamily:'arial', backgroundColor:tables.rowColor(index)}}>
                              <td>
                                {pdg.name}
                              </td>
                              <td style={{textAlign:"right"}}>
                                {pdg.percentage}%
                              </td>
                              <td style={{textAlign:"right"}}>
                                {numberWithCommas(pdg.total)}
                              </td>
                            </tr>
                            ))}
                          </tbody>        
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <br /><br />

                <Card>
                  <CardHeader>
                    <Row>
                          <Col sm="12">
                              <CardTitle tag="h4">Accounts</CardTitle>
                              <div style={{color:"green", fontSize:"11px"}}>May 2022</div>
                          </Col>
                      </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <Table>
                          <thead>
                              <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                <td>Name</td>
                                <td style={{textAlign:"right"}}>Percentage</td>
                                <td style={{textAlign:"right"}}>Count</td>
                                <td style={{textAlign:"right", display:"none"}}>Cost</td>
                              </tr>
                          </thead>
                          <tbody>
                            <tr style={{fontWeight:600, fontFamily: 'arial', color:'green', fontSize:"11px"}}>
                              <td></td>
                              <td></td>
                              <td style={{textAlign:"right"}}>{numberWithCommas(this.state.monthTotal)}</td>
                            </tr>
                            {this.state.accounts.map((account, index) => (
                            <tr key={index} style={{fontSize:"12px", fontFamily:'arial', backgroundColor:tables.rowColor(index)}}>
                              <td>
                                {account.name}
                              </td>
                              <td style={{textAlign:"right"}}>
                                {account.percentage}%
                              </td>
                              <td style={{textAlign:"right"}}>
                                {numberWithCommas(account.total)}
                              </td>
                            </tr>
                            ))}
                          </tbody>        
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

              </Col>
            </Row>
          </div>
    );
  }
}


const mapStateToProps = state => {
  return state;
}

CleverTap = connect(mapStateToProps)(CleverTap);
export default CleverTap;
