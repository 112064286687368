import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { slack, tables, telemetry } from 'assets/utils';

import Parser from 'html-react-parser';

// reactstrap components
import {
    Alert,
    Button, Card, CardHeader, CardBody, CardTitle, CardFooter,
    Input,
    Row, Col,
    TabContent, TabPane, Nav, NavItem, NavLink
  } from "reactstrap";

import ActivityCard from "./ActivityCard.js";
import PersonCard from "components/PersonCard/PersonCard.js";
//import ProjectCard from "components/ProjectCard/ProjectCard.jsx";
//import TeamCard from "components/TeamCard/TeamCard.js";

import logo from "assets/img/goConnectIcon.png";

const uuid = require('uuid');

class Career extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          plan: {
              id: this.props.user.personID,
              focusAreas: '',
              goals: '',
              strengths: '',
              weaknesses: '',
              JACA: {
                  scope: [],
                  complexity: [],
                  leadership: []
              },
          },
          manager: {},
          activity: [],
          filtered: [],
          cycleFilter: '22h1',
          isLoading: true,
          alertVisible: false,
          activeTab: 'activity',
          JACA: {
            scope: [ { id: uuid.v4(), category: '', detail: '', employeeComments: '', managerComments: ''} ],
            complexity: [ { id: uuid.v4(), category: '', detail: '', employeeComments: '', managerComments: ''} ],
            leadership: [ { id: uuid.v4(), category: '', detail: '', employeeComments: '', managerComments: ''} ]
          },
          person: this.props.user.person
        }
    }

    getPerson() {
        return new Promise((resolve, reject) => {

            if(this.props.personID) {

                db.getPerson(this.props.personID).then(person => {
                    this.setState({person: person});
                    resolve(person);
                })

            } else resolve(this.props.user.person);
        });
    }

    componentDidMount() {
 
        var plan = this.state.plan;
        
        this.getPerson().then(person => {

            db.getPerson(person.managerID).then(manager => {
                this.setState({manager: manager});
            
                db.getCareer(person.id).then(careerPlan => {

                    if(careerPlan) 
                        plan = careerPlan;

                    db.getPeopleActivityByPersonID(person.id).then(activity => {
                        //db.getPeopleActivityByAuthorID(person.id).then(feedbackSent => {

                            //feedbackSent.forEach(row => { activity.push(row); })

                            var people = [];
                            activity.forEach(row => {
        
                                // person
                                var isFound = false;
                                for(var x = 0; x < people.length; x++) {
                                    if(people[x] === row.personID) {
                                        isFound = true;
                                        break;
                                    }
                                }
                                if(!isFound) 
                                    people.push(row.personID);
        
                                // author
                                isFound = false;
                                for(var y = 0; y < people.length; y++) {
                                    if(people[y] === row.authorID) {
                                        isFound = true;
                                        break;
                                    }
                                }
                                if(!isFound) 
                                    people.push(row.authorID);

                                // manager
                                isFound = false;
                                for(var y = 0; y < people.length; y++) {
                                    if(people[y] === row.managerID) {
                                        isFound = true;
                                        break;
                                    }
                                }
                                if(!isFound) 
                                    people.push(row.managerID);
                            })
        
                            var promises = [];
                            people.forEach(personID => { promises.push(db.getPerson(personID)) })
        
                            Promise.all(promises).then(people => {
        
                                activity.forEach(row => {
                                    for(var i = 0; i < people.length; i++) {
                                        if(row.personID === people[i].id) 
                                            row.person = people[i];
                                            
                                        if(row.authorID === people[i].id)
                                            row.author = people[i];

                                        if(row.managerID === people[i].id)
                                            row.manager = people[i];
                                    }
                                });
                                activity.sort((a,b) => { return b.created - a.created });
                
                                this.setState({activity: activity, plan: plan}, this.filterActivity);
                            });

                        //});
                    })
        
                    if(person.id === this.props.user.person.id && this.props.user.person.role && this.props.user.person.level) {
        
                        db.getJACA(this.props.user.person.role, this.props.user.person.level).then(JACA => {
        
                            if(!plan.JACA)
                                plan.JACA = {  };
        
                            plan.JACA.id = JACA.id;
        
                            if(plan.JACA.scope.length === 0) {
                                JACA.scope.forEach(subcategory => {
                                    subcategory.detail = subcategory.detail.replace(/\n/g,'<br />');
                                    subcategory.employeeComments = '';
                                    subcategory.managerComments = '';
                                });
                                plan.JACA.scope = JACA.scope;
                            }
        
                            if(plan.JACA.complexity.length === 0) {
                                JACA.complexity.forEach(subcategory => {
                                    subcategory.detail = subcategory.detail.replace(/\n/g,'<br />');
                                    subcategory.employeeComments = '';
                                    subcategory.managerComments = '';
                                })
                                plan.JACA.complexity = JACA.complexity;
                            }
        
                            if(plan.JACA.leadership.length === 0) {
                                JACA.leadership.forEach(subcategory => {
                                    subcategory.detail = subcategory.detail.replace(/\n/g,'<br />');
                                    subcategory.employeeComments = '';
                                    subcategory.managerComments = '';
                                });
                                plan.JACA.leadership = JACA.leadership;
                            }
                            
                            this.setState({plan: plan});
                        });
                    }
                });
            });
        });


        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "career", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }


    filterActivity(filter, value) {

        var filtered = [];

        var cycleFilter = this.state.cycleFilter;
        if(filter === 'cycle')
            cycleFilter = value;

        this.setState({cycleFilter: cycleFilter});

        this.state.activity.forEach(row => {
            if(row.cycle === cycleFilter)
                filtered.push(row);
        })

        this.setState({filtered: filtered, isLoading: false});
    }

    addActivity(newActivity) {

        var activity = this.state.activity;
        
        newActivity.manager = this.state.manager;
        
        activity.push(newActivity);

        activity.sort((a,b) => { return b.created - a.created });

        this.setState({activity: activity}, this.filterActivity);
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    updatePlan(property, value) {
        var obj = this.state.plan;
        obj[property] = value;
        this.setState({plan: obj});
    }

    updateExpectation(expectation, category, property, value) {
        var obj = this.state.plan;

        expectation[property] = value;

        if(category === 'scope') {

            for(var i = 0; i < obj.JACA.scope.length - 1; i++) {
                if(obj.JACA.scope[i].id === expectation.id) {
                    obj.JACA.scope[i] = expectation;
                    break;
                }
            }
        }
        else if(category === 'complexity') {

            for(var j = 0; j < obj.JACA.complexity.length - 1; j++) {
                if(obj.JACA.complexity[j].id === expectation.id) {
                    obj.JACA.complexity[j] = expectation;
                    break;
                }
            }
        }
        else if(category === 'leadership') {

            for(var k = 0; k < obj.JACA.leadership.length - 1; k++) {
                if(obj.JACA.leadership[k].id === expectation.id) {
                    obj.JACA.leadership[k] = expectation;
                    break;
                }
            }
        }        

        this.setState({JACA: obj});
    }

    saveCareerDevelopmentPlan(notify) {

        var plan = this.state.plan;
        if(notify) 
            if(!plan.managerReview)  
                plan.managerReview = true;
            else 
                plan.managerReview = !plan.managerReview;

        db.updateCareer(this.state.plan.id, this.state.plan).then(() => {
            this.setState({plan: plan, alertVisible: true});
            setTimeout(() => {
                this.setState({alertVisible: false});
            }, 5000);

            if(notify) {
                this.sendSlackMessage();
            }
        });
    }

    sendSlackMessage() {

        var text = this.props.user.person.name + " has updated his or her Career Development Plan.";
        if(this.props.user.person.id !== this.state.person.id) 
            text = this.props.user.person.name + " has added comments to your Career Development Plan.";
        
        var blocks = [
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": text + "\n\nClick <https://www.gocnnct.com/career/" + this.state.plan.id + "|here> to see it."
                    }
                },
            ]
                    
        var slackID = 'U01C68P5D4P'; // replace with manager or person id
        slack.sendMessage(slackID, text, blocks);
                       
    }

    openCareerPlan(person) {
        window.open('/career/' + person.id)
    }

    employeeCard() {
        if(this.props.user.person.id) {
            return (
                <PersonCard person={this.state.person} showThumb />
            )
        }
    }

    showInManagerView() {
        if(this.state.person.id !== this.props.user.person.id)
            return "block";
        else
            return "none";
    }

    hideInManagerView() {
        if(this.state.person.id !== this.props.user.person.id)
            return "none";
        else
            return "block";
    }

    managerCard() {
        if(this.state.manager.id) {
            return (
                <PersonCard person={this.state.manager} showThumb />
            )
        }
    }
    
    disabled() {
        if(this.state.plan.managerReview && this.state.person.id === this.props.user.person.id)
            return "disabled";
        else
            return "";
    }

    getNotifyText() {
        if(this.state.person.id === this.props.user.person.id)
            return "SUBMIT AND NOTIFY MANAGER";
        else
            return "SUBMIT AND NOTIFY " + this.state.person.name;
    }

    render() {

        const employeeText = {
            color:"#446418",
            fontWeight:500,
            fontSize:"13px",
            padding:"6px",
            marginBottom:"11px"
        }
        const managerText = {
            color:"#446418",
            fontWeight:500,
            fontSize:"13px",
            padding:"6px",
            marginTop:"13px",
            display: this.hideInManagerView()
        }
        const link = {
            color:"#434343",
            fontWeight:500,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeLink = {
            color:"white",
            fontWeight:700,
            fontSize:"12px"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#efefef",
            marginLeft:"1px",   
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div style={{padding:"10px"}}>
                
                <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>


                <Row>
                    <Col xs="12" >
                        <div style={loadingDiv}>
                            <div className="spin">
                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                            </div>
                        </div>
                        <Card style={{padding:"10px", display:this.showResults()}}>
                            <CardHeader>
                                <Row>
                                    <Col xs="6" >
                                        <CardTitle tag="h4">Career</CardTitle>
                                    </Col>
                                    <Col xs="6" style={{textAlign:"right"}}> {/**, display:this.showInManagerView() */}
                                        {/*}
                                        <div style={{display:"inline-block", marginRight:"10px", marginTop:"10px", fontSize:"12px"}}><b>Employee</b><br />{this.state.person.name}</div>
                                        <div style={{display:"inline-block"}}>{this.employeeCard()}</div>
                                        <div style={{display:"inline-block", marginRight:"10px", fontSize:"12px"}}><b>Manager</b><br />{this.state.manager.name}</div>
                                        <div style={{display:"inline-block"}}>{this.managerCard()}</div>
                                        */}
                                        <ActivityCard onCreated={(a) => this.addActivity(a)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{minHeight:"300px", paddingTop:"10px"}}>
                               <Row>
                                   <Col xs="12">
                                        <Nav>
                                            <NavItem style={(this.state.activeTab === 'activity' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'activity' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'activity'}) }}
                                                >
                                                    Activity
                                                </NavLink>
                                            </NavItem>
                                            {/*}
                                            <NavItem style={(this.state.activeTab === 'plans' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'plans' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'plans'}) }}
                                                >
                                                    Career Development Planning
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'jaca' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'jaca' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'jaca'}) }}
                                                >
                                                    JACA
                                                </NavLink>
                                            </NavItem>
                                            */}
                                        </Nav>
                                   </Col>
                               </Row>
                               <Row style={{padding:"10px", paddingTop:"0px", paddingBottom:"0px", margin:"0px", marginBottom:"20px", borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green'}}>
                                   <Col>
                                        <TabContent activeTab={this.state.activeTab} >
                                            <TabPane tabId="activity" style={{minHeight:"210px", paddingTop:"50px"}}>
                                                <Row style={{marginBottom:"20px"}}>
                                                    <Col xs="6">
                                                        Cycle
                                                        <Input placeholder="" 
                                                            type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                                            value={this.state.cycleFilter} onChange={(e) => this.filterActivity("cycle", e.target.value)} >
                                                                <option value="21h2">2021 H2</option>
                                                                <option value="22h1">2022 H1</option>
                                                                <option value="22h2">2022 H2</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        {this.state.filtered.map((activity, index) => (
                                                            <ActivityCard key={index} activity={activity} />
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="plans" style={{minHeight:"210px", paddingTop:"50px"}}>

                                                <Row>
                                                    <Col md="12" style={{fontWeight:500, marginBottom:"20px", fontSize:"13px"}}>
                                                        We want you to build a career at Gojek that you are excited about and can be proud of.
                                                        The first step is to articulate your goals and realistic, concrete steps you can take to get there. 
                                                        It's also important that your manager knows what your goals are, so they can help by providing you 
                                                        with the opportunities and the feedback you need along the way. 
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <div style={{fontSize:"12px", marginTop:"20px", color:"#666" }}>
                                                            <div style={{color:"green", fontWeight:600, fontSize:"15px"}}>GOALS</div>
                                                            Describe your career goals at a high level. Think about what you want to do this year,
                                                            next year and five years from now. Try to be ambitious but realistic and think about 
                                                            how your goals support your team and Gojek as well.
                                                        </div>
                                                    </Col>
                                                    <Col sm="9">
                                                        <div style={{marginTop:"20px"}}>    
                                                            <Input disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                value={this.state.plan.goals} onChange={(e) => this.updatePlan("goals", e.target.value)} />
                                                                <div style={managerText}>{this.state.plan.goalsManagerComment}</div>
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{this.state.plan.goals}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                    value={this.state.plan.goalsManagerComment} onChange={(e) => this.updatePlan("goalsManagerComment", e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <div style={{fontSize:"12px", marginTop:"20px", color:"#666" }}>
                                                            <div style={{color:"green", fontWeight:600, fontSize:"15px"}}>STRENGTHS</div>
                                                            What do you think are the strengths that will help you build the career you want to have?
                                                        </div>
                                                    </Col>
                                                    <Col sm="9">
                                                        <div style={{marginTop:"20px"}}>
                                                            <Input disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                value={this.state.plan.strengths} onChange={(e) => this.updatePlan("strengths", e.target.value)} />
                                                                <div style={managerText}>{this.state.plan.strengthsManagerComment}</div>
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{this.state.plan.strengths}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                    value={this.state.plan.strengthsManagerComment} onChange={(e) => this.updatePlan("strengthsManagerComment", e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <div style={{fontSize:"12px", marginTop:"20px", color:"#666" }}>
                                                            <div style={{color:"green", fontWeight:600, fontSize:"15px"}}>WEAKNESSES</div>
                                                            We all have weaknesses and we don't have to overcome them all to be successful. 
                                                            What weaknesses do you feel you have that you need to improve on in order to be successful?
                                                        </div>
                                                    </Col>
                                                    <Col sm="9">
                                                        <div style={{marginTop:"20px"}}>
                                                            <Input disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                value={this.state.plan.weaknesses} onChange={(e) => this.updatePlan("weaknesses", e.target.value)} />
                                                                <div style={managerText}>{this.state.plan.weaknessesManagerComment}</div>
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{this.state.plan.weaknesses}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                    value={this.state.plan.weaknessesManagerComment} onChange={(e) => this.updatePlan("weaknessesManagerComment", e.target.value)} />
                                                                </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <div style={{fontSize:"12px", marginTop:"20px", color:"#666" }}>
                                                            <div style={{color:"green", fontWeight:600, fontSize:"15px"}}>FOCUS AREAS</div>
                                                            What do you want to focus on right now? This could be strengths you want to develop, 
                                                            weaknesses you feel you need to mitigate, specific skills you want to pick up or
                                                            specific types of experience you want to have.
                                                        </div>
                                                    </Col>
                                                    <Col sm="9">
                                                        <div style={{marginTop:"20px"}}>
                                                            <Input disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"21px", minHeight:"200px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                value={this.state.plan.focusAreas} onChange={(e) => this.updatePlan("focusAreas", e.target.value)} />
                                                                <div style={managerText}>{this.state.plan.focusAreasManagerComment}</div>
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{this.state.plan.focusAreas}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"21px", minHeight:"210px", fontSize:"13px", paddingLeft:"15px"}} 
                                                                    value={this.state.plan.focusAreasManagerComment} onChange={(e) => this.updatePlan("focusAreasManagerComment", e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop:"17px"}}>
                                                    <Col sm="3">
                                                        
                                                    </Col>
                                                    <Col sm="9" style={{color:"#666666"}}>
                                                        You can save your work to come back later. When you're done and ready to discuss, submit the plan.
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12" style={{textAlign:"right", marginTop:"20px"}}>
                                                        <div style={{display:"inline-block", marginRight:"10px"}}>
                                                            <Button color="secondary" onClick={() => this.saveCareerDevelopmentPlan(false)} size="md" disabled={this.disabled()} >
                                                                Save
                                                            </Button>
                                                        </div>
                                                        <div style={{display:"inline-block"}}>
                                                            <Button color="success" onClick={() => this.saveCareerDevelopmentPlan(true)} size="md" disabled={this.disabled()}>
                                                                {this.getNotifyText()}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="jaca" style={{minHeight:"210px", paddingTop:"50px"}}>
                                                
                                                <Row>
                                                    <Col xs="12" style={{color:"green", fontSize:"13px", marginBottom:"5px"}}>
                                                        Category:<span style={{fontSize:"14px", fontWeight:600}}> SCOPE</span>
                                                    </Col>
                                                </Row>
                                                {this.state.plan.JACA.scope.map((expectation, index) => (
                                                    <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                                        <Col xs="6" style={{fontSize:"12px"}}>
                                                            <div style={{fontWeight:600}}>{expectation.category}</div>
                                                            {Parser(expectation.detail)}
                                                        </Col>
                                                        <Col xs="6">
                                                            <Input placeholder="how have you made or how will you make this impact?" disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                value={expectation.employeeComments} onChange={(e) => this.updateExpectation(expectation, "scope", "employeeComments", e.target.value)} />
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{expectation.employeeComments}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                    value={expectation.managerComments} onChange={(e) => this.updateExpectation(expectation, "scope", "managerComments", e.target.value)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Row>
                                                    <Col xs="12" style={{color:"green", fontSize:"13px", marginTop:"23px", marginBottom:"5px"}}>
                                                        Category:<span style={{fontSize:"14px", fontWeight:600}}> COMPLEXITY</span>
                                                    </Col>
                                                </Row>
                                                {this.state.plan.JACA.complexity.map((expectation, index) => (
                                                    <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                                        <Col xs="6" style={{fontSize:"12px"}}>
                                                            <div style={{fontWeight:600}}>{expectation.category}</div>
                                                            {Parser(expectation.detail)}
                                                        </Col>
                                                        <Col xs="6">
                                                            <Input placeholder="how have you made or how will you make this impact?" disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                value={expectation.employeeComments} onChange={(e) => this.updateExpectation(expectation, "complexity", "employeeComments", e.target.value)} />
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{expectation.employeeComments}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                    value={expectation.managerComments} onChange={(e) => this.updateExpectation(expectation, "scope", "managerComments", e.target.value)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Row>
                                                    <Col xs="12" style={{color:"green", fontSize:"13px", marginTop:"23px", marginBottom:"5px"}}>
                                                        Category:<span style={{fontSize:"14px", fontWeight:600}}> LEADERSHIP</span>
                                                    </Col>
                                                </Row>
                                                {this.state.plan.JACA.leadership.map((expectation, index) => (
                                                    <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                                        <Col xs="6" style={{fontSize:"12px"}}>
                                                            <div style={{fontWeight:600}}>{expectation.category}</div>
                                                            {Parser(expectation.detail)}
                                                        </Col>
                                                        <Col xs="6">
                                                            <Input placeholder="how have you made or how will you make this impact?" disabled={this.disabled()}
                                                                type="textarea" style={{display:this.hideInManagerView(), lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                value={expectation.employeeComments} onChange={(e) => this.updateExpectation(expectation, "leadership", "employeeComments", e.target.value)} />
                                                            <div style={{display:this.showInManagerView(), marginTop:"10px"}}>
                                                                <div style={employeeText}>"{expectation.employeeComments}"</div>
                                                                <Input placeholder=" manager comments "
                                                                    type="textarea" style={{lineHeight:"17px", minHeight:"123px", fontSize:"12px", paddingLeft:"15px"}} 
                                                                    value={expectation.managerComments} onChange={(e) => this.updateExpectation(expectation, "scope", "managerComments", e.target.value)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                
                            </CardFooter>
                        </Card>
                        <Alert color="success" isOpen={this.state.alertVisible} toggle={() => this.setState({alertVisible: false})}>
                            Your changes have been saved.
                        </Alert>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Career = connect(mapStateToProps)(Career);
export default Career;