import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import './person-card.scss'

import React from 'react'
// redux
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card, CardBody, CardFooter,
  Carousel, CarouselCaption, CarouselControl,
  CarouselItem, Col,
  Modal, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'

import { db } from '../../assets/firebase'
import googleDocs from '../../assets/img/googleDocs.png'
import googleSheets from '../../assets/img/googleSheets.png'
import googleSlides from '../../assets/img/googleSlides.png'
import { arraySort, telemetry } from '../../assets/utils'
import KudosCard from '../KudosCard/KudosCard'
import PersonEditor from '../PersonEditor/PersonEditor'
import ProjectCard from '../ProjectCard/ProjectCard'
import TagBadges from '../Tag/TagBadges'
import TeamCard from '../TeamCard/TeamCard'

// var ps;
class PersonCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      isLoaded: false,
      activeIndex: 0,
      animating: false,
      activeTab: '1',
      person: {
        id: '',
        name: '',
        photos: [],
        preload: [],
        children: [],
      },
      manager: {},
      projects: [],
      teams: [],
      tags: [],
      isScaffolding: false,
      sendKudo: false,
      disabled: props.disabled || false,
    }

    this.cardRef = React.createRef()

    this.cardClick = this.cardClick.bind(this)
    this.emailEm = this.emailEm.bind(this)
    this.getPerson = this.getPerson.bind(this)
    this.slackEm = this.slackEm.bind(this)
    this.showOrg = this.showOrg.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onKudosTabClicked = this.onKudosTabClicked.bind(this)
    this.sendKudo = this.sendKudo.bind(this)
  }

  componentDidMount() {
    let person

    if (this.props.nodeData) {
      person = this.props.nodeData
      if (person.isScaffolding) {this.setState({ isScaffolding: true })}
    } else if (this.props.person) {person = this.props.person}

    if (person) {
      this.checkPhotos(person)

      if (!person.children) person.children = []

      if (person.isSelected) {
        this.cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.cardRef.current.style.backgroundColor = 'green'
        this.cardRef.current.style.borderRadius = '6px'
        this.cardRef.current.style.padding = '3px'
        person.isSelected = false
      }

      this.setState({ person }, () => {
        if (this.props.openByDefault) {
          this.toggleModal()
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodeData !== this.props.nodeData || prevProps.person !== this.props.person) {
      let person

      if (this.props.nodeData) {
        person = this.props.nodeData
      } else if (this.props.person) {
        person = this.props.person
      }

      if (person) {
        if (person.isScaffolding) {this.setState({ isScaffolding: true })}

        this.checkPhotos(person)

        if (!person.children) person.children = []

        this.setState({ person })
      }
    }

    if (prevProps.showPersonModal !== this.props.showPersonModal &&
        this.props.showPersonModal && this.state.person.id) {
      if (!this.state.isLoaded) {this.getPerson()}
      this.setState({
        modal: true,
      })
    }
  }

  toggleModal() {
    if (this.state.person.id) {
      if (!this.state.isLoaded) {this.getPerson()}

      this.setState({ modal: !this.state.modal })
    }

    if (this.props.showPersonModal) {
      this.props.closePersonModal()
    }
  }

  checkPhotos(person) {
    if (!person.profilePhoto) {person.profilePhoto = `${process.env.PUBLIC_URL}/avatar.png`}

    if (!person.photos) {person.photos = [{ name: '', caption: '', url: person.profilePhoto }]}

    let isPhotoFound = false
    for (let p = 0; p < person.photos.length; p += 1) {
      if (person.photos[p].url === person.profilePhoto) {
        isPhotoFound = true
        break
      }
    }
    if (!isPhotoFound) {person.photos.push({ name: '', caption: '', url: person.profilePhoto })}

    for (let i = 0; i < person.photos.length; i += 1) {
      if (person.photos[i].isPrimary) {
        person.profilePhoto = person.photos[i].url
        break
      }
    }
  }

  getPerson() {
    try {
      if (this.state.person.id) {
        telemetry.logUserActivity(
            this.props.user.id,
            this.props.user.personID,
            'personCard',
            'pageView',
            this.state.person.id,
        )

        db.getPerson(this.state.person.id).then((person) => {
          if (person) { // some people missing from db, perhaps workday export
            if (this.state.person.preload) person.preload = this.state.person.preload
            if (this.state.person.children) person.children = this.state.person.children

            this.checkPhotos(person)

            db.getTagsByType('skills').then((tags) => {
              tags.forEach((tag) => {
                tag.name = tag.name.toLowerCase()
              })

              this.setState({ person, tags, isLoaded: true })
            })

            if (person.managerID) {
              db.getPerson(person.managerID).then((manager) => {
                if (manager) {this.setState({ manager })}
              })
            }

            db.getTeamsByPerson(person.id).then((teamMembers) => {
              const promises = []
              for (let i = 0; i < teamMembers.length; i += 1) {promises.push(db.getTeam(teamMembers[i].teamID))}

              Promise.all(promises).then((teams) => {
                for (let k = teams.length - 1; k > -1; k -= 1) {if (!teams[k]) teams.splice(k)}

                teams.sort(arraySort.byName)
                this.setState({ teams })
              })
            })

            db.getProjectsByPerson(person.id).then((projectMembers) => {
              const promises = []
              for (let i = 0; i < projectMembers.length; i += 1) {
                promises.push(db.getProject(projectMembers[i].projectID))
              }

              Promise.all(promises).then((projects) => {
                projects.sort(arraySort.byName)
                this.setState({ projects })
              })
            })
          }
        })
      }
    } catch (exc) {
      console.log('exception:')
      console.log(exc)
    }
  }

  slackEm() {
    if (this.state.person.slackID) {window.open(`slack://user?team=T02T4D001&id=${this.state.person.slackID}`)}
  }

  emailEm() {
    if (this.state.person.email) {window.open(`mailto:${this.state.person.email}`)}
  }

  showCarousel() {
    if (this.state.person.photos.length > 0) return 'block'
    return 'none'
  }

  showLinkedIn() {
    if (this.state.person.linkedIn) return 'inline-block'
    return 'none'
  }

  showInstagram() {
    if (this.state.person.instagram) return 'inline-block'
    return 'none'
  }

  showTwitter() {
    if (this.state.person.twitter) return 'inline-block'
    return 'none'
  }

  showGithub() {
    if (this.state.person.github) return 'inline-block'
    return 'none'
  }

  showPhone() {
    if (this.state.person.phone) return 'flex'
    return 'none'
  }

  showWorkingHours() {
    if (this.state.person.workingHours) return 'flex'
    return 'none'
  }

  showEducation() {
    if (this.state.person.education) return 'flex'
    return 'none'
  }

  showBirthday() {
    if (this.state.person.birthday) return 'flex'
    return 'none'
  }

  showResponsibilities() {
    if (this.state.person.responsibilities) return 'flex'
    return 'none'
  }

  showUserManual() {
    if (this.state.person.userManual) return 'flex'
    return 'none'
  }

  getFirstName() {
    if (this.state.person.name) return this.state.person.name.substr(0, this.state.person.name.indexOf(' '))
    return ''
  }

  getLastNames() {
    if (this.state.person.name) return this.state.person.name.substr(this.state.person.name.indexOf(' ') + 1)
    return ''
  }

  getDirectReports() {
    if (!this.state.person.children) return 0
    if (this.state.person.children.length > 0) return this.state.person.children.length
    if (this.state.person.preload) return this.state.person.preload.length
    return 0
  }

  showDirectReports() {
    if (this.props.nodeData) return 'flex'
    return 'none'
  }

  showOrg() {
    if (this.state.person.email) {
      this.props.onShowOrg(this.state.person)
      this.setState({ modal: false })
    }
  }

  showOrgChartButton() {
    if (this.props.onShowOrg && this.state.person.email && this.state.activeTab === '1') return 'inline-block'
    return 'none'
  }

  showEmailButton() {
    if (this.state.person.email && this.state.activeTab === '1') return 'inline-block'
    return 'none'
  }

  showCard() {
    if (!this.props.showThumb && !this.props.showLargeThumb &&
        !this.state.isScaffolding && !this.props.closePersonModal) return 'block'
    return 'none'
  }

  showThumb() {
    if (this.props.showThumb || this.props.showLargeThumb) return 'flex'
    return 'none'
  }

  getThumbSize() {
    if (this.props.showThumb) return '27px'
    return '54px'
  }

  showStub() {
    if (this.state.isScaffolding) return 'block'
    return 'none'
  }

  showRole() {
    if (this.props.showRole) return 'block'
    return 'none'
  }

  cardClick() {
    if (!this.state.isScaffolding) {
      if (this.props.onSelected) {this.props.onSelected(this.state.person)} else {this.toggleModal()}
    }
  }

  getLink() {
    navigator.clipboard.writeText(`https://www.gocnnct.com/orgChart/${this.state.person.id}`)
  }

  async onKudosTabClicked() {
    this.setState({ activeTab: '6' })
    try {
      const res = await db.getAppreciationByPerson(this.state.person.id)
      Promise.all(res.map(async (i, index) => {
        const person = await db.getPerson(i.appreciatorID)
        if (person) res[index].appreciatorName = `${person.firstName} ${person.lastName}`
        else {res[index].appreciatorName = ''}
      }))
          .then(() => {
            this.setState({ appreciationList: res })
          })
          .catch((err) => console.log(err))
    } catch (err) {
      console.log(err)
    }
  }

  sendKudo() {
    this.setState({
      sendKudo: 'true',
    })
  }

  showSendKudoButton() {
    if ((this.state.person.id !== this.props.user.personID) &&
      this.state.activeTab === '6') return
    return 'none'
  }

  personEditor() {
    if (this.state.person.id === this.props.user.personID) {
      return (
        <div style={{ display: 'inline-block', marginRight: '6px' }}>
          <PersonEditor person={this.state.person} teams={this.state.teams} projects={this.state.projects} size="sm" />
        </div>
      )
    }
  }

  personDisabled() {
    return this.state.disabled && (
      <Row className="disabled-card">
        <Col md="12">
          Already in this Team
        </Col>
      </Row>
    )
  }

  disableThumbOnClick() {
    if (this.props.disableThumbOnClick) {
      return 'none'
    }

    return 'auto'
  }

  showProfileDropDown() {
    if (this.props.disableThumbOnClick) {
      return '10px'
    }

    return '0px'
  }

  render() {
    if (this.state.sendKudo === 'true') {
      return (
        <Redirect to={{
          pathname: '/kudos',
          key: this.state.person.id,
          sendKudo: this.state.sendKudo,
          person: this.state.person,
        }}
        />
      )
    }

    const activeLink = {
      color: 'white',
      fontWeight: 600,
      fontSize: '12px',
    }
    const link = {
      color: '#434343',
      fontWeight: 400,
      fontSize: '12px',
      cursor: 'pointer',
    }

    const activeTab = {
      backgroundColor: '#888888',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: '#888888',
    }
    const tab = {
      backgroundColor: '#dedede',
      marginLeft: '1px',
    }

    const icon = {
      fontSize: '17px',
      marginTop: '3px',
      color: '#898989',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    const firstName = {
      fontSize: '14px',
      textAlign: 'left',
      marginBottom: '-2px',
    }
    const fullName = {
      fontSize: '10px',
      textAlign: 'left',
      marginBottom: '8px',
    }
    const jobTitle = {
      fontSize: '8px',
      textAlign: 'left',
      color: 'green',
      fontWeight: 600,
      marginBottom: '4px',
    }
    const location = {
      fontSize: '8px',
      textAlign: 'left',
      color: '#232323',
      fontWeight: 400,
    }

    const subText = {
      color: '#545454',
    }

    const slides = this.state.person.photos.map((item, index) => (
      <CarouselItem
        onExiting={() => this.setState({ animating: true })}
        onExited={() => this.setState({ animating: false })}
        key={index}
      >
        <div style={{
          backgroundSize: 'cover',
          height: '300px',
          width: '100%',
          backgroundColor: '#aaaaaa',
          backgroundImage: `url(${item.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        />
        <CarouselCaption captionText={item.name} captionHeader={item.caption} />
      </CarouselItem>
    ))

    const setActiveIndex = (index) => {
      this.setState({ activeIndex: index })
    }

    const next = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === this.state.person.photos.length - 1 ? 0 : this.state.activeIndex + 1
      setActiveIndex(nextIndex)
    }

    const previous = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === 0 ? this.state.person.photos.length - 1 : this.state.activeIndex - 1
      setActiveIndex(nextIndex)
    }

    return (

      <div style={{ whiteSpace: 'normal' }} disabled={this.state.disabled}>
        <Card style={{
          display: this.showStub(),
          padding: '10px',
          width: '140px',
          background: 'linear-gradient(to right, #545454 0%, #767676 100%)',
          textAlign: 'center',
        }}
        >
          <div style={{ color: 'white', fontSize: '12px' }}>{this.state.person.name}</div>
        </Card>

        <div
          style={{
            display: this.showThumb(),
            backgroundSize: 'cover',
            height: this.getThumbSize(),
            width: this.getThumbSize(),
            backgroundColor: '#aaaaaa',
            borderRadius: '3px',
            padding: '0px',
            backgroundImage: `url(${this.state.person.profilePhoto})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            cursor: 'pointer',
            pointerEvents: this.disableThumbOnClick(),
            marginRight: this.showProfileDropDown(),
          }}
          onClick={this.toggleModal}
          title={this.state.person.name}
        />

        <div
          ref={this.cardRef}
          style={{
            display: this.showCard(),
            width: '140px',
            marginTop: '10px',
            paddingLeft: '3px',
            paddingRight: '3px',
            cursor: 'pointer',
          }}
        >
          <Card onClick={this.cardClick} style={{ marginBottom: '10px' }}>
            <div style={{
              backgroundSize: 'cover',
              height: '100px',
              width: '100%',
              backgroundColor: '#aaaaaa',
              borderTopRightRadius: '6px',
              borderTopLeftRadius: '6px',
              backgroundImage: `url(${this.state.person.profilePhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderBottom: '1px solid green',
            }}
            />
            <CardBody style={{ minHeight: '143px', padding: '8px' }}>
              <Row>
                <Col md="12">
                  <div style={firstName}>{this.getFirstName()}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div style={fullName}>{this.getLastNames()}</div>
                </Col>
              </Row>
              <Row>

                <Col md="12">
                  <div style={jobTitle}>{this.state.person.title}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div style={location}>{this.state.person.location}</div>
                </Col>
              </Row>
              {this.personDisabled()}
              {!this.state.disabled && (
                <Row>
                  <Col md="12">
                    &nbsp;
                  </Col>
                </Row>
              )}
            </CardBody>
            <CardFooter style={{
              display: this.showRole(),
              backgroundColor: '#898989',
              color: '#ffffff',
              fontSize: '10px',
              textAlign: 'center',
              padding: '3px',
            }}
            >
              {this.state.person.role}
            </CardFooter>
          </Card>
        </div>

        <style>
          {
            `.carousel {
                        max-width: 100%;
                        height: 300px;
                        background: black;
                      }
                      .socialIcon {
                        color: #696969;
                      }
                      .socialIcon:hover {
                        color: green;
                      },
                      .slider {
                        width: 100%;
                      }
                      .slider-horizontal {
                        width: 100% !important;
                      },
                      .tooltip {
                        display: none;
                      }
                      .tooltip-main {
                        display: none;
                      },
                      .slider.slider-disabled {}
                      .slider-handle {
                        background-image: linear-gradient(to bottom, #228c13, #4ca635) !important;
                      }
                      `
          }
        </style>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <div style={{ display: this.showCarousel() }}>
            <Carousel
              className="carousel"
              activeIndex={this.state.activeIndex}
              next={next}
              previous={previous}
              ride="carousel"
            >
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>

          </div>
          <ModalBody style={{
            paddingTop: '34px', paddingLeft: '17px', paddingRight: '17px', backgroundColor: '#efefef',
          }}
          >
            <Row>
              <Col xs="8" style={{ fontSize: '21px', marginBottom: '17px' }}>
                {this.state.person.name}
                <span onClick={() => this.getLink()}>
                  <i
                    style={{
                      color: '#ababab', fontSize: '14px', marginLeft: '20px', cursor: 'pointer',
                    }}
                    className="nc-icon nc-share-66"
                    title="get link"
                  />
                </span>
              </Col>
              <Col xs="4" style={{ textAlign: 'right', fontSize: '19px', marginBottom: '17px' }}>
                <div
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    display: this.showLinkedIn(),
                  }}
                  onClick={() => window.open(this.state.person.linkedIn)}
                >
                  <i className="socialIcon fab fa-linkedin" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    display: this.showInstagram(),
                  }}
                  onClick={() => window.open(this.state.person.instagram)}
                >
                  <i className="socialIcon fab fa-instagram" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    display: this.showTwitter(),
                  }}
                  onClick={() => window.open(this.state.person.twitter)}
                >
                  <i className="socialIcon fab fa-twitter" />
                </div>
                <div
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    display: this.showGithub(),
                  }}
                  onClick={() => window.open(this.state.person.github)}
                >
                  <i className="socialIcon fab fa-github" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Nav>
                  <NavItem style={(this.state.activeTab === '1' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '1' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '1' })}}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '2' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '2' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '2' })}}
                    >
                      Teams
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '3' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '3' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '3' })}}
                    >
                      Projects
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'skills' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'skills' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: 'skills' })}}
                    >
                      Skills
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '4' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '4' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '4' })}}
                    >
                      Personal
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '6' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '6' ? activeLink : link)}
                      onClick={this.onKudosTabClicked}
                    >
                      Kudos
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={this.state.activeTab}
                  style={{
                    borderStyle: 'solid',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: '#888888',
                    paddingLeft: '23px',
                    paddingRight: '23px',
                  }}
                >
                  <TabPane tabId="1" style={{ minHeight: '210px', paddingTop: '30px', fontSize: '12px' }}>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-badge" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.title}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-pin-3" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.location}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showDirectReports() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-vector" />
                      </Col>
                      <Col xs="10">
                        {this.getDirectReports()}
                        {' '}
                        <span style={subText}>direct reports</span>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showWorkingHours() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-watch-time" title="normal working hours" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.workingHours}
                        {' '}
                        <span style={subText}>{this.state.person.timeZone}</span>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showPhone() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-mobile" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.phone}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-email-85" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.email}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-vector" title="manager" />
                      </Col>
                      <Col xs="10">
                        {this.state.manager.name}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showResponsibilities() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-chat-33" title="ask me about" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.responsibilities}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showUserManual() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-single-copy-04" title="user manual" />
                      </Col>
                      <Col xs="10">
                        <a href={this.state.person.userManual} target="_new" style={{ color: 'green' }}>User Manual</a>
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="skills" style={{ minHeight: '210px', paddingTop: '23px' }}>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-user-run" title="skills" />
                      </Col>
                      <Col xs="10">
                        <TagBadges tagIDs={this.state.person.skills} tagsRef={this.state.tags} />
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" style={{ minHeight: '210px', paddingTop: '30px' }}>
                    <Row>
                      {this.state.teams.map((team, index) => (
                        <Col xs="6" sm="4" key={index}>
                          <TeamCard team={team} />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" style={{ minHeight: '210px', paddingTop: '30px', fontSize: '12px' }}>
                    <Row>
                      {this.state.projects.map((project, index) => (
                        <Col xs="6" sm="4" md="4" lg="4" key={index}>
                          <ProjectCard project={project} />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="4" style={{ minHeight: '210px', paddingTop: '30px', fontSize: '12px' }}>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-touch-id" title="Fun Fact" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.funFact}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-single-02" title="Family" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.family}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-palette" title="Hobbies" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.hobbies}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-note-03" title="Music" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.music}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showEducation() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-hat-3" title="Alma Mater" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.education}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-world-2" title="Former Homes" />
                      </Col>
                      <Col xs="10">
                        {this.state.person.formerHomes}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showBirthday() }}>
                      <Col xs="2">
                        <i
                          className="socialIcon fas fa-birthday-cake"
                          style={{ fontSize: '21px', marginLeft: '2px' }}
                          title="Birthday"
                        />
                      </Col>
                      <Col xs="10">
                        {this.state.person.birthday}
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5" style={{ minHeight: '210px', paddingTop: '34px', fontSize: '12px' }}>
                    <Row>
                      <Col xs="2">
                        <img src={googleSheets} style={{ height: '23px' }} alt="Google Sheets Icon" />
                      </Col>
                      <Col xs="10">
                        Created an interesting useful spreadsheet
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <img src={googleSlides} style={{ height: '23px' }} alt="Google Slides Icon" />
                      </Col>
                      <Col xs="10">
                        Modified an interesting slide deck
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <img src={googleDocs} style={{ height: '23px' }} alt="Google Docs Icon" />
                      </Col>
                      <Col xs="10">
                        Modified a super useful document
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6" style={{ minHeight: '210px', paddingTop: '34px', fontSize: '12px' }}>
                    <Row>
                      {
                        this.state.appreciationList &&
                                            this.state.appreciationList.map((kudos, index) => (
                                              <Col xs="5" sm="4" md="4" lg="4" key={index}>
                                                <KudosCard appreciateObject={kudos} />

                                              </Col>
                                            ))
                      }
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#efefef' }}>
            <div style={{ padding: '10px' }}>
              {this.personEditor()}

              <Button color="secondary" style={{ display: this.showOrgChartButton() }} onClick={this.showOrg} size="sm">
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <i style={navIcon} className="nc-icon nc-vector" />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                  Chart
                </div>
              </Button>
              <Button color="secondary" style={{ display: this.showEmailButton() }} onClick={this.emailEm} size="sm">
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <i style={navIcon} className="nc-icon nc-email-85" />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                  Email
                </div>
              </Button>
              <Button color="success" onClick={this.slackEm} size="sm">
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <i style={navIcon} className="nc-icon nc-chat-33" />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                  Slack
                </div>
              </Button>
              <Button style={{ display: this.showSendKudoButton() }} color="primary" onClick={this.sendKudo} size="sm">
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <i style={navIcon} className="nc-icon nc-trophy" />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                  Send Kudo

                </div>
              </Button>
            </div>
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = (state) => state

PersonCard = connect(mapStateToProps)(PersonCard)
export default PersonCard
