import React from 'react'

import { strings } from '../../assets/utils'
import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import { ENGINEERING_PLATFORM_TEAM_ID } from '../../components/Nexus/constants'
import {
  BACKGROUND_COLORS, CUSTOMER_PLATFORM_PODS, getPodByIssue, getTeamName, sortObjectByIntValue,
} from '../nexusReports/utils'

function getIssuesByPod(issues, teams, teamIdentifier) {
  const chartData = {
    labels: [],
    data: [],
  }

  const issuesByPod = {}
  const topIssuesByPod = []

  issues.forEach((issue) => {
    const teamID = getPodByIssue(issue)

    if (teamID in issuesByPod) {
      issuesByPod[teamID] += 1
    } else {
      issuesByPod[teamID] = 1
    }
  })

  const sortedIssuesByPod = sortObjectByIntValue(issuesByPod)
  sortedIssuesByPod.forEach((row) => {
    const [teamID, count] = row

    let teamName = teamID
    // If 'Customer Platform' pod or 'Engineering Platform', then team name is already present
    if (!CUSTOMER_PLATFORM_PODS.includes(teamID) && teamIdentifier !== ENGINEERING_PLATFORM_TEAM_ID) {
      teamName = getTeamName(teams, teamID)
    }

    if (teamName) {
      chartData.labels.push(strings.trim(teamName, 21))
      chartData.data.push(String(count))
    }

    topIssuesByPod.push({
      count,
      name: teamName,
    })
  })

  return {
    labels: chartData.labels,
    datasets: [
      {
        label: 'PDGs',
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: '#dee2e6',
        data: chartData.data,
        backgroundColor: BACKGROUND_COLORS.slice(0, issuesByPod.length),
      },
    ],
    topIssuesByPod,
  }
}

export default function IssuesReportByPodPieChart({ issues, teams, team }) {
  const chartData = {
    labels: getIssuesByPod(issues, teams, team.id).labels,
    datasets: getIssuesByPod(issues, teams, team.id).datasets,
  }

  return (
    <DoughnutWithPercentage
      title="Issues By Pod"
      totalLabel="Total Issues"
      totalRecords={issues.length}
      sortedChartData={chartData}
      sortedRawData={getIssuesByPod(issues, teams, team.id).topIssuesByPod}
    />
  )
}
