import React, { useState } from "react"
import { Nav, NavItem, Modal, ModalHeader, ModalBody, Button, ModalFooter, NavLink } from "reactstrap"
import CareSLA from "./CareSLA"
import SecuritySLA from "./SecuritySLA"

export default function SLA({ toggle }) {
  const [activeTab, setActiveTab ] = useState('care')

  const activeLink = {
    color:"white",
    fontWeight:700,
    fontSize:"12px"
  }
  const activeTabStyle = {
      backgroundColor:'green',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: 'green'
  }
  const link = {
    color:"#434343",
    fontWeight:500,
    fontSize:"12px",
    cursor:"pointer"
  }
  const tab = {
    backgroundColor:"#dedede",
    marginLeft:"1px",   
  }

  const CloseBtn = (
    <Button outline style={{ position: 'absolute', right: '20px', top: '10px' }} onClick={toggle}>Close</Button>
  )

  return (
    <Modal isOpen size="xl">
      <ModalHeader close={CloseBtn} toggle={toggle}>
        <Nav style={{marginTop:"10px"}}>
          <NavItem style={(activeTab === 'care' ? activeTabStyle : tab )}>
              <NavLink
                  style={(activeTab === 'care' ? activeLink : link )}
                  onClick={() => setActiveTab('care') }
              >
                  Care SLA
              </NavLink>
          </NavItem>
          <NavItem style={(activeTab === 'security' ? activeTabStyle : tab )}>
              <NavLink
                  style={(activeTab === 'security' ? activeLink : link )}
                  onClick={() => setActiveTab('security') }
              >
                  Security SLA
              </NavLink>
          </NavItem>
        </Nav>
      </ModalHeader>
      <ModalBody>
        
        {activeTab === 'care' && <CareSLA />}
        {activeTab === 'security' && <SecuritySLA />}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggle} style={{ marginRight: '20px' }}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}
