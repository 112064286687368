

import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, telemetry } from 'assets/utils';


//video player
import ReactPlayer from 'react-player'

// reactstrap components
import {
    Badge, 
    Input, Button,
    Card, CardHeader, CardBody, CardTitle,
    Row, Col, Table,
  } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";

import logo from "assets/img/goConnectIcon.png";


var people = [];

class GNN extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
          isLoading: true,
          episodes: [],
          feedback: []
      }
    }

    componentDidMount() {
      
        this.getEpisodes();
    }

    getEpisodes() {

        db.getGNNEpisodes().then(episodes => {
          
            db.getEntityFeedback('gnnEpisode').then(feedback => {
                
                this.findUniquePeople(feedback);

                var promises = [];
                people.forEach(person => {
                    promises.push(db.getPerson(person));
                })
    
                Promise.all(promises).then(results => {

                    people = results;
                    
                    for(var p = people.length - 1; p > -1; p--) // remove any missing people
                        if(!people[p])
                            people.splice(p,1);

                    feedback.sort(arraySort.byCreatedDesc)
                    this.attachPeople(episodes,feedback, people);

                    this.setState({episodes: episodes, feedback: feedback, isLoading: false});
                });

            })
        })
    }

    findUniquePeople(objs) {

        objs.forEach(obj => {
                
            var isFound = false;
            for(var a = 0; a < people.length; a++) {
                if(people[a] === obj.personID) {
                    isFound = true;
                    break;
                }
            }
            if(!isFound)
                people.push(obj.personID);
        })
    }

    attachPeople(episodes, feedback) {

        episodes.forEach(episode => {
            episode.people = [];
            for (var i = 0; i < feedback.length; i++) {
                if(feedback[i].entityID === episode.id) { // if the feedback is for this episode
                    for(var b = 0; b < people.length; b++) {
                        if(feedback[i].personID === people[b].id) { // if the person is the one who gave the feedback
                            if(feedback[i].heart)
                                episode.people.push(people[b]);

                            break;
                        }
                    }
                }
            }
        })
    }


    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    toggleHeart(episode) {

        var feedback = this.state.feedback;
        var episodes = this.state.episodes;

        var isFound = false;

        // remove the heart if it's there
        for(var i = feedback.length - 1; i > -1; i--) {
            
            if(feedback[i].entityID === episode.id) {
            
                if(feedback[i].personID === this.props.user.person.id) {
            
                    // remove person from video
                    for(var j = episodes.length - 1; j > -1; j--) {
                        if(episode.id === episodes[j].id) {
                            console.log(episode.people)
                            for(var k = episode.people.length - 1; k > -1; k--) {
                                if(episode.people[k].id === this.props.user.person.id) {
                                    episode.people.splice(k,1);
                                    db.deleteEntityFeedback(feedback[i].id);
                                    feedback.splice(i,1);
                                }
                            }
                            episodes[j] = episode;

                            break;
                        }
                    }
                
                    isFound = true;
                    this.setState({episodes: episodes, feedback: feedback});

                    break;
                }
            }
        }

        // add it if it's not
        if(!isFound) {
            var f = {
                personID: this.props.user.person.id,
                entityID: episode.id,
                created: new Date().getTime(),
                heart: true,
                type: 'gnnEpisode'
            }

            db.updateEntityFeedback(f.id, f).then(result => {
                
                f.id = result.id;
                feedback.push(f);

                for(var i = 0; i < episodes.length; i++) {
                    if(episodes[i].id === episode.id) {
                        episodes[i].people.unshift(this.props.user.person);
                        
                        this.setState({episodes: episodes, feedback: feedback});

                        break;
                    }
                }
            });
        }        
    }

    getPersonCard(person) {
        if(person) {
          return <PersonCard person={person} showThumb />
        } 
    }

    testData() {

        var f = {
            personID: '865c8159-812b-42b0-9907-f273b6b436e2',
            entityID: '7nNZM8uP6tu4QSpMpBW1',
            created: new Date().getTime(),
            heart: true,
            type: 'gnnEpisode'
        }
        db.updateEntityFeedback(f.id, f)

        f.personID = '60af56fb-84d2-4e6f-84dc-80ffef439fd8';
        db.updateEntityFeedback(f.id, f)

        f.personID = '26565b9a-fa7a-4f10-aee6-2d9edc7929a5';
        db.updateEntityFeedback(f.id, f)

        f.personID = 'ee431397-cd8a-4138-807c-beb3918cd936';
        db.updateEntityFeedback(f.id, f)

        f.personID = 'fdc735a0-9885-42bc-a94d-f22943fef5db'; //sunil
        db.updateEntityFeedback(f.id, f)

        f.personID = '31158594-e51a-40de-81a1-1e2c0ee7a74b'; //bruce
        db.updateEntityFeedback(f.id, f)

        f.personID = '0c5bb0bc-d35e-4c5e-96e3-fa54e468ce00'; //cath
        db.updateEntityFeedback(f.id, f)

        f.personID = '757cd202-6127-475c-aae9-8eb35147eada'; //pradeep
        db.updateEntityFeedback(f.id, f)

        f.personID = '31d01a12-7f3f-4e82-8859-e9f47cf91413'; //AB
        db.updateEntityFeedback(f.id, f)
        
        f.personID = 'f9e0e4ac-694b-41d2-ae41-661db16ace9a'; //fitz
        db.updateEntityFeedback(f.id, f)

        f.personID = '1f2bf4b5-f1d5-4b7c-add4-15cc3ed96eda'; //amaresh
        db.updateEntityFeedback(f.id, f)

        
    }

    heartColor(episode) {

        var feedback = this.state.feedback;

        for(var i = feedback.length - 1; i > -1; i--) {
            
            if(feedback[i].entityID === episode.id) {
            
                if(feedback[i].personID === this.props.user.person.id) {
            
                    return "red";
                }
            }
        }

        return "gray";
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (
            <div style={{padding:"10px"}}>
            
                <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                <Row>
                    <Col xs="12">
                        <div style={loadingDiv}>
                            <div className="spin">
                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{display:this.showResults()}}>
                    <Row>
                        <Col xs="12">
                            <CardTitle tag="h4">Episodes</CardTitle>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.episodes.map((episode, index) => (
                            <Col xs="12" sm="6" md="6" lg="4" key={index} style={{minHeight:"350px", marginBottom:"40px"}}>
                                <Card>
                                    <CardHeader>

                                    </CardHeader>
                                    <CardBody>
                                        <ReactPlayer url={episode.url} controls={true} width='100%' />
                                        <div style={{fontSize:"20px", marginTop:"20px"}}>
                                            {episode.title}
                                        </div>
                                        <div style={{fontSize:"11px", color:"green"}}>
                                            {episode.published}
                                        </div>
                                        <div style={{fontSize:"13px", marginTop:"8", marginBottom:"20px", minHeight:"76px"}}>
                                            {episode.description}
                                        </div>
                                        <div style={{cursor:"pointer", whiteSpace: "nowrap", position:"relative", overflowX:"hidden", overflowY:"hidden",  paddingRight:"150px"}} >
                                            <div style={{display:"inline-block", fontSize:"20px", verticalAlign:"top"}} onClick={() => this.toggleHeart(episode)}>
                                                <i className="fa fa-heart" style={{color:this.heartColor(episode)}} /> 
                                            </div>
                                            <div style={{display:"inline-block", whiteSpace: "nowrap"}}>
                                                {episode.people.map((person, index) => (
                                                    <div key={index} style={{display:"inline-block", marginLeft:"6px"}}>
                                                        {this.getPersonCard(person)}
                                                    </div>
                                                ))}
                                                <div style={{position:"absolute", right:"0px", top:"0px", bottom:"0px", width:"80px", pointerEvents: "none", backgroundImage: "linear-gradient(to left, #ffffffff, #ffffff22, #ffffff11, #ffffff00)"}}></div>
                                            </div>
                                        </div>
                                        <div style={{color:"gray", fontSize:"11px"}}>
                                            {episode.people.length} likes
                                        </div>
                                    </CardBody>
                                </Card>
                                
                            </Col>
                        ))}
                    </Row>
                    {/*
                    <Button onClick={() => this.testData()}>Test Data</Button>
                    */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

GNN = connect(mapStateToProps)(GNN);
export default GNN;