import React from 'react'
import {
  Button,
  Modal, ModalBody,
} from 'reactstrap'
import ModalFooter from 'reactstrap/lib/ModalFooter'

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal() {
    this.setState((prevState) => ({ modal: !prevState.modal }))
  }

  handleClose = () => {
    this.setState({ modal: false })
  }

  yes = () => {
    this.props.confirmed()
    this.setState({ modal: false })
  }

  showIcon() {
    if (!this.props.buttonText) return 'block'
    return 'none'
  }

  showButton() {
    if (this.props.buttonText) return 'block'
    return 'none'
  }

  changeButtonPadding() {
    if (this.props.padding) { return this.props.padding }
  }

  render() {
    return (
      <div>
        <div style={{ display: this.showIcon(), cursor: 'pointer' }} title={this.props.mouseover}>
          <i className="fa fa-trash" onClick={() => this.toggleModal()} />
        </div>

        <div style={{ display: this.showButton() }}>
          <Button color="danger" onClick={() => this.toggleModal()} style={{ padding: this.changeButtonPadding() }}>
            <i className="fa fa-trash" />
            {' '}
            {this.props.buttonText}
          </Button>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
          <ModalBody style={{ paddingTop: '30px' }}>
            <div style={{ fontWeight: 600 }}>{this.props.header}</div>
            {this.props.description}
          </ModalBody>
          <ModalFooter style={{ padding: '10px', paddingRight: '20px' }}>
            <Button onClick={this.handleClose} color="secondary">No</Button>
            <Button onClick={this.yes} color="danger" autoFocus>Yes</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default ConfirmDialog
