// import AsanaOAuth from "views/AsanaOAuth.js";
import 'bootstrap/dist/css/bootstrap.css'
import 'assets/scss/paper-dashboard.scss?v=1.2.0'
import 'assets/demo/demo.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import { createBrowserHistory } from 'history'
import $ from 'jquery'
import CareerLayout from 'layouts/Career.jsx'
import CleverTapLayout from 'layouts/CleverTap.js'
import CustomerLayout from 'layouts/Customer.jsx'
import DashboardLayout from 'layouts/Dashboard.js'
import GNNLayout from 'layouts/GNN.js'
import GoCodeLayout from 'layouts/GoCode.js'
import LearningJournalLayout from 'layouts/LearningJournal'
// import MercuryLayout from 'layouts/Mercury.js'
import NexusCollaborationLayout from 'layouts/NexusCollaboration.jsx'
import OnboardingLayout from 'layouts/Onboarding'
import OrgChartLayout from 'layouts/OrgChart'
import PACLayout from 'layouts/PAC.js'
import PortalLayout from 'layouts/Portal.js'
import QLSLayout from 'layouts/QLS.js'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { createStore } from 'redux'
import LoginPage from 'views/LoginPage/LoginPage'
import ProfilePage from 'views/Profile/Profile.js'

import isProdEnv from './assets/utils/env'
import ToastProvider from './context/UseToast'
import ApprovalLayout from './layouts/Approvals'
// import EventCatalogLayout from './layouts/EventCatalog/EventCatalog'
// import EventProperties from './layouts/EventCatalog/EventProperties'
// import KudosLanding from "views/Appreciate/KudosLanding";
import KudosLandingLayout from './layouts/KudosLanding'
import NexusReportLayout from './layouts/NexusReport'
import ProtoLayout from './layouts/ProtoCatalog'
import { loadState, saveState } from './redux/localStorage'
import appState from './redux/reducers'
// import AuthLayout from "layouts/Auth.js";
import CalendarPage from './views/Calendar.js'
import Charts from './views/Charts.js'
import Timeline from './views/pages/Timeline.js'
import Proposal from './views/Proposal.js'
import Widgets from './views/Widgets.js'


if (isProdEnv) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID_PROD)
}
window.$ = $

const persistedState = loadState()
const store = createStore(
    appState,
    persistedState,
    window.devToolsExtension && window.devToolsExtension(),
)
store.subscribe(() => {
  saveState(store.getState())
})

const hist = createBrowserHistory()


ReactDOM.render(
    <Provider store={store}>
    <Router history={hist}>
        <ToastProvider>
        <Switch>
            <Route path="/profile/:employeeID/:apiKey" render={(props) => <ProfilePage {...props} />} />

            {/* <Route path="/calendar" render={(props) => <CalendarPage {...props} />} /> */}
            {/* <Route path="/charts" render={(props) => <Charts {...props} />} /> */}
            {/* <Route path="/timeline" render={(props) => <Timeline {...props} />} /> */}
            {/* <Route path="/widgets" render={(props) => <Widgets {...props} />} /> */}

            {/* <Route path="/proposal" render={(props) => <Proposal {...props} />} /> */}

            <Route path="/login" render={(props) => <LoginPage {...props} />} />

            {/* <Route path="/approval/:personID" render={(props) => <CareerLayout approval {...props} />} /> */}

            {/* <Route path="/career/:personID" render={(props) => <CareerLayout career {...props} />} /> */}
            {/* <Route path="/career" render={(props) => <CareerLayout career {...props} />} /> */}

            {/* <Route path="/gnn" render={(props) => <GNNLayout {...props} />} /> */}

            {/* <Route path="/wgh" render={(props) => <CareerLayout fiveDysfunctions {...props} />} /> */}
            {/* <Route path="/wghs" render={(props) => <CareerLayout fiveDysfunctionsSurvey {...props} />} /> */}

            {/* <Route
            path="/manager/employee/:employeeID"
            render={(props) => <CareerLayout employee {...props} />}
          />
            <Route path="/manager/qls/:managerID" render={(props) => <CareerLayout qls {...props} />} /> */}
            {/* <Route path="/manager/qls" render={(props) => <CareerLayout qls {...props} />} /> */}
            <Route path="/manager/:managerID" render={(props) => <CareerLayout manager {...props} />} />
            {/* <Route path="/manager" render={(props) => <CareerLayout manager {...props} />} /> */}

            <Route path="/learningJournal" render={(props) => <LearningJournalLayout {...props} />} />

            {/* <Route path="/mentoring" render={(props) => <CareerLayout mentoring {...props} />} /> */}

            {/* <Route path="/clevertap" render={(props) => <CleverTapLayout {...props} />} /> */}
            <Route
            path="/mercury/proto"
            render={(props) => {
                ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
                return <ProtoLayout {...props} />
              }}
          />
          <Route path="/mercury/eventCatalog/:eventId" render={(props) => {
            // <EventProperties {...props} />
            window.location.href = `${process.env.REACT_APP_MERCURY_PLATFORM_HOST}/analytics/event-catalog/${props.match.params.eventId}`
            return null
          }} />
          <Route path="/mercury/eventCatalog" render={(props) => {
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
            // <EventCatalogLayout {...props} />
            window.location.href = `${process.env.REACT_APP_MERCURY_PLATFORM_HOST}/analytics/event-catalog`
            return null
          }}
          />
          <Route path="/mercury" render={(props) => {
            // <MercuryLayout {...props} />
            window.location.href = `${process.env.REACT_APP_MERCURY_PLATFORM_HOST}/analytics/event-analysis`
            return null
          }} />

            {/* <Route path="/gocode/apis" render={(props) => <GoCodeLayout view="apis" {...props} />} />
            <Route path="/gocode/synapse" render={(props) => <GoCodeLayout view="synapse" {...props} />} />
            <Route
            path="/gocode/standards/:standardID"
            render={(props) => <GoCodeLayout view="standards" {...props} />}
          />
            <Route path="/gocode/standards" render={(props) => <GoCodeLayout view="standards" {...props} />} />
            <Route path="/gocode" render={(props) => <GoCodeLayout {...props} />} />
            <Route path="/gdp" render={(props) => <PortalLayout {...props} />} /> */}
            <Route path="/feedback" render={(props) => <CustomerLayout feedback {...props} />} />

            {/* <Route path="/issues/:issueID" render={(props) => <CustomerLayout issues {...props} />} /> */}
            {/* <Route path="/issues/" render={(props) => <CustomerLayout issues {...props} />} /> */}

            {/* <Route exact path="/nexus" render={(props) => <NexusCollaborationLayout {...props} />} /> */}
            <Route exact path="/nexus/endpoints" render={(props) => <CustomerLayout nexus {...props} />} />
            <Route exact path="/nexus/reports" render={(props) => <NexusReportLayout {...props} />} />
            <Route exact path="/nexus/analytics" render={(props) => <NexusReportLayout {...props} />} />


            <Route path="/orgChart/:personID" render={(props) => <OrgChartLayout {...props} />} />
            <Route path="/orgChart" render={(props) => <OrgChartLayout {...props} />} />

            <Route path="/project/:projectID" render={(props) => <OrgChartLayout project {...props} />} />
            <Route path="/projects/:projectSlug" render={(props) => <OrgChartLayout project {...props} />} />

            {/* <Route
            path="/projecttracker/:teamID"
            render={(props) => <OrgChartLayout projectTracker {...props} />}
          /> */}
            {/* <Route path="/projecttracker" render={(props) => <OrgChartLayout projectTracker {...props} />} /> */}

            {/* <Route path="/onboarding" render={(props) => <OnboardingLayout {...props} />} /> */}
            {/* <Route path="/pac" render={(props) => <PACLayout {...props} />} /> */}
            {/* <Route path="/qls" render={(props) => <QLSLayout {...props} />} /> */}

            {/* <Route path="/reporting" render={(props) => <DashboardLayout {...props} />} /> */}

            <Route
            exact
            path="/teamChart"
            render={(props) => <OrgChartLayout team teamChartView {...props} />}
          />
            <Route
            path="/teamChart/:teamID"
            render={(props) => <OrgChartLayout team teamChartView {...props} />}
          />
            <Route
            path="/teamCharts/:teamSlug"
            render={(props) => <OrgChartLayout team teamChartView {...props} />}
          />
            <Route path="/team/:teamID" render={(props) => <OrgChartLayout team teamView {...props} />} />
            <Route path="/teams/:teamSlug" render={(props) => <OrgChartLayout team teamView {...props} />} />

            <Route path="/OKRs" render={(props) => <OrgChartLayout okrs {...props} />} />

            <Route path="/kudos" render={(props) => <KudosLandingLayout {...props} />} />
            {/* <Route path="/approvals" render={(props) => <ApprovalLayout {...props} />} /> */}
            <Route to="/" render={(props) => <OrgChartLayout {...props} />} />

          </Switch>
      </ToastProvider>
      </Router>
  </Provider>,
    document.getElementById('root'),
)
