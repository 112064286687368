import React from 'react'

function ProjectCardLink({ link, linkIdx }) {
  return (
    <li key={linkIdx}>
      <a href={link.url} target="_new" style={{ color: 'green' }}>{link.name}</a>
    </li>
  )
}

function ProjectCardLinks({ links }) {
  return (
    links && links.length > 0 && (
      <div style={{
        marginTop: '-6px',
        marginBottom: '8px',
        fontSize: '12px',
      }}
      >
        <div style={{ color: 'gray', fontSize: '11px' }}>Related Links:</div>
        <ul className="dashes">
          {links.map((link, linkIdx) => <ProjectCardLink link={link} linkIdx={linkIdx} key={linkIdx} />)}
        </ul>
      </div>
    )
  )
}

export default ProjectCardLinks
