import { storage } from './firebase';

export const profilePhotos = storage.ref('profilePhotos');
export const attachments = storage.ref('attachments');
export const content = storage.ref('content');

/*
export const blobs = storage.ref('blobs');

export const getBlob = (id) => {

    var storageRef = storage.ref();
    var blobsRef = storageRef.child('blobs');
    blobsRef.getBlob()
    //var spaceRef = storageRef.child('goconnect.appspot.com/blobs/d0dee72d-4432-459e-8dac-44409c9bbf8e/1660394927720557');

    console.log(id);
    var fileRef = blobs.child(id);
    storage.ref('blobs').getBlob(fileRef).then(blob => {
        console.log(blob)
    });
    console.log(fileRef);
    //fileRef.getBlob(id).then(blob => {
      //  return blob;
    //})
};
*/

export const uploadProfilePhotos = (fileName, blob) => {

    var fileRef = profilePhotos.child(fileName);

    return new Promise((resolve, reject) => {
        fileRef.put(blob).then(function(snapshot) {
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                resolve( downloadURL );
            });
        })
    });
}

export const uploadAttachments = (fileName, blob) => {

    var fileRef = attachments.child(fileName);

    return new Promise((resolve, reject) => {
        fileRef.put(blob).then(function(snapshot) {
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                resolve( downloadURL );
            });
        })
    });
}