

import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, strings, telemetry } from 'assets/utils';



// reactstrap components
import {
    Card, CardBody, CardTitle,
    Row, Col, 
  } from "reactstrap";

import OKRs from "components/OKRs/OKRs.js";
import PersonCard from "components/PersonCard/PersonCard.js";
import TeamCard from "components/TeamCard/TeamCard.js"; 

import logo from "assets/img/goConnectIcon.png";

var defaultTeam;

class OKRsPage extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
          isLoading: true,
          teams: [],
          loopCounter: 0,
          team: {},
          members: []
      }
    }

    componentDidMount() {

        document.title = 'OKRs - GoConnect';

        this.getTeams();

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "okrsPage", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    getTeams() {

        var root = {
            id: 'root',
            name: "GoTo",
            isScaffolding: true,
            children: [
                {
                    id: "4WIR9CpYkbHIWO8kF2b7",
                    name: "Gojek",
                    isScaffolding: false,
                    profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2Fb920872d-3d34-400d-acf7-1e56d15f71d7.png?alt=media&token=8558b6c8-1284-4286-ad2d-bfe781989182',
                    preload:[],
                    children: [
                        {
                            id: "corporate",
                            name: "Corporate",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "functional",
                            name: "Functional Orgs",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "product",
                            name: "Product Groups",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "platform",
                            name: "Platforms",
                            isScaffolding: true,
                            preload:[],
                            children:[]
                        }
                    ]
                },
                {
                    id: "jx0y4iLV90d3bd4V6QjB",
                    name: "GTF",
                    isScaffolding: false,
                    profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2F44d337da-8dbe-4f57-80a2-c62c19780e2c.png?alt=media&token=fd638d61-5e6f-4371-a4a5-af3a56f89cb5',
                    preload:[],
                    children: [{
                        id: "gtfCorporate",
                        name: "Corporate",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfFunctional",
                        name: "Functional Orgs",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfProduct",
                        name: "Product Groups",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfPlatform",
                        name: "Platforms",
                        isScaffolding: true,
                        preload:[],
                        children:[]
                    }]
                },
                
            ]
        }

        db.getTeams().then(teams => {

            for(var i = 0; i < teams.length; i++) {
                if(teams[i].id === '4WIR9CpYkbHIWO8kF2b7') {
                    defaultTeam = teams[i];
                    break;
                }
            }
            
            this.addTeams(root, teams);
        });
    }

    addTeams(root, teams) {

        for(var i = teams.length -1; i > -1; i--) {
            for(var x = 0; x < root.children.length; x++) {
                if(this.addTeamNode(root.children[x], teams[i]) > 0) {
                    teams.splice(i, 1);
                    break;
                }
            }
        }
        
        if(teams.length > 0) {
           
            if(this.state.loopCounter < 10) { // shouldn't need this, temporary 
                this.setState({loopCounter: this.state.loopCounter + 1}, () => {
                    this.addTeams(root, teams);
                })
            } else {

                console.log("leftovers", teams);

                this.setState(
                    { teams: [root], isLoading: false}, () => this.selectTeam(defaultTeam) );

            }
            
        } else {

            console.log('all teams accounted for')

            root.forEach(node => {
                node.children = node.preload;
                delete node.preload;
            })

            this.setState({
                teams: [
                    {
                        id: "4WIR9CpYkbHIWO8kF2b7",
                        name: "Gojek",
                        isScaffolding: false,
                        preload:[],
                        children: root 
                    }
                ], isLoading: false
            }, () => this.selectTeam(defaultTeam));
        }
    }

    addTeamNode(node, team) {
        
        if(!node.children) node.children = [];
        if(!node.preload) node.preload = [];

        if(node.id === team.parentID) { 

            node.preload.push(team);
            node.preload.sort(arraySort.byName);
            
            return 1;
        
        }
        else // this is not the node we're looking for so lets go through the children of this node
        {

            if(node.preload) {
                for(var i = 0; i < node.preload.length; i++) {
                    var result = this.addTeamNode(node.preload[i], team);
                    if(result > 0) return result;
                }
            }
                     
            if (node.children)
                for(var j = 0; j < node.children.length; j++) {
                    if(this.addTeamNode(node.children[j], team) > 0) return 1;
                }
        }
  
        return 0;
    }


    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    getTeamFontWeight(team) {
        
        if(team.id === this.state.team.id) {
            return '600';
        }
        else if(team.id === 'root' || team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return 600;
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform')
            return 500;
        else if(team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return 500;
        else 
            return 400;
    }

    getTeamFontColor(team) {
        if(team.id === this.state.team.id) {
            return 'green';
        }
        else if(team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return 'black';
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform' || team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return 'black';
        else 
            return "#666";
    }

    getTeamPaddingLeft(team) {
        if(team.id === 'root' || team.id === '4WIR9CpYkbHIWO8kF2b7' || team.id === 'jx0y4iLV90d3bd4V6QjB')
            return '0px';
        else if(team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return '6px';
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform' || team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return '12px';
        else 
            return "18px";
    }

    getRowBackgroundColor(team) {
        if(team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return '#aaa';
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform' |team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return 'white';
        else 
            return "white";
    }

    getTeamTextTransform(team) {
        if(team.id === 'root' || team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return 'uppercase';
        else 
            return 'none';
    }

    getCarret(team) {
        if(team.children && team.children.length > 0) 
            return <div onClick={() => this.collapseTeam(team)}
                        style={{fontSize:"11px", fontWeight:600, display:"inline-block", paddingTop:"10px", marginLeft:"0px", verticalAlign:"top", width:"5%", cursor:"pointer"}}>
                        <i className="fas fa-chevron-down" style={{color:"gray"}}></i>
                    </div>
        else if(team.preload && team.preload.length > 0) 
            return <div onClick={() => this.expandTeam(team)}
                        style={{fontSize:"11px", fontWeight:600, display:"inline-block", paddingTop:"10px", marginLeft:"0px", verticalAlign:"top", width:"5%", cursor:"pointer"}}>
                        <i className="fas fa-chevron-right" style={{color:"gray"}} ></i>
                    </div>
        else 
            return <div style={{display:"inline-block", paddingLeft:"10px", width:"5%"}}>

        </div>
    }

    getTeamCard(team) {
        if(team.id !== 'root' && team.id !== 'corporate' && team.id !== 'functional' && team.id !== 'product' && team.id !== 'platform' && team.id !== 'gtfCorporate' && team.id !== 'gtfFunctional' && team.id !== 'gtfProduct' && team.id !== 'gtfPlatform')
            return <TeamCard team={team} showThumb />
    }

    collapseTeam(team) {
       
        if (team.children.length > 0) {
            team.preload = team.children;
            team.children = [];
        }
        
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findNode(root[x], team);
        }

        this.setState({teams: root});
    }

    expandTeam(team) {

        if(team.preload) {
            team.children = team.preload
            delete team.preload;
        } 
        
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findNode(root[x], team);
        }

        this.setState({teams: root});
    }

    findNode(node, team) {

        if(node.id === team.id) {
            node = team;
        }
        else {
            try {
                if(node.children)
                    for(var i = 0; i < node.children.length; i++) 
                        this.findNode(node.children[i], team);
            }
            catch(exc) {
                console.log(exc)
            }
        }
    }

    getDetailRow(team, index) {
        return <div key={index}>
            <div style={{padding:"0px", borderTop:"1px solid #ddd",}}>
                <div style={{display:"inline-block", height:"40px", whiteSpace: "nowrap", width:"100%", paddingLeft:this.getTeamPaddingLeft(team) }}>

                    {this.getCarret(team)}
                    
                    <div style={{display:"inline-block", paddingLeft:"15px", paddingTop:"6px"}}>
                        {this.getTeamCard(team)}
                    </div>
                    <div title={team.name} onClick={() => this.selectTeam(team)}
                        style={{ color:this.getTeamFontColor(team), fontSize:"12px", textTransform:this.getTeamTextTransform(team), fontWeight:this.getTeamFontWeight(team),
                            display:"inline-block", paddingTop:"10px", paddingLeft:"20px", overflow: 'hidden', textOverflow: 'ellipsis',  verticalAlign:"top", cursor:"pointer"}}>
                        {strings.trim(team.name,24)}
                    </div>
                    
                </div>
            </div>
            <div style={{paddingLeft:"0px"}}>
                {team.children.map((team, index) => (
                    this.getDetailRow(team, index)
                ))}
            </div>
        </div>
    }

    selectTeam(team) {

        this.setState({team: {}}, () => {

            this.setState({team: team});

            db.getTeamMembers(team.id).then((teamMembers) => {
            
                var promises = [];
                for(var i = 0; i < teamMembers.length; i++) 
                    promises.push(db.getPerson(teamMembers[i].personID));
                
                Promise.all(promises).then(people => {

                    var members = [];
                    people.forEach(person => { // necessary to handle missing employees
                        if(person) members.push(person);
                    })

                    members.sort(arraySort.byName);
                    this.setState({members: members});
                })
            });
        })
        
    }

    currentUserPartOfTeam() {
        return this.state.members.find(member => member.userID === this.props.user.id)
    } 

    getOKRs() {
        if(this.state.team && this.state.team.id) {
            return <OKRs team={this.state.team} canEditOKR={this.currentUserPartOfTeam()}/>
        }
    }

    ownerCard(owner) {
        if(owner) {
            return (
                <PersonCard person={owner} showThumb />
            )
        }
    }

    teamCard(team) {
        if(team) {
            return (
                <TeamCard team={team} showThumb />
            )
        }
    }

    render() {

        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div style={{padding:"20px"}}>
            
                <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                
                <div style={loadingDiv}>
                    <div className="spin">
                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                    </div>
                </div>
        

                <div style={{display:this.showResults()}}>
                    <Row style={{marginTop:"30px"}}>
                        <Col xs="12">
                            <CardTitle tag="h4">OKRs</CardTitle>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col xs="4">
                            <Card style={{padding:"20px"}}>
                                <CardBody>
                                    {this.state.teams.map((team, index) => (
                                        this.getDetailRow(team, index)
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="8">
                            <Card>
                                <CardBody style={{padding:"20px"}}>
                                    <Row>
                                        <Col xs="12">
                                            <CardTitle tag="h5">{this.state.team.name}</CardTitle>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            {this.state.team.description}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12">
                                            {this.state.members.map((person, index) => (
                                            <div key={index} style={{display:"inline-block", marginLeft:"2px", marginRight:"2px"}}>
                                                <PersonCard person={person} showLargeThumb />
                                            </div>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                        <Col xs="12">
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:"5px"}}>
                                        <Col xs="12">
                                            {this.getOKRs()}        
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                   
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

OKRsPage = connect(mapStateToProps)(OKRsPage);
export default OKRsPage;