import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Alert,
    Button,
    Card, CardBody, CardTitle,
    Row, Col
  } from "reactstrap";

import ReactBootstrapSlider from 'react-bootstrap-slider';

import PersonCard from "components/PersonCard/PersonCard.js";

  class FiveDysfunctionsSurvey extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        response: {
            personID: this.props.user.personID,
            managerID: this.props.user.person.managerID,
            userID: this.props.user.id,
            quarter: '2022q2',
            q1: 0,
            q2: 0,
            q3: 0,
            q4: 0,
            q5: 0,
            q6: 0,
            q7: 0,
            q8: 0,
            q9: 0,
            q10: 0,
            q11: 0,
            q12: 0,
            q13: 0,
            q14: 0,
            q15: 0,
            isFinal: false
          },
          manager: {},
          isLoading: true,
          alertVisible: false,
        }
    }

    componentDidMount() {

        db.getFiveDysfunctionsResponsesByUser(this.props.user.id).then(results => {
            if(results.length > 0)
              this.setState({response: results[0]})
        });

        // get data from DB to make sure we're not using a cached manager
        db.getPerson(this.props.user.person.id).then(person => {
            db.getPerson(person.managerID).then(manager => {
                this.setState({manager: manager});
            })
        });

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "fiveDysfunctions", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });

    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    updateResponse(property, value) {
        var obj = this.state.response;
        obj[property] = value;
        this.setState({response: obj});
    }


    saveResponse(submit) {

        var response = this.state.response;
        response.isFinal = submit;

        db.updateFiveDysfunctionsResponse(response.id, response).then((result) => {
            
            if(result)
                response.id = result.id;

            this.setState({alertVisible: true, response: response});
            if(!submit) {
                setTimeout(() => {
                    this.setState({alertVisible: false});
                }, 8000);
            }
        });
    }

    employeeCard() {
        if(this.props.user.person.id) {
            return (
                <PersonCard person={this.props.user.person} showThumb />
            )
        }
    }

    managerCard() {
        if(this.state.manager.id) {
            return (
                <PersonCard person={this.state.manager} showThumb />
            )
        }
    }

    disabled() {
        if(this.state.response.isFinal)
            return "disabled";
        else
            return "";
    }

    disabledButton() {
        if(this.state.response.isFinal)
            return "disabled";
        else
            return false;
    }


    render() {

        return (
            <div style={{padding:"10px"}}>
               
               <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    .slider {
                        width: 100%;
                    }
                    .slider-horizontal {
                        width: 100% !important;
                    }
                    .slider-track-low {
                        
                    }
                    .slider-track-high {
                        background-image: linear-gradient(to bottom, #efefef, #cdcdcd);
                    }
                    .slider-tick-label {
                        font-size: 11px;
                        color: green;
                    }
                    .slider-selection.tick-slider-selection {
                        background-image: linear-gradient(to bottom, green, #74bf73);
                        
                    }
                    .tooltip {
                        display: none;
                    }
                    .tooltip-main {
                        display: none;
                    }
                    .slider-handle {
                        background-image: linear-gradient(to bottom, green, #4ca635);
                    }
                    `
                }
                </style>

                
                <Row>
                    <Col xs="12">
                        
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="7" md="8" lg="9" style={{fontWeight:500, marginBottom:"20px"}}>
                        <CardTitle tag="h4">Feedback</CardTitle>

                        You were sent this survey at the conclusion of a meeting - 
                        please answer the following questions with that "team" in mind.
                        {/*}
                        We are committed to building better teams at Gojek. Like any other skill we 
                        want to improve, we need feedback to know what we're doing well and where 
                        we need to improve.<br /><br />

                        Neither your manager or anyone else in your management chain will see your answers 
                        to these survey questions. Survey results are only made available in aggregate and
                        only to managers of teams with at least five employees participating. If you're on 
                        a small team your manager won't be able to see their summary results but your responses
                        will still be included as part of the aggregate for your larger organisation.<br /><br />
                        */}
                    </Col>
                    <Col xs="12" sm="5" md="4" lg="3">
                        <Card>
                            <CardBody style={{padding:"20px", textAlign:"right"}}>
                                <div style={{display:"inline-block", marginRight:"10px", fontSize:"12px"}}><b>Employee</b><br />{this.props.user.person.name}</div>
                                <div style={{display:"inline-block"}}>{this.employeeCard()}</div>
                                
                                <br /><br />
                                
                                <div style={{display:"inline-block", marginRight:"10px", fontSize:"12px"}}><b>Manager</b><br />{this.state.manager.name}</div>
                                <div style={{display:"inline-block"}}>{this.managerCard()}</div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col sm="12" >
                        
                        <Card style={{padding:"20px"}}>
                            <CardBody style={{minHeight:"300px", padding:"23px"}}>

                                <Row>
                                    <Col xs="12" style={{fontSize:"14px", fontWeight:600, color:"green"}}>
                                        Please rate each of the following based on how strongly you agree or disagree with the 
                                        statement.<br /><br />  
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members are passionate and unguarded in their discussion of issues.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q1}
                                            change={(e) => this.updateResponse('q1', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members call out one another's deficiencies or unproductive behaviours.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q2}
                                            change={(e) => this.updateResponse('q2', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members know what their peers are working on and how they contribute to the collective goals of the team.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q3}
                                            change={(e) => this.updateResponse('q3', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members quickly and genuinely apologise to one another when they say or do something inappropriate or possibly damaging to the team.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q4}
                                            change={(e) => this.updateResponse('q4', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members willingly make sacrifices (such as budget, turf or headcount) in their departments or areas of expertise for the good of the team.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q5}
                                            change={(e) => this.updateResponse('q5', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members openly admit their weaknesses and mistakes.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q6}
                                            change={(e) => this.updateResponse('q6', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team meetings are compelling and not boring.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q7}
                                            change={(e) => this.updateResponse('q7', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members leave meetings confident that their peers are completely committed to the decisions that were agreed on, even if there was initial disagreement.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q8}
                                            change={(e) => this.updateResponse('q8', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Morale is significantly affected by the failure to achieve team goals.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q9}
                                            change={(e) => this.updateResponse('q9', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        During team meetings the most important - and difficult - issues are put on the table to be resolved.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q10}
                                            change={(e) => this.updateResponse('q10', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members are deeply concerned about the prospect of letting down their peers.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q11}
                                            change={(e) => this.updateResponse('q11', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members know about each others personal lives and are comfortable discussing them.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q12}
                                            change={(e) => this.updateResponse('q12', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members end discussions with clear and specific resolutions and calls to action.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q13}
                                            change={(e) => this.updateResponse('q13', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members challenge one another about their plans and approaches.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q14}
                                            change={(e) => this.updateResponse('q14', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{ paddingTop:"23px", fontSize:"15px", fontWeight:500}}>
                                        Team members are slow to seek credit for their own contributions but quick to point out those of others.
                                    </Col>
                                    <Col xs={false} sm="1"></Col>
                                </Row>
                                <Row>
                                    <Col xs={false} sm="1"></Col>
                                    <Col xs="12" sm="10" style={{paddingLeft:"20px", paddingRight:"20px", marginTop:"10px"}}>
                                        <ReactBootstrapSlider
                                            value={this.state.response.q15}
                                            change={(e) => this.updateResponse('q15', e.target.value)}
                                            step={1}
                                            max={3}
                                            min={1}
                                            ticks={["Rarely", "Sometimes", "Usually"]}
                                            ticks_labels={["Rarely", "Sometimes", "Usually"]}
                                            orientation="horizontal"
                                            reversed={false}
                                            style={{backgroundColor:"green", fontSize:"23px"}}
                                            disabled={this.disabled()}
                                        />
                                        <br /><br />
                                    </Col>
                                    <Col xs={false} sm="1">&nbsp;</Col>
                                </Row>

                            </CardBody>
                        </Card>
                        <br />

                        <Alert color="success" isOpen={this.state.alertVisible} toggle={() => this.setState({alertVisible: false})}>
                            Your submission has been saved.
                        </Alert>

                        <br />
                        <Row>
                            <Col sm="12" style={{color:"#666666"}}>
                                You can save your work to come back later. 
                                When you <b>submit</b> your survey the results will be final 
                                and you will not be able to change them until the next survey.
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" style={{textAlign:"right", marginTop:"20px"}}>
                                <div style={{display:"inline-block", marginRight:"10px"}}>
                                    <Button color="secondary" onClick={() => this.saveResponse(false)} size="md" disabled={this.disabledButton()} >
                                        Save
                                    </Button>
                                </div>
                                <div style={{display:"inline-block"}}>
                                    <Button color="success" onClick={() => this.saveResponse(true)} size="md" disabled={this.disabledButton()} >
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

FiveDysfunctionsSurvey = connect(mapStateToProps)(FiveDysfunctionsSurvey);
export default FiveDysfunctionsSurvey;