import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Card, CardBody, Col, Collapse, Container, Row,
} from 'reactstrap'

import { telemetry } from '../assets/utils'
import ProtoData from '../clickstream_proto_docs.json'
import Navbar from '../components/Navbars/ProtoCatalogNavbar'
import { sortByKey } from '../views/mercury/utils'
import ProtoCatalog from '../views/protoCatalog/ProtoCatalog'

function ProtoLayout(props) {
  useEffect(() => {
    telemetry.logUserActivity(props.user.id, props.user.personID, 'proto', 'pageView').then((activity) => {
      setInterval(() => telemetry.trackDwellTime(activity), 60000)
    })
  })

  const [lastActiveProto, setLastActiveProto] = useState(undefined)
  const [currentProto, setCurrentProto] = useState(undefined)
  const [sideBar, setSideBar] = useState({})
  // name processing of proto from json data
  const processName = (name) => {
    const nameArray = []
    let currentString = ''
    for (let i = 0; i < name.length; i += 1) {
      if (name[i] !== '/') {
        currentString += name[i]
      } else {
        currentString = currentString.toUpperCase()
        nameArray.push(currentString)
        currentString = ''
      }
      if (i === name.length - 1) {
        currentString = currentString[0].toUpperCase() + currentString.substring(1)
        nameArray.push(currentString)
      }
    }
    return nameArray
  }

  const getNewNode = (proto, protoPath, currentIndex) => {
    let newNode
    const path = protoPath[currentIndex]
    if (currentIndex === protoPath.length - 1) {
      newNode = {
        name: path,
        nodes: [],
        package: proto.package,
        hasEnums: proto.hasEnums,
        hasExtensions: proto.hasExtensions,
        hasMessages: proto.hasMessages,
        enums: proto.enums,
        description: proto.description,
        messages: proto.messages,
        extensions: proto.extensions,
        services: proto.services,
      }
    } else {
      newNode = {
        name: path,
        isOpen: (currentIndex === 0),
        nodes: [],
      }
    }

    return newNode
  }

  // create Proto Tree for sidebar
  const createProtoTree = () => {
    const protoTree = []
    for (let i = 0; i < ProtoData.files.length; i += 1) {
      const proto = ProtoData.files[i]
      const protoPath = processName(proto.name)
      let currentTree = protoTree
      for (let pathIndex = 0; pathIndex < protoPath.length; pathIndex += 1) {
        const path = protoPath[pathIndex]
        if (path !== 'CLICKSTREAM') {
          let found = false
          let currentNode
          for (let j = 0; j < currentTree.length; j += 1) {
            if (currentTree[j].name === path) {
              found = true
              currentNode = currentTree[j]
              break
            }
          }
          if (found) {
            currentTree = currentNode.nodes
            found = false
          } else {
            const newNode = getNewNode(proto, protoPath, pathIndex)
            currentTree.push(newNode)
            currentTree = currentTree[currentTree.length - 1].nodes
          }
        }
      }
    }
    return protoTree
  }

  function sortProtoTree(protoTree) {
    protoTree.sort(sortByKey(protoTree, 'name'))
    for (let i = 0; i < protoTree.length; i += 1) {
      const currentNode = protoTree[i]
      currentNode.nodes = sortProtoTree(currentNode.nodes)
    }
    return protoTree
  }

  const updateView = (proto, event) => {
    event.preventDefault()
    if (proto.nodes.length !== 0) {
      return
    }

    // eslint-disable-next-line no-param-reassign
    event.target.style.color = 'green'
    // eslint-disable-next-line no-param-reassign
    event.target.style.fontWeight = 'bold'
    if (lastActiveProto) {
      lastActiveProto.style.color = 'rgb(0,0,0)'
      lastActiveProto.style.fontWeight = 'normal'
    }
    setLastActiveProto(event.target)
    setCurrentProto(proto)
  }

  const toggle = (id, node) => {
    // eslint-disable-next-line no-param-reassign
    node.isOpen = false
    const tempSideBar = { ...sideBar }
    tempSideBar[id] = !tempSideBar[id]
    setSideBar(tempSideBar)
  }

  const toggleMenu = (node, id) => {
    const isOpen = node.isOpen || Boolean(sideBar[id])
    if (isOpen) {
      return (<i className="fas fa-chevron-down" style={{ color: 'gray' }} />)
    }
    return (<i className="fas fa-chevron-right" style={{ color: 'gray' }} />)
  }

  // create sidebar using Proto Tree
  const mapper = (nodes, parentId, lvl) => nodes.map((node) => {
    const id = `${node.name}-${parentId || 'top'}`.replace(/[^a-zA-Z0-9-_]/g, '')

    if (node.nodes.length) {
      return (
        <>
          <div
            style={{
              paddingLeft: `${30 * lvl}px`,
              margin: '10px',
              fontSize: '11px',
              fontWeight: 'bold',
              display: 'block',
              cursor: 'pointer',
              color: (parentId === undefined) ? 'grey' : 'black',
            }}
            onClick={() => { toggle(id, node) }}
          >
            {toggleMenu(node, id)}
            <span style={{ margin: '2px' }} />
            {node.name}
          </div>
          <hr style={{ margin: '0px', padding: '0px' }} />
          <Collapse isOpen={node.isOpen || sideBar[id]}>
            {mapper(node.nodes, id, (lvl || 0) + 1)}
          </Collapse>
        </>
      )
    }

    return (
      <>
        <div
          style={{
            paddingLeft: `${30 * lvl}px`,
            margin: '0px',
            paddingBottom: '10px',
            paddingTop: '10px',
            fontSize: '11px',
            fontWeight: 'normal',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={(event) => {
            updateView(node, event)
          }}
        >
          {node.name}
        </div>
        <hr style={{ margin: '0px', padding: '0px' }} />
      </>
    )
  })

  if (!props.user.authenticated) {
    props.onUserRedirected('/mercury/proto')
    return <Redirect to="/login" />
  }

  const protoTree = sortProtoTree(createProtoTree())
  return (
    <div className="wrapper" style={{ backgroundColor: '#efefef' }}>
      <Navbar
        user={props.user}
        otherPresent={[]}
        totalOthers={0}
      />
      <div style={{ marginTop: '60px', padding: '30px', display: 'block' }}>
        <Container fluid style={{ padding: '0px', marginLeft: '0px', height: '100vh' }}>
          <Row style={{ height: '100px' }}>
            <Col xs="4">
              <Card overflow="auto">
                <CardBody>
                  {mapper(protoTree)}
                </CardBody>
              </Card>
            </Col>
            <Col xs="8">
              <ProtoCatalog
                user={props.user}
                currentProto={currentProto}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-func-assign
ProtoLayout = connect(mapStateToProps)(ProtoLayout)
export default ProtoLayout
