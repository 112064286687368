import React from "react";

import { 
    Button, 
    Row, Col, 
    Modal, ModalBody, ModalFooter,
    Card, CardBody,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import { db } from 'assets/firebase'; 
import { algolia } from 'assets/algolia';

const uuid = require('uuid');

class PageEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            isLoaded: false,
            page: {
                id: uuid.v4(),
                title: '',
                subTitle: '',
                abbreviation: ''
            },
            queryText: ''
        };

        if(props.page) {
            this.state.page = props.page;
        }

        this.savePage = this.savePage.bind(this);        
        this.toggleModal = this.toggleModal.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.page !== this.props.page) {
            this.setState({page: this.props.page});
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    updatePage(property, value) {
        var obj = this.state.page;
        obj[property] = value;
        this.setState({page: obj});
    }

    // save
    savePage() {
        
        var page = this.state.page;

        page.navItemID = this.props.navItem.id;
        
        db.updatePage(page.id, page).then(() => {

            if(this.props.onInsert) {
                this.props.onInsert(page);
                this.setState({
                    page: {
                        id: uuid.v4(),
                        title: '',
                        subTitle: '',
                        abbreviation: ''
                    }
                });

            } else if(this.props.onUpdate) {

                this.props.onUpdate(page);
            } 

            this.setState({modal:false});
        });
        
    }

    showMoveUp() {
        if(this.state.page.sortIndex === 0)
            return "none";
        else 
            return "block";
    }

    showMoveDown() {
        if(this.props.isLastItem)
            return "none";
        else 
            return "block";
    }

    showCard() {
        if(this.props.onInsert)
            return "none";
        else 
            return "block";
    }

    showButton() {
        if(this.props.onInsert)
            return "block";
        else 
            return "none";
    }

    searchPeople(queryText) {

        this.setState({queryText: queryText, searchResults: []}, () => {
            algolia.searchPeople(this.state.queryText).then(results => {
                console.log(results)
                this.setState({searchResults: results}); 
            });
        });
    }

    render() {
     
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const navIcon = {
            fontSize:"17px",
            marginTop:"3px",
            cursor:"pointer"
        }
       
        return (
            
            <div>
                <Button style={{display:this.showButton()}} onClick={this.toggleModal}>
                    Add Page
                </Button>
                <Card style={{display:this.showCard(), background: 'linear-gradient(to right, #898989 0%, #ababab 100%)', color:"white", borderRadius:"6px"}}>
                    <CardBody>
                        <Row>
                            <Col sm="2">
                                <div onClick={this.toggleModal}>
                                    <i style={navIcon} className="nc-icon nc-settings-gear-65" />  
                                </div>
                            </Col>
                            <Col sm="2">
                                {this.state.page.abbreviation} 
                            </Col>
                            <Col sm="6">{this.state.page.title}</Col>
                            <Col sm="1">
                                <div style={{display:this.showMoveUp()}}>
                                    <i style={navIcon} className="nc-icon nc-minimal-up" onClick={() => this.props.onMoveUp(this.state.page)} />  
                                </div>
                            </Col>
                            <Col sm="1">
                                <div style={{display:this.showMoveDown()}}>
                                    <i style={navIcon} className="nc-icon nc-minimal-down" onClick={() => this.props.onMoveDown(this.state.page)} />  
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                
                                <br /><br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Title <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-single-copy-04" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.page.title} onChange={(e) => this.updatePage("title", e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col md="3">
                                Abbreviation <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-single-copy-04" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.page.abbreviation} onChange={(e) => this.updatePage("abbreviation", e.target.value)} />
                                </InputGroup>
                            </Col>
                            {/* <Col md="3">
                                Owner <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-circle-10" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.page.queryText} onChange={(e) => this.searchPeople(e.target.value)} />
                                </InputGroup>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md="12">
                                Subtitle <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-single-copy-04" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.page.subTitle} onChange={(e) => this.updatePage("subTitle", e.target.value)} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                
                            </Col>
                        </Row>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div style={{padding:"20px"}}>
                            <Button color="success" onClick={this.savePage}>
                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                    <i style={navIcon} className="nc-icon nc-check-2" />  
                                </div>
                                <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                    Save
                                </div>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default PageEditor;