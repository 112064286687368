
import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected} from 'redux/actions';

//utils
import { presence } from 'assets/utils';

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from "react-router-dom";
import Navbar from "components/Navbars/OnboardingNavbar";
import Onboarding from "views/onboarding/Onboarding.js";

var ps;

class OnboardingLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      searchQuery: "",
      othersPresent: []
    };

    
  }
  componentDidMount() {

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
     // ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    this.getPresence();
    setInterval(() => this.getPresence(),60000);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getPresence() {
    var pageID = 'onboardingTips';
    
    presence.getOthersPresent(this.props.user.personID, pageID).then(result => {
      this.setState({othersPresent: result.people, totalOthers: result.totalOthers});
    });
  }
  
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  searchText(query) {
    this.setState({searchQuery: query, projectTrackerView: false, });
  }

  render() {

    if (!this.props.user.authenticated) {
      this.props.onUserRedirected('/onboarding');
      return <Redirect to='/login' />
    }

    return (
      <div className="wrapper" style={{backgroundColor:"#efefef"}}>
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick} 
            onSearch={this.searchText} 
            searchQuery={this.state.searchQuery}
            
            othersPresent={this.state.othersPresent}
            totalOthers={this.state.totalOthers}
          />

        <div style={{paddingTop:"66px"}}>
            <Onboarding />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      onUserRedirected: UserRedirected
       }, dispatch);  
}

OnboardingLayout = connect(mapStateToProps, mapDispatchToProps)(OnboardingLayout);
export default OnboardingLayout;
