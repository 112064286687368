import React from "react";

import { 
    Button, 
    Row, Col,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Modal, ModalBody, ModalFooter,
    NavItem, NavLink,
    Spinner
} from "reactstrap";

import { db } from "assets/firebase";

import { tables, telemetry } from 'assets/utils';

const uuid = require('uuid');

class JacaEditor extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            isLoading: false,
            roleFilter: '',
            levelFilter: '1',
            JACA: {
                scope: [ { category: '', detail: ''} ],
                complexity: [ { category: '', detail: ''} ],
                leadership: [ { category: '', detail: ''} ]
            }
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
        
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    getJACA(property, value) {

        this.saveJACA().then(() => {

            var roleFilter = this.state.roleFilter;
            if(property === 'role') 
            roleFilter = value;
    
            var levelFilter = this.state.levelFilter;
            if(property === 'level')
                levelFilter = value;
    
            this.setState({roleFilter: roleFilter, levelFilter: levelFilter, isLoading: true});
    
            db.getJACA(roleFilter, levelFilter).then(JACA => {
    
                console.log(JACA)

                if(!JACA) {
                    JACA = {
                        id: uuid.v4(),
                        level: levelFilter,
                        role: roleFilter,
                        title: '',
                        scope: [ { id: uuid.v4(), category: '', detail: ''} ],
                        complexity: [ { id: uuid.v4(), category: '', detail: ''} ],
                        leadership: [ { id: uuid.v4(), category: '', detail: ''} ]
                    }
                }
    
                this.setState({JACA: JACA, isLoading: false});
            });
        })
        
    }

    updateJACA(property, value) {
        var obj = this.state.JACA;
        obj[property] = value;
        this.setState({JACA: obj});
    }

    updateExpectation(expectation, category, property, value) {

        var obj = this.state.JACA;

        // e.g. detail = value
        expectation[property] = value;

        if(category === 'scope') {

            for(var i = 0; i < obj.scope.length - 1; i++) {
                if(obj.scope[i].id === expectation.id) {
                    obj.scope[i] = expectation;
                    break;
                }
            }
        }
        else if(category === 'complexity') {

            for(var j = 0; j < obj.complexity.length - 1; j++) {
                if(obj.complexity[j].id === expectation.id) {
                    obj.complexity[j] = expectation;
                    break;
                }
            }
        }
        else if(category === 'leadership') {

            for(var k = 0; k < obj.leadership.length - 1; k++) {
                if(obj.leadership[k].id === expectation.id) {
                    obj.leadership[k] = expectation;
                    break;
                }
            }
        }

        this.setState({JACA: obj});
    }

    addSubcategory(category) {

        var obj = this.state.JACA;

        if(category === 'scope') {
            obj.scope.push({ id: uuid.v4(), category: '', detail: ''});
        } else if (category === 'complexity') {
            obj.complexity.push({ id: uuid.v4(), category: '', detail: ''});
        } else if (category === 'leadership') {
            obj.leadership.push({ id: uuid.v4(), category: '', detail: ''});
        }

        this.setState({JACA: obj});
    }

    saveJACA() {
        return new Promise((resolve, reject) => {
            
            var JACA = this.state.JACA;
            if(JACA.id) {   
                db.updateJACA(JACA.id, JACA).then(() => {
                    resolve();
                })
            } else resolve();
        })
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    
        return (
            <div>
                <NavItem onClick={this.toggleModal}>
                  <NavLink className="btn-magnify" style={{marginTop:"5px"}}>
                        <i className="nc-icon nc-settings" />
                  </NavLink>
                </NavItem>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="xl" >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm="12">
                               <h4>JACA Editor</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                Role <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                            <i style={icon} className="nc-icon nc-settings" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" 
                                        type="select" 
                                        value={this.state.roleFilter} 
                                        onChange={(e) => this.getJACA("role", e.target.value)} >
                                            <option value='eng'>Engineer</option>
                                            <option value='pm'>Product Manager</option>
                                            <option value='pgm'>Program Manager</option>
                                            <option value='tpm'>Technical Program Manager</option>
                                            
                                    </Input>
                                </InputGroup>
                                <br />
                            </Col>
                            <Col xs="6">
                                Level <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                            <i style={icon} className="nc-icon nc-bullet-list-67" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" 
                                        type="select" 
                                        value={this.state.levelFilter} 
                                        onChange={(e) => this.getJACA("level", e.target.value)} >
                                            <option value='1'>1</option>
                                            <option value='2'>2</option>
                                            <option value='3'>3</option>
                                            <option value='4'>4</option>
                                            <option value='5'>5</option>
                                            <option value='6'>6</option>
                                    </Input>
                                </InputGroup>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px", height:"123px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                Title <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-badge" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="e.g. Senior Developer" value={this.state.JACA.title} onChange={(e) => this.updateJACA("title", e.target.value)} />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" style={{color:"green", fontSize:"13px", marginTop:"23px", marginBottom:"5px"}}>
                                Category:<span style={{fontSize:"14px", fontWeight:600}}> SCOPE</span>
                            </Col>
                        </Row>
                        {this.state.JACA.scope.map((expectation, index) => (
                            <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                <Col xs="4">
                                    <Input placeholder="subcategory" 
                                        type="text" style={{fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.category} onChange={(e) => this.updateExpectation(expectation, "scope", "category", e.target.value)} />
                                </Col>
                                <Col xs="8">
                                    <Input placeholder="expectations"
                                        type="textarea" style={{lineHeight:"17px", minHeight:"89px", fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.detail} onChange={(e) => this.updateExpectation(expectation, "scope", "detail", e.target.value)} />
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col xs="12" style={{marginTop:"3px"}}>
                                <Button size="sm" color='secondary' onClick={() => this.addSubcategory('scope')}>Add Subcategory</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" style={{color:"green", fontSize:"13px", marginTop:"23px", marginBottom:"5px"}}>
                                Category:<span style={{fontSize:"14px", fontWeight:600}}> COMPLEXITY</span>
                            </Col>
                        </Row>
                        {this.state.JACA.complexity.map((expectation, index) => (
                            <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                <Col xs="4">
                                    <Input placeholder="subcategory" 
                                        type="text" style={{fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.category} onChange={(e) => this.updateExpectation(expectation, "complexity", "category", e.target.value)} />
                                </Col>
                                <Col xs="8">
                                    <Input placeholder="expectations"
                                        type="textarea" style={{lineHeight:"17px", minHeight:"89px", fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.detail} onChange={(e) => this.updateExpectation(expectation, "complexity", "detail", e.target.value)} />
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col xs="12" style={{marginTop:"3px"}}>
                                <Button size="sm" color='secondary' onClick={() => this.addSubcategory('complexity')}>Add Subcategory</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" style={{color:"green", fontSize:"13px", marginTop:"23px", marginBottom:"5px"}}>
                                Category:<span style={{fontSize:"14px", fontWeight:600}}> LEADERSHIP</span>
                            </Col>
                        </Row>
                        {this.state.JACA.leadership.map((expectation, index) => (
                            <Row key={index} style={{backgroundColor:tables.rowColor(index), padding:"6px", paddingTop:"14px", paddingBottom:"14px", borderRadius:"6px"}}>
                                <Col xs="4">
                                    <Input placeholder="subcategory" 
                                        type="text" style={{fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.category} onChange={(e) => this.updateExpectation(expectation, "leadership", "category", e.target.value)} />
                                </Col>
                                <Col xs="8">
                                    <Input placeholder="expectations"
                                        type="textarea" style={{lineHeight:"17px", minHeight:"89px", fontSize:"12px", paddingLeft:"15px"}} 
                                        value={expectation.detail} onChange={(e) => this.updateExpectation(expectation, "leadership", "detail", e.target.value)} />
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col xs="12" style={{marginTop:"3px"}}>
                                <Button size="sm" color='secondary' onClick={() => this.addSubcategory('leadership')}>Add Subcategory</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col sm="12" style={{textAlign:"right"}}>
                                <Button color="success" onClick={() => this.saveJACA()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}

export default JacaEditor;