export const byCreated = (a,b) => {
  return a.created - b.created;
}

export const byCreatedDesc = (a,b) => {
  return b.created - a.created;
}

export const byPublishedDateString = (a, b) => {

  var x = b.published;
  var y = a.published;

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}

export const byProjectName = (a, b) => {

  var x = a && a.projectName && a.projectName.toLowerCase();
  var y = b && b.projectName && b.projectName.toLowerCase();

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}

export const byName = (a, b) => {

    var x = a.name.toLowerCase();
    var y = b.name.toLowerCase();

    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
}

export const byExposure = (a, b) => {

  var x = Number(a.probability) * Number(a.severity);
  var y = Number(b.probability) * Number(b.severity);

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}

export const bySeverity = (a, b) => {

  var x = a.severity;
  var y = b.severity;

  if(x === 'critical')
    x = 4;
  else if (x === 'high')
    x = 3;
  else if (x === 'medium')
    x = 2;
  else if (x === 'low')
    x = 1;
  else 
    x = 0;

  if(y === 'critical')
    y = 4;
  else if (y === 'high')
    y = 3;
  else if (y === 'medium')
    y = 2;
  else if (y === 'low')
    y = 1;
  else 
    y = 0;
  

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}

export const byText = (a, b) => {

  var x = a.text.toLowerCase();
  var y = b.text.toLowerCase();

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}

export const byTitle = (a, b) => {

  var x = a.title.toLowerCase();
  var y = b.title.toLowerCase();

  if (x < y)
    return -1;
  if (x > y)
    return 1;
  return 0;
}