
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { strings } from 'assets/utils';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    console.log(this.state)
  }

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
/*
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    */
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }


  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {

    const activePage = {
      color:"purple"
    }
    

    return routes.map((prop) => {

      if (prop.redirect) {
        return null;
      }

      if (prop.collapse) { // if it has children / opens up
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li style={{backgroundColor:'white'}}
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={prop.state}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }} 
              style={{backgroundColor:'#333333', color:"white", marginRight:'0px', marginLeft:'0px',  marginBottom:'1px', marginTop:'0px', paddingLeft:'10px', paddingRight:'6px'}}
            >
              {prop.icon !== undefined ? (
                <div>
                {prop.root ? (
                  <> {/* top level menu items  */}
                    <i className={prop.icon} />
                    <p style={{}}>
                      {prop.name} 
                      <b className="caret" />
                    </p>
                  </>
                ) : ( 
                  <div style={{marginLeft:"-6px", marginRight:"-6px", marginTop:"-5px", marginBottom:"-5px", paddingLeft:'10px', backgroundColor:"white"}}> {/* second level expandable menu items  */}
                    <i className={prop.icon} style={{fontSize:"13px", marginTop:"5px", color:'green'}} />
                    <p style={{fontSize:"11px", color:"#232323"}}>
                      {prop.name} 
                      <b className="caret" />
                    </p>
                  </div>
                )}
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal" style={{fontWeight:800}}>
                    ZZ {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      // if it doesn't have children
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={prop.state} style={{backgroundColor:'white'}}>
          <NavLink to={prop.layout + prop.path} style={{padding:'0px', backgroundColor:'white'}} >
            {prop.icon !== undefined ? (
              <div style={{padding:'6px', backgroundColor:'#ffffff'}}>
                {prop.indent ? (
                  <div style={{paddingLeft:"16px"}}>
                    <i className={prop.icon} />
                    <p>{prop.name} xxx </p>
                  </div>
                ) : (
                  <>
                    <i className={prop.icon} />
                    <p>WWW {prop.name}</p>
                  </>
                )}
              </div>
            ) : (
              <div style={{backgroundColor:'white', padding:'5px', paddingLeft:'0px', marginLeft:'-10px', whiteSpace:'nowrap'}}> 
                {prop.indent ? (
                  <div style={{marginLeft:"10px"}}>
                    <span className="sidebar-mini-icon" style={{color:"green"}} >{prop.mini}</span>
                    <span className="sidebar-normal" style={{color:"#232323", fontWeight:500}}>{strings.trim(prop.name,20)}</span>
                  </div>
                ) : (
                  <div>
                    <span className="sidebar-mini-icon" style={{color:"green", marginTop:"5px"}}>{prop.mini}</span>
                    <span style={{color:this.activePageColor(prop.path), fontWeight:this.activePageFontWeight(prop.path), marginLeft:"-3px"}}>{strings.trim(prop.name,20)}</span>
                  </div>
                )}
              </div>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  activePageColor = (routeName) => {
    var pageID = routeName.substr(routeName.lastIndexOf('/') + 1);
    return this.props.location.pathname.indexOf(pageID) > -1 ? "green" : "#232323";
  }
  activePageFontWeight = (routeName) => {
    var pageID = routeName.substr(routeName.lastIndexOf('/') + 1);
    return this.props.location.pathname.indexOf(pageID) > -1 ? "600" : "500";
  }
  
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {

    
    return (
      <div
        className="sidebar"
        data-color="#232323"
        data-active-color={this.state.activeColor}
      >
        <div className="sidebar-wrapper" ref="sidebar" style={{marginTop:"56px"}} >
          <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
