import React from "react";

import { db } from 'assets/firebase'; 

import { algolia } from 'assets/algolia';

import { strings, tables } from 'assets/utils';

import { 
    Button,
    Row, Col,
    Modal, ModalBody, ModalFooter,ModalHeader,
    Input
} from "reactstrap";
import OKRSearch from 'components/OKRs/OKRSearch.js';
import PersonSearch from 'components/PersonSearch/PersonSearch.js';
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

const uuid = require('uuid');

class OKREditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredOKRs: [],
      okrStatusFilter: 'all',
      okrCycleFilter: '22h1',
      OKRs: [],
      teamOKRs: [],
      openOKRSearch: false,
      openPersonSearch: false,
      isloadingOKR: true, 
    }
    this.saveOKR = this.saveOKR.bind(this)
  }

  componentDidMount() {
    db.getTeamOKRs(this.props.team.id).then((OKRs) => {

      var OKRIDs = [];
      OKRs.forEach(okr => {
          OKRIDs.push(okr.id);
      })

      OKRs.sort( (a,b) => { return a.number - b.number } );

      var owners = [];
      var promises = [];
      OKRs.forEach(okr => {

          okr.KRs.forEach(kr => {
              if(kr.ownerID) {
                  if(kr.ownerID.length > 0) {   
                      var isFound = false;
                      for(var o = 0; o < owners.length; o++) {
                          if(owners[o] === okr.ownerID) {
                              isFound = true;
                              break;
                          }
                      }
                      if(!isFound) promises.push(db.getPerson(kr.ownerID));
                  }
              }
          }) 
      })

      Promise.all(promises).then(people => {

          for(var q = people.length - 1; q > -1; q--)
              if(!people[q])
                  people.splice(q,1);

          OKRs.forEach(okr => {
              okr.KRs.forEach(kr => {
                  for(var p = 0; p < people.length; p++) {
                      if(kr.ownerID === people[p].id) {
                          kr.person = people[p];
                          break;
                      }
                  }
              });
          });
      });
      this.setState({OKRs: OKRs, teamOKRs: OKRIDs, isloadingOKR: false}, this.filterOKRs);

    });
  }

  addOKR() {
    var obj = this.state.OKRs;
    obj.push({
            id: uuid.v4(),
            number: this.state.filteredOKRs.length + 1, 
            teamID: this.props.team.id, 
            KRs: [], 
            created: new Date().getTime(), 
            cycle: this.state.okrCycleFilter, 
            status:'draft' 
        });
    this.setState({OKRs: obj}, this.filterOKRs);
  }

  updateOKR(okr, property, value) {
      var objs = this.state.OKRs;
      for(var i = 0; i < objs.length; i++) {
          if(objs[i].id === okr.id) {
              objs[i][property] = value;
              break;
          }
      }
      this.setState({OKRs: objs}, this.filterOKRs);
  }

  deleteOKR(okr) {
      var objs = this.state.OKRs;
      for(var i = 0; i < objs.length; i++) {
          if(objs[i].id === okr.id) {
              objs.splice(i,1);
              break;
          }
      }
      this.setState({OKRs: objs}, this.filterOKRs);
  }

  addKR(okr) {
      var objs = this.state.OKRs;
      for(var i = 0; i < objs.length; i++) {
          if(objs[i].id === okr.id) {
              objs[i].KRs.push({ id: uuid.v4(), number: objs[i].KRs.length + 1, description: "", ownerID: "", dependencies: [] });
              break;
          }
      }
      this.setState({OKRs: objs},this.filterOKRs);
  }

  deleteKR(okr, kr) {

      var objs = this.state.OKRs;
      for(var i = 0; i < objs.length; i++) {
          if(objs[i].id === okr.id) {
              for(var j = 0; j < objs[i].KRs.length; j++) {
                  if(objs[i].KRs[j].id === kr.id) {
                      objs[i].KRs.splice(j,1);
                      break;
                  }
              }
          }
      }
      
      this.setState({OKRs: objs},this.filterOKRs);
  }

  updateKR(okr, kr, property, value) {
      var objs = this.state.OKRs;
      for(var i = 0; i < objs.length; i++) {
          if(objs[i].id === okr.id) {
              for(var j = 0; j < objs[i].KRs.length; j++) {
                  if(objs[i].KRs[j].id === kr.id) {
                      objs[i].KRs[j][property] = value;
                      break;
                  }
              }
          }
      }
      this.setState({OKRs: objs},this.filterOKRs);
  }

  assignKROwner(person) {
      this.updateKR(this.state.assignToOKR, this.state.assignToKR, "ownerID", person.id);
      this.updateKR(this.state.assignToOKR, this.state.assignToKR, "person", person);
  }

  openPeopleSearch(okr, kr) {
      this.setState({openPersonSearch: true, assignToOKR: okr, assignToKR: kr}, () => {
          setTimeout(() => {
              this.setState({openPersonSearch: false});
          }, 500);
      });
  }

  openOKRSearch(okr, kr) {
      this.setState({openOKRSearch: true, assignToOKR: okr, assignToKR: kr}, () => {
          setTimeout(() => {
              this.setState({openOKRSearch: false});
          }, 500);
      })
  }

  createKRDependency(okr, kr) {
      this.updateKR(this.state.assignToOKR, this.state.assignToKR, "dependencies", [{okrID: okr.id, krID: kr.id, type:"upstream"}]);

      //update the upstream OKR/KR
      
      db.getOKR(okr.id).then(okr => {

          for(var j = 0; j < okr.KRs.length; j++) {
              if(okr.KRs[j].id === kr.id) {
                  
                  if(!okr.KRs[j].dependencies) okr.KRs[j].dependencies = [];
                  okr.KRs[j].dependencies.push({ okrID: this.state.assignToOKR.id, krID: this.state.assignToKR.id, type:"downstream" });
                  
                  db.updateOKR(okr.id, okr);

                  break;
              }
          }
      })
  }

  filterOKRs(filter, value) {

      var filtered = [];
      var okrs = this.state.OKRs;
      var cycleFilter = this.state.okrCycleFilter;
      if(filter === 'cycle')
          cycleFilter = value;

      var statusFilter = this.state.okrStatusFilter;
      if(filter === 'status')
          statusFilter = value;

      this.setState({okrCycleFilter: cycleFilter, okrStatusFilter: statusFilter});

      for(var i = 0; i < okrs.length; i++) {
          if(okrs[i].cycle === cycleFilter)
              if(okrs[i].status === statusFilter || statusFilter === 'all')
                  filtered.push(okrs[i]);                
      }

      this.setState({filteredOKRs: filtered });
  }

  saveOKR() {
     // add / update OKRs
     var promises = [];
     this.state.OKRs.forEach(okr => {
        okr.KRs.forEach(kr => {
            delete kr.person;
        })
        promises.push(db.updateOKR(okr.id, okr)); 
        // algolia.upsertOKR(okr);
    });

    // delete OKRs
    for(var v = 0; v < this.state.teamOKRs.length; v++) {
        var isOKRFound = false;
        for(var w = 0; w < this.state.OKRs.length; w++) {
            if(this.state.teamOKRs[v] === this.state.OKRs[w].id) {
                isOKRFound = true;
                break;
            }
        }

        if(!isOKRFound) {
            promises.push(db.deleteOKR(this.state.teamOKRs[v]));
        }
    }
    Promise.all(promises).then(() => {
        this.props.updateOKRs(this.state.OKRs)
        this.props.toggleEditOKR()
    })
 }

  render() {

    return (
      <Modal isOpen={this.props.isOpen}  size="lg" >
        <ModalHeader>Edit OKRs for {this.props.team.name}</ModalHeader>
        <ModalBody style={{paddingTop:"17px", paddingLeft:"43px", paddingRight:"43px", backgroundColor:"#efefef"}}>
          <Row style={{marginBottom:"20px"}}>
              <Col xs="6">
                  <Input placeholder="" 
                      type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                      value={this.state.okrCycleFilter} onChange={(e) => this.filterOKRs("cycle", e.target.value)} >
                          <option value="21h2">2021 H2</option>
                          <option value="22h1">2022 H1</option>
                          <option value="22h2">2022 H2</option>
                  </Input>
              </Col>
              <Col xs="6">
                  <Input placeholder="" 
                      type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                      value={this.state.statusFilter} onChange={(e) => this.filterOKRs("status", e.target.value)} >
                          <option value="all">All</option>
                          <option value="draft">Draft</option>
                          <option value="approved">Approved</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="complete">Complete</option>
                  </Input>
              </Col>
          </Row>
          {this.state.filteredOKRs.map((okr, index) => (
              <Row key={index} style={{padding:"10px", paddingTop:"20px", backgroundColor:tables.rowColor(index), borderTop: "1px solid #888888"}}>
                  <Col xs="1" >
                      <input type="text" value={okr.number} placeholder="" 
                          onChange={(e) => this.updateOKR(okr, "number", e.target.value)} 
                          style={{borderStyle:"solid", borderColor:"green", borderWidth:"1px", backgroundColor:"white", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                  </Col>
                  <Col sm="8" >
                      <textarea value={okr.description} placeholder="objective" 
                              onChange={(e) => this.updateOKR(okr, "description", e.target.value)} 
                              style={{borderStyle:"solid", borderColor:"green", borderWidth:"1px", backgroundColor:"white", padding:"6px", lineHeight:"17px", minHeight:"84px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                  </Col>
                  <Col xs="3" style={{fontSize:"11px"}}>
                      
                      <Input placeholder="" 
                          type="select" style={{border:"1px solid green", fontSize:"11px"}}
                          value={okr.status} onChange={(e) => this.updateOKR(okr,"status", e.target.value)} >
                              <option value="draft">Draft</option>
                              <option value="approved">Approved</option>
                              <option value="cancelled">Cancelled</option>
                              <option value="completed">Completed</option>
                      </Input>
                      <br />
                      <Input placeholder="" 
                          type="select" style={{border:"1px solid green", fontSize:"11px", width:"100%"}}
                          value={okr.cycle} onChange={(e) => this.updateOKR(okr,"cycle", e.target.value)} >
                              <option value="21h2">2021 H2</option>
                              <option value="22h1">2022 H1</option>
                              <option value="22h2">2022 H2</option>
                      </Input> 

                  </Col>

                  <Col xs="12">
                      {okr.KRs.map((kr, index) => (
                          <Row key={index} style={{marginTop:"17px"}}>
                              <Col xs="1" style={{fontSize:"11px", color:"green", textAlign:"right", marginTop:"6px"}}>
                                  {okr.number}.
                              </Col>
                              <Col xs="1" >
                                  <input type="text" value={kr.number} placeholder="objective" 
                                      onChange={(e) => this.updateKR(okr, kr, "number", e.target.value)} 
                                      style={{borderStyle:"solid", borderColor:"green", borderWidth:"1px", backgroundColor:"white", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                              </Col>
                              <Col xs="10">
                                  <textarea value={kr.description} placeholder="key result" 
                                      onChange={(e) => this.updateKR(okr, kr, "description", e.target.value)} 
                                      style={{borderStyle:"solid", borderColor:"green", borderWidth:"1px", backgroundColor:"white", padding:"6px", lineHeight:"17px", minHeight:"84px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                              </Col>
                              <Col xs="2">

                              </Col>
                              <Col xs="10" style={{paddingTop:"5px"}}>
                                  <textarea value={kr.notes} placeholder="notes" 
                                      onChange={(e) => this.updateKR(okr, kr, "notes", e.target.value)} 
                                      style={{borderStyle:"solid", borderColor:"gray", borderWidth:"1px", backgroundColor:"white", padding:"6px", lineHeight:"17px", minHeight:"84px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                              </Col>
                              <Col xs="2">

                              </Col>
                              <Col xs="10" style={{fontSize:"11px", position:"relative"}}>
                                  {/* <div style={{display:"inline-block", width:"50%"}}>
                                      <div onClick={() => this.openPeopleSearch(okr, kr)} 
                                          style={{cursor:"pointer", borderStyle:"solid", borderColor:"green", borderWidth:"1px",borderRadius:"6px", fontSize:"12px", padding:"6px", paddingLeft:"10px", paddingRight:"10px", backgroundColor:"white", minHeight:"34px"  }}>
                                              {kr.person ? strings.trim(kr.person.name,23) : ''}
                                      </div>
                                  </div> */}
                                  {/* <div style={{display:"inline-block", marginLeft:"10px", verticalAlign:"top"}}>
                                      <Button color="secondary" size="sm" onClick={() => this.openOKRSearch(okr, kr)} >Add Dependency</Button>
                                  </div> */}
                                  <div style={{display:"inline-block", marginLeft:"10px", verticalAlign:"top"}}>
                                      <Button color="danger" size="sm" onClick={() => this.deleteKR(okr, kr)} >Delete KR</Button>
                                  </div>
                              </Col>
                          </Row>
                      ))}
                  </Col>

                  <Col xs="12" style={{paddingTop:"11px"}}>
                      <hr />
                      <Row key={index} style={{marginTop:"10px"}}>
                          <Col xs="12">
                              <div style={{display:"inline-block", fontSize:"11px", marginRight:"6px"}}>
                                  <Button color="secondary" onClick={() => this.addKR(okr)} >Add Key Result</Button>
                              </div>
                              <div style={{display:"inline-block"}}>
                                  <ConfirmDialog 
                                      buttonText="Delete OKR"
                                      title="Delete OKR" 
                                      header="CONFIRM" 
                                      description="Are you sure you want to delete this OKR?" 
                                      confirmed={() => this.deleteOKR(okr)} /> 
                              </div>
                          </Col>
                      </Row>
                  </Col>
              </Row>
          ))}
          <Button onClick={() => this.addOKR()}>Add New OKR</Button>
          {/* <div style={{display:"none"}}>
              <OKRSearch isOpen={this.state.openOKRSearch} onKRChosen={(okr, kr) => this.createKRDependency(okr, kr)} />
              <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignKROwner(p)} />
          </div> */}
          </ModalBody>
        <ModalFooter style={{paddingRight:"43px"}}>
            <Button color="secondary" size="md" onClick={this.props.toggleEditOKR}>Close</Button>
            <Button color="success" size="md" onClick={this.saveOKR}>Save</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default OKREditor
