import React from 'react'
import {
  Col, Input, Row, Table,
} from 'reactstrap'

import { tables } from '../../../assets/utils'
import IssueEditor from '../../IssueEditor/IssueEditor'
import TagBadges from '../../Tag/TagBadges'
import { getUrgencyDescriptor, getValueDescriptor } from '../../utils'

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
function IssueTable({
  filtered, onIssueUpdated, onIssueDeleted, sortIssues, allTagsRef,
}) {
  return (
    <Table>
      <thead>
        <tr style={{ fontSize: '11px', fontWeight: '700', borderColor: 'pink' }}>
          <td />
          <td>Title</td>
          <td>Tags</td>

          <td
            style={{ color: 'green', cursor: 'pointer', textAlign: 'center' }}
            onClick={() => sortIssues('value')}
          >
            Value
          </td>
          <td
            style={{ color: 'green', cursor: 'pointer', textAlign: 'center' }}
            onClick={() => sortIssues('urgency')}
          >
            Urgency
          </td>
        </tr>
      </thead>
      <tbody>
        {filtered.map((issue, index) => (
          <tr key={index} style={{ fontSize: '12px', backgroundColor: tables.rowColor(index), padding: '0px' }}>
            <td style={{ padding: '3px', paddingLeft: '10px' }}>
              <IssueEditor
                issue={issue}
                onUpdated={(currIssue) => onIssueUpdated(currIssue)}
                onDeleted={(currIssue) => onIssueDeleted(currIssue)}
                showOwnerThumb
              />
            </td>
            <td>
              {issue.title}
              <br />
              <div style={{ color: 'green', fontSize: '11px' }}>
                {issue.projectName}
              </div>
            </td>
            <td>
              <TagBadges tagIDs={issue.tags} allTagsRef={allTagsRef} />
            </td>
            <td style={{ textAlign: 'center' }}>
              {getValueDescriptor(issue.value)}
            </td>
            <td style={{ textAlign: 'center' }}>
              {getUrgencyDescriptor(issue.urgency)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

  )
}
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */

function TasksTab({
  projectID, isLoading,
  allTagsRef, filtered, filterIssues,
  sortIssues, taskStatusFilter,
  onIssueCreated, onIssueUpdated, onIssueDeleted,
}) {
  return projectID && (
    <>
      <Row>
        <Col xs="6">
          Status
          <Input
            placeholder=""
            type="select"
            style={{ border: '1px solid #888888', fontSize: '11px' }}
            value={taskStatusFilter}
            onChange={(e) => filterIssues('status', e.target.value)}
          >
            <option value="all">All</option>
            <option value="Active">Active</option>
            <option value="Closed">Closed</option>
          </Input>
          <br />
        </Col>
        <Col xs="6" style={{ textAlign: 'right' }}>
          <IssueEditor
            projectID={projectID}
            type="task"
            onCreated={(issue) => onIssueCreated(issue)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <div style={{ backgroundColor: 'white', minHeight: '89px' }}>
            {!isLoading && (
              <div>
                <IssueTable
                  filtered={filtered}
                  onIssueDeleted={onIssueDeleted}
                  onIssueUpdated={onIssueUpdated}
                  sortIssues={sortIssues}
                  allTagsRef={allTagsRef}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}
export default TasksTab
