// db
import React from 'react'
import { connect } from 'react-redux'
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from 'react-router-dom'
// redux
import { bindActionCreators } from 'redux'

import { db } from '../assets/firebase'
// utils
import { presence } from '../assets/utils'
import Navbar from '../components/Navbars/CareerNavbar'
import { UserRedirected } from '../redux/actions'
import Approval from '../views/career/Approval'
import Career from '../views/career/Career'
import Employee from '../views/career/Employee'
import FiveDysfunctions from '../views/career/FiveDysfunctions'
import FiveDysfunctionsSurvey from '../views/career/FiveDysfunctionsSurvey'
import Manager from '../views/career/Manager'
import Mentoring from '../views/career/Mentoring'
import QLS from '../views/career/QLS'

let ps

class CareerLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      othersPresent: [],
      peopleManager: false,
    }

    if (this.props.fiveDysfunctions) {
      this.state.view = 'fiveDysfunctions'
    } else if (this.props.fiveDysfunctionsSurvey) {
      this.state.view = 'fiveDysfunctionsSurvey'
    } else if (this.props.employee) {
      this.state.view = 'employee'
    } else if (this.props.manager) {
      this.state.view = 'manager'
    } else if (this.props.mentoring) {
      this.state.view = 'mentoring'
    } else if (this.props.approval) {
      this.state.view = 'approval'
    } else if (this.props.qls) {
      this.state.view = 'qls'
    } else { this.state.view = 'career' }
  }

  componentDidMount() {
    this.getPresence()
    setInterval(() => this.getPresence(), 60000)

    this.getDirectReports()

    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    */
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
      document.documentElement.className += ' perfect-scrollbar-off'
      document.documentElement.classList.remove('perfect-scrollbar-on')
    }
  }

  componentDidUpdate(e) {
    if (e.view !== this.props.view) {
      this.setState({ view: this.props.view })
    }

    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
  }

  getPresence() {
    let pageID = 'career'
    if (this.state.view === 'employee') { pageID = 'employee' }

    if (this.state.view === 'fiveDysfunctions') {
      pageID = 'fiveDysfunctions'
    } else if (this.state.view === 'fiveDysfunctionsSurvey') {
      pageID = 'fiveDysfunctionsSurvey'
    } else if (this.state.view === 'manager') {
      pageID = 'managerQLS'
    } else if (this.state.view === 'mentoring') {
      pageID = 'mentoring'
    } else if (this.state.view === 'approval') {
      pageID = 'approval'
    }

    presence.getOthersPresent(this.props.user.personID, pageID).then((result) => {
      this.setState({ othersPresent: [] }, () => {
        this.setState({ othersPresent: result.people, totalOthers: result.totalOthers })
      })
    })
  }

  getDirectReports() {
    db.getPeopleByManagerID(this.props.user.person.id).then((people) => {
      const directReports = []
      people.forEach((person) => {
        if (person) { directReports.push(person) }
      })

      if (directReports.length > 0) { this.setState({ peopleManager: true }) }
    })
  }

  approval() {
    if (this.state.view === 'approval') { return <Approval personID={this.props.match.params.personID} /> }
  }

  career() {
    if (this.state.view === 'career') { return <Career personID={this.props.match.params.personID} /> }
  }

  employee() {
    if (this.state.view === 'employee') { return <Employee employeeID={this.props.match.params.employeeID} /> }
  }

  fiveDysfunctions() {
    if (this.state.view === 'fiveDysfunctions') { return <FiveDysfunctions /> }
  }

  fiveDysfunctionsSurvey() {
    if (this.state.view === 'fiveDysfunctionsSurvey') { return <FiveDysfunctionsSurvey /> }
  }

  manager() {
    if (this.state.view === 'manager') { return <Manager managerID={this.props.match.params.managerID} /> }
  }

  mentoring() {
    if (this.state.view === 'mentoring') { return <Mentoring /> }
  }

  qls() {
    if (this.state.view === 'qls') { return <QLS managerID={this.props.match.params.managerID} /> }
  }

  render() {
    if (!this.props.user.authenticated) {
      if (this.props.approval) { this.props.onUserRedirected('/approval') }
      if (this.props.mentoring) {
        this.props.onUserRedirected('/mentoring')
      } else if (this.props.career) {
        this.props.onUserRedirected('/career')
      } else if (this.props.manager) {
        this.props.onUserRedirected('/manager')
      } else if (this.props.qls) {
        this.props.onUserRedirected('/qls')
      }

      return <Redirect to="/login" />
    }

    return (
      <div className="wrapper" style={{ backgroundColor: '#efefef' }}>
        <Navbar
          {...this.props}
          othersPresent={this.state.othersPresent}
          totalOthers={this.state.totalOthers}
          peopleManager={this.state.peopleManager}
          onCareerViewClick={() => this.setState({ view: 'career' })}
          onManagerViewClick={() => this.setState({ view: 'manager' })}
          onMentoringViewClick={() => this.setState({ view: 'mentoring' })}
          onQLSViewClick={() => this.setState({ view: 'qls' })}
          onLandingPageViewClick={() => this.setState({ view: 'landing' })}
          view={this.state.view}
        />
        <div style={{ marginTop: '63px', padding: '34px' }}>
          {this.approval()}
          {this.career()}
          {this.employee()}
          {this.fiveDysfunctions()}
          {this.fiveDysfunctionsSurvey()}
          {this.manager()}
          {this.mentoring()}
          {this.qls()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUserRedirected: UserRedirected,
  }, dispatch)
}

CareerLayout = connect(mapStateToProps, mapDispatchToProps)(CareerLayout)
export default CareerLayout
