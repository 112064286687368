import React, { useEffect, useState } from 'react'

import { strings } from '../../assets/utils'
import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import { ENGINEERING_PLATFORM_TEAM_ID } from '../../components/Nexus/constants'
import ShowReassignedData from '../nexusReports/ShowReassignedData'
import {
  BACKGROUND_COLORS, CUSTOMER_PLATFORM_PODS, getPodByIssue, getTeamName,
} from '../nexusReports/utils'

export default function ReassignedTicketsByPodPieChart({ issues, teams, team }) {
  const [chartData, setChartData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [selectedPod, setSelectedPod] = useState('')
  const [topReassignedIssues, setTopReassignedIssues] = useState([])
  const [totalReassignedIssues, setTotalReassignedIssues] = useState(0)

  const getReassignedIssuesMap = () => {
    const reassignedIssuesMap = {}

    issues.forEach((issue) => {
      if (!issue.reassignments || issue.reassignments.length === 0) {
        return
      }

      const teamID = getPodByIssue(issue)
      let teamName = teamID
      // If 'Customer Platform' pod or 'Engineering Platform', then team name is already present
      if (!CUSTOMER_PLATFORM_PODS.includes(teamID) && team !== ENGINEERING_PLATFORM_TEAM_ID) {
        teamName = getTeamName(teams, teamID)
      }

      if (teamName in reassignedIssuesMap) {
        reassignedIssuesMap[teamName].count += 1
        reassignedIssuesMap[teamName].issues.push(issue)
      } else {
        reassignedIssuesMap[teamName] = {
          count: 1,
          issues: [issue],
        }
      }
    })

    return reassignedIssuesMap
  }

  const populateChartData = () => {
    const topReassignedIssuesByPod = []
    const labels = []
    const data = []
    let total = 0

    const reassignedIssuesMap = getReassignedIssuesMap()
    const entries = Object.entries(reassignedIssuesMap)
    const sortedEntries = entries.sort((a, b) => b[1].count - a[1].count)

    sortedEntries.forEach((entry) => {
      const teamName = entry[0]
      const record = entry[1]

      labels.push(strings.trim(teamName, 21))
      data.push(String(record.count))

      topReassignedIssuesByPod.push({
        count: record.count,
        issues: record.issues,
        name: teamName,
      })
      total += record.count
    })

    setTopReassignedIssues(topReassignedIssuesByPod)
    setTotalReassignedIssues(total)
    setChartData({
      labels,
      datasets: [{
        label: 'Re-assigned tickets by Pod',
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: '#dee2e6',
        data,
        backgroundColor: BACKGROUND_COLORS.slice(0, reassignedIssuesMap.length),
      }],
    })
  }

  const showReassignedData = (e, chartElement) => {
    if (chartElement.length !== 1) {
      setShowModal(false)
      return
    }

    const { _index } = chartElement[0]

    setSelectedPod(_index)
    setShowModal(true)
  }

  const closeReassignedData = () => {
    setShowModal(false)
  }

  useEffect(() => {
    populateChartData()
  }, [issues])

  return (
    <>
      <DoughnutWithPercentage
        title="Re-assigned tickets by Pod"
        totalLabel="Total Reassigned Issues"
        totalRecords={totalReassignedIssues}
        sortedChartData={chartData}
        sortedRawData={topReassignedIssues}
        onClick={(e, chartRef) => showReassignedData(e, chartRef)}
      />
      {(showModal && topReassignedIssues[selectedPod] && topReassignedIssues[selectedPod].issues) && (
        <>
          <br />
          <ShowReassignedData
            reassignedIssues={topReassignedIssues[selectedPod].issues}
            closeReassignedData={closeReassignedData}
          />
        </>
      )}
    </>
  )
}
