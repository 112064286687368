import moment from 'moment'

import { toDaysAndHours } from '../../assets/utils/dateTime'
import {
  CARE_TEAM_ID, COMMERCE_ENABLEMENT_TEAM_ID,
  CUSTOMER_PLATFORM_TEAM_ID, ENGINEERING_PLATFORM_TEAM_ID,
  GOJEK_PRODUCT_SECURITY_TEAM_ID,
} from '../../components/Nexus/constants'

const currYear = new Date().getFullYear()

export function sortByCount(data) {
  return data.sort((a, b) => b.count - a.count)
}

export function sortObjectByIntValue(data) {
  const entries = Object.entries(data)
  return entries.sort((a, b) => b[1] - a[1])
}

export function getTeamName(teams, id) {
  const team = teams.find((elem) => elem.id === id) || {}
  return team.name
}

export function getIssueByMonth(issues, month) {
  return issues.filter((issue) => {
    const date = moment(issue.created)
    return date.format('MMM') === month && date.year() === currYear
  })
}

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function getPastMonths(forTeamPage, endDate) {
  let currMonth = new Date(endDate).getMonth() + 1
  if (!endDate) {
    currMonth = new Date().getMonth() + 1
  }
  const startMonth = currMonth > 6 ? currMonth - 6 : 0
  const months = monthNames.slice(startMonth, currMonth)

  const remainingMonths = 6 - months.length
  const pastSixMonths = monthNames.slice(12 - remainingMonths, 12)
  pastSixMonths.push(...months)

  return forTeamPage ? pastSixMonths.slice(2) : pastSixMonths
}

function getNextMonths(forTeamPage, startDate) {
  let currMonth = new Date(startDate).getMonth()
  if (!startDate) {
    currMonth = new Date().getMonth() + 1
  }
  const endMonth = currMonth > 6 ? 12 : currMonth + 6
  const nextSixMonths = monthNames.slice(currMonth, endMonth)

  const remainingMonths = 6 - nextSixMonths.length
  const months = monthNames.slice(0, remainingMonths)
  nextSixMonths.push(...months)

  return forTeamPage ? nextSixMonths.slice(0, 4) : nextSixMonths
}

function getPastSixWeeks() {
  const today = moment().startOf('isoWeek')
  const pastSixWeeks = []
  for (let i = 5; i > 0; i -= 1) {
    const lastIthWeek = moment().startOf('isoWeek').subtract(i * 7, 'days').format('DD MMM YY')
    pastSixWeeks.push(lastIthWeek)
  }
  pastSixWeeks.push(today.format('DD MMM YY'))
  return pastSixWeeks
}

function getMonthsInBetween(startDate, endDate) {
  let monthsInBetween = []
  const startMonth = new Date(startDate).getMonth()
  const endMonth = new Date(endDate).getMonth()

  if (startMonth > endMonth) {
    monthsInBetween = [...monthNames.slice(startMonth), ...monthNames.slice(0, endMonth + 1)]
  } else if (startMonth < endMonth) {
    monthsInBetween = monthNames.slice(startMonth, endMonth + 1)
  } else {
    monthsInBetween = [monthNames[startMonth]]
  }

  return monthsInBetween
}

export const pastSixMonths = getPastMonths()
export const pastFourMonths = getPastMonths().slice(2)
export const pastSixWeeks = getPastSixWeeks()

export function getMonthsForChart(startDate, endDate, forTeamPage) {
  let months = []

  if (!endDate) {
    // eslint-disable-next-line no-param-reassign
    endDate = new Date()
  }

  if (startDate && endDate) {
    // Get months inbetween start and end date
    months = getMonthsInBetween(startDate, endDate)
  } else if (startDate) {
    // Get the next months until today
    months = getNextMonths(forTeamPage, startDate)
  } else if (endDate) {
    // Get the last months
    months = getPastMonths(forTeamPage, endDate)
  } else {
    months = getPastMonths(forTeamPage)
  }

  return months
}

export function getMonthlyData(issues, months, severity) {
  const filteredIssues = severity ? issues.filter((issue) => issue.severity === severity) : issues

  return months.map((month) => getIssueByMonth(filteredIssues, month).length)
}

export function getPodByIssue(issue) {
  let key = issue.toPodID

  if (issue.toPodID === CUSTOMER_PLATFORM_TEAM_ID) {
    key = issue.jira ? issue.jira.toPod : issue.toPodID
  }
  if (issue.toPodID === ENGINEERING_PLATFORM_TEAM_ID) {
    key = issue.jira && issue.jira.fromPod ? issue.jira.fromPod : 'Not Available'
  }

  return key
}

export function getPodNameFromIssue(teams, issue) {
  if (issue.toPodID === CUSTOMER_PLATFORM_TEAM_ID) {
    return issue.jira ? issue.jira.toPod : issue.toPodID
  }
  if (issue.toPodID === ENGINEERING_PLATFORM_TEAM_ID) {
    return issue.jira && issue.jira.fromPod ? issue.jira.fromPod : 'Not Available'
  }

  return getTeamName(teams, issue.toPodID)
}

export function groupIssuesByTeamAndMonth(issuesWithTeam, months) {
  const issuesGroupByTeam = issuesWithTeam.reduce((acc, issue) => {
    const key = getPodByIssue(issue)

    if (acc[key]) {
      acc[key].push(issue)
    } else {
      acc[key] = []
      acc[key].push(issue)
    }
    return acc
  }, {})

  return Object.keys(issuesGroupByTeam).reduce((acc, team) => {
    acc[team] = getMonthlyData(issuesGroupByTeam[team], months)
    return acc
  }, {})
}

export const severityOptions = [
  { label: 'Critical', value: 'critical' },
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
]

export const statusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Closed', value: 'Closed' },
]

export const careStatusOptions = [
  { label: 'To Do', value: 'To Do' },
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Waiting On Information', value: 'Waiting On Information' },
  { label: 'Done', value: 'Done' },
  { label: 'Reopened', value: 'Reopened' },
]

export const statusMap = {
  'Open': 'To Do',
  'To Do': 'To Do',
  'Backlog': 'To Do',
  'Start': 'In Progress',
  'In Progress': 'In Progress',
  'Work in progress': 'In Progress',
  'Pending': 'In Progress',
  'Waiting on information': 'Waiting On Information',
  'Done': 'Done',
  'Reopened': 'Reopened',
  'Ready-For-Review': 'In Progress',
  'Disputed': 'To Do',
  'Risk-Accepted': 'Done',
  'Resolved': 'Done',
  'False-Positive': 'Done',
  'Reported': 'To Do',
  'N/A': 'Done',
  'Awaiting-Sec-ACK': 'To Do',
  'Risk-Rejected': 'To Do',
  'Sec-Risk-Declined': 'To Do',
  'Cancelled': 'Done',
  'Awaiting-Risk-Acceptance': 'To Do',
  'In-progress': 'In Progress',
  'Waiting for customer': 'Waiting On Information',
  'Archived': 'Done',
  'DEPLOYED/LIVE': 'Done',
  'CP: DONE': 'Done',
  'CP: ESCALATED': 'In Progress',
  'CP: In Progress': 'In Progress',
  'CP: To Do': 'To Do',
  'Prioritised': 'To Do',
  'In QA': 'In Progress',
  'Ready for Dev': 'To Do',
  'Ready  For  QA': 'In Progress',
  'Released': 'Done',
  'Live in Production': 'Done',
  'Obsolete': 'Done',
  'Waiting for PR Review': 'In Progress',
  'In Dev': 'In Progress',
  'Icebox': 'To Do',
  'IN DEV': 'In Progress',
  'READY FOR DEV': 'In Progress',
  'IN PR REVIEW/DEVBOX': 'In Progress',
  'READY FOR QA': 'In Progress',
  'IN QA': 'In Progress',
  'READY FOR PROD': 'In Progress',
  'Blocked': 'In Progress',
  'QA Done': 'In Progress',
  'PR Raised': 'In Progress',
  'Ready For QA': 'In Progress',
  'Done/Completed': 'Done',
  'Automate': 'To Do',
  'Whiteboard': 'To Do',
  'Dev Box Testing': 'In Progress',
  'QA Approved': 'In Progress',
  'Graveyard': 'Done',
  'TO DEPLOY': 'In Progress',
  'Kickoff': 'To Do',
  'Waiting': 'To Do',
  'Devbox': 'In Progress',
  'Devbox Completed': 'In Progress',
  'DEV BOX PENDING': 'In Progress',
  'Invalid': 'Done',
  'QA Test': 'In Progress',
  'Review': 'In Progress',
  'On Hold': 'Waiting On Information',
  'Dev Done': 'Done',
  'IN PROD': 'In Progress', // This is In Progress - PAYMENTS (For Infosec): Experience - Online
  'Analysis': 'To Do',
  'Live': 'Done',
  'Bug': 'In Progress',
  'Documentation': 'In Progress',
  'Ready to Deploy': 'In Progress',
  'QA Ready': 'In Progress',
  'LIVE': 'Done',
  'BUG': 'In Progress',
  'BLOCKED': 'Waiting On Information',
  'Selected for development': 'To Do',
  'QA In Progress': 'In Progress',
  'PR Reviewed': 'In Progress',
  'Test Ready': 'In Progress',
  'Ready for prod': 'In Progress',
  'Ready for dev': 'In Progress',
  'Ready for QA/ doc complete': 'In Progress',
  'In Analysis': 'In Progress',
  'In Prod': 'Done',
  'Prod ready / Ready to Merge': 'In Progress',
  'QA IN Progress': 'In Progress',
  'Dev/QA Blocked': 'Waiting On Information',
  'Code Review': 'In Progress',
  'No more required': 'Done',
  'Ready to Pick': 'To Do',
  'Dev done': 'In Progress',
  'Ready to deploy': 'In Progress',
  'Testing': 'In Progress',
  'In Review': 'In Progress',
  'DEV-BOX DONE': 'In Progress',
  'Ready for Deployment': 'Done',
  'Ready for Testing': 'In Progress',
  'In Development': 'In Progress',
  'In Testing': 'In Progress',
  'In Production': 'In Progress',
  'TESTING': 'In Progress',
  'READY FOR TESTING': 'In Progress',
  'READY FOR DEPLOYMENT': 'In Progress',
  'Story Kickoff': 'To Do',
  'Discarded': 'Done',
  'Dev In Progress': 'In Progress',
  'Ready for Develop Branch': 'In Progress',
  'Ready for QA': 'In Progress',
  'Ready To Pick': 'To Do',
  'QA IN PROGRESS': 'In Progress',
  'QA Reject': 'In Progress',
  'Code Review / Devbox': 'In Progress',
  'Ready For Merge': 'In Progress',
  'QA BLOCKED': 'In Progress',
  'Prod ready': 'In Progress',
  'Dev Blocked': 'In Progress',
  'IN REVIEW': 'In Progress',
  'Ready to be deployed': 'In Progress',
  'Ready to test': 'In Progress',
  'Deployed to prod': 'Done',
  'DEV BOX DONE': 'In Progress',
  'DEV DONE': 'In Progress',
  'READY TO DEPLOY': 'In Progress',
  'Dev Complete': 'In Progress',
  'Selected for Develop': 'In Progress',
  'Next Milestone': 'To Do',
  'QA DONE': 'In Progress',
  'production deployed': 'Done',
  'devbox': 'In Progress',
  'code review': 'In Progress',
  'LIVE ON PRODUCTION': 'Done',
  'Ready For Dev': 'In Progress',
  'In PROD': 'Done',
  'Ready for deployment': 'In Progress',
  'QA DONE / READY TO DEPLOY': 'In Progress',
  'PR Review': 'In Progress',
  'Resolved Ticket': 'Done',
  'Reject': 'Done',
  'Declared': 'In Progress',
  'Approved By BPO': 'In Progress',
  'Reviewed By PM': 'Done',
  'Waiting for code review': 'To Do',
  'PR RAISED': 'In Progress',
  'QA': 'In Progress',
  'Not Required': 'Done',
  'IN ANALYSIS': 'In Progress',
  'IN TESTING': 'In Progress',
  'Ready for Deploy': 'In Progress',
  'In PR Review': 'In Progress',
  'In Review By BPO': 'In Progress',
  'Escalated to PDG': 'In Progress',
  'Need Follow Up': 'Waiting On Information',
}

export const SLAOptions = [
  { label: 'Resolution time Exceeded', value: 'Resolution time Exceeded' },
  { label: 'Response time Exceeded', value: 'Response time Exceeded' },
  { label: 'Resolution time On-track', value: 'Resolution time On-track' },
  { label: 'Response time On-track', value: 'Response time On-track' },
]

export const sum = (list) => list.reduce((acc, number) => acc + number, 0)

export const getSLARuleInMins = (fromTeamID, toPodID) => {
  let TTRFromPod = fromTeamID
  if (toPodID === COMMERCE_ENABLEMENT_TEAM_ID) {
    TTRFromPod = COMMERCE_ENABLEMENT_TEAM_ID
  }

  if (toPodID === ENGINEERING_PLATFORM_TEAM_ID) {
    TTRFromPod = ENGINEERING_PLATFORM_TEAM_ID
  }

  switch (TTRFromPod) {
    case COMMERCE_ENABLEMENT_TEAM_ID:
      return {
        critical: {
          timeToResolution: 1440,
          timeToRespond: 15, // 15 minutes
        },
        high: {
          timeToResolution: 4320,
          timeToRespond: 2880, // 2 days
        },
        medium: {
          timeToResolution: 7200,
          timeToRespond: 4320, // 3 days
        },
        low: {
          timeToResolution: 14400,
          timeToRespond: 5760, // 4 days
        },
      }
    case GOJEK_PRODUCT_SECURITY_TEAM_ID:
      return {
        critical: {
          timeToResolution: 4320,
          timeToRespond: 1440,
        },
        high: {
          timeToResolution: 20160,
          timeToRespond: 4320,
        },
        medium: {
          timeToResolution: 43200,
          timeToRespond: 7200,
        },
        low: {
          timeToResolution: 129600,
          timeToRespond: 20160,
        },
      }
    case ENGINEERING_PLATFORM_TEAM_ID:
      return {
        critical: {
          timeToResolution: 2880,
          timeToRespond: 30,
        },
        high: {
          timeToResolution: 4320,
          timeToRespond: 480,
        },
        medium: {
          timeToResolution: 5760,
          timeToRespond: 720,
        },
        low: {
          timeToResolution: 7200,
          timeToRespond: 1440,
        },
      }
    default:
      return {
        critical: {
          timeToResolution: 180, // 3 hours
          timeToRespond: 15, // 15 minutes
        },
        high: {
          timeToResolution: 4320, // 3 days
          timeToRespond: 2880, // 2 days
        },
        medium: {
          timeToResolution: 10080, // 7 days
          timeToRespond: 4320, // 3 days
        },
        low: {
          timeToResolution: 20160, // 14 days
          timeToRespond: 5760, // 4 days
        },
      }
  }
}

function getSLAByMonth(issues, month, timeUnit) {
  let totalIssues = 0
  let totalIssuesAbidingSLA = 0

  issues.forEach((issue) => {
    const SLA_RULES = getSLARuleInMins(issue.fromTeamID, issue.toPodID)
    const date = moment(issue.created)
    if (date.format('MMM') === month && date.year() === currYear) {
      totalIssues += 1

      // Time in milliseconds
      let time = moment().diff(moment(issue.created))

      if (issue[timeUnit]) {
        if ((timeUnit === 'timeToResolution' && issue.status === 'Closed') || timeUnit === 'timeToRespond') {
          time = moment(issue[timeUnit]).diff(moment(issue.created))
        }
      }

      if (time < (SLA_RULES[issue.severity][timeUnit]) * (60 * 1000)) {
        totalIssuesAbidingSLA += 1
      }
    }
  })

  if (totalIssues === 0 || totalIssuesAbidingSLA === 0) {
    return 0
  }

  const percentage = (totalIssuesAbidingSLA / totalIssues) * 100
  return Math.round(percentage)
}

export function getSLAResolutionPercentageDataBySeverity(issues, months) {
  return months.map((month) => getSLAByMonth(issues, month, 'timeToResolution'))
}

export function getSLAResponsePercentageDataBySeverity(issues, months) {
  return months.map((month) => getSLAByMonth(issues, month, 'timeToRespond'))
}

export function isIssueBreachedSLA(issue) {
  let timeTakenInMs
  const unresolvedIssue = issue.status !== 'Closed'

  if (unresolvedIssue) {
    timeTakenInMs = moment().diff(moment(issue.created))
  } else {
    timeTakenInMs = moment(issue.timeToResolution).diff(moment(issue.created))
  }

  const SLA_RULES = getSLARuleInMins(issue.fromTeamID, issue.toPodID)
  const breachedSLA = timeTakenInMs >= (SLA_RULES[issue.severity].timeToResolution) * (60 * 1000)

  return breachedSLA
}

export function groupSLAResolutionPercentageDataByTeam(issuesWithTeam, months) {
  const issuesGroupByTeam = issuesWithTeam.reduce((acc, issue) => {
    const key = getPodByIssue(issue)

    if (acc[key]) {
      acc[key].push(issue)
    } else {
      acc[key] = [issue]
    }
    return acc
  }, {})

  return Object.keys(issuesGroupByTeam).reduce((acc, team) => {
    acc[team] = getSLAResolutionPercentageDataBySeverity(issuesGroupByTeam[team], months)
    return acc
  }, {})
}

export function groupSLAResponsePercentageDataByTeam(issuesWithTeam, months) {
  const issuesGroupByTeam = issuesWithTeam.reduce((acc, issue) => {
    const key = getPodByIssue(issue)

    if (acc[key]) {
      acc[key].push(issue)
    } else {
      acc[key] = [issue]
    }
    return acc
  }, {})

  return Object.keys(issuesGroupByTeam).reduce((acc, team) => {
    acc[team] = getSLAResponsePercentageDataBySeverity(issuesGroupByTeam[team], months)
    return acc
  }, {})
}

export const BACKGROUND_COLORS = ['#f90100', '#ffd966', '#edd077', '#407426', '#274e13',
  '#f44e1a', '#0d54ff', '#131a35', '#ffc0cb', '#ff8700', '#fb73bf', '#b38ed3', '#8dd100',
  '#f7da00', '#fa623b', '#0152a1', '#696969', '#03bdcc', '#1d919c', '#00876c', '#439981',
  '#6aaa96', '#8cbcac', '#aecdc2', '#cfdfd9', '#f0b8b8', '#ec9c9d', '#e67f83', '#de6069',
  '#d43d51']

export const SEVERITY_COLORS = {
  critical: 'rgb(234,82,82)',
  high: '#ef8156',
  medium: 'orange',
  low: 'green',
}

export const COLORS = {
  green: 'green',
  red: 'red',
  orange: 'orange',
  grey: 'grey',
}

export const SEVERITY_TYPES = ['critical', 'high', 'medium', 'low']
export const CUSTOMER_PLATFORM_PODS = ['Segmentation Service', 'Customer Owner', 'Vouchers & Promos',
  'Order History & Active Orders', 'Missions & Journeys', 'Global Search', 'Order Invoices',
  'GoClub Technical', 'Feedback Service', 'Shuffle', 'Grid', 'Caretech Agent', 'Caretech Customer', 'Caretech Owner',
  'Others']

export const ENGINEERING_PLATFORM_PODS_FILTER = ['Data Batching', 'Internal Products', 'Routing']

// Show source issues on Care and Product Security Teams Page
export const EXCEPTIONS_FOR_FUNCTIONAL_TEAMS_PAGE = [CARE_TEAM_ID, GOJEK_PRODUCT_SECURITY_TEAM_ID]

export const GOJEK_PRODUCT_SECURITY_ISSUE_TYPES = ['Cloud Misconfig', 'Vulnerability',
  'Vulnerability Automated', 'Vulnerability External', 'Leaked-Secrets', 'Secrets']

export const ISSUES_PAGE_LIMIT = 150

export const getTeamNames = (data, teams, teamID) => {
  const dataByTeamNames = {}

  Object.keys(data).forEach((team) => {
    let teamName = team
    // If 'Customer Platform' pod or 'Engineering Platform', then team name is already present
    if (!CUSTOMER_PLATFORM_PODS.includes(team) && teamID !== ENGINEERING_PLATFORM_TEAM_ID) {
      teamName = getTeamName(teams, team)
    }
    if (teamName) {dataByTeamNames[teamName] = data[team]}
  })

  return dataByTeamNames
}

export const getAverageDaysOfCompletion = (issues, severity) => {
  const filteredIssues = severity ? issues.filter((issue) => issue.severity === severity) : issues

  let totalTimeInMilliseconds = 0
  const resolvedIssues = filteredIssues.filter((issue) => issue.status === 'Closed' &&
   issue.timeToResolution !== null && issue.timeToResolution !== undefined)
  resolvedIssues.forEach((issue) => {
    totalTimeInMilliseconds += moment(issue.timeToResolution).diff(moment(issue.created))
  })

  if (resolvedIssues.length === 0) {
    return 'NA (No resolved issues)'
  }

  const averageDurationInMilliseconds = totalTimeInMilliseconds / (resolvedIssues.length)
  return toDaysAndHours(averageDurationInMilliseconds)
}

export const getAverageDaysOfCompletionByMonth = (issues, month) => {
  let resolvedIssues = 0
  let totalTimeInMilliseconds = 0

  issues.forEach((issue) => {
    const date = moment(issue.created)
    if (date.format('MMM') === month && date.year() === currYear) {
      resolvedIssues += 1
      totalTimeInMilliseconds += moment(issue.timeToResolution).diff(moment(issue.created))
    }
  })

  if (resolvedIssues === 0) {
    return 0
  }

  const averageDurationInMilliseconds = (totalTimeInMilliseconds / resolvedIssues)
  return (averageDurationInMilliseconds / (24 * 60 * 60 * 1000)).toFixed(2)
}

export const getMonthlyAverageDaysOfCompletion = (issues, months, severity) => {
  const filteredIssues = severity ? issues.filter((issue) => issue.severity === severity) : issues
  const resolvedIssues = filteredIssues.filter((issue) => issue.status === 'Closed' &&
  issue.timeToResolution !== null &&
  issue.timeToResolution !== undefined)

  return months.map((month) => getAverageDaysOfCompletionByMonth(resolvedIssues, month))
}
