import React from 'react'
import { Badge } from 'reactstrap'

import { arraySort } from '../../assets/utils'

function TagBadgeRow({ tag, index }) {
  const { color, name } = tag
  return (
    <div key={index} style={{ marginRight: '8px', marginBottom: '4px', display: 'flex' }}>
      <Badge color={color} pill style={{ marginBottom: '0px' }}>{name}</Badge>
    </div>
  )
}

function TagBadges({ tagIDs, allTagsRef }) {
  if (!tagIDs || !allTagsRef || !Array.isArray(tagIDs)) return <div />

  const tags = []
  tagIDs.forEach((tagID) => {
    for (let i = 0; i < allTagsRef.length; i += 1) {
      if (tagID === allTagsRef[i].id) {
        tags.push(allTagsRef[i])
        break
      }
    }
  })
  tags.sort(arraySort.byName)

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {tags.map((tag, index) => (
        <TagBadgeRow tag={tag} index={index} key={index} />
      ))}
    </div>
  )
}
export default TagBadges
