// utils
import React from 'react'
import { connect } from 'react-redux'
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from 'react-router-dom'
// redux
import { bindActionCreators } from 'redux'

import { presence } from '../assets/utils'
import Navbar from '../components/Navbars/CustomerNavbar'
import { UserRedirected } from '../redux/actions'
import CustomerFeedbackList from '../views/customers/CustomerFeedbackList'
import CustomerIssuesList from '../views/customers/CustomerIssuesList'
import Nexus from '../views/customers/Nexus'

let ps

class CustomerLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarMini: false,
      searchQuery: '',
      view: 'issues',
      othersPresent: [],
    }

    if (this.props.feedback) { this.state.view = 'feedback' }

    if (this.props.nexus) { this.state.view = 'nexus/endpoints' }

    this.searchText = this.searchText.bind(this)
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on'
      document.documentElement.classList.remove('perfect-scrollbar-off')
      // ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    this.getPresence()
    setInterval(() => this.getPresence(), 60000)
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
      document.documentElement.className += ' perfect-scrollbar-off'
      document.documentElement.classList.remove('perfect-scrollbar-on')
    }
  }

  componentDidUpdate(e) {
    if (e.view !== this.props.view) {
      this.setState({ view: this.props.view })
    }

    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
  }

  getPresence() {
    let page = 'customerFeedbackList'
    if (this.state.view === 'issues') { page = 'customerIssuesList' }

    presence.getOthersPresent(this.props.user.personID, page).then((result) => {
      this.setState({ othersPresent: [] }, () => {
        this.setState({ othersPresent: result.people, totalOthers: result.totalOthers })
      })
    })
  }

  handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false })
    } else {
      this.setState({ sidebarMini: true })
    }
    document.body.classList.toggle('sidebar-mini')
  }

  searchText(query) {
    this.setState({ searchQuery: query })
  }

  feedback() {
    if (this.state.view === 'feedback') {
      return <CustomerFeedbackList onSearch={this.searchText} searchQuery={this.state.searchQuery} />
    }
  }

  issues() {
    if (this.state.view === 'issues') {
      return (
        <CustomerIssuesList
          issueID={this.props.match.params.issueID}
          onSearch={this.searchText}
          searchQuery={this.state.searchQuery}
        />
      )
    }
  }

  nexus() {
    if (this.state.view === 'nexus/endpoints') {
      return (
        <Nexus
          onSearch={this.searchText}
          searchQuery={this.state.searchQuery}
        />
      )
    }
  }

  render() {
    if (!this.props.user.authenticated) {
      this.props.onUserRedirected(`/${this.state.view}`)
      return <Redirect to="/login" />
    }

    return (
      <div className="wrapper" style={{ backgroundColor: '#efefef' }}>
        <Navbar
          {...this.props}
          handleMiniClick={this.handleMiniClick}
          othersPresent={this.state.othersPresent}
          totalOthers={this.state.totalOthers}
          onSearch={this.searchText}
          view={this.state.view}
          searchQuery={this.state.searchQuery}
          onFeedbackViewClick={() => this.setState({ view: 'feedback', searchQuery: '' })}
          onIssuesViewClick={() => this.setState({ view: 'issues', searchQuery: '' })}
          onLandingPageViewClick={() => this.setState({ view: 'landing' })}
        />
        <div style={{ marginTop: '63px' }}>
          {this.feedback()}
          {this.issues()}
          {this.nexus()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUserRedirected: UserRedirected,
  }, dispatch)
}

CustomerLayout = connect(mapStateToProps, mapDispatchToProps)(CustomerLayout)
export default CustomerLayout
