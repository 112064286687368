import React from 'react'
import {
  Card, CardBody, Col, Input, Row,
} from 'reactstrap'

import MilestoneCard from '../../MilestoneCard/MilestoneCard'

function MilestonesTab({
  projectRef,
  milestoneStatusFilter, milestoneSort,
  filterMilestones, filteredMilestones,
  onMilestoneCreated, onMilestoneUpdated, onMilestoneDeleted,
}) {
  return projectRef && (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Col xs="6" sm="3">
          Status
          <Input
            placeholder=""
            type="select"
            style={{ border: '1px solid #888888', fontSize: '11px' }}
            value={milestoneStatusFilter}
            onChange={(e) => filterMilestones('status', e.target.value)}
          >
            <option value="all">All</option>
            <option value="planned">Planned</option>
            <option value="atRisk">At Risk</option>
            <option value="overdue">Overdue</option>
            <option value="completed">Completed</option>
          </Input>
        </Col>
        <Col xs="6" sm="3">
          Sort
          <Input
            placeholder=""
            type="select"
            style={{ border: '1px solid #888888', fontSize: '11px' }}
            value={milestoneSort}
            onChange={(e) => filterMilestones('sort', e.target.value)}
          >
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </Input>
        </Col>
        <Col xs="6" sm="6" style={{ textAlign: 'right' }}>
          <MilestoneCard projectID={projectRef.id} onCreated={(m) => onMilestoneCreated(m)} />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col xs="12">
          <Card className="card-timeline card-plain">
            <CardBody>
              <ul className="timeline timeline-simple">
                {filteredMilestones.map((milestone, index) => (
                  <MilestoneCard
                    milestone={milestone}
                    key={index}
                    onDeleted={(m) => onMilestoneDeleted(m)}
                    onUpdated={(m) => onMilestoneUpdated(m)}
                  />
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default MilestonesTab
