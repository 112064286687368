import React from "react";

import { 
    Button, 
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody
} from "reactstrap";

import TeamCard from 'components/TeamCard/TeamCard.js';

import logo from "assets/img/goConnectIcon.png";
import { getTeams } from "assets/firebase/db";

class TeamSearch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            queryText: '',
            searchResults: [],
            initialSearch: false,
            isLoading: false,
            dialogTitle: 'Team Search',
            buttonText: '+ Add Team',
            teams: [],
        }

        this.onTeamChosen = this.onTeamChosen.bind(this);
        this.searchTeams = this.searchTeams.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        if(this.props.dialogTitle)
            this.state.dialogTitle = this.props.dialogTitle;
        if(this.props.buttonText)  
            this.state.buttonText = this.props.buttonText;
    }

    componentDidMount(){
        getTeams().then(teams => {
            this.setState({ teams })
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isOpen !== this.props.isOpen) {
            if(this.props.isOpen) {
                this.setState({modal: true});    
            }
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    setSearchTimer(queryText) {
        clearTimeout(this.searchTeams);
        this.setState({isLoading: true, queryText}, () => {
            setTimeout(this.searchTeams,2300);
        });
    }
    
    searchTeams() {
        this.setState({searchResults: []}, () => {
            if(this.state.queryText.length > 0) {
                const filteredTeams = this.state.teams.filter(t => t.name.toLowerCase().includes(this.state.queryText.toLowerCase()))
                this.setState({searchResults: filteredTeams, initialSearch: true, isLoading: false}); 
            }
        });
    }

    onTeamChosen(team) {
        this.setState({searchResults:[], queryText: '', modal:false}, () => {
            this.props.onTeamChosen(team);
        });
    }

    showAfterSearch() {
        if(this.state.initialSearch)
            return "flex";
        else
            return "none";
    }

    showWhenResults() {
        if(this.state.searchResults.length > 0)
            return "flex";
        else
            return "none";
    }
    showWhenNoResults() {
        if(this.state.initialSearch && this.state.searchResults.length === 0)
            return "flex";
        else
            return "none";
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }

        return (
            <div>
                <Button size="sm" color="secondary" onClick={this.toggleModal}  >  
                    {this.state.buttonText}
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" autoFocus={false} >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm={12}>
                                <br />{this.state.dialogTitle}<br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-zoom-split" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input autoFocus={true} placeholder="search by name" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div style={loadingDiv}>
                                    <div className="spin">
                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{display:this.showWhenResults()}}>
                            <Col sm={12}>
                                <br />
                                Is your team already here?
                            </Col>
                            {this.state.searchResults.map((team, index) => (
                                <Col sm={3} key={index}>
                                    <TeamCard team={team} onSelected={this.onTeamChosen} hideExpander />
                                </Col>
                            ))}
                        </Row>
                        <Row style={{width:"100%", display:this.showWhenResults()}}>
                            <Col sm={12} >
                                <br />
                                Note: currently new/missing teams must be added by editing their parent team.
                            </Col>
                        </Row>
                        {/*}
                        <Row style={{display:this.showAfterSearch()}}>
                            <Col sm="12" >
                            <div style={{display:"inline-block"}}>
                                <TeamEditor onCreated={this.onTeamChosen} />
                            </div>
                            </Col>
                        </Row>*/}
                    </ModalBody>
                </Modal>   
            </div>
        )
    }
}

export default TeamSearch;