import './SelectedRequestDetails.css'

import React from 'react'

import Comment from '../Comment/Comment'
import NexusRequestData from './NexusRequestData'

export default function SelectedRequestDetails({
  request,
}) {
  return (
    <>
      <NexusRequestData request={request} />

      {/* <div style={{ marginTop: '70px' }}>
        <h3>Comments</h3>
        {request.comments.map((comment) => (
          <Comment key={comment.id} />
        ))}
      </div> */}
    </>
  )
}
