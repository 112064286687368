import React from "react"
import { Table } from "reactstrap"

export default function SecuritySLA() {
  return (
    <>
      <div>
        <p><strong>SLA Time to Response</strong> refers to the time it takes for Engineering to respond to the Security Jira ticket, via responding in the ticket comment or acknowledging the ticket Status.</p>
        <p><strong>SLA Time to Resolution</strong> refers to the time it takes for Engineering to mitigate the Security issue after the ticket has been logged & escalated via Jira to the correct PDG. Mitigation can include a remediation plan to fix the bugs later than the SLA, or for Low priority items an exception plan to accept the risk and not fix the issue with a formal sign off from the HoE/HoX.</p>
      </div>
      <Table bordered style={{ padding: '10px'}}>
        <thead>
          <tr style={{fontSize: '12px', fontWeight: 'bold'}}>
            <th>Severity</th>
            <th>Time to Resolution</th>
            <th>Time to Respond</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody style={{fontSize: '14px', textAlign: 'left'}}>
          <tr>
            <td>Critical (P0)</td>
            <td>3 days</td>
            <td>24 hours</td>
            <td>
              <ul>
                <li>A Critical vulnerability, is a vulnerability that if exploited:</li>
                <ul>
                  <li>May cause privilege escalation to a super user privilege.</li>
                  <li>May cause breach and exfiltration of confidential and/or PII information.</li>
                  <li>May cause system takeover.</li>
                  <li>May enable remote code execution (RCE).</li>
                </ul>
                <li>A Vulnerability that</li>
                <ul>
                  <li>Affects all PUBLIC facing services and/or instances in production or non-production.</li>
                  <li>Requires minimum expertise to exploit.</li>
                  <li>Has easy to access exploitation method and/or tools.</li>
                  <li>Has a very high CVSS rating.</li>
                </ul>
                <li>A vulnerability that, after a risk assessment process, is considered as a critical vulnerability.</li>
                <li>
                  Examples include:
                  <ul>
                    <li>The zero-day vulnerability that affects the Apache Log4j utility (log4shell) on a public-facing endpoint.</li>
                    <li>The zero-day vulnerability that affects the Spring framework (spring4shell) on a public-facing endpoint.</li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>High (P1)</td>
            <td>12 days</td>
            <td>3 days</td>
            <td>
              <ul>
                <li>A High vulnerability, is a vulnerability that if exploited:</li>
                <ul>
                  <li>May cause privilege escalation to a super user privilege.</li>
                  <li>May cause breach and exfiltration of confidential and/or PII information.</li>
                  <li>May cause system takeover.</li>
                  <li>May enable remote code execution (RCE).</li>
                </ul>
                <li>A Vulnerability that</li>
                <ul>
                  <li>Affects NON-PUBLIC facing services and/or instances in production or non-production.</li>
                  <li>Requires minimum or some expertise to exploit;Requires minimum or some expertise to exploit.</li>
                  <li>Has easy to access exploitation method and/or tools.</li>
                  <li>Has a very high or high CVSS rating.</li>
                </ul>
                <li>A vulnerability that, after a risk assessment process, is considered as a high vulnerability.</li>
                <li>Examples include:</li>
                <ul>
                  <li>The zero-day vulnerability affects the Apache Log4j utility (log4shell) on a NON-Public-facing endpoint.</li>
                  <li>The zero-day vulnerability that affects the Spring framework (spring4shell) on a NON-Public facing endpoint.</li>
                </ul>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Medium (P2)</td>
            <td>30 days</td>
            <td>5 days </td>
            <td>
              <ul>
                <li>A Medium vulnerability, is a vulnerability that if exploited:</li>
                <ul>
                  <li>May cause disruptions to the systems.</li>
                  <li>May cause limited read write  unauthorized access to non-sensitive information.</li>
                </ul>
                <li>A Vulnerability that</li>
                <ul>
                  <li>Affects NON-PUBLIC facing services and/or instances in production or non-production.</li>
                  <li>Requires considerable expertise to exploit.</li>
                  <li>Has exploitation method and/or tools that are difficult to find.</li>
                  <li>Has a medium CVSS rating.</li>
                </ul>
                <li>A vulnerability that, after a risk assessment process, is considered a medium vulnerability.</li>
                <li>Examples include:</li>
                <ul>
                  <li>MySQL Stored SQL Injection (CVE-2013-0375) that enables remote authenticated users to affect confidentiality and integrity via unknown vectors related to Server Replication.</li>
                  <li>phpMyAdmin Reflected Cross-site Scripting Vulnerability (CVE-2013-1937).</li>
                </ul>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Low(P3)</td>
            <td>90 Days</td>
            <td>14 days</td>
            <td>
              <ul>
                <li>A Low vulnerability, is a vulnerability that if exploited:</li>
                <ul>
                  <li>May cause limited disruptions to the systems</li>
                  <li>May cause limited read write access to non-sensitive information</li>
                </ul>
                <li>A Vulnerability that</li>
                <ul>
                  <li>Affects NON-PUBLIC facing services and/or instances in non-production.</li>
                  <li>Requires considerable expertise to exploit.</li>
                  <li>Has exploitation method and/or tools that are difficult to find.</li>
                  <li>Has a low / informational CVSS rating.</li>
                </ul>
                <li>A vulnerability that, after a risk assessment process, is considered a low vulnerability.</li>
                <li>Examples include:</li>
                <ul>
                  <li>SSLv3 POODLE Vulnerability (CVE-2014-3566) a vulnerability which makes it easier for man-in-the-middle attackers to obtain cleartext data via a padding-oracle attack, aka the "POODLE" issue.</li>
                </ul>
              </ul>
            </td>
          </tr>
        </tbody> 
      </Table>
    </>
  )
}