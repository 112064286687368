import moment from 'moment'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'

import { pastSixWeeks } from './utils'

const options = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    intersect: false,
    callbacks: {
      title(tooltipItem, data) {
        const label = data.labels[tooltipItem[0].index]
        const isoWeek = moment(label).isoWeek()
        return `Week ${isoWeek} : ${label}`
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: '#9f9f9f',
          beginAtZero: false,
          maxTicksLimit: 5,
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: 'transparent',
          color: 'rgba(255,255,255,0.05)',
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          zeroLineColor: 'transparent',
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: '#9f9f9f',
        },
      },
    ],
  },
}

export default function CreatedVsResolvedIssues({ weeklyCreatedIssues, weeklyResolvedIssues }) {
  const chartData = {
    labels: pastSixWeeks,
    datasets: [
      {
        label: 'Created Issues',
        data: weeklyCreatedIssues,
        borderColor: 'rgb(234,82,82)',
        fill: false,
        pointHoverRadius: 0,
        pointRadius: 0,
        backgroundColor: 'rgb(234,82,82)',
        borderWidth: 3,
        barPercentage: 1.6,
      },
      {
        label: 'Resolved Issues',
        data: weeklyResolvedIssues,
        borderColor: 'green',
        fill: false,
        pointHoverRadius: 0,
        pointRadius: 0,
        backgroundColor: 'green',
        borderWidth: 3,
        barPercentage: 1.6,
      },
    ],
  }

  return (
    <Card>
      <CardBody>
        <h6 className="big-title">Created Vs Resolved Issues</h6>
        <Line options={options} data={chartData} />
      </CardBody>
    </Card>
  )
}
