import {
  connectFunctionsEmulator,
  getFunctions, httpsCallable,
} from 'firebase/functions'

export default function callableFunction(functionName) {
  const timeOut = 1000 * 60 * 10
  const functions = getFunctions()
  if (process.env.NODE_ENV === 'development') connectFunctionsEmulator(functions, 'localhost', 5001)
  return httpsCallable(functions, functionName, { timeout: timeOut })
}
