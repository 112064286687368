import React from "react";

import { connect } from 'react-redux';

import { algolia } from "assets/algolia";

import { db } from '../../assets/firebase';

import { strings, tables, telemetry, arraySort } from 'assets/utils';

// reactstrap components
import {
    Badge,
    Card, CardHeader, CardBody, CardTitle, CardFooter,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table,
    Row, Col
  } from "reactstrap";

import { Line } from "react-chartjs-2";

//goConnect Components
import ReviewCard from 'components/Review/Review.js';
import IssueSearch from "components/IssueSearch/IssueSearch.js"; 
import ManagerApproval from "components/ManagerApproval/ManagerApproval"; 
import PersonCard from "components/PersonCard/PersonCard.js";

import logo from "assets/img/goConnectIcon.png";
import { isEmpty } from '../../assets/utils/strings'

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value*1000);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return addZero(dt.getDate()) + '/' + addZero(dt.getMonth()+1) + '/' + dt.getFullYear() + ' ' + time;
  }

  let chartColor = "#FFFFFF";

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  function RGBToHex(r,g,b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
  
    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
  
    return "#" + r + g + b;
  }

  class CustomerFeedbackList extends React.Component {
      
    constructor(props) {
      super(props);
  
      this.state = {
        filtered: [],
        reviews: [],
        ratingSummary: { labels: [], data: [] },
        turnaroundSummary: { labels: [], data: [] },
        responseSummary: { labels: [], data: [] },
        reviewSummary: { labels: [], data: [] },
        issueLabelsSummary: { labels: [], data: [] },
        productLabelsSummary: { labels: [], data: [] },
        productLabels: [],
        respondents: [],
        rating: "All",
        status: "All",
        preferredLanguage:"All",
        market: "All",
        platform: "Android",
        packageFilter: 'com.gojek.app',
        sortBy: "date",
        queryText: "",
        isLoading: true,
        showSelectedOptions: false,
        totalReRatedReviews: 0,
        totalReRatingDifferential: 0,
        totalTurnarounds: 0,
        turnaroundImpact: 0,
        percentReviewsResponded: 0,
        showManagerApprovalCard: false,
        showManagerApprovalInProcess: false,
        teams: {},
      }

      var today = new Date();
      var yesterday = new Date(today.getTime() - (24*60*60*1000)); 
      this.state.dateFilter = yesterday.toISOString().substr(0,10);
    }

    componentDidMount() {
        
        this.getAppReviews();
        this.getReviewSummaries();

        //this.getIssueLinkTrends();
        // this.summarizeAllReviews(); // don't do this, it's crazy slow! 

        this.getUserPermissions();

        this.getTeamsForUser().then(teams => {
            this.getTeamMembers(teams).then(teamMembers => {
                this.setState({
                    teams: teamMembers,
                }, () => {
                    this.getTeamActivity()
                })
            })
        });

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "customerFeedbackList", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({queryText: this.props.searchQuery}, () => {
              if(this.state.queryText.length > 0) {
                  this.searchFeedback();
              } else {
                  this.filterReviews();
              }
            });
        }
    }

    summarizeAllReviews() {

        db.getReviewsAll('com.gojek.app').then(reviews => {
    
            console.log('reviews: ' + reviews.length);

            var totalReviews = 0;
            var totalResponded = 0;
            var totalReRated = 0;
            var sumOriginalRatings = 0;
            var sumReRatings = 0;
            var sumRatingDifferential = 0;
            var totalTurnarounds = 0;
            
            reviews.forEach((review) => {

                // counting total reviews 
                totalReviews = totalReviews + 1;

                // go through comments to find out if we have responded and if user has rerated
                var comments = review.review.comments;
                var userComments = [];
                var developerComments = [];
                for(var i = 0; i < comments.length; i++) {
                    if(comments[i].userComment) userComments.push(comments[i].userComment);
                    if(comments[i].developerComment) developerComments.push(comments[i].developerComment);
                }


                // if the user re-rated at any point
                if(userComments[0].starRating !== userComments[userComments.length -1].starRating) { 

                    totalReRated = totalReRated + 1;

                    // aggregating the ratings for the day so we can get an average later
                    const originalRating = userComments[0].starRating;
                    sumOriginalRatings = sumOriginalRatings + originalRating;

                    const newRating = userComments[userComments.length -1].starRating;

                    // aggregate total for re-rated reviews 
                    sumReRatings = sumReRatings + newRating;

                    var differential = Number(newRating - originalRating);
                    sumRatingDifferential = Number(sumRatingDifferential) + differential;
                    
                    if(originalRating === 1 && newRating === 5)
                        totalTurnarounds = totalTurnarounds + 1;

                }

                // if we responded at all
                if(developerComments.length > 0)
                    totalResponded = totalResponded + 1;

            });

            const avgScoreBeforeReRating = Number(sumOriginalRatings / totalReRated).toFixed(3);
            const avgScoreAfterReRating = Number(sumReRatings / totalReRated).toFixed(3);
            const reRatingDifferential = Number(sumRatingDifferential / totalReRated).toFixed(3);

            const summary = { 
                totalReviews: totalReviews,
                totalReRated: totalReRated,
                totalResponded: totalResponded,
                avgScoreBeforeReRating: Number(avgScoreBeforeReRating),
                avgScoreAfterReRating: Number(avgScoreAfterReRating),
                reRatingDifferential: Number(reRatingDifferential),
                totalTurnarounds: Number(totalTurnarounds)
            };

            console.log(summary);
        });
    }

    getUserPermissions() {
        db.getPerson(this.props.user.personID).then(person => {

            if (!person.managerApprovalAppReviews) {
                this.setState({showManagerApprovalCard: true});
            }
            else if(person.managerApprovalAppReviews === 'requested' ) {
                this.setState({showManagerApprovalCard: true, showManagerApprovalInProcess: true});    
            } 
        })
    }

    getReviewSummaries() {

        var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var productLabels = [];
        var issueLabels = [];
        
        db.getReviewSummaries(10).then(summaries => {
            
            var ratingData = [];
            var turnaroundsData = [];
            var reviewData = [];
            var responseData = [];
            
            var labels = [];
            var totalReRatedReviews = 0;
            var sumReRatingDifferentials = 0;
            var differentialDenominator = 0;
            var totalTurnarounds = 0;

            var totalReviews = 0;
            var totalResponses = 0;

            var promises = [];
            var respondents = [];

            summaries.forEach(summary => {

                labels.push(days[new Date(summary.summaryDate).getDay()]);

                ratingData.push( summary.avgScoreAfterReRating );
                turnaroundsData.push(summary.totalTurnarounds);
                reviewData.push(summary.totalReviews);
                responseData.push(summary.totalResponded);

                // summarise products
                summary.productLabels.forEach(row => {
                    var isFound = false;
                    for(var i = 0; i < productLabels.length; i++) {
                        if(productLabels[i].label === row.label) {
                            productLabels.count = productLabels.count + row.count;
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound && row.label !== 'not an issue') {
                        productLabels.push({
                            label: row.label,
                            count: row.count
                        });                        
                    }
                });
                // summarise issues
                summary.issueLabels.forEach(row => {
                    var isFound = false;
                    for(var i = 0; i < issueLabels.length; i++) {
                        if(issueLabels[i].label === row.label) {
                            issueLabels.count = issueLabels.count + row.count;
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound && row.label !== 'not an issue') {
                        issueLabels.push({
                            label: row.label,
                            count: row.count
                        });                        
                    }
                });

                //aggregate respondents
                summary.respondents.forEach(respondent => {

                    var isRespondentFound = false;
                    for(var r = 0; r < respondents.length; r++) {
                        if(respondents[r].id === respondent.id) {
                            isRespondentFound = true;
                            respondents[r].count = respondents[r].count + respondent.count;
                            break;
                        }
                    }
                    if(!isRespondentFound) {
                        respondents.push( {
                            id: respondent.id,
                            count: respondent.count
                        })
                        promises.push(db.getPerson(respondent.id));
                    }
                })

                totalReviews = totalReviews + summary.totalReviews;
                totalResponses = totalResponses + summary.totalResponded;

                totalReRatedReviews = totalReRatedReviews + summary.totalReRated;
                totalTurnarounds = totalTurnarounds + Number(summary.totalTurnarounds);
                summary.reRatingDifferential = Number(summary.reRatingDifferential);

                if(!isNaN(summary.reRatingDifferential)) {
                    differentialDenominator = differentialDenominator + 1;
                    sumReRatingDifferentials = sumReRatingDifferentials + summary.reRatingDifferential;
                }
            })

            var avgChange = (sumReRatingDifferentials / differentialDenominator).toFixed(3);
            if(avgChange > 0) 
                avgChange = 'avg +' + avgChange + ' / review';
            else 
                avgChange = 'avg -' + avgChange + ' / review';

            //summarise product labels
            productLabels.sort( (a,b) => { return b.count - a.count } );
            var productLabelsSummary = { datasets: [ { data: [], backgroundColor: [] } ], labels: [] };
            productLabels.forEach(row => {
                productLabelsSummary.labels.push(row.label);
                productLabelsSummary.datasets[0].data.push(row.count);
                //(0, 222-(8*(productLabelsSummary.labels.length+1)), 0));
                productLabelsSummary.datasets[0].backgroundColor.push(RGBToHex(0,222-(8*(productLabelsSummary.labels.length+1)), 0));
            });

            //summarise issue labels
            issueLabels.sort( (a,b) => { return b.count - a.count } );
            var issueLabelsSummary = { datasets: [ { data: [], backgroundColor: [] } ], labels: [] };
            issueLabels.forEach(row => {
                issueLabelsSummary.labels.push(row.label);
                issueLabelsSummary.datasets[0].data.push(row.count);
                issueLabelsSummary.datasets[0].backgroundColor.push(RGBToHex(222-(8*(issueLabelsSummary.labels.length+1)),0, 0));
            })


            this.setState({
                ratingSummary: { data: ratingData, labels: labels }, 
                reviewSummary: { data: reviewData, labels: labels }, 
                responseSummary: { data: responseData, labels: labels },
                turnaroundSummary: { data: turnaroundsData, labels: labels },
                issueLabelsSummary: issueLabelsSummary,
                productLabelsSummary: productLabelsSummary,
                totalReRatedReviews: totalReRatedReviews,
                totalTurnarounds: totalTurnarounds,
                turnaroundImpact: "worth " + (totalTurnarounds * 7) + " 5-star reviews",
                reRatingDifferential: avgChange,
                percentReviewsResponded: ((totalResponses / totalReviews) * 100).toFixed(1) + "%"
            });

            Promise.all(promises).then(people => {

                for(var x = people.length - 1; x> -1; x--) 
                    if(!people[x])
                        people.splice(x,1);
                
                people.forEach(person => {
                    if(person) {   
                        for(var x = 0; x < respondents.length; x++) {
                            if(person.id === respondents[x].id) {
                                person.responses = respondents[x].count;
                                break;
                            }
                        }
                    }
                })
                people.sort( (a,b) => { return b.responses - a.responses } );
                this.setState({respondents: people});
            });
        });
    }

    getTeamsForUser() {

        return new Promise((resolve, reject) => {
            db.getTeamsByPerson(this.props.user.personID).then(xrefs => {
                const promises = [];
                xrefs.forEach(xref => {
                    promises.push(db.getTeam(xref.teamID));
                });

                if(this.props.teamID) {
                    if(!xrefs.includes(this.props.teamID)) {
                        promises.push(db.getTeam(this.props.teamID));
                    }
                }
    
                Promise.all(promises).then(teams => {
                    teams.sort(arraySort.byName);
                    resolve(teams);
                });
            });
        });
    }

    getTeamMembers(teams) {

        return new Promise((resolve, reject) => {
            let promises = [];

            teams.forEach(team => promises.push(db.getTeamMembers(team.id)) );

            Promise.all(promises).then(results => {
                
                promises = [];
                const peopleIDs = {};
                
                results.forEach(result => {
                    result.forEach(row => {
                        if(!(row.personID in peopleIDs)) {
                            peopleIDs[row.personID] = true;
                        }
                    }); 
                })

                Object.keys(peopleIDs).forEach(personID => {
                    promises.push(db.getPerson(personID));
                });

                Promise.all(promises).then(people => {

                    for(let x = people.length-1; x > -1; x--){
                        if(!people[x]) 
                        people.splice(x,1);
                    }

                    results.forEach(result => {
                        
                        if(result.length > 0) { // handing the scenarios where there are no team members
                            for(let j = 0; j < teams.length; j++) {
                                if(result[0].teamID === teams[j].id) {

                                    teams[j].people = [];
                                    for(let r = 0; r < result.length; r++) {
                                        for(let k = 0; k < people.length; k++) {
                                            if(people[k].id === result[r].personID) {
                                                teams[j].people.push(people[k]);
                                                break;
                                            }
                                        }
                                    }

                                    teams[j].people.sort(arraySort.byName);
                                    break;
                                }
                            }
                        }
                    })
                    resolve(teams);
                });
            })
        });
    }
    
    getTeamActivity() {

        db.getReviewSummaries(30).then(summaries => {
            const respondents = {};
            summaries.forEach(summary => {
                summary.respondents.forEach(respondent => {
                    if(respondent.id in respondents) {
                        respondents[respondent.id] += respondent.count;
                    } else {
                        respondents[respondent.id] = respondent.count;
                    }
                })
            })
                
            const teams = [...this.state.teams];
            teams.forEach(team => {
                if(team.people.length) {   
                    for(var x = 0; x < team.people.length; x++) {
                        if(team.people[x].id in respondents) {
                            team.people[x].responses = respondents[team.people[x].id].count;
                            break;
                        } else {
                            team.people[x].responses = 0;
                        }
                    }
                }
                team.people.sort(arraySort.byName);
                team.people.sort( (a,b) => { return b.responses - a.responses });
            })

            this.setState({teams});
        });
    }

    getAppReviews(property, value) {
        
        // default
        var dateFilter = this.state.dateFilter;
        if(property === 'date') 
            dateFilter = value;

        var appPackage = this.state.packageFilter;
        if(property === 'package')
            appPackage = value;

        var platform = this.state.platform;
        if(property === 'platform')
            platform = value;

            this.setState({dateFilter: dateFilter,  platform: platform, packageFilter: appPackage, isLoading: true});
            
            if(platform === 'iOS') {
                if(appPackage === 'com.gojek.app')
                appPackage = 'ios.gojek.com';
                else if(appPackage === 'com.gojek.gopay')
                appPackage = 'com.go-jek.gopay';
                else if(appPackage === 'com.gojek.gopaymerchant')
                    appPackage = 'com.go-jek.gopaymerchant.app';
                else 
                appPackage = 'n/a';
            }

            db.getReviews(dateFilter, appPackage).then(reviews => {

            //ios.gojek.com
            //reviews.sort( (a,b) => { return b.review.comments[0].userComment.lastModified.seconds - a.review.comments[0].userComment.lastModified.seconds } );
            reviews.sort( (a,b) => { return b.lastComment - a.lastComment } );

            var promises = [];
            reviews.forEach(review => {
                if(!review.productLabel) review.productLabel = "";
                if(!review.issueLabel) review.issueLabel = "";


                if (platform === 'Android') {
                    review.appVersion = review.review.comments[0].userComment.appVersionName;
                    review.language = review.review.comments[0].userComment.reviewerLanguage;
                    review.market = '';
                    review.rating = review.review.comments[0].userComment.starRating;
                    review.votes = 0; if(review.review.comments[0].userComment.thumbsUpCount) review.votes = Number(review.review.comments[0].userComment.thumbsUpCount);
                }
                else if(platform === 'iOS') {
                    review.appVersion = review.review.version;
                    review.language = '';
                    review.rating = Number(review.review.rating);
                    review.t = review.review.description;
                    review.responded = false;
                    review.votes = Number(review.review.votes);

                    review.comments = [ {
                        userComment : {
                            text: review.review.descrption,
                            starRating: review.rating
                        }
                    }];
                }  

                promises.push(db.getReviewIssues(review.id));
            })

            Promise.all(promises).then(reviewIssues => {
                
                reviewIssues.forEach(ri => {
                    for(var i = 0; i < reviews.length; i++) {        
                        if(ri.length > 0) {   
                            if(reviews[i].id === ri[0].reviewID) {
                                reviews[i].isLinked = true;
                            }
                        }
                    }
                })

                this.setState({reviews:reviews}, () => this.filterReviews());
            });
        });
    }
    
    filterReviews(property, value) {

        var preferredLanguage = this.state.preferredLanguage;
        if(property === 'preferredLanguage')
            preferredLanguage = value;

        var status = this.state.status;
        if(property === 'status')
            status = value;

        var rating = this.state.rating;
        if(property === 'rating')
            rating = value;

        var market = this.state.market;
        if(property === 'market')
            market = value;

        this.setState({filtered: [], preferredLanguage: preferredLanguage, rating: rating, market: market, status: status, isLoading: true}, () => {

            var filtered = [];
            for(var i = 0; i < this.state.reviews.length; i++) {
    
                var review = this.state.reviews[i];
    

                if(rating === 'All' || 
                    (rating === 'low' && (review.rating === 1 || review.rating === 2)) ||
                    (rating === 'medium' && review.rating === 3) ||
                    (rating === 'high' && (review.rating === 4 || review.rating === 5))
                    ) {

                        if(this.state.platform === 'iOS') {

                            if(market === 'All' || review.market === market) {

                                if(status === 'No Response' || status === 'All') {
                                    filtered.push(review);
                                }
                            }

                        } else {

                            if(review.review.comments) {
                                
                                const lastComment = review.review.comments[review.review.comments.length - 1];
                                if(
                                    (lastComment.userComment && status === 'No Response') ||
                                    (lastComment.developerComment && status === 'Responded') ||
                                    status === 'All'
                                ) {
                                    
                                    if(lastComment.userComment) 
                                        review.responded = false;
                                    else 
                                        review.responded = true;

                                    if(lastComment.userComment)
                                        review.lastComment = lastComment.userComment.lastModified.seconds;
                                    else if (lastComment.developerComment)
                                        review.lastComment = lastComment.developerComment.lastModified.seconds;
                        

                                    if(
                                        (review.language === 'en' && preferredLanguage === 'English') ||
                                        (review.language === 'id' && preferredLanguage === 'Indonesian') ||
                                        (review.language === 'vi' && preferredLanguage === 'Vietnamese') ||
                                        (review.language === 'th' && preferredLanguage === 'Thai') || 
                                        preferredLanguage === 'All'
                                    ) {

                                        if(
                                            ((review.language === 'id' && preferredLanguage === 'Indonesian') ||
                                            (review.language === 'vi' && preferredLanguage === 'Vietnamese') ||
                                            (review.language === 'th' && preferredLanguage === 'Thai')) &&
                                            review.review.comments[0].userComment.originalText
                                        )
                                            review.t = review.review.comments[0].userComment.originalText;
                                        else 
                                            review.t = review.review.comments[0].userComment.text;


                                        //if(!review.review.comments[0].userComment.thumbsDownCount) review.review.comments[0].userComment.thumbsDownCount = 0;
                                        //if(!review.review.comments[0].userComment.thumbsUpCount) review.review.comments[0].userComment.thumbsUpCount = 0;

                                        filtered.push(review);
                                    }
                                    
                                
                                }
                            }
                        }
                    }
            }
    
            this.setState({filtered: filtered, isLoading: false});
        });
       
    }

    sortReviews(sortBy) {
        var reviews = this.state.filtered;

        if(sortBy === this.state.sortBy) { // reverse
            
            var reversed = [];
            for(var i = reviews.length - 1; i > -1; i--) 
                reversed.push(reviews[i]);
            reviews = reversed;
        } else {

            if(sortBy === 'modified')
                reviews.sort(this.byModified);
            else if (sortBy === 'rating')
                reviews.sort((a,b) => { return b.rating - a.rating });
            else if (sortBy === 'thumbsUp')
                reviews.sort((a,b) => { return b.votes - a.votes });
            else if (sortBy === 'language')
                reviews.sort(this.byLanguage);
            else if (sortBy === 'platform')
                reviews.sort(this.byPlatform);
            else if (sortBy === 'market')
                reviews.sort(this.byMarket);
        }

        this.setState({filtered: reviews, sortBy: sortBy});
    }

    byModified(a,b) {

        var x = 0;
        for(var i = a.review.comments.length -1; i > -1; i--)
            if(a.review.comments[i].userComment) {
                x = a.review.comments[i].userComment.lastModified.seconds;
                break;
            }
        
        var y = 0;
        for(var j = b.review.comments.length -1; j > -1; j--)
            if(b.review.comments[j].userComment) {
                y = b.review.comments[j].userComment.lastModified.seconds;
                break;
            }
            
        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }

    byLanguage(a,b) {

        var x = a.language;
        var y = b.language;

        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }

    byPlatform(a,b) {

        var x = a.platform;
        var y = b.platform;

        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }

    byMarket(a,b) {

        var x = a.market;
        var y = b.market;

        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }

    searchFeedback() {

        this.setState({isLoading: true}, () => {
  
            algolia.searchFeedback(this.state.queryText).then(searchResults => {

                var reviews = [];
                searchResults.forEach(review => {

                    if(!review.productLabel) review.productLabel = "";
                    if(!review.issueLabel) review.issueLabel = "";


                    if (review.platform === 'android') {
                        review.language = review.review.comments[0].userComment.reviewerLanguage;
                        review.rating = review.review.comments[0].userComment.starRating;
                        review.votes = 0; if(review.review.comments[0].userComment.thumbsUpCount) review.votes = Number(review.review.comments[0].userComment.thumbsUpCount);

                        const lastComment = review.review.comments[review.review.comments.length - 1];
                        if(lastComment.userComment) 
                            review.responded = false
                        else 
                            review.responded = true;

                        if(lastComment.userComment)
                            review.lastComment = lastComment.userComment.lastModified.seconds;
                        else if (lastComment.developerComment)
                            review.lastComment = lastComment.developerComment.lastModified.seconds;

                        if(
                            ((review.review.comments[0].userComment.reviewerLanguage === 'id' && preferredLanguage === 'Indonesian') ||
                            (review.review.comments[0].userComment.reviewerLanguage === 'vi' && preferredLanguage === 'Vietnamese') ||
                            (review.review.comments[0].userComment.reviewerLanguage === 'th' && preferredLanguage === 'Thai')) &&
                            review.review.comments[0].userComment.originalText
                        )
                            review.t = review.review.comments[0].userComment.originalText;
                        else 
                            review.t = review.review.comments[0].userComment.text;
        
                        //if(!review.review.comments[0].userComment.thumbsDownCount) review.review.comments[0].userComment.thumbsDownCount = 0;
                        //if(!review.review.comments[0].userComment.thumbsUpCount) review.review.comments[0].userComment.thumbsUpCount = 0;

                    }
                    else if(review.platform === 'iOS') {
                        review.language = '';
                        review.rating = Number(review.review.rating);
                        review.t = review.review.description;
                        review.responded = false;
                        review.votes = Number(review.review.votes);
                    }  

                    reviews.push(review);
                    //if(result.platform === 'android') reviews.push(result);
                })

                
                var preferredLanguage = this.state.preferredLanguage;
                var sortBy = this.state.sortBy;

                if(sortBy === 'modified')
                    reviews.sort(this.byModified);
                else if (sortBy === 'rating')
                    reviews.sort((a,b) => { return b.rating - a.rating });
                else if (sortBy === 'thumbsUp')
                    reviews.sort((a,b) => { return b.votes - a.votes });
                else if (sortBy === 'language')
                    reviews.sort(this.byLanguage);

                
                var promises = [];
                reviews.forEach(review => {
                    promises.push(db.getReviewIssues(review.id));
                })
                
                Promise.all(promises).then(reviewIssues => {
                
                    reviewIssues.forEach(ri => {
                        for(var i = 0; i < reviews.length; i++) {        
                            if(ri.length > 0) {   
                                if(reviews[i].id === ri[0].reviewID) {
                                    reviews[i].isLinked = true;
                                }
                            }
                        }
                    })
    
                    this.setState({filtered: reviews, isLoading: false});
                });
            })
        })
    }

    toggleSelect(review) {

        var rowSelected = false;
        var reviews = this.state.filtered;

        for(var i = 0; i < reviews.length; i++) {

            if(reviews[i].id === review.id) {
                if(!reviews[i].isSelected)
                    reviews[i].isSelected = true;
                else 
                    reviews[i].isSelected = !reviews[i].isSelected;
            }

            if(reviews[i].isSelected)
                rowSelected = true;
        }

        this.setState({filtered: reviews, rowSelected: rowSelected});
    }

    issueChosen(issue) {

        var reviews = this.state.filtered;
        for(var i = 0; i < reviews.length; i++) {
            if(reviews[i].isSelected) {
                
                var issueReview = { issueID: issue.id, reviewID: reviews[i].id, 
                    created: new Date().getTime(), createdBy: this.props.user.person.name };
                db.updateIssueReviews(issueReview.id, issueReview);

                reviews[i].isSelected = false;
                reviews[i].isLinked = true;
            }
        }

        this.setState({filtered: reviews, rowSelected: false});
    }

    onReviewUpdated(review) {

        var preferredLanguage = this.state.preferredLanguage;

        var reviews = this.state.filtered;

        for(var i = 0; i < reviews.length; i++) {
            if(review.id === reviews[i].id) {

                if (review.platform === 'android') {

                    // setup the temporary data for the list
                    review.rating = review.review.comments[0].userComment.starRating;
                    review.votes = 0; if(review.review.comments[0].userComment.thumbsUpCount) review.votes = Number(review.review.comments[0].userComment.thumbsUpCount);
                    //if(!review.review.comments[0].userComment.thumbsDownCount) review.review.comments[0].userComment.thumbsDownCount = 0;
                    //if(!review.review.comments[0].userComment.thumbsUpCount) review.review.comments[0].userComment.thumbsUpCount = 0;
                        
                    if(
                        ((review.review.comments[0].userComment.reviewerLanguage === 'id' && preferredLanguage === 'Indonesian') ||
                        (review.review.comments[0].userComment.reviewerLanguage === 'vi' && preferredLanguage === 'Vietnamese') ||
                        (review.review.comments[0].userComment.reviewerLanguage === 'th' && preferredLanguage === 'Thai')) &&
                        review.review.comments[0].userComment.originalText
                    )
                        review.t = review.review.comments[0].userComment.originalText;
                    else {
                        review.t = review.review.comments[0].userComment.text;
                    }
                    
                    
                }

                

                reviews[i] = review;
                break;
            }
        }
        this.setState({filtered: reviews});
    }


    getStars(rating) {

        if(rating === 1)
            return <div >
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                </div>
        else if (rating === 2)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                </div>
        else if (rating === 3)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                </div>
        else if (rating === 4)
            return <div>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"gold"}}></i>
                    <i className="fas fa-star" style={{color:"#ddd"}}></i>
                </div>
        else if (rating === 5)
            return <div style={{color:"gold"}}>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
            </div>
    }

    getReviewerLanguage(language) {
        
        if(language === 'en')
            return "English";
        else if(language === 'cs')
            return "Czech";
        else if(language === 'eo')
            return "Esperanto";
        else if(language === 'fr')
            return "French";
        else if(language === 'id')
            return "Indonesian";
        else if(language === 'jv')
            return "Javanese";
        else if(language === 'ms')
            return "Malay";
        else if(language === 'th')
            return "Thai";
        else if(language === 'tr')
            return "Turkish";
        else if(language === 'vi')
            return "Vietnamese";
        else 
            return "Other / Unknown";
    }

    getMarketName(market) {
        if(market === 'id') 
            return 'Indonesia';
        else if(market === 'sg')
            return "Singapore";
        else if (market === 'vn')
            return "Vietnam";
    }

    replyAvailable(review) {
        const comments = review.review.comments;
        const lastComment = comments[comments.length - 1];
        if(lastComment.userComment)
            return true;
        else
            return false;
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading && this.state.filtered.length > 0)
            return "block";
        else 
            return "none";
    }
    showFilters() {
        if(this.state.queryText.length > 0)
            return "none";
        else
            return "flex";
    }
    showNoResults() {
        if(this.state.filtered.length === 0 && !this.state.isLoading)
            return "block";
        else
            return "none";
    }

    getIconColor(value) {
        if(value)
            return "green";
        else
            return "#dddddd";
    }
    
    showDates() {
        
        if(this.state.queryText.length === 0)
            return "none";
        else
            return "flex";
    }

    showMarket() {
        if(this.state.platform === 'iOS') 
            return "block";
        else
            return "none";
    }
    showMarketColumn() {
        if(this.state.platform === 'iOS' && this.state.queryText.length === 0) 
            return "table-cell";
        else
            return "none";
    }

    showLanguage() {
        if(this.state.platform === 'Android') 
            return "block";
        else
            return "none";
    }

    showSelectedOptions() {
        if(this.state.rowSelected)
            return "block";
        else 
            return "none";
    }
    showLanguageColumn() {
        if(this.state.preferredLanguage==='All' && this.state.platform === 'Android')
            return 'table-cell';
        else 
            return 'none';
    }

    showCategoryColumn() {
        return "none";
        if(this.state.packageFilter === 'com.gojek.app')
            return "table-cell";
        else 
            return "none";
    }

    showPlatformColumn() {
        if(this.state.queryText.length > 0)
            return "table-cell";
        else
            return "none";
    }

    getAppName(packageName) {
        if(packageName === 'com.gojek.app')
            return "consumer";
        else if (packageName === 'com.gojek.partner')
            return "partner";
        else if (packageName === 'com.gojek.resto')
            return "biz";
    }

    getCategoryLabel(product, issue) {

        if(!product) product = '';
        if(!issue) issue = '';

        if(product === 'not an issue')
            product = '';
        if(issue === 'not an issue')
            issue = '';

        if(product === '' && issue === '') 
            return <div style={{color:"#898989"}}>general</div>
        else if(product.length > 0 && issue.length > 0)
            return product + ' / ' + issue;
        else 
            return product + issue;

    }

    showManagerApprovalCard() {
        if(this.state.showManagerApprovalCard)
            return "block";
        else 
            return "none";
    }

    approvalRequested() {
        console.log('approval requested')
        this.setState({showManagerApprovalInProcess: true});
    }

    showManagerApproval() {
        if(!this.state.showManagerApprovalInProcess)
            return "block";
        else
            return "none";
    }
    showManagerApprovalInProcess() {
        if(this.state.showManagerApprovalInProcess)
            return "block";
        else
            return "none";
    }

    getPlatformIcon(platform) {
        if(platform === 'android')
            return <i className="fab fa-android" style={{fontSize:"17px", color:"#a4c639"}}></i>
        else if(platform === 'iOS')
            return <i className="fab fa-apple" style={{fontSize:"17px", color:"#898989"}}></i>

    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#666666"
        }
        
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        const noResultsDiv = {
            display: this.showNoResults(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        const resultCountDiv = {
            display: this.showResults(),
            textAlign: 'right',
            color:"gray",
            fontSize:"11px"
        }

        const ratingSummaryChart = {
            data: (canvas) => {
              let ctx = canvas.getContext("2d");
          
              let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, "#18ce0f");
              gradientStroke.addColorStop(1, chartColor);
          
              let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
              gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));

              return {
                labels: this.state.ratingSummary.labels, //["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
                datasets: [
                  {
                    label: "Daily Average Rating",
                    borderColor: "#ef8156",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1,
                    data: this.state.ratingSummary.data //[40, 500, 650, 700, 1200, 1250, 1300, 1900],
                  },
                ],
              };
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                intersect: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "rgba(255,255,255,0.05)",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(255,255,255,0.1)",
                      zeroLineColor: "transparent",
                      display: false,
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f",
                    },
                  },
                ],
              },
            },
        };
        const turnaroundsSummaryChart = {
            data: (canvas) => {
              let ctx = canvas.getContext("2d");
          
              let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, "#18ce0f");
              gradientStroke.addColorStop(1, chartColor);
          
              let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
              gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));

              return {
                labels: this.state.turnaroundSummary.labels, //["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
                datasets: [
                  {
                    label: "Daily Total Turnarounds",
                    borderColor: "green",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1,
                    data: this.state.turnaroundSummary.data //[40, 500, 650, 700, 1200, 1250, 1300, 1900],
                  }
                ],
              };
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                intersect: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "rgba(255,255,255,0.05)",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(255,255,255,0.1)",
                      zeroLineColor: "transparent",
                      display: false,
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f",
                    },
                  },
                ],
              },
            },
        };
        const reviewResponseRateChart = {
            data: (canvas) => {
              let ctx = canvas.getContext("2d");
          
              let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, "green");
              gradientStroke.addColorStop(1, chartColor);
          
              let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, "green");
              gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));

              return {
                labels: this.state.responseSummary.labels, //["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
                datasets: [
                  {
                    label: "Responses",
                    borderColor: "green",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1.6,
                    data: this.state.responseSummary.data //[40, 500, 650, 700, 1200, 1250, 1300, 1900],
                  },
                  {
                    label: "Reviews",
                    borderColor: "#ef8156",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1.6,
                    data: this.state.reviewSummary.data //[40, 500, 650, 700, 1200, 1250, 1300, 1900],
                  },
                ],
              };
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                intersect: false,
              },
              animations: {
                tension: {
                  duration: 1000,
                  easing: 'linear',
                  from: 0,
                  to: 1,
                  loop: true
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "rgba(255,255,255,0.05)",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(255,255,255,0.1)",
                      zeroLineColor: "transparent",
                      display: false,
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f",
                    },
                  },
                ],
              },
            },
        };
     
        const {teams} = this.state;

        return (
            <div style={{padding:"20px"}}>

                <style>
                    {
                        `
                        @keyframes spinning {
                            from { transform: rotate(0deg) }
                            to { transform: rotate(360deg) }
                        }
                        .spin {
                            animation-name: spinning;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                            /* linear | ease | ease-in | ease-out | ease-in-out */
                            animation-timing-function: linear;
                        }
                        `
                    }
                </style>

                <Row>
                    <Col sm="12" md="9" >
                        
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="6">
                                        <CardTitle tag="h4">App / Play Store Feedback</CardTitle>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{minHeight:"500px"}}>
                                <Row style={{display:this.showFilters()}}>
                                    <Col sm="6" md="4">
                                        App <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-mobile" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.packageFilter} 
                                                onChange={(e) => this.getAppReviews("package", e.target.value)} >
                                                    <option value='com.gojek.resto'>Biz</option>
                                                    <option value='com.gojek.app'>Consumer</option>
                                                    <option value='com.gojek.partner'>Partner</option>
                                                    <option value='com.gojek.gopay'>Payments</option>
                                                    <option value='com.gojek.gopaymerchant'>GoPay Merchant</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6" md="4">
                                        Platform <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-mobile" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.platform} onChange={(e) => this.getAppReviews("platform", e.target.value)} >
                                                    <option value='Android' selected>Android</option>
                                                    <option value='iOS' >iOS</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6" md="4" style={{display:this.showLanguage()}}>
                                        Review Language <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-globe-2" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.preferredLanguage} onChange={(e) => this.filterReviews("preferredLanguage", e.target.value)} >
                                                    <option>All</option>
                                                    <option>English</option>
                                                    <option>Indonesian</option>
                                                    <option>Thai</option>
                                                    <option>Vietnamese</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6" md="4" style={{display:this.showMarket()}}>
                                        Market <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-globe-2" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.market} onChange={(e) => this.filterReviews("market", e.target.value)} >
                                                    <option value='All'>All</option>
                                                    <option value='id'>Indonesia</option>
                                                    <option value='sg'>Singapore</option>
                                                    <option value='vn'>Vietnam</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6" md="4">
                                        Date <br />  
                                        <Input
                                            type="date"
                                            placeholder=""
                                            value={this.state.dateFilter}
                                            onChange={(e) => this.getAppReviews("date", e.target.value)}
                                            style={{height:"42px"}}
                                        />
                                    </Col>
                                    <Col sm="6" md="4">
                                        Rating <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top", color:"#898989"}}>
                                                    <i className="far fa-star"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.rating} onChange={(e) => this.filterReviews("rating", e.target.value)} >
                                                    <option value='All' selected>All</option>
                                                    <option value='low'>Low (1-2)</option>
                                                    <option value='medium'>Medium (3)</option>
                                                    <option value='high'>High (4-5)</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    <Col sm="6" md="4">
                                        Status <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"40px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-chat-33" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.status} onChange={(e) => this.filterReviews("status", e.target.value)} >
                                                    <option selected>All</option>
                                                    <option>No Response</option>
                                                    <option>Responded</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    
                                </Row>
                                {/* <Row style={{display:this.showSelectedOptions(), backgroundColor:"#898989", color:"white", padding:"6px"}}>
                                    <Col sm="12" style={{textAlign:"right"}}>
                                        <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                            Selected Items:
                                        </div>
                                        <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"10px"}}>
                                            <IssueSearch onIssueChosen={(e) => this.issueChosen(e)} />
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col sm="12">
                                        <div style={loadingDiv}>
                                            <div className="spin">
                                                <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                            </div>
                                        </div>
                                        <div style={noResultsDiv}>
                                            <div style={{marginTop:"234px"}}>
                                                No Results Found
                                            </div>
                                        </div>
                                        <div style={resultCountDiv}>
                                            <div>
                                                {this.state.filtered.length} results
                                            </div>
                                        </div>
                                        <div style={{display:this.showResults()}}>
                                            <Table style={{borderColor:"green", width:"100%"}}>
                                                <thead>
                                                    <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                        <td></td>
                                                        <td>Review</td>
                                                        <td style={{display:this.showLanguageColumn(), color:"green", cursor:"pointer", textAlign:"center"}} onClick={() => this.sortReviews('language')}>Language</td>
                                                        <td style={{display:this.showMarketColumn(), color:"green", cursor:"pointer", textAlign:"center"}} onClick={() => this.sortReviews('market')}>Market</td>
                                                        <td style={{display:this.showCategoryColumn(), color:"green", cursor:"pointer", textAlign:"center"}} onClick={() => this.sortReviews('category')}>Category</td>
                                                        <td style={{color:"green", textAlign:"center", cursor:"pointer"}} onClick={() => this.sortReviews('rating')}>Rating</td>
                                                        <td style={{color:"green", textAlign:"center", cursor:"pointer"}} onClick={() => this.sortReviews('thumbsUp')}>Votes</td>
                                                        <td style={{color:"green", textAlign:"center", cursor:"pointer", display:this.showPlatformColumn()}} onClick={() => this.sortReviews('platform')}>Platform</td>
                                                        <td style={{color:"green", cursor:"pointer", display:this.showDates()}} onClick={() => this.sortReviews('modified')}>Date Modified</td>
                                                        <td style={{textAlign:"center"}}>Replied</td>
                                                        <td style={{textAlign:"center"}}>Issue</td>
                                                        <td style={{textAlign:"center"}}>
                                                            <i style={{fontSize:"17px", marginTop:"0px", color:"gray", display:"none"}} className="nc-icon nc-check-2" />
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filtered.map((review, index) => (
                                                    <tr key={index} style={{backgroundColor:tables.rowColor(index)}}>
                                                        <td>
                                                            <ReviewCard review={review} onUpdated={(r) => this.onReviewUpdated(r)} />
                                                        </td>
                                                        <td>
                                                            <div style={{fontSize:"12px"}} title={review.t}>{strings.trim(review.t,54)}</div>
                                                            <div style={{fontSize:"11px", color:"green"}}>{review.review.authorName}</div>
                                                        </td>
                                                        <td style={{display:this.showLanguageColumn(), fontSize:"11px", color:"#545454", textAlign:"center"}}>
                                                            {this.getReviewerLanguage(review.language)}
                                                        </td>
                                                        <td style={{display:this.showMarketColumn(), fontSize:"11px", color:"#545454", textAlign:"center"}}>
                                                            {this.getMarketName(review.market)}
                                                        </td>
                                                        <td style={{display:this.showCategoryColumn(), fontSize:"11px", color:"#545454", textAlign:"center"}}>
                                                            {this.getCategoryLabel(review.productLabel, review.issueLabel)}
                                                        </td>
                                                        <td style={{minWidth:"93px", textAlign:"center",}}>
                                                            {this.getStars(review.rating)}
                                                        </td>
                                                        <td style={{width:"111px", fontSize:"11px", color:"#545454", textAlign:"center"}}>
                                                            {review.votes}
                                                        </td>
                                                        <td style={{width:"111px", fontSize:"11px", color:"#545454", textAlign:"center", display:this.showPlatformColumn()}}>
                                                            {this.getPlatformIcon(review.platform)}
                                                        </td>
                                                        <td style={{width:"123px", fontSize:"11px", color:"#545454", display:this.showDates()}}>
                                                            {getFormattedDate(review.lastComment)}
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <i style={{fontSize:"17px", marginTop:"0px", color:this.getIconColor(review.responded)}} className="nc-icon nc-chat-33" />
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            {review.isLinked}
                                                            <i style={{fontSize:"17px", marginTop:"0px", color:this.getIconColor(review.isLinked)}} className="nc-icon nc-ambulance" />
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <i style={{fontSize:"17px", fontWeight:600, marginTop:"0px", marginRight:"10px", cursor:"pointer", color:this.getIconColor(review.isSelected)}} className="nc-icon nc-check-2" onClick={() => this.toggleSelect(review)} />
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12" md="3">  

                        <div style={{display:this.showManagerApprovalCard()}}>
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs="12">
                                        <div style={{fontSize:"12px", display:"inline-block", margin:"10px", marginBottom:"25px"}}>
                                            <div style={{color:"green", fontSize:"17px"}}>CUSTOMER OBSESSION</div> 
                                            <br />
                                            Don't just read what customers have to say, <b>get involved</b>.
                                            You can help solve problems, answer questions and say thanks
                                            to our customers. All you need is your managers
                                            approval to start replying to customer reviews.
                                            <br /><br />
                                            <div style={{display: this.showManagerApproval()}}>
                                                <ManagerApproval 
                                                    buttonText="GET STARTED"
                                                    title="Customer Obsession" 
                                                    line1="We are eager to get you involved in discussions with our customers. Whether they sent us good or bad feedback, when we respond with empathy we can create loyal, lifelong customers." 
                                                    line2="Please remember that you are responsible for always engaging with customers professionally. Every response we send is public, so choose your wording carefully. Responding to customers in a derogatory or abusive way may result in termination."
                                                    line3="Let's get this approval out of the way so you can get started!"
                                                    managerText="Outstanding news! One of your employees is ready to engage with our users and is requesting permission to respond to customer feedback in the Apple App / Google Play Store. Follow the link below to learn more."
                                                    type="managerApprovalAppReviews"
                                                    confirmed={() => this.approvalRequested()} /> 
                                            </div>
                                            <div style={{display: this.showManagerApprovalInProcess(), textAlign:"center", color:"#232323"}}>
                                                REQUEST SENT TO MANAGER
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        </div>

                        <br />
                        <Card>
                            <CardBody>
                                <h6 className="big-title">
                                    Top Customer Advocates<br />
                                    <span style={{fontSize:"10px", color:"#666666"}}>last 10 days</span>
                                </h6>
                                {this.state.respondents.map((respondent, index) => (
                                    <div key={index}>
                                        <div style={{display:"inline-block"}}>
                                            <PersonCard person={respondent} showLargeThumb />
                                        </div>
                                        <div style={{display:"inline-block", marginLeft:"10px", fontSize:"11px", verticalAlign:"top"}}>
                                            <div style={{color:"green", size:"13px", fontWeight:"600"}}>{respondent.name}</div>
                                            <div style={{color:"gray"}}>{isEmpty(respondent.responses) ? 0 : respondent.responses} responses</div>
                                        </div>
                                        
                                    </div>
                                ))}
                            </CardBody>
                        </Card>

                        <br />
                        {
                            Object.keys(teams).length > 0 && Object.keys(teams).map(teamId => (
                                <div key={teamId}>
                                    <Card>
                                        <CardBody>
                                            <h6 className="big-title">
                                                Your Team : {teams[teamId].name}<br />
                                                <span style={{fontSize:"10px", color:"#666666"}}>last 30 days</span>
                                            </h6>
                                            {teams[teamId].people && teams[teamId].people.length && teams[teamId].people.map((member, index) => (
                                                <div key={index}>
                                                    <div style={{display:"inline-block"}}>
                                                        <PersonCard person={member} showLargeThumb />
                                                    </div>
                                                    <div style={{display:"inline-block", marginLeft:"10px", fontSize:"11px", verticalAlign:"top"}}>
                                                        <div style={{color:"green", size:"13px", fontWeight:"600"}}>{member.name}</div>
                                                        <div style={{color:"gray"}}>{isEmpty(member.responses) ? 0 : member.responses} responses</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </CardBody>
                                    </Card>
                                    <br />
                                </div>
                            ))
                        }

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="7">
                                        <div className="numbers pull-left" style={{display:"inline-block"}}>{this.state.totalReRatedReviews}</div> 
                                        <div style={{fontSize:"12px", display:"inline-block", marginLeft:"10px", marginTop:"6px", marginBottom:"25px"}}>
                                            reviews re-rated<br />
                                            <span style={{fontSize:"10px", color:"#666666"}}>last 10 days</span>
                                        </div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right" style={{marginTop:"10px"}}>
                                            <Badge color="success" pill>
                                                {this.state.reRatingDifferential}
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="12">
                                        <div className="numbers pull-left" style={{display:"inline-block"}}>{this.state.percentReviewsResponded}</div> 
                                        <div style={{fontSize:"12px", display:"inline-block", marginLeft:"10px", marginTop:"6px", marginBottom:"25px"}}>
                                            average response rate<br />
                                            <span style={{fontSize:"10px", color:"#666666"}}>last 10 days</span>
                                        </div>
                                        
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="big-title">
                                    Reviews and Responses
                                </h6>
                                <Line
                                    data={reviewResponseRateChart.data}
                                    options={reviewResponseRateChart.options}
                                    height={217}
                                />
                            </CardBody>
                        </Card>
                        
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="7">
                                        <div className="numbers pull-left" style={{display:"inline-block"}}>{this.state.totalTurnarounds}</div> 
                                        <div style={{fontSize:"12px", display:"inline-block", marginLeft:"10px", marginTop:"15px", marginBottom:"25px"}}>total</div>
                                    </Col>
                                    <Col sm="5">
                                        <div className="pull-right" style={{marginTop:"10px"}}>
                                            <Badge color="success" pill>
                                                {this.state.turnaroundImpact}
                                            </Badge>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="big-title">
                                    Daily Turnarounds
                                </h6>
                                <Line
                                    data={turnaroundsSummaryChart.data}
                                    options={turnaroundsSummaryChart.options}
                                    height={123}
                                />
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col sm="12">
                                        <div style={{fontSize:"10px", color:"#666666", marginTop:"5px", marginLeft:"6px"}}>
                                            A turnaround is a 1-star changed to a 5-star, which is the equivalent of seven more 5-star reviews.
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        

                        <br />
                        <Card>
                            <CardBody>
                                <h6 className="big-title">
                                    Daily Average Rating
                                </h6>
                                <Line
                                    data={ratingSummaryChart.data}
                                    options={ratingSummaryChart.options}
                                    height={189}
                                />
                            </CardBody>
                        </Card>

                        <br />
                        <div style={{color:"#aaaaaa", fontSize:"11px"}}>
                            Note: charts currently reflect Android numbers only.
                        </div>

                        <br />
                        <div style={{color:"#aaaaaa", fontSize:"11px", display:"none"}}>
                            <i style={icon} className="nc-icon nc-settings" />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

CustomerFeedbackList = connect(mapStateToProps)(CustomerFeedbackList);
export default CustomerFeedbackList;