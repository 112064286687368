// import animationData from '"assets/img/Appreciate/StayCurious';
/*
import StayCurious from "assets/img/Appreciate/AppreciateWall/StayCurious.json";
import CustomerFocus from "assets/img/Appreciate/AppreciateWall/CustomerFocus.json";
import GreatMentor from "assets/img/Appreciate/AppreciateWall/GreatMentor.json";
import Bridges from "assets/img/Appreciate/AppreciateWall/BridgesNotSilos.json";
import RisingStar from "assets/img/Appreciate/AppreciateWall/RisingStar.json";
import KeepThePromise from "assets/img/Appreciate/AppreciateWall/KeepThePromise.json"; 
import Difference from "assets/img/Appreciate/AppreciateWall/EmbraceDifferences.json";
import TILII from "assets/img/Appreciate/AppreciateWall/TILII.json"; 
import UnlockPotential from "assets/img/Appreciate/AppreciateWall/UnlockPotential.json"; 
import CreatingClarity from "assets/img/Appreciate/AppreciateWall/CreatingClarity.json"; 
import TeamPlayer from "assets/img/Appreciate/AppreciateWall/TeamPlayerNew.json"; 
*/
import ImageStayCurious from "assets/img/Appreciate/AppreciateProfile/StayCurious.png";
import ImageCustomerFocus from "assets/img/Appreciate/AppreciateProfile/CustomerFocus.png";
import ImageGreatMentor from "assets/img/Appreciate/AppreciateProfile/GreatMentor.png";
import ImageBridges from "assets/img/Appreciate/AppreciateProfile/BridgesNotSilos.png";
import ImageRisingStar from "assets/img/Appreciate/AppreciateProfile/RisingStar.png";
import ImageKeepThePromise from "assets/img/Appreciate/AppreciateProfile/KeepThePromise.png";
import ImageDifference from "assets/img/Appreciate/AppreciateProfile/EmbraceDifferences.png";
import ImageTILII from "assets/img/Appreciate/AppreciateProfile/TILII.png"; 
import ImageUnlockPotential from "assets/img/Appreciate/AppreciateProfile/UnlockPotential.png"; 
import ImageCreatingClarity from "assets/img/Appreciate/AppreciateProfile/CreatingClarity.png"; 
import ImageTeamPlayer from "assets/img/Appreciate/AppreciateProfile/TeamPlayer.png" 

import StandUpFor from "assets/img/Appreciate/ValuesWall/StandUpFor.json";
import BeFast from "assets/img/Appreciate/ValuesWall/BeFast.json";
import CriticismGift from "assets/img/Appreciate/ValuesWall/CriticismGift.json";
import Collaborate from "assets/img/Appreciate/ValuesWall/Collaborate.json";
import Empower from "assets/img/Appreciate/ValuesWall/Empower.json";
import PutCustomerFirst from "assets/img/Appreciate/ValuesWall/PutCustomerFirst.json";
import ShootForGreatness from "assets/img/Appreciate/ValuesWall/ShootForGreatness.json";
import CommunicateWithPurpose from "assets/img/Appreciate/ValuesWall/CommunicateWithPurpose.json";
import ItsNotAboutYou from "assets/img/Appreciate/ValuesWall/ItsNotAboutYou.json";
import AlwaysBePrepared from "assets/img/Appreciate/ValuesWall/AlwaysBePrepared.json";
import BecomeAScientist from "assets/img/Appreciate/ValuesWall/BecomeAScientist.json";
import EarnYourTitle from "assets/img/Appreciate/ValuesWall/EarnYourTitle.json";

import ImageStandUpFor from "assets/img/Appreciate/ValuesProfile/StandUpFor.json";
import ImageBeFast from "assets/img/Appreciate/ValuesProfile/BeFast.json";
import ImageCriticismGift from "assets/img/Appreciate/ValuesProfile/CriticismGift.json";
import ImageCollaborate from "assets/img/Appreciate/ValuesProfile/Collaborate.json";
import ImageEmpower from "assets/img/Appreciate/ValuesProfile/Empower.json";
import ImagePutCustomerFirst from "assets/img/Appreciate/ValuesProfile/PutCustomerFirst.json";
import ImageShootForGreatness from "assets/img/Appreciate/ValuesProfile/ShootForGreatness.json";
import ImageCommunicateWithPurpose from "assets/img/Appreciate/ValuesProfile/CommunicateWithPurpose.json";
import ImageItsNotAboutYou from "assets/img/Appreciate/ValuesProfile/ItsNotAboutYou.json";
import ImageAlwaysBePrepared from "assets/img/Appreciate/ValuesProfile/AlwaysBePrepared.json";
import ImageBecomeAScientist from "assets/img/Appreciate/ValuesProfile/BecomeAScientist.json";
import ImageEarnYourTitle from "assets/img/Appreciate/ValuesProfile/EarnYourTitle.json";

const categoryList =
  [
    {
      id: "1",
      title: "Stay Curious",
      description: "Ask the right questions, remain interested and invested during discussions and show eagerness to learn new things",
    //  image: StayCurious,
      png: ImageStayCurious
    },
    {
      id: "2",
      title: "Customer Focus",
      description: "Keeps a customer centric approach at all times and puts customers’ need at the center of decision making",
     // image: CustomerFocus,
      png: ImageCustomerFocus
    },
    {
      id: "3",
      title: "Great Mentor",
      description: "Shows empathy, treats people with respect, encourages people to develop strengths and dilute weaknesses",
    //  image: GreatMentor,
      png: ImageGreatMentor
    },
    {
      id: "4",
      title: "Build Bridges, Not Silos",
      description: "Understands & demonstrates the importance of relationships and collaborations & goes that extra mile to keep all stakeholders & teams aligned",
    //  image: Bridges,
      png: ImageBridges
    },
    {
      id: "5",
      title: "Rising Star",
      description: "New member with outstanding performance & accomplishments",
     // image: RisingStar,
      png: ImageRisingStar
    },
    {
      id: "6",
      title: "Embrace Difference",
      description: "Understands and appreciates individuals with different backgrounds, skills and values contrasting opinions. Encourages healthy discussions than consensus building",
  //   image: Difference,
      png: ImageDifference
    },
    {
      id: "7",
      title: "Tell It Like It Is",
      description: "Stand Up for what you believe in. Challenge the status quo, when it matters",
   //   image: TILII,
      png: ImageTILII
    },
    {
      id: "8",
      title: "Unlock Potential",
      description: "Finds solutions in unexpected ways. Steps out of the comfort zone and follows out of the box thinking",
   //   image: UnlockPotential,
      png: ImageUnlockPotential
    },
    {
      id: "9",
      title: "Creating Clarity",
      description: "Resolves ambiguity with detailed and timely communication with stakeholders",
   //   image: CreatingClarity,
      png: ImageCreatingClarity
    },
    {
      id: "10",
      title: "Team Player",
      description: "Acknowledges and appreciates team members, shares responsibility and works alongside team members to achieve a common goal. Focusses on shared success than individual glory",
   //   image: TeamPlayer,
      png: ImageTeamPlayer
    },
    {
      id: "11",
      title: "Keep The Promise",
      description: "Is trustworthy, always commits & delivers par excellence",
   //   image: KeepThePromise,
      png: ImageKeepThePromise
    },

    {
      id: "12",
      title: "It's Not About You",
      description: "Puts the company above themselves, is obsessed with customer problems (not personal problems) and has a purpose beyond personal success.",
      image: ItsNotAboutYou,
      png: ImageItsNotAboutYou
    },
    {
      id: "13",
      title: "Stand Up For What You Believe In",
      description: "Says what they mean, has courage to disagree and has a strong moral compass.",
      image: StandUpFor,
      png: ImageStandUpFor
    },
    {
      id: "14",
      title: "Collaborate With Compassion",
      description: "Is a pleasure to work with, support others in areas beyond their scope and considers how their actions affect others.",
      image: Collaborate,
      png: ImageCollaborate
    },
    {
      id: "15",
      title: "Be Fast And Fearless",
      description: "Takes calculated risks, values failures as much as successes and has strong sense of urgency.",
      image: BeFast,
      png: ImageBeFast
    },
    {
      id: "16",
      title: "Earn Your Title",
      description: "Walks the talk, gets down in the trenches and trusts their team.",
      image: EarnYourTitle,
      png: ImageEarnYourTitle
    },
    {
      id: "17",
      title: "Become A Scientist",
      description: "Follows the numbers intensely, finds solutions in unexpected places, learns independently and share knowledge.",
      image: BecomeAScientist,
      png: ImageBecomeAScientist
    },
    {
      id: "18",
      title: "Always Be Prepared",
      description: "Does their homework, plans out every scenario and takes action to reduce risk.",
      image: AlwaysBePrepared,
      png: ImageAlwaysBePrepared
    },
    {
      id: "19",
      title: "Criticism Is A Gift",
      description: "Actively seeks feedback from others, genuinely acts on it, gives helpful, unprompted feedback to others.",
      image: CriticismGift,
      png: ImageCriticismGift
    },
    {
      id: "20",
      title: "Communicate With Purpose",
      description: "States objectives in every interaction, focuses on what is actionable, aligns early and consistently.",
      image: CommunicateWithPurpose,
      png: ImageCommunicateWithPurpose
    },
    {
      id: "21",
      title: "Shoot For Greatness",
      description: "Goes the extra mile, thinks big and loves to challenge themselves.",
      image: ShootForGreatness,
      png: ImageShootForGreatness
    },
    {
      id: "22",
      title: "Put Customers First",
      description: "Keeps a customer centric approach at all times and puts customers’ needs at the center of decision making.",
      image: PutCustomerFirst,
      png: ImagePutCustomerFirst
    },
    {
      id: "23",
      title: "Empower the Small",
      description: "Shows empathy, treats people with respect, encourages people to develop strengths and minimise weaknesses.",
      image: Empower,
      png: ImageEmpower
    }
  ];

export default categoryList;