import React from 'react'
import { Link } from 'react-router-dom'

export default function CardToPageRedirect({
  pageSlug, redirectFromCardToPage, children,
}) {
  if (redirectFromCardToPage) {
    return (
      <Link to={pageSlug} target="_blank" style={{ textDecoration: 'none' }}>
        <div style={{ whiteSpace: 'normal' }}>
          { children }
        </div>
      </Link>
    )
  }

  return <div style={{ whiteSpace: 'normal' }}>{children}</div>
}
