import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Button,
    Card, CardBody, CardHeader, CardTitle,
    Row, Col
  } from "reactstrap";

import ResultsCard from "./ResultsCard";

class FiveDysfunctions extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        response: {
            personID: this.props.user.personID,
            managerID: this.props.user.person.managerID,
            userID: this.props.user.id,
            quarter: '2022q2',
            q1: 0,
            q2: 0,
            q3: 0,
            q4: 0,
            q5: 0,
            q6: 0,
            q7: 0,
            q8: 0,
            q9: 0,
            q10: 0,
            q11: 0,
            q12: 0,
            q13: 0,
            q14: 0,
            q15: 0,
            isFinal: false
          },
          manager: {},
          isLoading: true,
          alertVisible: false,
        }
    }

    componentDidMount() {

        db.getFiveDysfunctionsResponsesByUser(this.props.user.id).then(results => {
            if(results.length > 0)
              this.setState({response: results[0]})
        });

        // get data from DB to make sure we're not using a cached manager
        db.getPerson(this.props.user.person.id).then(person => {
            db.getPerson(person.managerID).then(manager => {
                this.setState({manager: manager});
            })
        });

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "fiveDysfunctions", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });

    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }


    render() {

        const layer1 = {
            borderBottom: "75px solid gray",
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "500px",
            textAlign:"center",
            marginBottom: "50px",
        }
        const layer2 = {
            borderBottom: "75px solid gray",
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "400px",
            marginLeft:"50px"
        }
        const layer3 = {
            borderBottom: "75px solid gray",
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "300px",
            marginLeft:"100px"
        }
        const layer4 = {
            borderBottom: "75px solid gray",
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "200px",
            marginLeft:"150px"
        }
        const layer5 = {
            borderBottom: "75px solid gray",
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "100px",
            marginLeft:"200px"
        }

        return (
            <div style={{padding:"10px", paddingTop:"0px"}}>
               
               <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>
                <Row>
                    <Col xs="12">
                        <CardTitle tag="h4">Team Health</CardTitle>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" style={{fontWeight:500, marginBottom:"20px"}}>
                        
                        We are committed to building better teams at Gojek. Like any other skill we 
                        want to improve, we need feedback to know what we're doing well and where 
                        we need to improve.<br /><br />

                        Ideally you should send this survey to team members just as a team meeting has 
                        concluded, such that how they interact with the team is fresh in their mind. 
                        You will want to use the survey more than once to track your progress as a 
                        team but be careful not to use it too often. <br /><br />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card style={{padding:"20px"}}>
                            <CardHeader>
                                <Row>
                                    <Col xs="12">
                                        <CardTitle tag="h4">The Model</CardTitle>
                                        <div style={{fontSize:"13px", fontWeight:400, marginTop:"6px", marginBottom:"0px", color:"#434343"}}>
                                        This survey is designed to take the pulse of your team using the model described
                                        in the book, "the Five Dysfunctions of a Team". The results give you an indicator
                                        as to the level of trust amongst team members, their ability to engage in constructive conflict, 
                                        to commit to decisions, hold each other accountable and focus on results. <br /><br />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="8"  >

                                        <div style={layer5}> 
                                            
                                        </div> 
                                        <div style={layer4} >
                                            <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                        </div> 
                                        <div style={layer3} >
                                            <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                        </div> 
                                        <div style={layer2} >
                                            <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                        </div> 
                                        <div style={layer1} >
                                            <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                        </div>    
                                    </Col>
                                    <Col xs="12" sm="4" style={{fontSize:"17px"}}>

                                        <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"180%", marginLeft:"-80%"}}>
                                            INATTENTION TO RESULTS
                                        </div>
                                        <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"160%", marginLeft:"-60%"}}>
                                            AVOIDANCE OF ACCOUNTABILITY
                                        </div>
                                        <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"140%", marginLeft:"-40%"}}>
                                            LACK OF COMMITMENT
                                        </div>
                                        <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"120%", marginLeft:"-20%"}}>
                                            FEAR OF CONFLICT
                                        </div>
                                        <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666"}}>
                                            LACK OF TRUST
                                        </div>
                                    </Col>
                                
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" style={{marginBottom:"20px", padding:"10px"}}>
                                        <div style={{fontWeight:"bold"}}>LACK OF TRUST</div>
                                        Vulnerability based trust ...
                                    </Col>
                                    <Col xs="12" sm="6" style={{marginBottom:"20px", padding:"10px"}}>
                                        <div style={{fontWeight:"bold"}}>FEAR OF CONFLICT</div>
                                        Constructive conflict ...
                                    </Col>
                                    <Col xs="12" sm="6" style={{marginBottom:"20px", padding:"10px"}}>
                                        <div style={{fontWeight:"bold"}}>LACK OF COMMITMENT</div>
                                        Disagree and commit ... 
                                    </Col>
                                    <Col xs="12" sm="6" style={{marginBottom:"20px", padding:"10px"}}>
                                        <div style={{fontWeight:"bold"}}>AVOIDANCE OF ACCOUNTABILITY</div>
                                        hmm?
                                    </Col>
                                    <Col xs="12" sm="6" style={{marginBottom:"20px", padding:"10px"}}>
                                        <div style={{fontWeight:"bold"}}>INATTENTION TO RESULTS</div>
                                        hmm?
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <ResultsCard surveyID={"1zQfzn2bswtTlLpZjtWv"} />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" >
                        
                        <Card style={{padding:"20px", display:"none"}}>
                            <CardBody style={{minHeight:"300px", padding:"23px"}}>

                                <Row>
                                    <Col xs="12" style={{fontSize:"14px", fontWeight:600, color:"green"}}>
                                        Text.<br /><br />  
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                        <br />

                       
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

FiveDysfunctions = connect(mapStateToProps)(FiveDysfunctions);
export default FiveDysfunctions;