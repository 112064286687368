import { applyQueryFilterOnNexusIssues, buildIssue } from '../../views/nexusReports/nexusAnalyticsHelper'
import { ISSUES_PAGE_LIMIT } from '../../views/nexusReports/utils'
import { db } from './firebase'

export const deleteAlert = (id) => db.collection('Alerts').doc(id).delete()

export const deleteClient = (id) => db.collection('Clients').doc(id).delete()

export const deleteCleverTapAccount = (id) => db.collection('ctAccounts').doc(id).delete()

export const deleteCleverTapEvent = (id) => db.collection('ctEvents').doc(id).delete()

export const deleteEntityFeedback = (id) => db.collection('EntityFeedback').doc(id).delete()

export const deleteIssue = (id) => db.collection('Issues').doc(id).delete()

export const deleteIssueFollower = (id) => db.collection('IssueFollowers').doc(id).delete()

export const deleteIssueReview = (id) => db.collection('IssueReviews').doc(id).delete()

export const deleteIssueTicket = (id) => db.collection('IssueTickets').doc(id).delete()

export const deleteLearningJournal = (id) => db.collection('LearningJournal').doc(id).delete()

export const deleteJournal = (id) => db.collection('Journals').doc(id).delete()

export const deleteMetric = (id) => db.collection('Metrics').doc(id).delete()

export const deleteMetricValue = (id) => db.collection('MetricValues').doc(id).delete()

export const deleteMetricValues = (metricID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('MetricValues')
  const queryRef = baseRef.where('metricID', '==', metricID)
  queryRef.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      db.collection('MetricValues').doc(doc.id).delete()
    })
    resolve()
  })
})

export const deleteMilestone = (id) => db.collection('Milestones').doc(id).delete()

export const deleteNexusEndpoint = (id) => db.collection('NexusEndpoints').doc(id).delete()

export const deleteOKR = (id) => db.collection('OKRs').doc(id).delete()

export const deleteNote = (id) => db.collection('Notes').doc(id).delete()

export const deletePerson = (id) => db.collection('People').doc(id).delete()

export const deleteProjectMember = (id) => db.collection('ProjectMembers').doc(id).delete()

export const deleteStatusReport = (id) => db.collection('StatusReport').doc(id).delete()

export const deleteRelatedTeam = (id) => db.collection('RelatedTeams').doc(id).delete()

export const deleteRisk = (id) => db.collection('Risks').doc(id).delete()

export const deleteTag = (id) => db.collection('Tags').doc(id).delete()

export const deleteTask = (id) => db.collection('Tasks').doc(id).delete()

export const deleteTeam = (id) => db.collection('Teams').doc(id).delete()

export const deleteTeamMember = (id) => db.collection('TeamMembers').doc(id).delete()

export const deleteTeamProject = (id) => db.collection('TeamProjects').doc(id).delete()

export const deleteTip = (id) => db.collection('Tips').doc(id).delete()

export const deleteUser = (id) => db.collection('Users').doc(id).delete()

export const deleteAppreciation = (id) => db.collection('Appreciation').doc(id).delete()

export const deleteLearningResource = (id) => db.collection('LearningResources').doc(id).delete()

export const deleteMercuryChart = (id) => db.collection('mercuryCharts').doc(id).delete()

export const deleteMercuryDashboard = (id) => db.collection('mercuryDashboards').doc(id).delete()

// generic get
export const getTableData = (table) => new Promise((resolve, reject) => {
  const baseRef = db.collection(table)
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getActivityByOrg = (orgID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Activity')
  const queryRef = baseRef.where('orgID', '==', orgID)
  queryRef.orderBy('modified', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getActivitySummaries = (days) => new Promise((resolve, reject) => {
  if (!days) days = 10

  const today = new Date().getTime()
  const timeInBetween = (24 * 60 * 60 * 1000 * days)
  const daysAgo = parseInt(today - timeInBetween) // seconds in a day * 10 days

  const baseRef = db.collection('UserActivitySummaries')
  const queryRef = baseRef.where('summaryDate', '>=', daysAgo)

  queryRef.orderBy('summaryDate', 'asc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAppreciationByPerson = (personID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Appreciation')
  const queryRef = baseRef.where('recipientID', '==', personID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getAPI = (id) => new Promise((resolve, reject) => {
  db.collection('APIs').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing API:${id}`)
    resolve(obj)
  })
})

export const getAPIs = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('APIs')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getRecentAppreciations = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Appreciation')
  baseRef.orderBy('createdOn', 'desc').limit(80).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAppreciations = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Appreciation')
  baseRef.limit(15).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAsanaProject = (id) => new Promise((resolve, reject) => {
  id = id.toString()
  db.collection('asanaProjects').doc(id).get().then((doc) => {
    const d = doc.data()
    d.id = doc.id
    resolve(d)
  })
})

export const getAsanaProjectStatus = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('asanaProjectStatus')
  const queryRef = baseRef.where('projectID', '==', id)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAsanaUsers = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('asanaUsers')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAsanaUser = (id) => db.collection('asanaUsers').doc(id).get()

export const getUserActivityBetween = (lowerBound, upperBound) => new Promise((resolve, reject) => {
  const baseRef = db.collection('UserActivity')
  const queryRef = baseRef.where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getUserActivityByTypeBetween = (type, lowerBound, upperBound) => new Promise((resolve, reject) => {
  const baseRef = db.collection('UserActivity')
  const queryRef = baseRef.where('activityType', '==', type).where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getUserActivityByTypeAndPageIDBetween = (type, pageID, lowerBound, upperBound) => new Promise((resolve, reject) => {
  const baseRef = db.collection('UserActivity')
  const queryRef = baseRef.where('pageID', '==', pageID).where('activityType', '==', type).where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getContributionsByPerson = (personID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('UserActivity')
  const queryRef = baseRef.where('personID', '==', personID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getUserActivityByType = (type, after) => new Promise((resolve, reject) => {
  const baseRef = db.collection('UserActivity')
  const queryRef = baseRef.where('activityType', '==', type).where('created', '>', after)
  console.log(after)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const activity = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      activity.push(a)
    })
    resolve(activity)
  })
})

export const getUserActivityLastXHours = (hours) => {
  const rightNow = new Date().getTime()
  const someTimeAgo = rightNow - (hours * 3600 * 1000)

  return new Promise((resolve, reject) => {
    const baseRef = db.collection('UserActivity')
    const queryRef = baseRef.where('created', '>', someTimeAgo)
    queryRef.orderBy('created', 'desc').get().then((snapshot) => {
      const activity = []
      snapshot.forEach((doc) => {
        const a = doc.data()
        a.id = doc.id
        activity.push(a)
      })
      resolve(activity)
    })
  })
}

export const getPresenceActivity = (pageID) => {
  const rightNow = new Date().getTime()
  const someTimeAgo = rightNow - (360 * 1000)

  return new Promise((resolve, reject) => {
    const baseRef = db.collection('UserActivity')
    const queryRef = baseRef.where('dwellTime', '>', someTimeAgo)
    queryRef.where('pageID', '==', pageID).get().then((snapshot) => {
      const peopleIDs = []
      snapshot.forEach((doc) => {
        const a = doc.data()

        let isFound = false
        for (let i = 0; i < peopleIDs.length; i++) {
          if (peopleIDs[i] === a.personID) {
            isFound = true
            break
          }
        }

        if (!isFound) peopleIDs.push(a.personID)
      })

      resolve(peopleIDs)
    })
  })
}

export const getAlerts = (orgID) => {
  const baseRef = db.collection('Alerts')
  const queryRef = baseRef.where('orgId', '==', orgID)
  return queryRef.orderBy('dateTimeSent', 'desc').get()
}

export const getAlertsByType = (alertType) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Alerts').where('type', '==', alertType)
  baseRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getAlertsLastDays = (days) => {
  if (!days) days = 3

  const rightNow = new Date().getTime()
  const someTimeAgo = rightNow - (days * 3600 * 1000 * 24)

  return new Promise((resolve, reject) => {
    const baseRef = db.collection('Alerts')
    const queryRef = baseRef.where('created', '>', someTimeAgo)
    queryRef.orderBy('created', 'desc').get().then((snapshot) => {
      const activity = []
      snapshot.forEach((doc) => {
        const a = doc.data()
        a.id = doc.id
        activity.push(a)
      })
      resolve(activity)
    })
  })
}

export const getAlertsBetween = (lowerBound, upperBound) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Alerts')
  const queryRef = baseRef.where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getCareer = (id) => new Promise((resolve, reject) => {
  db.collection('Career').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getCleverTapAccounts = (type, after) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ctAccounts')
  baseRef.orderBy('name', 'asc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getCleverTapEvents = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('ctEvents')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getCleverTapEventsYearMonth = (month, year) => {
  month = Number(month)
  year = Number(year)

  return new Promise((resolve, reject) => {
    const baseRef = db.collection('ctEvents')
    const queryRef = baseRef.where('month', '==', month).where('year', '==', year)
    queryRef.get().then((snapshot) => {
      const objs = []
      snapshot.forEach((doc) => {
        const a = doc.data()
        a.id = doc.id
        objs.push(a)
      })
      resolve(objs)
    })
  })
}

export const getCleverTapEventsFiltered = (month, year, account) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ctEvents')
  const queryRef = baseRef.where('month', '==', month).where('year', '==', year).where('accountName', '==', account)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getCleverTapOwners = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('ctOwners')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getClient = (id) => new Promise((resolve, reject) => {
  db.collection('Clients').doc(id).get().then((doc) => {
    const client = doc.data()
    client.id = doc.id
    resolve(client)
  })
})

export const getFiveDysfunctionsSurvey = (id) => new Promise((resolve, reject) => {
  db.collection('FiveDysfunctionsSurveys').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getFiveDysfunctionsResponsesBySurveyID = (userID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('FiveDysfunctions')
  const queryRef = baseRef.where('surveyID', '==', userID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getFiveDysfunctionsResponsesByUser = (userID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('FiveDysfunctions')
  const queryRef = baseRef.where('userID', '==', userID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getGNNEpisodes = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('GNNEpisodes')
  const queryRef = baseRef.orderBy('published', 'desc')
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})
export const getEntityFeedback = (type) => new Promise((resolve, reject) => {
  const baseRef = db.collection('EntityFeedback')
  const queryRef = baseRef.where('type', '==', type).orderBy('created', 'desc')
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getIssue = (id) => new Promise((resolve, reject) => {
  db.collection('Issues').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getIssues = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Issues')
  baseRef.orderBy('modified', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})
export const getIssuesByProjectID = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Issues')
  baseRef.where('projectID', '==', projectID).orderBy('modified', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getIssuesByTeamID = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Issues')
  baseRef.where('team', '==', teamID).where('createdBy', '!=', 'Nexus').orderBy('createdBy', 'desc')
      .orderBy('modified', 'desc')
      .get()
      .then((snapshot) => {
        const objs = []
        snapshot.forEach((doc) => {
          const d = doc.data()
          d.id = doc.id
          objs.push(d)
        })
        resolve(objs)
      })
})

export const getRecentReviews = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Reviews')
  baseRef.orderBy('modified', 'desc').limit(4).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getReview = (id) => new Promise((resolve, reject) => {
  db.collection('Reviews').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getReviewsAll = (appPackage) => new Promise((resolve, reject) => {
  db.collection('Reviews').where('packageName', '==', appPackage).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getReviews = (date, appPackage) => new Promise((resolve, reject) => {
  const startTime = new Date(date).getTime() / 1000
  const endTime = startTime + (24 * 60 * 60) // seconds in a day

  const baseRef = db.collection('Reviews').where('packageName', '==', appPackage)
  const queryRef = baseRef.where('lastComment', '>=', startTime).where('lastComment', '<', endTime)

  queryRef.orderBy('lastComment', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getReviewSummaries = (days) => new Promise((resolve, reject) => {
  if (!days) days = 10

  const today = new Date().getTime()
  const timeInBetween = (24 * 60 * 60 * 1000 * days)
  const daysAgo = parseInt(today - timeInBetween) // seconds in a day * 10 days

  const baseRef = db.collection('ReviewSummaries')
  const queryRef = baseRef.where('summaryDate', '>=', daysAgo)

  queryRef.orderBy('summaryDate', 'asc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getAllReviews = (startAt, pageSize) => {
  console.log(startAt)
  return new Promise((resolve, reject) => {
    const baseRef = db.collection('Reviews')
    const queryRef = baseRef.orderBy('modified', 'asc').startAt(startAt).limit(pageSize)
    queryRef.get().then((snapshot) => {
      const objs = []
      snapshot.forEach((doc) => {
        const d = doc.data()
        d.id = doc.id
        objs.push(d)
      })
      console.log(objs[0])
      resolve(objs)
    })
  })
}

export const getIssueFollowers = (issueID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('IssueFollowers')
  const queryRef = baseRef.where('issueID', '==', issueID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getIssueReviews = (issueID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('IssueReviews')
  const queryRef = baseRef.where('issueID', '==', issueID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getIssueReviewsRecent = (days) => new Promise((resolve, reject) => {
  if (!days) days = 10

  const today = new Date().getTime()
  const timeInBetween = (24 * 60 * 60 * 1000 * days)
  const daysAgo = parseInt(today - timeInBetween) // seconds in a day * 10 days

  const baseRef = db.collection('IssueReviews')
  const queryRef = baseRef.where('created', '>=', daysAgo)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getIssueTickets = (issueID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('IssueTickets')
  const queryRef = baseRef.where('issueID', '==', issueID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getJACA = (role, level) => new Promise((resolve, reject) => {
  const baseRef = db.collection('JACA')
  const queryRef = baseRef.where('role', '==', role).where('level', '==', level)
  queryRef.get().then((snapshot) => {
    if (snapshot.docs.length > 0) {
      snapshot.forEach((doc) => {
        const a = doc.data()
        a.id = doc.id
        resolve(a)
      })
    } else resolve()
  })
})

export const getJiraProject = (id) => new Promise((resolve, reject) => {
  db.collection('jiraProjects').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getJournalEntriesByPerson = (personID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournal')
  const queryRef = baseRef.where('personID', '==', personID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getJournalEntries = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournal')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getJournalEntriesByMonthYearAuthor = (month, year, author) => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournal')
  const queryRef = baseRef.where('month', '==', month).where('year', '==', year).where('authorID', '==', author)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getLearningJournalContent = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournalContent')
  baseRef.get().then((snapshot) => {
    // var objs = [];
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      resolve(d)
      // objs.push(d);
    })
    // resolve(objs);
    resolve() // allow completion when no record is found
  })
})

export const getLearningJournalContentByRole = (role) => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournalContent')
  const queryRef = baseRef.where('role', '==', role)
  queryRef.get().then((snapshot) => {
    // var objs = [];
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      resolve(d)
      // objs.push(d);
    })
    // resolve(objs);
  })
})

export const getLearningResources = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningResources')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getManagerNotes = (personID, managerID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ManagerNotes')
  const queryRef = baseRef.where('personID', '==', personID).where('managerID', '==', managerID)
  queryRef.get().then((snapshot) => {
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      resolve(d)
    })
    resolve()
  })
})

export const getMetric = (id) => new Promise((resolve, reject) => {
  db.collection('Metrics').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})
export const getMetricsByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Metrics')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getMetricsByTeam = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Metrics')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})
export const getMetricValues = (metricID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('MetricValues')
  const queryRef = baseRef.where('metricID', '==', metricID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getMemberships = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Memberships')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getMilestonesByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Milestones')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getMilestonesByTeam = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Milestones')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getNavigation = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Navigation')
  baseRef.get().then((snapshot) => {
    const objs = []

    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getNavItems = (parentID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Navigation')
  const queryRef = baseRef.where('parentID', '==', parentID)
  queryRef.get().then((snapshot) => {
    const objs = []

    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getNexusEndpoint = (id) => new Promise((resolve, reject) => {
  db.collection('NexusEndpoints').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id

    resolve(obj)
  })
})

export const getNexusEndpoints = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('NexusEndpoints')
  baseRef.orderBy('title', 'asc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getNexusIterations = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('NexusIterations')
  baseRef.orderBy('created', 'desc').limit(100).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getNexusLogsByIteration = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('NexusLogs')
  baseRef.orderBy('created', 'desc').where('iteration', '==', id).get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getNote = (id) => new Promise((resolve, reject) => {
  db.collection('Notes').doc(id).get().then((doc) => {
    const obj = doc.data()
    obj.id = doc.id
    resolve(obj)
  })
})
export const getNotesByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Notes')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})
export const getNotesByTeam = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Notes')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})
export const getOKR = (id) => new Promise((resolve, reject) => {
  db.collection('OKRs').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing okr:${id}`)
    resolve(obj)
  })
})

export const getOKRs = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('OKRs')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getOrganization = (id) => {
  console.log(`id:${id}`)
  return db.collection('Organizations').doc(id).get()
}

export const getPage = (id) => new Promise((resolve, reject) => {
  db.collection('Pages').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing page:${id}`)
    resolve(obj)
  })
})

export const getPages = (navItemID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Pages')
  const queryRef = baseRef.where('navItemID', '==', navItemID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getPeople = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('People').where('isActive', '==', true)
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getPeopleByManagerID = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('People')
  const queryRef = baseRef.where('managerID', '==', id).where('isActive', '==', true)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getPeopleActivityByAuthorID = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('PeopleActivity')
  const queryRef = baseRef.where('authorID', '==', id)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getPeopleActivityByManagerID = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('PeopleActivity')
  const queryRef = baseRef.where('managerID', '==', id)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      if (d.authorID === id || d.shareWithEmployee) objs.push(d)
    })
    resolve(objs)
  })
})

export const getPeopleActivityByPersonID = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('PeopleActivity')
  const queryRef = baseRef.where('personID', '==', id) // .where('shareWithEmployee','==', true);
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id

      if (d.shareWithEmployee || d.authorID === d.personID) objs.push(d)
    })
    resolve(objs)
  })
})

export const getPerson = (id) => new Promise((resolve, reject) => {
  db.collection('People').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing user:${id}`)
    resolve(obj)
  })
})

export const getPersonByEmail = (email) => new Promise((resolve, reject) => {
  const baseRef = db.collection('People')
  const queryRef = baseRef.where('email', '==', email).where('isActive', '==', true)
  queryRef.get().then((snapshot) => {
    if (snapshot.docs.length === 0) resolve(null)
    else {
      const doc = snapshot.docs[0]
      const obj = doc.data()
      if (obj) obj.id = doc.id
      resolve(obj)
    }
  })
})

export const getPersonByEmployeeID = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('People')
  const queryRef = baseRef.where('employeeID', '==', id)
  queryRef.get().then((snapshot) => {
    if (snapshot.docs.length === 0) resolve(null)
    else {
      const doc = snapshot.docs[0]
      const obj = doc.data()
      if (obj) obj.id = doc.id
      resolve(obj)
    }
  })
})

export const getProduct = (id) => db.collection('Products').doc(id).get()

export const getProducts = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Products')
  baseRef.orderBy('ProductName', 'asc').get().then((snapshot) => {
    const products = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      products.push(d)
    })
    resolve(products)
  })
})

export const getProject = (id) => new Promise((resolve, reject) => {
  db.collection('Projects').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    resolve(obj)
  })
})

export const getProjectBySlug = (slug) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Projects')
  const queryRef = baseRef.where('slug', '==', slug)
  queryRef.get().then((snapshot) => {
    if (snapshot.docs.length === 0) resolve(null)
    else {
      const doc = snapshot.docs[0]
      const obj = doc.data()
      if (obj) obj.id = doc.id
      resolve(obj)
    }
  })
})

export const getProjectMembers = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ProjectMembers')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const members = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      members.push(a)
    })
    resolve(members)
  })
})

export const getProjectMemberXrefs = () => new Promise((resolve, reject) => {
  db.collection('ProjectMembers').get().then((snapshot) => {
    const members = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      members.push(a)
    })
    resolve(members)
  })
})

export const getProjects = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Projects')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getProjectsByPerson = (personID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ProjectMembers')
  const queryRef = baseRef.where('personID', '==', personID)
  queryRef.get().then((snapshot) => {
    const projects = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      projects.push(a)
    })
    resolve(projects)
  })
})
export const getRisk = (id) => new Promise((resolve, reject) => {
  db.collection('Risks').doc(id).get().then((doc) => {
    const obj = doc.data()
    obj.id = doc.id
    resolve(obj)
  })
})
export const getRisks = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Risks')
  return baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})
export const getRisksByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Risks')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getStatusReport = (id) => new Promise((resolve, reject) => {
  db.collection('StatusReports').doc(id).get().then((doc) => {
    const obj = doc.data()
    obj.id = doc.id
    resolve(obj)
  })
})
export const getStatusReportsByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('StatusReports')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getPurchases = (orgID) => {
  const baseRef = db.collection('Purchases')
  const queryRef = baseRef.where('orgId', '==', orgID)
  return queryRef.orderBy('created', 'desc').get()
}

export const getQLSResponsesByUser = (userID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('QLS')
  const queryRef = baseRef.where('userID', '==', userID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getRecentlyUpdatedPeople = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('People')
  const queryRef = baseRef.where('isActive', '==', true).orderBy('modified', 'desc').limit(16)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getRecentlyUpdatedProjects = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Projects')
  const queryRef = baseRef.orderBy('modified', 'desc').limit(17)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getRecentlyUpdatedTeams = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Teams')
  const queryRef = baseRef.orderBy('modified', 'desc').limit(16)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getRelatedTeams = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('RelatedTeams')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const members = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      members.push(a)
    })
    resolve(members)
  })
})

export const getReviewIssues = (reviewID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('IssueReviews')
  const queryRef = baseRef.where('reviewID', '==', reviewID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getStandard = (id) => new Promise((resolve, reject) => {
  db.collection('Standards').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing standard:${id}`)
    resolve(obj)
  })
})

export const getStandardAdopter = (id) => new Promise((resolve, reject) => {
  db.collection('StandardAdoption').doc(id).get().then((doc) => {
    const a = doc.data()
    a.id = doc.id
    resolve(a)
  })
})
export const getStandardAdopterByTeam = (id) => new Promise((resolve, reject) => {
  const baseRef = db.collection('StandardAdoption')
  const queryRef = baseRef.where('team', '==', id)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getStandardAdoption = (standardID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('StandardAdoption')
  const queryRef = baseRef.where('standardID', '==', standardID)
  queryRef.get().then((snapshot) => {
    const teams = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      teams.push(a)
    })
    resolve(teams)
  })
})

export const getStandards = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Standards')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getStandardsByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('StandardsProjects')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const promises = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      promises.push(getStandard(d.standardID))
    })

    Promise.all(promises).then((standards) => {
      resolve(standards)
    })
  })
})

export const getStandardsByTeam = (id) => new Promise((resolve, reject) => {
  let baseRef = db.collection('Standards')
  baseRef.get().then((snapshot) => {
    const standards = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      standards.push(d)
    })

    baseRef = db.collection('StandardAdoption')
    const queryRef = baseRef.where('team', '==', id)
    queryRef.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const a = doc.data()
        a.id = doc.id

        for (let i = 0; i < standards.length; i++) {
          if (standards[i].id === a.standardID) {
            standards[i].adopter = a
            break
          }
        }
      })

      resolve(standards)
    })
  })
})

export const getSubTeams = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Teams')
  const queryRef = baseRef.where('parentID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const teams = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      teams.push(a)
    })
    resolve(teams)
  })
})

export const getTagsByType = (type) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Tags')
  const queryRef = baseRef.where('type', '==', type)
  queryRef.orderBy('name', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getTasks = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Tasks')
  return baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getTeam = (id) => new Promise((resolve, reject) => {
  db.collection('Teams').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    resolve(obj)
  })
})

export const getTeamBySlug = (slug) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Teams')
  const queryRef = baseRef.where('slug', '==', slug)
  queryRef.get().then((snapshot) => {
    if (snapshot.docs.length === 0) resolve(null)
    else {
      const doc = snapshot.docs[0]
      const obj = doc.data()
      if (obj) obj.id = doc.id
      resolve(obj)
    }
  })
})

export const getTeamMembers = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('TeamMembers')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const members = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      members.push(a)
    })
    resolve(members)
  })
})

export const getTeamOKRs = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('OKRs')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getTeamProjects = (teamID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('TeamProjects')
  const queryRef = baseRef.where('teamID', '==', teamID)
  queryRef.get().then((snapshot) => {
    const members = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      members.push(a)
    })
    resolve(members)
  })
})

export const getTeams = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Teams')
  return baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getTeamsByPerson = (personID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('TeamMembers')
  const queryRef = baseRef.where('personID', '==', personID)
  queryRef.get().then((snapshot) => {
    const teams = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      teams.push(a)
    })
    resolve(teams)
  })
})
export const getTeamsByProject = (projectID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('TeamProjects')
  const queryRef = baseRef.where('projectID', '==', projectID)
  queryRef.get().then((snapshot) => {
    const teams = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      teams.push(a)
    })
    resolve(teams)
  })
})

export const getTip = (id) => new Promise((resolve, reject) => {
  db.collection('Tips').doc(id).get().then((doc) => {
    const obj = doc.data()
    if (obj) obj.id = doc.id
    else console.log(`missing tip:${id}`)
    resolve(obj)
  })
})

export const getTips = () => new Promise((resolve, reject) => {
  const baseRef = db.collection('Tips')
  baseRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const getVideos = (orgID) => {
  const baseRef = db.collection('Videos')
  return baseRef.orderBy('Quarter', 'asc').orderBy('Week', 'asc').get()
}

export const getJournalEntriesBetween = (lowerBound, upperBound) => new Promise((resolve, reject) => {
  const baseRef = db.collection('LearningJournal')
  const queryRef = baseRef.where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.get().then((snapshot) => {
    const entries = []
    snapshot.forEach((doc) => {
      const e = doc.data()
      e.id = doc.id
      entries.push(e)
    })
    resolve(entries)
  })
})

export const updateAsanaMembership = (id, value) => {
  if (typeof id === 'undefined') return db.collection('asanaMemberships').add(value)
  return db.collection('asanaMemberships').doc(id).set(value)
}

export const updateAsanaProject = (id, value) => {
  if (typeof id === 'undefined') return db.collection('asanaProjects').add(value)
  return db.collection('asanaProjects').doc(id).set(value)
}

export const updateAsanaTeam = (id, value) => {
  if (typeof id === 'undefined') return db.collection('asanaTeams').add(value)
  return db.collection('asanaTeams').doc(id).set(value)
}

export const updateAsanaUser = (id, value) => {
  if (typeof id === 'undefined') return db.collection('asanaUsers').add(value)
  return db.collection('asanaUsers').doc(id).set(value)
}

export const updateAppreciation = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Appreciation').add(value)
  return db.collection('Appreciation').doc(id).set(value)
}

export const updateCareer = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Career').add(value)
  return db.collection('Career').doc(id).set(value)
}

export const updateCleverTapAccount = (id, value) => {
  if (typeof id === 'undefined') return db.collection('ctAccounts').add(value)
  return db.collection('ctAccounts').doc(id).set(value)
}
export const updateCleverTapEvent = (id, value) => {
  if (typeof id === 'undefined') return db.collection('ctEvents').add(value)
  return db.collection('ctEvents').doc(id).set(value)
}
export const updateCleverTapOwner = (id, value) => {
  if (typeof id === 'undefined') return db.collection('ctOwners').add(value)
  return db.collection('ctOwners').doc(id).set(value)
}

export const updateFiveDysfunctionsResponse = (id, value) => {
  if (typeof id === 'undefined') return db.collection('FiveDysfunctions').add(value)
  return db.collection('FiveDysfunctions').doc(id).set(value)
}

export const updateEntityFeedback = (id, value) => {
  if (typeof id === 'undefined') return db.collection('EntityFeedback').add(value)
  return db.collection('EntityFeedback').doc(id).set(value)
}

export const updateIssue = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Issues').add(value)
  return db.collection('Issues').doc(id).set(value)
}

export const updateIssueFollower = (id, value) => {
  if (typeof id === 'undefined') return db.collection('IssueFollowers').add(value)
  return db.collection('IssueFollowers').doc(id).set(value)
}

export const updateIssueReviews = (id, value) => {
  if (typeof id === 'undefined') return db.collection('IssueReviews').add(value)
  return db.collection('IssueReviews').doc(id).set(value)
}

export const updateIssueTickets = (id, value) => {
  if (typeof id === 'undefined') return db.collection('IssueTickets').add(value)
  return db.collection('IssueTickets').doc(id).set(value)
}

export const updateJACA = (id, value) => {
  if (typeof id === 'undefined') return db.collection('JACA').add(value)
  return db.collection('JACA').doc(id).set(value)
}

export const updateLearningJournal = (id, value) => {
  if (typeof id === 'undefined') return db.collection('LearningJournal').add(value)
  return db.collection('LearningJournal').doc(id).set(value)
}
export const updateLearningJournalContent = (id, value) => {
  if (typeof id === 'undefined') return db.collection('LearningJournalContent').add(value)
  return db.collection('LearningJournalContent').doc(id).set(value)
}
export const updateLearningResource = (id, value) => {
  if (typeof id === 'undefined') return db.collection('LearningResources').add(value)
  return db.collection('LearningResources').doc(id).set(value)
}

export const updateLocation = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Locations').add(value)
  return db.collection('Locations').doc(id).set(value)
}
export const updateManagerNotes = (id, value) => {
  if (typeof id === 'undefined') return db.collection('ManagerNotes').add(value)
  return db.collection('ManagerNotes').doc(id).set(value)
}
export const updateMercuryChart = (id, value) => {
  if (typeof id === 'undefined') return db.collection('mercuryCharts').add(value)
  return db.collection('mercuryCharts').doc(id).set(value)
}
export const updateMercuryDashboard = (id, value) => {
  if (typeof id === 'undefined') return db.collection('mercuryDashboards').add(value)
  return db.collection('mercuryDashboards').doc(id).set(value)
}
export const updateMetric = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Metrics').add(value)
  return db.collection('Metrics').doc(id).set(value)
}
export const updateMetricValue = (id, value) => {
  if (typeof id === 'undefined') return db.collection('MetricValues').add(value)
  return db.collection('MetricValues').doc(id).set(value)
}
export const updateMilestone = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Milestones').add(value)
  return db.collection('Milestones').doc(id).set(value)
}
export const updateNavigation = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Navigation').add(value)
  return db.collection('Navigation').doc(id).set(value)
}

export const updateNexusEndpoint = (id, value) => {
  if (typeof id === 'undefined') return db.collection('NexusEndpoints').add(value)
  return db.collection('NexusEndpoints').doc(id).set(value)
}

export const updateNote = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Notes').add(value)
  return db.collection('Notes').doc(id).set(value)
}

export const updateObjective = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Objectives').add(value)
  return db.collection('Objectives').doc(id).set(value)
}

export const updateOKR = (id, value) => {
  if (typeof id === 'undefined') return db.collection('OKRs').add(value)
  return db.collection('OKRs').doc(id).set(value)
}

export const updateOrganization = (id, value) => {
  if (id === 0) return db.collection('Organizations').add(value)
  return db.collection('Organizations').doc(id).set(value)
}

export const updatePerson = (id, value) => {
  try {
    if (typeof id === 'undefined') return db.collection('People').add(value)
    return db.collection('People').doc(id).set(value)
  } catch (e) {
    console.log('DB UPDATE ERROR - updatePerson()')
    console.log(e)
    return null
  }
}

export const updatePersonActivity = (id, value) => {
  try {
    if (typeof id === 'undefined') return db.collection('PeopleActivity').add(value)
    return db.collection('PeopleActivity').doc(id).set(value)
  } catch (e) {
    console.log('DB UPDATE ERROR - updatePersonActivity()')
    console.log(e)
    return null
  }
}

export const updatePage = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Pages').add(value)
  return db.collection('Pages').doc(id).set(value)
}

export const updateProject = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Projects').add(value)
  return db.collection('Projects').doc(id).set(value)
}

export const updateProjectMember = (id, value) => {
  if (typeof id === 'undefined') return db.collection('ProjectMembers').add(value)
  return db.collection('ProjectMembers').doc(id).set(value)
}

export const updateStatusReport = (id, value) => {
  if (typeof id === 'undefined') return db.collection('StatusReports').add(value)
  return db.collection('StatusReports').doc(id).set(value)
}

export const updateQLSResponse = (id, value) => {
  if (typeof id === 'undefined') return db.collection('QLS').add(value)
  return db.collection('QLS').doc(id).set(value)
}

export const updateRelatedTeam = (id, value) => {
  if (typeof id === 'undefined') return db.collection('RelatedTeams').add(value)
  return db.collection('RelatedTeams').doc(id).set(value)
}

export const updateReview = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Reviews').add(value)
  return db.collection('Reviews').doc(id).set(value)
}

export const updateRisk = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Risks').add(value)
  return db.collection('Risks').doc(id).set(value)
}

export const updateStandard = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Standards').add(value)
  return db.collection('Standards').doc(id).set(value)
}

export const updateStandardAdoption = (id, value) => {
  if (typeof id === 'undefined') return db.collection('StandardAdoption').add(value)
  return db.collection('StandardAdoption').doc(id).set(value)
}

export const updateTag = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Tags').add(value)
  return db.collection('Tags').doc(id).set(value)
}

export const updateTask = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Tasks').add(value)
  return db.collection('Tasks').doc(id).set(value)
}

export const updateTeam = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Teams').add(value)
  return db.collection('Teams').doc(id).set(value)
}

export const updateTeamMember = (id, value) => {
  if (typeof id === 'undefined') return db.collection('TeamMembers').add(value)
  return db.collection('TeamMembers').doc(id).set(value)
}

export const updateTeamProject = (id, value) => {
  if (typeof id === 'undefined') return db.collection('TeamProjects').add(value)
  return db.collection('TeamProjects').doc(id).set(value)
}

export const updateTip = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Tips').add(value)
  return db.collection('Tips').doc(id).set(value)
}

export const updateUserActivity = (id, value) => {
  if (typeof id === 'undefined') return db.collection('UserActivity').add(value)
  return db.collection('UserActivity').doc(id).set(value)
}

export const updateAlert = (id, value) => {
  if (typeof id === 'undefined') return db.collection('Alerts').add(value)
  return db.collection('Alerts').doc(id).set(value)
}

export const getKudosByCategory = (categoryID) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Appreciation')
  const queryRef = baseRef.where('category', '==', categoryID)
  queryRef.orderBy('createdOn', 'desc').get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const a = doc.data()
      a.id = doc.id
      objs.push(a)
    })
    resolve(objs)
  })
})

export const getPersonForKudos = (id) => // created to avoid x no. of console logs of missing user since getPerson in Kudos has been called in loop
  new Promise((resolve, reject) => {
    db.collection('People').doc(id).get().then((doc) => {
      const obj = doc.data()
      if (obj) obj.id = doc.id

      resolve(obj)
    })
  })

// export const getKudosByMonth = (lowerBound,upperBound) => { // to do for filter kudos by month
//   return new Promise(function (resolve, reject) {
//     var baseRef = db.collection('Appreciation');
//     var queryRef = baseRef.where('createdOn','>=',lowerBound).where('createdOn','<',upperBound);

//     queryRef.get().then(snapshot => {
//       var objs = [];
//       snapshot.forEach((doc) => {
//         var a = doc.data();
//         a.id = doc.id;
//         objs.push(a);
//       })
//       resolve(objs);
//     });
//   });
// }

export const getNexusIssuesWithPaginationNext = async (
    isPrevToNextPage,
    oldPage,
    newPage,
    lastIssueSnapshot,
    teams,
    filter,
    limit = ISSUES_PAGE_LIMIT,
) => {
  let calculatedLimit = Math.abs((newPage - oldPage) * limit)
  if (isPrevToNextPage) {
    calculatedLimit += limit - 1
  }

  let firstSnapshots = db.collection('Issues').where('createdBy', '==', 'Nexus')
  firstSnapshots = applyQueryFilterOnNexusIssues(filter, firstSnapshots)
  firstSnapshots = firstSnapshots.orderBy('created', 'desc')
      .startAfter(lastIssueSnapshot)
      .limit(calculatedLimit)
  let snapshot = await firstSnapshots.get()

  const index = snapshot.docs.length - 1
  let lastSnapshot = snapshot.docs[index]

  let nextSnapshots = db.collection('Issues').where('createdBy', '==', 'Nexus')
  nextSnapshots = applyQueryFilterOnNexusIssues(filter, nextSnapshots)
  nextSnapshots = nextSnapshots.orderBy('created', 'desc')

  if (isPrevToNextPage) {
    nextSnapshots = nextSnapshots.endAt(lastSnapshot).limitToLast(limit)
  } else {
    nextSnapshots = nextSnapshots.startAt(lastSnapshot)
        .limit(limit)
  }
  snapshot = await nextSnapshots.get()

  const issues = []
  snapshot.forEach((issue) => {
    const obj = buildIssue(issue.data(), teams)
    obj.id = issue.id
    issues.push(obj)
  })
  if (isPrevToNextPage) {
    [lastSnapshot] = snapshot.docs
  }

  return [issues, lastSnapshot]
}

export const getNexusIssuesWithPaginationPrev = async (
    isNextToPrevPage,
    oldPage,
    newPage,
    lastIssueSnapshot,
    teams,
    filter,
    limit = ISSUES_PAGE_LIMIT,
) => {
  let firstSnapshots = db.collection('Issues').where('createdBy', '==', 'Nexus')
  firstSnapshots = applyQueryFilterOnNexusIssues(filter, firstSnapshots)
  firstSnapshots = firstSnapshots.orderBy('created', 'desc')
      .endBefore(lastIssueSnapshot)
      .limitToLast(Math.abs((newPage - oldPage) * limit))
  let snapshot = await firstSnapshots.get()

  const index = isNextToPrevPage ? 0 : ISSUES_PAGE_LIMIT - 1
  let lastSnapshot = snapshot.docs[index]

  let nextSnapshots = db.collection('Issues').where('createdBy', '==', 'Nexus')
  nextSnapshots = applyQueryFilterOnNexusIssues(filter, nextSnapshots)
  nextSnapshots = nextSnapshots.orderBy('created', 'desc')
  if (isNextToPrevPage) {
    nextSnapshots = nextSnapshots.startAt(lastSnapshot).limit(limit)
  } else {
    nextSnapshots = nextSnapshots.endAt(lastSnapshot)
        .limitToLast(limit)
  }
  snapshot = await nextSnapshots.get()

  const issues = []
  snapshot.forEach((issue) => {
    const obj = buildIssue(issue.data(), teams)

    obj.id = issue.id
    issues.push(obj)
  })

  if (!isNextToPrevPage) {
    [lastSnapshot] = snapshot.docs
  }
  return [issues, lastSnapshot]
}

export const getNexusIssuesWithPagination = async (oldPage, newPage, filter, teams, limit = ISSUES_PAGE_LIMIT) => {
  let query = db.collection('Issues').where('createdBy', '==', 'Nexus')
  query = applyQueryFilterOnNexusIssues(filter, query)
  query = query.orderBy('created', 'desc')
      .limit(Math.abs((newPage - oldPage) * limit))

  const snapshot = await query.get()
  const lastSnapshot = snapshot.docs[0]

  const issues = []
  snapshot.forEach((issue) => {
    const obj = buildIssue(issue.data(), teams)
    obj.id = issue.id
    issues.push(obj)
  })

  return [issues, lastSnapshot]
}

export const getNexusIssuesBetween = (lowerBound, upperBound) => new Promise((resolve) => {
  const baseRef = db.collection('Issues')
  const queryRef = baseRef.where('createdBy', '==', 'Nexus')
      .where('created', '>=', lowerBound).where('created', '<', upperBound)
  queryRef.orderBy('created', 'desc').get().then((snapshot) => {
    const entries = []
    snapshot.forEach((doc) => {
      const e = doc.data()
      e.id = doc.id
      entries.push(e)
    })
    resolve(entries)
  })
})

export const getNexusFilters = async () => {
  const baseRef = db.collection('NexusFilters')
  const snapshot = await baseRef.get()
  const filters = []
  snapshot.forEach((key) => {
    filters.push(key.data())
  })

  return filters
}

export const getNexusAnalyticsData = async (filter) => {
  let baseRef = db.collection('NexusAnalyticsData')
  baseRef = applyQueryFilterOnNexusIssues(filter, baseRef, true)
  const snapshot = await baseRef.get()
  const data = []
  snapshot.forEach((key) => {
    data.push(key.data())
  })

  return data
}

export const getNexusIssuesByTeamID = (teamID, issueType) => new Promise((resolve, reject) => {
  const baseRef = db.collection('Issues')
  const queryRef = baseRef.where('createdBy', '==', 'Nexus').where(issueType, '==', teamID).orderBy('created', 'desc')
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const addMercuryFunnel = (funnel) => db.collection('mercuryFunnels').add(funnel)

export const getApprovalRquests = (email) => new Promise((resolve, reject) => {
  const baseRef = db.collection('ApprovalRequests')
  const queryRef = baseRef.where('approver', '==', email).orderBy('createdAt', 'desc')
  queryRef.get().then((snapshot) => {
    const objs = []
    snapshot.forEach((doc) => {
      const d = doc.data()
      d.id = doc.id
      objs.push(d)
    })
    resolve(objs)
  })
})

export const updateApprovalRquest = (id, value) => db.collection('ApprovalRequests').doc(id).set(value)
