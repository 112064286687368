import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap'

import logo from '../../assets/img/goconnect.png'
import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

class KudosWallNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
      showSettings: false,
      query: '',
      view: 'landing',
      othersPresent: [],
      redirectToHome: false,
      isPersonModalOpen: false,
    }

    this.landingPage = this.landingPage.bind(this)
    this.orgChart = this.orgChart.bind(this)
    this.productView = this.productView.bind(this)
    this.searchText = this.searchText.bind(this)
    this.setSearchTimer = this.setSearchTimer.bind(this)
    this.teamView = this.teamView.bind(this)
    this.toggleShowSettings = this.toggleShowSettings.bind(this)
    this.showPersonModal = this.showPersonModal.bind(this)
    this.closePersonModal = this.closePersonModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth > 693
      && e.history.location.pathname !== e.location.pathname
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }

    if (e.searchQuery !== this.props.searchQuery) {
      this.setState({ query: this.props.searchQuery })
    }

    if (e.orgChartView !== this.props.orgChartView
      || e.teamView !== this.props.teamView
      || e.productView !== this.props.productView) {
      if (this.props.orgChartView) {
        this.setState({ view: 'orgChart' })
      } else if (this.props.teamView) {
        this.setState({ view: 'team' })
      } else if (this.props.productView) {
        this.setState({ view: 'product' })
      }
    }

    if (e.othersPresent !== this.props.othersPresent) {
      this.setState({ othersPresent: this.props.othersPresent })
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth > 693 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
      })
    } else {
      this.setState({
        color: 'navbar-transparent',
      })
    }
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white'
    } else {
      newState.color = 'navbar-transparent'
    }
    this.setState(newState)
  }

  setSearchTimer(query) {
    clearTimeout(this.searchText)
    this.setState({ query }, () => {
      setTimeout(this.searchText, 1700)
    })
  }

  searchText() {
    this.props.onSearch(this.state.query)
  }

  toggleShowSettings() {
    this.setState({ showSettings: !this.state.showSettings })
  }

  landingPage() {
    this.setState({ query: '', view: 'landing' })
    // this.props.onLandingPageViewClick();
  }

  orgChart() {
    this.setState({ query: '', view: 'orgChart' })
    this.props.onOrgChartViewClick()
  }

  productView() {
    this.setState({ query: '', view: 'product' })
    this.props.onProductViewClick()
  }

  teamView() {
    this.setState({ query: '', view: 'team' })
    this.props.onTeamViewClick()
  }

  selectedView(value) {
    if (value === this.state.view) return 'green'
    return '#232323'
  }

  showOtherPeople() {
    if (this.state.othersPresent.length > 0) return 'list-item'
    return 'none'
  }

  redirectHome() {
    this.setState({ redirectToHome: true })
  }

  showPersonModal() {
    this.setState({
      isPersonModalOpen: true,
    })
  }

  closePersonModal() {
    this.setState({
      isPersonModalOpen: false,
    })
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to="/" />
    }

    return (
      <div>

        <Navbar
          className={classnames('navbar-absolute fixed-top', 'bg-white')}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{ cursor: 'pointer' }}>
              <div style={{ display: 'inline-block' }}>

                <img
                  onClick={() => this.redirectHome()}
                  src={logo}
                  alt="react-logo"
                  style={{
                    marginTop: '0px', marginBottom: '0px', marginLeft: '4px', marginRight: '10px', height: '34px', paddingRight: '0px',
                  }}
                />

              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <NavItem
                  style={{
                    display: this.showOtherPeople(),
                    backgroundImage: 'linear-gradient(to right, #ffffff, #efefef)',
                    borderRadius: '0px 10px 10px 0px',
                    marginRight: '20px',
                  }}
                  title="Other GoTroops here with you."
                >
                  <NavLink>
                    {this.state.othersPresent.map((person, index) => (
                      <div style={{ marginTop: '5px', marginRight: '10px', display: 'inline-block' }} key={index}>
                        <PersonCard person={person} showThumb />
                      </div>
                    ))}
                  </NavLink>
                </NavItem>

                <ProfileMenuDropdown user={this.props.user} showPersonModal={this.showPersonModal} />

                <PersonCard
                  person={this.props.user.person}
                  showPersonModal={this.state.isPersonModalOpen}
                  closePersonModal={this.closePersonModal}
                />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>

      </div>
    )
  }
}

const mapStateToProps = (state) => state

KudosWallNavbar = connect(mapStateToProps)(KudosWallNavbar)
export default KudosWallNavbar
