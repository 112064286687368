// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import React from 'react'

import { strings } from '../../assets/utils'
import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import { BACKGROUND_COLORS, sortObjectByIntValue } from './utils'

function getIssuesByPDG(issuesByPDG) {
  const chartData = {
    labels: [],
    data: [],
    topIssuesByPdg: [],
  }

  const sortedIssuesByPDG = sortObjectByIntValue(issuesByPDG)
  sortedIssuesByPDG.forEach((row) => {
    const [teamName, count] = row
    chartData.labels.push(strings.trim(teamName, 21))
    chartData.data.push(String(count))
    chartData.topIssuesByPdg.push({
      count,
      name: teamName,
    })
  })

  return {
    labels: chartData.labels,
    datasets: [
      {
        label: 'PDGs',
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: '#dee2e6',
        data: chartData.data,
        backgroundColor: BACKGROUND_COLORS.slice(0, issuesByPDG.length),
      },
    ],
    topIssuesByPdg: chartData.topIssuesByPdg,
  }
}

export default function IssuesReportByPDGPieChart({
  isDateRangeSelected, issuesByPDG, totalIssues, totalIssuesPast30Days,
}) {
  const issuesGroupedByPDG = getIssuesByPDG(issuesByPDG)
  const chartData = _.pick(issuesGroupedByPDG, ['labels', 'datasets'])

  const title = `${'Issues By PDG'} ${!isDateRangeSelected ? ' - Last 30 Days' : ''}`
  return (
    <DoughnutWithPercentage
      title={title}
      totalLabel="Total"
      totalRecords={!isDateRangeSelected ? totalIssuesPast30Days : totalIssues}
      sortedChartData={chartData}
      sortedRawData={issuesGroupedByPDG.topIssuesByPdg}
    />
  )
}
