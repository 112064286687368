import { db } from 'assets/firebase';
import { arraySort } from 'assets/utils';

export const getOthersPresent = (userID, pageID) => {

    return new Promise(function(resolve, reject) {
        db.getPresenceActivity(pageID).then((peopleIDs) => {

            var promises = [];
            peopleIDs.forEach(id => {
                if(id !== userID)
                    promises.push(db.getPerson(id));
            })

            Promise.all(promises).then(people => {

                people.sort(arraySort.byName);

                // filter out people who have been deleted 
                for(var i = people.length - 1; i > -1; i--) {
                    if(!people[i])
                        people.splice(i,1);
                }

                var totalOthers = 0;
                
                if(people.length > 17) {
                    totalOthers = people.length - 17;
                    people.splice(0,people.length-17);
                }

                resolve({people: people, totalOthers: totalOthers});
            });
        })
    })
}
