
import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { strings, tables, telemetry } from 'assets/utils';

import EndpointEditor from "components/Nexus/EndpointEditor.js";
import TeamCard from "components/TeamCard/TeamCard.js"; 

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table,
    Row, Col,
    Spinner,
    TabContent, TabPane, Nav, NavItem, NavLink
  } from "reactstrap";

  class Nexus extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        endpoints: [],
        filtered: [],
        teams: [],
        queryText: '',
        isLoading: true,
        activeTab: 'config',
        iterations: [],
        issues: [],
        methods: [],
        logEntries: [],
        filteredLogs: [],
        rowID: '',
        keyFilter: 'all',
        categoryFilter: 'all',
        methodFilter: 'all'
      }
    }

    componentDidMount() {

        db.getNexusEndpoints().then(endpoints => {

            var teams = [];
            endpoints.forEach(ep => {
                if(ep.teamID) {
                    var isFound = false;
                    for(var i = 0; i < teams.length; i++) {
                        if(ep.teamID === teams[i]) {
                            isFound = true;
                            break;
                        }
                    } 
                    if(!isFound)
                        teams.push(ep.teamID);
                }
            });

            var promises = [];
            teams.forEach(id => promises.push(db.getTeam(id)));
            
            Promise.all(promises).then(teams => {

                endpoints.forEach(ep => {

                    for(var j = 0; j < teams.length; j++) {
                        if(ep.teamID === teams[j].id) {
                            ep.team = teams[j];
                            break;
                        }
                    }
                });

                db.getTagsByType('nexusEndpoint').then(tags => {
                        
                    tags.forEach(tag => {
                        tag.name = tag.name.toLowerCase();
                    })
        
                    this.setState({endpoints: endpoints, teams: teams, tags: tags, isLoading: false}, this.filterEndpoints);
                });
            });            
        })

        // FIXME: commenting this as we are not Nexus logging data
        // this.getIterations();
        // setTimeout(() => this.getIterations(), 30000);

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "nexus", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({queryText: this.props.searchQuery}, () => {
              if(this.state.queryText.length > 0) {
                  //this.searchIssues();
              } else {
                  //this.filterIssues();
              }
            });
        }
    }

    getIterations() {
        db.getNexusIterations().then((iterations) => {
            if(iterations.length) {
                this.setState({iterations: iterations},() => {
                    if(!this.state.iterationFilter) 
                        this.selectIteration(iterations[0].iteration);
                });
            }
        });
    }

    selectIteration(iteration) {

        var issues = [];
        var methods = [];

        db.getNexusLogsByIteration(iteration).then(entries => {

            entries.forEach(entry => {
                var isFound = false;
                for(var i = 0; i < issues.length; i++) {
                    if(issues[i] === entry.key) {
                        isFound = true;
                        break;
                    }
                }
                if(!isFound)
                    issues.push(entry.key);

                isFound = false;
                for(var j = 0; j < methods.length; j++) {
                    if(methods[j] === entry.method) {
                        isFound = true;
                        break;
                    }
                }
                if(!isFound)
                    methods.push(entry.method);
            })
            console.log(methods);

            this.setState({iterationFilter: iteration, issues: issues, methods: methods, logEntries: entries}, this.filterEntries);
        })
    }

    filterEntries(filter, value) {

        var keyFilter = this.state.keyFilter;
        if(filter === 'key')
            keyFilter = value;
    
        var categoryFilter = this.state.categoryFilter;
        if(filter === 'category')
            categoryFilter = value;

        var methodFilter = this.state.methodFilter;
        if(filter === 'method')
            methodFilter = value;
    

        this.setState({categoryFilter: categoryFilter, keyFilter: keyFilter, methodFilter: methodFilter });

        var filtered = [];
        for(var i = 0; i < this.state.logEntries.length; i++) {
            if(this.state.logEntries[i].key === keyFilter || keyFilter === 'all')
                if(this.state.logEntries[i].category === categoryFilter || categoryFilter === 'all')
                    if(this.state.logEntries[i].method === methodFilter || methodFilter === 'all')
                        filtered.push(this.state.logEntries[i]);
            
        };

        this.setState({filteredLogs: filtered});
    }

    filterEndpoints() {
        var endpoints = this.state.endpoints;

        this.setState({filtered: endpoints, isLoading: false});
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading && this.state.filtered.length > 0)
            return "block";
        else 
            return "none";
    }
    showFilters() {
        return "none"; // until we add some real filters
        if(this.state.queryText.length > 0)
            return "none";
        else
            return "flex";
    }
    showNoResults() {
        if(this.state.filtered.length === 0 && !this.state.isLoading)
            return "block";
        else
            return "none";
    }

    endpointCreated(obj) {
        var objs = this.state.endpoints;
        objs.push(obj);
        this.setState({endpoints: objs}, () => this.filterEndpoints());
    }

    endpointDeleted(obj) {
        var objs = this.state.endpoints;
        for(var i = 0; i < objs.length; i++) {
            if(obj.id === objs[i].id) {
                objs.splice(i,1);
                break;
            }
        }
        this.setState({endpoints: objs}, this.filterEndpoints);
    }

    endpointUpdated(obj) {

        var teams = this.state.teams;
        for(var j = 0; j < teams.length; j++) {
            if(obj.teamID === teams[j].id) {
                obj.team = teams[j];
                break;
            }
        }

        var objs = this.state.endpoints;
        for(var i = 0; i < objs.length; i++) {
            if(obj.id === objs[i].id) {
                objs[i] = obj;
                break;
            }
        }
        this.setState({endpoints: objs}, this.filterEndpoints);
    }

    teamCard(team) {
        if(team) {
            return (
                <TeamCard team={team} showThumb />
            )
        }
    }

    // FIXME: use TagBadges instead
    displayTags(tagIDs) {

        if(tagIDs) {   
            if(Array.isArray(tagIDs)) {

                var tags = [];
                tagIDs.forEach(tagID => {
                    for(var i = 0; i < this.state.tags.length; i++) {
                        if(tagID === this.state.tags[i].id) {
                            tags.push(this.state.tags[i]);
                            break;
                        }
                    }
                })

                tags.sort(this.byName);
                
                return (
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                        {tags.map((tag, index) => (
                            <div key={index} style={{marginRight:"8px", marginBottom:"4px", display:"flex"}}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px"}} >{tag.name}</Badge>
                            </div>
                        ))}
                    </div>
                )
            } 
        } 
    }
    getStatusDescriptor(status) {
        if(status === 'disabled')
            return <div style={{color:"#666666"}}>Disabled</div>
        else if (status === 'enabled')
            return <div style={{color:"green"}}>Enabled</div>
    }

    showMeta(id){
        if(id === this.state.rowID)
            return "none";
        else 
            return "table-cell";
    }
    showJSON(id){
        if(id === this.state.rowID)
            return "table-cell";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        const noResultsDiv = {
            display: this.showNoResults(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        const resultCountDiv = {
            display: this.showResults(),
            textAlign: 'right',
            color:"gray",
            fontSize:"11px"
        }
        const link = {
            color:"#434343",
            fontWeight:500,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeLink = {
            color:"white",
            fontWeight:700,
            fontSize:"12px"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#dedede",
            marginLeft:"1px",   
        }

        return (
            <div style={{padding:"20px"}}>
                <Row>
                    <Col xs="12" >
                        
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="6">
                                        <CardTitle tag="h4">Nexus</CardTitle>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <div style={{textAlign:"right"}}>
                                            <EndpointEditor onCreated={(ep) => this.endpointCreated(ep)} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{minHeight:"500px"}}>
                                <Row>
                                    <Col xs="12">
                                    <Nav style={{marginTop:"10px"}}>
                                        <NavItem style={(this.state.activeTab === 'config' ? activeTab : tab )}>
                                            <NavLink
                                                style={(this.state.activeTab === 'config' ? activeLink : link )}
                                                onClick={() => this.setState({activeTab:'config'}) }
                                            >
                                                Configuration
                                            </NavLink>
                                        </NavItem>
                                        {/* FIXME: commenting this as we are not Nexus logging data */}
                                        {/* <NavItem style={(this.state.activeTab === 'activity' ? activeTab : tab )}>
                                            <NavLink
                                                style={(this.state.activeTab === 'activity' ? activeLink : link )}
                                                onClick={() => this.setState({activeTab:'activity'}) }
                                            >
                                                Activity
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>

                                    </Col>
                                </Row>
                                <TabContent activeTab={this.state.activeTab} style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#888888'}}>
                                    <TabPane tabId="config" style={{minHeight:"210px", padding:"17px"}}>

                                        <Row style={{display:this.showFilters()}}>
                                        <Col sm="6">
                                                PDG <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                            <i style={icon} className="nc-icon nc-app" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>  
                                                    <Input placeholder="" 
                                                        type="select">
                                                            <option>All</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <div style={loadingDiv}>
                                                    <div style={{marginTop:"89px"}}>
                                                        <Spinner color="success" />
                                                    </div>
                                                </div>
                                                <div style={noResultsDiv}>
                                                    <div style={{marginTop:"234px"}}>
                                                        No Results Found
                                                    </div>
                                                </div>
                                                <div style={resultCountDiv}>
                                                    <div>
                                                        {this.state.filtered.length} results
                                                    </div>
                                                </div>
                                                <div style={{display:this.showResults()}}>
                                                    <Table style={{borderColor:"green", width:"100%"}}>
                                                        <thead> 
                                                            <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                                <td></td>
                                                                <td>PDG</td>
                                                                <td>Status</td>
                                                                <td style={{textAlign:"center", maxWidth:"50px"}}>Team</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.filtered.map((endpoint, index) => (
                                                                <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                                                                    <td style={{verticalAlign:"top"}}>
                                                                        <EndpointEditor endpoint={endpoint} onUpdated={(ep) => this.endpointUpdated(ep)}/>
                                                                    </td>
                                                                    <td style={{verticalAlign:"top"}}>
                                                                        <div style={{fontWeight:"600", color:"green"}}>
                                                                            {endpoint.title}
                                                                        </div>
                                                                        {strings.trim(endpoint.description,123)}
                                                                        <div style={{fontWeight:600, marginTop:"10px", fontSize:"10px", color:"#696969"}}>
                                                                            {this.displayTags(endpoint.tags)}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{verticalAlign:"top"}}>
                                                                        {this.getStatusDescriptor(endpoint.status)}
                                                                    </td>
                                                                    <td style={{textAlign:"center", maxWidth:"50px"}}>
                                                                        {this.teamCard(endpoint.team)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="activity" style={{minHeight:"210px", padding:"17px"}}>
                                        <Row>
                                            <Col xs="3">
                                                Iteration <br />
                                                <Input placeholder="" 
                                                    type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                    value={this.state.iterationFilter} onChange={(e) => this.selectIteration(e.target.value)} >
                                                        {this.state.iterations.map((iteration, index) => (
                                                            <option key={index}>{iteration.iteration}</option>
                                                        ))}
                                                </Input>
                                                <br />
                                            </Col>
                                            <Col xs="3">
                                                Issue <br />
                                                <Input placeholder="" 
                                                    type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                    value={this.state.keyFilter} onChange={(e) => this.filterEntries("key", e.target.value)} >
                                                        <option value='all'>All</option>
                                                        {this.state.issues.map((issue, index) => (
                                                            <option key={index}>{issue}</option>
                                                        ))}
                                                </Input>
                                                <br />
                                            </Col>
                                            <Col xs="3">
                                                Method <br />
                                                <Input placeholder="" 
                                                    type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                    value={this.state.methodFilter} onChange={(e) => this.filterEntries("method", e.target.value)} >
                                                        <option value='all'>All</option>
                                                        {this.state.methods.map((method, index) => (
                                                            <option key={index}>{method}</option>
                                                        ))}
                                                </Input>
                                                <br />
                                            </Col>
                                            <Col xs="3">
                                                Category <br />
                                                <Input placeholder="" 
                                                    type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                    value={this.state.categoryFilter} onChange={(e) => this.filterEntries("category", e.target.value)} >
                                                        <option value='all'>All</option>
                                                        <option value='info'>Info</option>
                                                        <option value='error'>Error</option>
                                                </Input>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Table style={{borderColor:"green", width:"100%", tableLayout:"fixed"}}>
                                                <thead> 
                                                    <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                        <td>Created</td>
                                                        <td>Key</td>
                                                        <td>Category</td>
                                                        <td>Method</td>
                                                        <td>Details</td>
                                                        <td></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filteredLogs.map((entry, index) => (
                                                        <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"13px"}}>
                                                            <td style={{minWidth:"80px", verticalAlign:"top"}} onClick={() => this.setState({rowID: ""})}>
                                                                {entry.created}
                                                            </td>
                                                            <td style={{minWidth:"80px", verticalAlign:"top"}} onClick={() => this.setState({rowID: ""})}>
                                                                {entry.key}
                                                            </td>
                                                            <td style={{verticalAlign:"top", display:this.showMeta(entry.id)}}>
                                                                {entry.category}
                                                            </td>
                                                            <td style={{verticalAlign:"top", display:this.showMeta(entry.id)}}>
                                                                {entry.method}
                                                            </td>
                                                            <td style={{verticalAlign:"top", display:this.showMeta(entry.id)}}>
                                                                {entry.details}
                                                            </td>
                                                            <td style={{verticalAlign:"top", display:this.showMeta(entry.id)}}>
                                                                <div style={{color:"green", cursor:"pointer"}} onClick={() => this.setState({rowID: entry.id})}>JSON</div>
                                                            </td>
                                                            <td colSpan="4" style={{display:this.showJSON(entry.id), wordWrap:"break-word", fontFamily:"consolas", backgroundColor:"green", color:"white"}}>
                                                                {JSON.stringify(entry.json)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Nexus = connect(mapStateToProps)(Nexus);
export default Nexus;