
export const timeConverter = (UNIX_timestamp) => {

  //UNIX_timestamp = UNIX_timestamp * 1000;
    var a = new Date(UNIX_timestamp);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    if(min < 10) min = '0' + min;
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
    
    return time;

}

export const dateConverter = (UNIX_timestamp) => {

    var a = new Date(UNIX_timestamp);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    
    return month + ' ' + date + ', ' + year;

}

export const mmdd = (UNIX_timestamp) => {

  var a = new Date(UNIX_timestamp);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  
  var month = months[a.getMonth()];
  var date = a.getDate();
  
  return month + ' ' + date;

}

export const toHoursAndMinutes = (totalMinutes) => {
  //totalMinutes = 23.6666;
  const minutes = (totalMinutes % 60).toFixed(0);
  const hours = Math.floor(totalMinutes / 60).toFixed(0);

  //console.log('minutes', minutes);

  return `${padTo2Digits(hours)} hours, ${padTo2Digits(minutes)} minutes`;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const toDaysAndHours = (totalMilliSeconds) => {
  const days = Math.floor(totalMilliSeconds / (24*60*60*1000));
  const hours = Math.floor((totalMilliSeconds / (60*60*1000)) % 24);

  const daysAndHours = days !== 0 ? (hours !== 0 ? `${days}d ${hours}h` : `${days}d`) : (hours !== 0 ? `${hours}h` : '');
  return daysAndHours;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
