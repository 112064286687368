import React from 'react'
import { Col, Row } from 'reactstrap'

import { dateTime } from '../../../assets/utils'
import TagBadges from '../../Tag/TagBadges'
import iconStyle from '../constants'

function OverviewTab({
  isLoading, projectRef, allTagsRef,
}) {
  function getProjectURL() {
    return `https://gocnnct.com/projects/${projectRef.slug}`
  }

  function getLastModified() {
    if (projectRef.modifiedBy) {
      return `Last modified on ${dateTime.timeConverter(projectRef.modified)} by ${projectRef.modifiedBy}`
    }

    return `Last modified on ${dateTime.timeConverter(projectRef.modified)}`
  }

  function getAsanaLink() {
    return projectRef.asanaProjectID && `https://app.asana.com/0/${projectRef.asanaProjectID}`
  }

  // FIXME: This might not work for GTF / Tokopedia
  function getJiraLink() {
    return projectRef.jiraProjectKey && `https://go-jek.atlassian.net/browse/${projectRef.jiraProjectKey}`
  }

  function displayStatusDescriptor() {
    switch (projectRef.status) {
      case 'active':
        return <div style={{ color: 'green' }}>Active</div>
      case 'cancelled':
        return <div style={{ color: 'maroon' }}>Cancelled</div>
      case 'paused':
        return <div style={{ color: 'orange' }}>Paused</div>
      case 'completed':
        return <div style={{ color: 'green' }}>Completed</div>
      default:
    }
  }

  return !isLoading && projectRef && (
    <>
      <br />

      <Row>
        <Col xs="1">
          <i style={iconStyle} className="nc-icon nc-share-66" title="Project URL" />
        </Col>
        <Col xs="11">
          <a href={getProjectURL()} target="_new">{getProjectURL()}</a>
        </Col>
      </Row>
      <br />

      <Row>
        <Col xs="1">
          <i style={iconStyle} className="nc-icon nc-sound-wave" title="Project Status" />
        </Col>
        <Col xs="11">
          {displayStatusDescriptor()}
        </Col>
      </Row>
      <br />

      <Row>
        <Col xs="1">
          <i style={iconStyle} className="nc-icon nc-briefcase-24" title="Project Description" />
        </Col>
        <Col xs="11">
          {projectRef.description}
        </Col>
      </Row>
      <br />

      {projectRef.asanaProjectID && (
        <>
          <Row style={{ display: 'flex' }}>
            <Col xs="1">
              <i style={iconStyle} className="nc-icon nc-globe" title="Asana Page URL" />
            </Col>
            <Col xs="11">
              <div style={{ display: 'inline-block' }}>
                <a href={getAsanaLink()} target="_new">Asana</a>
              </div>
            </Col>
          </Row>
          <br />
        </>
      )}

      {projectRef.jiraProjectKey && (
        <>
          <Row style={{ display: 'flex' }}>
            <Col xs="1">
              <i style={iconStyle} className="nc-icon nc-globe" title="JIRA Page URL" />
            </Col>
            <Col xs="11">
              <div style={{ display: 'inline-block' }}>
                <a href={getJiraLink()} target="_new">JIRA</a>
              </div>
            </Col>
          </Row>
          <br />
        </>
      )}

      {projectRef.confluence && (
        <>
          <Row style={{ display: 'flex' }}>
            <Col xs="1">
              <i style={iconStyle} className="nc-icon nc-globe" title="Confluence Page URL" />
            </Col>
            <Col xs="11">
              <a href={projectRef.confluence} target="_new">Confluence</a>
            </Col>
          </Row>
          <br />
        </>
      )}

      {projectRef.tags && projectRef.tags.length > 0 && (
        <>
          <Row style={{ display: 'flex' }}>
            <Col xs="1">
              <i style={iconStyle} className="nc-icon nc-tag-content" title="Project Tags" id="TooltipProjectTags" />
            </Col>
            <Col xs="11">
              <TagBadges
                tagIDs={projectRef.tags}
                allTagsRef={allTagsRef}
              />
            </Col>
          </Row>
          <br />
        </>
      )}

      <Row>
        <Col xs="1">
          <i style={iconStyle} className="nc-icon nc-calendar-60" title="Last Modified" />
        </Col>
        <Col xs="11" className="content">
          <span style={{ color: '#898989' }}>{getLastModified()}</span>
        </Col>
      </Row>
      <br />

    </>
  )
}
export default OverviewTab
