import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { strings, telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import PersonSearch from "components/PersonSearch/PersonSearch.js";  
import TeamCard from "components/TeamCard/TeamCard.js";
import TeamSearch from "components/TeamSearch/TeamSearch.js"; 


class StandardAdopter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            adopter: {
                notes: '',
                owner: '',
                team: '',
                status: ''
            },
            owner: {},
            team: {},
            standard: props.standard,
            openPersonSearch: false,
            openTeamSearch: false,
        }

    }

    componentDidMount() {
        this.getAdopter();
        this.getOwner();
        this.getTeam();

        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standardAdopter", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getAdopter() {
        if(this.props.adopter) {   
            if(this.props.adopter.id) {   
                db.getStandardAdopter(this.props.adopter.id).then(adopter => {
                    
                    if(adopter.status === 'inProgress')
                        adopter.status = 'onTrack';

                    this.setState({adopter: adopter});
                });
            } else {
                this.setState({adopter: this.props.adopter});
            }
        }
    }

    getOwner() {
        if(this.props.adopter) {
            if(this.props.adopter.owner) {   
                db.getPerson(this.props.adopter.owner).then((owner => {
                    if(owner)
                        this.setState({owner: owner});
                }));
            }
        }
    }

    getTeam() {
        if(this.props.adopter) {
            if(this.props.adopter.team) {   
                db.getTeam(this.props.adopter.team).then((team => {
                    if(team)
                        this.setState({team: team});
                    else
                        console.log('missing team:',this.props.adopter.team)
                }));
            }
        }
    }

    updateAdopter(property, value) {
        var obj = this.state.adopter;
        obj[property] = value;
        this.setState({adopter: obj});
    }

    saveAdopter() {

        var adopter = this.state.adopter;
        
        if(!adopter.created) { 
            adopter.created = new Date().getTime(); 
            adopter.createdBy = this.props.user.person.name;
        }

        adopter.modified = new Date().getTime(); 
        adopter.modifiedBy = this.props.user.person.name;

        adopter.standardID = this.props.standard.id;


        db.updateStandardAdoption(adopter.id, adopter).then((result) => {

            if(this.props.onCreated) { // 
                adopter.id = result.id;
                this.props.onCreated(adopter);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standardAdopter", "createAdopter");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standardAdopter", "updateAdopter");
            }

            if(this.props.onCreated) { // reset for new standard

                this.setState({ 
                    adopter: {
                        notes: '',
                        owner: '',
                        team: '',
                        status: ''
                    },
                    team: {},
                    owner: {}
                    
                })

            } else if (this.props.onUpdated) {
                this.props.onUpdated(adopter);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    assignOwner(person) {
        this.updateAdopter("owner", person.id);
        this.setState({owner: person});
    }
    assignTeam(team) {
        this.updateAdopter("team", team.id);
        this.setState({team: team});
    }

    openPeopleSearch() {
          
        this.setState({openPersonSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPersonSearch: false});
            }, 500);
        });
    
    }
    openTeamSearch() {
        this.setState({openTeamSearch: true}, () => {
            setTimeout(() => {
                this.setState({openTeamSearch: false});
            }, 500);
        });
    }

    ownerCard() {
        if(this.state.owner.id) {
            return (
                <PersonCard person={this.state.owner} showThumb />
            )
        }
    }
    teamCard() {
        if(this.state.team.id) {
            return (
                <TeamCard team={this.state.team} showThumb />
            )
        }
    }

    showButton() {
        if(this.props.onCreated)
            return "block";
        else
            return "none";
    }

    showRow() {
        if(this.props.onUpdated)
            return "block";
        else
            return "none";
    }

    getStatusText(value) {
        if(value === 'planning')
            return <div style={{color:'#343434'}}>PLANNING</div>
        else if(value === 'agreed')
            return <div style={{color:'#343434'}}>AGREED</div>
        else if (value === 'inProgress') 
            return <div style={{color:'green'}}>IN PROGRESS</div>
        else if (value === 'onTrack') 
            return <div style={{color:'green'}}>ON TRACK</div>
        else if (value === 'atRisk') 
            return <div style={{color:'green'}}>AT RISK</div>
        else if (value === 'adopted') 
            return <div style={{color:'green'}}>ADOPTED</div>
        else if (value === 'refused') 
            return <div style={{color:'maroon'}}>REFUSED</div>
        else if (value === 'notApplicable') 
            return <div style={{color:'gray'}}>N/A</div>
        else 
            return <div style={{color:"maroon"}}>NOT STARTED</div>
    }

    render() {

        const iconTextArea = {
            fontSize:"17px",
            position:"absolute",
            top:"20px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              
              <div style={{display:this.showRow(), fontSize:"12px", width:"100%"}}>
                <Row style={{padding:"6px"}}>
                    <Col xs="2" >
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            Edit
                        </Button> 
                    </Col>

                    <Col xs="4">
                        <div style={{display:"inline-block", maxWidth:"30px", paddingTop:"8px"}}>
                            {this.teamCard()}
                        </div>
                        <div style={{display:"inline-block", marginLeft:"13px", position:"absolute", top:"12px"}}>
                            {strings.trim(this.state.team.name,17)}
                        </div>
                    </Col>

                    <Col xs="4" style={{verticalAlign:"center"}}>
                        <div style={{display:"inline-block", maxWidth:"30px", paddingTop:"8px"}}>
                            {this.ownerCard()}
                        </div>
                        <div style={{display:"inline-block", marginLeft:"13px", position:"absolute", top:"13px"}}>
                            {strings.trim(this.state.owner.name,23)}
                        </div>
                    </Col>

                    <Col xs="2" style={{paddingTop:"5px", fontSize:"11px"}}>
                        {this.getStatusText(this.state.adopter.status)}
                        {this.state.adopter.targetDate}
                    </Col>
                </Row>

              </div>
              <div style={{display:this.showButton()}}>
                <Button size="sm" onClick={() => this.toggleModal()} >
                    New Adopter
                </Button> 
              </div>
                    
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Adoption
                            </Col>
                        </Row>   
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs="12" style={{marginBottom:"15px"}}>
                                Initiative / Standard
                                <div style={{color:"green", fontWeight:600}}>
                                    {this.state.standard.title}        
                                </div>
                            </Col>
                            <Col xs="12" style={{marginBottom:"15px"}}>
                                Team
                                <div style={{color:"green", fontWeight:600}}>
                                    {this.state.team.name}        
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/*}
                            <Col xs="12" md="6">
                                Team <br />
                                <div onClick={() => this.openTeamSearch()
                                    style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                        
                                    </div>
                                    <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px", whiteSpace: 'nowrap'}}>
                                        {strings.trim(this.state.team.name,34)}
                                    </div>
                                    <div style={{display:"none"}}>
                                        <TeamSearch label="Assign Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.assignTeam(t)} />
                                    </div>
                                  
                                </div>
                                <br />
                            </Col>  */}
                            {/* <Col xs="6" md="4">
                                Owner <br />
                                <div onClick={() => this.openPeopleSearch()} 
                                    style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                    <div style={{display:"inline-block", verticalAlign:"middle", float: "left"}}>
                                        {this.ownerCard()}
                                    </div>
                                    <div style={{width: "75%", display:"inline-block", marginLeft:"10px", verticalAlign:"middle", color:"#666666", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        {this.state.owner.name}
                                    </div>
                                    <div style={{display:"none"}}>
                                        <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignOwner(p)} />
                                    </div>
                                </div>
                                <br />
                            </Col> */}
                            <Col xs="12" sm="4" >
                                Status <br />  
                                <Input placeholder="" 
                                    type="select" style={{height:"42px"}}
                                    value={this.state.adopter.status} 
                                    onChange={(e) => this.updateAdopter("status", e.target.value)} >
                                        <option value="">Not Started</option>
                                        <option value="planning">Planning</option>
                                        <option value="agreed">Agreed</option>
                                        <option value="onTrack">On Track</option>
                                        <option value="atRisk">At Risk</option>
                                        <option value="adopted">Fully Adopted</option>
                                        <option value="notApplicable">Not Applicable</option>
                                        <option value="refused">Refused</option>
                                </Input>
                                <br />
                            </Col> 
                            <Col xs="12" sm="4" >
                                Target Date <br />  
                                <Input
                                    type="date"
                                    placeholder=""
                                    value={this.state.adopter.targetDate}
                                    onChange={(e) => this.updateAdopter("targetDate", e.target.value)}
                                    style={{height:"42px"}}
                                />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                Notes <br />
                                <Input placeholder="" 
                                    type="textarea" style={{minHeight:"150px", lineHeight:"19px", padding:"10px"}}
                                    value={this.state.adopter.notes} onChange={(e) => this.updateAdopter("notes", e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <br />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="12" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveAdopter()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    StandardAdopter = connect(mapStateToProps)(StandardAdopter);
    export default StandardAdopter;
    