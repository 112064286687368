import React from "react";

import { 
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, Spinner
} from "reactstrap";

import OKRCard from './OKRCard.js';

import { algolia } from 'assets/algolia';


class OKRSearch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            queryText: '',
            searchResults: [],
            initialSearch: false,
            isLoading: false
        }

        this.searchOKRs = this.searchOKRs.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isOpen !== this.props.isOpen) {
            if(this.props.isOpen) {
                this.setState({modal: true});    
            }
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    setSearchTimer(queryText) {
        clearTimeout(this.searchOKRs);
        this.setState({isLoading: true, queryText}, () => {
            setTimeout(this.searchOKRs,2300);
        });
    }
    
    searchOKRs() {
        this.setState({searchResults: []}, () => {
            if(this.state.queryText.length > 0) {
                algolia.searchOKRs(this.state.queryText).then(results => {
                    console.log(results)
                    this.setState({searchResults: results, initialSearch: true, isLoading: false}); 
                });
            }
        });
    }

    okrChosen(okr) {
        this.props.onOKRChosen(okr);
        this.setState({searchResults:[], queryText: '', modal:false});
    }

    krChosen(okr, kr) {
        this.props.onKRChosen(okr, kr);
        this.toggleModal();
        this.setState({initialSearch: false, queryText:'', searchResults: []})
    }

    showAfterSearch() {
        if(this.state.initialSearch)
            return "flex";
        else
            return "none";
    }

    showWhenResults() {
        if(this.state.searchResults.length > 0)
            return "flex";
        else
            return "none";
    }
    showWhenNoResults() {
        if(this.state.initialSearch && this.state.searchResults.length === 0)
            return "flex";
        else
            return "none";
    }
    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" autoFocus={false} >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm={12}>
                                <br />OKR Search<br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-zoom-split" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input autoFocus={true} placeholder="search by text" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{display:this.showWhenResults()}}>
                            
                            {this.state.searchResults.map((okr, index) => (
                                <Col sm={12} key={index}>
                                    <OKRCard okr={okr} onKRSelected={(okr, kr) => this.krChosen(okr, kr)} />
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default OKRSearch;