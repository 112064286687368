import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { arraySort, tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Button,
    Card, CardBody, CardTitle, 
    Row, Col,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Spinner, Table,
    TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";

import PersonCard from 'components/PersonCard/PersonCard.js';
import PersonSearch from 'components/PersonSearch/PersonSearch.js';


class QLSReporting extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          isLoading: false,
          openPersonSearch: false,
          managerChosen: false,
          selectedQuarter: '2021q2',
          manager: {
              name: ''
          },
          results: [],
          resultsLoaded: false,
          sendingReminders: false,
          activeTab: 'responseRates',
          heatMaps: [],
          details: []
        }
    }

    componentDidMount() {

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "qlsReporting", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }


    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(this.state.resultsLoaded)
            return "block";
        else 
            return "none";
    }
    openPersonSearch() {  
        this.setState({openPersonSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPersonSearch: false});
            }, 500);
        });
    }

    managerChosen(manager) {
        this.setState({manager: manager, results:[], managerChosen: true, isLoading: true, resultsLoaded: false}, () => {
           
            this.getResponseRates(manager.id, this.state.selectedQuarter).then(results => {
                //results.sort(arraySort.byName);
                this.setState({results: results, isLoading: false, resultsLoaded: true});
            })

            func.getQLSResults(manager.id, manager.name, 'organisation', this.state.selectedQuarter).then(result => {
                if(result.data) {
                    console.log(result.data)
                    this.setState({details: result.data});
                    //console.log(result);
                 //   resolve(result.data);
                }
                else {
                    console.log(result);
                   // resolve([]);
                } 
            });
           
            func.getQLSResultHeatMaps(manager.id, manager.name, 'organisation', this.state.selectedQuarter).then(result => {
                console.log(result)
                this.setState({heatMaps: result.heatMaps});
            });
        });
    }

    getOrg(managerID, name) {
        
        return new Promise((resolve, reject) => {

            db.getPeopleByManagerID(managerID).then(directReports => {

                var promises = [];

                if(directReports.length > 0) {
   
                    var org = [];
                    if(directReports.length > 0)
                        org.push({id: managerID, name: name, directReports: directReports.length});

                    directReports.forEach(report => {
                        promises.push(this.getOrg(report.id, report.name));
                    });
                }

                Promise.all(promises).then((results) => {

                    results.forEach(result => {
                        if(result)
                            result.forEach(row =>
                                org.push(row));
                            
                    });                  

                    resolve(org);
                });
            });
        });
    }

    getResponseRates(managerID, quarter) {

        return new Promise((resolve, reject) => {
            func.getQLSResponseRates(managerID, this.state.manager.name, quarter).then(result => {
                if(result.data) {
                    this.setState({responseCount: result.responseCount});
                    resolve(result.data);
                }
                else {
                    console.log(result);
                    resolve([]);
                } 
            });
        });
    }

    exportToCSV() {
        var rows = this.state.results;
  
        let filename = "qlsResponseRates.csv";
        let contentType = "text/csv;charset=utf-8;";
      
        var content = "Name, Team, Responses, Org, Responses \n";
        for(var i = 0; i < rows.length; i++) {
          content = content + rows[i].managerName.replace(/,/g, "") + ', ' + rows[i].teamSize + ', ' + rows[i].teamResponses  + ', ' + rows[i].orgSize + ', ' + rows[i].orgResponses + '\n';
        }
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
    }

    exportDetailsToCSV() {
        var rows = this.state.details;
  
        let filename = this.state.manager.name + " QLS Detailed Results.csv";
        let contentType = "text/csv;charset=utf-8;";
      
        var content = "Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, Q10, Q11, Q12, Q13 \n";
        for(var i = 0; i < rows.length; i++) {
          content = content + rows[i].q1  + ', ' + rows[i].q2 + ', ' + rows[i].q3 + ', ' + rows[i].q4 + ', ' + rows[i].q5 + ', ' + rows[i].q6 + ', ' + rows[i].q7 + ', ' + rows[i].q8 + ', ' + rows[i].q9 + ', ' + rows[i].q10 + ', ' + rows[i].q11 + ', ' + rows[i].q12 + ', ' + rows[i].q13 + '\n';
        }
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
    }

    exportHeatMapToCSV() {
        var rows = this.state.heatMaps;
  
        let filename = this.state.manager.name + " QLS Top Box Scores.csv";
        let contentType = "text/csv;charset=utf-8;";
      
        var content = "Name, Responses, Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, Q10, Q11, Q12, Q13 \n";
        for(var i = 0; i < rows.length; i++) {
          content = content + rows[i].managerName.replace(/,/g, "") + ', ' + rows[i].totalResults + ', ' + rows[i].q1  + ', ' + rows[i].q2 + ', ' + rows[i].q3 + ', ' + rows[i].q4 + ', ' + rows[i].q5 + ', ' + rows[i].q6 + ', ' + rows[i].q7 + ', ' + rows[i].q8 + ', ' + rows[i].q9 + ', ' + rows[i].q10 + ', ' + rows[i].q11 + ', ' + rows[i].q12 + ', ' + rows[i].q13 + '\n';
        }
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
    }

    sendReminders() {
        this.setState({sendingReminders: true}, () => {
            func.sendQLSReminders(this.state.manager.id, this.state.manager.name, this.state.manager.slackID, this.state.selectedQuarter).then(result => {

                this.setState({sendingReminders: false});
            });
        });
    }

  
    managerCard() {
        if(this.state.managerChosen) {
            return (
                <PersonCard person={this.state.manager} showThumb />
            )
        }
    }
    
    rowWeight(result) {
        if(result.managerID === this.state.manager.id)
            return 600;
        else
            return 400;
    }
    
    getCellColor(people, responses) {
        
        if(responses < people * 0.6)
            return "maroon";
        else if(responses < people * 0.8)
            return "orange";
        else 
            return "green";
    }
    
    disabled() {
        if(this.state.response.isFinal)
            return "disabled";
        else
            return "";
    }
    showExport() {
        if(this.state.results.length > 0)
            return "block";
        else
            return "none";
    }
    showReminders() {
        if(this.state.results.length > 0)
            return "block";
        else
            return "none";
    }
    showRemindersButton() {
        if(!this.state.sendingReminders)
            return "block";
        else
            return "none";
    }
    showRemindersWorking() {
        if(this.state.sendingReminders)
            return "block";
        else
            return "none";
    }

    detailCellColor(value) {
        if(value > 3)
            return 'rgba(0, 255, 0, 0.4)';
        else if (value > 2)
            return 'rgba(255, 215, 0, 0.4)';
        else if (value > 0)
            return 'rgba(255, 0, 0, 0.4)';
        else 
            return 'rgba(0, 0, 0, 0.2)';
    }

    cellColor(percentage) {
        if(percentage > 79)
            return 'rgba(0, 255, 0, 0.4)';
        else if (percentage > 69)
            return 'rgba(255, 215, 0, 0.4)';
        else 
            return 'rgba(255, 0, 0, 0.4)';
    }

    openManagerPage(id) {
        window.open('/manager/' + id);
    }


    render() {

        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }

        return (
            <div style={{padding:"20px", paddingTop:"0px"}}>
            
                <Row>
                    <Col xs="12">
                        <CardTitle tag="h4">Quarterly Leadership Survey</CardTitle>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" >
                        
                        <Card style={{padding:"20px"}}>
                            <CardBody style={{minHeight:"180px", padding:"23px"}}>
                                <Row>
                                    <Col xs="6" sm="3" >
                                        Quarter <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-calendar-60" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.selectedQuarter} onChange={(e) => this.getManagerFeedback("quarter", e.target.value)} >
                                                    <option value="2021q2">2021 Q2</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    {/* <Col xs="6" sm="4" >
                                        <div style={{paddingLeft:"23px"}}>
                                            Manager <br />
                                            <div onClick={() => this.openPersonSearch()} 
                                                style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", minHeight:"41px", cursor:"pointer"}}>
                                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                    {this.managerCard()}
                                                </div>
                                                <div style={{display:"inline-block", marginLeft:"10px"}}>
                                                    {this.state.manager.name}
                                                </div>
                                                <div style={{display:"none"}}>
                                                    <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.managerChosen(p)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col xs="12" sm="4" style={{display:this.showReminders()}}>
                                        <br />
                                        <div style={{display:this.showRemindersButton()}}>
                                            <Button size="sm" color="secondary" onClick={() => this.sendReminders()}>Send Slack Reminders</Button>
                                        </div>
                                        <div style={{display:this.showRemindersWorking()}}>
                                            <Spinner color="success" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div style={loadingDiv}>
                                            <div style={{marginTop:"89px"}}>
                                                <Spinner color="success" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{display:this.showResults()}}>
                                    <Col xs={12}>

                                        <Nav>
                                            <NavItem>
                                                <NavLink
                                                    style={(this.state.activeTab === 'responseRates' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'responseRates'}) }}>
                                                    Response Rates
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={(this.state.activeTab === 'responses' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'responses'}) }}>
                                                    Org Responses
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={(this.state.activeTab === 'heatMap' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeTab: 'heatMap'}) }}>
                                                    Org Heat Map
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={this.state.activeTab} style={{paddingLeft:"27px", paddingRight:"27px"}}>
                                            <TabPane tabId="responseRates" style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>

                                                <Row>
                                                    <Col xs="6" style={{fontSize:"14px", fontWeight:600, color:"green", marginBottom:"13px"}}>
                                                        
                                                    </Col>
                                                    <Col sm="6" style={{textAlign:"right", display:this.showExport()}}>
                                                        <div style={{cursor:'pointer', fontSize:"13px", padding:"11px"}} title="Export to CSV" onClick={() => this.exportToCSV()}>
                                                        <i style={icon} className="nc-icon nc-cloud-download-93" />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Table style={{borderColor:"green", width:"100%"}}>
                                                    <thead>   
                                                        <tr>
                                                            <td style={{fontWeight:"bold"}}>Name</td>
                                                            <td style={{fontWeight:"bold"}}>Team</td>
                                                            <td style={{fontWeight:"bold"}}>Responses</td>
                                                            <td style={{fontWeight:"bold"}}>Org</td>
                                                            <td style={{fontWeight:"bold"}}>Responses</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.map((result, index) => (
                                                        <tr key={index} style={{backgroundColor:tables.rowColor(index), fontWeight:this.rowWeight(result)}}>
                                                            <td>{result.managerName}</td>
                                                            <td style={{borderLeft:"1px"}}>
                                                                {result.teamSize}
                                                            </td>
                                                            <td style={{color:this.getCellColor(result.teamSize, result.teamResponses)}}>
                                                                {result.teamResponses}
                                                            </td>
                                                            <td style={{borderLeft:"1px"}}>{result.orgSize}</td>
                                                            <td style={{color:this.getCellColor(result.orgSize, result.orgResponses)}}>{result.orgResponses}</td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </TabPane>

                                            <TabPane tabId="responses" style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>

                                                <Row>
                                                    <Col xs="6" style={{fontSize:"14px", fontWeight:600, color:"green", marginBottom:"13px"}}>
                                                        
                                                    </Col>
                                                    <Col sm="6" style={{textAlign:"right", display:this.showExport()}}>
                                                        <div style={{cursor:'pointer', fontSize:"13px", padding:"11px"}} title="Export to CSV" onClick={() => this.exportDetailsToCSV()}>
                                                        <i style={icon} className="nc-icon nc-cloud-download-93" />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs="12">
                                                        <div style={{paddingBottom:"20px"}}>
                                                            <Row>
                                                                <Col xs="8" sm="6">
                                                                    <br /><br />
                                                                    Total Results: <b>{this.state.details.length}</b>
                                                                </Col>
                                                                <Col xs="4" sm="6" style={{textAlign:"right"}}>
                                                                    <div style={{fontSize:"11px", color:"#555", padding:"10px", paddingBottom:"20px"}}>
                                                                        <span style={{fontWeight:600}}>Green</span> indicates a positive response.
                                                                        <br />
                                                                        <span style={{fontWeight:600}}>Yellow</span> indicates a neutral response.
                                                                        <br />
                                                                        <span style={{fontWeight:600}}>Red</span> indicates a negative response.
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            
                                                            <table style={{width:"100%", borderTopLeftRadius:"6px", borderTopRightRadius:"6px"}}>
                                                                <thead style={{textAlign:"center", color:"#ffffff", backgroundColor:"#898989", padding:'6px' }}>
                                                                    <tr >
                                                                        <td title="My Manager assigns stretch opportunities to help me develop my career.">Q1</td>
                                                                        <td title="My Manager communicates clear goals for the team.  ">Q2</td>
                                                                        <td title="My Manager gives me actionable feedback at least once a month.">Q3</td>
                                                                        <td title="My manager provides the autonomy I need to do my job (i.e., does not 'micromanage' by getting involved in details that should be handled at other levels).">Q4</td>
                                                                        <td title="My manager consistently shows consideration for me as a person.">Q5</td>
                                                                        <td title="My manager keeps the team focused on priorities, even when it’s difficult (e.g. declining or deprioritizing other projects).">Q6</td>
                                                                        <td title="My manager regularly shares relevant information from their manager and senior leadership.">Q7</td>
                                                                        <td title="My manager has had a meaningful discussion with me about my career development in the past three months.">Q8</td>
                                                                        <td title="My manager has the technical expertise (e.g., technical judgment in Tech, selling in Sales, accounting in Finance) required to effectively manage me.">Q9</td>
                                                                        <td title="The actions of my manager show that he/she values the perspective I bring to the team, even if it is different from his/her own.">Q10</td>
                                                                        <td title="My manager makes tough decisions effectively (e.g., decisions involving multiple teams, competing priorities).">Q11</td>
                                                                        <td title="My manager effectively collaborates across boundaries (e.g., team, organizational).">Q12</td>
                                                                        <td title="I would recommend my manager to others.">Q13</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.details.map((row, index) => (
                                                                    <tr key={index} style={{color:"#898989", fontWeight:600, padding: "1px", margin:'1px', textAlign:"center"}}>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q1),padding:'4px'}}>{row.q1}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q2),padding:'4px'}}>{row.q2}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q3),padding:'4px'}}>{row.q3}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q4),padding:'4px'}}>{row.q4}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q5),padding:'4px'}}>{row.q5}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q6),padding:'4px'}}>{row.q6}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q7),padding:'4px'}}>{row.q7}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q8),padding:'4px'}}>{row.q8}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q9),padding:'4px'}}>{row.q9}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q10),padding:'4px'}}>{row.q10}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q11),padding:'4px'}}>{row.q11}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q12),padding:'4px'}}>{row.q12}</td>
                                                                        <td style={{backgroundColor: this.detailCellColor(row.q13),padding:'4px'}}>{row.q13}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            
                                            <TabPane tabId="heatMap" style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>

                                                <Row>
                                                    <Col xs="6" style={{fontSize:"14px", fontWeight:600, color:"green", marginBottom:"13px"}}>
                                                        
                                                    </Col>
                                                    <Col sm="6" style={{textAlign:"right", display:this.showExport()}}>
                                                        <div style={{cursor:'pointer', fontSize:"13px", padding:"11px"}} title="Export to CSV" onClick={() => this.exportHeatMapToCSV()}>
                                                        <i style={icon} className="nc-icon nc-cloud-download-93" />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs="12">
                                                        <div style={{paddingBottom:"20px"}}>

                                                            <Row>
                                                                <Col xs="8" sm="6">
                                                                    
                                                                </Col>
                                                                <Col xs="4" sm="6" style={{textAlign:"right"}}>
                                                                    <div style={{fontSize:"11px", color:"#555", padding:"10px", paddingBottom:"20px"}}>
                                                                        <span style={{fontWeight:600}}>Green</span> indicates 80%+ positive response.
                                                                        <br />
                                                                        <span style={{fontWeight:600}}>Yellow</span> indicates 70-79% positive response.
                                                                        <br />
                                                                        <span style={{fontWeight:600}}>Red</span> indicates less than 70% positive response.
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <table style={{width:"100%", borderTopLeftRadius:"6px", borderTopRightRadius:"6px"}}>
                                                                <thead style={{textAlign:"center", color:"#ffffff", backgroundColor:"#898989", padding:'6px' }}>
                                                                    <tr >
                                                                        <td style={{textAlign:"left", paddingLeft:"6px"}}> Manager</td>
                                                                        <td title="My Manager assigns stretch opportunities to help me develop my career.">Q1</td>
                                                                        <td title="My Manager communicates clear goals for the team.  ">Q2</td>
                                                                        <td title="My Manager gives me actionable feedback at least once a month.">Q3</td>
                                                                        <td title="My manager provides the autonomy I need to do my job (i.e., does not 'micromanage' by getting involved in details that should be handled at other levels).">Q4</td>
                                                                        <td title="My manager consistently shows consideration for me as a person.">Q5</td>
                                                                        <td title="My manager keeps the team focused on priorities, even when it’s difficult (e.g. declining or deprioritizing other projects).">Q6</td>
                                                                        <td title="My manager regularly shares relevant information from their manager and senior leadership.">Q7</td>
                                                                        <td title="My manager has had a meaningful discussion with me about my career development in the past three months.">Q8</td>
                                                                        <td title="My manager has the technical expertise (e.g., technical judgment in Tech, selling in Sales, accounting in Finance) required to effectively manage me.">Q9</td>
                                                                        <td title="The actions of my manager show that he/she values the perspective I bring to the team, even if it is different from his/her own.">Q10</td>
                                                                        <td title="My manager makes tough decisions effectively (e.g., decisions involving multiple teams, competing priorities).">Q11</td>
                                                                        <td title="My manager effectively collaborates across boundaries (e.g., team, organizational).">Q12</td>
                                                                        <td title="I would recommend my manager to others.">Q13</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.heatMaps.map((heatMap, index) => (
                                                                    <tr key={index} style={{color:"#898989", fontWeight:600, padding: "1px", margin:'1px', textAlign:"center"}}>
                                                                        <td style={{color:"#232323", textAlign:"left", fontWeight:400, paddingLeft:"6px", cursor:'pointer'}}><div onClick={()=>this.openManagerPage(heatMap.managerID)}> {heatMap.managerName}</div></td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q1),padding:'4px'}}>{heatMap.q1}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q2),padding:'4px'}}>{heatMap.q2}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q3),padding:'4px'}}>{heatMap.q3}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q4),padding:'4px'}}>{heatMap.q4}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q5),padding:'4px'}}>{heatMap.q5}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q6),padding:'4px'}}>{heatMap.q6}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q7),padding:'4px'}}>{heatMap.q7}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q8),padding:'4px'}}>{heatMap.q8}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q9),padding:'4px'}}>{heatMap.q9}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q10),padding:'4px'}}>{heatMap.q10}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q11),padding:'4px'}}>{heatMap.q11}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q12),padding:'4px'}}>{heatMap.q12}</td>
                                                                        <td style={{backgroundColor: this.cellColor(heatMap.q13),padding:'4px'}}>{heatMap.q13}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

QLSReporting = connect(mapStateToProps)(QLSReporting);
export default QLSReporting;