import React from "react";

//redux
import { connect } from 'react-redux'

// reactstrap components
import {  Card, CardBody, 
        Row, Col, 
        Modal, ModalBody, ModalFooter,
    } from "reactstrap";
import PersonCard from "components/PersonCard/PersonCard";

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate()) + ' ' + time;
}

class DiscussionCard extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
          modal: false,
          discussion: props.discussion
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    personCard(person) {
        if(person)
            return <PersonCard person={person} showThumb />
    }

    personName(person) {
        if(person)
            return person.name;
    }

  render() {

    return (

        <div style={{whiteSpace:"normal"}}>
        
            <Card style={{backgroundColor:"#efefef", border:"solid 0.8px #888888"}}> {/**87de9e*/}
                <CardBody>
                    <Row>
                        <Col sm="1" style={{paddingTop:"2px"}}>
                            {this.personCard(this.props.discussion.person)}
                        </Col>
                        <Col sm="11">
                            <Row>
                                <Col sm="9" style={{fontWeight:"600"}}>
                                    {this.personName(this.props.discussion.person)}
                                </Col>
                                <Col sm="3" style={{fontSize:"11px", fontWeight:"500", color:"green"}} >
                                    {getFormattedDate(this.props.discussion.created)}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" style={{paddingBottom:"5px", fontSize:"11px"}}>
                                    {this.props.discussion.text}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                
                <ModalBody style={{paddingTop:"34px", paddingLeft:"23px", paddingRight:"23px", backgroundColor:"#ffffff"}}>
                    <Row>
                        <Col sm="9">
                            
                        </Col>
                        <Col sm="3">
                            
                        </Col>
                    </Row>
                   
                </ModalBody>
                <ModalFooter style={{backgroundColor:"#efefef"}}>
                    <Row style={{marginTop:"20px", width:"100%"}}>
                       
                    
                    </Row>
                </ModalFooter>
            </Modal>
        
        </div>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

DiscussionCard = connect(mapStateToProps)(DiscussionCard);
export default DiscussionCard;