import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected } from 'redux/actions';
import KudosLanding from 'views/Appreciate/KudosLanding.js';

import { Redirect } from "react-router-dom"

import Navbar from "components/Navbars/KudosWallNavbar";

import { presence } from 'assets/utils';

// reactstrap components

var ps;

class KudosLandingLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      searchQuery: "",
      othersPresent: [],
      totalOthers: 0
    }

    this.kudosWall = this.kudosWall.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
     
    }
    
    this.getPresence();
    setInterval(() => this.getPresence(),60000);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

//to get activity presence
  getPresence() {

    presence.getOthersPresent(this.props.user.personID, "KudosWall").then(result => {
      this.setState({othersPresent: result.people, totalOthers: result.totalOthers});
    });
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  kudosWall() {
    
     return <div style={{ marginTop: "48px" }}>
       
        <KudosLanding key = {this.props.location.key} sendKudo = {this.props.location.sendKudo} personFromCard = {this.props.location.person}/>
      </div>
    
  };

  render() {

    if (!this.props.user.authenticated) {
      this.props.onUserRedirected('/kudos');
      return <Redirect to='/login' />
    }

    return (

      <div className="wrapper" style={{ backgroundColor: "#efefef" }}>
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick}
          othersPresent={this.state.othersPresent}
          totalOthers={this.state.totalOthers}
        />
        {this.kudosWall()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUserRedirected: UserRedirected
  }, dispatch);
}

KudosLandingLayout = connect(mapStateToProps, mapDispatchToProps)(KudosLandingLayout);
export default KudosLandingLayout;
