import React from 'react'
import Select from 'react-select'
import {
  Col, Input, Row,
} from 'reactstrap'

import { careStatusOptions, severityOptions } from './utils'

export default function IssuesFilter({
  filterData, fromTeamOptions, toPodOptions, toPDGOptions,
  startDate, endDate, allRequestOptions,
  countryOptions, subTeamOptions, searchKeyword, searchIssues,
}) {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      textTransform: 'capitalize',
    }),
  }

  return (
    <>
      <Row style={{ marginTop: '20px', marginBottom: '20px', fontSize: '12px' }}>
        <Col xs="2">
          Severity
          <Select
            defaultValue={[]}
            isMulti
            name="Severity"
            options={severityOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('severity', value)}}
            styles={customStyles}
          />
        </Col>
        <Col xs="2">
          Issue Type
          <Select
            defaultValue={[]}
            isMulti
            name="fromTeamID"
            options={fromTeamOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('fromTeamID', value)}}
            styles={customStyles}
          />
        </Col>
        <Col xs="2">
          Request Type
          <Select
            defaultValue={[]}
            isMulti
            name="customerRequestType"
            options={allRequestOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('customerRequestType', value)}}
            styles={customStyles}
          />
        </Col>
        <Col xs="3">
          Owner PDG
          <Select
            defaultValue={[]}
            isMulti
            name="toTeamID"
            options={toPDGOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('toTeamID', value)}}
          />
        </Col>
        <Col xs="3">
          Owner Pod
          <Select
            defaultValue={[]}
            isMulti
            name="toPodID"
            options={toPodOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('toPodID', value)}}
            styles={customStyles}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px', marginBottom: '20px', fontSize: '12px' }}>
        <Col xs="2">
          Status
          <Select
            defaultValue={[]}
            isMulti
            name="Status"
            options={careStatusOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('status', value)}}
            styles={customStyles}
          />
        </Col>
        <Col xs="2">
          Start Date
          <br />
          <Input
            type="date"
            placeholder=""
            value={startDate}
            onChange={(e) => {filterData('startDate', e.target.value)}}
            style={{ fontSize: '12px', padding: '10px', borderColor: 'hsl(0,0%,80%)' }}
          />
        </Col>
        <Col xs="2">
          End Date
          <br />
          <Input
            type="date"
            placeholder=""
            value={endDate}
            onChange={(e) => {filterData('endDate', e.target.value)}}
            style={{ fontSize: '12px', padding: '10px', borderColor: 'hsl(0,0%,80%)' }}
          />
        </Col>
        <Col xs="3">
          Country
          <Select
            defaultValue={[]}
            isMulti
            name="Country"
            options={countryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('country', value)}}
            styles={customStyles}
          />
        </Col>
        <Col xs="3">
          SubTeam
          <Select
            defaultValue={[]}
            isMulti
            name="SubTeam"
            options={subTeamOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {filterData('subTeam', value)}}
            styles={customStyles}
          />
        </Col>
      </Row>
    </>
  )
}
