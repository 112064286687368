import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import { uniqBy } from 'lodash'

import { getMercuryTablesByApp, getMercuryUniqueEventsByAppAndTable } from '../../assets/firebase/db/mercuryDb'
import { strings } from '../../assets/utils'
import {AppOptions, specialPrefixedApps} from "./constants";
import { DISABLE_PLATFORM_AND_MARKET_FILTER_FOR_APPS } from 'constants/GoConnectDomain';

export const appOptions = [
  { label: 'Consumer', value: 'gojek' },
  { label: 'Driver', value: 'gopartner' },
  { label: 'Merchant', value: 'gobiz' },
  { label: 'GoPay', value: 'gopay' },
  { label: 'GoPay Web', value: 'web_gopay' },
  { label: 'Gobiz Web', value: 'web_gobiz' },
  { label: 'GoScreen', value: 'goscreen' },
  { label: 'Service Gojek', value: 'service_gojek' },
  { label: 'GMA', value: 'gopay_merchant' },
  { label: 'Nexus', value: 'web_nexus' },
  { label: 'Snap', value: 'web_snap' },
]

export const aggregationOptions = [
  { label: 'daily', value: 'daily' },
  { label: 'weekly', value: 'weekly' },
  { label: 'monthly', value: 'monthly' },
]

export const tooltipLabel = {
  daily: 'By Day',
  weekly: 'By Week',
  monthly: 'By Month',
}

export const marketOptions = [
  { label: 'All', value: 'all' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Vietnam', value: 'VN' },
]

export const platformOptions = [
  { label: 'All', value: 'all' },
  { label: 'Android', value: 'android' },
  { label: 'iOS', value: 'iOS' },
]

export const visualisationOptions = [
  { label: 'Bar Chart', value: 'bar' },
  { label: 'Line Chart', value: 'line' },
]

export const colorOptions = [
  { label: 'Blue', value: 'blue' },
  { label: 'Green', value: 'green' },
  { label: 'Maroon', value: 'maroon' },
  { label: 'Orange', value: 'orange' },
  { label: 'Purple', value: 'purple' },
]

export const daysOptions = [
  { label: '7 Days', value: 7 },
  { label: '10 Days', value: 10 },
  { label: '14 Days', value: 14 },
  { label: '21 Days', value: 21 },
  { label: '28 Days', value: 28 },
  { label: '30 Days', value: 30 },
]

export const refreshFrequencyOptions = [
  { label: 'Every 2 hours', value: 2 },
  { label: 'Every 4 hours', value: 4 },
  { label: 'Every 8 hours', value: 8 },
  { label: 'Every 16 hours', value: 16 },
  { label: 'Everyday', value: 24 },
]

export const chartOptions = [
  { label: 'Trend', value: 'trend' },
  { label: 'Funnel', value: 'funnel' },
]

// Note: Do not remove the value comparison to undefined
export const filterUndefined = (obj) => Object.keys(obj)
    // eslint-disable-next-line no-param-reassign
    .forEach((key) => (obj[key] === undefined ? delete obj[key] : {}))

export const chartJSOptions = ({ scaleXLabel, scaleYLabel } = {}) => {
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      intersect: false,
    },
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 0,
        to: 1,
        loop: true,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: '#9f9f9f',
            beginAtZero: true,
            maxTicksLimit: 5,
            callback(value) {
              return strings.numberWithCommas(value)
            },
          },
          gridLines: {
            drawBorder: true,
            color: 'rgba(0,0,0,0.05)',
            zeroLineColor: 'rgba(0,0,0,0.1)',
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: 'rgba(0,0,0,0.05)',
            zeroLineColor: 'rgba(0,0,0,0.1)',
          },
          ticks: {
            beginAtZero: true,
            padding: 20,
            fontColor: '#9f9f9f',
            maxTicksLimit: 20,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  }
  if (scaleXLabel) {
    options.scales.xAxes[0] = {
      ...options.scales.xAxes[0],
      scaleLabel: {
        display: true,
        labelString: scaleXLabel,
      },
    }
  }

  if (scaleYLabel) {
    options.scales.yAxes[0] = {
      ...options.scales.yAxes[0],
      scaleLabel: {
        display: true,
        labelString: scaleYLabel,
      },
    }
  }
  return options
}

export const getBQTableName = (app, table) => {
  const appName = specialPrefixedApps.includes(app) ? '' : `${app}_`
  let tableName = table.split('-')[0]

  // Translates (SERVICE)_web_(TABLE) -> web_(SERVICE)_(TABLE)
  // ex: (gopay)_web_(page) -> web_(gopay)_(page)
  if (table.includes('_web_')) {
    tableName = `web_${tableName.split('_')[0]}_${tableName.split('_')[2]}`
  } else if (table.includes('service_')) { // Translates to clickstream_service_gojek_(TABLENAME)_log
    tableName = tableName.replace('service_', 'service_gojek_')
  }

  return `clickstream_${appName}${tableName}_log`
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function sortByKey(array, key) {
  array.sort((a, b) => {
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    return 0
  })
}

export function isNumeric(str) {
  try {
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(str)
  } catch {
    return false
  }
}

export function parseDate(timestamp) {
  return new Date(timestamp.replace(/\s+/g, 'T').split('+')[0])
}

// eslint-disable-next-line no-extend-native,func-names
// This function Returns  week of the date.
// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function() {
  const date = new Date(this.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  const totalMilliSecondsInADay = 86400000
  const milliSecondsBetweenGivenDateAndWeek1 = (date.getTime() - week1.getTime())
  const daysDifference = milliSecondsBetweenGivenDateAndWeek1 / totalMilliSecondsInADay

  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round((daysDifference - 3 + ((week1.getDay() + 6) % 7)) / 7)
}

export const generateDateStringLabel = (dateString) => {
  const date = new Date(Date.parse(dateString))
  return `${date.getMonth() + 1}/${date.getDate()}`
}

const generateAggregationLabel =
    (startDate, endDate) => `${generateDateStringLabel(startDate)} - ${generateDateStringLabel(endDate)}`

const checkForNewYear = (aggregatedKeysArray) => {
  let newYear = false
  let newYearIndex = 0
  for (let index = 0; index < aggregatedKeysArray.length - 1; index += 1) {
    newYear = (aggregatedKeysArray[index + 1] - aggregatedKeysArray[index] > 1)
    if (newYear) {
      newYearIndex = index
      break
    }
  }
  return [newYear, newYearIndex]
}

export const generateAggregatedChartDataAndLabel = (aggregationWiseData) => {
  let aggregatedKeys = Object.keys(aggregationWiseData).sort((a, b) => Number(a) - Number(b))
  const [isNewYear, newYearIndex] = checkForNewYear(aggregatedKeys)
  if (isNewYear) {
    aggregatedKeys = aggregatedKeys.slice(newYearIndex + 1).concat(aggregatedKeys.slice(0, newYearIndex + 1))
  }
  const data = aggregatedKeys.map((week) => aggregationWiseData[week].count)
  const label = aggregatedKeys.map(
      (week) => generateAggregationLabel(aggregationWiseData[week].startDate, aggregationWiseData[week].endDate),
  )
  return [data, label]
}

export const countEventsForCurrentDuration = (currentData, currentDate, total) => {
  let { count, startDate, endDate } = currentData
  count += total
  startDate = (startDate > currentDate) ? currentDate : startDate
  endDate = (endDate < currentDate) ? currentDate : endDate
  return {
    count,
    startDate,
    endDate,
  }
}

export const generateSha256Hash = async (data) => {
  const msgUint8 = new TextEncoder().encode(data)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}


// eslint-disable-next-line no-extend-native, func-names
Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

// eslint-disable-next-line no-extend-native, func-names
Date.prototype.subDays = function(days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() - days)
  return date
}

export const getDatesInRange = (startDate, stopDate) => {
  const dateArray = []
  let currentDate = startDate
  while (currentDate <= stopDate) {
    dateArray.push(currentDate)
    currentDate = currentDate.addDays(1)
  }
  return dateArray
}

export const disabledDate = (current) => {
  // Can not select days after today and before start Date
  const rightNow = new Date()
  const start = new Date(rightNow - (60 * 60 * 1000 * 24 * 90))
  return current < start || current > dayjs().endOf('day')
}

export const calculateDateDifference = (startDate) => {
  const secondsInDay = 86400
  return dayjs().diff(startDate, 'seconds') / secondsInDay
}

export const getUniqueDates = (startDate, endDate) => {
   // Create an array of dates within the range
   const dateArray = [];
   let currentDate = dayjs(startDate);
 
    while(currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')){
     dateArray.push(currentDate.format('YYYY-MM-DD'));
     currentDate = currentDate.add(1, 'day');
   }
 
   // Create set to store unique date strings and convert to an array
   return uniqBy(dateArray)
}

const checkForMilliSecondUnixTime = (time) => {
  if (time.toString().length > 10) {
    return time / 1000
  }
  return time
}

export const CalculateDaysJsDate = (days) => {
  let startDate
  let endDate
  if (typeof days !== 'object') {
    startDate = dayjs().subtract(days, 'day')
    endDate = dayjs()
  } else {
    startDate = dayjs.unix(checkForMilliSecondUnixTime(days[0]))
    endDate = dayjs.unix(checkForMilliSecondUnixTime(days[1]))
  }
  return [startDate, endDate]
}

export const fetchMercuryTablesForTrendsAndFunnel = async (app) => {
  const mercuryTableOptions = []
  const mercuryTablesFromDB = await getMercuryTablesByApp(app.value)
  mercuryTablesFromDB.forEach((table) => {
    const { tableName } = table

    mercuryTableOptions.push({
      label: tableName.replace(/_/g, ' '),
      value: `${tableName}-log`,
    })
  })
  return mercuryTableOptions
}

export const fetchMercuryEventsForTrendsAndFunnel = async (app, table) => {
  const mercuryEventOptions = []
  const mercuryEventsFromDB = await getMercuryUniqueEventsByAppAndTable(app, table)
  mercuryEventsFromDB.forEach((event) => {
    const { eventName } = event

    mercuryEventOptions.push({
      label: eventName.replace(/_/g, ' '),
      value: eventName,
    })
  })
  return mercuryEventOptions
}

// all the firestore tables related to event analysis are storing
// tables names as 'gobiz_web' for 'web_gobiz' tables
export const getAppId = (app, table) => {
  if (app.value !== 'gojek') return app.value
  if (table.value.includes('service')) return 'service'
  if (table.value.includes('gopay')) return 'web_gopay'
  if (table.value.includes('gobiz')) return 'web_gobiz'
  if (table.value.includes('gofood')) return 'web_gofood'
  if (table.value.includes('finance')) return 'web_gofinance'
  if (table.value.includes('risk')) return 'web_risk'
  return app.value
}


export const findDatatypeForField = (fieldPath, schema) => {
  const item = schema.find((schemaItem) => schemaItem.field_path === fieldPath)
  if (item) return item.data_type
  return null
}

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  })
}

export const getUserIdentityPath = (appSelected, tableSelected) => {
  const appId = getAppId(appSelected, tableSelected)
  const userIdentityPath = AppOptions.find((elem) => elem.id === appId).userIdentityFieldPath
  return `count(distinct(${userIdentityPath}))`
}

export const shouldDisablePlatformAndMarketForApp = (appFilter) => {
  return DISABLE_PLATFORM_AND_MARKET_FILTER_FOR_APPS.includes(appFilter)
}

export const generateTimeIntervals = (startDate, endDate) => {
  const eventDurationWindow = 2;
  const intervals = [];
  let currentStartDate = new Date(startDate);
  let currentEndDate = new Date(endDate);

  while (currentEndDate > new Date(startDate)) {
    let currentStartDateAdjusted = new Date(currentEndDate.getTime() - (eventDurationWindow * 60 * 60 * 1000));
    if(currentStartDateAdjusted < currentStartDate) currentStartDateAdjusted = currentStartDate
    intervals.push({
      startDate: currentStartDateAdjusted.toString(),
      endDate: currentEndDate.toString()
    });
    currentEndDate = currentStartDateAdjusted;
  }
  
  return intervals;
}

const getFormattedTimeUnit = (unit) => {
  return unit <= 9 ? `0${unit}` : unit.toString()
}

export const formatTimeString = (date) => {
  let hours = getFormattedTimeUnit(date.getHours()),
  minutes = getFormattedTimeUnit(date.getMinutes()),
  seconds = getFormattedTimeUnit(date.getSeconds())
  return `${hours}:${minutes}:${seconds}`
}

export const getUAVDateEvents = (date, dateWiseData, adjust) => {
  let dateEvents = dateWiseData.find(d => d.dateShort === date)
  if(adjust && dateEvents?.events?.length){
    dateEvents.events = dateEvents.events.filter(e => e.runAdjust === true)
  }
  return dateEvents
}

export const getFormattedDate = (date) => dayjs(date).format('YYYY-MM-DD')
