import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected } from 'redux/actions';

import { Redirect } from "react-router-dom"

import Navbar from "components/Navbars/ApprovalNavbar.js";

import { presence, telemetry } from 'assets/utils';

import Approvals from '../views/approvals/index'

var ps;

class ApprovalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      searchQuery: "",
      othersPresent: [],
      totalOthers: 0
    }

    document.title = 'Approvals';
  }
  componentDidMount() {
    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "approvals", "pageView").then(activity => {
      setInterval(() => telemetry.trackDwellTime(activity),60000);
    });

    this.getPresence();
    setInterval(() => this.getPresence(),60000);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  //to get activity presence
  getPresence() {
    presence.getOthersPresent(this.props.user.personID, "approvals").then(result => {
      this.setState({othersPresent: result.people, totalOthers: result.totalOthers});
    });
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {

    if (!this.props.user.authenticated) {
      this.props.onUserRedirected('/approvals');
      return <Redirect to='/login' />
    }

    return (

      <div className="wrapper" style={{ backgroundColor: "#efefef" }}>
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick}
          othersPresent={this.state.othersPresent}
          totalOthers={this.state.totalOthers}
        />
        <div style={{ marginTop: "63px" }}>
          <Approvals user={this.props.user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUserRedirected: UserRedirected
  }, dispatch);
}

ApprovalLayout = connect(mapStateToProps, mapDispatchToProps)(ApprovalLayout);
export default ApprovalLayout;
