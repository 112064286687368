import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { slack, strings, telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText, Label, FormGroup
} from "reactstrap";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import JiraProjectSearch from "components/Jira/JiraProjectSearch.js"; 
import AsanaProjectSearch from 'components/AsanaProjectSearch/AsanaProjectSearch';
import Tag from "components/Tag/Tag.js"; 
import TeamSearch from "components/TeamSearch/TeamSearch.js";
import PersonCard from "components/PersonCard/PersonCard.js";
import PersonSearch from "components/PersonSearch/PersonSearch.js";

class EndpointEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            endpoint: {
                title: '',
                description: '',
                podID: '',
                teamID: '',
                orgID: '',
                status: 'disabled',
                slackChannel: '',
                tags: [],
                defaultAssigneeId: '',
                defaultSlackUser: '',
                sendAlertForTimeToRespondSLA: true,
                sendAlertForTimeToResolutionSLA: true,
                projectType: 'jira',
                sendAlertForPreTimeToRespondSLA: false,
                sendAlertForPreTimeToResolutionSLA: false
            },
            pod: {},
            team: {},
            org: {},
            jira: {},
            asana:{},
            defaultAssignee: {},
            openPodSearch: false,
            openTeamSearch: false,
            openOrgSearch: false,
            openPersonSearch: false,
            openAsanaSearch: false,
        }
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        
        if(!this.state.modal && !this.props.onCreated) {
            this.getEndpoint();

            this.getPod();
            this.getTeam();
            this.getOrg();
            this.getDefaultAssignee();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "endpointEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getEndpoint() {

        db.getNexusEndpoint(this.props.endpoint.id).then(endpoint => {
           
            this.setState({endpoint:endpoint}, () => {
                this.getProject();
            });
        });
    }

    getPod() {
        if(this.props.endpoint.podID) {   
            db.getTeam(this.props.endpoint.podID).then((team => {
                this.setState({pod: team});
            }));
        }
    }
    getTeam() {
        if(this.props.endpoint.teamID) {   
            db.getTeam(this.props.endpoint.teamID).then((team => {
                this.setState({team: team});
            }));
        }
    }
    getOrg() {
        if(this.props.endpoint.orgID) {   
            db.getTeam(this.props.endpoint.orgID).then((team => {
                this.setState({org: team});
            }));
        }
    }

    getDefaultAssignee() {
        if(this.props.endpoint.defaultAssigneeId) {   
            db.getPerson(this.props.endpoint.defaultAssigneeId).then((person => {
                this.setState({defaultAssignee: person});
            }));
        }
    }

    getProject() {
        const {
            jiraID, jiraDomain, projectType, asanaID, jiraKey,
        } = this.state.endpoint
        console.log(projectType);
        if(projectType === 'jira') {
            if(jiraID) {
                const queryID = jiraDomain && jiraDomain !== 'go-jek' ? `${jiraID}_${jiraKey}` : jiraID
                db.getJiraProject(queryID).then(jira => {
                    this.setState({jira: jira});
                })
            }
        }  else{
            if(asanaID) {
                db.getAsanaProject(asanaID).then(asana => {
                    this.setState({asana: asana});
                })
            }
        }
    }

    updateEndpoint(property, value) {
        var obj = this.state.endpoint;
        obj[property] = value;
        this.setState({endpoint: obj});
    }

    deleteEndpoint() {
        db.deleteNexusEndpoint(this.state.endpoint.id).then(() => {
            this.props.onDeleted(this.state.endpoint);
            this.setState({modal: false});
        });
    }

    saveEndpoint() {

        var endpoint = this.state.endpoint;
        console.log(this.state.endpoint);
        if(!endpoint.created) { 
            endpoint.created = new Date().getTime(); 
            endpoint.createdBy = this.props.user.person.name;
            endpoint.createdByID = this.props.user.person.id;
        }

        endpoint.modified = new Date().getTime(); 
        endpoint.modifiedBy = this.props.user.person.name;

        db.updateNexusEndpoint(endpoint.id, endpoint).then((result) => {

            if(this.props.onCreated) { // 
                endpoint.id = result.id;
                this.props.onCreated(endpoint);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "endpoint", "createEndpoint");
            } else {
                this.props.onUpdated(endpoint);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "endpoint", "updateEndpoint");
            }

            if(this.props.onCreated) { // reset for new endpoint

                this.setState({ 
                    endpoint: {
                        title: '',
                        description: '',
                        podID: '',
                        teamID: '',
                        orgID: '',
                        slackChannel: '',
                        tags: [],
                    },
                    team: {},
                    org: {},
                    pod: {},
                    jira: {}
                })
            } 

            // close the dialog
            this.setState({modal:false});
        });
    }

    sendPreview() {
        
        var tip = this.state.tip;

        var blocks = [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "_*" + tip.title + "*_ \n\n"
                } // `
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": tip.description + "\n\n"
                }
            }
        ]

        tip.links.sort( (a,b) => { return a.sortIndex - b.sortIndex }  )
        tip.links.forEach(link => {
            blocks.push( {
                "type": "section",
                text: {
                    "type": "mrkdwn",
                    "text": "<" + link.url + "|" + link.name + ">"
                }
            })
        })

        slack.sendMessage(this.props.user.person.slackID, this.state.tip.title, blocks)
    }

    assignPod(pod) {
        this.updateEndpoint("podID", pod.id);
        db.getTeam(pod.id).then((team => {
            this.setState({pod: team});
        }));
    }
    deletePod() {
        var obj = this.state.endpoint;
        delete obj.podID;
        this.setState({endpoint:obj, pod:{}});
    }
    openPodSearch() {
        this.setState({openPodSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPodSearch: false});
            }, 500);
        });
    }

    openJiraSearch() {
        this.setState({openJiraSearch: true}, () => {
            setTimeout(() => {
                this.setState({openJiraSearch: false});
            }, 500);
        });
    }

    openAsanaSearch() {
        this.setState({openAsanaSearch: true}, () => {
            setTimeout(() => {
                this.setState({openAsanaSearch: false});
            }, 500);
        });
    }

    assignTeam(team) {
        this.updateEndpoint("teamID", team.id);
        db.getTeam(team.id).then((team => {
            this.setState({team: team});
        }));
    }
    deleteTeam() {
        var obj = this.state.endpoint;
        delete obj.teamID;
        this.setState({endpoint:obj, team:{}});
    }
    openTeamSearch() {
        this.setState({openTeamSearch: true}, () => {
            setTimeout(() => {
                this.setState({openTeamSearch: false});
            }, 500);
        });
    }

    assignOrg(org) {
        this.updateEndpoint("orgID", org.id);
        db.getTeam(org.id).then((team => {
            this.setState({org: team});
        }));
    }
    deleteOrg() {
        var obj = this.state.endpoint;
        delete obj.orgID;
        this.setState({endpoint:obj, org:{}});
    }
    openOrgSearch() {
        this.setState({openOrgSearch: true}, () => {
            setTimeout(() => {
                this.setState({openOrgSearch: false});
            }, 500);
        });
    }


    jiraProjectChosen(project) {
        
        this.updateEndpoint('jiraID', project.jiraID);
        this.updateEndpoint('jiraDomain', project.jiraDomain)
        this.updateEndpoint('jiraKey', project.jiraKey)
        this.updateEndpoint('asanaID', null);

        db.getJiraProject(project.id).then(jira => {
            this.setState({jira: jira});
        })
    }

    jiraAsanaChosen(project) {
        console.log(project);
        this.updateEndpoint('asanaID', project.id);
        this.updateEndpoint('jiraID', null);
        this.updateEndpoint('jiraDomain', null)
        this.updateEndpoint('jiraKey', null)
        db.getAsanaProject(project.id).then(asana => {
            this.setState({asana: asana});
        })
    }

    deleteJira() {
        console.log('todo')
    }

    deleteAsana() {
        console.log('todo') 
    }

    buttonText() {
        if(this.props.onCreated)
            return "New Endpoint";
        else
            return "Edit";
    }

    showPreviewButton() {
        return "none";
        if(this.state.activeTab==='overview')
          return "inline-block";
        else
          return "none";
    }
    showDeleteButton() {
        if(this.props.user.isAdmin || this.state.endpoint.createdByID === this.props.user.person.id)
          return "inline-block";
        else
          return "none";
    }

    openPeopleSearch() {
        this.setState({openPersonSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPersonSearch: false});
            }, 500);
        });
    }

    assignDefaultAssignee(person) {
        this.setState({defaultAssignee: person, endpoint: { ...this.state.endpoint, defaultAssigneeId: person.id }});
    }

    deleteDefaultAssignee(e) {
        e.stopPropagation()
        this.setState({endpoint: { ...this.state.endpoint, defaultAssigneeId: '' }, defaultAssignee:{}});
    }

    defaultAssigneeCard() {
        if(this.state.defaultAssignee && this.state.defaultAssignee.id) {
            return (
                <PersonCard person={this.state.defaultAssignee} showThumb />
            )
        }
    }

    getDefaultAssigneeName() {
        if(this.state.defaultAssignee && this.state.defaultAssignee.name) 
            return strings.trim(this.state.defaultAssignee.name,26)

        return ''
    }

    getPodName() {
        if(this.state.pod && this.state.pod.name)
            return strings.trim(this.state.pod.name,30)

        return ''
    }

    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const deleteIcon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#888888"
        }
        const iconTextArea = {
            fontSize:"17px",
            position:"absolute",
            top:"20px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Nexus Endpoint
                            </Col>
                        </Row>   
                        <Row>
                            <Col xs="6">
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            </Col>
                            <Col xs="6" style={{textAlign:"right", fontSize:"11px", color:"#888888", marginTop:"17px", paddingRight:"40px"}}>
                                Created by: <span style={{color:"green"}}>{this.state.endpoint.createdBy}</span>
                            </Col>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12" sm="8">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.endpoint.title} onChange={(e) => this.updateEndpoint("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                    <Col xs="12" sm="4" >
                                        Status <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.endpoint.status} 
                                            onChange={(e) => this.updateEndpoint("status", e.target.value)} >
                                                <option value="disabled">Disabled</option>
                                                <option value="enabled">Enabled</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Description <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="textarea" style={{minHeight:"120px", lineHeight:"19px"}}
                                                value={this.state.endpoint.description} onChange={(e) => this.updateEndpoint("description", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col xs="12" >
                                            Project Type <br />  
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                        <i style={icon} className="nc-icon nc-sound-wave" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="" 
                                                type="select" 
                                                value={this.state.endpoint.projectType} 
                                                onChange={(e) => this.updateEndpoint("projectType", e.target.value)} >
                                                    <option value="jira">Jira</option>
                                                    <option value="asana">Asana</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                </Row>
                                <Row>
                                    {/* {this.state.endpoint.projectType === 'jira' ? 
                                        <Col xs="12" sm="6" >
                                            Jira <br />  
                                            <div onClick={() => this.openJiraSearch()}
                                                style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                    
                                                </div>
                                                <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                    {strings.trim(this.state.jira.title,30)}
                                                </div>
                                                <div style={{display:"none"}}>
                                                    <JiraProjectSearch isOpen={this.state.openJiraSearch} onProjectChosen={(project) => this.jiraProjectChosen(project)} />
                                                </div>
                                            </div>
                                            <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deleteJira()}>
                                                <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                            </div>
                                        </Col>
                                        :
                                        <Col xs="12" sm="6" >
                                        Asana <br />  
                                        <div onClick={() => this.openAsanaSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {strings.trim(this.state.asana.name, 30)}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <AsanaProjectSearch isOpen={this.state.openAsanaSearch} onProjectChosen={(project) => this.jiraAsanaChosen(project)} />
                                            </div>
                                        </div>
                                        <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deleteAsana()}>
                                            <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                        </div>
                                    </Col>
                                    } */}
                                    <Col xs="12" sm="6">
                                        Type <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.endpoint.issueType} onChange={(e) => this.updateEndpoint("issueType", e.target.value)} >
                                                    <option selected></option>
                                                    <option value="app">App/Play Store</option>
                                                    <option value="care">Care</option>
                                                    <option value="market">Market</option>
                                                    <option value="other">Other</option>
                                                    <option value="tasks">Project Tasks</option>
                                                    <option value="security">Security</option>

                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6">
                                        Slack Channel <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-chat-33" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.endpoint.slackChannel} onChange={(e) => this.updateEndpoint("slackChannel", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                    <Col xs="12" sm="6">
                                        Slack Severity <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.endpoint.slackSeverity} onChange={(e) => this.updateEndpoint("slackSeverity", e.target.value)} >
                                                    <option value="all" selected>All</option>
                                                    <option value="critical">Critical</option>
                                                    <option value="high">High</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="low">Low</option>
                                                    <option value="none">None</option>
                                            </Input>
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                {/* <Row>
                                    <Col xs="12"  >
                                        Pod <br />
                                        <div onClick={() => this.openPodSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", marginBottom:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {this.getPodName()}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Pod" isOpen={this.state.openPodSearch} onTeamChosen={(t) => this.assignPod(t)} />
                                            </div>
                                        </div>
                                        <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deletePod()}>
                                            <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                        </div>
                                    </Col>
                                    <Col xs="12"  >
                                        Team <br />
                                        <div onClick={() => this.openTeamSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", marginBottom:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {strings.trim(this.state.team.name,30)}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.assignTeam(t)} />
                                            </div>
                                        </div>
                                        <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deleteTeam()}>
                                            <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                        </div>
                                    </Col>
                                    <Col xs="12"  >
                                        Org <br />
                                        <div onClick={() => this.openOrgSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", marginBottom:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {strings.trim(this.state.org.name,30)}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Org" isOpen={this.state.openOrgSearch} onTeamChosen={(t) => this.assignOrg(t)} />
                                            </div>
                                        </div>
                                        <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deleteOrg()}>
                                            <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row style={{marginTop:"10px"}}>
                                    <Col xs="12">
                                        Tags to Assign <br />
                                        <Tag type="issueType" icon="nc-bookmark-2" 
                                            selected={this.state.endpoint.tags} 
                                            onChange={(tags) => this.updateEndpoint('tags',tags)} />
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"10px"}}>
                                    {/* <Col xs="12" sm="6">
                                        Default Jira assignee <br />
                                        <div onClick={() => this.openPeopleSearch()} 
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                {this.defaultAssigneeCard()}
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666"}}>
                                                {this.getDefaultAssigneeName()}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignDefaultAssignee(p)} />
                                            </div>
                                            <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={(e) => this.deleteDefaultAssignee(e)}>
                                                <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col xs="12" sm="6">
                                        Default Slack mention<br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.endpoint.defaultSlackUser} onChange={(e) => this.updateEndpoint("defaultSlackUser", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"10px"}}>
                                    <Col xs="12" sm="6">
                                        <Input type="checkbox" id="sendAlertForTimeToRespondSLA" style={{marginLeft:"0px"}} checked={this.state.endpoint.sendAlertForTimeToRespondSLA} onChange={(e) => this.updateEndpoint("sendAlertForTimeToRespondSLA", e.target.checked)} />
                                        <Label check for="sendAlertForTimeToRespondSLA" style={{marginLeft:"20px"}}>Send Slack alert for time to respond</Label>
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <Input type="checkbox" id="sendAlertForTimeToResolutionSLA" style={{marginLeft:"0px"}} checked={this.state.endpoint.sendAlertForTimeToResolutionSLA} onChange={(e) => this.updateEndpoint("sendAlertForTimeToResolutionSLA", e.target.checked)} />
                                        <Label check for="sendAlertForTimeToResolutionSLA" style={{marginLeft:"20px"}}>Send Slack alert for time to resolution</Label>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"10px"}}>
                                    <Col xs="12" sm="6">
                                        <Input type="checkbox" id="sendAlertForPreTimeToRespondSLA" style={{marginLeft:"0px"}} checked={this.state.endpoint.sendAlertForPreTimeToRespondSLA} onChange={(e) => this.updateEndpoint("sendAlertForPreTimeToRespondSLA", e.target.checked)} />
                                        <Label check for="sendAlertForPreTimeToRespondSLA" style={{marginLeft:"20px"}}>Send Slack alert for pre time to respond</Label>
                                    </Col>
                                    <Col xs="12" sm="6">
                                        <Input type="checkbox" id="sendAlertForPreTimeToResolutionSLA" style={{marginLeft:"0px"}} checked={this.state.endpoint.sendAlertForPreTimeToResolutionSLA} onChange={(e) => this.updateEndpoint("sendAlertForPreTimeToResolutionSLA", e.target.checked)} />
                                        <Label check for="sendAlertForPreTimeToResolutionSLA" style={{marginLeft:"20px"}}>Send Slack alert for pre time to resolution</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="7" >
                                
                                <div style={{display:this.showDeleteButton(), marginRight:"6px"}}>
                                    <ConfirmDialog 
                                        buttonText="Delete"
                                        title="Delete Endpoint" 
                                        header="CONFIRM" 
                                        description="Are you sure you want to delete this endpoint?" 
                                        confirmed={() => this.deleteEndpoint()} /> 
                                </div>
                                <div style={{display:this.showPreviewButton(), fontSize:"11px"}}>
                                    <Button onClick={() => this.sendPreview()}>Send Preview Alert</Button>
                                </div>
                            </Col>
                            <Col xs="5" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveEndpoint()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    EndpointEditor = connect(mapStateToProps)(EndpointEditor);
    export default EndpointEditor;
    