export const TPM_TEAM_ID = 'D3yXmqIOUM16xSc39bKa'

export const IP_TEAM_ID = 'xAFdzBXmtsEr6MGvoOnP'

export const EP_TEAM_PEOPLE_IDS = ['40c801f9-e771-4a30-9529-6466be25e14e',
  'b3efd58c-22ea-4604-8c38-7416e42c4887', 'a282fb09-9b99-478e-a943-529b0a9b912f',
]

export const MERCURY_METRICS_EXCEPTION_PEOPLE_IDS = [
  'cf5b330c-f494-4981-8a57-42c60145895e', 'eb5ecaee-1110-430a-8a4f-213c456db6cd',
  '066dfb1a-6b5d-4daf-88ea-64b0f0769034', 'wo8zEcuAeZwv1gyl0doO',
  'e8662247-2f17-464c-96ac-5881eae64d08', '4dcce762-695a-4793-8742-34bd78836752',
]

export const DISABLE_PLATFORM_AND_MARKET_FILTER_FOR_APPS = ['web_gobiz', 'goscreen']

export const SEND_SLACK_MESSAGE_ROUTE = '/goconnect/sendSlackMessage'
export const POST_RESPONSE_ROUTE = '/goconnect/postPlaystoreResponse'
export const SAVE_BLOB_ROUTE = '/goconnect/saveBlob'
