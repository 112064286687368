import React from "react";

import { 
    Button, 
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, ModalFooter, Spinner 
} from "reactstrap";

import { algolia } from 'assets/algolia';
import { tables, telemetry } from 'assets/utils';


class JiraProjectSearch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            queryText: '',
            searchResults: [],
            initialSearch: false
        }

        this.onProjectChosen = this.onProjectChosen.bind(this);
        this.searchProjects = this.searchProjects.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isOpen !== this.props.isOpen) {
            if(this.props.isOpen)
                this.setState({modal: true});    
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    setSearchTimer(queryText) {
        clearTimeout(this.searchProjects);
        this.setState({isLoading: true, queryText}, () => {
            setTimeout(this.searchProjects,2300);
        });
    }
    
    searchProjects() {
        this.setState({searchResults: []}, () => {
            algolia.searchJiraProjects(this.state.queryText).then(results => {
                this.setState({searchResults: results, initialSearch: true, isLoading: false}); 
            });
        });
    }

    onProjectChosen(project) {
        this.props.onProjectChosen(project);
        this.setState({searchResults:[], queryText: '', modal:false});
    }

    showAfterSearch() {
        if(this.state.initialSearch)
            return "flex";
        else
            return "none";
    }

    showWhenResults() {
        if(this.state.searchResults.length > 0)
            return "flex";
        else
            return "none";
    }
    showWhenNoResults() {
        if(this.state.initialSearch && this.state.searchResults.length === 0)
            return "flex";
        else
            return "none";
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
        
        return (
            <div>
                <Button onClick={this.toggleModal}  >  
                    + Link Jira 
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm={12}>
                                <br />Jira Project Name<br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-zoom-split" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="search by name" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{display:this.showWhenResults()}}>
                            <Col sm={12}>
                                <br />
                            </Col>
                            <Col sm={12}>
                                {this.state.searchResults.map((project, index) => (
                                    <Row key={index} style={{backgroundColor:tables.rowColor(index)}}>
                                        <Col sm={2} >
                                            <Button size="sm" onClick={() => this.onProjectChosen(project)} >Select</Button>
                                        </Col>
                                        <Col sm={10} style={{fontSize:"12px", marginTop:"14px"}}>
                                            {project.name}
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <Row style={{width:"100%", display:this.showWhenNoResults()}}>
                            <Col sm={12} >
                                There are no matching projects.
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{justifyContent:"left"}}>
                        <div style={{fontSize:"11px", color:"545454", padding:"20px"}}>
                            Note: it may take 1-2 hours after a new Jira project is added or permissions are changed
                            before it will be synchronised.
                        </div>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}

export default JiraProjectSearch;