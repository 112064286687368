import { func } from './firebase'
import { apiRequest } from '../../api/requests'
const { SEND_SLACK_MESSAGE_ROUTE, POST_RESPONSE_ROUTE, SAVE_BLOB_ROUTE } = require('../../constants/GoConnectDomain')

// BLOB

export const getBlob = (id) => new Promise((resolve) => {
  func.httpsCallable('getBlob')({
    id,
  })
    .then((blob) => {
      resolve(blob)
    })
})
// export const saveBlob = (blob, id) => new Promise((resolve) => {
//   func.httpsCallable('saveBlob')({
//     blob,
//     id,
//   })
//     .then(() => {
//       resolve()
//     })
// })

export const saveBlob = async (blob, id, path) => {
  const payload = {
    blob,
    id,
  }

  return apiRequest(SAVE_BLOB_ROUTE, 'POST', payload, path)
}

// ALGOLIA

export const deleteAlgoliaRow = (objType, objID) => new Promise((resolve) => {
  func.httpsCallable('deleteAlgoliaRow')({
    objType,
    objID,
  })
    .then(() => {
      resolve()
    })
})

export const searchAlgoliaIndex = (objType, query) => new Promise((resolve) => {
  func.httpsCallable('searchAlgoliaIndex')({
    objType,
    query,
  })
    .then((response) => {
      const objs = []
      const results = response.data

      for (let i = 0; i < results.hits.length; i += 1) {
        const obj = results.hits[i]
        delete obj._highlightResult
        objs.push(obj)
      }

      resolve(objs)
    })
})
export const searchAlgoliaIndexPPT = (query) => new Promise((resolve) => {
  func.httpsCallable('searchAlgoliaIndexPPT')({
    query,
  })
    .then((response) => {
      const results = response.data

      const result = {
        people: [],
        projects: [],
        teams: [],
      }

      results.people.forEach((person) => {
        delete person._highlightResult
        result.people.push(person)
      })
      results.projects.forEach((project) => {
        delete project._highlightResult
        result.projects.push(project)
      })
      results.teams.forEach((team) => {
        delete team._highlightResult
        result.teams.push(team)
      })

      resolve(result)
    })
})

export const upsertAlgoliaRow = (objType, obj) => new Promise((resolve) => {
  func.httpsCallable('upsertAlgoliaRow')({
    objType,
    obj,
  })
    .then(() => {
      resolve()
    })
})

/*
// GOOGLE DOCS
export const getGoogleDocsMetadata = (id) => {
    return new Promise(function(resolve, reject) {
        var _getGoogleDocsMetadata = func.httpsCallable('getGoogleDocsMetadata');
        _getGoogleDocsMetadata({
            id: id
        })
        .then((result) => {
            resolve( result.data );
        })
    });
}
*/

// QLS
export const getQLSResponseRates = (managerID, managerName, quarter) => new Promise((resolve) => {
  func.httpsCallable('getQLSResponseRates')({
    managerID,
    managerName,
    quarter,
  })
    .then((result) => {
      resolve(result.data)
    })
})

export const getQLSResults = (managerID, managerName, aggregation, quarter) => new Promise((resolve) => {
  func.httpsCallable('getQLSResults')({
    managerID,
    managerName,
    aggregation,
    quarter,
  })
    .then((result) => {
      resolve(result.data)
    })
})

export const getQLSResultHeatMap = (managerID, managerName, quarter) => new Promise((resolve) => {
  func.httpsCallable('getQLSResultHeatMap')({
    managerID,
    managerName,
    quarter,
  })
    .then((result) => {
      resolve(result.data)
    })
})

export const getQLSResultHeatMaps = (managerID, managerName, aggregation, quarter) => new Promise((resolve) => {
  func.httpsCallable('getQLSResultHeatMaps')({
    managerID,
    managerName,
    aggregation,
    quarter,
  })
    .then((result) => {
      resolve(result.data)
    })
})

export const sendQLSReminders = (managerID, managerName, slackID, quarter) => new Promise((resolve) => {
  func.httpsCallable('sendQLSReminders')({
    managerID,
    managerName,
    slackID,
    quarter,
  })
    .then((result) => {
      resolve(result.data)
    })
})

// SLACK
// export const sendSlackMessage = (slackID, text, blocks) => new Promise((resolve) => {
//   func.httpsCallable('slackEm')({
//     slackID,
//     text,
//     blocks,
//   })
//     .then((result) => {
//       resolve(result.data)
//     })
// })

export const sendSlackMessage = async (slackID, text, blocks, path) => {
  const payload = {
    channel: slackID,
    text,
    blocks,
  }

  return apiRequest(SEND_SLACK_MESSAGE_ROUTE, 'POST', payload, path)
}

// PLAY STORE

export const getPlayStoreReview = (reviewId, packageName) => new Promise((resolve) => {
  func.httpsCallable('getPlayStoreReview')({
    reviewId,
    packageName,
  })
    .then((result) => {
      console.log(result)
      resolve(result.data)
    })
})

// export const replyPlayStoreReview = () => new Promise((resolve) => {
//   func.httpsCallable('replyPlayStoreReview')({
//     reviewId,
//     replyText,
//     packageName,
//   })
//     .then((result) => {
//       console.log(result)
//       resolve(result.data)
//     })
// })

export const replyPlayStoreReview = async (reviewId, replyText, packageName, path) => {
  const payload = {
    reviewId,
    replyText,
    packageName,
  }

  return apiRequest(POST_RESPONSE_ROUTE, 'POST', payload, path)
}
