import React from 'react'
import { Col, Row } from 'reactstrap'

import { db } from '../../assets/firebase'
import logo from '../../assets/img/goConnectIcon.png'
import { CUSTOMER_PLATFORM_TEAM_ID, GO_CLUB_TEAM_ID } from '../../components/Nexus/constants'
import callableFunction from '../mercury/api/functions'
import AverageDaysOfCompletion from './AverageDaysOfCompletion'
import CreatedVsResolvedIssues from './CreatedVsResolvedIssues'
import IssuesBySeverityBarGraph from './IssuesBySeverityBarGraph'
import IssuesFilter from './IssuesFilter'
import IssuesReportByPDG from './IssuesReportByPDG'
import IssuesReportByPDGPieChart from './IssuesReportByPDGPieChart'
import IssuesReportBySeverity from './IssuesReportBySeverity'
import IssuesTable from './IssuesTable'
import MonthlyAverageDaysToCompletion from './MonthlyAverageDaysToCompletion'
import { constructMonthlyChartData } from './nexusAnalyticsHelper'
import OpenIssuesByPDG from './OpenIssuesByPDG'
import SLA from './SLA'
import SLABreachedByTeam from './SLABreachedByTeamPieChart'
import SLAReportByPDG from './SLAReportByPDG'
import SLAResolutionResponseTimeBarGraph from './SLAResolutionResponseTimeBarGraph'
import TopCareIssuesPercentage from './TopCareIssuesPercentagePieChart'
import { getMonthsForChart, getTeamName } from './utils'

class NexusReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      issues: [],
      offset: 0,
      teams: [],
      fromTeamOptions: [],
      toPodOptions: [],
      toPDGOptions: [],
      allRequestOptions: [],
      countryOptions: [],
      subTeamOptions: [],
      toPDGs: {},
      allPods: [],
      totalIssues: 0,
      filter: {
        severity: [],
        status: [],
        fromTeamID: [],
        toPodID: [],
        toTeamID: [],
        startDate: '',
        endDate: '',
        searchKeyword: '',
        country: [],
        requestType: [],
        subTeam: [],
      },
      isDateRangeSelected: false,
      isLoading: true,
      sortBy: 'created',
      showSLAModel: false,
      page: 0,
      oldOldPage: null,
      lastIssueSnapshot: null,
      labels: [],
      monthlyChartData: {
        monthlySeverityData: {},
        monthlyPDGData: {},
        openIssuesByTeamData: {},
        issuesByPDGData: {},
        totalIssuesPast30Days: 0,
        weeklyCreatedIssuesData: [],
        weeklyResolvedIssuesData: [],
        topCareIssuesData: {},
        totalCareIssues: 0,
        averageDurationBySeverityData: {},
        monthlyAverageDurationBySeverityData: {},
        timeToResolutionData: [],
        timeToRespondData: [],
        monthlySLAPDGResponseData: {},
        monthlySLAPDGResolutionData: {},
        totalIssuesBreachedResponseSLAPDG: {},
      },
    }
    this.filterData = this.filterData.bind(this)
    this.handlePaginationClick = this.handlePaginationClick.bind(this)
    this.searchIssues = this.searchIssues.bind(this)
    this.deleteIssue = this.deleteIssue.bind(this)
    this.updateIssue = this.updateIssue.bind(this)
    this.sortIssues = this.sortIssues.bind(this)
    this.showSLA = this.showSLA.bind(this)
    this.getAnalyticsData = this.getAnalyticsData.bind(this)
  }

  async buildFilterData() {
    const filters = await db.getNexusFilters()
    const filterOptions = {}

    filters.forEach((element) => {
      filterOptions[element.filter] = element.value
    })
    this.setState(filterOptions)
  }

  async getIssues(teams) {
    const [issues, last] = await db.getNexusIssuesWithPagination(0, 1, this.state.filter, teams)
    this.setState({
      issues,
      lastIssueSnapshot: last,
      isLoading: false,
    })
  }

  async getAnalyticsData() {
    const { teams, filter } = this.state
    const { startDate, endDate } = filter
    const today = new Date()
    const pastSixMonthsDate = new Date(today - (5 * 30 * 86400000))
    const pastSixMonths = new Date(pastSixMonthsDate.getFullYear(), pastSixMonthsDate.getMonth(), 1)
    if (!startDate && !endDate) {
      filter.startDate = pastSixMonths
    }
    const analyticsData = await db.getNexusAnalyticsData(filter)
    const labels = getMonthsForChart(startDate, endDate)
    const monthlyChartData = constructMonthlyChartData(analyticsData, labels, teams)

    this.setState({
      labels, monthlyChartData,
    })
  }

  getTotalNexusIssues(filters) {
    const getTotalNexusIssues = callableFunction('getTotalNexusIssues')
    getTotalNexusIssues(filters).then((resp) => {
      const { data } = resp
      this.setState({
        totalIssues: data,
      })
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Error in Running Query: ', err)
    })
  }

  async fetchdata() {
    const teams = await db.getTeams()
    this.setState({
      teams,
    })
    this.getIssues(teams)
    this.buildFilterData()
    this.getTotalNexusIssues(this.state.filter)
    this.getAnalyticsData()
  }

  componentDidMount() {
    this.fetchdata()
  }

  async applyFilter(filters) {
    const [issues, last] = await db.getNexusIssuesWithPagination(0, 1, filters, this.state.teams)
    this.getTotalNexusIssues(filters)
    this.getAnalyticsData()

    this.setState({
      issues,
      lastIssueSnapshot: last,
      offset: 0,
      page: 0,
      filter: filters,
    })
  }

  searchIssues(value) {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        searchKeyword: value || '',
      },
    }))
  }

  filterData(filterType, value) {
    const isDateFilter = filterType === 'startDate' || filterType === 'endDate'

    let filterValue

    if (isDateFilter) {
      filterValue = value || ''
      this.setState({ isDateRangeSelected: filterValue.length > 0})
    } else if (filterType === 'searchKeyword') {
      filterValue = this.state.filter.searchKeyword || ''
    } else {
      filterValue = []
      if (value) {
        value.forEach((elem) => {
          filterValue.push(elem.value)
        })
      }
    }

    const filterdata = {
      [filterType]: filterValue,
    }

    if (filterType === 'toTeamID') {
      const toPodOptions = []
      if (value && value.length > 0) {
        value.forEach((team) => {
          const pods = this.state.toPDGs[team.value]
          pods.forEach((id) => {
            let teamName = ''
            if (team.value === CUSTOMER_PLATFORM_TEAM_ID && id !== GO_CLUB_TEAM_ID) {
              // For Customer Platform team: if Pod != 'GoClub Business', then use the pod name for value
              teamName = id
            } else {
              teamName = getTeamName(this.state.teams, id)
            }

            if (teamName) {
              const teamObj = {
                label: teamName,
                value: id,
              }
              toPodOptions.push(teamObj)
            }
          })
        })

        this.setState({
          toPodOptions: toPodOptions.sort((a, b) => a.label.localeCompare(b.label)),
        })
      } else {
        this.setState((prevState) => ({
          toPodOptions: prevState.allPods,
        }))
      }
    }

    const newFilterData = { ...this.state.filter, ...filterdata }
    this.applyFilter(newFilterData)
    this.setState({
      filter: newFilterData,
    })
  }

  async handlePaginationClick(oldOffset, newOffset, isPrev) {
    let {
      page: newPage, teams, lastIssueSnapshot: lastIssue, filter, oldOldPage,
    } = this.state
    if (newOffset >= 15 && newOffset % 15 === 0) {
      newPage = newOffset / 15
    }
    if (isPrev) {
      newPage -= 1
    }

    let isNextToPrevPage = false
    let isPrevToNextPage = false
    if (oldOldPage) {
      isNextToPrevPage = oldOldPage < oldOffset && oldOffset > newOffset
      isPrevToNextPage = oldOldPage > oldOffset && oldOffset < newOffset
    }

    const [issues, lastIssueSnapshot] = (oldOffset > newOffset) || isPrev ?
      await db.getNexusIssuesWithPaginationPrev(
          isNextToPrevPage,
          oldOffset + 1,
          newOffset + 1,
          lastIssue,
          teams,
          filter,
      ) :
      await db.getNexusIssuesWithPaginationNext(
          isPrevToNextPage,
          oldOffset + 1,
          newOffset + 1,
          lastIssue,
          teams,
          filter,
      )

    this.setState({
      offset: newOffset,
      page: newPage,
      issues,
      lastIssueSnapshot,
      oldOldPage: oldOffset,
    })
  }

  deleteIssue(issueId) {
    const issues = [...this.state.issues]
    for (let i = 0; i < issues.length; i += 1) {
      if (issues[i].id === issueId) {
        issues.splice(i, 1)
        break
      }
    }
    this.setState({ issues })
  }

  updateIssue(issue) {
    const issues = [...this.state.issues]
    for (let i = 0; i < issues.length; i += 1) {
      if (issues[i].id === issue.id) {
        issues[i] = issue
        break
      }
    }
    this.setState({ issues })
  }

  showLoading() {
    if (this.state.isLoading) return 'block'
    return 'none'
  }

  sortIssues(sortBy, issues) {
    const severityOrdering = {
      critical: 0,
      high: 1,
      medium: 2,
      low: 3,
    }

    if (sortBy === this.state.sortBy) { // reverse the current state
      issues.reverse()
    } else if (sortBy === 'severity') issues.sort((a, b) => severityOrdering[a.severity] - severityOrdering[b.severity])
    else if (sortBy === 'pdg') issues.sort((a, b) => (a.toTeamName ? a.toTeamName.localeCompare(b.toTeamName) : 1))
    else if (sortBy === 'pod') issues.sort((a, b) => (a.toPodName ? a.toPodName.localeCompare(b.toPodName) : 1))
    else if (sortBy === 'status') issues.sort((a, b) => a.status.localeCompare(b.status))
    else if (sortBy === 'created') issues.sort((a, b) => b.created - a.created)
    else if (sortBy === 'jira') issues.sort((a, b) => a.jira.key.localeCompare(b.jira.key))

    this.setState({ issues, sortBy })
  }

  showSLA() {
    this.setState({
      showSLAModel: true,
    })
  }

  render() {
    const {
      issues, isLoading, fromTeamOptions, toPodOptions, toPDGOptions,
      totalIssues, offset, filter, isDateRangeSelected, allRequestOptions,
      showSLAModel, countryOptions, subTeamOptions, page, labels, monthlyChartData,
    } = this.state

    const {
      monthlySeverityData, monthlyPDGData, issuesByPDGData, totalIssuesPast30Days, openIssuesByTeamData,
      weeklyCreatedIssuesData, weeklyResolvedIssuesData, timeToResolutionData, timeToRespondData,
      averageDurationBySeverityData, monthlyAverageDurationBySeverityData, topCareIssuesData,
      totalCareIssues, monthlySLAPDGResponseData, monthlySLAPDGResolutionData, totalIssuesBreachedResponseSLAPDG,
    } = monthlyChartData

    const loadingDiv = {
      display: this.showLoading(),
      marginTop: '50px',
      textAlign: 'center',
      minHeight: '100px',
    }

    const link = {
      color: 'green',
      fontWeight: 700,
      fontSize: '14px',
      cursor: 'pointer',
      marginBottom: '10px',
    }

    return (
      <div style={{ padding: '20px' }}>
        <style>
          {
            `
              @keyframes spinning {
                  from { transform: rotate(0deg) }
                  to { transform: rotate(360deg) }
              }
              .spin {
                  animation-name: spinning;
                  animation-duration: 3s;
                  animation-iteration-count: infinite;
                  /* linear | ease | ease-in | ease-out | ease-in-out */
                  animation-timing-function: linear;
              }
              `
          }
        </style>
        <Row>
          <Col xs="12">
            <div style={loadingDiv}>
              <div className="spin">
                <img src={logo} alt="logo" style={{ height: '43px', width: '43px' }} />
              </div>
            </div>
          </Col>
        </Row>
        {!isLoading &&
          (
            <>
              <Row>
                <Col sm="12" md="12">
                  <IssuesFilter
                    filterData={this.filterData}
                    fromTeamOptions={fromTeamOptions}
                    toPodOptions={toPodOptions}
                    toPDGOptions={toPDGOptions}
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    allRequestOptions={allRequestOptions}
                    countryOptions={countryOptions}
                    subTeamOptions={subTeamOptions}
                    searchKeyword={filter.searchKeyword}
                    searchIssues={this.searchIssues}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="6">
                  <IssuesBySeverityBarGraph
                    labels={labels}
                    monthlySeverityData={monthlySeverityData}
                  />
                </Col>
                <Col xs="12" sm="12" md="6">
                  <OpenIssuesByPDG
                    openIssuesByTeam={openIssuesByTeamData}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '40px' }}>
                <Col xs="12" sm="12" md="8">
                  <IssuesTable
                    issues={issues}
                    offset={offset}
                    totalIssues={7000}
                    handlePaginationClick={this.handlePaginationClick}
                    deleteIssue={this.deleteIssue}
                    updateIssue={this.updateIssue}
                    sortIssues={this.sortIssues}
                    page={page}
                  />
                </Col>
                <Col xs="12" sm="12" md="4">
                  <IssuesReportBySeverity
                    labels={labels}
                    monthlySeverityData={monthlySeverityData}
                  />
                  <br />
                  <IssuesReportByPDG
                    labels={labels}
                    monthlyPDGData={monthlyPDGData}
                  />
                  <br />
                  <IssuesReportByPDGPieChart
                    isDateRangeSelected={isDateRangeSelected}
                    issuesByPDG={issuesByPDGData}
                    totalIssues={totalIssues}
                    totalIssuesPast30Days={totalIssuesPast30Days}
                  />
                  <br />
                  <CreatedVsResolvedIssues
                    weeklyCreatedIssues={weeklyCreatedIssuesData}
                    weeklyResolvedIssues={weeklyResolvedIssuesData}
                  />
                  <br />
                  <div onClick={this.showSLA} style={link}>
                    Show SLA Details
                  </div>
                  <SLAResolutionResponseTimeBarGraph
                    labels={labels}
                    timeToResolution={timeToResolutionData}
                    timeToRespond={timeToRespondData}
                  />
                  <br />
                  <SLAReportByPDG
                    monthlySLAPDGResponseData={monthlySLAPDGResponseData}
                    monthlySLAPDGResolutionData={monthlySLAPDGResolutionData}
                    labels={labels}
                  />
                  <br />
                  <SLABreachedByTeam
                    issuesGroupedByTeam={totalIssuesBreachedResponseSLAPDG}
                    teams={this.state.teams}
                    chartTitle="SLA BREACHED BY PDG"
                  />
                  <br />
                  <AverageDaysOfCompletion
                    averageDurationBySeverity={averageDurationBySeverityData}
                    isDateRangeSelected={isDateRangeSelected}
                  />
                  <br />
                  <MonthlyAverageDaysToCompletion
                    monthlyAverageDurationBySeverity={monthlyAverageDurationBySeverityData}
                    labels={labels}
                  />
                  <br />
                  <>
                    <TopCareIssuesPercentage
                      topCareIssues={topCareIssuesData}
                      isDateRangeSelected={isDateRangeSelected}
                      totalCareIssues={totalCareIssues}
                    />
                    <br />
                  </>
                  {showSLAModel && <SLA toggle={() => this.setState({ showSLAModel: false })} />}
                </Col>
              </Row>
            </>
          )}
      </div>
    )
  }
}

export default NexusReports
