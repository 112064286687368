import React from 'react'
import {
  Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap'

import StatusEditor from '../../StatusEditor/StatusEditor'
import { getHTML, getStatusDescriptor } from '../../utils'
import ProjectAuthorCard from '../ProjectAuthorCard'
import ProjectCardLinks from '../ProjectCardLink'

function StatusReportRow({
  onStatusUpdated, showEditButton, statusReport, index,
}) {
  const {
    author, createdBy, details, links, published, status, title,
  } = statusReport

  return (
    <Row key={index}>
      <Col sm="12">
        <Card>
          <CardHeader style={{
            height: '5px',
            padding: '5px',
            backgroundColor: status,
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
          }}
          />

          <CardBody style={{ padding: '20px', fontSize: '13px', fontWeight: 500 }}>
            <div style={{ textAlign: 'right', fontWeight: 600, color: status }}>
              {getStatusDescriptor(status)}
            </div>

            <div style={{ fontWeight: 600, display: 'inline-block', verticalAlign: 'middle' }}>
              {title}
            </div>

            <div className="ql-editor" style={{ marginTop: '8px', marginBottom: '0px' }}>
              {getHTML(details)}
            </div>

            <ProjectCardLinks links={links} />

            <ProjectAuthorCard
              author={author}
              createdBy={createdBy}
              publishedAt={published}
            />

            {showEditButton && (
              <div>
                <StatusEditor
                  statusReport={statusReport}
                  onUpdated={(currStatusReport) => onStatusUpdated(currStatusReport)}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

function StatusTab({
  projectID, personID,
  isLoading,
  statusReports,
  onStatusCreated, onStatusUpdated,
}) {
  return projectID && (
    <>
      <Row>
        <Col xs="12" style={{ textAlign: 'right' }}>
          <StatusEditor
            projectID={projectID}
            onCreated={(statusReport) => onStatusCreated(statusReport)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col xs="12">
          <div style={{ minHeight: '89px' }}>
            {!isLoading && (
              <div>
                {statusReports.map((statusReport, index) => (
                  <StatusReportRow
                    onStatusUpdated={onStatusUpdated}
                    showEditButton={statusReport.authorID === personID}
                    statusReport={statusReport}
                    statusReportIdx={index}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}
export default StatusTab
