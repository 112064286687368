import React from 'react'
import {
  Card, CardBody, CardHeader, Col, Row, Table,
} from 'reactstrap'

export default function ShowReassignedData({ reassignedIssues, closeReassignedData }) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="10">
            <div
              className="numbers pull-left"
              style={{ display: 'inline-block', marginLeft: '2px' }}
            >
              {reassignedIssues.length}
            </div>
            <div style={{
              fontSize: '12px', display: 'inline-block', marginLeft: '10px', marginTop: '15px',
            }}
            >
              ticket(s) tagged incorrectly
            </div>
          </Col>
          <Col sm="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button
              style={{
                height: '27px', width: '25px', margin: '-18px', border: '1px solid #dee2e6', borderRadius: '5px',
              }}
              type="button"
              onClick={closeReassignedData}
            >
              X
            </button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table striped>
          <thead>
            <tr style={{ fontSize: '12px', fontWeight: 'bold' }}>
              <td style={{ width: '80px' }}>Jira Ticket</td>
              <td style={{ textAlign: 'center' }}>Re-assigned PDGs/Pods</td>
            </tr>
          </thead>
          <tbody>
            {reassignedIssues.map((issue) => (
              <tr style={{ fontSize: '12px' }} key={issue.jira.id}>
                <td>{issue.jira.key}</td>
                <td style={{ textAlign: 'center' }}>
                  {issue.reassignments.map((reassignment) => reassignment.prevPod && `${reassignment.prevPod} -> `)}
                  <span style={{ color: 'green' }}>{issue.jira.toPod}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
