//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected} from 'redux/actions';

//assets
import { db } from 'assets/firebase';
import { arraySort, telemetry } from 'assets/utils';
import logo from "assets/img/goConnectIcon.png";

// reactstrap components
import { Row, Col, Card, CardBody, Button,
        Carousel, CarouselItem, CarouselCaption, CarouselControl, 
        TabContent, TabPane, Nav, NavItem, NavLink,
      } from "reactstrap";

import KudosCard from 'components/KudosCard/KudosCard.js';
import PersonCard from 'components/PersonCard/PersonCard.js';
import PersonEditor from 'components/PersonEditor/PersonEditor.js';
import ProjectCard from 'components/ProjectCard/ProjectCard.jsx';
import TeamCard from 'components/TeamCard/TeamCard.js';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      renderProfile: false,
      person: {
        photos: []
      },
      manager: {},
      activeIndex: 0,
      teams: [],
      projects: [],
      directReports: [],
      kudos: [],
      activeTab: 'personal',
    };

    var apiKey = this.props.match.params["apiKey"];
    if(apiKey === 'jNMoFr3YecTLCZ5ErkUM') {
        this.state.renderProfile = true;
    }
  }

  componentDidMount() {
   
    if(this.props.user.authenticated) {
      
      var employeeID = this.props.match.params["employeeID"];

      db.getPersonByEmployeeID(employeeID).then(person => {

        if(!person.photos) person.photos = [];

        this.setState({person: person}, () => {
          this.setState({isLoading: false});

          // manager
          if(person.managerID) {
            db.getPerson(person.managerID).then(manager => {
                if(manager)
                    this.setState({manager: manager});
            });
          }

          // direct reports
          db.getPeopleByManagerID(person.id).then(directReports => {
            directReports.sort(arraySort.byName);
            this.setState({directReports: directReports});
          })

          // teams
          db.getTeamsByPerson(person.id).then((teamMembers) => {
                    
            var promises = [];
            for(var i = 0; i < teamMembers.length; i++) 
                promises.push(db.getTeam(teamMembers[i].teamID));
            
            Promise.all(promises).then(teams => {
                teams.sort(arraySort.byName);
                this.setState({teams: teams});
            })
          });

          // projects
          db.getProjectsByPerson(person.id).then((projectMembers) => {
              var promises = [];
              for(var i = 0; i < projectMembers.length; i++) 
                  promises.push(db.getProject(projectMembers[i].projectID));
              
              Promise.all(promises).then(projects => {
                  projects.sort(arraySort.byName);
                  this.setState({projects: projects});
              })
          });

          // kudos
          db.getAppreciationByPerson(person.id).then(kudos => {

            Promise.all(kudos.map(async (i, index) => {	
              let person = await db.getPerson(i.appreciatorID);
              if(person)
                kudos[index].appreciatorName = person.firstName + " " + person.lastName;	
              
              return;	
            })).then(() => {	
                  this.setState({ kudos: kudos });	
            })
          });
        
        });
      });

      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "oneGojekProfile", "pageView");

    }
  }

  showLoading() {
    if(this.state.isLoading)
      return "block";
    else 
      return "none";
  }

  showProfile() {
    if(!this.state.isLoading)
      return "block";
    else 
      return "none";
  }

  showLinkedIn() {
    if(this.state.person.linkedIn)
        return "inline-block";
    else
        return "none";
  }

  showInstagram() {
    if(this.state.person.instagram)
        return "inline-block";
    else
        return "none";
  }

  showTwitter() {
    if(this.state.person.twitter)
        return "inline-block";
    else
        return "none";
  }

  showGithub() {
    if(this.state.person.github)
        return "inline-block";
    else
        return "none";
  }

  showPhone() {
    if(this.state.person.phone) 
        return "flex";
    else 
        return "none";
  }

  showWorkingHours() {
    if(this.state.person.workingHours) 
        return "flex";
    else 
        return "none";
  }

  showResponsibilities() {
    if(this.state.person.responsibilities) 
        return "flex";
    else 
        return "none";
  }

  showEducation() {
    if(this.state.person.education) 
        return "flex";
    else 
        return "none";
  }

  showBirthday() {
    if(this.state.person.birthday) 
        return "flex";
    else 
        return "none";
  }

  showDirects() {
    if(this.state.directReports.length > 0)
      return "block";
    else 
      return "none";
  }

  slackEm() {
    if(this.state.person.slackID)
        window.open('slack://user?team=T02T4D001&id=' + this.state.person.slackID);
  }

  emailEm() {
    if(this.state.person.email)
        window.open('mailto:' + this.state.person.email);
  }

  showOrg() {
    const url = 'https://www.gocnnct.com/orgChart/' + this.state.person.id;
    window.open(url);
  }

  allowEdit() {
    if(this.state.person.id === this.props.user.personID || this.props.user.isAdmin)
        return "inline-block";
    else
        return "none";
  }

  render() {
  
    if (!this.props.user.authenticated) {
      this.props.onUserRedirected(this.props.location.pathname);
      return <Redirect to='/login' />
    }

    const loadingDiv = {
      display: this.showLoading(),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign:'center'
    }
    const icon = {
      fontSize:"17px",
      marginTop:"3px",
      color:"#898989"
    }
    const navIcon = {
      fontSize:"17px",
      marginTop:"3px",
      cursor:"pointer"
    }
    const subText = {
      color:"#545454"
    }

    const activeLink = {
      color:"white",
      fontWeight:600,
      fontSize:"12px"
    }
    const link = {
        color:"#434343",
        fontWeight:400,
        fontSize:"12px",
        cursor:"pointer"
    }
    const activeTab = {
        backgroundColor:'green',
        borderStyle: 'none',
        borderWidth: '1px 1px 0px 1px',
        borderColor: 'green'
    }
    const tab = {
        backgroundColor:"#efefef",
        marginLeft:"1px",   
    }

    const slides = this.state.person.photos.map((item, index) => {
      
      return (
        <CarouselItem
          onExiting={() => this.setState({animating:true})}
          onExited={() => this.setState({animating:false})}
          key={index}
        >
          <div style={{backgroundSize: "cover", height:"260px", maxHeight:"260px", width:"100%", backgroundColor:"#aaaaaa", 
              backgroundImage:`url(${item.url})`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}>
          </div>
          <CarouselCaption captionText={item.name} captionHeader={item.caption} />
        </CarouselItem>
      );
      
    });

    const next = () => {
      if (this.state.animating) return;
      const nextIndex = this.state.activeIndex === this.state.person.photos.length - 1 ? 0 : this.state.activeIndex + 1;
      setActiveIndex(nextIndex);
    }
    
    const previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.person.photos.length - 1 : this.state.activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const setActiveIndex = (index) => {
      this.setState({activeIndex: index});
    }

    if(!this.state.renderProfile) {
        return <div style={{padding:"20px"}}>access denied</div>
    }

    return (
      <div>

        <style>
        {
            `
              @keyframes spinning {
                from { transform: rotate(0deg) }
                to { transform: rotate(360deg) }
              }
              .spin {
                animation-name: spinning;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                /* linear | ease | ease-in | ease-out | ease-in-out */
                animation-timing-function: linear;
              }
              .carousel {
                max-width: 100%;
                height: 240px;
                background: black;
                padding: 0px;
              }
              .socialIcon {
                color: #696969;
              }
              .socialIcon:hover {
                color: green;
              }
              `
        }
        </style>

        <div style={loadingDiv}>
          <div className="spin">
            <img src={logo} alt="logo" style={{height:"54px", width:"54px"}} />
          </div>
        </div>

        <div style={{display:this.showProfile()}}>

          <Row style={{height:"650px", backgroundColor:"#eeeeee"}}>
            <Col xs="12" sm="12" md="12" lg="6" xl="5">
              <div style={{padding:"20px", paddingRight:"20px"}}>

                <Card>

                  <CardBody style={{padding:"0px"}}>
                    
                    <Carousel
                        className="carousel"
                        activeIndex={this.state.activeIndex}
                        next={next}
                        previous={previous}
                        ride="carousel"
                        >
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                    </Carousel>
                  
                    <div style={{padding:"30px", fontSize:"12px"}}>
                      
                      <Row>
                        <Col xs="2">
                            <i style={icon} className="nc-icon nc-badge" />
                        </Col>
                        <Col xs="10">
                            {this.state.person.title}<br /><br />
                        </Col>
                      </Row>
                      <Row>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-pin-3" />
                          </Col>
                          <Col xs="10">
                              {this.state.person.location}<br /><br />
                          </Col>
                      </Row>
                      <Row style={{display:this.showWorkingHours()}}>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-watch-time" title="normal working hours" />
                          </Col>
                          <Col xs="10">
                              {this.state.person.workingHours} <span style={subText}>{this.state.person.timeZone}</span><br /><br />
                          </Col>
                      </Row>
                      <Row style={{display:this.showPhone()}}>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-mobile" />
                          </Col>
                          <Col xs="10">
                              {this.state.person.phone}<br /><br />
                          </Col>
                      </Row>
                      <Row>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-email-85" />
                          </Col>
                          <Col xs="10">
                              {this.state.person.email}<br /><br />
                          </Col>
                      </Row>
                      <Row>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-vector" title="manager" />
                          </Col>
                          <Col xs="10">
                              {this.state.manager.name}<br /><br />
                          </Col>
                      </Row>
                      <Row style={{display:this.showResponsibilities()}}>
                          <Col xs="2">
                              <i style={icon} className="nc-icon nc-chat-33" title="ask me about" />
                          </Col>
                          <Col xs="10">
                              {this.state.person.responsibilities}<br /><br />
                          </Col>
                      </Row>     

                      <Row>
                        <Col xs="12" style={{textAlign:"center"}}>
                          <Button color="secondary" onClick={() => this.showOrg()} size="sm" >
                              <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                  <i style={navIcon} className="nc-icon nc-vector" />  
                              </div>
                              <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                  Chart
                              </div>    
                          </Button>
                          {/*}
                          <Button color="secondary" onClick={() => this.emailEm()} size="sm">
                              <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                  <i style={navIcon} className="nc-icon nc-email-85" />  
                              </div>
                              <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                  Email
                              </div>
                          </Button>
                          <Button color="success" onClick={() => this.slackEm()} size="sm">
                              <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                  <i style={navIcon} className="nc-icon nc-chat-33" />  
                              </div>
                              <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                  Slack
                              </div>
                          </Button>
                          */}
                          <div style={{display:this.allowEdit(),marginLeft:"6px"}}>
                            <PersonEditor person={this.state.person} teams={this.state.teams} projects={this.state.projects} size="sm" />
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </CardBody>
                </Card>
              </div>
            </Col>
            
            <Col md="6" lg="6" xl="7" className="d-none d-lg-block" style={{maxHeight:"640px", overflow:"hidden"}}>
              <div style={{padding:"20px", paddingBottom:"0px", paddingRight:"30px"}}>

                <Row>
                    <Col xs="8" style={{fontSize:"21px", marginBottom:"17px"}}>
                        {this.state.person.name}
                    </Col>
                    <Col xs="4" style={{textAlign:"right", fontSize:"19px", marginBottom:"17px"}} className="d-none d-lg-block">
                        <div style={{marginLeft:"10px", cursor:"pointer", display:this.showLinkedIn()}} onClick={() => window.open(this.state.person.linkedIn)}>
                            <i className="socialIcon fab fa-linkedin"></i>
                        </div>
                        <div style={{marginLeft:"10px", cursor:"pointer", display:this.showInstagram()}} onClick={() => window.open(this.state.person.instagram)}>
                            <i className="socialIcon fab fa-instagram"></i>
                        </div>
                        <div style={{marginLeft:"10px", cursor:"pointer", display:this.showTwitter()}} onClick={() => window.open(this.state.person.twitter)}>
                            <i className="socialIcon fab fa-twitter"></i>
                        </div>
                        <div style={{marginLeft:"10px", cursor:"pointer", display:this.showGithub()}} onClick={() => window.open(this.state.person.github)}>
                            <i className="socialIcon fab fa-github"></i>
                        </div>
                    </Col>
                </Row>

                <Nav style={{paddingTop:"0px"}}>
                  <div style={{display:this.showDirects()}}>
                    <NavItem style={(this.state.activeTab === 'directs' ? activeTab : tab )}>
                        <NavLink
                            style={(this.state.activeTab === 'directs' ? activeLink : link )}
                            onClick={() => { this.setState({activeTab: 'directs'}) }}
                        >
                            Direct Reports
                        </NavLink>
                    </NavItem>
                  </div>
                  <NavItem style={(this.state.activeTab === 'kudos' ? activeTab : tab )}>
                      <NavLink
                          style={(this.state.activeTab === 'kudos' ? activeLink : link )}
                          onClick={() => { this.setState({activeTab: 'kudos'}) }}
                      >
                          Kudos
                      </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'personal' ? activeTab : tab )}>
                      <NavLink
                          style={(this.state.activeTab === 'personal' ? activeLink : link )}
                          onClick={() => { this.setState({activeTab: 'personal'}) }}
                      >
                          Personal
                      </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'projects' ? activeTab : tab )}>
                      <NavLink
                          style={(this.state.activeTab === 'projects' ? activeLink : link )}
                          onClick={() => { this.setState({activeTab: 'projects'}) }}
                      >
                          Projects
                      </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'teams' ? activeTab : tab )}>
                      <NavLink
                          style={(this.state.activeTab === 'teams' ? activeLink : link )}
                          onClick={() => { this.setState({activeTab: 'teams'}) }}
                      >
                          Teams
                      </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab} style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green', paddingTop:"3px"}}>
                  <TabPane tabId="personal" style={{minHeight:"210px", paddingTop:"17px"}}>
                    <Card style={{margin:"10px"}}>
                      <CardBody style={{fontSize:"12px", paddingTop:"30px"}}>
                          <Row> 
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-touch-id" title="Fun Fact" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.funFact}<br /><br />
                              </Col>
                          </Row>
                          <Row>
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-single-02" title="Family" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.family}<br /><br />
                              </Col>
                          </Row>
                          <Row>
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-palette" title="Hobbies" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.hobbies}<br /><br />
                              </Col>
                          </Row>
                          <Row>
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-note-03" title="Music" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.music}<br /><br />
                              </Col>
                          </Row>
                          <Row style={{display:this.showEducation()}}>
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-hat-3" title="Alma Mater" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.education}<br /><br />
                              </Col>
                          </Row>
                          <Row>
                              <Col xs="1">
                                  <i style={icon} className="nc-icon nc-world-2" title="Former Homes" />
                              </Col>
                              <Col xs="11">
                                  {this.state.person.formerHomes}<br /><br />
                              </Col>
                          </Row>
                          <Row style={{display:this.showBirthday()}}>
                              <Col xs="1">
                                  <i className="socialIcon fas fa-birthday-cake" style={{fontSize:"21px", marginLeft:"2px"}} title="Birthday"></i>
                              </Col>
                              <Col xs="11">
                                  {this.state.person.birthday}<br /><br />
                              </Col>
                          </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="teams" >
                    <Row>
                      <Col xs="12">
                        {this.state.teams.map((team, index) => (    
                          <div style={{display:"inline-block", marginRight:"15px"}} key={index}>
                            <TeamCard team={team}/>
                          </div>
                        ))}                        
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="projects" >
                    <Row>
                      <Col xs="12">
                        {this.state.projects.map((project, index) => (    
                          <div style={{display:"inline-block", marginRight:"15px"}} key={index}>
                            <ProjectCard project={project}/>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="directs" >
                    <Row>
                      <Col xs="12">
                        {this.state.directReports.map((person, index) => (    
                          <div style={{display:"inline-block", marginRight:"15px"}} key={index}>
                            <PersonCard person={person} />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="kudos" >
                    <Row>
                      <Col xs="12">
                        {this.state.kudos.map((kudo, index) => (    
                          <div style={{display:"inline-block", marginRight:"15px"}} key={index}>
                            <KudosCard appreciateObject={kudo} />	
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>          
          </Row>

          <div style={{marginLeft:"50px", paddingTop:"50px", display:"none"}}>
            <div className="d-xs-block">XS</div>
            <div className="d-none d-sm-block">SM</div>
            <div className="d-none d-md-block">MD</div>
            <div className="d-none d-lg-block">LG</div>
            <div className="d-none d-xl-block">XL</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      onUserRedirected: UserRedirected
       }, dispatch);  
}

ProfilePage = connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
export default ProfilePage;