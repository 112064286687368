import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected} from 'redux/actions';

//utils
import { telemetry } from 'assets/utils';

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from "react-router-dom";
import Navbar from "components/Navbars/LearningJournalNavbar";
import LearningJournal from "views/learningJournal/LearningJournal.js";

var ps;

class LearningJournalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      othersPresent: []
    };

  }
  componentDidMount() {
    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "learningJournal", "pageView").then(activity => {
      setInterval(() => telemetry.trackDwellTime(activity),60000);
    });

    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    */

    document.title = 'Learning Journal';
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };


  render() {

    if (!this.props.user.authenticated) {
      this.props.onUserRedirected('/learningJournal');
      return <Redirect to='/login' />
    }

    return (
      <div className="wrapper" style={{backgroundColor:"#efefef"}}>
        <Navbar {...this.props} />
        <div style={{marginTop:"63px", padding:"34px"}}>
            <LearningJournal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      onUserRedirected: UserRedirected
       }, dispatch);  
}

LearningJournalLayout = connect(mapStateToProps, mapDispatchToProps)(LearningJournalLayout);
export default LearningJournalLayout;
