import React from "react";

import { connect } from 'react-redux';

import { 
    Alert,
    Button, CustomInput,
    Row, Col, 
    Modal, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import Tag from "components/Tag/Tag.js"; 
import TeamCard from 'components/TeamCard/TeamCard.js';
import TeamSearch from 'components/TeamSearch/TeamSearch.js';
import ProjectCard from 'components/ProjectCard/ProjectCard.jsx';
import ProjectSearch from 'components/ProjectSearch/ProjectSearch.js';

//fileUploader
import FileUploader from 'react-firebase-file-uploader';

//firebase
import { db, storage } from 'assets/firebase';
import { arraySort, telemetry } from 'assets/utils';

import { algolia } from 'assets/algolia';


const uuid = require('uuid');

class PersonEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: '1',
            showPrivacyWarning: true,
            progress: 0,
            isUploading: false,
            teams: [],
            projects: []
        };

        var person = props.person;
        if(!person.photos) person.photos = [];
        this.state.person = person;

        if(props.teams) this.state.teams = props.teams;

        this.photoUploadSuccess = this.photoUploadSuccess.bind(this);
        this.savePerson = this.savePerson.bind(this);        
        this.toggleModal = this.toggleModal.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.person !== this.props.person) {
            var person = this.props.person;
            if(!person.photos) person.photos = [];
            this.setState({person: this.props.person});
        }
        if(prevProps.teams !== this.props.teams) {
            this.setState({teams: this.props.teams});
        }
    }

    toggleModal() {
        if(!this.state.modal) {
            this.getPerson();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "personEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getPerson() {

        var person = this.state.person;

        // get projects
        db.getProjectsByPerson(person.id).then((personProjects) => {

            var promises = [];
            for(var i = 0; i < personProjects.length; i++) 
                promises.push(db.getProject(personProjects[i].projectID));
            
            Promise.all(promises).then(projects => {
                projects.sort(arraySort.byName);
                this.setState({projects: projects, personProjects: personProjects});
            })
        });

        // get teams
        db.getTeamsByPerson(person.id).then((personTeams) => {

            var promises = [];
            for(var i = 0; i < personTeams.length; i++) 
                promises.push(db.getTeam(personTeams[i].teamID));
            
            Promise.all(promises).then(teams => {
                teams.sort(arraySort.byName);
                this.setState({teams: teams, personTeams: personTeams});
            })
        });
    }

    updatePerson(property, value) {
        var obj = this.state.person;
        obj[property] = value;
        this.setState({person: obj});
    }

    addToTeam(team) {
        var teams = this.state.teams;
        teams.push(team);
        this.setState({teams: teams});
        
    }
    addToProject(project) {
        var projects = this.state.projects;
        projects.push(project);
        this.setState({projects: projects});
    }

    deleteProject(project) {
        var projects = this.state.projects;
        for(var i = 0; i < projects.length; i++) {
            if(projects[i].id === project.id) {
                projects.splice(i,1);
                break;
            }
        }
        this.setState({projects: projects});
    }
    deleteTeam(team) {
        var teams = this.state.teams;
        for(var i = 0; i < teams.length; i++) {
            if(teams[i].id === team.id) {
                teams.splice(i,1);
                break;
            }
        }
        this.setState({teams: teams});
    }

    // photos
    photoUploadStart = () => {
        this.setState({isUploading: true, progress: 0});
    };
    photoUploadProgress = (progress) => {
        this.setState({progress});
    };
    photoUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    photoUploadSuccess = (filename) => {
        this.setState({progress: 100, isUploading: false});
        storage.profilePhotos.child(filename).getDownloadURL().then(url => this.addPhoto(url, false));
    };
    photoUploadSuccess2 = (filename) => {
        this.setState({progress: 100, isUploading: false});
        storage.profilePhotos.child(filename).getDownloadURL().then((url) => {
            this.addPhoto(url, true);
            this.updatePerson('profilePhoto', url);
        });
    };

    addPhoto(url, isPrimary) {

        var obj = this.state.person;
        if(!obj.photos) {
          obj.photos = [];
        }
  
        var photo = { id: uuid.v4(), name: "", description: "", url: url, isPrimary: isPrimary};
  
        obj.photos.push(photo);
        this.setState({person:obj});
    }

    // todo, cleanup storage when photo is deleted
    deletePhoto(photo) {

        var obj = this.state.person;
        for(var i = 0; i < obj.photos.length; i++) {
            if(obj.photos[i].id === photo.id) {
                obj.photos.splice(i,1);
                break;
            }
        }
        this.setState({person:obj});
    }

    showNewPhotoButton() {
        if(this.state.activeTab==='2')
          return "block";
        else
          return "none";
    }
    showNewTeamButton() {
        if(this.state.activeTab==='3')
          return "block";
        else
          return "none";
    }
    showNewProjectButton() {
        if(this.state.activeTab==='4')
          return "block";
        else
          return "none";
    }

    // save
    savePerson() {
     
        var person = this.state.person;
        delete person.isSelected;

        // save team memberships
        for(var i = 0; i < this.state.teams.length; i++) {
            var isFound = false;
            for(var j = 0; j < this.state.personTeams.length; j++) {
                if(this.state.teams[i].id === this.state.personTeams[j].teamID) {
                    isFound = true;
                    break;
                }
            }
            if(!isFound) {
                const teamMember = { teamID: this.state.teams[i].id, personID: person.id, modified: new Date().getTime() }
                db.updateTeamMember(teamMember.id, teamMember);
            }
        }

        // remove deleted teams
        for(var y = 0; y < this.state.personTeams.length; y++) {
            var isPersonTeamFound = false;
            for(var z = 0; z < this.state.teams.length; z++) {
                if(this.state.personTeams[y].teamID === this.state.teams[z].id) {
                    isPersonTeamFound = true;
                    break;
                }
            }

            if(!isPersonTeamFound) 
                db.deleteTeamMember(this.state.personTeams[y].id);
        }

        // add new projects
        this.state.projects.forEach(project => {
            var isFound = false;
            for(var j = 0; j < this.state.personProjects.length; j++) {
                if(project.id === this.state.personProjects[j].projectID) {
                    isFound = true;
                    break;
                }
            }
            if(!isFound) {
                var projectMember = { projectID: project.id, personID: person.id }
                db.updateProjectMember(projectMember.id, projectMember);
            }
        });

        // remove deleted projects
        for(var v = 0; v < this.state.personProjects.length; v++) {
            var isPersonProjectFound = false;
            for(var w = 0; w < this.state.projects.length; w++) {
                if(this.state.personProjects[v].projectID === this.state.projects[w].id) {
                    isPersonProjectFound = true;
                    break;
                }
            }

            if(!isPersonProjectFound) 
                db.deleteProjectMember(this.state.personProjects[v].id);
        }
          
        // kill temporary fields
        delete person.preload;
        delete person.children;

        // enable product view searches
        if(person.product && person.role)
            person.productHead = true;
        else
            person.productHead = false;
    
        person.email = person.email.toLowerCase();

        person.modified = new Date().getTime(); 
        person.modifiedBy = this.props.user.person.name;

        db.updatePerson(person.id, person).then(() => {
            // algolia.upsertPerson(person);
            this.setState({modal:false});

            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "personEditor", "upsert");
        });
    }

    showAdmin() {
        if(this.props.user.isAdmin)
            return "block";
        else
            return "none";
    }

    getButtonSize() {
        if(this.props.size)
            return this.props.size;
        else
            return "md";
    }

    render() {
     
        const activeLink = {
            color:"white",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#efefef",
            marginLeft:"1px",   
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const navIcon = {
            fontSize:"17px",
            marginTop:"3px",
            cursor:"pointer"
        }
        
        return (
            <div>

                <style>
                {
                    `
                        .slider-handle {
                            background-image: linear-gradient(to bottom, green, #4ca635);
                        },
                        .slider {
                            width: 100%;
                        }
                        .slider-horizontal {
                            width: 100% !important;
                        },
                        .tooltip {
                            display: none;
                        }
                        .tooltip-main {
                            display: none;
                        }
                      `
                }
                </style>

                <Button color="secondary" onClick={this.toggleModal} size={this.getButtonSize()}>
                    Edit Profile
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <Nav style={{paddingTop:"17px"}}>
                                    <NavItem style={(this.state.activeTab === '1' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '1' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '1'}) }}
                                        >
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === '2' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '2' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '2'}) }}
                                        >
                                            Photos
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === '3' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '3' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '3'}) }}
                                        >
                                            Teams
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === '4' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '4' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '4'}) }}
                                        >
                                            Projects
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === '5' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '5' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '5'}) }}
                                        >
                                            Personal
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === '6' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === '6' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '6'}) }}
                                        >
                                            Social
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:"none"}}>
                                        <NavLink
                                            style={(this.state.activeTab === 'mentoring' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'mentoring'}) }}
                                        >
                                            Mentoring
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:this.showAdmin(), backgroundColor:"#efefef", marginLeft:"1px" }}>
                                        <NavLink
                                            style={(this.state.activeTab === '7' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: '7'}) }}
                                        >
                                            Admin
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green', paddingTop:"20px"}}>
                                    <TabPane tabId="1" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        <Row>
                                            <Col sm="12" md="12" lg="5">
                                                <label style={{color: "#696969", cursor:"pointer", marginBottom:"23px"}}>
                                                    <img src={this.state.person.profilePhoto} alt="profile" />
                                                
                                                    <FileUploader
                                                        hidden 
                                                        accept="image/*"
                                                        name="photo"
                                                        randomizeFilename
                                                        storageRef={storage.profilePhotos}
                                                        onUploadStart={this.photoUploadStart}
                                                        onUploadError={this.photoUploadError}
                                                        onUploadSuccess={this.photoUploadSuccess2}
                                                        onProgress={this.photoUploadProgress}
                                                    />
                                                </label>
                                            </Col>
                                            <Col sm="12" md="12" lg="7">    
                                                <Row style={{display:this.showAdmin()}}>   
                                                    <Col xs="12">
                                                        Email <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-email-85" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="What is your Gojek email?" value={this.state.person.email} onChange={(e) => this.updatePerson("email", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{display:this.showAdmin()}}>
                                                    <Col xs="12" >
                                                        Title <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-badge" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="What is your job title?" value={this.state.person.title} onChange={(e) => this.updatePerson("title", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        Location <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-pin-3" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="Where are you located?" value={this.state.person.location} onChange={(e) => this.updatePerson("location", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" >
                                                        Working Hours <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-watch-time" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="What are your normal working hours in your time zone?" value={this.state.person.workingHours} onChange={(e) => this.updatePerson("workingHours", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs="12">
                                                        Time Zone <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                                    <i style={icon} className="nc-icon nc-world-2" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="" 
                                                                type="select" 
                                                                value={this.state.person.timeZone} onChange={(e) => this.updatePerson("timeZone", e.target.value)} >
                                                                    <option value=""></option>
                                                                    <option value="IST">India (IST)</option>
                                                                    <option value="WIB">Indonesia (WIB)</option>
                                                                    <option value="SGT">Singapore (SGT)</option>
                                                                    <option value="ICT">Thailand (ICT)</option>
                                                                    <option value="PST">United States (PST)</option>
                                                            </Input>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        Ask Me About <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-chat-33" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="What should people come to you for?" value={this.state.person.responsibilities} onChange={(e) => this.updatePerson("responsibilities", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        User Manual <br />
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={icon} className="nc-icon nc-single-copy-04" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input placeholder="Add a link to your user manual here." value={this.state.person.userManual} onChange={(e) => this.updatePerson("userManual", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        Skills <br />
                                                        <Tag type="skills" icon="nc-user-run" 
                                                            selected={this.state.person.skills} 
                                                            onChange={(skills) => this.updatePerson('skills',skills)} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        
                                        <Row>
                                            {this.state.person.photos.map((photo, index) => (
                                            <Col md="3" key={index}>
                                                <img src={photo.url} style={{marginBottom:"17px"}} alt="logo" />
                                                <div style={{display:"inline-block", marginLeft:"10px", marginBottom:"16px", marginTop:"-8px"}}>
                                                    <ConfirmDialog 
                                                        buttonText="Remove"
                                                        title="Remove Photo" 
                                                        header="CONFIRM" 
                                                        description="Are you sure you want to remove this photo from your profile?" 
                                                        confirmed={() => this.deletePhoto(photo)} /> 
                                                </div>
                                            </Col>
                                            ))}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        <Row>
                                            {this.state.teams.map((team, index) => (
                                                <Col xs="6" sm="4" md="4" key={index} >
                                                    <TeamCard team={team} key={index} />
                                                    <div style={{display:"inline-block", marginLeft:"10px", marginBottom:"16px", marginTop:"-8px"}}>
                                                        <ConfirmDialog 
                                                            buttonText="Remove"
                                                            title="Remove Team" 
                                                            header="CONFIRM" 
                                                            description="Are you sure you want to remove this team from your profile?" 
                                                            confirmed={() => this.deleteTeam(team)} /> 
                                                    </div>
                                                </Col>
                                            ))}                                            
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="4" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        <Row >
                                            {this.state.projects.map((project, index) => (    
                                            <Col xs="6" sm="4" md="4" lg="3" key={index} style={{color:"#898989"}} >
                                                <ProjectCard project={project} />
                                                <div style={{display:"inline-block", marginLeft:"10px", marginBottom:"16px", marginTop:"-8px"}}>
                                                    <ConfirmDialog 
                                                        buttonText="Remove"
                                                        title="Remove Project" 
                                                        header="CONFIRM" 
                                                        description="Are you sure you want to remove this project from your profile?" 
                                                        confirmed={() => this.deleteProject(project)} /> 
                                                </div>
                                            </Col>
                                            ))}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="5" style={{minHeight:"350px", paddingTop:"17px"}}>

                                        <Alert color="danger" isOpen={this.state.showPrivacyWarning} toggle={() => { this.setState({showPrivacyWarning:false})}} fade={true}>
                                            Share only personal information as you want to share and are comfortable with!
                                        </Alert>

                                        <Row>
                                            <Col xs="12">
                                                Family / Pets<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-single-02" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Would you like to tell us about your family? Pets?" value={this.state.person.family} onChange={(e) => this.updatePerson("family", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Hobbies <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-palette" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="What do you like to do when you're not working?" value={this.state.person.hobbies} onChange={(e) => this.updatePerson("hobbies", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Music <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-note-03" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="What music do you enjoy?" value={this.state.person.music} onChange={(e) => this.updatePerson("music", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Fun Fact <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-touch-id" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="What makes you uniquely you?" value={this.state.person.funFact} onChange={(e) => this.updatePerson("funFact", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Education <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-hat-3" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Proud of your alma mater?" value={this.state.person.university} onChange={(e) => this.updatePerson("university", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Former Homes <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-world-2" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Where have you lived before?" value={this.state.person.formerHomes} onChange={(e) => this.updatePerson("formerHomes", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Birthday<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i className="socialIcon fas fa-birthday-cake"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Tell us if you want people to say Happy Birthday!" value={this.state.person.birthday} onChange={(e) => this.updatePerson("birthday", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="mentoring" style={{minHeight:"350px", paddingTop:"0px"}}>
                                        <Row>
                                            <Col sm="12" style={{fontSize:"13px", marginBottom:"17px"}}>
                                                Helping each other grow and succeed is the Gojek way! 
                                                Everyone has valuable skills, experiences and insights to share.
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                    <CustomInput type="switch" id="followIssue" name="customSwitch"
                                                        checked={this.state.person.openToMentoring} onChange={(e) => this.updatePerson("openToMentoring", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block", verticalAlign:"middle", fontSize:"13px", color:"green"}} >
                                                    I am interested in being a mentor.
                                                </div>
                                                <br /><br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Mentor Profile <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-hat-3" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input text="textArea" placeholder="What are you good at and willing to help a lucky someone with?" value={this.state.person.mentorProfile} onChange={(e) => this.updatePerson("mentorProfile", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" style={{fontSize:"13px", marginTop:"17px", marginBottom:"17px", color:"#545454"}}>
                                                The following questions help us identify the ideal GoTroops to partner with you as a mentee.
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                Role <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                            <i style={icon} className="nc-icon nc-badge" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="" 
                                                        type="select" 
                                                        value={this.state.person.mentorRolePreference} onChange={(e) => this.updatePerson("mentorRolePreference", e.target.value)} >
                                                            <option value="any" >Any function / discipline</option>
                                                            <option value="preferFunction">I prefer my function / discipline</option>
                                                            <option value="funtionOnly">Only my function / discipline</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                            <Col sm="6">
                                                Time Commitment <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                            <i style={icon} className="nc-icon nc-time-alarm" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="" 
                                                        type="select" 
                                                        value={this.state.person.mentorTimePreference} onChange={(e) => this.updatePerson("mentorTimePreference", e.target.value)} >
                                                            <option value="flexible">Flexible</option>
                                                            <option value="thirtyMinutesWeekly">Max 30 minutes weekly</option>
                                                            <option value="twoHoursMonthly">Max 2 hours a month</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                Gender <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                            <i style={icon} className="nc-icon nc-badge" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="" 
                                                        type="select" 
                                                        value={this.state.person.mentorGenderPreference} onChange={(e) => this.updatePerson("mentorGenderPreference", e.target.value)} >
                                                            <option value="noPreference">No Preference</option>                                                            
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                            <Col sm="6">
                                                Role <br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                            <i style={icon} className="nc-icon nc-badge" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="" 
                                                        type="select" 
                                                        value={this.state.person.mentorRolePreference} onChange={(e) => this.updatePerson("mentorRolePreference", e.target.value)} >
                                                            <option value="anyFunction">Any function / discipline</option>
                                                            <option value="preferFunction">I prefer my function / discipline</option>
                                                            <option value="functionOnly">Only my function / discipline</option>
                                                    </Input>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" style={{fontSize:"13px", marginTop:"17px", marginBottom:"17px", color:"#545454"}}>
                                                What are some specific areas you can offer thoughts and feedback?
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6" md="4">
                                                <div style={{display:"inline-block"}}>
                                                    <CustomInput type="switch" id="careerdev"
                                                        checked={this.state.person.mentorSkillCareerDev} onChange={(e) => this.updatePerson("mentorSkillCareerDev", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block"}}>Career Development</div>
                                            </Col>
                                            <Col sm="6" md="4">
                                                <div style={{display:"inline-block"}}>
                                                    <CustomInput type="switch" id="comms"
                                                        checked={this.state.person.mentorSkillCommunication} onChange={(e) => this.updatePerson("mentorSkillCommunication", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block"}}>Communication Skills</div>
                                            </Col>
                                            <Col sm="6" md="4">
                                                <div style={{display:"inline-block"}}>
                                                    <CustomInput type="switch" id="pplmgmt"
                                                        checked={this.state.person.mentorSkillManaging} onChange={(e) => this.updatePerson("mentorSkillManaging", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block"}}>Managing People</div>
                                            </Col>
                                            <Col sm="6" md="4">
                                                <div style={{display:"inline-block"}}>
                                                    <CustomInput type="switch" id="projmgmt"
                                                        checked={this.state.person.mentorSkillProjMgmt} onChange={(e) => this.updatePerson("mentorSkillProjMgmt", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block"}}>Project Management</div>
                                            </Col>
                                            <Col sm="6" md="4">
                                                <div style={{display:"inline-block"}}>
                                                    <CustomInput type="switch" id="teambldg"
                                                        checked={this.state.person.mentorSkillTeamBuilding} onChange={(e) => this.updatePerson("mentorSkillTeamBuilding", e.target.checked)}
                                                    />
                                                </div>
                                                <div style={{display:"inline-block"}}>Team Building</div>
                                            </Col>
                                        </Row>             
                                    </TabPane>
                                    <TabPane tabId="6" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        <Row>
                                            <Col xs="12">
                                                LinkedIn<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i className="socialIcon fab fa-linkedin"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="The URL to your profile page." value={this.state.person.linkedIn} onChange={(e) => this.updatePerson("linkedIn", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Twitter<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i className="socialIcon fab fa-twitter"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="The URL to your profile page." value={this.state.person.twitter} onChange={(e) => this.updatePerson("twitter", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Instagram<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i className="socialIcon fab fa-instagram"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="The URL to your profile page." value={this.state.person.instagram} onChange={(e) => this.updatePerson("instagram", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                Github<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i className="socialIcon fab fa-twitter"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="The URL to your profile page." value={this.state.person.github} onChange={(e) => this.updatePerson("github", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="7" style={{minHeight:"350px", paddingTop:"17px"}}>
                                        <Row>
                                            <Col md="12">
                                                Slack ID<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-badge" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="This is a number." value={this.state.person.slackID} onChange={(e) => this.updatePerson("slackID", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                Product<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-bag-16" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Is there a specific product you work on?" value={this.state.person.product} onChange={(e) => this.updatePerson("product", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                Role<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-compass-05" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="What leadership role do you play?" value={this.state.person.role} onChange={(e) => this.updatePerson("role", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                Flight<br />
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{width:"50px"}}>
                                                            <i style={icon} className="nc-icon nc-send" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input placeholder="Testing Flight" value={this.state.person.flight} onChange={(e) => this.updatePerson("flight", e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs={4} >
                                {/* <div style={{display:this.showNewTeamButton()}}>
                                    <TeamSearch onTeamChosen={(t) => this.addToTeam(t)}  />
                                </div> */}
                                {/* <div style={{display:this.showNewProjectButton()}}>
                                    <ProjectSearch onProjectChosen={(p) => this.addToProject(p)} />
                                </div> */}
                                <div style={{display:this.showNewPhotoButton()}}>
                                    
                                    <label style={{marginTop:"11px", backgroundColor:"#888888", borderRadius:"6px", color:"white", fontSize:"12px", fontWeight:600, padding:"10px", textAlign:"center", cursor:"pointer" }}>
                                        New Photo  
                                    
                                        <FileUploader
                                            hidden 
                                            accept="image/*"
                                            name="photo"
                                            randomizeFilename
                                            storageRef={storage.profilePhotos}
                                            onUploadStart={this.photoUploadStart}
                                            onUploadError={this.photoUploadError}
                                            onUploadSuccess={this.photoUploadSuccess}
                                            onProgress={this.photoUploadProgress}
                                        />
                                        
                                    </label>
                                </div>
                            </Col>
                            <Col xs="8" style={{textAlign:"right"}}>
                                <Button color="success" onClick={this.savePerson}>
                                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                        <i style={navIcon} className="nc-icon nc-check-2" />  
                                    </div>
                                    <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                        Save
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

PersonEditor = connect(mapStateToProps)(PersonEditor);
export default PersonEditor;