import React, { useEffect, useState } from 'react'

import { strings } from '../../assets/utils'
import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import { BACKGROUND_COLORS, sortObjectByIntValue } from '../nexusReports/utils'

const defaultChartData = {
  labels: [],
  datasets: [],
}

export default function TopPageViewsByPDG({ teams, activities }) {
  const [chartData, setChartData] = useState(defaultChartData)
  const [totalPageViews, setTotalPageViews] = useState(0)
  const [topPDGPageViews, setTopPDGPageViews] = useState([])

  const getTopPageViewsByPDG = () => {
    const teamsMap = {}
    teams.forEach((team) => {
      if (!(team.id in teamsMap)) {
        teamsMap[team.id] = team.name
      }
    })

    const pageViewsByPDG = {}
    activities.forEach((activity) => {
      const teamName = teamsMap[activity.resourceID]
      if (teamName in pageViewsByPDG) {
        pageViewsByPDG[teamName] += 1
      } else {
        pageViewsByPDG[teamName] = 1
      }
    })
    setTotalPageViews(activities.length)

    const sortedTopPDGPageViews = sortObjectByIntValue(pageViewsByPDG)
    const topPDGPageViewsList = sortedTopPDGPageViews.map((row) => {
      const [name, count] = row
      return { count, name }
    })
    setTopPDGPageViews(topPDGPageViewsList)

    const chart = {
      labels: [],
      data: [],
    }
    sortedTopPDGPageViews.forEach((row) => {
      const [PDG, count] = row
      chart.labels.push(strings.trim(PDG, 21))
      chart.data.push(String(count))
    })
    setChartData({
      labels: chart.labels,
      datasets: [
        {
          label: 'PDGs',
          borderWidth: 0,
          hoverOffset: 4,
          hoverBorderWidth: 2,
          hoverBorderColor: '#dee2e6',
          data: chart.data,
          backgroundColor: BACKGROUND_COLORS.slice(0, Object.keys(pageViewsByPDG).length),
        },
      ],
    })
  }

  useEffect(() => (
    getTopPageViewsByPDG()
  ), [])

  return (
    <DoughnutWithPercentage
      title="Top Page Views By PDG"
      totalLabel="Total Page Views"
      totalRecords={totalPageViews}
      sortedChartData={chartData}
      sortedRawData={topPDGPageViews}
    />
  )
}
