import React from 'react'
import FileUploader from 'react-firebase-file-uploader'
import { connect } from 'react-redux'
import {
  Button,
  Col,
  CustomInput,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'

import { algolia } from '../../assets/algolia'
import { db, storage } from '../../assets/firebase'
import { slack, strings, telemetry } from '../../assets/utils'
import AsanaProjectSearch from '../AsanaProjectSearch/AsanaProjectSearch'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import JiraProjectSearch from '../Jira/JiraProjectSearch'
import PersonCard from '../PersonCard/PersonCard'
import PersonSearch from '../PersonSearch/PersonSearch'
import Tag from '../Tag/Tag'
import TeamSearch from '../TeamSearch/TeamSearch'
import TextEditor from '../TextEditor/TextEditor'
import { getSanitisedSlugFromName } from '../utils'

class ProjectEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      activeTab: 'overview',
      project: {
        name: '',
        description: '',
        askUs: '',
        photos: [],
        links: [],
        successCriteria: [],
        tags: [],
        confluence: '',
        isActive: true,
        status: 'active',
        profilePhoto: `${process.env.PUBLIC_URL}/projectAvatar.jpg`,
      },
      projectMembers: [],
      members: [],
      asanaProject: { name: '' },
      openAsanaProjectSearch: false,
      openPersonSearch: false,
      openTeamSearch: false,
      openJiraSearch: false,
      jira: {},
    }

    if (props.project) {
      const { project } = props
      if (!project.links) project.links = []
      if (!project.successCriteria) project.successCriteria = []
      if (!project.status) {
        if (project.isActive) { project.status = 'active' } else { project.status = 'complete' }
      }
      this.state.project = project
    }

    this.addLink = this.addLink.bind(this)
    this.addMember = this.addMember.bind(this)
    this.getProject = this.getProject.bind(this)
    this.photoUploadSuccess = this.photoUploadSuccess.bind(this)
    this.saveProject = this.saveProject.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project) {
      const { project } = this.props
      if (!project.links) project.links = []
      if (!project.successCriteria) project.successCriteria = []
      this.setState({ project }, this.getProject)
    }
    if (prevProps.suggestedName !== this.props.suggestedName) {
      this.updateProject('name', this.props.suggestedName)
      this.updateProject('slug', getSanitisedSlugFromName(this.props.suggestedName))
    }
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal })
    this.getProject()
  }

  getProject() {
    if (this.props.project) {
      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'projectEditor', 'pageView')

      db.getProject(this.props.project.id).then((project) => {
        const projectRef = project

        if (!project.links) projectRef.links = []
        project.links.sort((a, b) => b.created - a.created)

        if (!project.successCriteria) projectRef.successCriteria = []

        if (!project.photos) projectRef.photos = []
        if (!project.profilePhoto) { projectRef.profilePhoto = `${process.env.PUBLIC_URL}/projectAvatar.jpg` }

        if (!project.tags) projectRef.tags = []

        if (!project.status) {
          if (project.isActive) {
            projectRef.status = 'active'
          } else {
            projectRef.status = 'complete'
          }
        }

        if (!project.slug) {
          projectRef.slug = ''
        }

        this.setState({ project }, () => {
          this.getJira()
        })

        db.getProjectMembers(this.props.project.id).then((projectMembers) => {
          const people = []
          projectMembers.forEach((member) => {
            let isFound = false
            for (let k = 0; k < people.length; k += 1) {
              if (people[k] === member.personID) {
                isFound = true
                break
              }
            }
            if (!isFound) people.push(member.personID)
          })

          const promises = people.map((person) => db.getPerson(person))
          Promise.all(promises).then((missingPeople) => {
            const members = []
            missingPeople.forEach((missingPerson) => {
              if (missingPerson) members.push(missingPerson)
            })

            this.setState({ members, projectMembers })
          })
        })

        if (project.asanaProjectID) {
          db.getAsanaProject(project.asanaProjectID).then((asanaProject) => {
            this.setState({ asanaProject })
          })

          if (project.asanaSyncOwnerID) {
            db.getPerson(project.asanaSyncOwnerID).then((person) => {
              this.setState({ asanaOwner: person })
            })
          }
          if (project.asanaSyncTeamID) {
            db.getTeam(project.asanaSyncTeamID).then((team) => {
              this.setState({ asanaTeam: team })
            })
          }
        }
      })
    }
  }

  getJira() {
    const {
      jiraID, jiraDomain, jiraKey,
    } = this.state.project

    if (jiraID) {
      const queryID = jiraDomain && jiraDomain !== 'go-jek' ? `${jiraID}_${jiraKey}` : jiraID
      db.getJiraProject(queryID).then((jira) => {
        this.setState({ jira })
      })
    }
  }

  updateProject(property, value) {
    const obj = this.state.project
    obj[property] = value
    this.setState({ project: obj })
  }

  // project members
  showNewMemberButton() {
    if (this.state.activeTab === 'members') return 'block'
    return 'none'
  }

  addMember(person) {
    const { members } = this.state
    members.push(person)
    this.setState({ members })
  }

  deleteMember(person) {
    const { members } = this.state
    for (let i = 0; i < members.length; i += 1) {
      if (members[i].id === person.id) {
        members.splice(i, 1)
        break
      }
    }
    this.setState({ members })
  }

  // project success criteria

  showNewCriterionButton() {
    if (this.state.activeTab === 'success') return 'block'
    return 'none'
  }

  addCriterion() {
    const obj = this.state.project
    obj.successCriteria.push({ id: uuidv4(), description: '', created: new Date().getTime() })
    this.setState({ project: obj })
  }

  deleteCriterion(criterion) {
    const obj = this.state.project
    for (let i = 0; i < obj.successCriteria.length; i += 1) {
      if (obj.successCriteria[i].id === criterion.id) {
        obj.successCriteria.splice(i, 1)
        break
      }
    }
    this.setState({ project: obj })
  }

  updateCriterion(criterion, property, value) {
    const obj = this.state.project
    for (let i = 0; i < obj.successCriteria.length; i += 1) {
      if (obj.successCriteria[i].id === criterion.id) {
        obj.successCriteria[i][property] = value
        break
      }
    }
    this.setState({ project: obj })
  }

  // project links
  showNewLinkButton() {
    if (this.state.activeTab === 'links') return 'block'
    return 'none'
  }

  addLink() {
    const obj = this.state.project
    obj.links.splice(0, 0, {
      id: uuidv4(), name: '', url: '', created: new Date().getTime(),
    })
    this.setState({ project: obj })
  }

  updateLink(link, property, value) {
    const obj = this.state.project
    for (let i = 0; i < obj.links.length; i += 1) {
      if (obj.links[i].id === link.id) {
        obj.links[i][property] = value
        break
      }
    }
    this.setState({ project: obj })
  }

  // asana
  linkAsanaProject(asanaProject) {
    const { project } = this.state
    project.asanaProjectID = asanaProject.id
    this.setState({ project, asanaProject })
  }

  showAsanaOptions() {
    if (this.state.project.asanaProjectID) return 'block'
    return 'none'
  }

  openAsanaProjectSearch() {
    this.setState({ openAsanaProjectSearch: true }, () => {
      setTimeout(() => {
        this.setState({ openAsanaProjectSearch: false })
      }, 500)
    })
  }

  asanaSyncOwner(person) {
    this.updateProject('asanaSyncOwnerID', person.id)
    this.setState({ asanaOwner: person })
  }

  asanaSyncOwnerCard() {
    if (this.state.asanaOwner) {
      return (
        <PersonCard person={this.state.asanaOwner} showThumb />
      )
    }
  }

  asanaSyncTeam(team) {
    this.updateProject('asanaSyncTeamID', team.id)
    this.setState({ asanaTeam: team })
  }

  asanaSyncTeamCard() {
    if (this.state.asanaTeam) {
      return (
        <div>{this.state.asanaTeam.name}</div>
      )
    }
  }

  // jira
  jiraProjectChosen(project) {
    this.updateProject('jiraID', project.jiraID)
    this.updateProject('jiraDomain', project.jiraDomain)
    this.updateProject('jiraKey', project.jiraKey)

    db.getJiraProject(project.id).then((jira) => {
      this.setState({ jira })
    })
  }

  deleteJira() {
    console.log('todo')
  }

  openJiraSearch() {
    this.setState({ openJiraSearch: true }, () => {
      setTimeout(() => {
        this.setState({ openJiraSearch: false })
      }, 500)
    })
  }

  openPersonSearch() {
    this.setState({ openPersonSearch: true }, () => {
      setTimeout(() => {
        this.setState({ openPersonSearch: false })
      }, 500)
    })
  }

  openTeamSearch() {
    this.setState({ openTeamSearch: true }, () => {
      setTimeout(() => {
        this.setState({ openTeamSearch: false })
      }, 500)
    })
  }

  // photos
  photoUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 })
  }

  photoUploadProgress = (progress) => {
    this.setState({ progress })
  }

  photoUploadError = (error) => {
    this.setState({ isUploading: false })
    console.error(error)
  }

  photoUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false })
    storage.profilePhotos.child(filename).getDownloadURL().then((url) => this.addPhoto(url, false))
  }

  photoUploadSuccess2 = (filename) => {
    this.setState({ progress: 100, isUploading: false })
    storage.profilePhotos.child(filename).getDownloadURL().then((url) => {
      this.addPhoto(url, true)
      this.updateProject('profilePhoto', url)
    })
  }

  addPhoto(url, isPrimary) {
    const obj = this.state.project
    if (!obj.photos) {
      obj.photos = []
    }

    const photo = {
      id: uuidv4(), name: '', description: '', url, isPrimary,
    }

    obj.photos.push(photo)
    this.setState({ project: obj })
  }

  deletePhoto(photo) {
    const obj = this.state.project
    for (let i = 0; i < obj.photos.length; i += 1) {
      if (obj.photos[i].id === photo.id) {
        obj.photos.splice(i, 1)
        break
      }
    }
    this.setState({ project: obj })
    // todo, actually delete the photo from blob storage
  }

  // save
  saveProject() {
    const { project } = this.state

    if (!project.created) {
      project.created = new Date().getTime()
      project.createdBy = this.props.user.person.name
    }
    if (!project.slug) {
      project.slug = getSanitisedSlugFromName(project.name)
    }
    project.modified = new Date().getTime()
    project.modifiedBy = this.props.user.person.name

    db.updateProject(project.id, project).then((result) => {
      if (this.props.onCreated) {
        project.id = result.id
        this.props.onCreated(project, this.state.members)
      }

      // update the search index
      // algolia.upsertProject(project)

      // add new members
      for (let i = 0; i < this.state.members.length; i += 1) {
        let isMemberFound = false
        for (let j = 0; j < this.state.projectMembers.length; j += 1) {
          if (this.state.members[i].id === this.state.projectMembers[j].personID) {
            isMemberFound = true
            break
          }
        }
        if (!isMemberFound) {
          const projectMember = { personID: this.state.members[i].id, projectID: project.id }
          db.updateProjectMember(projectMember.id, projectMember)
          this.sendSlackMessage(this.state.members[i])
        }
      }

      // remove deleted members
      for (let k = 0; k < this.state.projectMembers.length; k += 1) {
        let isProjectMemberFound = false
        for (let l = 0; l < this.state.members.length; l += 1) {
          if (this.state.projectMembers[k].personID === this.state.members[l].id) {
            isProjectMemberFound = true
            break
          }
        }

        if (!isProjectMemberFound) { db.deleteProjectMember(this.state.projectMembers[k].id) }
      }

      console.log('members', this.state.members)
      if (this.props.onUpdated) { this.props.onUpdated(project, this.state.members) }

      if (this.props.onCreated) { // reset for next new team
        this.setState({
          activeTab: 'overview',
          project: {
            name: '',
            description: '',
            askUs: '',
            confluence: '',
            photos: [],
            links: [],
            successCriteria: [],
            isActive: true,
          },
          projectMembers: [],
          members: [],
        })
      }

      // close the dialog
      this.setState({ modal: false })

      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'projectEditor', 'upsert')
    })
  }

  sendSlackMessage(person) {
    const text = 'You were added to a project on GoConnect.'

    const blocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `You were added to the ${this.state.project.name} project on GoConnect.\n\n`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: 'GoConnect is a community owned directory of people, teams and projects that makes it easier for GoTroops to find their way around. Please take a few moments to add or update to the profiles of the teams and projects you are a part of - add a description, links to useful files, OKRs, etc.!\n\n',
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: 'If you need GoConnect support, please visit our slack channel at #go-connect.\n\n',
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Click <https://www.gocnnct.com/projects/${this.state.project.id}|here> to see the ${this.state.project.name} project profile.`,
        },
      },
    ]

    const { slackID } = person
    // slackID = 'U01C68P5D4P'; // testing

    slack.sendMessage(slackID, text, blocks)
  }

  buttonText() {
    if (this.props.project) return 'Edit'
    return '+ Add New Project'
  }

  showAsanaSyncTaskOptions() {
    if (this.state.project.asanaSyncTasks) return 'block'
    return 'none'
  }

  getProjectURL() {
    return `https://gocnnct.com/projects/${this.state.project.slug}`
  }

  render() {
    const activeLink = {
      color: 'white',
      fontWeight: 600,
      fontSize: '12px',
    }
    const link = {
      color: '#434343',
      fontWeight: 400,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeTab = {
      backgroundColor: 'green',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: 'green',
    }
    const tab = {
      backgroundColor: '#efefef',
      marginLeft: '1px',
    }
    const icon = {
      fontSize: '17px',
      marginTop: '0px',
      color: '#000000',
    }
    const deleteIcon = {
      fontSize: '15px',
      marginTop: '0px',
      color: 'maroon',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }
    const iconTextArea = {
      fontSize: '17px',
      position: 'absolute',
      top: '20px',
      color: '#000000',
    }

    return (
      <div>

        <Button color="secondary" size="sm" onClick={this.toggleModal}>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <i style={navIcon} className="nc-icon nc-settings-gear-65" />
          </div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
            {this.buttonText()}
          </div>
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
          <ModalBody>
            <Row>
              <Col md="12">
                <Nav style={{ paddingTop: '17px' }}>
                  <NavItem style={(this.state.activeTab === 'overview' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'overview' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'overview' }) }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'business' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'business' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'business' }) }}
                    >
                      Business
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'links' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'links' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'links' }) }}
                    >
                      Links
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'members' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'members' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'members' }) }}
                    >
                      Members
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'photos' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'photos' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'photos' }) }}
                    >
                      Photos
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  style={{
                    borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green', paddingTop: '20px',
                  }}
                >
                  <TabPane tabId="overview" style={{ minHeight: '300px', paddingTop: '17px' }}>
                    <Row>
                      <Col md="5">
                        <label style={{ color: '#696969', cursor: 'pointer', marginBottom: '23px' }}>
                          <img src={this.state.project.profilePhoto} alt="profile" />

                          <FileUploader
                            hidden
                            accept="image/*"
                            name="photo"
                            randomizeFilename
                            storageRef={storage.profilePhotos}
                            onUploadStart={this.photoUploadStart}
                            onUploadError={this.photoUploadError}
                            onUploadSuccess={this.photoUploadSuccess2}
                            onProgress={this.photoUploadProgress}
                          />
                        </label>
                      </Col>

                      <Col md="7">
                        <Row>
                          <Col md="12">
                            Name
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="What is your projects name?"
                                value={this.state.project.name}
                                onChange={(e) => this.updateProject('name', e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Description
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={iconTextArea} className="nc-icon nc-badge" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="What is your projects high level goal or responsibility?"
                                type="textarea"
                                style={{ lineHeight: '23px', minHeight: '150px' }}
                                value={this.state.project.description}
                                onChange={(e) => this.updateProject('description', e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Confluence Link
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-globe" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Do you have a Confluence page?"
                                value={this.state.project.confluence}
                                onChange={(e) => this.updateProject('confluence', e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>

                        {/* <Row style={{ marginBottom: '10px' }}>
                          <Col xs="12">
                            Jira Project
                            <br />
                            <div
                              onClick={() => this.openJiraSearch()}
                              style={{
                                border: 'solid 1px #cdcdcd',
                                borderRadius: '4px',
                                paddingTop: '6px',
                                paddingLeft: '10px',
                                width: '100%',
                                height: '41px',
                                cursor: 'pointer',
                              }}
                            >
                              <div style={{ display: 'inline-block', verticalAlign: 'middle' }} />
                              <div style={{
                                display: 'inline-block',
                                marginLeft: '15px',
                                verticalAlign: 'middle',
                                color: '#666666',
                                marginTop: '4px',
                              }}
                              >
                                {strings.trim(this.state.jira.title, 30)}
                              </div>
                              <div style={{ display: 'none' }}>
                                <JiraProjectSearch
                                  isOpen={this.state.openJiraSearch}
                                  onProjectChosen={(project) => this.jiraProjectChosen(project)}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                position: 'absolute', right: '25px', top: '32px', cursor: 'pointer',
                              }}
                              onClick={() => this.deleteJira()}
                            >
                              <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                            </div>
                          </Col>
                        </Row> */}

                        {/* <Row>
                          <Col sm="12" style={{ marginBottom: '10px' }}>
                            Asana Project
                            <div
                              onClick={() => this.openAsanaProjectSearch()}
                              style={{
                                border: 'solid 1px #cdcdcd',
                                borderRadius: '4px',
                                paddingTop: '6px',
                                paddingLeft: '10px',
                                paddingBottom: '10px',
                                width: '100%',
                                minHeight: '41px',
                                cursor: 'pointer',
                              }}
                            >
                              <div style={{ display: 'inline-block', verticalAlign: 'middle' }} />
                              <div style={{
                                display: 'inline-block', marginLeft: '10px', verticalAlign: 'middle', color: '#666666',
                              }}
                              >
                                {this.state.asanaProject.name}
                              </div>
                              <div style={{ display: 'none' }}>
                                <AsanaProjectSearch
                                  isOpen={this.state.openAsanaProjectSearch}
                                  onProjectChosen={(p) => this.linkAsanaProject(p)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                        <Row style={{ display: this.showAsanaOptions() }}>
                          <Col sm="12">
                            <CustomInput
                              type="switch"
                              label="Sync Members"
                              id="asanaSyncUsersSwitch"
                              checked={this.state.project.asanaSyncUsers}
                              onChange={(e) => this.updateProject('asanaSyncUsers', e.target.checked)}
                            />
                          </Col>
                        </Row>
                        <Row style={{ display: this.showAsanaOptions() }}>
                          <Col sm="12">
                            <CustomInput
                              type="switch"
                              label="Sync Status Reports"
                              id="asanaSyncStatusSwitch"
                              checked={this.state.project.asanaSyncStatus}
                              onChange={(e) => this.updateProject('asanaSyncStatus', e.target.checked)}
                            />
                          </Col>
                        </Row>
                        <Row style={{ display: this.showAsanaOptions() }}>
                          <Col sm="12">
                            <CustomInput
                              type="switch"
                              label="Sync Tasks"
                              id="asanaSyncTasksSwitch"
                              checked={this.state.project.asanaSyncTasks}
                              onChange={(e) => this.updateProject('asanaSyncTasks', e.target.checked)}
                            />
                          </Col>
                        </Row>
                        {/* <Row style={{ display: this.showAsanaSyncTaskOptions(), marginTop: '15px' }}>
                          <Col sm="12">
                            <div style={{ paddingLeft: '23px' }}>
                              Default Issue Owner
                              <br />
                              <div
                                onClick={() => this.openPersonSearch()}
                                style={{
                                  border: 'solid 1px #cdcdcd',
                                  borderRadius: '4px',
                                  paddingTop: '6px',
                                  paddingLeft: '10px',
                                  width: '100%',
                                  minHeight: '41px',
                                  cursor: 'pointer',
                                }}
                              >
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                  {this.asanaSyncOwnerCard()}
                                </div>
                                <div style={{
                                  display: 'inline-block',
                                  marginLeft: '15px',
                                  verticalAlign: 'middle',
                                  color: '#666666',
                                }}
                                >
                                  {this.state.project.asanaTaskOwner}
                                </div>
                                <div style={{ display: 'none' }}>
                                  <PersonSearch
                                    label="Assign"
                                    isOpen={this.state.openPersonSearch}
                                    onPersonChosen={(p) => this.asanaSyncOwner(p)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm="12">
                            <div style={{ paddingLeft: '23px', marginTop: '10px' }}>
                              Assign Issues to Team

                              <br />
                              <div
                                onClick={() => this.openTeamSearch()}
                                style={{
                                  border: 'solid 1px #cdcdcd',
                                  borderRadius: '4px',
                                  paddingTop: '6px',
                                  paddingLeft: '10px',
                                  width: '100%',
                                  minHeight: '41px',
                                  cursor: 'pointer',
                                }}
                              >
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                  {this.asanaSyncTeamCard()}
                                </div>
                                <div style={{
                                  display: 'inline-block',
                                  marginLeft: '15px',
                                  verticalAlign: 'middle',
                                  color: '#666666',
                                  marginTop: '4px',
                                }}
                                />
                                <div style={{ display: 'none' }}>
                                  <TeamSearch
                                    label="Assign Team"
                                    isOpen={this.state.openTeamSearch}
                                    onTeamChosen={(t) => this.asanaSyncTeam(t)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row> */}

                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                          <Col xs="12">
                            Tags
                            <br />
                            <Tag
                              type="project"
                              icon="nc-tag-content"
                              selected={this.state.project.tags}
                              onChange={(tags) => this.updateProject('tags', tags)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            Status
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px', verticalAlign: 'top' }}>
                                  <i style={icon} className="nc-icon nc-sound-wave" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder=""
                                type="select"
                                value={this.state.project.status}
                                onChange={(e) => this.updateProject('status', e.target.value)}
                              >
                                <option value="active">Active</option>
                                <option value="paused">Paused</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="completed">Completed</option>
                              </Input>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            URL Slug
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder=""
                                value={this.state.project.slug}
                                onChange={(e) => this.updateProject('slug', e.target.value)}
                              />
                            </InputGroup>
                            <div style={{ fontSize: '11px', color: '#898989' }}>
                              {`Project URL: ${this.getProjectURL()}`}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="business" style={{ paddingTop: '17px' }}>
                    <Row>
                      <Col md="12">
                        Problem Statement
                        {' '}
                        <br />
                        <TextEditor
                          content={this.state.project.problemStatement}
                          onChange={(e) => this.updateProject('problemStatement', e)}
                          height="200px"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '60px' }}>
                      <Col md="12">
                        Vision
                        {' '}
                        <br />
                        <TextEditor
                          content={this.state.project.vision}
                          onChange={(e) => this.updateProject('vision', e)}
                          height="200px"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '60px' }}>
                      <Col md="12">
                        Solution Concept
                        {' '}
                        <br />
                        <TextEditor
                          content={this.state.project.solutionConcept}
                          onChange={(e) => this.updateProject('solutionConcept', e)}
                          height="200px"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '60px' }}>
                      <Col sm="12">
                        Success Criteria
                        <br />
                      </Col>
                    </Row>
                    {this.state.project.successCriteria.map((criterion, index) => (
                      <Row key={index} style={{ marginTop: '10px' }}>
                        <Col xs="12">
                          <div style={{ display: 'inline-block', cursor: 'pointer' }}>
                            <i
                              style={deleteIcon}
                              className="nc-icon nc-simple-remove"
                              onClick={() => this.deleteCriterion(criterion)}
                            />
                          </div>
                          <div style={{
                            display: 'inline-block', cursor: 'pointer', width: 'calc(100% - 34px)', marginLeft: '10px',
                          }}
                          >
                            <input
                              type="text"
                              value={criterion.description}
                              placeholder="specific, measureable, time bound, etc."
                              onChange={(e) => this.updateCriterion(criterion, 'description', e.target.value)}
                              style={{
                                borderStyle: 'none',
                                backgroundColor: '#efefef',
                                padding: '6px',
                                height: '28px',
                                borderRadius: '6px',
                                fontSize: '12px',
                                width: '100%',
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}
                    <Row style={{ marginTop: '10px' }}>
                      <Col xs="12">
                        <Button size="sm" onClick={() => this.addCriterion()}>Add</Button>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="links" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <div style={{ marginBottom: '20px' }}>
                      Links to the Project folders/documents (e.g. Confulence, Google Drive):
                    </div>

                    <Row>
                      <Col sm="6">
                        <b>Name</b>
                      </Col>
                      <Col sm="6">
                        <b>URL</b>
                      </Col>
                    </Row>
                    {this.state.project.links.map((projctLink, index) => (
                      <Row key={index} style={{ marginBottom: '10px' }}>
                        <Col sm="6">
                          <input
                            type="text"
                            value={projctLink.name}
                            placeholder="name"
                            onChange={(e) => this.updateLink(projctLink, 'name', e.target.value)}
                            style={{
                              borderStyle: 'none',
                              backgroundColor: '#efefef',
                              padding: '6px',
                              height: '28px',
                              borderRadius: '6px',
                              fontSize: '12px',
                              width: '100%',
                            }}
                          />
                        </Col>
                        <Col sm="6">
                          <input
                            type="text"
                            value={projctLink.url}
                            placeholder="url"
                            onChange={(e) => this.updateLink(projctLink, 'url', e.target.value)}
                            style={{
                              borderStyle: 'none',
                              backgroundColor: '#efefef',
                              padding: '6px',
                              height: '28px',
                              borderRadius: '6px',
                              fontSize: '12px',
                              width: '100%',
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </TabPane>

                  <TabPane tabId="members" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col md="12">
                        Slack ID
                        <br />
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ width: '50px' }}>
                              <i style={icon} className="nc-icon nc-badge" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="This is a number."
                            value={this.state.project.slackID}
                            onChange={(e) => this.updateProject('slackID', e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.members.map((person, index) => (
                        <Col sm="4" md="3" key={index}>
                          <PersonCard person={person} hideExpander />
                          <div style={{ marginLeft: '10px', marginBottom: '16px', marginTop: '-8px' }}>
                            <ConfirmDialog
                              buttonText="Remove"
                              title="Remove Project Member"
                              header="CONFIRM"
                              description="Are you sure you want to remove this person from the project?"
                              confirmed={() => this.deleteMember(person)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>

                  <TabPane tabId="photos" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col sm="6">
                        <label style={{ color: '#696969', cursor: 'pointer' }}>
                          Upload a New Photo

                          <FileUploader
                            hidden
                            accept="image/*"
                            name="photo"
                            randomizeFilename
                            storageRef={storage.profilePhotos}
                            onUploadStart={this.photoUploadStart}
                            onUploadError={this.photoUploadError}
                            onUploadSuccess={this.photoUploadSuccess}
                            onProgress={this.photoUploadProgress}
                          />

                        </label>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.project.photos.map((photo, index) => (
                        <Col md="3" key={index}>
                          <img src={photo.url} style={{ marginBottom: '17px' }} alt="logo" />
                          <div style={{
                            display: 'inline-block', marginLeft: '10px', marginBottom: '16px', marginTop: '-8px',
                          }}
                          >
                            <ConfirmDialog
                              buttonText="Remove"
                              title="Remove Photo"
                              header="CONFIRM"
                              description="Are you sure you want to remove this photo from your profile?"
                              confirmed={() => this.deletePhoto(photo)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row style={{ width: '100%' }}>
              <Col xs={7}>
                {/* <div style={{ display: this.showNewMemberButton() }}>
                  <PersonSearch onPersonChosen={this.addMember} />
                </div> */}
                <div style={{ display: this.showNewCriterionButton() }} />
                <div style={{ display: this.showNewLinkButton() }}>
                  <Button onClick={this.addLink}>Add Link</Button>
                </div>

              </Col>
              <Col xs="5" style={{ textAlign: 'right' }}>
                <Button color="success" onClick={this.saveProject} size="md">
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-check-2" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Save
                  </div>
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-class-assign
ProjectEditor = connect(mapStateToProps)(ProjectEditor)
export default ProjectEditor
