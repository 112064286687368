import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import {
  SEVERITY_COLORS, sum,
} from './utils'

export default function IssuesReportBySeverity({
  labels, monthlySeverityData,
}) {
  const severityTypes = Object.keys(monthlySeverityData)
  const monthlyTotal = new Array(labels.length).fill(0)
  const getMonthlyTotal = (index) => {
    const total = sum(severityTypes.map((severity) => monthlySeverityData[severity][index]))
    monthlyTotal[index] = total

    return total
  }

  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          Monthly Issues By Severity
        </h6>
        <Table striped>
          <thead>
            <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
              <td>Severity</td>
              {labels.map((month) => <td key={month}>{month}</td>)}
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            {severityTypes.map((severity) => (
              <tr style={{ fontSize: '11px' }} key={severity}>
                <th style={{ textTransform: 'uppercase', fontWeight: 'bold', color: SEVERITY_COLORS[severity] }}>
                  {severity}

                </th>
                {monthlySeverityData[severity].map((issueCount, index) => (
                  <td key={labels[index]}>
                    {issueCount}
                  </td>
                ))}
                <th>{sum(monthlySeverityData[severity])}</th>
              </tr>
            ))}
            <tr style={{ fontSize: '11px' }}>
              <th>Total</th>
              {
                labels.map((_, index) => (
                  <th key={index}>
                    {getMonthlyTotal(index)}
                  </th>
                ))
              }
              <th>{sum(monthlyTotal)}</th>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
