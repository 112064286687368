import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { slack, telemetry } from 'assets/utils';

// reactstrap components
import {
    Alert,
    Button, Card, CardHeader, CardBody, CardFooter, CardTitle,
    Row, Col,
  } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";

class Approval extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
          isLoading: true,
          alertVisible: false,
          type: 'managerApprovalAppReviews',
          person: {}
        }
    }

    componentDidMount() {

        db.getPerson(this.props.personID).then(person => {           
            this.setState({person: person});
        })

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "managerApproval", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });        
    }


    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }


    sendSlackMessage(result) {


        var text = this.props.user.person.name + " has *" + result + "* your request to respond to customer app reviews.";
        var blocks = [
                {
                    "type": "section",
                    "text": {
                        "type": "mrkdwn",
                        "text": text + "\n\n" 
                    }
                },
            ]

        if(result === 'approved')
            blocks.push({
                "type": "section",
                text: {
                    "type": "mrkdwn",
                    "text": "<https://www.gocnnct.com/feedback|Get Started>"
                }
            })
                          
        slack.sendMessage(this.state.person.slackID, text, blocks);
    }

    approve() {
        var person = this.state.person;
        person[this.state.type] = 'approved';
        db.updatePerson(person.id, person).then(() => {
            this.sendSlackMessage('approved');
            this.setState({alertVisible: true});
        });
    }
    reject() {
        var person = this.state.person;
        person[this.state.type] = 'denied';
        db.updatePerson(person.id, person).then(() => {
            this.sendSlackMessage('rejected');
            this.setState({alertVisible: true});
        });
    }

    approvalDescription() {

        if(this.state.person) {
   
            if(this.state.type === 'managerApprovalAppReviews') {
                
                return <div>
                    <b style={{color:"green"}}>{this.state.person.name}</b> is requesting permission to respond to customer feedback in the Google Play store. 
                    <div style={{paddingTop:"23px"}}>
                        This is great news because we can use the help! We get over a thousand reviews every day and we can respond to only a fraction of them. 
                        Customers love hearing from the people who help make Gojek ... Gojek! 
                    </div>
                    <div style={{paddingTop:"23px"}}>
                        If you approve this request, your employee will be able to respond to customer comments in app reviews.
                        Their response will be seen by the user and will be visible to the public, so <b>please 
                        make sure this is an employee you trust to respond professionally, using proper grammar, etc.</b>
                    </div>
                    <div style={{paddingTop:"23px"}}>
                        Thank you!
                    </div>
                </div>
            }
        }
    }

    employeeCard() {
        if(this.state.person.id) {
            return (
                <PersonCard person={this.state.person} showThumb />
            )
        }
    }

    managerCard() {
        if(this.state.manager.id) {
            return (
                <PersonCard person={this.state.user.person} showThumb />
            )
        }
    }
    
    showPrompt() {
        if(!this.state.alertVisible)
            return "block";
        else
            return "none";
    }

    render() {

       
        return (
            <div style={{padding:"20px"}}>
                
                <Row>
                    <Col xs="12" >
                        <br />
                        <Card style={{padding:"20px"}}>
                            <CardHeader>
                                <Row>
                                    <Col xs="6">
                                        <CardTitle tag="h4">Manager Approval</CardTitle>
                                    </Col>
                                    <Col xs="6" style={{textAlign:"right"}}>
                                        <div style={{display:"inline-block", marginRight:"10px", marginTop:"10px", fontSize:"12px"}}><b>Employee</b><br />{this.state.person.name}</div>
                                        <div style={{display:"inline-block"}}>{this.employeeCard()}</div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            
                            <CardBody style={{minHeight:"200px", display:this.showPrompt()}}>
                                <Row>
                                    <Col md="12" style={{fontWeight:500, marginBottom:"20px"}}>
                                        {this.approvalDescription()}
                                    </Col>
                                </Row>
                            </CardBody>
                            
                            <CardFooter>
                                <div style={{display:this.showPrompt()}}>
                                <Row>
                                    <Col sm="12" style={{textAlign:"right", marginTop:"20px"}}>
                                        <div style={{display:"inline-block", marginRight:"10px"}}>
                                            <Button color="danger" onClick={() => this.reject()} size="md" >
                                                Reject
                                            </Button>
                                        </div>
                                        <div style={{display:"inline-block"}}>
                                            <Button color="success" onClick={() => this.approve()} size="md" >
                                                Approve
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                </div>
                                <Row>
                                    <Col xs="12">
                                        <Alert color="success" isOpen={this.state.alertVisible} >
                                            Thank you! {this.state.person.name} has been notified!
                                        </Alert>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Approval = connect(mapStateToProps)(Approval);
export default Approval;