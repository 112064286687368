import React from "react";

import { 
    Row, Col, 
    Input, InputGroup, 
    Modal, ModalBody,
    NavItem, NavLink,
    Spinner
} from "reactstrap";

import CSVReader from 'react-csv-reader'
import { db } from "assets/firebase";

class CleverTapImport extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            year: 2022,
            month: 6,
            showFilePicker: true,
            isLoading: false,
            account: '',
            accounts:[]
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
        this.getAccounts();
    }

    getAccounts() {
        db.getCleverTapAccounts().then(accounts => {
            this.setState({accounts: accounts});
        });
    }

    importCSV() {
        this.setState({showFilePicker: true});
    }

    showFilePicker() {
        if(this.state.showFilePicker)
            return "block";
        else 
            return "none";
    }

    onFileError (error) {
        console.log(error);
    }

    onFileLoaded(data) {

        this.setState({showFilePicker: false, isLoading: true});

        console.log(this.state.year);
        console.log(this.state.month);

        var promises = [];
        db.getCleverTapEventsYearMonth(this.state.month, this.state.year).then(events => {
            
            
            console.log(events)
            console.log('DELETING EXISTING EVENTS');

            events.forEach(event => {
                promises.push(db.deleteCleverTapEvent(event.id));
            })
            

            Promise.all(promises).then(() => {

                console.log('SAVING EVENTS FROM CSV');

                var events = [];
                var timeStamp = new Date().getTime();
        
                for(var i = 0; i < data.length; i++) {
        
                    //eventID: data[i].event_id,
                    var event = {
                        accountID: data[i].accountid,
                        accountName: data[i].accountname,
                        eventName: data[i].event_name,
                        eventType: data[i].event_type,
                        count: data[i].events_count,
                        year: Number(this.state.year),
                        month: Number(this.state.month),
                        created: timeStamp
                    }
        
                    events.push(event);
                }
        
                promises = [];
                events.forEach(event => {
                    promises.push(db.updateCleverTapEvent(event.id, event))
                    //console.log(event)
                })
        
                Promise.all(promises).then(() => {
                    this.setState({showFilePicker: true, isLoading: false});
                })
            });
        });
    }

    onFileLoadedDRP(data) {

        this.setState({isLoading: true});

        var promises = [];
        db.getCleverTapEventsYearMonth(this.state.month, this.state.year).then(events => {
            
            console.log('events: ' + events.length);
            console.log('data: ' + data.length);

            promises = [];

            events.forEach(event => {
                for(var i = 0; i < data.length; i++) {
                    if(event.eventName === data[i].event_name) {
                        event.drp = data[i].drp;
                        //console.log(data[i].drp)
                        promises.push(db.updateCleverTapEvent(event.id, event));
                        break;
                    }
                }
            });

            Promise.all(promises).then(() => {
                this.setState({isLoading: false});
            })
        });
    }

/*
    updateCTAccounts() {

        this.setState({isLoading: true}, () => {

            db.getCleverTapAccounts().then(accounts => {
                accounts.forEach(account => {
                    db.deleteCleverTapAccount(account.id);
                })
            })

            db.getCleverTapEvents().then(events => {

                var accounts = [];
                events.forEach(event => {
                    var isFound = false;
                    for(var i = 0; i < accounts.length; i++) {
                        if(event.accountID === accounts.id) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound)
                        accounts.push({
                            id: event.accountID,
                            name: event.accountName
                        })
                })

                var promises = [];
                accounts.forEach(account => {
                    promises.push(db.updateCleverTapAccount(account.id, account));
                })

                Promise.all(promises).then(() => {
                    this.setState({ accounts: accounts, isLoading: false });    
                })                
            })
        });
    }*/

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    render() {

        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header =>
              header
                .toLowerCase()
                .replace(/\W/g, '_')
        }

        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    
        return (
            <div>
                <NavItem onClick={this.toggleModal}>
                  <NavLink className="btn-magnify" style={{marginTop:"5px"}}>
                        <i className="nc-icon nc-settings" />
                  </NavLink>
                </NavItem>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm="12">
                               <h4>CleverTap Data Management</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                Year <br />
                                <InputGroup>
                                    <Input placeholder="" 
                                        type="select" 
                                        value={this.state.year} onChange={(e) => this.setState({year: e.target.value})} >
                                            <option>2022</option>
                                            <option>2021</option>
                                            <option>2020</option>
                                            <option>2019</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                            <Col md="6">
                                Month <br />
                                <InputGroup>
                                    
                                    <Input placeholder="" 
                                        type="select" 
                                        value={this.state.month} onChange={(e) => this.setState({month: e.target.value})} >
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                    </Input>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br /><br />


                        <Row>
                            <Col sm="12">
                                <h5 style={{color:"green"}}>Import Usage Data</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <br />
                                <div style={{display:this.showFilePicker()}}>
                                    <CSVReader
                                        cssClass="csv-reader-input"
                                        label=""
                                        onFileLoaded={(data) => this.onFileLoaded(data)}
                                        onError={this.onFileError}
                                        parserOptions={papaparseOptions}
                                        inputId="ObiWan"
                                        inputStyle={{color: 'green'}}
                                    />
                                </div>
                                
                            </Col>
                        </Row>
                        <br /><br />

                        
                        <Row>
                            <Col sm="12">
                                <h5 style={{color:"green"}}>Import Data Retention Policy Data</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <br />
                                <div>
                                    <CSVReader
                                        cssClass="csv-reader-input"
                                        label=""
                                        onFileLoaded={(data) => this.onFileLoadedDRP(data)}
                                        onError={this.onFileError}
                                        parserOptions={papaparseOptions}
                                        inputId="Kenobi"
                                        inputStyle={{color: 'green'}}
                                    />
                                </div>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px", height:"123px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br /><br />
                        
                    </ModalBody>
                    
                </Modal>
                
            </div>
        )
    }
}

export default CleverTapImport;