import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Container,
  DropdownItem, DropdownMenu, DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'

import logo from '../../assets/img/goconnect.png'
import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

class OrgChartNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
      showSettings: false,
      query: '',
      view: 'landing',
      othersPresent: [],
      isPersonModalOpen: false,
    }

    if (this.props.orgChartView) {
      this.state.view = 'orgChart'
    }
    if (this.props.okrsView) {
      this.state.view = 'okrs'
    } else if (this.props.teamChartView) {
      this.state.view = 'team'
    } else if (this.props.searchView) {
      this.state.view = 'search'
    }

    this.landingPage = this.landingPage.bind(this)
    this.searchText = this.searchText.bind(this)
    this.setSearchTimer = this.setSearchTimer.bind(this)
    this.toggleShowSettings = this.toggleShowSettings.bind(this)
    this.showPersonModal = this.showPersonModal.bind(this)
    this.closePersonModal = this.closePersonModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth > 693
      && e.history.location.pathname !== e.location.pathname
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }

    if (e.searchQuery !== this.props.searchQuery) {
      this.setState({ query: this.props.searchQuery })
    }

    if (e.orgChartView !== this.props.orgChartView
      || e.okrsView !== this.props.okrsView
      || e.teamChartView !== this.props.teamChartView
      || e.searchView !== this.props.searchView
    ) {
      if (this.props.orgChartView) { this.setState({ view: 'orgChart' }) }
      if (this.props.okrsView) {
        this.setState({ view: 'okrs' })
      } else if (this.props.teamView) {
        this.setState({ view: 'team' })
      } else if (this.props.searchView) {
        this.setState({ view: 'search' })
      }
    }

    if (e.othersPresent !== this.props.othersPresent) {
      this.setState({ othersPresent: this.props.othersPresent })
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth > 693 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
      })
    } else {
      this.setState({
        color: 'navbar-transparent',
      })
    }
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white'
    } else {
      newState.color = 'navbar-transparent'
    }
    this.setState(newState)
  }

  setSearchTimer(query) {
    clearTimeout(this.searchText)
    this.setState({ query }, () => {
      setTimeout(this.searchText, 1700)
    })
  }

  searchText() {
    this.props.onSearch(this.state.query)
  }

  toggleShowSettings() {
    this.setState((prevState) => ({ showSettings: !prevState.showSettings }))
  }

  landingPage() {
    this.setState({ query: '', view: 'landing' })
    this.props.onLandingPageViewClick()
  }

  okrsView() {
    this.setState({ query: '', view: 'okrs' })
    this.props.onOKRViewClick()
  }

  orgChart() {
    this.setState({ query: '', view: 'orgChart' })
    this.props.onOrgChartViewClick()
  }

  teamView() {
    this.setState({ query: '', view: 'team' })
    this.props.onTeamChartViewClick()
  }

  selectedView(value) {
    if (value === this.state.view) return 'green'
    return '#232323'
  }

  showOtherPeople() {
    if (this.state.othersPresent.length > 0) return 'list-item'
    return 'none'
  }

  showTotalOthers() {
    if (this.props.totalOthers > 0) { return 'inline-block' }
    return 'none'
  }

  showPersonModal() {
    this.setState({
      isPersonModalOpen: true,
    })
  }

  closePersonModal() {
    this.setState({
      isPersonModalOpen: false,
    })
  }

  render() {
    const okrsIcon = {
      cursor: 'pointer',
      color: this.selectedView('okrs'),
      marginTop: '8px',
    }

    const orgChartIcon = {
      cursor: 'pointer',
      color: this.selectedView('orgChart'),
      marginTop: '8px',
    }

    const teamIcon = {
      cursor: 'pointer',
      color: this.selectedView('team'),
      marginTop: '8px',
    }

    const navText = {
      fontSize: '11px',
      marginLeft: '6px',
    }

    return (
      <div>

        <Navbar
          className={classnames('navbar-absolute fixed-top', 'bg-white')}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{ cursor: 'pointer' }}>
              <div style={{ display: 'inline-block' }}>
                <Link onClick={this.landingPage} to="/">
                  <img
                    src={logo}
                    alt="Gojek"
                    style={{
                      marginTop: '0px',
                      marginBottom: '0px',
                      marginLeft: '4px',
                      marginRight: '10px',
                      height: '34px',
                      paddingRight: '0px',
                    }}
                  />
                </Link>
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <NavItem
                  style={{
                    display: this.showOtherPeople(),
                    backgroundImage: 'linear-gradient(to right, #ffffff, #efefef)',
                    borderRadius: '0px 10px 10px 0px',
                    marginRight: '20px',
                  }}
                  title="Other GoTroops here with you."
                >
                  <NavLink>
                    {this.state.othersPresent.map((person, index) => (
                      <div style={{ marginTop: '5px', marginRight: '10px', display: 'inline-block' }} key={index}>
                        <PersonCard person={person} showThumb />
                      </div>
                    ))}
                    <div style={{
                      display: this.showTotalOthers(),
                      height: '27px',
                      width: '27px',
                      borderRadius: '4px',
                      verticalAlign: 'top',
                      marginTop: '5px',
                      marginRight: '0px',
                      backgroundColor: '#aaaaaa',
                      textAlign: 'center',
                      color: '#dedede',
                      fontSize: '10px',
                    }}
                    >
                      +
                      {this.props.totalOthers}
                    </div>
                  </NavLink>
                </NavItem>
                {/* <NavItem style={{ marginTop: '10px', marginRight: '20px' }}>
                  <InputGroup className="no-border">
                    <Input
                      value={this.state.query}
                      placeholder="Search..."
                      type="text"
                      onChange={(e) => this.setSearchTimer(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i className="nc-icon nc-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    style={orgChartIcon}
                    onClick={() => this.orgChart()}
                    title="Org Chart"
                  >
                    <i className="nc-icon nc-vector" />
                    <span style={navText}>Org Chart</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    style={teamIcon}
                    onClick={() => this.teamView()}
                    title="Teams"
                  >
                    <i className="nc-icon nc-bank" />
                    <span style={navText}>Teams</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    style={okrsIcon}
                    onClick={() => this.okrsView()}
                    title="OKRs"
                  >
                    <i className="nc-icon nc-user-run" />
                    <span style={navText}>OKRs</span>
                  </NavLink>
                </NavItem>

                <UncontrolledDropdown className="btn-magnify" nav style={{ paddingTop: '8px' }}>
                  <DropdownToggle
                    aria-haspopup
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-bullet-list-67" />
                    <p>
                      <span className="d-lg-none d-md-block">GoTo Links</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://360.golabs.io/')}
                    >
                      360
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://go-jek.atlassian.net/wiki/spaces/GOJ/overview?mode=global')}
                    >
                      Confluence
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://source.golabs.io/')}
                    >
                      GoLabs (git)
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://sites.google.com/aux.gojek.com/golead/')}
                    >
                      GoLead
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://www.gocnnct.com/kudos')}
                    >
                      Kudos
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://gojek.fuseuniversal.com/')}
                    >
                      Learning Lab
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://one.gojek.com/')}
                    >
                      OneGojek
                    </DropdownItem>
                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://sites.google.com/go-jek.com/performance-portal/home?authuser=0')}
                    >
                      Performance Portal
                    </DropdownItem>

                    <DropdownItem
                      style={{ fontSize: '13px' }}
                      onClick={() => window.open('https://wd3.myworkday.com/gojek/d/home.htmld')}
                    >
                      Workday
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <ProfileMenuDropdown user={this.props.user} showPersonModal={this.showPersonModal} />

                <PersonCard
                  person={this.props.user.person}
                  showPersonModal={this.state.isPersonModalOpen}
                  closePersonModal={this.closePersonModal}
                />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>

      </div>
    )
  }
}

const mapStateToProps = (state) => state

OrgChartNavbar = connect(mapStateToProps)(OrgChartNavbar)
export default OrgChartNavbar
