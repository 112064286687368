
import React from "react";

import { connect } from 'react-redux';

import { algolia } from "assets/algolia";

import { db } from 'assets/firebase';

import { arraySort, strings, tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table,
    Row, Col,
    Spinner,
    Nav, NavItem, NavLink,
  } from "reactstrap";

  import IssueEditor from 'components/IssueEditor/IssueEditor.js';
  import ReviewCard from 'components/Review/Review.js';
  import Tag from "components/Tag/Tag.js"; 
  import { isNumeric } from "redoc";

  /*
  import gojek from 'assets/img/logo.png';
  import goBiz from 'assets/img/gobiz_logo.png';
  import goBluebird from 'assets/img/gobluebird_logo.png';
  import goBox from 'assets/img/gobox_logo.png';
  import goCar from 'assets/img/gocar_logo.png';
  import goFood from 'assets/img/gofood_logo.png';
  import goGive from 'assets/img/gogive_logo.png';
  import goMart from 'assets/img/gomart_logo.png';
  import goMed from 'assets/img/gomed_logo.png';
  import goPay from 'assets/img/gopay_logo.png';
  import goPlay from 'assets/img/goplay_logo.png';
  import goRide from 'assets/img/goride_logo.png';
  import goSend from 'assets/img/gosend_logo.png';
  import goSure from 'assets/img/gosure_logo.png';
  import goTix from 'assets/img/gotix_logo.png';
  */

  function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);
    return dt.getUTCFullYear() + '.' + addZero(dt.getUTCMonth()+1) + '.' + addZero(dt.getUTCDate()) + ' ' + addZero(dt.getUTCHours()) + '.' + addZero(dt.getUTCMinutes());
  }

  class CustomerIssuesList extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        issues: [],
        filtered: [],
        reviews: [],
        projects: [],
        teams: [],
        marketFilter:[],
        productFilter: [],
        tagFilter: [],
        severityFilter: "All",
        projectFilter: "All",
        statusFilter: "Active",
        issueTypeFilter: 'All',
        queryText: "",
        tags: [],
        isLoading: true,
        activeTab: 'critical',
        issuesInResults: false,
        tasksInResults: false
      }

      this.getLinkedIssue = this.getLinkedIssue.bind(this);
      this.LinkedIssue = this.LinkedIssue.bind(this);
    }

    componentDidMount() {
        this.getCustomerIssues();
        this.getLinkedIssue();

        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "customerIssuesList", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({queryText: this.props.searchQuery}, () => {
              if(this.state.queryText.length > 0) {
                  this.searchIssues();
              } else {
                  this.filterIssues();
              }
            });
        }
    }

    getLinkedIssue() {
        if(this.props.issueID) {
            db.getIssue(this.props.issueID).then(issue => {
                this.setState({linkedIssue: issue});
            });
        }
    }

    getCustomerIssues() {

        db.getIssues().then(issues => {

            var projectIDs = [];
            var promises = [];

            for(var i = 0; i < issues.length; i++) {
                if(!issues[i].attachments) issues[i].attachments = [];
                if(!issues[i].discussion) issues[i].discussion = [];
                if(!issues[i].history) issues[i].history = [];    
                if(!issues[i].severity) 
                    issues[i].severity = '';
                
                if(issues[i].projectID) {
                    var isFound = false;
                    for(var j = 0; j < projectIDs.length; j++) {
                        if(projectIDs[j] === issues[i].projectID) {
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound) {
                        projectIDs.push(issues[i].projectID);
                        promises.push(db.getProject(issues[i].projectID));
                    } 
                }
            }

            Promise.all(promises).then(projects => {

                issues.forEach(issue => {
                    if(issue.asanaTask) {
                        if(issue.asanaTask.projectID) {
                            for(var p = 0; p < projects.length; p++) {
                                if(projects[p].id === issue.asanaTask.projectID) {
                                    issue.projectName = projects[p].name;
                                    break;
                                }
                            }
                        }
                    }
                });
            
                db.getTagsByType('issueType').then(tags => {
                    
                    tags.forEach(tag => {
                        tag.name = tag.name.toLowerCase();
                    })

                    this.setState({issues: issues, projects: projects, tags: tags}, () => this.filterIssues());    
                });
            });
        });
    }

    filterIssues(filter, value) {

        var filtered = [];
        var issues = this.state.issues;

        var issuesInResults = false;
        var tasksInResults = false;

        var marketFilter = this.state.marketFilter;
        if(filter === 'market')
            marketFilter = value;

        var productFilter = this.state.productFilter;
        if(filter === 'product')
            productFilter = value;

        var projectFilter = this.state.projectFilter;
        if(filter === 'project')
            projectFilter = value;

        var severityFilter = this.state.severityFilter;
        if(filter === 'severity')
            severityFilter = value;
        
        var statusFilter = this.state.statusFilter;
        if(filter === 'status')
            statusFilter = value;

        var tagFilter = this.state.tagFilter;
        if(filter === 'tags')
            tagFilter = value;
        let issueTypeFilter = this.state.issueTypeFilter;
 
        if(this.state.activeTab === 'critical' || this.state.activeTab === 'custom')
            issueTypeFilter = 'All'

        if(this.state.activeTab === 'care')
            issueTypeFilter = 'care'

        if(this.state.activeTab === 'security')
            issueTypeFilter = 'security'

        if(this.state.activeTab === 'market')
            issueTypeFilter = 'market'

        this.setState({marketFilter: marketFilter, productFilter: productFilter, projectFilter: projectFilter, 
                        severityFilter: severityFilter, statusFilter: statusFilter, tagFilter: tagFilter});

        // applies the filter when on that tab but not for other tabs
        if(this.state.activeTab === 'critical')
            severityFilter = 'critical'; 

        for(var i = 0; i < issues.length; i++) {
            /*
            if(issues[i].id === 'SoNDiBHCWNRNCVXV001i') {
                console.log(issues[i])
                console.log('pdgFilter: ' + pdgFilter);
                console.log('teamFilter: ' + teamFilter);
                console.log('podFilter: ' + podFilter);
                console.log('statusFilter: ' + statusFilter);
                console.log('severityFilter: ' + severityFilter);
                console.log('marketFilter: ' + marketFilter);
            }*/
            if (this.filterIssueType(issues[i].type, issueTypeFilter))
                if(issues[i].status === statusFilter)
                    if(this.filterSeverity(issues[i].severity, severityFilter))
                     if(this.filterSet(issues[i].product, productFilter))
                            if(this.filterSet(issues[i].tags, tagFilter))
                                if(this.filterSet(issues[i].market, marketFilter))
                                    if(issues[i].projectID === projectFilter || projectFilter === 'All') {

                                        if(issues[i].type === 'task')
                                            tasksInResults = true;
                                        else 
                                            issuesInResults = true;

                                        filtered.push(issues[i]);
                                    }
                                            
                                    
        }

        this.setState({filtered: filtered, issuesInResults: issuesInResults, tasksInResults: tasksInResults, isLoading: false });
    }

    filterSet(values, filter) {
        if(filter.length === 0) return true;

        if(!values) return false;

        for(var i = 0; i < values.length; i++) {
            for(var j = 0; j < filter.length; j++) {
                if(values[i] === filter[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    filterSeverity(value, filter) {
        if(value === filter)
            return true;
        else if(
            (filter === 'All') ||
            (filter === 'critical' && value >= 90) ||
            (filter === 'high' && value >= 80) ||
            (filter === 'medium' && value >=20 && value < 80) ||
            (filter === 'low' && value < 20) 
        ) return true;

        return false;
    }

    filterIssueType(value, filter) {
        
        if(filter === 'All')
            return true;
        else if (value) {
            if (value === filter) {
                return true;
            }
        }

        return false;
    }

    sortIssues(sortBy) {
        var issues = this.state.filtered;

        if(sortBy === this.state.sortBy) { // reverse
            
            var reversed = [];
            for(var i = issues.length - 1; i > -1; i--) 
                reversed.push(issues[i]);
            issues = reversed;
        } else {

            if(sortBy === 'modified')
                issues.sort((a,b) => { return b.modified - a.modified });
            else if (sortBy === 'severity')
                issues.sort((a,b) => { return Number(b.severity) - Number(a.severity) });   
        }

        this.setState({filtered: issues, sortBy: sortBy});
    }

    searchIssues() {
        this.setState({isLoading: true}, () => {
            algolia.searchIssues(this.state.queryText).then(issues => {
                issues.forEach(issue => {
                    if(!issue.severity) issue.severity = Number(0);
                })
                this.setState({filtered: issues, isLoading: false});
            });
        });
    }
    
    changeTab(tab) {

        this.setState({activeTab: tab}, () => {
            
            this.filterIssues();
        });
    }

    issueCreated(issue) {
        var issues = this.state.issues;
        issues.push(issue);
        this.setState({issues: issues}, () => this.filterIssues());
    }

    issueUpdated(issue) {
        
        var issues = this.state.issues;
        for(var i = 0; i < issues.length; i++) {
            if(issues[i].id === issue.id) {
                issues[i] = issue;
                break;
            }
        }
        this.setState({issues: issues}, () => this.filterIssues());
    }

    issueDeleted(issue) {
        var issues = this.state.issues;
        for(var i = 0; i < issues.length; i++) {
            if(issues[i].id === issue.id) {
                issues.splice(i,1);
                break;
            }
        }
        this.setState({issues: issues}, () => this.filterIssues());
    }

    exportToCSV() {
        var rows = this.state.filtered;
  
        console.log(rows)
        
        let filename = "customerIssues.csv";
        let contentType = "text/csv;charset=utf-8;";
      
        var content = "Name, Owner, Status \n";
        for(var i = 0; i < rows.length; i++) {
          content = content + rows[i].title.replace(/,/g, "") + ',' + rows[i].owner + ', ' + rows[i].status + '\n';
        }
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        
    }


    getSeverityDescriptor(severity) {
        if(isNaN(severity))
            return severity;
        else if(isNumeric(severity)) {
            if(severity > 90)
                return "Critical";
            else if (severity > 75)
                return "High";
            else if (severity > 60)
                return "Medium";
            else if(severity <40)
                return "Low";
        } 
        else return severity;
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showFilters() {
        if(this.state.queryText.length === 0 )
            return "flex";
        else
            return "none";
    }
    showCustomFilters() {
        if(this.state.activeTab === 'custom')
            return 'block';
        else 
            return 'none';
    }
    showSeverityFilter() {
        if(this.state.activeTab !== 'critical')
            return 'block';
        else 
            return 'none';
    }
    showTeamFilter() {
        if(this.state.activeTab === 'care')
            return 'block';
        else 
            return 'none';
    }
    showIssueColumn() {
        if(this.state.issuesInResults)
            return "table-cell";
        else
            return "none";
    }
    showTaskColumn() {
        if(this.state.tasksInResults)
            return "table-cell";
        else
            return "none";
    }
    showExport() {
        if(this.state.filtered.length > 0)
            return "block";
        else
            return "none";
    }

    
    LinkedIssue() {
        if(this.state.linkedIssue) {
            return ( 
                <IssueEditor issue={this.state.linkedIssue} onUpdated={(issue) => this.issueUpdated(issue)} openByDefault /> 
            )
        }   
    }

    // FIXME: use TagBadges instead
    displayTags(tagIDs) {

        if(tagIDs) {   
            if(Array.isArray(tagIDs)) {

                var tags = [];
                tagIDs.forEach(tagID => {
                    for(var i = 0; i < this.state.tags.length; i++) {
                        if(tagID === this.state.tags[i].id) {
                            tags.push(this.state.tags[i]);
                            break;
                        }
                    }
                })

                tags.sort(arraySort.byName);
                
                return (
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                        {tags.map((tag, index) => (
                            <div key={index} style={{marginRight:"8px", marginBottom:"4px", display:"flex"}}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px"}} >{tag.name}</Badge>
                            </div>
                        ))}
                    </div>
                )
            } 
        } 
    }
    

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        const link = {
            color:"#434343",
            fontWeight:500,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeLink = {
            color:"white",
            fontWeight:700,
            fontSize:"12px"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#efefef",
            marginLeft:"1px",   
        }

        return (
            <div style={{padding:"20px"}}>
                <Row>
                    <Col sm="12" >
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="6">
                                        <CardTitle tag="h4">Nexus</CardTitle>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <div style={{textAlign:"right"}}>
                                            <IssueEditor onCreated={(issue) => this.issueCreated(issue)} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{minHeight:"300px"}}>
                                
                                <Row>
                                    <Col xs="12">
                                        <Nav style={{marginTop:"10px"}}>
                                            <NavItem style={(this.state.activeTab === 'critical' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'critical' ? activeLink : link )}
                                                    onClick={() => this.changeTab('critical') }
                                                >
                                                    Critical Issues
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'care' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'care' ? activeLink : link )}
                                                    onClick={() => this.changeTab('care') }
                                                >
                                                    Care Issues
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'market' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'market' ? activeLink : link )}
                                                    onClick={() => this.changeTab('market') }
                                                >
                                                    Market Issues
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'security' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'security' ? activeLink : link )}
                                                    onClick={() => this.changeTab('security') }
                                                >
                                                    Security Issues
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'custom' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'custom' ? activeLink : link )}
                                                    onClick={() => this.changeTab('custom') }
                                                >
                                                    Custom Query
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>

                                <Row style={{display:this.showFilters(), padding:"10px", paddingTop:"20px", margin:"0px", marginBottom:"20px", borderStyle: 'solid', borderWidth: '1px 1px 1px 1px', borderColor: 'green'}}>
                                    <Col xs="12" sm="6" md="4" >
                                        Product <br />
                                        <Tag type="product" icon="nc-shop" placeholder="All" readOnly
                                            selected={this.state.productFilter} 
                                            onChange={(tags) => this.filterIssues('product',tags)} />
                                        <br />
                                    </Col>
                                    <Col sm="6" md="4" style={{display:this.showCustomFilters()}}>
                                        Project <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-spaceship" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                value={this.state.projectFilter} onChange={(e) => this.filterIssues("project", e.target.value)} >
                                                    <option selected value="All">All</option>
                                                    {this.state.projects.map((project, index) => (
                                                    <option value={project.id} key={index}>{project.name}</option>
                                                    ))}
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    <Col xs="12" sm="6" md="4" style={{display:this.showTeamFilter()}}> 
                                        Care Team <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-vector" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                value={this.state.teamFilter} onChange={(e) => this.filterIssues("team", e.target.value)} >
                                                    <option selected>All</option>
                                                    <option value='9mO4WxTOGufiLTw942fV'>Customer Care Unit (CCU)</option>
                                                    <option value='XoWoh25BUsavjHYt7J8T'>Driver Care Unit (DCU)</option>
                                                    <option value='WT1m7ObhdXW19uPqRBrP'>Merchant Care Unit (MCU)</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    <Col xs="12" sm="6" md="2" style={{display:this.showSeverityFilter()}}> 
                                        Severity <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                value={this.state.severityFilter} onChange={(e) => this.filterIssues("severity", e.target.value)} >
                                                    <option selected>All</option>
                                                    <option value='critical'>Critical</option>
                                                    <option value='high'>High</option>
                                                    <option value='medium'>Medium</option>
                                                    <option value='low'>Low</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    <Col xs="12" sm="6" md="2" >
                                        Status <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-alert-circle-i" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00", fontSize:"11px"}}
                                                value={this.state.statusFilter} onChange={(e) => this.filterIssues("status", e.target.value)} >
                                                    <option selected>Active</option>
                                                    <option>Closed</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                    <Col xs="12" sm="6">
                                        Market <br />
                                        <Tag type="market" icon="nc-pin-3" placeholder="All" readOnly
                                            selected={this.state.marketFilter} 
                                            onChange={(tags) => this.filterIssues('market',tags)} />
                                        <br />
                                    </Col>
                                    <Col xs="12" sm="6" >
                                        Tags <br />
                                        <Tag type="issueType" icon="nc-bookmark-2" placeholder="All" readOnly
                                            selected={this.state.tagFilter} 
                                            onChange={(tags) => this.filterIssues('tags',tags)} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div style={loadingDiv}>
                                            <div style={{marginTop:"89px"}}>
                                                <Spinner color="success" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{display:"none"}}>
                                    <Col sm="6">

                                    </Col>
                                    <Col sm="6" style={{textAlign:"right", display:this.showExport()}}>
                                        <div style={{cursor:'pointer', fontSize:"13px", padding:"11px"}} title="Export to CSV" onClick={() => this.exportToCSV()}>
                                            <i style={icon} className="nc-icon nc-cloud-download-93" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div style={{display:this.showResults()}}>
                                            <Table style={{borderColor:"transparent"}}>
                                                <thead>
                                                    <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                        <td></td>
                                                        <td>Title</td>
                                                        <td>Tags</td>
                                                        {/*<td>Product</td>*/}
                                                        <td style={{color:"green", cursor:"pointer", textAlign:"center", display:this.showIssueColumn()}} onClick={() => this.sortIssues('severity')}>Severity</td>
                                                        <td style={{color:"green", cursor:"pointer", textAlign:"center", display:this.showTaskColumn()}} onClick={() => this.sortIssues('priority')}>Priority</td>
                                                        <td style={{textAlign:"right"}}>Modified</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filtered.map((issue, index) => (
                                                    <tr key={index} style={{fontSize:"12px", backgroundColor:tables.rowColor(index)}}>
                                                        <td style={{maxWidth:"100px"}}>
                                                            <IssueEditor issue={issue} onUpdated={(issue) => this.issueUpdated(issue)} onDeleted={(issue) => this.issueDeleted(issue)} showOwnerThumb />
                                                        </td>
                                                        <td>
                                                            {issue.title}<br />
                                                            <div style={{color:"green", fontSize:"11px"}}>
                                                                {issue.projectName}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {this.displayTags(issue.tags)}
                                                        </td>
                                                        {/*
                                                        <td>
                                                            <img src={this.getLogo(issue)} style={{height:"17px"}} alt="logo" />
                                                        </td>*/}
                                                        <td style={{textAlign:"center", display:this.showIssueColumn()}}>
                                                            {this.getSeverityDescriptor(issue.severity)}
                                                        </td>
                                                        <td style={{textAlign:"center", display:this.showTaskColumn()}} >
                                                            {this.getSeverityDescriptor(issue.priority)}
                                                        </td>
                                                        <td style={{textAlign:"right"}}>
                                                            {getFormattedDate(issue.modified)}
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" md="3" style={{display:"none"}}>
                        <h4>Recent Reviews</h4>
                        {this.state.reviews.map((review, index) => (
                            <Card key={index}>
                                <CardHeader>
                                    <CardTitle>
                                        <div style={{marginTop:"-10px"}}>
                                        {review.review.authorName}
                                        </div>
                                    </CardTitle>
                                    <CardBody style={{fontSize:"11px", padding:"0px", paddingBottom:"10px"}}>
                                        <div style={{marginTop:"-10px", marginBottom:"10px"}}>
                                            {this.getStars(review.review.comments[0].userComment.starRating)}
                                        </div>
                                        <div>
                                            {strings.trim(review.review.comments[0].userComment.text,123)}
                                        </div>
                                        <ReviewCard review={review} />
                                    </CardBody>
                                </CardHeader>
                            </Card>
                        ))}
                    </Col>
                </Row>
                
                {this.LinkedIssue()}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

CustomerIssuesList = connect(mapStateToProps)(CustomerIssuesList);
export default CustomerIssuesList;