import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import { strings } from '../../assets/utils'
import { getTeamNames, groupIssuesByTeamAndMonth} from '../nexusReports/utils'

export default function IssuesReportByPod({
  issues, teams, labels, team,
}) {
  const issuesByPod = groupIssuesByTeamAndMonth(issues, labels)
  const teamNames = getTeamNames(issuesByPod, teams, team.id)

  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          Monthly Issues By Pod
        </h6>
        <Table striped>
          <thead>
            <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
              <td>Pod</td>
              {labels.map((month) => <td key={month}>{month}</td>)}
            </tr>
          </thead>
          <tbody>
            {Object.keys(teamNames).sort().map((teamName) => (
              <tr style={{ fontSize: '11px' }} key={teamName}>
                <th title={teamName}>{strings.trim(teamName, 20)}</th>
                {labels.map((month, index) => <td key={month}>{teamNames[teamName][index]}</td>)}
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
