import React, { useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'
import {
  Card, CardBody,
  Table,
} from 'reactstrap'

import { tables } from '../../assets/utils'
import { BACKGROUND_COLORS } from '../../views/nexusReports/utils'

export default function DoughnutWithPercentage({
  title, totalLabel, totalRecords,
  sortedChartData, sortedRawData,
  className, onClick = () => {},
}) {
  const chartRef = useRef()
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    onClick: (e, item) => onClick(e, item),
    // FIXME: one needs to move the cursor a bit to see the result
    onHover(_event, chartElement) {
      if (chartElement.length !== 1) {
        return
      }

      const {
        _chart, _model, _index, _view,
      } = chartElement[0]
      const { x, y } = _model
      const { backgroundColor } = _view
      const { ctx, config } = _chart

      /* eslint no-underscore-dangle: 0 */
      const value = config.data.datasets[0].data[_index]
      const label = config.data.labels[_index]
      const truncatedLabel = label.replace(/(.{12})..+/, '$1…')
      const { total } = _chart.getDatasetMeta(0)
      const percentage = ((value / total) * 100).toFixed(1)

      ctx.restore()
      ctx.font = 'bolder 23px sans-serif'
      ctx.fillStyle = backgroundColor
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${percentage} %`, x, y - 5)
      ctx.font = '12px sans-serif'
      ctx.fillText(truncatedLabel, x, y + 15)
      ctx.save()
    },
  }

  return (
    <Card className={className}>
      <CardBody>
        <h6 className="big-title">{title}</h6>
        <Doughnut
          ref={chartRef}
          options={options}
          data={sortedChartData}
          onClick={onClick}
        />
        <div style={{ fontSize: '12px', margin: '5px 0', textAlign: 'center' }}>
          {`${totalLabel} : ${totalRecords}`}
        </div>
        <Table style={{ fontSize: '11px', marginBottom: '0' }}>
          <tbody>
            {sortedRawData.map((row, index) => (
              <tr key={index} style={{ backgroundColor: tables.rowColor(index) }}>
                <td>
                  <span style={{ color: BACKGROUND_COLORS[index] }}>&#9632; </span>
                  {row.name}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {row.count}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
