import React, { useEffect, useState } from 'react'

import { strings } from '../../assets/utils'
import DoughnutWithPercentage from '../../components/DoughnutWithPercentage'
import {
  BACKGROUND_COLORS, getPodNameFromIssue, isIssueBreachedSLA, sortObjectByIntValue,
} from './utils'

export default function SLABreachedByTeam({
  issues, issuesGroupedByTeam, teams, chartTitle,
}) {
  const [chartData, setChartData] = useState({})
  const [sortedBreachedSLA, setSortedBreachedSLA] = useState([])
  const [totalBreachedIssues, setTotalBreachedIssues] = useState(0)

  const populateChartData = (issuesByTeam) => {
    const labels = []
    const data = []
    const topIssuesByTeam = []
    let total = 0

    const sortedIssues = sortObjectByIntValue(issuesByTeam)
    sortedIssues.forEach((row) => {
      const [teamName, count] = row

      labels.push(strings.trim(teamName, 21))
      data.push(String(count))
      total += count

      topIssuesByTeam.push({
        count,
        name: teamName,
      })
    })
    setTotalBreachedIssues(total)
    setSortedBreachedSLA(topIssuesByTeam)

    setChartData({
      labels,
      datasets: [{
        label: 'SLA Breached',
        borderWidth: 0,
        hoverOffset: 4,
        hoverBorderWidth: 2,
        hoverBorderColor: '#dee2e6',
        data,
        backgroundColor: BACKGROUND_COLORS.slice(0, issuesByTeam.length),
      }],
    })
  }

  const groupBreachedSLAByTeamName = () => {
    const breachedSLACount = {}

    issues.forEach((issue) => {
      if (isIssueBreachedSLA(issue)) {
        const teamName = getPodNameFromIssue(teams, issue)
        breachedSLACount[teamName] = (breachedSLACount[teamName] || 0) + 1
      }
    })

    return breachedSLACount
  }

  useEffect(() => {
    if (!issues) return

    const issuesByTeam = groupBreachedSLAByTeamName(issues, teams)
    populateChartData(issuesByTeam)
  }, [issues])

  useEffect(() => {
    if (!issuesGroupedByTeam) return

    populateChartData(issuesGroupedByTeam)
  }, [issuesGroupedByTeam])

  return (
    <DoughnutWithPercentage
      title={chartTitle}
      totalLabel="Total Breached Issues"
      totalRecords={totalBreachedIssues}
      sortedChartData={chartData}
      sortedRawData={sortedBreachedSLA}
    />
  )
}
