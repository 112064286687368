import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import { strings } from '../../assets/utils'

export default function IssuesReportByPDG({
  monthlyPDGData, labels,
}) {
  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          Monthly Issues By PDG
        </h6>
        <Table striped>
          <thead>
            <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
              <td>PDG</td>
              {labels.map((month) => <td key={month}>{month}</td>)}
            </tr>
          </thead>
          <tbody>
            {monthlyPDGData && Object.keys(monthlyPDGData).sort().map((team) => (
              <tr style={{ fontSize: '11px' }} key={team}>
                <th title={team}>{strings.trim(team, 20)}</th>
                {labels.map((month, index) => <td key={month}>{monthlyPDGData[team][index]}</td>)}
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
