import React from "react";

import { 
    Button, Table,
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, Spinner
} from "reactstrap";

import { algolia } from 'assets/algolia';
import { strings } from 'assets/utils';

import IssueEditor from "components/IssueEditor/IssueEditor";

class IssueSearch extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            queryText: '',
            searchResults: [],
            initialSearch: false,
            isLoading: false
        }

        this.searchIssues = this.searchIssues.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    setSearchTimer(queryText) {
        clearTimeout(this.searchIssues);
        this.setState({isLoading: true, queryText: queryText}, () => {
            setTimeout(this.searchIssues,2100);
        });
    }
    
    searchIssues() {
        this.setState({searchResults: []}, () => {
            algolia.searchIssues(this.state.queryText).then(results => {

                results.forEach(result => {
                    if(!result.responseTextEnglish) result.responseTextEnglish = "";
                    if(!result.responseTextIndonesian) result.responseTextIndonesian = "";
                    if(!result.responseTextThai) result.responseTextThai = "";
                    if(!result.responseTextVietnamese) result.responseTextVietnamese = "";
                })

                this.setState({searchResults: results, initialSearch: true, isLoading: false}); 
            });
        });
    }

    onIssueChosen(issue) {
        this.props.onIssueChosen(issue);
        this.setState({searchResults:[], queryText: '', modal:false});
    }

    showAfterSearch() {
        if(this.state.initialSearch)
            return "flex";
        else
            return "none";
    }
    showWhenResults() {
        if(this.state.searchResults.length > 0)
            return "flex";
        else
            return "none";
    }
    showWhenNoResults() {
        if(this.state.initialSearch && this.state.searchResults.length === 0)
            return "flex";
        else
            return "none";
    }
    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showTemplateIcon(issue) {
        if(issue.responseTextEnglish.length > 0 || 
            issue.responseTextIndonesian.length > 0 || 
            issue.responseTextThai.length > 0 || 
            issue.responseTextVietnamese.length > 0 )
                return "block";
            else 
                return "none";
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const iconResponseText = {
            fontSize:"17px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div>
                <Button size="sm" color="secondary" onClick={this.toggleModal}  >  
                    {this.props.label}
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" autoFocus={false} >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm={12}>
                                <br />Issue Search<br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-zoom-split" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input autoFocus={true} placeholder="keyword search" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{display:this.showWhenResults()}}>
                            <Col sm={12}>
                                <br />
                                Is your issue already here?
                            </Col>
                            <Col sm={12}>
                                <Table>
                                    {this.state.searchResults.map((issue, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{display:this.showTemplateIcon(issue)}}>
                                                <i style={iconResponseText} className="nc-icon nc-chat-33" />
                                            </div>
                                        </td>
                                        <td style={{maxWidth:"84px"}}>
                                            <Button size="sm" color="success" onClick={() => this.onIssueChosen(issue)} >Select</Button>
                                        </td>
                                        <td style={{maxWidth:"76px"}}>
                                            <IssueEditor issue={issue} />
                                        </td>
                                        <td style={{fontSize:"12px"}}>
                                            <div style={{fontWeight:600, color:"green"}}>{issue.title}</div>
                                            {strings.trim(issue.description,66)}
                                        </td>
                                    </tr>
                                    ))}
                                </Table>
                            </Col>
                        </Row>
                        <Row style={{width:"100%", display:this.showWhenResults()}}>
                            <Col sm={12} >
                                <br />
                                If not, you can add it here.
                            </Col>
                        </Row>
                        <Row style={{width:"100%", display:this.showWhenNoResults()}}>
                            <Col sm={12} >
                                <br />
                                No existing issue found.
                            </Col>
                        </Row>
                        <Row style={{display:this.showAfterSearch()}}>
                            <Col sm="12" >
                            <div style={{display:"inline-block"}}>
                                <IssueEditor onCreated={(issue) => this.onIssueChosen(issue)} />
                            </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                
            </div>
        )
    }
}

export default IssueSearch;