import React from "react";

import { connect } from 'react-redux';
import { db } from 'assets/firebase';

//utils
import { tables } from 'assets/utils';

import {
    Button,
    Card, CardBody, CardHeader, 
    Input, 
    Row, Col,
  } from "reactstrap";

import OKRDialog from './OKR.js';
import PersonCard from 'components/PersonCard/PersonCard.js';
import TeamCard from "components/TeamCard/TeamCard.js";

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate()) + ' ' + time;
}

class OKRCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            okr: props.okr,
            openDependentOKR: false,
            dependentOKRID: "",
            dependentKRID: "",
            team: {}
        };

        this.state.okr = props.okr;

        this.state.okr.statusColor = 'gray';

        this.state.okr.KRs.forEach(kr => {

            if(!kr.statusReports) 
                kr.statusReports = [];

            else if (kr.statusReports.length > 0) {

                if(kr.statusReports[0].status === 'red')
                    this.state.okr.statusColor = 'red';
                else if (kr.statusReports[0].status === 'orange' && this.state.okr.statusColor !== 'red')
                    this.state.okr.statusColor ='orange';
                else if(kr.statusReports[0].status === 'green' && this.state.okr.statusColor !== 'red' && this.state.okr.statusColor !== 'yellow')
                    this.state.okr.statusColor ='green';
            }
        });

        db.getTeam(this.state.okr.teamID).then(team => {
            this.setState({team: team});
        })
    }

    addKRStatus(kr) {

        console.log('addKRStatus()',kr)

        var okr = this.state.okr;
        var statusReports = kr.statusReports;

        if(!kr.newStatus)
            kr.newStatus = 'gray';
        
        statusReports.unshift({status: kr.newStatus, text: kr.newStatusText, personID: this.props.user.person.id, created: new Date().getTime()});

        for(var j = 0; j < okr.KRs.length; j++) {

            if(okr.KRs[j].id === kr.id) {

                okr.KRs[j]["statusReports"] = statusReports;
                okr.KRs[j]["newStatusText"] = "";

                delete okr.KRs[j].person;
                delete okr.KRs[j].showStatus;

                console.log('db.updateOKR()',okr)
                db.updateOKR(okr.id, okr);

                okr.KRs[j].statusReports[0].person = this.props.user.person;
                okr.KRs[j].showStatus = true;

                break;
            }
        }
        
        this.setState({okr: okr});
        if(this.props.onUpdate)
            this.props.onUpdate(okr);
    }

    updateKR(kr, property, value) {

        var okr = this.state.okr;

        for(var j = 0; j < okr.KRs.length; j++) {
            if(okr.KRs[j].id === kr.id) {
                okr.KRs[j][property] = value;
                break;
            }
        }

        this.setState({okr: okr});
        if(this.props.onUpdate)
            this.props.onUpdate(okr);
    }

    showKRStatus(kr) {
        if(kr.showStatus)
            return "block";
        else 
            return "none";
    }

    krStatus(kr) {

        var color = "gray";
        if(kr.statusReports)
            if(kr.statusReports.length > 0)
                color = kr.statusReports[0].status;

        return <div style={{height:"24px", width:"24px", borderRadius:"6px", backgroundColor:color, cursor:"pointer"}} 
                    onClick={() => this.updateKR(kr, "showStatus", !kr.showStatus)}
                    title="Show/Hide Status Details">
                </div>
    }

    krStatusIcon(color) {
        return <div style={{height:"24px", width:"24px", borderRadius:"6px", backgroundColor:color, cursor:"pointer"}} >
                </div>
    }

    ownerCard(kr) {
        if(kr.person) {
            return (
                <PersonCard person={kr.person} showThumb />
            )
        }
    }
    ownerName(kr) {
        if(kr.person) {
            return this.trim(kr.person.name,23);
        }
    }

    showDetail() {
        if(!this.props.onKRSelected)
            return "block";
        else
            return "none";
    }
    showChooser() {
        if(this.props.onKRSelected)
            return "block";
        else    
            return "none";
    }
    dependencyIconColor(kr) {
        if(kr.dependencies.length > 0)
            if(kr.dependencies[0].krID === this.props.highlightKR)
                return "green";
            else 
                return "#666";
        else 
            return "#ddd";
    }
    dependencyIconTitle(kr) {
        if(kr.dependencies.length === 0)
            return "This KR is NOT depedent on any others.";
    }
    dependencyIconCursor(kr) {
        if(kr.dependencies.length === 0)
            return "default";
        else 
            return "pointer";
    }
    openDependentOKR(kr) {
        if(kr.dependencies.length > 0) {
            this.setState({openDependentOKR: true, dependentOKRID: kr.dependencies[0].okrID, dependentKRID: kr.dependencies[0].krID}, () => {
                setTimeout(() => {
                    this.setState({openDependentOKR: false});
                }, 500);
            })
        }
    }
    teamCard() {
        if(this.state.team.id) {
            return (
                <TeamCard team={this.state.team} showLargeThumb />
            )
        }
    }

    render() {

        return (
            <Row > 
                <Col sm="12" >
                    <Card>
                        <CardHeader style={{height:"5px", padding:"5px", backgroundColor:this.state.okr.statusColor, borderTopLeftRadius:"9px", borderTopRightRadius:"9px"}}>

                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="10" style={{fontWeight:600, fontSize:"13px"}}>
                                    {this.state.okr.number}. {this.state.okr.description}
                                    <div style={{color:"#888"}}>
                                        {this.state.okr.status}
                                    </div>
                                </Col>
                                <Col xs="2" style={{textAlign:"right"}}>
                                    {this.teamCard()}
                                </Col>
                            </Row>
                            <div style={{padding:"20px"}}>
                                <Row>
                                    <Col xs="12" style={{fontSize:"12px", fontWeight:500, marginTop:"8px", marginBottom:"4px"}}>
                                        {this.state.okr.KRs.map((kr, index) => (
                                            <div key={index}>
                                                <Row  style={{padding:"10px", backgroundColor:tables.rowColor(index)}}>
                                                    <Col xs="1" style={{fontSize:"11px", color:"green", textAlign:"right"}}>
                                                        {this.state.okr.number}.{kr.number}
                                                    </Col>
                                                    <Col xs="8" title={kr.notes}>
                                                        {kr.description}
                                                    </Col>
                                                    
                                                    <Col xs="1">
                                                        {this.ownerCard(kr)}
                                                    </Col>
                                                    <Col xs="1" style={{display:this.showDetail()}}>
                                                        {this.krStatus(kr)}
                                                    </Col>
                                                    <Col xs="1" style={{display:this.showDetail()}}>
                                                        <div style={{paddingTop:"6px"}} title={this.dependencyIconTitle(kr)}>
                                                            <i style={{color:this.dependencyIconColor(kr), cursor:this.dependencyIconCursor(kr), fontSize:"17px"}} className="fas fa-link" onClick={() => this.openDependentOKR(kr)} />
                                                        </div>
                                                    </Col>
                                                    <Col xs="2" style={{display:this.showChooser()}}>
                                                        <Button size="sm" color="secondary" onClick={() => this.props.onKRSelected(this.state.okr, kr)}>Select</Button>
                                                    </Col>
                                                </Row>
                                                <Row style={{display:this.showKRStatus(kr), backgroundColor:"#888888", padding:"0px"}}>
                                                
                                                    <Row style={{padding:"10px", paddingTop:"20px"}}>
                                                        <Col xs="1">
                                                            
                                                        </Col>
                                                        <Col xs="8">
                                                            <textarea value={kr.newStatusText} placeholder="new status" 
                                                                onChange={(e) => this.updateKR(kr, "newStatusText", e.target.value)} 
                                                                style={{backgroundColor:"white", padding:"6px", lineHeight:"17px", minHeight:"69px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                                        </Col>
                                                        <Col xs="3">
                                                            <Input placeholder="" 
                                                                type="select" style={{fontSize:"10px"}}
                                                                value={kr.newStatus} 
                                                                onChange={(e) => this.updateKR(kr, "newStatus", e.target.value)} 
                                                            >
                                                                <option value="gray">Not Started</option>
                                                                <option value="green">On Track</option>
                                                                <option value="darkgreen">Complete</option>
                                                                <option value="orange">At Risk</option>
                                                                <option value="red">Failed</option>
                                                            </Input>
                                                            <div style={{fontSize:"11px", marginRight:"6px"}}>
                                                                <Button color="success" style={{height:"29px", width:"100%"}} onClick={() => this.addKRStatus(kr)} >Add</Button>
                                                            </div>
                                                        </Col>
                                                        
                                                        <Col xs="1">

                                                        </Col>
                                                        <Col xs="11" >
                                                            {kr.statusReports.map((sr, index) => (
                                                                <Row style={{fontSize:"11px", padding:"10px", color:"white"}} key={index}>
                                                                    <Col xs="10">
                                                                        {sr.text}
                                                                        <div style={{fontSize:"10px", color:"#cccccc"}}>
                                                                            {getFormattedDate(sr.created)}
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="1">
                                                                        {this.ownerCard(sr)}
                                                                    </Col>
                                                                    <Col xs="1">
                                                                        {this.krStatusIcon(sr.status)}
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Col>
                                                    </Row>                                                                        
                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                                <div style={{display:"none"}}>
                                    <OKRDialog isOpen={this.state.openDependentOKR} okrID={this.state.dependentOKRID} krID={this.state.dependentKRID} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

OKRCard = connect(mapStateToProps)(OKRCard);
export default OKRCard;