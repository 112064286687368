import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

import firebase from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const db = firebase.firestore()
const func = firebase.functions()
const auth = firebase.auth()
const googleAuth = new firebase.auth.GoogleAuthProvider()
const gojekAuth = new firebase.auth.SAMLAuthProvider(process.env.REACT_APP_FIREBASE_SAML_PROVIDER)
const storage = firebase.storage()

export {
  auth,
  db,
  func,
  gojekAuth,
  googleAuth,
  storage,
}
