import React from "react";

//redux
import { connect } from 'react-redux'

// reactstrap components
import {
    Card, CardBody,
    Row, Col,
    Media
} from "reactstrap";

import categoryList from "variables/appreciateCategory.js";
import Lottie from 'react-lottie';

class KudosCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            discussion: props.discussion,
            person: {}
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

    }

    toggleModal() {
        this.setState({ modal: !this.state.modal });
    }

    showThumb() {
        if (this.props.showThumb || this.props.showLargeThumb || this.props.showXLargeThumb)
            return "inline-block";
        else
            return "none";
    }
    showCard() {
        if (!this.props.showThumb && !this.props.showLargeThumb && !this.props.showXLargeThumb)
            return "block";
        else
            return "none"
    }
    getThumbSize(axis) {
        if (this.props.showThumb)
            return "50px";
        else if (this.props.showLargeThumb)
            return "89px";
        else if (this.props.showXLargeThumb & axis === 'x')
            return "100%";
        else if (this.props.showXLargeThumb & axis === 'y')
            return "120px";
    }



    render() {

        const title = {
            fontSize: "12px",
            textAlign: "left",
        };

        const description = {
            fontSize: "8px",
            textAlign: "left",
            color: "#232323",
            fontWeight: 400
        };
        const appreciator = {
            fontSize: "8px",
            textAlign: "left",
            color: "green",
            fontWeight: 800
        };
        const defaultOptions = {
            loop: true,
            autoplay: true,
            // animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMaxyMax"
            }
        };
        
        let categoryObject = categoryList.find((category) => {

            if (category.id === this.props.appreciateObject.category) {

                category.appreciatorName = this.props.appreciateObject.appreciatorName;
                category.comment = this.props.appreciateObject.comment;
                if (category.id >= 12) {

                    category.lottie =
                        <Lottie
                            options={{ defaultOptions, animationData: category.png }}
                        />
                }
                else {
                    category.lottie =
                        <Media src={category.png} />
                }
                return category;
            }
        })


        return (

            <div style={{ whiteSpace: "normal" }}>


                <div ref={this.myRef} style={{ display: this.showCard(), width: "130px", marginTop: "10px", paddingLeft: "3px", paddingRight: "3px" }}>

                    <Card>

                        <div style={{
                            backgroundSize: "cover", height: "100%", width: "124px", backgroundColor: "#aaaaaa", borderTopRightRadius: "6px", borderTopLeftRadius: "6px",

                            borderBottom: "1px solid green"
                        }}>
                            {categoryObject.lottie}
                        </div>

                        <CardBody style={{ minHeight: "135px", padding: "8px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                            {/* <Row>
                                        <Col md="12" >
                                            <div>
                                                
                                                {categoryObject.lottie}
                                                <Lottie sm="12" style={{ width: "124px",height:"100%",color: "green" }}
                                                    options={{ defaultOptions, animationData: categoryObject.image }}
                                                />
                                            </div>
                                        </Col>
                        </Row> */}

                            {/* <div style ={{padding:"8px"}}> */}
                            <Row>
                                <Col md="12">
                                    <div style={title}>{categoryObject.title}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div style={description}>{categoryObject.comment}</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div style={appreciator}>-{categoryObject.appreciatorName}</div>
                                </Col>
                            </Row>
                            {/* </div> */}
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

KudosCard = connect(mapStateToProps)(KudosCard);
export default KudosCard;