import classnames from 'classnames'
import React from 'react'
import {
  Collapse,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap'

import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

class GoCodeNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: true,
      color: 'navbar-transparent',
      query: '',
      view: props.view,
      othersPresent: [],
      isPersonModalOpen: false,
    }

    this.searchText = this.searchText.bind(this)
    this.setSearchTimer = this.setSearchTimer.bind(this)
    this.showPersonModal = this.showPersonModal.bind(this)
    this.closePersonModal = this.closePersonModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
  }

  componentDidUpdate(e) {
    /*
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    */
    if (e.othersPresent !== this.props.othersPresent) {
      this.setState({ othersPresent: this.props.othersPresent })
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: 'bg-white',
      })
    } else {
      this.setState({
        color: 'navbar-transparent',
      })
    }
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white'
    } else {
      newState.color = 'navbar-transparent'
    }
    this.setState(newState)
  }

  setSearchTimer(query) {
    clearTimeout(this.searchText)
    this.setState({ query }, () => {
      setTimeout(this.searchText, 1700)
    })
  }

  searchText() {
    this.props.onSearch(this.state.query)
  }

  apiView() {
    this.setState({ query: '', view: 'api' })
    // this.props.onFeedbackViewClick();
  }

  portalView() {
    this.setState({ query: '', view: 'portal' })
    // this.props.onIssuesViewClick();
  }

  standardsView() {
    this.setState({ query: '', view: 'standards' })
    // this.props.onIssuesViewClick();
  }

  selectedView(value) {
    if (value === this.state.view) return 'green'
    return '#232323'
  }

  showOtherPeople() {
    if (this.state.othersPresent.length > 0) return 'list-item'
    return 'none'
  }

  showPersonModal() {
    this.setState({
      isPersonModalOpen: true,
    })
  }

  closePersonModal() {
    this.setState({
      isPersonModalOpen: false,
    })
  }

  render() {
    const apiIcon = {
      cursor: 'pointer',
      color: this.selectedView('api'),
      marginTop: '5px',
    }
    const portalIcon = {
      cursor: 'pointer',
      color: this.selectedView('portal'),
      marginTop: '5px',
    }
    const standardsIcon = {
      cursor: 'pointer',
      color: this.selectedView('standards'),
      marginTop: '5px',
    }

    return (
      <div>

        <Navbar
          className={classnames('navbar-absolute fixed-top', 'bg-white')}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">

              <div style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold' }}>
                GoTo Developer Platform
              </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar style={{ marginLeft: '20px' }}>
                <NavItem
                  style={{
                    display: this.showOtherPeople(),
                    backgroundImage: 'linear-gradient(to right, #ffffff, #efefef)',
                    borderRadius: '0px 10px 10px 0px',
                    marginRight: '20px',
                  }}
                  title="Other GoTroops here with you."
                >
                  <NavLink>
                    {this.state.othersPresent.map((person, index) => (
                      <div style={{ marginTop: '5px', marginRight: '10px', display: 'inline-block' }} key={index}>
                        <PersonCard person={person} showThumb />
                      </div>
                    ))}
                  </NavLink>
                </NavItem>
                {/* <NavItem style={{ marginTop: '10px', marginRight: '20px' }}>
                  <InputGroup className="no-border" style={{ display: 'none' }}>
                    <Input
                      value={this.state.query}
                      placeholder="Search..."
                      type="text"
                      onChange={(e) => this.setSearchTimer(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i className="nc-icon nc-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </NavItem> */}
                <NavItem style={{ display: 'none' }}>
                  <NavLink
                    className="btn-magnify"
                    style={portalIcon}
                    onClick={() => this.portalView()}
                    title="Home"
                  >
                    <i className="nc-icon nc-shop" />
                    <p>
                      <span className="d-lg-none d-md-block">Home</span>
                    </p>
                  </NavLink>
                </NavItem>
                <NavItem style={{ display: 'none' }}>
                  <NavLink
                    className="btn-magnify"
                    style={apiIcon}
                    onClick={() => this.apiView()}
                    title="API Documentation"
                  >
                    <i className="nc-icon nc-single-copy-04" style={{ fontSize: '19px' }} />
                    <p>
                      <span className="d-lg-none d-md-block">API Documentation</span>
                    </p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    style={standardsIcon}
                    onClick={() => this.standardsView()}
                    title="Standards"
                  >
                    <i className="nc-icon nc-book-bookmark" style={{ fontSize: '19px' }} />
                    <p>
                      <span className="d-lg-none d-md-block">Standards</span>
                    </p>
                  </NavLink>
                </NavItem>

                <ProfileMenuDropdown user={this.props.user} showPersonModal={this.showPersonModal} />

                <PersonCard
                  person={this.props.user.person}
                  showPersonModal={this.state.isPersonModalOpen}
                  closePersonModal={this.closePersonModal}
                />

              </Nav>
            </Collapse>
          </Container>
        </Navbar>

      </div>
    )
  }
}

export default GoCodeNavbar
