import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { arraySort, strings, telemetry } from 'assets/utils';

// reactstrap components
import {
    Input,
    Card, CardHeader, CardBody, CardTitle,
    Row, Col,
    Nav, NavItem, NavLink,
  } from "reactstrap";

import ActivityCard from "./ActivityCard.js";
import PersonCard from "components/PersonCard/PersonCard.js";


import logo from "assets/img/goConnectIcon.png";

class Manager extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
          isLoading: true,
          managerID: this.props.user.personID, //'26565b9a-fa7a-4f10-aee6-2d9edc7929a5',// //
          manager: {},
          directReports: [],
          org: [],
          orgSize: 0,
          activeTab: 'all',
          activity: [],
          filtered: [],
          cycleFilter: '22h1',
          typeFilter: 'all',
          employeeFilter: 'all'
      }

      if(this.props.managerID)
        this.state.managerID = this.props.managerID;
    }

    componentDidMount() {

        db.getPerson(this.state.managerID).then(manager => {

            this.setState({manager: manager}, () => {
                
            });

            this.getOrg(this.state.managerID).then(org => {
                console.log(org)
            });

            db.getPeopleByManagerID(this.state.managerID).then(people => {

                var directReports = [];
                people.forEach(person => {
                    if(person) directReports.push(person);
                })
                directReports.sort(arraySort.byName);
                
                //this.state.managerID
                db.getPeopleActivityByManagerID(this.state.managerID).then(activity => {
                
                    var people = [];
                    activity.forEach(row => {

                        var isFound = false;
                        for(var x = 0; x < people.length; x++) {
                            if(people[x] === row.personID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) 
                            people.push(row.personID);

                        isFound = false;
                        for(var y = 0; y < people.length; y++) {
                            if(people[y] === row.authorID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) 
                            people.push(row.authorID);
                    })

                    var promises = [];
                    people.forEach(personID => { promises.push(db.getPerson(personID)) })

                    Promise.all(promises).then(people => {

                        activity.forEach(row => {
                            for(var i = 0; i < people.length; i++) {
                                if(row.personID === people[i].id) 
                                    row.person = people[i];
                                    
                                if(row.managerID === people[i].id)
                                    row.manager = people[i];

                                if(row.authorID === people[i].id)
                                    row.author = people[i];
                            }
                        });
                        activity.sort((a,b) => { return b.created - a.created });
        
                        this.setState({directReports: directReports, activity: activity}, this.filterActivity);
                    });                    
                });
            });
        })
        
        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "manager", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    addActivity(newActivity) {

        var activity = this.state.activity;

        activity.push(newActivity);

        activity.sort((a,b) => { return b.created - a.created });

        this.setState({activity: activity}, this.filterActivity);
    }

    changeTab(tab) {

        var typeFilter = this.state.typeFilter;

        if(tab === 'all')
            typeFilter = 'all';
        else if(tab === 'achievements')
            typeFilter = 'achievement';
        else if(tab === 'feedback')
            typeFilter = 'feedback';
        else if(tab === 'goals')
            typeFilter = 'goal';
        else if(tab === 'checkins')
            typeFilter = 'checkin';
        else if(tab === 'notes')
            typeFilter = 'note';

        this.setState({activeTab: tab, typeFilter: typeFilter}, () => {
            
            this.filterActivity();
        });
    }

    filterActivity(filter, value) {

        var filtered = [];

        var cycleFilter = this.state.cycleFilter;
        if(filter === 'cycle')
            cycleFilter = value;

        var employeeFilter = this.state.employeeFilter;
        if(filter === 'employee')
            employeeFilter = value;

        this.setState({employeeFilter: employeeFilter, cycleFilter: cycleFilter});


        this.state.activity.forEach(row => {
            if(row.cycle === cycleFilter)
                if(row.personID === employeeFilter || employeeFilter === 'all')
                    if(row.type === this.state.typeFilter || this.state.typeFilter === 'all')
                        filtered.push(row);
        })

        this.setState({filtered: filtered, isLoading: false});
    }


    
    getOrg(managerID, name) {
        
        return new Promise((resolve, reject) => {

            db.getPeopleByManagerID(managerID).then(directReports => {

                var promises = [];

                if(directReports.length > 0) {
   
                    var org = [];
                    if(directReports.length > 0)
                        org.push({id: managerID, name: name, directReports: directReports.length});

                    directReports.forEach(report => {
                        promises.push(this.getOrg(report.id, report.name));
                    });
                }

                Promise.all(promises).then((results) => {

                    results.forEach(result => {
                        if(result)
                            result.forEach(row =>
                                org.push(row));
                            
                    });                  

                    resolve(org);
                });
            });
        });
    }
    

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showResults() {
        if(!this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    employeeCard() {
        return (
            <PersonCard person={this.state.manager} showThumb />
        )
    }

    getEmployeePage(person) {
        window.open('/manager/employee/' + person.id);
    }

    
    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#666666"
        }
        
        const loadingDiv = {
            display: this.showLoading(),
            marginTop: '50px',
            textAlign: 'center',
            minHeight: '100px'
        }
        const link = {
            color:"#434343",
            fontWeight:500,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeLink = {
            color:"white",
            fontWeight:700,
            fontSize:"12px"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#efefef",
            marginLeft:"1px",   
        }

        return (
            <div style={{padding:"10px"}}>
               
               <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                <Row>
                    <Col xs="12" sm="12" md="8">
                        <Card style={{padding:"10px"}}>
                            <CardHeader>
                                <Row>
                                    <Col xs="12" sm="8">
                                        <CardTitle tag="h4">Manager Dashboard</CardTitle>
                                    </Col>
                                    <Col xs="12" sm="4" style={{textAlign:"right", paddingTop:"7px"}}>
                                        <ActivityCard directReports={this.state.directReports} onCreated={(a) => this.addActivity(a)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{paddingTop:"0px", minHeight:"400px"}}>
                                <Row style={{display:"none"}}>
                                    <Col xs="12" sm="7" md="8" lg="9" style={{fontWeight:500, marginBottom:"20px", fontSize:"11px"}}>
                                        It can be difficult to make sure you're putting in enough time with each of your 
                                        employees and that when review time comes around that you remember everything you 
                                        wanted to. Nothing is worse that going into calibration sessions unprepared to 
                                        fight for the employees in your care.<br /><br />

                                        GoConnect for Managers helps you provide feedback and keep notes along the way.
                                        Use the site to take note of accomplishments, share specific feedback or take 
                                        random notes you want to remember. You can even check-in along the way to get a sense 
                                        of how they think their doing and reset expectations if necessary.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <Nav style={{marginTop:"10px"}}>
                                            <NavItem style={(this.state.activeTab === 'all' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'all' ? activeLink : link )}
                                                    onClick={() => this.changeTab('all') }
                                                >
                                                    All Activity
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'achievements' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'achievements' ? activeLink : link )}
                                                    onClick={() => this.changeTab('achievements') }
                                                >
                                                    Achievements
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'feedback' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'feedback' ? activeLink : link )}
                                                    onClick={() => this.changeTab('feedback') }
                                                >
                                                    Feedback
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'goals' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'goals' ? activeLink : link )}
                                                    onClick={() => this.changeTab('goals') }
                                                >
                                                    Goals
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'checkins' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'checkins' ? activeLink : link )}
                                                    onClick={() => this.changeTab('checkins') }
                                                >
                                                    Check-Ins
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={(this.state.activeTab === 'notes' ? activeTab : tab )}>
                                                <NavLink
                                                    style={(this.state.activeTab === 'notes' ? activeLink : link )}
                                                    onClick={() => this.changeTab('notes') }
                                                >
                                                    Notes
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Row  style={{padding:"10px", paddingTop:"20px", paddingBottom:"0px", margin:"0px", marginBottom:"20px", borderStyle: 'solid', borderWidth: '1px 1px 1px 1px', borderColor: 'green'}}>
                                    <Col xs="12">
                                        <Row style={{marginBottom:"20px"}}>
                                            <Col xs="6">
                                                Employee
                                                <Input placeholder="" 
                                                    type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                                    value={this.state.employeeFilter} onChange={(e) => this.filterActivity("employee", e.target.value)} >
                                                        <option value="all">All</option>
                                                        {this.state.directReports.map((employee, index) => (
                                                            <option value={employee.id} key={index}>{employee.name}</option>
                                                        ))}
                                                </Input>
                                            </Col>
                                            <Col xs="6">
                                                Cycle
                                                <Input placeholder="" 
                                                    type="select" style={{border:"1px solid #888888", fontSize:"11px"}}
                                                    value={this.state.cycleFilter} onChange={(e) => this.filterActivity("cycle", e.target.value)} >
                                                        <option value="21h1">2021 H1</option>
                                                        <option value="21h2">2021 H2</option>
                                                        <option value="22h1">2022 H1</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div style={loadingDiv}>
                                                    <div className="spin">
                                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div style={{marginTop:"20px"}}> {/**maxHeight:"432px", overflow:"auto" */}
                                                    {this.state.filtered.map((activity, index) => (
                                                        <ActivityCard key={index} activity={activity} />
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                               
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="4">
                        <Card style={{padding:"10px", paddingTop:"20px", paddingBottom:"20px"}}>
                            <CardBody style={{minHeight:"300px", paddingTop:"0px"}}>
                                <Row>
                                    <Col xs="12">
                                        <h6>Direct Reports</h6>
                                        <br />
                                    </Col>
                                </Row>
                                {this.state.directReports.map((person, index) => (
                                <Row key={index} >
                                    <Col xs={12} >
                                        <div style={{display:"inline-block"}}>
                                            <PersonCard person={person} showLargeThumb />
                                        </div>
                                        <div style={{display:"inline-block", marginLeft:"9px", verticalAlign:"top", fontSize:"12px", cursor:"pointer"}}
                                            onClick={() => this.getEmployeePage(person)}>
                                            <div style={{color:"black", fontSize:"12px", fontWeight:600}}>
                                                {person.name}
                                            </div>
                                            <div style={{color:"#343434", fontSize:"11px"}}>
                                                {strings.trim(person.title,29)}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Manager = connect(mapStateToProps)(Manager);
export default Manager;