
import React from "react";

import { db } from 'assets/firebase';

import { tables, telemetry } from 'assets/utils';

// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
//import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

import {
  chartExample1,
  
} from "variables/charts.js";


/*
function getFormattedDateStamp(value) {

  function addZero(i) {
      if (i < 10) {
          i = "0" + i;
      }
      return i;
  }

  var dt = new Date(value);
  return dt.getUTCFullYear() + '.' + addZero(dt.getUTCMonth()+1) + '.' + addZero(dt.getUTCDate()) + ' ' + addZero(dt.getUTCHours()) + '.' + addZero(dt.getUTCMinutes()) + '.' + addZero(dt.getUTCSeconds());
}*/

let chartColor = "#FFFFFF";

  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageViews: [],
      pageViewSummary: [],
      uniqueUsersSummary: [],
      visitors: [],
      alerts: []
    }
  }


  componentDidMount() {
    
    this.getActivitySummaries();

    this.getPageViews().then(() => {
      this.getAlerts();
      this.getContributions();
    });
    
    //this.getProjects();
    //this.getUserActivity();
  }

  getProjects() {
    db.getProjects().then(projects => {
      
    })
  }

  getActivitySummaries() {

    
    db.getActivitySummaries(360).then(summaries => {
      
      console.log(summaries)

        var labels = [];
        var pageViewData = [];
        var userData = [];
      

        summaries.forEach(summary => {

            //labels.push(days[new Date(summary.summaryDate).getDay()]);
            var summaryDate = new Date(summary.summaryDate);
            labels.push((summaryDate.getMonth() + 1) + '-' + summaryDate.getDate());

            pageViewData.push(summary.totalPageViews );
            userData.push(summary.totalUsers);
            
            var totalPageViews = totalPageViews + summary.totalPageViews;
            var totalUsers = totalUsers + summary.totalUsers;
        })

        this.setState({
            pageViewSummary: { data: pageViewData, labels: labels }, 
            uniqueUsersSummary: { data: userData, labels: labels }
        });
    });
}

  getPageViews() {

    return new Promise((resolve, reject) => {
      
      var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      var daysHistory = 14;

      var upperBound = new Date();
      upperBound.setHours(0); upperBound.setMinutes(0); upperBound.setSeconds(0); upperBound.setMilliseconds(0);
      upperBound = upperBound.getTime();
      upperBound = upperBound + (24*60*60*1000); // i.e. start with end of today

      var promises = [];
      for(var i = 0; i < daysHistory; i++) {

        var lowerBound = upperBound - (24*60*60*1000);
        //promises.push(db.getUserActivityByTypeBetween('pageView', lowerBound, upperBound));
        promises.push(db.getUserActivityBetween(lowerBound, upperBound));
        upperBound = lowerBound;

      }

      Promise.all(promises).then(results => {

        var summaryData = [];
        var labels = [];
        var pageViews = [];
        var totalPageViews = [];
        var uniqueUsers = []; // per day
        var uniqueVisitors = []; // all days
        var peoplePromises = [];
        var uniqueActivityTypes = [];

        results.forEach(result => {

          if(result.length > 0) {

            var dateStamp = new Date(result[0].created);

            var totalPageViews = 0;
            var uniqueUsers = [];

            result.forEach(row => {

              var isFound = false;

              if(row.activityType === 'pageView') {

                totalPageViews = totalPageViews + 1;

                // counting users
                for(var j = 0; j < uniqueUsers.length; j++) {
                  if(uniqueUsers[j] === row.personID) {
                    isFound = true;
                    break;
                  }
                }
                if(!isFound) {
                  uniqueUsers.push(row.personID);

                  var personFound = false;
                  for(var k = 0; k < uniqueVisitors.length; k++) {
                    if(uniqueVisitors[k].personID === row.personID) {
                      uniqueVisitors[k].pageViews = uniqueVisitors[k].pageViews + 1;
                      personFound = true;
                      break;
                    }
                  }
                  if(!personFound) {
                    uniqueVisitors.push({ 
                      personID: row.personID,
                      pageViews: 1
                    });
                    peoplePromises.push(db.getPerson(row.personID));
                  } 
                } 

                // counting pages
                isFound = false;
                for (var l=0; l < pageViews.length; l++) {
                  if(pageViews[l].name === row.pageID) {
                    pageViews[l].visits = pageViews[l].visits + 1;
                    isFound = true;
                    break;
                  }
                }
                if(!isFound) {
                  pageViews.push({ name: row.pageID, visits: 0});
                }
              }

              // counting activity types
              isFound = false;
              for (var l=0; l < uniqueActivityTypes.length; l++) {
                if(uniqueActivityTypes[l].name === row.activityType) {
                  uniqueActivityTypes[l].count = uniqueActivityTypes[l].count + 1;
                  isFound = true;
                  break;
                }
              }
              if(!isFound) {
                uniqueActivityTypes.push({ name: row.activityType, count: 0});
              }

            });

            pageViews.sort( (b,a) => { return a.visits - b.visits } );
            uniqueActivityTypes.sort( (b,a) => { return a.count - b.count } );

            //console.log('uniqueActivityTypes',JSON.stringify(uniqueActivityTypes));

            summaryData.push({
              label: days[dateStamp.getDay()],
              timeStamp: dateStamp.getTime(),
              totalPageViews: totalPageViews,
              uniqueUsers: uniqueUsers.length,
              pageViews: pageViews
            });
          }
        })

        summaryData.sort( (b,a) => { return b.timeStamp - a.timeStamp } );

        summaryData.forEach(data => {
          labels.push(data.label);
          totalPageViews.push(data.totalPageViews);
          uniqueUsers.push(data.uniqueUsers);
          uniqueActivityTypes.push(data.activityTypes)
        })

        this.setState({
          //pageViewSummary: { data: totalPageViews, labels: labels }, 
          //uniqueUsersSummary: { data: uniqueUsers, labels: labels }, 
          pageViews: pageViews
        }, () => {
          //console.log(this.state.pageViewSummary)
        });

        Promise.all(peoplePromises).then(people => {

          // remove employees who have left the company
          // because we can't get their details anymore
          for(var p = people.length - 1; p > -1; p--) { 
            if(!people[p])
              people.splice(p,1);
          }

          people.forEach(person => {
            for(var p = 0; p < uniqueVisitors.length; p++) {
              if(person.id === uniqueVisitors[p].personID) {
                person.pageViews = uniqueVisitors[p].pageViews;
                break;
              }
            }
          })

          people.sort( (a,b) => { return b.pageViews - a.pageViews } );
          this.setState({visitors: people});

          resolve();
        })
      })
  });
  }

  getAlerts() {

    db.getAlertsLastDays(10).then(alerts => {

      var alertTypes = [];

      alerts.forEach(alert => {
        var isFound = false;
        for(var i = 0; i < alertTypes.length; i++) {
          if(alert.type === alertTypes[i].type) {
            alertTypes[i].count = alertTypes[i].count + 1;
            isFound = true;
          }
        }
        if(!isFound) {
          alertTypes.push({type: alert.type, count: 1});
        }
      });

      alertTypes.sort( (a,b) => { return b.count - a.count } );
      this.setState({alerts: alertTypes});
    });
  }

  getContributions() {
    db.getContributionsByPerson('7dedc453-090f-41d5-9e16-2ab51e753f1a').then(rows => {
      var contributions = [];
      rows.forEach(row => {
        if(row.activityType !== 'pageView' && row.activityType !== 'login') {
          contributions.push(row);
        }
      })
      
      console.log(contributions.length);
    })
  }

  /*
  getUserActivity() {
    
    db.getUserActivityLastXHours(72).then(activity => {

      // unique people
      var people = [];
      var peoplePromises = [];
      for(var a = 0; a < activity.length; a++) {

        var isPersonFound = false;
        activity[a].timeStamp = getFormattedDateStamp(activity[a].created);

        for(var p = 0; p < people.length; p++) {
          if(activity[a].personID === people[p].id) {
            isPersonFound = true;
            people[p].activity.push(activity[a]);
            break;
          }
        }
        if(!isPersonFound) {
          people.push({ id: activity[a].personID, activity: [ activity[a] ] });
          peoplePromises.push(db.getPerson(activity[a].personID));
        }
      }

      Promise.all(peoplePromises).then(results => {
        people.forEach(person => {
          for(var r = 0; r < results.length; r++) {
            if(person.id === results[r].id) {
              person.name = results[r].name;
              break;
            }
          }
        })        
      })
    
      console.log(people)
    })
  }*/

  

  render() {

    const pageViewSummaryChart = {
        data: (canvas) => {
          let ctx = canvas.getContext("2d");
      
          let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
          gradientStroke.addColorStop(0, "#18ce0f");
          gradientStroke.addColorStop(1, chartColor);
      
          let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
          gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
          gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));
    
          return {
            labels: this.state.pageViewSummary.labels, 
            datasets: [
              {
                label: "Page Views",
                borderColor: "green",
                pointHoverRadius: 0,
                pointRadius: 0,
                fill: false,
                backgroundColor: gradientFill,
                borderWidth: 3,
                barPercentage: 1,
                data: this.state.pageViewSummary.data
              },
            ],
          };
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            intersect: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontColor: "#9f9f9f",
                  beginAtZero: false,
                  maxTicksLimit: 5,
                },
                gridLines: {
                  drawBorder: true,
                  zeroLineColor: "green",
                  color: "rgba(200,200,200,0.5)",
                  display:true
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: true,
                  color: "rgba(200,200,200,0.5)",
                  zeroLineColor: "green",
                  display: true,
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9f9f9f",
                },
              },
            ],
          },
        },
    };

    const uniqueUsersSummaryChart = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");
    
        let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "#18ce0f");
        gradientStroke.addColorStop(1, chartColor);
    
        let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
        gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));
  
        return {
          labels: this.state.uniqueUsersSummary.labels, 
          datasets: [
            {
              label: "Unique Users",
              borderColor: "orange",
              pointHoverRadius: 0,
              pointRadius: 0,
              fill: false,
              backgroundColor: gradientFill,
              borderWidth: 3,
              barPercentage: 1,
              data: this.state.uniqueUsersSummary.data
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          intersect: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 5,
              },
              gridLines: {
                drawBorder: true,
                zeroLineColor: "green",
                color: "rgba(200,200,200,0.5)",
                display:true
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: true,
                color: "rgba(200,200,200,0.5)",
                zeroLineColor: "green",
                display: true,
              },
              ticks: {
                padding: 20,
                fontColor: "#9f9f9f",
              },
            },
          ],
        },
      },
    };

    return (
      <>
        <div className="content">
          <Row style={{display:"none"}}>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Capacity</p>
                        <CardTitle tag="p">150GB</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-refresh" />
                    Update Now
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Revenue</p>
                        <CardTitle tag="p">$ 1,345</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    Last day
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-vector text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Errors</p>
                        <CardTitle tag="p">23</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                    In the last hour
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-favourite-28 text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Followers</p>
                        <CardTitle tag="p">+45K</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-refresh" />
                    Update now
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
            <Row>
              <Col xs="12">
                <h4 style={{marginTop:"6px"}}>Activity Summary</h4>
                
              </Col>
            </Row>
          <Row>
            <Col lg="4" sm="6" style={{display:"none"}}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">$34,657</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="success" pill>
                          +18%
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    total earnings in last ten quarters
                  </h6>
                  <Line
                    data={chartExample1.data}
                    options={chartExample1.options}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">Financial Statistics</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="success"
                          size="sm"
                        >
                          <i className="nc-icon nc-simple-add" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardHeader style={{display:"none"}}>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">169</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="danger" pill>
                          -14%
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    page views
                  </h6>
                  <Line
                    data={pageViewSummaryChart.data}
                    options={pageViewSummaryChart.options}
                    height={380}
                    width={828}
                  />
                </CardBody>
                <CardFooter style={{display:"none"}}>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">View all members</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="danger"
                          size="sm"
                        >
                          <i className="nc-icon nc-button-play" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardHeader style={{display:"none"}}>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">8,960</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Badge color="warning" pill>
                          ~51%
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">unique users</h6>
                  <Line
                    data={uniqueUsersSummaryChart.data}
                    options={uniqueUsersSummaryChart.options}
                    height={380}
                    width={826}
                  />
                </CardBody>
                <CardFooter style={{display:"none"}}>
                  <hr />
                  <Row>
                    <Col sm="7">
                      <div className="footer-title">View more details</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round btn-icon"
                          color="warning"
                          size="sm"
                        >
                          <i className="nc-icon nc-alert-circle-i" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            
            <Col sm="6">
              <Card>
                <CardBody>

                  <Row style={{marginBottom:"11px"}}>
                    <Col xs="6">
                      <h5>TOP PAGES</h5>
                      <div style={{fontSize:"13px", color:"green", marginTop:"-10px"}}>
                        Last 14 Days
                      </div>
                    </Col>
                    <Col xs="6" style={{textAlign:"right", fontSize:"10px", color:"#343434"}}>
                      Unique Pages: {this.state.pageViews.length}
                    </Col>
                  </Row>
                  <Table>
                    <thead>
                      <tr style={{fontSize:"12px", fontWeight:600}}>
                        <td>
                          Name
                        </td>
                        <td style={{textAlign:"right"}}>
                          Page Views
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pageViews.map((page, index) => (
                      <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                        <td>
                          {page.name}
                        </td>
                        <td style={{textAlign:"right"}}>
                          {page.visits}
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <br />
              <Card>
                <CardBody>

                  <Row style={{marginBottom:"11px"}}>
                    <Col xs="6">
                      SLACK ALERTS SENT
                    </Col>
                    <Col xs="6" style={{textAlign:"right", fontSize:"10px", color:"#343434"}}>
                      Unique Alert Types: {this.state.alerts.length}
                    </Col>
                  </Row>
                  <Table>
                    <thead>
                      <tr style={{fontSize:"12px", fontWeight:600}}>
                        <td>
                          Type
                        </td>
                        <td style={{textAlign:"right"}}>
                          Alerts Sent
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.alerts.map((alert, index) => (
                      <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                        <td>
                          {alert.type}
                        </td>
                        <td style={{textAlign:"right"}}>
                          {alert.count}
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardBody>
                  <Row style={{marginBottom:"11px"}}>
                    <Col xs="6">
                      <h5>TOP USERS</h5>
                      <div style={{fontSize:"13px", color:"green", marginTop:"-10px"}}>
                        Last 14 Days
                      </div>
                    </Col>
                    <Col xs="6" style={{textAlign:"right", fontSize:"10px", color:"#343434"}}>
                      Unique Users: {this.state.visitors.length}
                    </Col>
                  </Row>
                  
                  <Table>
                    <thead>
                      <tr style={{fontSize:"12px", fontWeight:600}}>
                        <td>
                          Name
                        </td>
                        <td style={{textAlign:"right"}}>
                          Page Views
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.visitors.map((person, index) => (
                      <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                        <td>
                          {person.name}
                        </td>
                        <td style={{textAlign:"right"}}>
                          {person.pageViews}
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            
            <Col sm="6">
              
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
