import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button, Table } from "reactstrap"
import format from 'date-fns/format'
import { db } from '../../assets/firebase'
import logo from '../../assets/img/goConnectIcon.png'

function ApprovalRequest({ approval, updateApprovalRequest, hasClosed }) {

  const handleApproveRequest = () => {
    const data = {...approval, status: 'approved', approvedAt: new Date().getTime(), updatedAt: new Date().getTime() }
    delete data.id
    db.updateApprovalRquest(approval.id, data).then(() => {
      updateApprovalRequest({...approval, ...data })
    })
    
  }

  const handleRejectRequest = () => {
    const data = {...approval, status: 'rejected', rejectedAt: new Date().getTime(), updatedAt: new Date().getTime() }
    delete data.id
    db.updateApprovalRquest(approval.id, data).then(() => {
      updateApprovalRequest({...approval, ...data })
    })
  }

  return (
    <tr style={{marginBottom: '10px'}}>
      <td>
        <div style={{ marginTop: 0, fontSize: '18px', fontWeight:"600", color:"green" }}>{approval.title}</div>
        <div>{approval.description}</div>
      </td>
      <td style={{ textAlign: "center" }}>{format(new Date(approval.createdAt), "dd, MMM, yyyy")}  </td>
      <td style={{ textAlign: "center" }}>{approval.requestor}</td>
      {hasClosed ? <td style={{textAlign:"center"}}>{approval.status}</td>:
        <td>
        <Button color="success" onClick={handleApproveRequest}>Approve</Button>
        <Button color="danger" onClick={handleRejectRequest}>Reject</Button>
      </td>
      }
    </tr>
  )
} 
  
function Approvals({ user }) {
  const [isLoadingData, setIsLaodingData] = useState(true)
  const [openApprovalRequests, setOpenApprovalRequests] = useState([])
  const [closedApprovalRequests, setClosedApprovalRequests] = useState([])

  useEffect(() => {
    db.getApprovalRquests(user.email).then(res => {
      setOpenApprovalRequests(res.filter(approvalRequest => approvalRequest.status === 'pending'))
      setClosedApprovalRequests(res.filter(approvalRequest => approvalRequest.status !== 'pending'))
      setIsLaodingData(false)
    })
  }, [])

  const updateApprovalRequest = (request) => {
    const updatedOpenApprovalRequests = openApprovalRequests.filter(approvalRequest => approvalRequest.id !== request.id)
    const updatedClosedApprovalRequests = [request, ...closedApprovalRequests]
    setOpenApprovalRequests(updatedOpenApprovalRequests)
    setClosedApprovalRequests(updatedClosedApprovalRequests)
  }

  const loadingDiv = {
    marginTop: '50px',
    textAlign: 'center',
    minHeight: '100px',
  }

  return (
    <div style={{ padding: '40px' }}>
      <style>
          {
              `
              @keyframes spinning {
                  from { transform: rotate(0deg) }
                  to { transform: rotate(360deg) }
              }
              .spin {
                  animation-name: spinning;
                  animation-duration: 3s;
                  animation-iteration-count: infinite;
                  /* linear | ease | ease-in | ease-out | ease-in-out */
                  animation-timing-function: linear;
              }
              `
          }
      </style>
      {isLoadingData ? <div style={loadingDiv}>
              <div className="spin">
                <img src={logo} alt="logo" style={{ height: '43px', width: '43px' }} />
              </div>
            </div>
        :
        <Card>
          <CardBody>
            <div style={{ marginBottom: '60px' }}>
              <h6 style={{ marginBottom: '20px' }}>Open Requests</h6>
              <div>
                <Table style={{borderColor: "green", width: "100%"}} striped>
                  <thead> 
                    <tr style={{fontSize: "12px", fontWeight: "700"}}>
                        <td style={{ width: '100%' }}>Title</td>
                        <td style={{ textAlign: "center", minWidth: "120px"}}>Created At</td>
                        <td style={{ textAlign: "center", minWidth: "150px"}}>Requested by</td>
                        <td style={{ textAlign: "center", minWidth: "240px"}}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {openApprovalRequests.map(approval => (<ApprovalRequest approval={approval} updateApprovalRequest={updateApprovalRequest} hasClosed={false} />))}
                  </tbody>
                </Table>
                {openApprovalRequests.length === 0 && <div>No Open Request Pending</div>
                }
              </div>
            </div>
            <h6 style={{ marginBottom: '20px' }}>Closed Requests</h6>
            <Table style={{borderColor: "green", width: "100%"}} striped>
              <thead> 
                <tr style={{fontSize: "12px", fontWeight: "700"}}>
                    <td style={{ width: '100%' }}>Title</td>
                    <td style={{ textAlign: "center", minWidth: "120px" }}>Created At</td>
                    <td style={{ textAlign: "center", minWidth: "150px" }}>Requested by</td>
                    <td style={{ textAlign: "center", minWidth: "240px" }}>Status</td>
                </tr>
              </thead>
              <tbody>
                {closedApprovalRequests.map(approval => (<ApprovalRequest key={approval.id} approval={approval} hasClosed />))}
              </tbody>
            </Table>
            {closedApprovalRequests.length === 0 && <div>No Closed Request</div>}
          </CardBody>
      </Card>
      }
    </div>
  )
}

export default Approvals
