import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'

import { SEVERITY_COLORS } from './utils'

const options = {
  responsive: true,
  legend: {
    display: true,
  },
  tooltips: {
    enabled: true,
    intersect: false,
  },
  animations: {
    tension: {
      duration: 1000,
      easing: 'linear',
      from: 0,
      to: 1,
      loop: true,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: '#9f9f9f',
          beginAtZero: false,
          maxTicksLimit: 5,
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: 'transparent',
          color: 'rgba(255,255,255,0.05)',
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          zeroLineColor: 'transparent',
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: '#9f9f9f',
        },
      },
    ],
  },
}

export default function IssuesBySeverityBarGraph({
  labels, monthlySeverityData,
}) {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Low',
        data: monthlySeverityData.low,
        backgroundColor: SEVERITY_COLORS.low,
      },
      {
        label: 'Medium',
        data: monthlySeverityData.medium,
        backgroundColor: SEVERITY_COLORS.medium,
      },
      {
        label: 'High',
        data: monthlySeverityData.high,
        backgroundColor: SEVERITY_COLORS.high,
      },
      {
        label: 'Critical',
        data: monthlySeverityData.critical,
        backgroundColor: SEVERITY_COLORS.critical,
      },
    ],
  }

  return (
    <Card className="h-100">
      <CardBody>
        <h6 className="big-title">
          Monthly Issues by Severity
        </h6>
        <Bar options={options} data={chartData} />
      </CardBody>
    </Card>
  )
}
