import { db } from 'assets/firebase'
import googleDocs from 'assets/img/googleDocs.png'
import googleSheets from 'assets/img/googleSheets.png'
import googleSlides from 'assets/img/googleSlides.png'
import {
  arraySort, dateTime, strings, tables, telemetry,
} from 'assets/utils'
import CardToPageRedirect from 'components/CardToPageRedirect/CardToPageRedirect.jsx'
import OKRs from 'components/OKRs/OKRs.js'
import PersonCard from 'components/PersonCard/PersonCard.js'
import ProjectCard from 'components/ProjectCard/ProjectCard.jsx'
import SubTeamCard from 'components/TeamCard/TeamCard.js'
import TeamEditor from 'components/TeamEditor/TeamEditor.js'
import React from 'react'
// redux
import { connect } from 'react-redux'
// reactstrap components
import {
  Button,
  Card, CardBody,
  Carousel, CarouselCaption, CarouselControl,
  CarouselItem, Col, Input,
  Modal, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'

class TeamCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      activeIndex: 0,
      animating: false,
      activeTab: '1',
      members: [],
      projects: [],
      filteredProjects: [],
      subTeams: [],
      OKRs: [],
      filteredOKRs: [],
      showOKRs: false,
      isScaffolding: false,
      isLoaded: false,
      team: {
        photos: [],
        links: [],
        children: [],
      },
      okrStatusFilter: 'all',
      okrCycleFilter: '22h1',
      projectStatusFilter: 'active',
    }

    this.cardRef = React.createRef()

    this.getTeam = this.getTeam.bind(this)
    this.getTeamURL = this.getTeamURL.bind(this)

    this.cardClick = this.cardClick.bind(this)
    this.emailEm = this.emailEm.bind(this)

    this.onUpdated = this.onUpdated.bind(this)
    this.slackEm = this.slackEm.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    let team

    if (this.props.nodeData) {
      team = this.props.nodeData
      if (team.isScaffolding) this.setState({ isScaffolding: true })
    } else if (this.props.team) {
      team = this.props.team
    }

    if (team) {
      if (!team.links) team.links = []
      if (!team.photos) team.photos = []

      this.setDefaultImg(team)

      this.setState({ team }, () => {
        if (team.isSelected) {
          this.cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
          this.cardRef.current.style.backgroundColor = 'green'
          this.cardRef.current.style.borderRadius = '6px'
          this.cardRef.current.style.padding = '3px'
          team.isSelected = false
        }

        if (this.props.openByDefault) {
          this.toggleModal()
        }
      })

      if (this.props.showOKRs) {
        this.setState({ showOKRs: true })
        this.getOKRs()
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.nodeData !== this.props.nodeData || prevProps.team !== this.props.team) {
      let team
      if (this.props.nodeData) {team = this.props.nodeData} else {team = this.props.team}

      if (team.isScaffolding) this.setState({ isScaffolding: true })

      this.setDefaultImg(team)
      if (!team.links) team.links = []

      this.setState({ team })
    }
    if (prevProps.showOKRs !== this.props.showOKRs) {
      this.getOKRs()
      this.setState({ showOKRs: this.props.showOKRs })
    }
  }

  toggleModal() {
    if (!this.state.isLoaded) {this.getTeam()}

    this.setState({ modal: !this.state.modal })
  }

  setDefaultImg(team) {
    if (!team.profilePhoto) {team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png`}
    if (team.profilePhoto.indexOf('avatar') > -1) {team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png`}

    if (!team.photos) team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
    else if (team.photos.length === 0) team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
  }

  getTeam() {
    try {
      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'teamCard', 'pageView')

      db.getTeam(this.state.team.id).then((team) => {
        if (team) {
          team.preload = this.state.team.preload
          if (this.state.team.children) team.children = this.state.team.children

          this.setDefaultImg(team)

          if (!team.links) team.links = []
          team.links.sort((a, b) => b.created - a.created)

          if (!team.created) team.created = new Date().getTime()
          if (!team.modified) team.modified = new Date().getTime()

          this.setState({ team, isLoaded: true })


          db.getTeamMembers(team.id).then((teamMembers) => {
            const promises = []
            for (let i = 0; i < teamMembers.length; i++) {promises.push(db.getPerson(teamMembers[i].personID))}

            Promise.all(promises).then((people) => {
              const members = []
              people.forEach((person) => { // necessary to handle missing employees
                if (person) members.push(person)
              })

              members.sort(arraySort.byName)
              this.setState({ members })
            })
          })

          db.getTeamProjects(team.id).then((teamProjects) => {
            const promises = []
            for (let i = 0; i < teamProjects.length; i++) {promises.push(db.getProject(teamProjects[i].projectID))}

            Promise.all(promises).then((results) => {
              const projects = []
              results.forEach((project) => {
                if (project) {projects.push(project)}
              })

              projects.sort(arraySort.byName)
              this.setState({ projects }, this.filterProjects)
            })
          })

          db.getSubTeams(this.state.team.id).then((subTeams) => {
            const promises = []
            for (let i = 0; i < subTeams.length; i++) {promises.push(db.getTeam(subTeams[i].id))}

            Promise.all(promises).then((teams) => {
              teams.sort(arraySort.byName)
              for (let j = 0; j < teams.length; j++) {
                if (!teams[j].children) teams[j].children = []
              }
              this.setState({ subTeams: teams })
            })
          })

          this.getOKRs()
        }
      })
    } catch (exc) {
      console.log('exception:')
      console.log(exc)
    }
  }

  getOKRs() {
    db.getTeamOKRs(this.state.team.id).then((OKRs) => {
      OKRs.sort((a, b) => a.number - b.number)

      const owners = []
      const promises = []
      OKRs.forEach((okr) => {
        okr.KRs.forEach((kr) => {
          if (kr.ownerID) {
            if (kr.ownerID.length > 0) {
              let isFound = false
              for (let o = 0; o < owners.length; o++) {
                if (owners[o] === okr.ownerID) {
                  isFound = true
                  break
                }
              }
              if (!isFound) promises.push(db.getPerson(kr.ownerID))
            }
          }
          if (kr.statusReports) {
            kr.statusReports.forEach((sr) => {
              let isFound = false
              for (let o = 0; o < owners.length; o++) {
                if (owners[o] === sr.personID) {
                  isFound = true
                  break
                }
              }
              if (!isFound) promises.push(db.getPerson(sr.personID))
            })
          }
        })
      })

      Promise.all(promises).then((people) => {
        for (let q = people.length - 1; q > -1; q--) {
          if (!people[q]) people.splice(q, 1)
        }

        OKRs.forEach((okr) => {
          okr.KRs.forEach((kr) => {
            kr.showStatus = false
            if (!kr.statusReports) kr.statusReports = []

            kr.statusReports.forEach((sr) => {
              for (let p = 0; p < people.length; p++) {
                if (sr.personID === people[p].id) {
                  sr.person = people[p]
                  break
                }
              }
            })

            for (let p = 0; p < people.length; p++) {
              if (kr.ownerID === people[p].id) {
                kr.person = people[p]
                break
              }
            }
          })
          okr.statusColor = 'green'
        })

        this.setState({ OKRs, teamOKRs: OKRs }, this.filterOKRs)
      })
    })
  }

  onUpdated(team, members, projects, subTeams, OKRs, newParentID) {
    this.setDefaultImg(team)
    team.links.sort((a, b) => b.created - a.created)

    members.sort(arraySort.byName)
    projects.sort(arraySort.byName)

    this.setState({ OKRs: [] }, () => {
      this.setState({
        team,
        members,
        projects,
        subTeams,
        OKRs,
      }, () => {
        console.log('OKRs', this.state.OKRs)
        this.filterProjects()
        this.filterOKRs()
      })
    })

    if (this.props.onUpdated) {this.props.onUpdated(team)}

    if (newParentID) {
      if (this.props.onParentChanged) this.props.onParentChanged()
    }
  }

  onDeleted() {
    console.log('teamCard::onDeleted', this.state.team)
    if (this.props.onDeleted) {this.props.onDeleted(this.state.team)}

    this.setState({ modal: false })
  }


  filterOKRs(filter, value) {
    console.log('filterOKRs()', `${filter}, ${value}`)

    const filtered = []
    const okrs = this.state.OKRs

    let cycleFilter = this.state.okrCycleFilter
    if (filter === 'cycle') {cycleFilter = value}

    let statusFilter = this.state.okrStatusFilter
    if (filter === 'status') {statusFilter = value}

    this.setState({ okrCycleFilter: cycleFilter, okrStatusFilter: statusFilter })

    for (let i = 0; i < okrs.length; i++) {
      if (okrs[i].cycle === cycleFilter) {
        if (okrs[i].status === statusFilter || statusFilter === 'all') filtered.push(okrs[i])
      }
    }

    this.setState({ filteredOKRs: filtered })
  }

  filterProjects(filter, value) {
    const filtered = []
    const { projects } = this.state

    let statusFilter = this.state.projectStatusFilter
    if (filter === 'status') {statusFilter = value}

    this.setState({ projectStatusFilter: statusFilter })

    for (let i = 0; i < projects.length; i++) {
      if (projects[i].status === statusFilter || statusFilter === 'all') {filtered.push(projects[i])}
    }

    this.setState({ filteredProjects: filtered })
  }

  emailEm() {
    if (this.state.team.email) {window.open(`mailto:${this.state.team.email}`)}
  }

  slackEm() {
    if (this.state.team.slackID) {window.open(`slack://channel?team=T02T4D001&id=${this.state.team.slackID}`)}
  }

  ownerCard(kr) {
    if (kr.person) {
      return (
        <PersonCard person={kr.person} showThumb />
      )
    }
  }

  showSlackButton() {
    if (this.state.team.slackID) return 'inline-block'
    return 'none'
  }

  getTeamLink() {
    return `https://www.gocnnct.com${this.getTeamURL()}`
  }

  getTeamURL() {
    return `/teams/${this.state.team.slug}`
  }

  showCard() {
    if (!this.props.showThumb && !this.props.showLargeThumb && !this.state.isScaffolding && !this.state.showOKRs) return 'block'
    return 'none'
  }

  showThumb() {
    if (this.props.showThumb || this.props.showLargeThumb) return 'inline-block'
    return 'none'
  }

  getThumbSize() {
    if (this.props.showThumb) return '27px'
    return '54px'
  }

  showStub() {
    if (this.state.isScaffolding) return 'block'
    return 'none'
  }

  showGithub() {
    if (this.state.team.github) return 'block'
    return 'none'
  }

  cardClick() {
    if (!this.state.isScaffolding && !this.props.redirectToTeamPage) {
      if (this.props.onSelected) {
        this.props.onSelected(this.state.team)
      } else {this.toggleModal()}
    }
  }

  showAskUs() {
    if (this.state.team.askUs) return 'flex'
    return 'none'
  }

  showAsana() {
    if (this.state.team.asana) return 'flex'
    return 'none'
  }

  showConfluence() {
    if (this.state.team.confluence) return 'flex'
    return 'none'
  }

  showLinks() {
    if (this.state.team.links.length > 0) return 'block'
    return 'none'
  }

  showOrg() {
    this.props.onShowOrg(this.state.team)
    this.setState({ modal: false })
  }

  showOrgChartButton() {
    if (this.props.onShowOrg) return 'inline-block'
    return 'none'
  }

  showEmailButton() {
    if (this.state.team.email) return 'inline-block'
    return 'none'
  }

  showOKRs() {
    if (this.state.showOKRs && !this.state.isScaffolding) return 'block'
    return 'none'
  }

  krStatus(okr, kr) {
    let color = 'gray'
    if (!kr.statusReports) {kr.statusReports = []}

    if (kr.statusReports.length > 0) {color = kr.statusReports[0].status}

    return (
      <div style={{
        height: '24px', width: '24px', borderRadius: '6px', backgroundColor: color,
      }}
      />
    )
  }

  render() {
    const activeLink = {
      color: 'white',
      fontWeight: 600,
      fontSize: '12px',
    }
    const link = {
      color: '#434343',
      fontWeight: 400,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeTab = {
      backgroundColor: '#888888',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: '#888888',
    }
    const tab = {
      backgroundColor: '#dedede',
      marginLeft: '1px',
    }

    const icon = {
      fontSize: '17px',
      marginTop: '3px',
      color: '#898989',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    const title = {
      fontSize: '12px',
      textAlign: 'left',
    }

    const description = {
      fontSize: '8px',
      textAlign: 'left',
      color: '#232323',
      fontWeight: 400,
    }

    const slides = this.state.team.photos.map((item, index) => (
      <CarouselItem
        onExiting={() => this.setState({ animating: true })}
        onExited={() => this.setState({ animating: false })}
        key={index}
      >
        <div style={{
          backgroundSize: 'cover',
          height: '300px',
          width: '100%',
          backgroundColor: '#aaaaaa',
          backgroundImage: `url(${item.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        />
        <CarouselCaption captionText={item.name} captionHeader={item.caption} />
      </CarouselItem>
    ))

    const setActiveIndex = (index) => {
      this.setState({ activeIndex: index })
    }

    const next = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === this.state.team.photos.length - 1 ? 0 : this.state.activeIndex + 1
      setActiveIndex(nextIndex)
    }

    const previous = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === 0 ? this.state.team.photos.length - 1 : this.state.activeIndex - 1
      setActiveIndex(nextIndex)
    }

    return (
      <CardToPageRedirect
        pageSlug={this.getTeamURL}
        redirectFromCardToPage={this.props.redirectToTeamPage}
      >

        <style>
          {
            `.carousel {
                        max-width: 100%;
                        height: 300px;
                        background: black;
                      }
                      .socialIcon {
                        color: #696969;
                      }
                      .socialIcon:hover {
                        color: green;
                      }
                      .navIcon {
                        color: #898989;
                        fontSize:"23px";
                        marginTop:"3px";
                        cursor:"pointer";
                      }
                      .navIcon:hover {
                        color: green;
                      }
                      `
          }
        </style>

        <Card style={{
          display: this.showStub(), padding: '10px', width: '140px', background: 'linear-gradient(to right, #545454 0%, #767676 100%)', textAlign: 'center',
        }}
        >
          <div style={{ color: 'white', fontSize: '12px' }}>{this.state.team.name}</div>
        </Card>

        <div
          style={{
            display: this.showThumb(),
            backgroundSize: 'cover',
            height: this.getThumbSize(),
            width: this.getThumbSize(),
            backgroundColor: '#aaaaaa',
            borderRadius: '6px',
            padding: '0px',
            backgroundImage: `url(${this.state.team.profilePhoto})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            cursor: 'pointer',
          }}
          onClick={this.toggleModal}
          title={this.state.team.name}
        />

        <div
          ref={this.cardRef}
          style={{
            display: this.showCard(), width: '140px', marginTop: '10px', paddingLeft: '3px', paddingRight: '3px', cursor: 'pointer',
          }}
        >
          <Card onClick={this.cardClick}>
            <div style={{
              backgroundSize: 'cover',
              height: '80px',
              width: '100%',
              backgroundColor: '#aaaaaa',
              borderTopRightRadius: '6px',
              borderTopLeftRadius: '6px',
              backgroundImage: `url(${this.state.team.profilePhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderBottom: '1px solid green',
            }}
            />
            <CardBody style={{ minHeight: '143px', padding: '8px' }}>
              <Row>
                <Col md="12">
                  <div style={title}>{this.state.team.name}</div>
                </Col>
              </Row>
              <Row>

                <Col md="12">
                  <div style={description}>{strings.trim(this.state.team.description, 76)}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  &nbsp;
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>

        <div style={{
          display: this.showOKRs(), width: '500px', marginTop: '10px', paddingLeft: '3px', paddingRight: '3px',
        }}
        >
          <Card>
            <div
              style={{
                backgroundSize: 'cover',
                height: '123px',
                width: '100%',
                backgroundColor: '#aaaaaa',
                borderTopRightRadius: '6px',
                borderTopLeftRadius: '6px',
                backgroundImage: `url(${this.state.team.profilePhoto})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderBottom: '1px solid green',
                cursor: 'pointer',
              }}
              onClick={this.cardClick}
            />
            <CardBody style={{ minHeight: '143px', padding: '18px' }}>
              <Row>
                <Col md="12">
                  <div style={{ fontSize: '15px', marginBottom: '6px' }}>{this.state.team.name}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div style={{ fontSize: '10px' }}>{strings.trim(this.state.team.description, 350)}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {this.state.filteredOKRs.map((okr, index) => (
                    <Row key={index}>
                      <Col sm="12">

                        <div style={{
                          fontWeight: 600, fontSize: '11px', marginLeft: '10px', marginTop: '17px', marginBottom: '6px',
                        }}
                        >
                          <span style={{ color: 'green' }}>
                            {okr.number}
                            .
                          </span>
                          {' '}
                          {okr.description}
                        </div>

                        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>

                          {okr.KRs.map((kr, index) => (
                            <Row
                              key={index}
                              style={{
                                padding: '10px', fontSize: '10px', backgroundColor: tables.rowColor(index), border: 'solid 1px #efefef',
                              }}
                            >
                              <Col xs="1" style={{ color: 'green', whiteSpace: 'nowrap' }}>
                                {okr.number}
                                .
                                {kr.number}
                              </Col>
                              <Col xs="9">
                                {kr.description}
                              </Col>
                              <Col xs="1" style={{ marginLeft: '-10px' }}>
                                {this.ownerCard(kr)}
                              </Col>
                              <Col xs="1">
                                {this.krStatus(okr, kr)}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">

          <Carousel
            className="carousel"
            activeIndex={this.state.activeIndex}
            next={next}
            previous={previous}
            ride="carousel"
          >
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>

          <ModalBody style={{
            paddingTop: '34px', paddingLeft: '23px', paddingRight: '23px', backgroundColor: '#efefef',
          }}
          >
            <Row>
              <Col md="11" style={{ fontSize: '21px', marginBottom: '17px' }}>
                {this.state.team.name}
              </Col>

              <Col
                md="1"
                style={{
                  fontSize: '19px', marginBottom: '17px', cursor: 'pointer', display: this.showGithub(),
                }}
              >
                <div onClick={() => window.open(this.state.team.github)}>
                  <i className="socialIcon fab fa-github" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Nav>
                  <NavItem style={(this.state.activeTab === '1' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '1' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '1' })}}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '2' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '2' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '2' })}}
                    >
                      Members
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '3' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '3' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '3' })}}
                    >
                      Projects
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'OKRs' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'OKRs' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: 'OKRs' })}}
                    >
                      OKRs
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '7' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '7' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '7' })}}
                    >
                      Links
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ display: 'none' }}>
                    <NavLink
                      style={(this.state.activeTab === '5' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '5' })}}
                    >
                      Activity
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '6' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '6' ? activeLink : link)}
                      onClick={() => {this.setState({ activeTab: '6' })}}
                    >
                      Subteams
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  style={{
                    borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#888888', padding: '20px',
                  }}
                >
                  <TabPane tabId="1" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-share-66" title="Team URL" />
                      </Col>
                      <Col xs="10">
                        <a href={this.getTeamURL()} target="_new">{this.getTeamLink()}</a>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-briefcase-24" />
                      </Col>
                      <Col xs="10">
                        {this.state.team.description}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showAskUs() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-chat-33" title="ask us about" />
                      </Col>
                      <Col xs="10">
                        {this.state.team.askUs}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showAsana() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-globe" title="Asana Page" />
                      </Col>
                      <Col xs="10">
                        <a href={this.state.team.asana} target="_new" style={{ color: 'green' }}>Asana Page</a>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row style={{ display: this.showConfluence() }}>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-globe" title="Confluence Page" />
                      </Col>
                      <Col xs="10">
                        <a href={this.state.team.confluence} target="_new" style={{ color: 'green' }}>Confluence Page</a>
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-calendar-60" title="Last Modified" />
                      </Col>
                      <Col xs="10" style={{ color: '#898989' }}>
                        Last modified on
                        {' '}
                        {dateTime.timeConverter(this.state.team.modified)}
                        {' '}
                        by
                        {' '}
                        {this.state.team.modifiedBy}
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      {this.state.members.map((person, index) => (
                        <Col xs="4" sm="3" key={index}>
                          <PersonCard person={person} hideExpander />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs="6">
                        <Input
                          placeholder=""
                          type="select"
                          style={{ border: '1px solid #888888', fontSize: '11px' }}
                          value={this.state.projectStatusFilter}
                          onChange={(e) => this.filterProjects('status', e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="active">Active</option>
                          <option value="paused">Paused</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="completed">Completed</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.filteredProjects.map((project, index) => (
                        <Col xs="4" sm="3" key={index}>
                          <ProjectCard project={project} />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="4" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-touch-id" title="Fun Fact" />
                      </Col>
                      <Col xs="10">
                        {this.state.team.funFact}
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <i style={icon} className="nc-icon nc-palette" title="Traditions" />
                      </Col>
                      <Col xs="10">
                        {this.state.team.traditions}
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5" style={{ minHeight: '210px', paddingTop: '34px', fontSize: '12px' }}>
                    <Row>
                      <Col sm="2">
                        <img src={googleSheets} style={{ height: '23px' }} alt="Google Sheets Icon" />
                      </Col>
                      <Col sm="10">
                        Created an interesting useful spreadsheet
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="2">
                        <img src={googleSlides} style={{ height: '23px' }} alt="Google Slides Icon" />
                      </Col>
                      <Col sm="10">
                        Modified an interesting slide deck
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="2">
                        <img src={googleDocs} style={{ height: '23px' }} alt="Google Docs Icon" />
                      </Col>
                      <Col sm="10">
                        Modified a super useful document
                        <br />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <Row>
                      {this.state.subTeams.map((team, index) => (
                        <Col sm="3" key={index}>
                          <SubTeamCard team={team} />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="7" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <div style={{ display: this.showLinks() }}>
                      <Row style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                        <Col xs="1" />
                        <Col xs="6">
                          Name
                        </Col>
                        <Col xs="5" style={{ textAlign: 'right', color: '#898989' }}>
                          Added
                        </Col>
                      </Row>
                      {this.state.team.links.map((link, index) => (
                        <Row key={index}>
                          <Col xs="1">
                            <i style={icon} className="nc-icon nc-globe" title="link" />
                          </Col>
                          <Col xs="8">
                            <a href={link.url} target="_new" style={{ color: '#232323' }}>{link.name}</a>
                            <br />
                          </Col>
                          <Col xs="3" style={{ textAlign: 'right', color: '#898989' }}>
                            {dateTime.dateConverter(link.created)}
                            <br />
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tabId="OKRs" style={{ minHeight: '210px', paddingTop: '34px', fontSize: '12px' }}>
                    <OKRs team={this.state.team} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter style={{ backgroundColor: '#efefef' }}>
            <Row style={{ width: '100%' }}>
              <Col xs={3} style={{ textAlign: 'left' }}>
                <div style={{ display: 'inline-block', marginRight: '6px' }}>
                  <TeamEditor
                    team={this.state.team}
                    members={this.state.members}
                    onDeleted={() => this.onDeleted()}
                    onUpdated={this.onUpdated}
                  />
                </div>
              </Col>
              <Col xs={9} style={{ textAlign: 'right' }}>
                <Button color="secondary" size="sm" style={{ display: this.showOrgChartButton() }} onClick={() => this.showOrg()}>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-vector" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Chart
                  </div>
                </Button>
                <Button color="secondary" size="sm" style={{ display: this.showEmailButton() }} onClick={this.emailEm}>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-email-85" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Email
                  </div>
                </Button>
                <Button color="success" size="sm" onClick={this.slackEm} style={{ display: this.showSlackButton() }}>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-chat-33" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Slack
                  </div>
                </Button>
              </Col>
            </Row>

          </ModalFooter>
        </Modal>

      </CardToPageRedirect>
    )
  }
}

const mapStateToProps = (state) => state

TeamCard = connect(mapStateToProps)(TeamCard)
export default TeamCard
