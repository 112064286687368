
import { combineReducers } from 'redux';
import { 
    USER_LOGGEDIN, USER_LOGGEDOUT, USER_REDIRECTED
} from './actions';


function destination(state = {url: '/orgChart'}, action) {
    switch (action.type) {
        case USER_REDIRECTED:
            return action.destination;
        default:
            return state;
    }
}

function user(state = {authenticated: false}, action) {
    
    switch (action.type) {
        case USER_LOGGEDIN:
            return {
                authenticated: true,
                id: action.user.id,
                email: action.user.email,
                photo: action.user.photo,
                name: action.user.name,
                personID: action.user.personID,
                person: action.user.person,
                isAdmin: action.user.isAdmin
            }

        case USER_LOGGEDOUT:

            return {
                authenticated: false
            }

        

        default:
            return state;
    }
}


const Reducers = combineReducers({ 
    destination,
    user
})

const appState = (Reducers)

export default appState
