
import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserRedirected} from 'redux/actions';

// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import Navbar from "components/Navbars/GoCodeNavbar";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import GoCodeStandards from "views/goCode/GoCodeStandards.js";

import ContentPage from "views/ContentPage.js";
import NavList from "views/admin/NavList.js";
import Icons from "views/components/Icons.js";

import { db } from 'assets/firebase'; 

var ps;

class PortalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
      routes: []
    };
    document.title = 'GoTo Developer Platform';
  }

  componentDidMount() {
  /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
*/
    this.getNavigation();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  bySortIndex(a,b) {

    var x = a.sortIndex;
    var y = b.sortIndex;
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  getNavigation() {

    db.getNavItems('root').then((navItems) => {

      navItems.sort(this.bySortIndex);

      var routes = [];
      var promises = [];

      navItems.forEach(navItem => {

        routes.push( {
          id: navItem.id,
          state: navItem.id,
          layout: "/gdp",
          path:"/todo",
          name: navItem.title,
          icon: "nc-icon " + navItem.icon,
          component: ContentPage,
          root: true
        });

        promises.push(this.getSection(navItem.id));
      });

      routes.push( {
        collapse: true,
        name: 'Admin',
        icon: "nc-icon nc-single-copy-04",
        state: "adminCollapse",
        root: true,
        views: [
          {
            id:'admin001',
            state:'admin001',
            layout:"/gdp",
            path: "/navigation",
            name:"Navigation",
            mini: 'NV',
            component: NavList
          },
          {
            id:'admin002',
            state:'admin002',
            layout:"/gdp",
            path: "/icons",
            name:"Icons",
            mini: 'IC',
            component: Icons
          }
        ]
      });

      Promise.all(promises).then(results => {

        results.forEach(result => {
        
          if(result.length > 0) {

            for(var i = 0; i < routes.length; i++) {
              if(result[0].navItemID === routes[i].id) {

                var nodes = [];
                result.forEach(result => {
                  
                  if(result.page) {

                    if(result.page.id === '78d66cb0-c42f-47f2-869f-db4f210deb13') {

                      nodes.push({
                        id: result.page.id,
                        state: result.page.id,
                        layout: "/gdp",
                        path: '/standards',
                        name: result.page.title,
                        mini: result.page.abbreviation,
                        component: GoCodeStandards
                      });

                    } else {

                      nodes.push({
                        id: result.page.id,
                        state: result.page.id,
                        layout: "/gdp",
                        path: '/content/' + result.page.id,
                        name: result.page.title,
                        mini: result.page.abbreviation,
                        component: ContentPage
                      });
                      
                    }


                  } else if (result.navItem) {

                    var node = {
                      id: result.navItem.id,
                      layout: "/gdp",
                      icon: "nc-icon " + result.navItem.icon,
                      name: result.navItem.title,
                      collapse: true,
                      state: result.navItem.id,
                      views: []
                    }

                    if(result.navItem.pages) {
                      for(var j = 0; j < result.navItem.pages.length; j++) {

                        node.views.push({
                          id: result.navItem.pages[j].id,
                          state: result.navItem.pages[j].id,
                          layout: "/gdp",
                          path: '/content/' + result.navItem.pages[j].id,
                          name: result.navItem.pages[j].title,
                          mini: result.navItem.pages[j].abbreviation,
                          component: ContentPage,
                          indent: true
                        });                        
                      }
                    }

                    nodes.push(node);
                  }
                })

                routes[i].views = nodes;
                routes[i].collapse = true;
              }
            }
          }
          
        });

        console.log(routes)
        this.setState({routes: routes});
      });

    });
  }

  getSection(navItemID) {

    return new Promise((resolve, reject) => {

      var children = [];

      this.getNavItems(navItemID).then((navItems) => {

          this.getPages(navItemID).then((pages) => {

              // get any third level pages
              var promises = [];
              navItems.forEach(navItem => {
                promises.push(this.getPages(navItem.id));
              });

              // bring it all together
              Promise.all(promises).then(results => {

                navItems.sort(this.bySortIndex);

                navItems.forEach(navItem => {

                  // assign the right pages to the right nav items
                  for(var i = 0; i < results.length; i++) {

                    if(results[i].length > 0) {
                      if(results[i][0].navItemID === navItem.id) {
                  
                        navItem.pages = results[i].sort(this.bySortIndex);
                  
                        break;
                      }
                    }
                  }

                  children.push({ navItemID: navItemID, navItem: navItem, sortIndex: navItem.sortIndex } );
                });

                pages.forEach(page => {
                  children.push({ navItemID: navItemID, page: page, sortIndex: page.sortIndex } );
                })


                children.sort(this.bySortIndex);

                resolve(children);

              });
          });
      });
    });
  }

  getNavItems(navItemID) {

    return new Promise((resolve, reject) => {

        db.getNavItems(navItemID).then((results) => {
    
            for(var i = 0; i < results.length; i++) {
                if(!results[i].pages)
                  results[i].pages = [];
                if(!results[i].sortIndex)
                  results[i].sortIndex = i;
            }
        
            results.sort(this.bySortIndex)
            resolve(results);
        });
    });
  }

  getPages(navItemID) {
    
    return new Promise((resolve, reject) => {

        db.getPages(navItemID).then(pages => {
            
          resolve(pages);
        });
    });
  }

  getRoutes = (routes) => {

    if(!routes) routes = this.state.routes;

    return routes.map((prop, key) => {
      
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      
      if (prop.layout === "/gdp") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    return (
      <div className="wrapper" >
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick} />
        <Sidebar
            {...this.props}
            routes={this.state.routes}
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
          />
        <div className="main-panel" ref="mainPanel">
          <Switch>{this.getRoutes()}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
        {/*
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
        />
        */}
      </div>
    );
  }
}


const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      onUserRedirected: UserRedirected
       }, dispatch);  
}

PortalLayout = connect(mapStateToProps, mapDispatchToProps)(PortalLayout);
export default PortalLayout;
