import React from "react";

import { connect } from 'react-redux';

import { db, func } from 'assets/firebase';

import { arraySort, tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Card, CardHeader, CardBody, CardTitle,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Row, Col,
    Table
  } from "reactstrap";

import { HorizontalBar } from "react-chartjs-2";

import PersonCard from "components/PersonCard/PersonCard.js";
import logo from "assets/img/goConnectIcon.png";

class Manager extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          surveyResults: [
            { 
                labels: ["Q1"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q2"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q3"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q4"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q5"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q6"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q7"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q8"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q9"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q10"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q11"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q12"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
            { 
                labels: ["Q13"], 
                data: [
                    { label: 'high', data: [0], stack: 'q1', backgroundColor:'rgba(0, 255, 0, 0.4)', borderColor: 'rgba(0, 255, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'medium', data: [0], stack: 'q1', backgroundColor:'rgba(255, 215, 0, 0.4)', borderColor: 'rgba(255, 215, 0)', borderWidth:2, maxBarThickness: 23 },
                    { label: 'low', data: [0], stack: 'q1', backgroundColor: 'rgba(255, 0, 0, 0.4)', borderColor: 'red', borderWidth:2, maxBarThickness: 23 },
                ] 
            },
          ],
          responseCount: 0,
          isLoading: true,
          alertVisible: false,
          defaultQuarter: '2021q2',
          aggregation: 'directReports',
          keepDoing: [],
          changeDoing: [],
          managerID: this.props.user.personID, //'26565b9a-fa7a-4f10-aee6-2d9edc7929a5',// //
          manager: {},
          org: [],
          heatMaps: [],
          orgSize: 0
      }

      if(this.props.managerID)
        this.state.managerID = this.props.managerID;
    }

    componentDidMount() {

        db.getPerson(this.state.managerID).then(manager => {

            this.setState({manager: manager}, () => {
                this.getManagerFeedback("managerID", this.state.managerID); 
            });

            var orgSize = 0;
            this.getOrg(manager.id, manager.name).then((org) => { 

                // only managers with at least 5 direct reports
                for(var i = org.length - 1; i > -1; i--) {
                    console.log(org[i])

                    orgSize = orgSize + org[i].directReports;

                    if(org[i].directReports < 5)
                        org.splice(i,1);
                }
    
                org.sort(arraySort.byName);
    
                this.setState({org: org, orgSize: orgSize});
            })
        })
        
        telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "managerQLS", "pageView").then(activity => {
            setInterval(() => telemetry.trackDwellTime(activity),60000);
        });
    }

    getManagerFeedback(property, value) {

        var managerID = this.state.managerID;
        if(property === 'managerID')
            managerID = value;

        var aggregation = this.state.aggregation;
        if(property === 'aggregation')
            aggregation = value; 

        
        this.setState({managerID: managerID, aggregation: aggregation, responseCount:0, heatMaps:[], isLoading: true});

        this.getSurveyResults(managerID, aggregation, '2021q2').then(results => {
            var surveyResults = this.state.surveyResults;

            for(var i = 0; i < 13; i++) {
                var percentages = this.calculatePercentages(results, i+1);

                surveyResults[i].data[2].data = [percentages.negative];
                surveyResults[i].data[1].data = [percentages.neutral];
                surveyResults[i].data[0].data = [percentages.positive];
            }

            var keepDoing = [];
            var changeDoing = [];

            results.forEach(result => {
                if(result.keepDoing)
                    if(result.keepDoing.length > 0)
                        keepDoing.push(result.keepDoing);

                if(result.changeDoing)
                    if(result.changeDoing.length > 0)
                        changeDoing.push(result.changeDoing);
            })

            this.setState({
                surveyResults: surveyResults, 
                keepDoing: keepDoing,
                changeDoing: changeDoing,
                isLoading: false
            });
        });
    }

    getSurveyResults(managerID, aggregation, quarter) {

        return new Promise((resolve, reject) => {
            func.getQLSResults(managerID, this.state.manager.name, aggregation, quarter).then(result => {
                console.log(result)
                if(result.data) {
                    this.setState({responseCount: result.responseCount});
                    resolve(result.data);
                }
                else {
                    resolve([]);
                } 
            });

            func.getQLSResultHeatMaps(managerID, this.state.manager.name, aggregation, quarter).then(result => {
                this.setState({heatMaps: result.heatMaps});
            });
        });
    }

    calculatePercentages(results, index) {

        var count = 0;
        var positive = 0;
        var neutral = 0;
        var negative = 0;
        var property = 'q' + index;

        results.forEach(result => {

            var r = Number(result[property]);

            if(r > 1)
                count = count + 1;

            // note: we don't count n/a (1) results

            if(r === 2 || r === 3) 
                negative = negative + 1;
            else if(r === 4)  
                neutral = neutral + 1;
            else if(r === 5 || r === 6) 
                positive = positive + 1;
        })

        negative = Math.floor(negative / count * 100);
        neutral = Math.floor(neutral / count * 100);
        positive = Math.floor([positive] / count * 100);

        return {
            negative: negative,
            neutral: neutral,
            positive: positive
        }
    }

    getOrg(managerID, name) {
        
        return new Promise((resolve, reject) => {

            db.getPeopleByManagerID(managerID).then(directReports => {

                var promises = [];

                if(directReports.length > 0) {
   
                    var org = [];
                    if(directReports.length > 0)
                        org.push({id: managerID, name: name, directReports: directReports.length});

                    directReports.forEach(report => {
                        promises.push(this.getOrg(report.id, report.name));
                    });
                }

                Promise.all(promises).then((results) => {

                    results.forEach(result => {
                        if(result)
                            result.forEach(row =>
                                org.push(row));
                            
                    });                  

                    resolve(org);
                });
            });
        });
    }

    getNumberOfDirectReports() {
        for(var i = 0; i < this.state.org.length; i++) {
            if(this.state.org[i].id === this.state.managerID)
                return this.state.org[i].directReports;
        }
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    showOrg() {
        if(this.state.orgSize > 0)
            return "block";
        else
            return "none";
    }
    showManagers() {
        if(this.state.org.length > 0)
            return "block";
        else
            return "none";
    }

    showResults() {
        if(this.state.responseCount > 4 && !this.state.isLoading) 
            return "block";
        else 
            return "none";
    }

    showHeatMaps() {
        if(this.state.heatMaps.length > 0 && !this.state.isLoading) 
            return "block";
        else    
            return "none";
    }


    showInsufficientResults() {
        if(this.state.responseCount < 5 && !this.state.isLoading) 
            return "block";
        else
            return "none";
    }

    employeeCard() {
        
        return (
            <PersonCard person={this.state.manager} showThumb />
        )
    }


    cellColor(percentage) {
        if(percentage > 79)
            return 'rgba(0, 255, 0, 0.4)';
        else if (percentage > 69)
            return 'rgba(255, 215, 0, 0.4)';
        else 
            return 'rgba(255, 0, 0, 0.4)';
    }

    openManagerPage(id) {
        window.open('/manager/' + id);
    }
    
    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#666666"
        }
        
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minHeight:"100px"
        }

        const options = {
              legend: { display: false },
              tooltips: { enabled: false, intersect: false },
              scales: {
                yAxes: [{ stacked: true, beginAtZero: true, min: 0, max: 100 }],
                xAxes: [{ stacked: true, beginAtZero: true, min: 0, max: 100 }],
              },
              responsive: true,
              maintainAspectRatio: false
        }
        
        return (
            <div style={{padding:"10px"}}>
               
               <style>
                {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
                </style>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs="12" sm="8">
                                <CardTitle tag="h4">Quarterly Leadership Survey Results</CardTitle>
                            </Col>
                            <Col xs="12" sm="4" style={{textAlign:"right", paddingTop:"17px"}}>
                                <div style={{display:"inline-block", marginRight:"10px", fontSize:"12px"}}><b>People Manager</b><br />{this.state.manager.name}</div>
                                <div style={{display:"inline-block"}}>{this.employeeCard()}</div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody style={{padding:"20px", minHeight:"200px"}}>
                
                        <Row>
                            <Col xs="6" sm="3" >
                                Quarter <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                            <i style={icon} className="nc-icon nc-calendar-60" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" 
                                        type="select" 
                                        value={this.state.selectedQuarter} onChange={(e) => this.getManagerFeedback("quarter", e.target.value)} >
                                            <option value="2021q2">2021 Q2</option>
                                    </Input>
                                </InputGroup>
                                <br />
                            </Col>
                            <Col xs="6" sm="3" style={{display:this.showManagers()}} >
                                <div >
                                    Manager <br />
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                <i style={icon} className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="" 
                                            type="select" 
                                            value={this.state.managerID} 
                                            onChange={(e) => this.getManagerFeedback("managerID", e.target.value)} >
                                                {this.state.org.map((person, index) => (
                                                    <option value={person.id} key={index}>{person.name}</option>         
                                                ))}
                                        </Input>
                                    </InputGroup>
                                    <br />
                                </div>
                            </Col>
                            <Col xs="6" sm="3" >
                                <div style={{display:this.showOrg()}}>
                                    Results <br />
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                <i style={icon} className="nc-icon nc-chart-bar-32" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="" 
                                            type="select" 
                                            value={this.state.aggregation} 
                                            onChange={(e) => this.getManagerFeedback("aggregation", e.target.value)} >
                                                <option value='directReports' >Direct Reports</option>
                                                <option value='organization' >Organization</option>
                                        </Input>
                                    </InputGroup>
                                    <br />
                                </div>
                            </Col>
                            <Col xs="6" sm="3" style={{marginTop:"20px"}}>
                                <div className="pull-right" style={{display:"inline-block", fontSize:"12px", marginTop:"16px", marginLeft:"6px"}}>
                                    responses
                                </div>
                                <div className="numbers pull-right" style={{display:"inline-block"}}>{this.state.responseCount} <span style={{fontSize:"13px", color:"#666"}}></span></div> 
                             </Col> 
                        </Row>
                    
                        <Row>
                            <Col xs="12">
                                <div style={loadingDiv}>
                                    <div className="spin">
                                        <img src={logo} alt="logo" style={{height:"43px", width:"43px"}} />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div style={{display:this.showInsufficientResults()}}>
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", paddingBottom:"23px", fontSize:"15px", fontWeight:500}}>
                                    You need at least five employees to have responded to the Quarterly 
                                    Leadership Survey before you'll be able to see your results.
                                </Col>
                            </Row>
                        </div>

                        <div style={{display:this.showHeatMaps(), paddingBottom:"20px"}}>

                            <Row>
                                <Col xs="8" sm="6">
                                    <div style={{fontSize:"19px", fontWeight:400, paddingBottom:"10px", paddingTop:"20px", color:"green"}}>Top Box Scores</div>
                                </Col>
                                <Col xs="4" sm="6" style={{textAlign:"right"}}>
                                    <div style={{fontSize:"11px", color:"#555", padding:"10px", paddingBottom:"20px"}}>
                                        <span style={{fontWeight:600}}>Green</span> indicates 80%+ positive response.
                                        <br />
                                        <span style={{fontWeight:600}}>Yellow</span> indicates 70-79% positive response.
                                        <br />
                                        <span style={{fontWeight:600}}>Red</span> indicates less than 70% positive response.
                                    </div>
                                </Col>
                            </Row>

                            <table style={{width:"100%", borderTopLeftRadius:"6px", borderTopRightRadius:"6px"}}>
                                <thead style={{textAlign:"center", color:"#ffffff", backgroundColor:"#898989", padding:'6px' }}>
                                    <tr >
                                        <td style={{textAlign:"left", paddingLeft:"6px"}}> Manager</td>
                                        <td title="My Manager assigns stretch opportunities to help me develop my career.">Q1</td>
                                        <td title="My Manager communicates clear goals for the team.  ">Q2</td>
                                        <td title="My Manager gives me actionable feedback at least once a month.">Q3</td>
                                        <td title="My manager provides the autonomy I need to do my job (i.e., does not 'micromanage' by getting involved in details that should be handled at other levels).">Q4</td>
                                        <td title="My manager consistently shows consideration for me as a person.">Q5</td>
                                        <td title="My manager keeps the team focused on priorities, even when it’s difficult (e.g. declining or deprioritizing other projects).">Q6</td>
                                        <td title="My manager regularly shares relevant information from their manager and senior leadership.">Q7</td>
                                        <td title="My manager has had a meaningful discussion with me about my career development in the past three months.">Q8</td>
                                        <td title="My manager has the technical expertise (e.g., technical judgment in Tech, selling in Sales, accounting in Finance) required to effectively manage me.">Q9</td>
                                        <td title="The actions of my manager show that he/she values the perspective I bring to the team, even if it is different from his/her own.">Q10</td>
                                        <td title="My manager makes tough decisions effectively (e.g., decisions involving multiple teams, competing priorities).">Q11</td>
                                        <td title="My manager effectively collaborates across boundaries (e.g., team, organizational).">Q12</td>
                                        <td title="I would recommend my manager to others.">Q13</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.heatMaps.map((heatMap, index) => (
                                    <tr key={index} style={{color:"#898989", fontWeight:600, padding: "1px", margin:'1px', textAlign:"center"}}>
                                        <td style={{color:"#232323", textAlign:"left", fontWeight:400, paddingLeft:"6px", cursor:'pointer'}}><div onClick={()=>this.openManagerPage(heatMap.managerID)}> {heatMap.managerName}</div></td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q1),padding:'4px'}}>{heatMap.q1}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q2),padding:'4px'}}>{heatMap.q2}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q3),padding:'4px'}}>{heatMap.q3}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q4),padding:'4px'}}>{heatMap.q4}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q5),padding:'4px'}}>{heatMap.q5}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q6),padding:'4px'}}>{heatMap.q6}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q7),padding:'4px'}}>{heatMap.q7}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q8),padding:'4px'}}>{heatMap.q8}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q9),padding:'4px'}}>{heatMap.q9}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q10),padding:'4px'}}>{heatMap.q10}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q11),padding:'4px'}}>{heatMap.q11}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q12),padding:'4px'}}>{heatMap.q12}</td>
                                        <td style={{backgroundColor: this.cellColor(heatMap.q13),padding:'4px'}}>{heatMap.q13}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div style={{display:this.showResults(), paddingTop:"20px", paddingBottom:"20px"}}>

                            <Row>
                                <Col xs="8" sm="6">
                                    <div style={{fontSize:"19px", fontWeight:400, paddingBottom:"10px", paddingTop:"20px", color:"green"}}>Detailed Results</div>
                                </Col>
                                <Col xs="4" sm="6" style={{textAlign:"right"}}>
                                    <div style={{fontSize:"11px", color:"#555", padding:"10px", paddingBottom:"20px"}}>
                                        <span style={{fontWeight:600}}>Green</span> indicates agreement or strong agreement.
                                        <br />
                                        <span style={{fontWeight:600}}>Yellow</span> indicates a neutral response.
                                        <br />
                                        <span style={{fontWeight:600}}>Red</span> indicates disagreement or strong disagreement.
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My Manager assigns stretch opportunities to help me develop my career.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[0].labels, 
                                            datasets: this.state.surveyResults[0].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>
                         
                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My Manager communicates clear goals for the team.  
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[1].labels, 
                                            datasets: this.state.surveyResults[1].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                My Manager gives me actionable feedback at least once a month.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[2].labels, 
                                            datasets: this.state.surveyResults[2].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager provides the autonomy I need to do my job (i.e., does not "micromanage" by getting involved in details that should be handled at other levels).
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[3].labels, 
                                            datasets: this.state.surveyResults[3].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager consistently shows consideration for me as a person.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[4].labels, 
                                            datasets: this.state.surveyResults[4].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager keeps the team focused on priorities, even when it’s difficult (e.g. declining or deprioritizing other projects).
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[5].labels, 
                                            datasets: this.state.surveyResults[5].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager regularly shares relevant information from their manager and senior leadership.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[6].labels, 
                                            datasets: this.state.surveyResults[6].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager has had a meaningful discussion with me about my career development in the past three months.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[7].labels, 
                                            datasets: this.state.surveyResults[7].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager has the technical expertise (e.g., technical judgment in Tech, selling in Sales, accounting in Finance) required to effectively manage me.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[8].labels, 
                                            datasets: this.state.surveyResults[8].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    The actions of my manager show that he/she values the perspective I bring to the team, even if it is different from his/her own.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[9].labels, 
                                            datasets: this.state.surveyResults[9].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager makes tough decisions effectively (e.g., decisions involving multiple teams, competing priorities).
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[10].labels, 
                                            datasets: this.state.surveyResults[10].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    My manager effectively collaborates across boundaries (e.g., team, organizational).
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[11].labels, 
                                            datasets: this.state.surveyResults[11].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    I would recommend my manager to others.
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{height:"88px", width:"100%"}}>
                                    <HorizontalBar
                                        data={{
                                            labels: this.state.surveyResults[12].labels, 
                                            datasets: this.state.surveyResults[12].data
                                        }}
                                        options={options}
                                    />
                                </Col>
                            </Row>


                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    What would you recommend your manager keep doing?
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"10px", fontSize:"12px", fontWeight:500}}>
                                    <Table>
                                        <tbody>
                                            {this.state.keepDoing.map((feedback, index) => (
                                                <tr key={index} style={{backgroundColor:tables.rowColor(index)}}>
                                                <td>
                                                    {feedback}
                                                </td>
                                                </tr> 
                                            ))}
                                    </tbody>
                                </Table>
                                </Col>
                            </Row>

                            <br />
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"23px", fontSize:"14px", fontWeight:500}}>
                                    What would you recommend your manager change?
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{paddingLeft:"50px", paddingRight:"50px", paddingTop:"10px", fontSize:"12px", fontWeight:500}}>
                                    <Table>
                                        <tbody>
                                            {this.state.changeDoing.map((feedback, index) => (
                                                <tr key={index} style={{backgroundColor:tables.rowColor(index)}}>
                                                <td>
                                                    {feedback}
                                                </td>
                                                </tr> 
                                            ))}
                                    </tbody>
                                </Table>
                                </Col>
                            </Row>
                            
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Manager = connect(mapStateToProps)(Manager);
export default Manager;