
import { db } from 'assets/firebase';


export const logUserActivity = (userID, personID, pageID, activityType, resourceID) => {

  return new Promise(function(resolve, reject) {

    if(userID) {

      var userActivity = {
          userID: userID,
          personID: personID,
          pageID: pageID,
          activityType: activityType,
          created: new Date().getTime(),
          dwellTime: new Date().getTime(),
          timeStamp: new Date()
      }

      if(resourceID)
        userActivity.resourceID = resourceID;

      db.updateUserActivity(userActivity.id, userActivity).then((result) => {
        userActivity.id = result.id;
        resolve(userActivity);
      });
    }
  });
}

export const trackDwellTime = (userActivity) => {
  userActivity.dwellTime = new Date().getTime();
  db.updateUserActivity(userActivity.id, userActivity);
}

 