
import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Parser from 'html-react-parser';
import CardHeader from "reactstrap/lib/CardHeader";

class ConfluencePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      content: '',
      wiki: '',
      link: ''
    }

    this.getContent = this.getContent.bind(this);
  }

  componentDidMount() {
    
    this.getContent();
  }

  getContent() {

    /*
    // commented out until the service can be secured
    var uid = '';
    var pwd = '';

    //var contentID = 1730548985;//1655702266; //this.props.id;


    const params = {
      uid: uid,
      pwd: pwd,
      id: this.props.id,
    };

    fetch(
      'https://us-central1-goconnect.cloudfunctions.net/getConfluencePage',
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    )
      .then((response) => {
        return response.json();
        
      }).then((json) => {
        
        var body = json.body.view.value;
        body = body.replaceAll('href', 'target="_blank" href');
        
        var wiki = json.container.name;

        var link = json._links.base + json._links.webui;

        this.setState({title: json.title, content: body, wiki: wiki, link: link })
        this.props.onLoaded(this.props.id, json.title);
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error (2):  ' + error);
      });
    */
  }


  render() {

    

    return (
      <div className="content">
        <Card>
          <CardHeader style={{backgroundColor:"#545454"}}>
            <div style={{paddingLeft:"20px", paddingRight:"20px", paddingTop:"20px", color:"white"}}>
            <h3>{this.state.title}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12}>
                <div style={{padding:"20px",fontSize:"10px", color:"#232323", display:"inline-block"}}>{this.state.wiki}</div>
                <div style={{padding:"20px",fontSize:"10px", color:"#898989", display:"inline-block", cursor:"pointer"}} onClick={() => window.open(this.state.link)}>{this.props.id}</div>
                <div style={{padding:"20px",fontSize:"10px", color:"maroon", display:"inline-block", cursor:"pointer"}} onClick={() => this.props.onRemove(this.props.id)}>Remove</div>
              </Col>
            </Row>
            <Row>
              <Col md={12} >
                <div style={{padding:"20px", overflow:'scroll'}}>
                {Parser(this.state.content)}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ConfluencePage;
