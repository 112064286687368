import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'

import { dateTime, tables } from '../../../assets/utils'
import LinkEditor from '../../../components/TeamEditor/LinkEditor'

function LinkViewer({ links, toggleEditMode }) {
  return (
    <>
      {links && links.map((link, index) => (
        <Row
          key={index}
          style={{
            backgroundColor: tables.rowColor(index),
            fontSize: '13px',
            padding: '6px',
          }}
        >
          <Col>
            <a href={link.url} target="_new" style={{ color: '#232323' }}>{link.name}</a>
          </Col>
          <Col xs="3" style={{ textAlign: 'right' }}>
            {dateTime.dateConverter(link.created)}
            <br />
          </Col>
        </Row>
      ))}

      <Row style={{ width: '100%', margin: '15px 0 0 0' }}>
        <Col sm={7} style={{ paddingLeft: '0' }}>
          <Button onClick={() => toggleEditMode()}>Edit Links</Button>
        </Col>
      </Row>
    </>
  )
}

function LinkTab({
  user, initialLinks = [], team, updateInitialLinks, updateTeam,
}) {
  const [links, setLinks] = useState([])
  const [isLinkPreviewMode, setIsLinkPreviewMode] = useState(true)

  const resetLinks = () => {
    const newTeamObj = team
    newTeamObj.links = _.cloneDeep(initialLinks)
    updateTeam(newTeamObj)
  }

  const toggleEditMode = () => {
    resetLinks()
    setIsLinkPreviewMode(!isLinkPreviewMode)
  }

  useEffect(() => {
    setLinks(team.links)
  }, [team.links])

  return (
    <div style={{ padding: '30px', backgroundColor: 'white', minHeight: '89px' }}>
      {isLinkPreviewMode ?
        <LinkViewer links={links} toggleEditMode={toggleEditMode} /> :
        (
          <LinkEditor
            links={links}
            team={team}
            user={user}
            setLinks={setLinks}
            updateTeam={updateTeam}
            updateInitialLinks={updateInitialLinks}
            toggleEditMode={toggleEditMode}
          />
        )}
    </div>
  )
}
export default LinkTab
