import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import TextEditor from "components/TextEditor/TextEditor.js";


const uuid = require('uuid');

class StatusEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            statusReport: {
                title: '',
                details: '',
                status: 'onTrack',
                published: new Date().toISOString().substr(0,10),
                links: [],
                projectID: this.props.projectID
            },
            author: {}
        }

        if(props.statusReport)
            this.state.statusReport = props.statusReport;
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            this.getStatusReport();
            this.getAuthor();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "statusEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getStatusReport() {

        db.getStatusReport(this.props.statusReport.id).then(statusReport => {
            
            this.setState({statusReport: statusReport});
        });
    }

    getAuthor() {
        if(this.props.statusReport.authorID) {   
            db.getPerson(this.props.statusReport.authorID).then((author => {
                if(author)
                    this.setState({author: author});
            }));
        }
    }
   
    updateStatusReport(property, value) {
        var obj = this.state.statusReport;
        obj[property] = value;
        this.setState({statusReport: obj});
    }

    addLink() {
        var obj = this.state.statusReport;
        obj.links.splice(0,0,{id: uuid.v4(), name:"", url:"", created: new Date().getTime() });
        this.setState({statusReport: obj});
    }

    updateLink(link, property, value) {
        var obj = this.state.statusReport;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({statusReport: obj});
    }

    saveStatusReport() {

        var statusReport = this.state.statusReport;

        if(!statusReport.created) { 
            statusReport.created = new Date().getTime(); 
            statusReport.createdBy = this.props.user.person.name;
            statusReport.authorID = this.props.user.person.id;
        }

        statusReport.modified = new Date().getTime(); 
        statusReport.modifiedBy = this.props.user.person.name;

        db.updateStatusReport(statusReport.id, statusReport).then((result) => {

            if(this.props.onCreated) {
                statusReport.id = result.id;
                this.props.onCreated(statusReport);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "statusReport", "createStatusReport");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "statusReport", "updateStatusReport");
            }

            if(this.props.onCreated) { // reset for new report

                this.setState({ 
                    statusReport: {
                        title: '',
                        
                        status: 'onTrack',
                        links: [],
                        published: new Date().toISOString().substr(0,10)
                    },
                    author: {}
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(statusReport);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    authorCard() {
        if(this.state.author.id) {
            return (
                <PersonCard person={this.state.author} showThumb />
            )
        }
    }

    buttonText() {
        if(this.props.onCreated)
            return "New Status Report";
        else
            return "Edit";
    }


    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "block";
        else
          return "none";
    }

    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              
                <Button size="sm" onClick={() => this.toggleModal()} >
                    {this.buttonText()}
                </Button>
        
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Status Report
                            </Col>
                        </Row>   
                        <Row>
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'links' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'links'}) }}
                                    >
                                        References
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.statusReport.title} onChange={(e) => this.updateStatusReport("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" >
                                        Project Status <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.statusReport.status} 
                                            onChange={(e) => this.updateStatusReport("status", e.target.value)} >
                                                <option value="gray">Proposed</option>
                                                <option value="green">Active</option>
                                                <option value="orange">At Risk</option>
                                                <option value="red">Blocked</option>
                                                <option value="black">Completed</option>
                                                <option value="maroon">Cancelled</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12" sm="6" >
                                        Date <br />  
                                        <Input
                                            type="date"
                                            placeholder=""
                                            value={this.state.statusReport.published}
                                            onChange={(e) => this.updateStatusReport("published", e.target.value)}
                                            style={{height:"42px"}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Details <br />
                                        <TextEditor 
                                            content={this.state.statusReport.details} 
                                            onChange={(e) => this.updateStatusReport("details", e)} 
                                        />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                        <Col sm="6">
                                            <b>Name</b>
                                        </Col>
                                        <Col sm="6">
                                            <b>URL</b>
                                        </Col>
                                </Row>
                                {this.state.statusReport.links.map((link, index) => (    
                                    <Row key={index} style={{marginBottom:"10px"}}>
                                        <Col sm="6"  >
                                            <input type="text" value={link.name} placeholder="name" 
                                                onChange={(e) => this.updateLink(link, "name", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="6"  >
                                            <input type="text" value={link.url} placeholder="url" 
                                                onChange={(e) => this.updateLink(link, "url", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                    </Row>
                                ))}
                            </TabPane>                   
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6" >
                                <div style={{display:this.showNewLinkButton()}}>
                                    <Button onClick={() => this.addLink()}>Add Link</Button>
                                </div>
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveStatusReport()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    StatusEditor = connect(mapStateToProps)(StatusEditor);
    export default StatusEditor;
    