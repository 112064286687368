import axios from 'axios'

const BASE_URL = process.env.REACT_APP_KAHALA_SERVER_URL

const getRequestHeaders = () => {
  const token = localStorage.getItem('bearer-token')
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  }
}

const clearLocalStorage = () => {
  localStorage.removeItem('bearer-token')
  localStorage.removeItem('id-token')
  localStorage.removeItem('state')
}

const handleLoginRedirect = () => {
  clearLocalStorage()
  window.location.href = '/login'
}


export const makeRequest = async (path, method, headers, body) => {
  const url = `${BASE_URL}${path}`
  const response = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  })

  if (!response.ok) {
    if (response.status === 401) {
      handleLoginRedirect()
    }
    throw new Error(`Request failed with status ${response.status}`)
  }

  const data = await response.json()
  return data
}

export const apiRequest = async (path, method, body, callingPath, customBaseUrl = BASE_URL, headers = {}, signal) => {
  const url = `${customBaseUrl}${path}`
  const token = localStorage.getItem('bearer-token')

  if (!token) {
    localStorage.setItem('logoutLocation', callingPath)
    handleLoginRedirect()
    return
  }

  const mergedHeaders = { ...getRequestHeaders(), ...headers }
  try {
    const response = await axios({
      method,
      url,
      headers: mergedHeaders,
      data: body,
      signal
    })

    return response.data
  } catch (error) {
    if (error?.response && error?.response?.status === 401) {
      localStorage.setItem('logoutLocation', callingPath)
      handleLoginRedirect()
    }
    if(axios.isCancel(error)){
      console.log('Request canceled');
    } else {
      throw new Error(`Request failed with status ${error?.response?.data?.data?.error || error?.response?.status}`)
    }
  }
}
