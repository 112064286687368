import React from 'react'
import {
  Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap'

import RiskEditor from '../../RiskEditor/RiskEditor'
import { getHTML } from '../../utils'
import ProjectAuthorCard from '../ProjectAuthorCard'
import ProjectCardLinks from '../ProjectCardLink'

function RiskRow({ risk, index, onRiskUpdated }) {
  const getExposureDescriptor = (value) => {
    switch (value) {
      case '1':
        return <span style={{ color: 'gray' }}>Low</span>
      case '2':
        return <span style={{ color: 'black' }}>Medium</span>
      case '3':
        return <span style={{ color: 'maroon' }}>High</span>
      default:
    }
  }

  const {
    author, created, createdBy, links,
  } = risk

  return (
    <Row key={index}>
      <Col sm="12">
        <Card>
          <CardHeader style={{
            height: '5px',
            padding: '5px',
            backgroundColor: 'maroon',
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
          }}
          />
          <CardBody style={{ padding: '20px', fontSize: '13px', fontWeight: 500 }}>
            <div style={{ fontWeight: 600, display: 'inline-block', verticalAlign: 'middle' }}>
              {risk.title}
            </div>
            <div className="ql-editor" style={{ marginTop: '8px', marginBottom: '0px' }}>
              {getHTML(risk.details)}
            </div>

            <div style={{
              fontSize: '12px',
              fontWeight: 400,
              marginTop: '10px',
              marginBottom: '21px',
              color: '#434343',
              padding: '15px',
            }}
            >
              {'Probability: '}
              {getExposureDescriptor(risk.probability)}
              <br />
              {'Severity: '}
              {getExposureDescriptor(risk.severity)}
            </div>

            <div className="ql-editor" style={{ marginTop: '10px', marginBottom: '0px' }}>
              <b>Mitigation Plan</b>
              <br />
              {getHTML(risk.mitigation)}
            </div>
            <div className="ql-editor" style={{ marginTop: '10px' }}>
              <b>Contingency Plan</b>
              <br />
              {getHTML(risk.contingency)}
            </div>

            <ProjectCardLinks links={links} />

            <ProjectAuthorCard
              author={author}
              createdBy={createdBy}
              publishedAt={created}
            />

            <RiskEditor risk={risk} onUpdated={(currentRisk) => onRiskUpdated(currentRisk)} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

function RisksTab({
  projectID, isLoading,
  risks, onRiskCreated, onRiskUpdated,
}) {
  return projectID && (
    <>
      <Row>
        <Col xs="12" style={{ textAlign: 'right' }}>
          <RiskEditor projectID={projectID} onCreated={(risk) => onRiskCreated(risk)} />
        </Col>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Col xs="12">
          <div style={{ minHeight: '89px' }}>
            {!isLoading && (
              <div>
                {risks.map((risk, index) => (
                  <RiskRow
                    risk={risk}
                    index={index}
                    onRiskUpdated={onRiskUpdated}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}
export default RisksTab
