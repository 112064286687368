import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import { telemetry } from '../assets/utils'
import Navbar from '../components/Navbars/NexusCollaborationNavbar'
import NexusCollaboration from '../views/NexusCollaboration/NexusCollaboration'

export default function NexusCollaborationLayout(props) {
  useEffect(() => {
    telemetry
        .logUserActivity(
            props.user.id,
            props.user.personID,
            'nexusCollaboration',
            'pageView',
        )
        .then((activity) => {
          setInterval(() => telemetry.trackDwellTime(activity), 60000)
        })
  })

  if (!props.user.authenticated) {
    props.onUserRedirected('/nexus')
    return <Redirect to="/login" />
  }

  return (
    <div className="wrapper" style={{ backgroundColor: '#efefef' }}>
      <Navbar user={props.user} otherPresent={[]} totalOthers={0} />
      <div style={{ marginTop: '65px', padding: '37px' }}>
        <NexusCollaboration user={props.user} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-func-assign
NexusCollaborationLayout = connect(mapStateToProps)(NexusCollaborationLayout)
