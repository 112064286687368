import React from "react"
import { Table } from "reactstrap"

export default function CareSLA() {
  return (
    <>
      <div>
        <p><strong>SLA Time to Response</strong> refers to the time it takes for Engineering to respond to the Care Jira ticket, via responding in the ticket comment or acknowledging the ticket Status.</p>
        <p><strong>SLA Time to Resolution</strong> refers to the time it takes for the PDG team to mitigate the Care issue after the ticket has been logged & escalated via Jira to the correct PDG.</p>
      </div>
      <Table bordered style={{ padding: '10px'}}>
        <thead>
          <tr style={{fontSize: '14px', fontWeight: 'bold'}}>
            <th>Severity</th>
            <th>Time to Resolution</th>
            <th>Time to Respond</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody style={{fontSize: '14px', textAlign: 'left'}}>
          <tr>
            <td>Critical (P0)</td>
            <td>3 hours</td>
            <td>15 minutes</td>
            <td>
              <ul>
                <li>Tickets increased more than 100% within 1 hour window and reach 100 tickets/above, the comparison is done looking at the tickets trend for the last 1 - 6 hours</li>
                <li>Outage Service /Product incident impacts a whole system or a large number of critical business processes.</li>
                <li> VVIP Customer (Viral Issues + VVIP Tag ~150 customers)</li>
              </ul>
              <div style={{ textDecoration: 'underline' }}>
                Examples of Critical issues of outage issue:
              </div>
              <div>Unable to login, unable to create booking, general wallet issue (customer enable to use GoPay), UI error, Apps force closed</div>
            </td>
          </tr>
          <tr>
            <td>High (P1)</td>
            <td>3 days</td>
            <td>2 days</td>
            <td>
              <ul>
                <li>Tickets increased by 100% within 1 hour window and reach 30 tickets/above, the comparison is done looking at the tickets trend for the last 1 - 6 hours</li>
                <li>VIP Customer with ticket increment by 100% within 1 hour window and reach 15 tickets/above, the comparison is done looking at the tickets trend for the last 1 -6 hours. (mass problem not individual VIP) </li>
              </ul>
              <div style={{ textDecoration: 'underline' }}>
                Examples of High issues include:
              </div>
              <div>
                All issues related to the parameter of the ticket increment within 1 hour window
              </div>
            </td>
          </tr>
          <tr>
            <td>Medium (P2)</td>
            <td>7 days</td>
            <td>3 days </td>
            <td>
              <ul>
                <li>Tickets increased by 100% within 1 hour window and reach 5 - 30 tickets, the comparison is done looking at the tickets trend for the last 1 - 6 hours</li>
                <li> VIP individual ticket</li>
              </ul>
              <div style={{ textDecoration: 'underline' }}>
                Examples of Medium issues include:
              </div>
              <div>All issues related to the parameter of the ticket increment within 1 hour window.</div>
            </td>
          </tr>
          <tr>
            <td>Low(P3)</td>
            <td>14 Days</td>
            <td>4 days</td>
            <td>
              <ul>
                <li>{`Tickets created 1 - 5 tickets`}</li>
                <li>A Low severity Care incidents which can not be resolved with current SOP</li>
              </ul>
              <div style={{ textDecoration: 'underline' }}>
                Examples of Low issues : 
              </div>
              <div>All issues that can not resolve with current SOP</div>
            </td>
          </tr>
        </tbody> 
      </Table>
    </>
  )
}