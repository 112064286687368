import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { strings, tables, telemetry } from 'assets/utils';

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table,
    Row, Col,
    Spinner
  } from "reactstrap";

  import Tag from "components/Tag/Tag.js"; 
  import TeamCard from "components/TeamCard/TeamCard.js"; 
  import APIEditor from "components/APIEditor/APIEditor.js";

  function getFormattedDate(value) {

    value = value / 1000;

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value*1000);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return addZero(dt.getDate()) + '/' + addZero(dt.getMonth()+1) + '/' + dt.getFullYear() + ' ' + time;
  }

  class Synapse extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        tips: [],
        filtered: [],
        tagFilter: [],
        tags: [],
        isLoading: true,
        queryText: '',
        statusFilter: 'all',
        alerts: []
      }
    }

    componentDidMount() {
        
        this.getAPIs();
        
    }

    getAPIs() {

        db.getAPIs().then(apis => {

            var teams = [];
            apis.forEach(api => {
                if(api.teamID) {
                    var isFound = false;
                    for(var i = 0; i < teams.length; i++) {
                        if(api.teamID === teams[i]) {
                            isFound = true;
                            break;
                        }
                    } 
                    if(!isFound)
                        teams.push(api.teamID);
                }
            });

            var promises = [];
            teams.forEach(id => promises.push(db.getTeam(id)));
            
            Promise.all(promises).then(teams => {

                apis.forEach(api => {

                    for(var j = 0; j < teams.length; j++) {
                        if(api.teamID === teams[j].id) {
                            api.team = teams[j];
                            break;
                        }
                    }

                })

                db.getTagsByType('apiType').then(tags => {
                        
                    tags.forEach(tag => {
                        tag.name = tag.name.toLowerCase();
                    })
        
                    this.setState({apis: apis, tags: tags, teams: teams}, this.filterAPIs);
                });
                
               
            });
        });
    }

    filterAPIs(filter, value) {

        var filtered = [];

        var tagFilter = this.state.tagFilter;
        if(filter === 'tags')
            tagFilter = value;

        var statusFilter = this.state.statusFilter;
        if(filter === 'status')
            statusFilter = value;

        this.setState({tagFilter: tagFilter, statusFilter: statusFilter});

        this.state.apis.forEach(api => {
            
            if(api.status === statusFilter || statusFilter === 'all')
                if(this.filterSet(api.tags, tagFilter))
                    filtered.push(api);
        })
        
        filtered.sort( (a,b) => { return a.day - b.day } );
        this.setState({filtered: filtered, isLoading: false });
    }

    filterSet(values, filter) {
        if(filter.length === 0) return true;

        if(!values) return false;

        for(var i = 0; i < values.length; i++) {
            for(var j = 0; j < filter.length; j++) {
                if(values[i] === filter[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    apiCreated(api) {
        var apis = this.state.apis;
        apis.push(api);
        this.setState({apis: apis}, () => this.filterAPIs());
    }

    apiDeleted(api) {
        var apis = this.state.apis;
        for(var i = 0; i < apis.length; i++) {
            if(api.id === apis[i].id) {
                apis.splice(i,1);
                break;
            }
        }
        this.setState({apis: apis}, this.filterAPIs);
    }

    apiUpdated(api) {

        var teams = this.state.teams;
        for(var j = 0; j < teams.length; j++) {
            if(api.teamID === teams[j].id) {
                api.team = teams[j];
                break;
            }
        }

        var apis = this.state.apis;
        for(var i = 0; i < apis.length; i++) {
            if(api.id === apis[i].id) {
                apis[i] = api;
                break;
            }
        }
        this.setState({apis: apis}, this.filterAPIs);
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showFilters() {
        if(this.state.queryText.length > 0)
            return "none";
        else
            return "flex";
    }

    // FIXME: use TagBadges instead
    displayTags(tagIDs) {

        if(tagIDs) {   
            if(Array.isArray(tagIDs)) {

                var tags = [];
                tagIDs.forEach(tagID => {
                    for(var i = 0; i < this.state.tags.length; i++) {
                        if(tagID === this.state.tags[i].id) {
                            tags.push(this.state.tags[i]);
                            break;
                        }
                    }
                })

                tags.sort(this.byName);
                
                return (
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                        {tags.map((tag, index) => (
                            <div key={index} style={{marginRight:"8px", marginBottom:"4px", display:"flex"}}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px"}} >{tag.name}</Badge>
                            </div>
                        ))}
                    </div>
                )
            } 
        } 
    }
    
    getStatusText(status) {
        if(status === 'development')
            return <span style={{color:"gray"}}>Development</span>
        else if (status === 'testing')
            return <span style={{color:"orange"}}>Testing</span>
        else if (status === 'paused')
            return <span style={{color:"green"}}>Production</span>
    }

    teamCard(team) {
        if(team) {
            return (
                <TeamCard team={team} showThumb />
            )
        }
    }


    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div style={{padding:"20px"}}>
                <Row>
                    <Col sm="9" >
                        <br />
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="6">
                                        <CardTitle tag="h4">Synapse</CardTitle>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <div style={{textAlign:"right"}}>
                                            <APIEditor onCreated={(api) => this.apiCreated(api)} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={{minHeight:"300px"}}>
                                <Row style={{display:this.showFilters()}}>
                                    <Col sm="6">
                                        Tags <br />
                                        <Tag type="tipType" icon="nc-bookmark-2" placeholder="All" readOnly
                                            selected={this.state.tagFilter} 
                                            onChange={(tags) => this.filterAPIs('tags',tags)} />
                                        <br />
                                    </Col>
                                    <Col sm="6">
                                        Status <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.statusFilter} 
                                                onChange={(e) => this.filterAPIs("status", e.target.value)} >
                                                    <option value="all">All</option>
                                                    <option value="development">Development</option>
                                                    <option value="testing">Testing</option>
                                                    <option value="production">Production</option>
                                            </Input>
                                        </InputGroup>
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div style={loadingDiv}>
                                            <div style={{marginTop:"89px"}}>
                                                <Spinner color="success" />
                                            </div>
                                        </div>
                                        <div style={{display:this.showResults()}}>
                                            <Table style={{borderColor:"transparent"}}>
                                                <thead>
                                                    <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                        <td></td>
                                                        <td></td>
                                                        <td>Title</td>
                                                        <td>Team</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filtered.map((api, index) => (
                                                    <tr key={index} style={{fontSize:"12px", backgroundColor:tables.rowColor(index)}}>
                                                        <td style={{verticalAlign:"top", textAlign:"center"}}>
                                                            <APIEditor api={api} onUpdated={(api) => this.apiUpdated(api)} onDeleted={(api) => this.apiDeleted(api)} />
                                                            <div style={{fontSize:"9px", marginTop:"12px"}}>
                                                                {this.getStatusText(api.status)}
                                                            </div>
                                                        </td>
                                                        <td style={{verticalAlign:"top", textAlign:"center"}}>
                                                            
                                                        </td>
                                                        <td style={{verticalAlign:"top"}}>
                                                            <div style={{fontWeight:"600", color:"green"}}>
                                                                {api.title}
                                                            </div>
                                                            {strings.trim(api.description,123)}
                                                            <div style={{fontWeight:600, marginTop:"10px", fontSize:"10px", color:"#696969"}}>
                                                                {this.displayTags(api.tags)}
                                                            </div>
                                                        </td>
                                                        <td style={{textAlign:"center", maxWidth:"50px"}}>
                                                            {this.teamCard(api.team)}
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3">
                       
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

Synapse = connect(mapStateToProps)(Synapse);
export default Synapse;