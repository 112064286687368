import React from 'react'
import { RxExit } from 'react-icons/rx'
import { useHistory } from 'react-router-dom'
import { DropdownItem } from 'reactstrap'


export default function MercuryDropDownItem() {
  const history = useHistory()

  const handleLogout = () => {
    localStorage.setItem('logoutLocation', history.location.pathname)
    window.location.href = '/login'
  }
  return (
    <>
      <DropdownItem
        style={{ fontSize: '13px', borderBottom: '0.5px solid #66615B' }}
        onClick={() => window.open('https://go-jek.atlassian.net/servicedesk/customer/portal/71')}
      >
        Raise a Ticket
      </DropdownItem>
      <DropdownItem
        style={{ fontSize: '13px', borderBottom: '0.5px solid #66615B' }}
        onClick={() => window.open('https://docs.google.com/document/d/1xOd1yfuCeG7eXSq7DtK8oCW5eYaLllVwDoj0FuX4GW8/edit#')}
      >
        Documentation
      </DropdownItem>
      <DropdownItem
        style={{ fontSize: '13px', borderBottom: '0.5px solid #66615B' }}
        onClick={() => handleLogout()}
      >
        Logout
        <RxExit style={{ marginLeft: '0.5rem' }} />
      </DropdownItem>
    </>
  )
}
