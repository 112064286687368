import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { telemetry } from 'assets/utils';

import { 
    Button, 
    Row, Col, 
    Input, InputGroup, InputGroupAddon, InputGroupText,
    Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import PersonSearch from "components/PersonSearch/PersonSearch.js";  
import TextEditor from "components/TextEditor/TextEditor.js";

class ContentEditor extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            page: {
                title:''
            },
            openPeopleSearch: false,
            owner: {}
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        if(!this.state.modal)
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "contentEditor", "pageView");
    
        this.setState({modal: !this.state.modal});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    
        if(prevProps.page !== this.props.page ) {
            
            var page = this.props.page;
            

            console.log(page)

            this.setState({page: page})
            
            
            if(this.props.page.owner) {
                db.getPerson(this.props.page.owner).then(owner => {
                  this.setState({owner: owner});
                });
            }
        }
    }

    updatePage(property, value) {
        var obj = this.state.page;
        obj[property] = value;
        this.setState({page: obj});
    }

    openPeopleSearch() { 
        this.setState({openPersonSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPersonSearch: false});
            }, 500);
        });
    }

    assignOwner(person) {
        this.updatePage("owner", person.id);
        this.setState({owner: person});
    }

    ownerCard() {
        if(this.state.owner && this.state.owner.id) {
            return (
                <PersonCard person={this.state.owner} showThumb />
            )
        }
    }

    getEditor() {
        
        if(typeof this.state.page.content !== 'undefined' ) 
            return <TextEditor content={this.state.page.content} 
                onChange={(e) => this.updatePage("content", e)} hideToolbar={false} />
        else 
            console.log('missing content')
    }

    savePage() {

        db.updatePage(this.state.page.id, this.state.page).then(() => {
            this.toggleModal();
        })
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        return (
            <div>
              
                <Button size="sm" onClick={this.toggleModal}>
                    Edit Page
                </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Content Editor
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                Title <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-badge" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={this.state.page.title} onChange={(e) => this.updatePage("title", e.target.value)} />
                                </InputGroup>
                            </Col> 
                        </Row>
                        {/* <Row>
                            <Col md="12">
                                Owner <br />
                                <div onClick={() => this.openPeopleSearch()} 
                                    style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                        {this.ownerCard()}
                                    </div>
                                    <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666"}}>
                                        {this.state.owner.name}
                                    </div>
                                    <div style={{display:"none"}}>
                                        <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignOwner(p)} />
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row style={{minHeight:"400px", marginTop:"20px"}}>
                            <Col md="12">
                            
                                {this.getEditor()}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col sm="3"></Col>
                            <Col sm="9" style={{textAlign:"right"}}>
                                <div style={{display:"inline-block"}}>
                                    <Button color="secondary" onClick={() => this.toggleModal()} size="sm">
                                        Cancel
                                    </Button>
                                </div>
                                <div style={{display:"inline-block", marginLeft:"10px"}}>
                                    <Button color="success" onClick={() => this.savePage()} size="sm" >
                                        Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

ContentEditor = connect(mapStateToProps)(ContentEditor);
export default ContentEditor;
