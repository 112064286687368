import React, { useEffect, useState } from 'react'
import {
  Button, Col, Row,
} from 'reactstrap'
import validator from 'validator'

import { db } from '../../assets/firebase'
import { telemetry } from '../../assets/utils'

const uuid = require('uuid')

function LinkSaveButton({ saveUpdatedLinks, isDisabled }) {
  return (
    <Button color="success" size="md" onClick={() => saveUpdatedLinks()} disabled={isDisabled}>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <i
          style={{ fontSize: '17px', marginTop: '3px', cursor: 'pointer' }}
          className="nc-icon nc-check-2"
        />
      </div>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Save</div>
    </Button>
  )
}

function LinkCancelButton({ onCancelled }) {
  return (
    <Button color="default" size="md" onClick={() => onCancelled()}>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <i
          style={{ fontSize: '17px', marginTop: '3px', cursor: 'pointer' }}
          className="fa fa-close"
        />
      </div>
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Cancel</div>
    </Button>
  )
}

function LinkEditor({
  links, team, user, setLinks, updateTeam, updateInitialLinks, toggleEditMode, isNexusCollaborationForm,
}) {
  const [nameErrors, setNameErrors] = useState(Array(links.length).fill(''))
  const [linkErrors, setLinkErrors] = useState(Array(links.length).fill(''))

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [firebaseErrorMsg, setFirebaseErrorMsg] = useState()

  const addLink = () => {
    const newLinks = [...links]
    const newNameErrors = [...nameErrors]
    const newLinkErrors = [...linkErrors]

    // prepend empty fields to the front of array
    newLinks.splice(0, 0, {
      id: uuid.v4(),
      name: '',
      url: '',
      created: new Date().getTime(),
    })
    newNameErrors.splice(0, 0, '')
    newLinkErrors.splice(0, 0, '')

    setLinks(newLinks)
    setNameErrors(newNameErrors)
    setLinkErrors(newLinkErrors)
  }

  const updateLink = (link, property, value) => {
    const newLinks = [...links]
    for (let i = 0; i < newLinks.length; i += 1) {
      if (newLinks[i].id === link.id) {
        newLinks[i][property] = value
        break
      }
    }
    setLinks(newLinks)
  }

  const deleteLink = (index) => {
    const newLinks = [...links]
    const newNameErrors = [...nameErrors]
    const newLinkErrors = [...linkErrors]

    newLinks.splice(index, 1)
    newNameErrors.splice(index, 1)
    newLinkErrors.splice(index, 1)

    setLinks(newLinks)
    setNameErrors(newNameErrors)
    setLinkErrors(newLinkErrors)
  }

  const toggleSaveButton = () => {
    const validNames = nameErrors.every((el) => el === '')
    const validLinks = linkErrors.every((el) => el === '')
    setIsSaveBtnDisabled(!validNames || !validLinks)
  }

  const validateName = (value, index) => {
    const newErrors = [...nameErrors]

    if (value) {
      newErrors[index] = ''
    } else {
      newErrors[index] = 'Name can\'t be blank'
      setIsSaveBtnDisabled(true)
    }

    setNameErrors(newErrors)
  }

  const validateLink = (value, index) => {
    const newErrors = [...linkErrors]

    if (validator.isURL(value)) {
      newErrors[index] = ''
    } else {
      newErrors[index] = 'Link is empty or invalid'
      setIsSaveBtnDisabled(true)
    }

    setLinkErrors(newErrors)
  }

  const validateAndUpdateName = (linkRef, index, value) => {
    updateLink(linkRef, 'name', value)
    validateName(value, index)
  }

  const validateAndUpdateURL = (linkRef, index, value) => {
    updateLink(linkRef, 'url', value)
    validateLink(value, index)
  }

  const isCurrNameValid = (index) => nameErrors[index] === ''
  const isCurrLinkValid = (index) => linkErrors[index] === ''

  const saveUpdatedLinks = () => {
    setIsSaving(true)

    if (!team && !team.id) {
      updateInitialLinks([...links]) // set initialLinks to the updated links
      return
    }

    const newTeamObj = team
    newTeamObj.links = [...links]
    updateTeam(newTeamObj)

    db.updateTeam(team.id, team).then(() => {
      setIsSaving(false)

      updateInitialLinks([...links]) // set initialLinks to the updated links

      telemetry.logUserActivity(user.id, user.personID, 'teamEditor', 'upsert')
    }).catch((e) => {
      setIsSaving(false)

      setFirebaseErrorMsg(e.message)
      // eslint-disable-next-line no-console
      console.log(firebaseErrorMsg) // TODO: Introduce React Toastify (https://www.npmjs.com/package/react-toastify)
    })
  }

  useEffect(() => {
    for (let index = links.length - 1; index >= 0; index -= 1) {
      const link = links[index]
      validateName(link.name, index)
      validateLink(link.url, index)
    }
  }, [links])

  useEffect(() => {
    toggleSaveButton()
  }, [nameErrors, linkErrors])

  const errorsStyle = {
    fontSize: '12px',
    color: 'red',
    display: 'inline-block',
    minHeight: '15px',
    width: '100%',
  }

  return (
    <>
      <Row className="mb-2">
        <Col sm="5">
          <b>Name</b>
        </Col>
        <Col sm="6">
          <b>URL</b>
        </Col>
        <Col sm="1" />
      </Row>

      {
        links.map((link, index) => (
          <Row key={index} style={{ marginBottom: '10px' }}>
            <Col sm="5">
              <input
                type="text"
                value={link.name}
                placeholder="name"
                onChange={(e) => validateAndUpdateName(link, index, e.target.value)}
                style={{
                  borderStyle: 'none',
                  backgroundColor: isCurrNameValid(index) ? '#efefef' : '#ffbaba',
                  padding: '6px',
                  height: '28px',
                  borderRadius: '6px',
                  fontSize: '12px',
                  width: '100%',
                }}
              />
              <span style={errorsStyle}>{!isCurrNameValid(index) && nameErrors[index]}</span>
            </Col>

            <Col sm="6">
              <input
                type="text"
                value={link.url}
                placeholder="url"
                onChange={(e) => validateAndUpdateURL(link, index, e.target.value)}
                style={{
                  borderStyle: 'none',
                  backgroundColor: isCurrLinkValid(index) ? '#efefef' : '#ffbaba',
                  padding: '6px',
                  height: '28px',
                  borderRadius: '6px',
                  fontSize: '12px',
                  width: '100%',
                }}
              />
              <span style={errorsStyle}>{!isCurrLinkValid(index) && linkErrors[index]}</span>
            </Col>

            <Col sm="1">
              <Button color="danger" size="sm" className="m-0" onClick={() => deleteLink(index)}>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <i
                    style={{ cursor: 'pointer' }}
                    className="fa fa-trash"
                  />
                </div>
              </Button>
            </Col>
          </Row>
        ))
      }

      <Row style={{ width: '100%', margin: '15px 0 0 0' }}>
        <Col sm={7} style={{ paddingLeft: '0' }}>
          <Button onClick={() => addLink()} size={isNexusCollaborationForm ? 'sm' : 'md'}>Add New Link</Button>
        </Col>
        {
          !isNexusCollaborationForm && (
            <Col sm="5" style={{ textAlign: 'right', paddingRight: '0' }}>
              <LinkCancelButton onCancelled={toggleEditMode} />
              <LinkSaveButton saveUpdatedLinks={saveUpdatedLinks} isDisabled={isSaving || isSaveBtnDisabled} />
            </Col>
          )
        }
      </Row>
    </>
  )
}
export default LinkEditor
