import React from "react";

//redux
import { connect } from 'react-redux'

//
import { db } from 'assets/firebase';

import { dateTime, telemetry } from 'assets/utils';

// reactstrap components
import {
        Button, Input, CustomInput,
        Card, CardBody, CardHeader, CardTitle,
        Row, Col, 
        Collapse
    } from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard";

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);

    var hours = dt.getHours();

    var ampm = "am";
    if(hours > 11) 
        ampm = "pm"
    if(hours > 12)
        hours = hours - 12;

    var time = hours + ":" + addZero(dt.getMinutes()) + ampm;

    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate()) + ' ' + time;
}

class ResultsCard extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            trust: 0,
            conflict: 0,
            commitment: 0,
            accountability: 0,
            results: 0,
            showResults: false,
            buttonText: 'Show Results',
            isOpen: false
        };

    }

    componentDidMount() {

        db.getFiveDysfunctionsResponsesBySurveyID(this.props.surveyID).then(responses => {

            //var respondents = 0;
            var trust = 0;
            var conflict = 0;
            var commitment = 0;
            var accountability = 0;
            var results = 0;

            //1 = 4,6,12
            //2 = 1,7,10
            //3 = 3,8,13
            //4 = 2,11,14
            //5 = 5, 9, 15

            responses.forEach(r => {

                trust = trust + Number(r.q4) + Number(r.q6) + Number(r.q12);
                conflict = conflict + Number(r.q1) + Number(r.q7) + Number(r.q10);
                commitment = commitment + Number(r.q3) + Number(r.q8) + Number(r.q13);
                accountability = accountability + Number(r.q2) + Number(r.q11) + Number(r.q14);
                results = results + Number(r.q2) + Number(r.q11) + Number(r.q14);

            });

            trust = (trust / responses.length).toFixed(0);
            conflict = (conflict / responses.length).toFixed(0);
            commitment = (commitment / responses.length).toFixed(0);
            accountability = (accountability / responses.length).toFixed(0);
            results = (results / responses.length).toFixed(0);

            this.setState({trust: trust, conflict: conflict, commitment: commitment, accountability: accountability, results: results});

            //green = 8/9
            //yellow = 6/7
            //red = 3/4/5


        })
    }

    getBackgroundColor(layer) {

        if(!this.state.showResults)
            return "75px solid gray";

        if(layer === 1) {
            return "75px solid " + this.getIndicatorColor(this.state.trust);
        } else if(layer === 2) {
            return "75px solid " + this.getIndicatorColor(this.state.conflict);
        } else if(layer === 3) {
            return "75px solid " + this.getIndicatorColor(this.state.commitment);
        } else if(layer === 4) {
            return "75px solid " + this.getIndicatorColor(this.state.accountability);
        } else if(layer === 5) {
            return "75px solid " + this.getIndicatorColor(this.state.results);
        }

    }

    getIndicatorColor(value) {

        if(value === 0)
            return 'gray';
        else if(value < 5)
            return 'rgba(255, 0, 0, 1.0)'; //red
        else if(value == 5)
            return 'rgba(255, 0, 0, 0.5)'; //red
        else if (value == 6)
            return 'rgba(255, 215, 0, 1.0)'; //yellow
        else if (value == 7)
            return 'rgba(255, 215, 0, 0.5)'; //yellow
        else if (value == 8)
            return 'rgba(0, 255, 0, 0.4)'; //green
        else if (value == 9)
            return 'rgba(0, 255, 0, 1.0)'; //green


    }

    toggleShowResults() {

        if(this.state.showResults)
            this.setState({buttonText:"Show Results", showResults: !this.state.showResults})
        else 
            this.setState({buttonText:"Hide Results", showResults: !this.state.showResults})
        
    }

    render() {

        const layer1 = {
            borderBottom: this.getBackgroundColor(1),
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "500px",
            textAlign:"center",
            marginBottom: "50px",
        }
        const layer2 = {
            borderBottom: this.getBackgroundColor(2),
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "400px",
            marginLeft:"50px"
        }
        const layer3 = {
            borderBottom: this.getBackgroundColor(3),
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "300px",
            marginLeft:"100px"
        }
        const layer4 = {
            borderBottom: this.getBackgroundColor(4),
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "200px",
            marginLeft:"150px"
        }
        const layer5 = {
            borderBottom: this.getBackgroundColor(5),
            borderLeft: "50px solid transparent",
            borderRight: "50px solid transparent",
            width: "100px",
            marginLeft:"200px"
        }

        return (

            <div>
                <Card style={{padding:"20px"}}> 
                    <CardHeader>
                        <Row>
                            <Col xs="8">
                                <CardTitle tag="h4">Technical Program Management</CardTitle>
                                <div style={{fontSize:"11px", fontWeight:400, marginTop:"6px", marginBottom:"0px", color:"#434343"}}>
                                    This survey was delivered on {dateTime.dateConverter("2022-04-22")}.
                                </div>
                            </Col>
                            <Col xs="4" style={{textAlign:"right"}}>
                                <Button size="sm" onClick={() => this.toggleShowResults()}>{this.state.buttonText}</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Collapse isOpen={this.state.showResults}>
                            <Row>
                                <Col xs="12" sm="8"  >
                                    <div style={layer5}> 
                                        
                                    </div> 
                                    <div style={layer4} >
                                        <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                    </div> 
                                    <div style={layer3} >
                                        <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                    </div> 
                                    <div style={layer2} >
                                        <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                    </div> 
                                    <div style={layer1} >
                                        <div style={{height:"1px", backgroundColor:"#fff", width:"100%"}}></div>
                                    </div>    
                                </Col>
                                <Col xs="12" sm="4" style={{fontSize:"17px"}}>

                                    <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"180%", marginLeft:"-80%"}}>
                                        INATTENTION TO RESULTS
                                    </div>
                                    <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"160%", marginLeft:"-60%"}}>
                                        AVOIDANCE OF ACCOUNTABILITY
                                    </div>
                                    <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"140%", marginLeft:"-40%"}}>
                                        LACK OF COMMITMENT
                                    </div>
                                    <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666", width:"120%", marginLeft:"-20%"}}>
                                        FEAR OF CONFLICT
                                    </div>
                                    <div style={{height:"75px", paddingTop:"40px", borderBottom:"1px solid #666"}}>
                                        LACK OF TRUST
                                    </div>
                                </Col>
                            </Row>
                        </Collapse>
                    </CardBody>
                </Card>    
            </div>
            
        )
    }

}

const mapStateToProps = state => {
    return state;
}

ResultsCard = connect(mapStateToProps)(ResultsCard);
export default ResultsCard;