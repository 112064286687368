



// user
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';
export const USER_REDIRECTED = 'USER_REDIRECTED';

export function UserLoggedIn(userObj) {
  return {
    type: USER_LOGGEDIN,
    user: userObj
  }
}

export function UserLoggedOut() {
  return {
    type: USER_LOGGEDOUT,
    user: null
  }
}

export function UserRedirected(url) {
  return {
    type: USER_REDIRECTED,
    destination: url
  }
}

