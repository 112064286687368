// FIXME: create dedicated util files for these
import Parser from 'html-react-parser'
import React from 'react'

const getHTML = (value) => value && Parser(value)

const getSanitisedSlugFromName = (name) => (
  name.replace(/[^a-z0-9.]+/gi, ' ')
      .trim()
      .replace(/[\s]/gi, '-')
      .toLowerCase()
)

const getStatusDescriptor = (status) => {
  switch (status) {
    case 'gray':
      return 'PROPOSED'
    case 'green':
      return 'ON TRACK'
    case 'orange':
      return 'AT RISK'
    case 'red':
      return 'BLOCKED'
    case 'black':
      return 'COMPLETED'
    case 'maroon':
      return 'CANCELLED'
    default:
      return ''
  }
}

const getNoteTypeDescriptor = (category) => {
  switch (category) {
    case 'faq':
      return 'Frequently Asked Question'
    case 'meeting':
      return 'Meeting Notes'
    case 'planning':
      return 'Planning'
    case 'topic':
      return 'Topic'
    default:
      return ''
  }
}

const getValueDescriptor = (value) => {
  switch (value) {
    case '5':
      return <div style={{ color: 'maroon' }}>Critical</div>
    case '4':
      return 'High'
    case '3':
      return 'Medium'
    case '2':
      return 'Low'
    default:
  }
}

const getUrgencyDescriptor = (value) => {
  switch (value) {
    case '4':
      return <div style={{ color: 'maroon' }}>Critical</div>
    case '3':
      return 'High'
    case '2':
      return 'Medium'
    case '1':
      return 'Low'
    default:
  }
}

export {
  getHTML,
  getNoteTypeDescriptor,
  getSanitisedSlugFromName,
  getStatusDescriptor,
  getUrgencyDescriptor,
  getValueDescriptor,
}
