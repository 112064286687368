import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import PersonCard from "components/PersonCard/PersonCard.js";
import TextEditor from "components/TextEditor/TextEditor.js";

const uuid = require('uuid');

class NoteEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            note: {
                title: '',
                category: 'other',
                details: '',
                published: new Date().toISOString().substr(0,10),
                links: []
            },
            author: {}
        }

        if(this.props.projectID)
            this.state.note.projectID = this.props.projectID;

        if(this.props.teamID)
            this.state.note.teamID = this.props.teamID;

        if(this.props.note)
            this.state.note = this.props.note;
        


    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            this.getNote();
            this.getAuthor();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "noteEditor", "pageView");
        } 

        this.setState({modal: !this.state.modal});
    }

    getNote() {

        db.getNote(this.props.note.id).then(note => {
            
            this.setState({note: note});
        });
    }

    getAuthor() {
        if(this.props.note.authorID) {   
            db.getPerson(this.props.note.authorID).then((author => {
                if(author)
                    this.setState({author: author});
            }));
        }
    }
   
    updateNote(property, value) {
        var obj = this.state.note;
        obj[property] = value;
        this.setState({note: obj});
    }

    addLink() {
        var obj = this.state.note;
        obj.links.splice(0,0,{id: uuid.v4(), name:"", url:"", created: new Date().getTime() });
        this.setState({note: obj});
    }

    updateLink(link, property, value) {
        var obj = this.state.note;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({note: obj});
    }

    saveNote() {

        var note = this.state.note;

        if(!note.created) { 
            note.created = new Date().getTime(); 
            note.createdBy = this.props.user.person.name;
            note.authorID = this.props.user.person.id;
        }

        note.modified = new Date().getTime(); 
        note.modifiedBy = this.props.user.person.name;

        db.updateNote(note.id, note).then((result) => {

            if(this.props.onCreated) { // 
                note.id = result.id;
                this.props.onCreated(note);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "note", "createNote");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "note", "updateNote");
            }

            if(this.props.onCreated) { // reset for new report

                var newNote = {
                    title: '',
                    category: '',
                    details: '',
                    published: new Date().toISOString().substr(0,10),
                    links: []
                }

                if(this.props.projectID)
                    newNote.projectID = this.props.projectID;

                if(this.props.teamID)
                    newNote.teamID = this.props.teamID;

                this.setState({ 
                    note: newNote,
                    author: {},
                    activeTab: 'overview'
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(note);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    deleteNote() {

        db.deleteNote(this.state.note.id).then(() => {
            this.setState({modal: false}, () => {
                this.props.onDeleted(this.state.note.id);
            });
        });
    
    }

    authorCard() {
        if(this.state.author.id) {
            return (
                <PersonCard person={this.state.author} showThumb />
            )
        }
    }

    buttonText() {
        if(this.props.onCreated)
            return "New Note";
        else
            return "Edit";
    }


    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "inline-block";
        else
          return "none";
    }

    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              
                <Button size="sm" onClick={() => this.toggleModal()} >
                    {this.buttonText()}
                </Button>
        
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Note
                            </Col>
                        </Row>   
                        <Row>
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Note
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'links' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'links'}) }}
                                    >
                                        Links
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.note.title} onChange={(e) => this.updateNote("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" >
                                        Category <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.note.category} 
                                            onChange={(e) => this.updateNote("category", e.target.value)} >
                                                <option value="faq">Frequently Asked Question (FAQ)</option>
                                                <option value="meeting">Meeting Notes</option>
                                                <option value="planning">Planning</option>
                                                <option value="topic">Topic</option>
                                                <option value="other">Other</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12" sm="6" >
                                        Date <br />  
                                        <Input
                                            type="date"
                                            placeholder=""
                                            value={this.state.note.published}
                                            onChange={(e) => this.updateNote("published", e.target.value)}
                                            style={{height:"42px"}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Details <br />
                                        <TextEditor content={this.state.note.details} onChange={(e) => this.updateNote("details", e)} hideToolbar={false} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col sm="6">
                                        <b>Name</b>
                                    </Col>
                                    <Col sm="6">
                                        <b>URL</b>
                                    </Col>
                                </Row>
                                {this.state.note.links.map((link, index) => (    
                                    <Row key={index} style={{marginBottom:"10px"}}>
                                        <Col sm="6"  >
                                            <input type="text" value={link.name} placeholder="name" 
                                                onChange={(e) => this.updateLink(link, "name", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="6"  >
                                            <input type="text" value={link.url} placeholder="url" 
                                                onChange={(e) => this.updateLink(link, "url", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                    </Row>
                                ))}
                            </TabPane>                   
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6" >
                                <div style={{display:"inline-block", marginRight:"10px"}}>
                                        <ConfirmDialog 
                                        buttonText="Delete"
                                        title="Delete Note" 
                                        header="CONFIRM" 
                                        description="Are you sure you want to delete this note?" 
                                        confirmed={() => this.deleteNote()} /> 
                                </div>
                                <div style={{display:this.showNewLinkButton()}}>
                                    <Button onClick={() => this.addLink()}>Add Link</Button>
                                </div>
                                
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveNote()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    NoteEditor = connect(mapStateToProps)(NoteEditor);
    export default NoteEditor;
    