import React from "react";

import { connect } from 'react-redux';

import { 
    Row, Col
} from "reactstrap";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class TextEditor extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            content: "",
            height: "300px"
        }

        if(this.props.height)
            this.state.height = this.props.height;

        this.onEditorBlur = this.onEditorBlur.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
    }

    componentDidMount() {
        if(this.props.content) {

            this.setState({content: this.props.content});
            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.content !== undefined && prevState.content !== undefined && prevProps.content !== prevState.content) {
            this.setState({
                content: this.props.content
            });
        }
    }

    onEditorBlur(previousRange, source, editor) {
        
        if(this.props.onBlur)
            this.props.onBlur();
    }

    
    onEditorChange(content, delta, source, editor) {
        
        var html = editor.getHTML();
        if(html !== this.state.content) {
            this.setState({content: html}, () => {
                if(this.props.onChange) {
                    //html = html.replaceAll("<p><br></p>", "");
                    this.props.onChange(html);
                }
            });
        }   
    }
    

    modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],[{ 'color': [] }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      }
    
      formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'color', 'size',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    render() {

        return (
            <div style={{minHeight: this.state.height, backgroundColor: "#FFFFFF", color: "#000000"}}>
                    
                <ReactQuill 
                    style={{minHeight: this.state.height, height:this.state.height}}
                    theme="snow" 
                    value={this.state.content} 
                    onBlur={this.onEditorBlur}
                    onChange={this.onEditorChange}
                    modules={this.modules}
                    formats={this.formats}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

TextEditor = connect(mapStateToProps)(TextEditor);
export default TextEditor;