export const trim = (value, maxLength) => {
  if (value) {
    if (value.trim().length > maxLength) return `${value.trim().substring(0, maxLength)}...`
    return value.trim()
  }
  return ''
}

export const numberWithCommas = (x) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(x)) {
    return ''
  }

  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const isEmpty = (str) => !str || str.length === 0
