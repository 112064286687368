import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";

const uuid = require('uuid');

class RiskEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            risk: {
                title: '',
                probability: '',
                severity: '',
                details: '',
                mitigation: '',
                contingency: '',
                links: [],
                projectID: this.props.projectID
            },
            author: {}
        }

        if(this.props.risk)
            this.state.risk = this.props.risk;
        
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            this.getRisk();
            this.getAuthor();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "riskEditor", "pageView");
        } 

        this.setState({modal: !this.state.modal});
    }

    getRisk() {

        db.getRisk(this.props.risk.id).then(risk => {
            
            this.setState({risk: risk});
        });
    }

    getAuthor() {
        if(this.props.risk.authorID) {   
            db.getPerson(this.props.risk.authorID).then((author => {
                if(author)
                    this.setState({author: author});
            }));
        }
    }
   
    updateRisk(property, value) {
        var obj = this.state.risk;
        obj[property] = value;
        this.setState({risk: obj});
    }

    addLink() {
        var obj = this.state.risk;
        obj.links.splice(0,0,{id: uuid.v4(), name:"", url:"", created: new Date().getTime() });
        this.setState({risk: obj});
    }

    updateLink(link, property, value) {
        var obj = this.state.risk;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({risk: obj});
    }

    saveRisk() {

        var risk = this.state.risk;

        if(!risk.created) { 
            risk.created = new Date().getTime(); 
            risk.createdBy = this.props.user.person.name;
            risk.authorID = this.props.user.person.id;
        }

        risk.modified = new Date().getTime(); 
        risk.modifiedBy = this.props.user.person.name;

        db.updateRisk(risk.id, risk).then((result) => {

            if(this.props.onCreated) { // 
                risk.id = result.id;
                this.props.onCreated(risk);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "risk", "createRisk");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "risk", "updateRisk");
            }

            if(this.props.onCreated) { // reset for new report

                this.setState({ 
                    srisktatusReport: {
                        title: '',
                        links: [],
                        published: new Date().toISOString().substr(0,10)
                    },
                    author: {}
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(risk);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    authorCard() {
        if(this.state.author.id) {
            return (
                <PersonCard person={this.state.author} showThumb />
            )
        }
    }

    buttonText() {
        if(this.props.onCreated)
            return "New Risk";
        else
            return "Edit";
    }


    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "block";
        else
          return "none";
    }

    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Risk
                            </Col>
                        </Row>   
                        <Row>
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Risk
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'links' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'links'}) }}
                                    >
                                        Links
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.risk.title} onChange={(e) => this.updateRisk("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Details <br />
                                        <Input placeholder="" 
                                            type="textarea" style={{lineHeight:"23px", minHeight:"150px", paddingLeft:"10px", paddingRight:"10px"}} 
                                            value={this.state.risk.details} onChange={(e) => this.updateRisk("details", e.target.value)} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" >
                                        Probability <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.risk.probability} 
                                            onChange={(e) => this.updateRisk("probability", e.target.value)} >
                                                <option value=""></option>
                                                <option value="3">High</option>
                                                <option value="2">Medium</option>
                                                <option value="1">Low</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12" sm="6" >
                                        Severity <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.risk.severity} 
                                            onChange={(e) => this.updateRisk("severity", e.target.value)} >
                                                <option value=""></option>
                                                <option value="3">High</option>
                                                <option value="2">Medium</option>
                                                <option value="1">Low</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Mitigation Plan<br />
                                        <Input placeholder="How can we proactively prevent this risk from becoming an issue?" 
                                            type="textarea" style={{lineHeight:"23px", minHeight:"150px", paddingLeft:"10px", paddingRight:"10px"}} 
                                            value={this.state.risk.mitigation} onChange={(e) => this.updateRisk("mitigation", e.target.value)} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Contingency Plan <br />
                                        <Input placeholder="How will we address it if it does becomes an issue?" 
                                            type="textarea" style={{lineHeight:"23px", minHeight:"150px", paddingLeft:"10px", paddingRight:"10px"}} 
                                            value={this.state.risk.contingency} onChange={(e) => this.updateRisk("contingency", e.target.value)} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Trigger <br />
                                        <Input placeholder="What non-subjective trigger will tell us it is time to implement the contingency plan?" 
                                            type="textarea" style={{lineHeight:"23px", minHeight:"100px", paddingLeft:"10px", paddingRight:"10px"}} 
                                            value={this.state.risk.trigger} onChange={(e) => this.updateRisk("trigger", e.target.value)} />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                        <Col sm="6">
                                            <b>Name</b>
                                        </Col>
                                        <Col sm="6">
                                            <b>URL</b>
                                        </Col>
                                </Row>
                                {this.state.risk.links.map((link, index) => (    
                                    <Row key={index} style={{marginBottom:"10px"}}>
                                        <Col sm="6"  >
                                            <input type="text" value={link.name} placeholder="name" 
                                                onChange={(e) => this.updateLink(link, "name", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="6"  >
                                            <input type="text" value={link.url} placeholder="url" 
                                                onChange={(e) => this.updateLink(link, "url", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                    </Row>
                                ))}
                            </TabPane>                   
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6" >
                                <div style={{display:this.showNewLinkButton()}}>
                                    <Button onClick={() => this.addLink()}>Add Link</Button>
                                </div>
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveRisk()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    RiskEditor = connect(mapStateToProps)(RiskEditor);
    export default RiskEditor;
    