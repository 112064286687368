import React from 'react'
import { Col, Row } from 'reactstrap'

import TeamCard from '../../TeamCard/TeamCard'

function TeamsTab({ teams }) {
  return teams && (
    <Row>
      {teams.map((team, index) => (
        <Col xs="6" sm="4" md="4" lg="3" xl="3" key={index}>
          <TeamCard team={team} />
        </Col>
      ))}
    </Row>
  )
}
export default TeamsTab
