import React from "react";
import classnames from "classnames";
import { connect } from 'react-redux'
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  Nav,
  Container,
} from "reactstrap";


import logo from "assets/img/goconnect.png";

class DashboardNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: true,
      color: "navbar-transparent",
      showSettings: false,
      query:""
    };

    this.searchText = this.searchText.bind(this);
    this.setSearchTimer = this.setSearchTimer.bind(this);
    this.toggleShowSettings = this.toggleShowSettings.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };


  setSearchTimer(query) {
    clearTimeout(this.searchText);
    this.setState({query}, () => {
        setTimeout(this.searchText,1700);
    });
  }

  searchText() {
    this.props.onSearch(this.state.query);
    
  }

  toggleShowSettings() {
    this.setState({showSettings: !this.state.showSettings});
  }


  render() {
    return (
      <div>
        
        <Navbar
          className={classnames("navbar-absolute fixed-top", "bg-white")}
          expand="lg" 
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{cursor:"pointer"}} onClick={(e) => this.orgChart()} >
                <div style={{display:"inline-block"}}>
                    <img src={logo} alt="react-logo" style={{marginTop:"0px", marginBottom:"0px", marginLeft:"4px", marginRight:"10px", height:"34px", paddingRight:"0px" }} />
                </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              {/* <Form>
                <InputGroup className="no-border">
                  <Input value={this.state.query} placeholder="Search..." type="text" onChange={ (e) => this.setSearchTimer(e.target.value) } />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form> */}
              <Nav navbar>
                
                {/*}
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-bell-55" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else here
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    className="btn-rotate"
                  >
                    <Settings />
                  </NavLink>
                </NavItem>
    */}
               
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

DashboardNavbar = connect(mapStateToProps)(DashboardNavbar);
export default DashboardNavbar;
