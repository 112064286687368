import React from 'react'
import {
  Card,
  CardBody,
  Col,
  Row, Table,
} from 'reactstrap'

import { tables } from '../../assets/utils'

const exportWidgetData = (fileName, tableInfoHeading, countHeading, usersData) => {
  const filename = `${fileName}.csv`
  const contentType = 'text/csv;charset=utf-8;'

  let content = `Employee ID, ${tableInfoHeading}, ${countHeading} \n`
  if (fileName !== 'topPageViews') {
    content = `${tableInfoHeading}, ${countHeading} \n`
  }
  for (let i = 0; i < usersData.length; i += 1) {
    if (fileName === 'topPageViews') {
      content += `${usersData[i].employeeID}, `
    }
    content += `${usersData[i].name.replace(/,/g, '')}, ${usersData[i].count}\n`
  }

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType })
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const a = document.createElement('a')
    a.download = filename
    a.href = `data:${contentType},${encodeURIComponent(content)}`
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export default function TableSummaryChart({
  tableData, cardHeading, cardSummaryLabel, tableInfoHeading, countHeading, filename, downloadIconTitle,
}) {
  return (
    <Card>
      <CardBody>
        <Row style={{ marginBottom: '11px' }}>
          <Col xs="8">
            {cardHeading}
            <div
              style={{
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: 800,
                paddingLeft: '11px',
                display: 'inline-block',
              }}
              title={downloadIconTitle}
              onClick={() => exportWidgetData(filename, tableInfoHeading, countHeading, tableData)}
            >
              <i className="nc-icon nc-cloud-download-93" />
            </div>
          </Col>
          <Col
            xs="4"
            style={{
              fontSize: '10px',
              color: '#343434',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            {cardSummaryLabel}
            {' '}
            {tableData.length}
          </Col>
        </Row>

        <Table>
          <thead>
            <tr style={{ fontSize: '12px', fontWeight: 600 }}>
              <td>
                {tableInfoHeading}
              </td>
              <td style={{ textAlign: 'right' }}>
                {countHeading}
              </td>
            </tr>
          </thead>
          <tbody>
            {tableData.map((person, index) => (
              <tr key={index} style={{ backgroundColor: tables.rowColor(index), fontSize: '12px' }}>
                <td>
                  {person.name}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {person.count}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
