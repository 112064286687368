
import React from "react";

//redux
import { connect } from 'react-redux'

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect } from "react-router-dom";
import Navbar from "components/Navbars/DashboardNavbar.js";
import Dashboard from "views/Dashboard.js";

var ps;

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "success",
      sidebarMini: false,
    };

  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };


  render() {

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <div className="wrapper" style={{backgroundColor:"#efefef"}}>
        <Navbar {...this.props} handleMiniClick={this.handleMiniClick} 
            onSearch={this.searchText} 
            searchQuery={this.state.searchQuery}
            onOrgChartViewClick={() => this.setState({orgChartView: true, teamView: false, productView: false, searchQuery:""})}
            onProductViewClick={() => this.setState({productView: true, teamView: false, orgChartView: false, searchQuery:""})}
            onTeamViewClick={() => this.setState({teamView: true, productView: false, orgChartView: false, searchQuery:""})}
          />
        <div style={{marginTop:"63px", padding:"34px"}}>
            <Dashboard />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

DashboardLayout = connect(mapStateToProps)(DashboardLayout);
export default DashboardLayout;
