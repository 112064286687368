import './project-card.scss'

import Parser from 'html-react-parser'
import React from 'react'
import { connect } from 'react-redux'
import {
  Button, Card, CardBody,
  CardHeader, Carousel, CarouselCaption, CarouselControl,
  CarouselItem, Col,
  Modal, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'

import { db } from '../../assets/firebase'
import {
  arraySort, dateTime, strings, telemetry,
} from '../../assets/utils'
import ProjectEditor from '../ProjectEditor/ProjectEditor'
import LinksTab from './Tabs/LinksTab'
import MembersTab from './Tabs/MembersTab'
import OverviewTab from './Tabs/OverviewTab'
import TeamsTab from './Tabs/TeamsTab'

class ProjectCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      activeIndex: 0,
      animating: false,
      activeTab: 'overview',
      members: [],
      statusReports: [],
      tags: [],
      teams: [],
    }

    const { project } = props
    if (!project.photos) project.photos = []
    if (!project.profilePhoto) { project.profilePhoto = `${process.env.PUBLIC_URL}/projectAvatar.jpg` }

    if (!project.links) project.links = []
    if (!project.successCriteria) project.successCriteria = []
    if (!project.tags) project.tags = []

    this.state.project = project

    this.cardClick = this.cardClick.bind(this)
    this.getProject = this.getProject.bind(this)
    this.onUpdated = this.onUpdated.bind(this)
    this.showDirects = this.showDirects.bind(this)
    this.slackEm = this.slackEm.bind(this)
    this.toggleModal = this.toggleModal.bind(this)

    this.myRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.openByDefault) {
      this.toggleModal()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project) {
      const { project } = this.props
      if (!project.photos) project.photos = []
      if (!project.links) project.links = []
      if (!project.successCriteria) project.successCriteria = []
      if (!project.tags) project.tags = []
      this.setState({ project })
    }
  }

  toggleModal() {
    if (!this.state.modal) {
      this.getProject()
      this.getProjectStatus()
    }

    this.setState((prevState) => ({ modal: !prevState.modal }))
  }

  getProject() {
    try {
      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'projectCard', 'pageView')

      db.getProject(this.state.project.id).then((project) => {
        const projectRef = project
        if (projectRef) {
          if (!projectRef.profilePhoto) { projectRef.profilePhoto = `${process.env.PUBLIC_URL}/projectAvatar.jpg` }

          if (!projectRef.photos) {
            projectRef.photos = [{ name: '', caption: '', url: projectRef.profilePhoto }]
          } else if (projectRef.photos.length === 0) {
            projectRef.photos = [{ name: '', caption: '', url: projectRef.profilePhoto }]
          }

          for (let i = 0; i < projectRef.photos.length; i += 1) {
            if (projectRef.photos[i].isPrimary) {
              projectRef.profilePhoto = projectRef.photos[i].url
              break
            }
          }

          if (!projectRef.links) projectRef.links = []
          projectRef.links.sort((a, b) => b.created - a.created)

          if (!projectRef.successCriteria) projectRef.successCriteria = []

          if (!projectRef.created) projectRef.created = new Date().getTime()
          if (!projectRef.modified) projectRef.modified = new Date().getTime()

          if (projectRef.jiraID) {
            db.getJiraProject(projectRef.jiraID).then((jira) => {
              projectRef.jiraProjectKey = jira.key
            })
          }

          if (!projectRef.tags) projectRef.tags = []
          db.getTagsByType('project').then((tags) => {
            tags.forEach((tag) => {
              const tagRef = tag
              tagRef.name = tagRef.name.toLowerCase()
            })

            this.setState({ tags, project })
          })

          db.getProjectMembers(projectRef.id).then((projectMembers) => {
            const promises = []
            for (let i = 0; i < projectMembers.length; i += 1) {
              promises.push(db.getPerson(projectMembers[i].personID))
            }

            Promise.all(promises).then((results) => {
              const people = [] // because sometimes project members leave
              results.forEach((person) => {
                if (person) people.push(person)
              })

              people.sort(arraySort.byName)
              this.setState({ members: people })
            })
          })

          db.getTeamsByProject(projectRef.id).then((projectTeams) => {
            const promises = []
            for (let i = 0; i < projectTeams.length; i += 1) { promises.push(db.getTeam(projectTeams[i].teamID)) }

            Promise.all(promises).then((results) => {
              const teams = [] // because sometimes teams are deleted
              results.forEach((team) => {
                if (team) teams.push(team)
              })

              teams.sort(arraySort.byName)
              this.setState({ teams })
            })
          })
        }
      })
    } catch (exc) {
      /* eslint-disable no-console */
      console.log('exception:')
      console.log(exc)
      /* eslint-enable no-console */
    }
  }

  getProjectStatus() {
    if (this.state.project.asanaProjectID) {
      db.getAsanaProjectStatus(this.state.project.asanaProjectID).then((statusReports) => {
        statusReports.forEach((report) => {
          const reportRef = report
          reportRef.text = report.text.replace(/\n/g, '<br />')
          reportRef.published = new Date(report.created_at).getTime()
        })
        statusReports.sort((a, b) => b.published - a.published)
        this.setState({ statusReports })
      })
    } else {
      db.getStatusReportsByProject(this.state.project.id).then((statusReports) => {
        statusReports.forEach((statusReport) => {
          const statusReportRef = statusReport
          if (!statusReportRef.text) statusReportRef.text = ''
        })
        statusReports.sort((a, b) => b.published - a.published)
        this.setState({ statusReports })
      })
    }
  }

  onUpdated(project, members) {
    project.links.sort(this.byCreated)
    this.setState({ project, members })
  }

  slackEm() {
    if (this.state.project.slackID) { window.open(`slack://channel?team=T02T4D001&id=${this.state.project.slackID}`) }
  }

  showSlackButton() {
    if (this.state.project.slackID) return 'inline-block'
    return 'none'
  }

  showCard() {
    if (!this.props.showThumb && !this.props.showLargeThumb && !this.props.showXLargeThumb) return 'block'
    return 'none'
  }

  showThumb() {
    if (this.props.showThumb || this.props.showLargeThumb || this.props.showXLargeThumb) return 'inline-block'
    return 'none'
  }

  getThumbSize(axis) {
    if (this.props.showThumb) return '54px'
    if (this.props.showLargeThumb) return '89px'
    if (this.props.showXLargeThumb && axis === 'x') return '100%'
    if (this.props.showXLargeThumb && axis === 'y') return '120px'
  }

  showExpandPrompt() {
    if (this.state.project.preload && !this.props.hideExpander) return 'block'
    return 'none'
  }

  showDirects() {
    const { project } = this.state
    project.children = project.preload
    delete project.preload
    this.setState({ project })
  }

  showGithub() {
    if (this.state.project.github) return 'block'
    return 'none'
  }

  cardClick() {
    if (this.props.onSelected) {
      this.props.onSelected(this.state.project)
    } else { this.toggleModal() }
  }

  render() {
    const activeLink = {
      color: 'white',
      fontWeight: 600,
      fontSize: '12px',
    }
    const link = {
      color: '#434343',
      fontWeight: 400,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeTab = {
      backgroundColor: '#888888',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: '#888888',
    }
    const tab = {
      backgroundColor: '#dedede',
      marginLeft: '1px',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    const title = {
      fontSize: '12px',
      textAlign: 'left',
    }

    const description = {
      fontSize: '8px',
      textAlign: 'left',
      color: '#232323',
      fontWeight: 400,
    }

    const slides = this.state.project.photos.map((item, index) => (
      <CarouselItem
        onExiting={() => this.setState({ animating: true })}
        onExited={() => this.setState({ animating: false })}
        key={index}
      >
        <div style={{
          backgroundSize: 'cover',
          height: '260px',
          width: '100%',
          backgroundColor: '#aaaaaa',
          backgroundImage: `url(${item.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        />
        <CarouselCaption captionText={item.name} captionHeader={item.caption} />
      </CarouselItem>
    ))

    const setActiveIndex = (index) => {
      this.setState({ activeIndex: index })
    }

    const next = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === this.state.project.photos.length - 1 ? 0 : this.state.activeIndex + 1
      setActiveIndex(nextIndex)
    }

    const previous = () => {
      if (this.state.animating) return
      const nextIndex = this.state.activeIndex === 0 ? this.state.project.photos.length - 1 : this.state.activeIndex - 1
      setActiveIndex(nextIndex)
    }

    return (

      <div style={{ whiteSpace: 'normal' }}>

        <div
          style={{
            display: this.showThumb(),
            backgroundSize: 'cover',
            height: this.getThumbSize('y'),
            width: this.getThumbSize('x'),
            backgroundColor: '#aaaaaa',
            borderRadius: '6px',
            backgroundImage: `url(${this.state.project.profilePhoto})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            cursor: 'pointer',
          }}
          onClick={this.toggleModal}
          title={this.state.project.name}
        />

        <div
          ref={this.myRef}
          style={{
            display: this.showCard(),
            width: '123px',
            marginTop: '10px',
            paddingLeft: '3px',
            paddingRight: '3px',
            cursor: 'pointer',
          }}
        >
          <Card onClick={this.cardClick} style={{ marginBottom: '10px' }}>
            <div style={{
              backgroundSize: 'cover',
              height: '100px',
              width: '100%',
              backgroundColor: '#aaaaaa',
              borderTopRightRadius: '6px',
              borderTopLeftRadius: '6px',
              backgroundImage: `url(${this.state.project.profilePhoto})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderBottom: '1px solid green',
            }}
            />
            <CardBody style={{ minHeight: '143px', padding: '8px' }}>
              <Row>
                <Col md="12">
                  <div style={title}>{strings.trim(this.state.project.name, 30)}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div style={description}>{strings.trim(this.state.project.description, 69)}</div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                                &nbsp;
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div
            style={{
              display: this.showExpandPrompt(), cursor: 'pointer', color: 'green', width: '123px', textAlign: 'center',
            }}
            onClick={this.showDirects}
          >
            <i style={navIcon} className="nc-icon nc-vector" />
          </div>
        </div>

        <style>
          {
                    `.carousel {
                        max-width: 100%;
                        height: 260px;
                        background: black;
                        max-height: 260px;
                      }
                      .socialIcon {
                        color: #696969;
                      }
                      .socialIcon:hover {
                        color: green;
                      }
                      `
                }
        </style>

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">

          <Carousel
            className="carousel"
            activeIndex={this.state.activeIndex}
            next={next}
            previous={previous}
            ride="carousel"
          >
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>

          <ModalBody style={{
            paddingTop: '34px', paddingLeft: '23px', paddingRight: '23px', backgroundColor: '#efefef',
          }}
          >
            <Row>
              <Col md="11" style={{ fontSize: '21px', marginBottom: '17px' }}>
                {this.state.project.name}
              </Col>

              <Col
                md="1"
                style={{
                  fontSize: '19px', marginBottom: '17px', cursor: 'pointer', display: this.showGithub(),
                }}
              >
                <div onClick={() => window.open(this.state.project.github)}>
                  <i className="socialIcon fab fa-github" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Nav>
                  <NavItem style={(this.state.activeTab === 'overview' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'overview' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'overview' }) }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'links' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'links' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'links' }) }}
                    >
                      Links
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'members' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'members' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'members' }) }}
                    >
                      Members
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'status' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'status' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'status' }) }}
                    >
                      Status
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'teams' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'teams' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'teams' }) }}
                    >
                      Teams
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  style={{
                    borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#888888', padding: '20px',
                  }}
                >
                  <TabPane tabId="overview" style={{ minHeight: '210px', paddingTop: '17px', fontSize: '12px' }}>
                    <OverviewTab projectRef={this.state.project} allTagsRef={this.state.tags} />
                  </TabPane>

                  <TabPane tabId="links" style={{ minHeight: '210px', paddingTop: '34px', fontSize: '12px' }}>
                    <LinksTab projectLinks={this.state.project.links} />
                  </TabPane>

                  <TabPane tabId="members" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <MembersTab members={this.state.members} fullCardView />
                  </TabPane>

                  <TabPane tabId="status" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col sm="12" />
                    </Row>
                    {this.state.statusReports.map((status, index) => (
                      <Row key={index}>
                        <Col sm="12">
                          <Card>
                            <CardHeader style={{
                              height: '5px',
                              padding: '5px',
                              backgroundColor: status.color,
                              borderTopLeftRadius: '9px',
                              borderTopRightRadius: '9px',
                            }}
                            />
                            <CardBody>
                              <div style={{
                                fontWeight: 600, display: 'inline-block', marginLeft: '10px', verticalAlign: 'middle',
                              }}
                              >
                                {status.title}
                              </div>
                              <div style={{
                                fontSize: '12px',
                                fontWeight: 500,
                                marginTop: '8px',
                                marginLeft: '10px',
                                marginBottom: '4px',
                              }}
                              >
                                {Parser(status.text)}
                              </div>
                              <div style={{
                                fontSize: '11px',
                                fontWeight: 400,
                                marginTop: '6px',
                                marginLeft: '10px',
                                marginBottom: '21px',
                                color: '#434343',
                              }}
                              >
                                {dateTime.dateConverter(status.published)}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                  </TabPane>

                  <TabPane tabId="teams" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <TeamsTab teams={this.state.teams} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter style={{ backgroundColor: '#efefef' }}>
            <Row style={{ width: '100%' }}>
              <Col xs={3} style={{ textAlign: 'left' }}>
                <div style={{ display: 'inline-block', marginRight: '6px' }}>
                  <ProjectEditor project={this.state.project} members={this.state.members} onUpdated={this.onUpdated} />
                </div>
              </Col>
              <Col xs={9} style={{ textAlign: 'right' }}>
                <Button color="success" onClick={this.slackEm} style={{ display: this.showSlackButton() }}>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-chat-33" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Slack
                  </div>
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-class-assign
ProjectCard = connect(mapStateToProps)(ProjectCard)
export default ProjectCard
