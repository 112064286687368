import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

import PersonCard from '../PersonCard/PersonCard'
import GoConnectDropDownItem from './GoConnectDropDownItem'
import MercuryDropDownItem from './MercuryDropDownItem'

export default function ProfileMenuDropdown({ user, showPersonModal }) {
  const location = useLocation()
  return (
    <UncontrolledDropdown className="btn-magnify" nav style={{ paddingTop: '8px' }}>
      <DropdownToggle
        aria-haspopup
        caret
        color="default"
        data-toggle="dropdown"
        id="navbarDropdownMyProfileLink"
        nav
        style={{
          paddingTop: '8px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '0px',
        }}
      >
        <PersonCard person={user.person} showThumb disableThumbOnClick />
      </DropdownToggle>
      <DropdownMenu
        persist
        aria-labelledby="navbarDropdownMyProfileLink"
        right
      >
        <DropdownItem
          style={{ fontSize: '13px', borderBottom: '0.5px solid #66615B' }}
          onClick={() => showPersonModal()}
        >
          My Profile
        </DropdownItem>
        {location.pathname.includes('mercury') ? (
          <MercuryDropDownItem />
                ) : (
                  <GoConnectDropDownItem />
                )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
