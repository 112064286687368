import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button, Card, CardBody, CardHeader, CardText, Table,
} from 'reactstrap'

import { telemetry } from '../../assets/utils'

function ProtoCatalog(props) {
  useEffect(() => {
    telemetry.logUserActivity(props.user.id, props.user.personID, 'mercury', 'pageView')
  })

  const [enumState, setEnumState] = useState(false)
  const [messageState, setMessageState] = useState(false)

  const checkForOptions = (field) => ('options' in field)

  const addAdditionalRowValue = (isTrue, value) => {
    if (isTrue) {
      return (
        <td style={{ verticalAlign: 'top' }}>{value}</td>
      )
    }
  }

  const createMessageTableBody = (fields, isTrue) => {
    const tableBody = []
    for (let i = 0; i < fields.length; i += 1) {
      const currentField = fields[i]
      tableBody.push(
        <tr
          className={(checkForOptions(currentField) && currentField.options.deprecated) ? 'strikeout' : ''}
          style={{
            fontSize: '11px',
            position: 'relative',
          }}
        >
          <td style={{ verticalAlign: 'top' }}>{currentField.name}</td>
          <td style={{ verticalAlign: 'top' }}>{currentField.type}</td>
          <td style={{ verticalAlign: 'top' }}>{currentField.description}</td>
          {addAdditionalRowValue(isTrue.isMappedToKey, currentField.MappedToKey)}
          {addAdditionalRowValue(isTrue.isMappedTo, currentField.MappedTo)}
          {addAdditionalRowValue(isTrue.isSampleValue, currentField['Sample values'])}
        </tr>,
      )
    }
    return tableBody
  }

  const getAdditionalColumn = (currentValue, columnName) => {
    let { description } = currentValue
    let columnValue = null
    if (description.includes(columnName)) {
      [description, columnValue] = description.split(`${columnName}: `)
      columnValue = columnValue.replaceAll('"', '')
    }
    return [description, columnValue]
  }

  const checkAdditionalColumn = (values, columnName) => {
    let isAdditionalColumn = false
    for (let i = 0; i < values.length; i += 1) {
      const currentValue = values[i]
      if (currentValue[columnName] === undefined) {
        [currentValue.description, currentValue[columnName]] = getAdditionalColumn(currentValue, columnName)
      }
      if (currentValue[columnName] !== null) {
        isAdditionalColumn = true
      }
    }
    return isAdditionalColumn
  }

  const addAdditionalColumn = (isTrue, columnName) => {
    let colName
    switch (columnName) {
      case 'DerivedFrom':
        colName = 'Derived From'
        break
      case 'MappedTo':
        colName = 'Mapped To'
        break
      case 'MappedToKey':
        colName = 'Mapped To Key'
        break
      default:
        colName = 'Sample Values'
    }
    if (isTrue) {
      return (
        <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>{colName}</th>
      )
    }
  }

  const createMessageTable = (message) => {
    const isMappedTo = checkAdditionalColumn(message.fields, 'MappedTo')
    const isSampleValue = checkAdditionalColumn(message.fields, 'Sample values')
    const isMappedToKey = checkAdditionalColumn(message.fields, 'MappedToKey')
    const isTrue = {
      isMappedTo,
      isSampleValue,
      isMappedToKey,
    }
    return (
      <CardBody>
        <Table striped bordered>
          <thead>
            <tr>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Name</th>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Type</th>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Description</th>
              {addAdditionalColumn(isMappedTo, 'MappedTo')}
              {addAdditionalColumn(isMappedToKey, 'MappedToKey')}
              {addAdditionalColumn(isSampleValue, 'Sample values')}
            </tr>
          </thead>
          <tbody>
            <style>
              {
              `
              tr.strikeout td:before {
                content: " ";
                position: absolute;
                top: 50%;
                left: 0;
                border-bottom: 1px solid red;
                width: 100%;
              }
              `
            }
            </style>
            {createMessageTableBody(message.fields, isTrue)}
          </tbody>
        </Table>
      </CardBody>
    )
  }

  function processCorrespondsTo(message) {
    let { description, correspondsTo } = message
    if (description.includes('CorrespondsTo')) {
      [description, correspondsTo] = description.split('CorrespondsTo: ')
      correspondsTo = correspondsTo.replaceAll('"', '')
    } else {
      correspondsTo = null
    }
    return [description, correspondsTo]
  }

  const populateMessages = (messages) => {
    const messagesTable = []
    if (!messageState) {
      return messagesTable
    }

    for (let i = 0; i < messages.length; i += 1) {
      const message = messages[i]
      if (message.correspondsTo === undefined) {
        [message.description, message.correspondsTo] = processCorrespondsTo(message)
      }
      messagesTable.push(
        <>
          <h4>{message.name}</h4>
          <p>
            {message.description}
            <br style={{ padding: '0px' }} />
            <span style={{ fontWeight: 'bold' }}>Corresponds To: </span>
            {message.correspondsTo}
          </p>
          <Card style={{ overflow: 'auto' }}>
            {createMessageTable(message)}
          </Card>
        </>,
      )
    }

    if (messagesTable.length === 0) {
      messagesTable.push(
        <Card>
          <CardBody>
            <CardText>No message exists for this proto</CardText>
          </CardBody>
        </Card>,
      )
    }
    return messagesTable
  }

  const createEnumTableBody = (values, isDerivedFrom, isMappedTo) => values.map((currentValue) => (
    <tr style={{ fontSize: '11px', position: 'relative' }}>
      <td style={{ verticalAlign: 'top' }}>{currentValue.name}</td>
      <td style={{ verticalAlign: 'top' }}>{currentValue.description}</td>
      <td style={{ verticalAlign: 'top' }}>{currentValue.number}</td>
      {addAdditionalRowValue(isDerivedFrom, currentValue.DerivedFrom)}
      {addAdditionalRowValue(isMappedTo, currentValue.MappedTo)}
    </tr>
  ))

  const createEnumsTable = (singleEnum) => {
    const isDerivedFrom = checkAdditionalColumn(singleEnum.values, 'DerivedFrom')
    const isMappedTo = checkAdditionalColumn(singleEnum.values, 'MappedTo')
    return (
      <CardBody>
        <Table bordered striped responsive>
          <thead>
            <tr style={{ fontSize: '24px', fontWeight: 'bold' }}>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Name</th>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Description</th>
              <th style={{ color: 'green', cursor: 'pointer', textTransform: 'none' }}>Number</th>
              {addAdditionalColumn(isDerivedFrom, 'DerivedFrom')}
              {addAdditionalColumn(isMappedTo, 'MappedTo')}
            </tr>
          </thead>
          <tbody>
            {createEnumTableBody(singleEnum.values, isDerivedFrom, isMappedTo)}
          </tbody>
        </Table>
      </CardBody>
    )
  }

  const populateEnums = (enums) => {
    const enumsTable = []

    if (!enumState) {
      return enumsTable
    }

    for (let i = 0; i < enums.length; i += 1) {
      const singleEnum = enums[i]
      enumsTable.push(
        <>
          <h4>{singleEnum.name}</h4>
          <p>{singleEnum.description}</p>
          <Card style={{ overflow: 'auto' }}>
            {createEnumsTable(singleEnum)}
          </Card>
        </>,
      )
    }

    if (enumsTable.length === 0) {
      enumsTable.push(
        <Card>
          <CardBody>
            <CardText>No enums exists for this proto</CardText>
          </CardBody>
        </Card>,
      )
    }

    return enumsTable
  }

  const toggleEnums = () => {
    setEnumState(!enumState)
    setMessageState(false)
  }

  const toggleMessage = () => {
    setMessageState(!messageState)
    setEnumState(false)
  }

  const populateData = () => {
    const proto = props.currentProto
    if (!proto || proto.nodes.length !== 0) {
      return <p> Select a Proto from sidebar </p>
    }

    const protoComponent = []
    protoComponent.push(
      <Card>
        <CardHeader tag="h3">
          {proto.name}
        </CardHeader>
        <CardBody>
          <CardText>
            {proto.description}
          </CardText>
          <Button onClick={toggleEnums} active={enumState}>
            View Enums
          </Button>
          <Button onClick={toggleMessage} active={messageState}>
            View Messages
          </Button>
          {populateMessages(proto.messages)}
          {populateEnums(proto.enums)}
        </CardBody>
      </Card>,
    )
    return protoComponent
  }

  return (
    <div style={{ paddingLeft: '10px' }}>
      {populateData()}
    </div>
  )
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-func-assign
ProtoCatalog = connect(mapStateToProps)(ProtoCatalog)
export default ProtoCatalog
