import React from 'react'
import { Col, Row } from 'reactstrap'

import PersonCard from '../../PersonCard/PersonCard'

function DetailedMemberCards({ members }) {
  return members.map((person, index) => (
    <Col xs="6" sm="4" md="4" lg="3" xl="3" key={index}>
      <PersonCard person={person} hideExpander />
    </Col>
  ))
}

function SimpleMembercards({ members }) {
  return (
    <Col xs="12">
      {members.map((person, index) => (
        <div key={index} style={{ display: 'inline-block', marginLeft: '2px', marginRight: '2px' }}>
          <PersonCard person={person} showLargeThumb />
        </div>
      ))}
    </Col>
  )
}

function MembersTab({
  members, fullCardView = false,
}) {
  return members && (
    <>
      <Row>
        <Col xs="12" className="mb-2">
          {!fullCardView && <h6>Project Members</h6>}
        </Col>
      </Row>
      <Row>
        {fullCardView
          ? <DetailedMemberCards members={members} />
          : (
            <SimpleMembercards members={members} />
          )}
      </Row>
    </>
  )
}
export default MembersTab
