import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'

import {
  CUSTOMER_PLATFORM_TEAM_ID, ENGINEERING_PLATFORM_TEAM_ID,
} from '../../components/Nexus/constants'
import { getTeamName, SEVERITY_COLORS } from '../nexusReports/utils'

const options = {
  responsive: true,
  legend: {
    display: true,
  },
  tooltips: {
    enabled: true,
    intersect: false,
    callbacks: {
      label(tooltipItem, data) {
        const type = data.datasets[tooltipItem.datasetIndex].label
        let total = 0
        for (let i = 0; i < data.datasets.length; i += 1) total += data.datasets[i].data[tooltipItem.index]
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        if (value !== 0) {
          value = Math.round(((value) / total) * 100)
        }
        if (tooltipItem.datasetIndex !== data.datasets.length - 1) {
          return (
            `${type} : ${value}%`
          )
        }
        return [
          `${type
          } : ${
            value}%`,
          `Total issues : ${total}`,
        ]
      },
    },
  },
  animations: {
    tension: {
      duration: 1000,
      easing: 'linear',
      from: 0,
      to: 1,
      loop: true,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: '#9f9f9f',
          beginAtZero: false,
          maxTicksLimit: 10,
          padding: 25,
          callback(value) {
            return value.length > 21 ? (`${value.substr(0, 21)}...`) : value
          },
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: 'transparent',
          color: 'rgba(255,255,255,0.05)',
        },
        stacked: true,
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,255,255,0.1)',
          zeroLineColor: 'transparent',
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: '#9f9f9f',
        },
        stacked: true,
      },
    ],
  },
}

const getTeamNameFromIssueAndTeam = (teams, team, issue) => {
  if (team && team.id === CUSTOMER_PLATFORM_TEAM_ID) {
    return issue.jira ? issue.jira.toPod : issue.toPodID
  }
  if (team && team.id === ENGINEERING_PLATFORM_TEAM_ID) {
    return issue.jira && issue.jira.fromPod ? issue.jira.fromPod : 'Not Available'
  }
  return getTeamName(teams, issue.toPodID)
}

export default function OpenIssuesByPod({ issues, teams, team }) {
  const getOpenIssuesByPod = () => {
    const openIssuesByTeam = {}
    issues.forEach((issue) => {
      if (issue.status === 'Active') {
        const teamName = getTeamNameFromIssueAndTeam(teams, team, issue)

        if (teamName) {
          const { severity } = issue
          if (teamName in openIssuesByTeam) {
            if (severity in openIssuesByTeam[teamName]) {
              openIssuesByTeam[teamName][severity] += 1
            } else {
              openIssuesByTeam[teamName][severity] = 1
            }
            openIssuesByTeam[teamName].total += 1
          } else {
            openIssuesByTeam[teamName] = {
              critical: 0,
              high: 0,
              medium: 0,
              low: 0,
              total: 0,
            }
            openIssuesByTeam[teamName][severity] = 1
            openIssuesByTeam[teamName].total = 1
          }
        }
      }
    })

    const data = {
      critical: [],
      high: [],
      medium: [],
      low: [],
    }
    const sortedOpenIssues = Object.keys(openIssuesByTeam).sort((a, b) => (
      openIssuesByTeam[b].total - openIssuesByTeam[a].total
    ))
    sortedOpenIssues.forEach((teamName) => {
      Object.keys(data).forEach((severity) => {
        data[severity].push(openIssuesByTeam[teamName][severity])
      })
    })

    const datasets = [{
      label: 'Low',
      data: data.low,
      backgroundColor: SEVERITY_COLORS.low,
    },
    {
      label: 'Medium',
      data: data.medium,
      backgroundColor: SEVERITY_COLORS.medium,
    },
    {
      label: 'High',
      data: data.high,
      backgroundColor: SEVERITY_COLORS.high,
    },
    {
      label: 'Critical',
      data: data.critical,
      backgroundColor: SEVERITY_COLORS.critical,
    },
    ]

    return {
      labels: sortedOpenIssues,
      datasets,
    }
  }
  return (
    <Card className="h-100">
      <CardBody>
        <h6 className="big-title">
          Open Issues By Pod
        </h6>
        <HorizontalBar options={options} data={getOpenIssuesByPod()} />
      </CardBody>
    </Card>
  )
}
