

import React from "react";

import { connect } from 'react-redux';

import { db } from 'assets/firebase';

import { arraySort, strings, tables } from 'assets/utils';

// reactstrap components
import {
    Badge,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table,
    Row, Col,
    Spinner
  } from "reactstrap";

  import PersonCard from "components/PersonCard/PersonCard.js"; 
  import StandardAdopter from "components/StandardAdopter/StandardAdopter.js"; 
  import StandardCard from "components/StandardCard/StandardCard.js"; 
  import Tag from "components/Tag/Tag.js"; 
  import TeamCard from "components/TeamCard/TeamCard.js"; 

  class StandardAdoptionMatrix extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        scoreCard: {
            platforms: [],
            products: [],
            adoption: 0,
        },
        standards: [],
        filtered: [],
        isLoading: true,
        tpms: [],
        teams: [],
        loopCounter: 0,
        tags: [],
        tagFilter: [],
        statusFilter: 'approved',
        queryText: '',
        openAdoptionEditor: false
      }

      this.getDetailRow = this.getDetailRow.bind(this);
    }

    componentDidMount() {
        
        this.getStandards().then(() => {

            this.filterStandards();

            this.getTeams();
        });

    }

    getTeams() {
       
        var root = {
            id: 'root',
            name: "GoTo",
            isScaffolding: true,
            children: [
                {
                    id: "root", //4WIR9CpYkbHIWO8kF2b7
                    name: "Gojek",
                    isScaffolding: false,
                    profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2Fb920872d-3d34-400d-acf7-1e56d15f71d7.png?alt=media&token=8558b6c8-1284-4286-ad2d-bfe781989182',
                    preload:[],
                    children: [
                        {
                            id: "corporate",
                            name: "Corporate",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "functional",
                            name: "Functional Orgs",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "product",
                            name: "Product Groups",
                            isScaffolding: true,
                            preload:[],
                            children: []
                        },
                        {
                            id: "platform",
                            name: "Platforms",
                            isScaffolding: true,
                            preload:[],
                            children:[]
                        }
                    ]
                },
                {
                    id: "root", //jx0y4iLV90d3bd4V6QjB
                    name: "GTF",
                    isScaffolding: false,
                    profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2F44d337da-8dbe-4f57-80a2-c62c19780e2c.png?alt=media&token=fd638d61-5e6f-4371-a4a5-af3a56f89cb5',
                    preload:[],
                    children: [{
                        id: "gtfCorporate",
                        name: "Corporate",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfFunctional",
                        name: "Functional Orgs",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfProduct",
                        name: "Product Groups",
                        isScaffolding: true,
                        preload:[],
                        children: []
                    },
                    {
                        id: "gtfPlatform",
                        name: "Platforms",
                        isScaffolding: true,
                        preload:[],
                        children:[]
                    }]
                },
                
            ]
        }
    
        this.getTPMs().then(() => {

            db.getTeams().then(teams => {

                for(var t = teams.length - 1; t > -1; t--) {
                    if(teams[t].excludeFromStandards)
                        teams.splice(t,1);
                }

                var promises = [];
                teams.forEach(team => {

                    if(team.tpmID) {
                        for(var y = 0; y < this.state.tpms.length; y++) {
                            if(team.tpmID === this.state.tpms[y].id) {
                                team.tpm = this.state.tpms[y];
                                break;
                            }
                        }
                    }
 
                    promises.push(db.getStandardAdopterByTeam(team.id));
                })
                
                Promise.all(promises).then(adoptions => {

                    adoptions.forEach(adoption => {

                        if(adoption.length > 0) {
        
                            var teamID = adoption[0].team;
                            
                            for(var i = 0; i < teams.length; i++) {
                                if(teams[i].id === teamID) {
                                    teams[i].adoption = adoption;
                                    break;
                                }
                            }
                            
                        }
                    })

                    this.addTeams(root, teams);
                });
            });    
        });
    }

    addTeams(root, teams) {

        for(var i = teams.length -1; i > -1; i--) {
            for(var x = 0; x < root.children.length; x++) {
                if(this.addTeamNode(root.children[x], teams[i]) > 0) {
                    teams.splice(i, 1);
                    break;
                }
            }
        }
        
        if(teams.length > 0) {
           
            if(this.state.loopCounter < 10) { // shouldn't need this, temporary 
                this.setState({loopCounter: this.state.loopCounter + 1}, () => {
                    this.addTeams(root, teams);
                })
            } else {

                console.log("leftovers", teams);

                this.setState({
                    teams: [root], 
                });
            }
            
        } else {

            console.log('all teams accounted for')

            root.forEach(node => {
                node.children = node.preload;
                delete node.preload;
            })

            this.setState({
                teams: [root] 
            });   
        }
    }

    addTeamNode(node, team) {
        
        if(!node.children) node.children = [];
        if(!node.preload) node.preload = [];

        if(node.id === team.parentID) { 

            node.preload.push(team);
            node.preload.sort(arraySort.byName);

            return 1;
        
        }
        else // this is not the node we're looking for so lets go through the children of this node
        {
            if(node.preload) {
                for(var i = 0; i < node.preload.length; i++) {
                    var result = this.addTeamNode(node.preload[i], team);                    
                    if(result > 0) 
                        return result;
                }           
            }
            if (node.children)
                for(var j = 0; j < node.children.length; j++) {
                    if(this.addTeamNode(node.children[j], team) > 0) return 1;
                }
        }
  
        return 0;
    }

    getTPMs() {
        return new Promise( (resolve, reject) => {

            var promises = [];
            db.getTeamMembers('D3yXmqIOUM16xSc39bKa').then(xrefs => {
                xrefs.forEach(xref => {
                    if(xref)
                        promises.push(db.getPerson(xref.personID));
                })

                Promise.all(promises).then(tpms => {

                    var people = [];

                    for(var i = tpms.length -1; i>-1; i--)
                        if(tpms[i])
                            people.push(tpms[i]);

                    this.setState({tpms: people}, resolve);
                })
            })
        });
    }
    
    getInitialSet() {
        return new Promise( (resolve, reject) => {
            if(this.props.project) {
                db.getStandardsByProject(this.props.project.id).then((standards) => {
                    resolve(standards);
                });
            } else {
                db.getStandards().then(standards => {
                    resolve(standards);
                });
            }
        });
    }

    getStandards() {

        return new Promise( (resolve, reject) => {

            this.getInitialSet().then(standards => {

                var people = [];
                var teams = [];

                standards.forEach(standard => {

                    if(!standard.targetDate) standard.targetDate = '';

                    if(standard.teamID) {
                        var isFound = false;
                        for(var i = 0; i < teams.length; i++) {
                            if(standard.teamID === teams[i]) {
                                isFound = true;
                                break;
                            }
                        } 
                        if(!isFound)
                            teams.push(standard.teamID);
                    }

                    if(standard.ownerID) {
                        var isFound = false;
                        for(var p = 0; p < people.length; p++) {
                            if(standard.ownerID === people[p]) {
                                isFound = true;
                                break;
                            }
                        } 
                        if(!isFound)
                            people.push(standard.ownerID);
                    }
                })

                var promises = [];
                teams.forEach(id => promises.push(db.getTeam(id)));
                
                Promise.all(promises).then(teams => {
                    
                    standards.forEach(standard => {
                        for(var j = 0; j < teams.length; j++) {
                            if(standard.teamID === teams[j].id) {
                                standard.team = teams[j];
                                break;
                            }
                        }
                    })


                    promises = [];
                    people.forEach(id => promises.push(db.getPerson(id)));

                    Promise.all(promises).then(people => {
                        standards.forEach(standard => {
                            for(var q = 0; q < people.length; q++) {
                                if(people[q]) { // handle deleted people   
                                    if(standard.ownerID === people[q].id) {
                                        standard.owner = people[q];
                                        break;
                                    }
                                }
                            }
                        })

                        this.setState({standards: standards}, () => resolve());

                    });
                });
            });
        });
    }

    filterStandards(filter, value) {

        var filtered = [];
        var standards = this.state.standards;

        var tagFilter = this.state.tagFilter;
        if(filter === 'tags')
            tagFilter = value;

        var statusFilter = this.state.statusFilter;
        if(filter === 'status')
            statusFilter = value;

        this.setState({tagFilter: tagFilter, statusFilter: statusFilter});

        for(var i = 0; i < standards.length; i++) 
            if(this.filterSet(standards[i].tags, tagFilter))
                if(standards[i].status === statusFilter || statusFilter === 'all')
                    filtered.push(standards[i]);

        filtered.sort(this.byTitle);
        this.setState({filtered: filtered, isLoading: false });

    }

    filterSet(values, filter) {
        if(filter.length === 0) return true;

        if(!values) return false;

        for(var i = 0; i < values.length; i++) {
            for(var j = 0; j < filter.length; j++) {
                if(values[i] === filter[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    standardCreated(standard) {
        var standards = this.state.standards;
        standards.push(standard);
        this.setState({standards: standards}, () => this.filterStandards());
    }
    standardDeleted(standard) {
        var standards = this.state.standards;
        for(var i = 0; i < standards.length; i++) {
            if(standard.id === standards[i].id) {
                standards.splice(i,1);
                break;
            }
        }
        this.setState({standards: standards});
    }
    standardUpdated(standard) {
        var standards = this.state.standards;
        for(var i = 0; i < standards.length; i++) {
            if(standard.id === standards[i].id) {
                standards[i] = standard;
                break;
            }
        }
        this.setState({standards: standards});
    }

    adoptionCreated(adopter) {
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findAdoptionNode(root[x], adopter).then(nodeFound => {
                console.log('created: ' + nodeFound);
                this.setState({teams: root});
            });
        }
    }

    adoptionUpdated(adopter) {
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findAdoptionNode(root[x], adopter).then(nodeFound => {
                console.log('updated: ' + nodeFound);
                this.setState({teams: root});
            });
        }
    }

    findAdoptionNode(node, adopter) {

        return new Promise( (resolve, reject) => {

            if(node.id === adopter.team) {
                
                if(!node.adoption) {
                    node.adoption = [ adopter ];
                } else {

                    var isFound = false;
                    for(var i = 0; i < node.adoption.length; i++) {
                        if(node.adoption[i].standardID === adopter.standardID) {
                            node.adoption[i] = adopter;
                            isFound = true;
                            break;
                        }
                    }
                    if(!isFound)
                        node.adoption.push(adopter);
                }

                resolve(true);
            }
            else {
                try {
                    if(node.children)
                        for(var i = 0; i < node.children.length; i++) 
                            this.findAdoptionNode(node.children[i], adopter).then((nodeFound) => {
                                if(nodeFound)
                                    resolve(nodeFound);
                            });
                }
                catch(exc) {
                    console.log(exc)
                }
            }
        })
    }

    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showResults() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showFilters() {
        if(this.state.queryText.length > 0 || this.props.project)
            return "none";
        else
            return "flex";
    }

    // FIXME: use TagBadges instead
    displayTags(tagIDs) {

        if(tagIDs) {   
            if(Array.isArray(tagIDs)) {

                var tags = [];
                tagIDs.forEach(tagID => {
                    for(var i = 0; i < this.state.tags.length; i++) {
                        if(tagID === this.state.tags[i].id) {
                            tags.push(this.state.tags[i]);
                            break;
                        }
                    }
                })

                tags.sort(arraySort.byName);
                
                return (
                    <div style={{display:"flex", flexWrap:"wrap"}}>
                        {tags.map((tag, index) => (
                            <div key={index} style={{marginRight:"8px", marginBottom:"4px", display:"flex"}}>
                                <Badge color={tag.color} pill style={{marginBottom:"0px"}} >{tag.name}</Badge>
                            </div>
                        ))}
                    </div>
                )
            } 
        } 
    }

    getStatusText(status) {
        if(status === 'proposed')
            return <span style={{color:"gray"}}>Proposed</span>
        else if (status === 'approved')
            return <span style={{color:"green"}}>Approved</span>
        else if (status === 'rejected')
            return <span style={{color:"maroon"}}>Rejected</span>
        else if (status === 'archived')
            return <span style={{color:"gray"}}>Archived</span>
    }

    targetDate(date) {
        if(date) {
            return <span>, target: {date}</span>
        }
    }
    
    byTitle(a,b) {

        var x = a.title.toLowerCase();
        var y = b.title.toLowerCase();
      
        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }

    ownerCard(owner) {
        if(owner && owner.id) {
            return (
                <PersonCard person={owner} showThumb />
            )
        }
    }


    teamCard(team) {
        if(team) {
            if(team.id !== 'root' && team.id !== 'corporate' && team.id !== 'functional' && team.id !== 'product' && team.id !== 'platform' && team.id !== 'gtfCorporate' && team.id !== 'gtfFunctional' && team.id !== 'gtfProduct' && team.id !== 'gtfPlatform')
                return (
                    <TeamCard team={team} showThumb />
            )
        }
    }

    getTeamAdoptionStatusIndicator(team, standard) {

        if(team.id === 'root' || team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return '#bbb';

        if(team.adoption) {

            for(var i = 0; i < team.adoption.length; i++) {

                if(team.adoption[i].standardID === standard.id) {

                    if(team.adoption[i].status) {

                        switch(team.adoption[i].status) {
                            case 'agreed':
                                return '#BDFF90';
                            case 'planning':
                                return '#7DF65C';
                            case 'inProgress':
                                return '#1BDD37';
                            case 'onTrack':
                                return '#1BDD37';
                            case 'adopted':
                                return '#00CC33';
                            case 'atRisk':
                                return 'orange';
                            case 'refused':
                                return 'maroon';
                            case 'notApplicable':
                                return '#bbbbbb';
                            default:
                                return '#efefef';
                        }
                    }
                }
            }
        }
            
        return "#efefefaa";
    }

    getTeamAdoptionStatusText(team, standard) {

        if(team.adoption) {
            for(var i = 0; i < team.adoption.length; i++) {

                if(team.adoption[i].standardID === standard.id) {

                    var text = "Status: ";

                    switch(team.adoption[i].status) {
                        case 'agreed':
                            text = text + 'Agreed';
                            break;
                        case 'planning':
                            text = text + 'Planning';
                            break;
                        case 'inProgress':
                            text = text + 'In Progress';
                            break;
                        case 'onTrack':
                            text = text + 'On Track';
                            break;
                        case 'adopted':
                            text = text + 'Adopted';
                            break;
                        case 'atRisk':
                            text = text + 'At Risk';
                            break;
                        case 'refused':
                            text = text + 'Refused';
                            break;
                        case 'notApplicable':
                            text = text + 'N/A';
                            break;
                        default:
                            text = text + '?';
                    }

                    if(team.adoption[i].notes)
                        text = text + '\nNotes: ' + team.adoption[i].notes;

                    return text;
                }
            }
        }

        return 'no details are available';
    }

    openAdoptionEditor(team, standard) {
   
        console.log('team',team)
        console.log('standard',standard)
        
        if(team && standard) {

            if(!team.adoption) 
                team.adoption = [];
            
            this.setState({openAdoptionInsert: false, openAdoptionUpdate: false}, () => {

                var isFound = false;
                for(var i = 0; i < team.adoption.length; i++) {
                    if(team.adoption[i].standardID === standard.id) {
                        isFound = true;
                        this.setState({selectedAdopter: team.adoption[i], selectedStandard: standard}, () => {
                            this.setState({ openAdoptionUpdate: true }, () => {
                                console.log('糟糕')
                            });
                        });
                    }
                }
                if(!isFound) { // if an adoption record does not yet exist for this team and standard

                    console.log('not found')
                    var adopter = {
                        team: team.id
                    }

                    this.setState({selectedAdopter: adopter, selectedStandard: standard}, () => {
                        this.setState({ openAdoptionInsert: true }, () => {
                            console.log('在这儿')
                        });
                    });
                }
            })
        }
    }

    adoptionEditorUpdate() {
        if(this.state.openAdoptionUpdate) {
            return <StandardAdopter adopter={this.state.selectedAdopter} standard={this.state.selectedStandard}
                        onUpdated={(a) => this.adoptionUpdated(a)}
                        openByDefault />
        }
    }
    adoptionEditorInsert() {
        if(this.state.openAdoptionInsert) {
            return <StandardAdopter adopter={this.state.selectedAdopter} standard={this.state.selectedStandard}
                        onCreated={(a) => this.adoptionCreated(a)} 
                        openByDefault />
        }
    }


    getTeamFontWeight(team) {
        if(team.id === 'root' || team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return 600;
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform')
            return 500;
        else if(team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return 500;
        else 
            return 400;
    }

    getTeamFontColor(team) {
        if(team.id === 'root')
            return 'green';
        else if(team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return 'black';
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform' || team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return 'black';
        else 
            return "#666";
    }

    getTeamBorder(team) {
        if(team.id === 'root')
            return '1px solid #999';
        else if( team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return "1px solid #999";
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform')
            return "0.8px solid #888";
        else 
            return "0.5px solid #aaa";
        
    }

    getTeamPadding(team) {
        if(team.id === 'root')
            return '0px';
        else if(team.id === 'corporate' || team.id === 'functional' || team.id === 'product' || team.id === 'platform' || team.id === 'gtfCorporate' || team.id === 'gtfFunctional' || team.id === 'gtfProduct' || team.id === 'gtfPlatform')
            return '6px';
        else if(team.parentID === 'corporate' || team.parentID === 'functional' || team.parentID === 'product' || team.parentID === 'platform' || team.parentID === 'gtfCorporate' || team.parentID === 'gtfFunctional' || team.iparentIDd === 'gtfProduct' || team.parentID === 'gtfPlatform')
            return '20px';
        else 
            return "34px";
        
    }

    getCarret(team) {
        if(team.children.length > 0) 
            return <div onClick={() => this.collapseTeam(team)}
                        style={{fontSize:"11px", fontWeight:600, display:"inline-block", paddingTop:"10px", marginLeft:"0px", verticalAlign:"top", width:"5%", cursor:"pointer"}}>
                        <i className="fas fa-chevron-down" style={{color:"gray"}}></i>
                    </div>
        else if(team.preload.length > 0) 
            return <div onClick={() => this.expandTeam(team)}
                        style={{fontSize:"11px", fontWeight:600, display:"inline-block", paddingTop:"10px", marginLeft:"0px", verticalAlign:"top", width:"5%", cursor:"pointer"}}>
                        <i className="fas fa-chevron-right" style={{color:"gray"}} ></i>
                    </div>
        else return <div style={{display:"inline-block", paddingLeft:"10px", width:"5%"}}>

        </div>
    }

    collapseTeam(team) {
        
        if (team.children.length > 0) {
            team.preload = team.children;
            team.children = [];
        }
        
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findNode(root[x], team);
        }

        this.setState({teams: root});
    }

    expandTeam(team) {

        if(team.preload) {
            team.children = team.preload
            delete team.preload;
        } 
        
        var root = this.state.teams;
        for(var x = 0; x < root.length; x++) {
            this.findNode(root[x], team);
        }

        this.setState({teams: root});
    }

    findNode(node, team) {

        if(node.id === team.id) {
            node = team;
        }
        else {
            try {
                if(node.children)
                    for(var i = 0; i < node.children.length; i++) 
                        this.findNode(node.children[i], team);
            }
            catch(exc) {
                console.log(exc)
            }
        }
    }

    getDetailRow(team, index) {
        return <div key={index} style={{whiteSpace:'nowrap', overflow:'hidden'}} >
            <div style={{padding:"0px", borderTop:this.getTeamBorder(team), whiteSpace:'nowrap', overflow:'hidden'}}>
                <div style={{display:"inline-block", width:"30%", height:"40px"}}>

                    {this.getCarret(team)}
                    
                    <div style={{display:"inline-block", paddingLeft:this.getTeamPadding(team), paddingTop:"6px"}}>
                        {this.teamCard(team)}
                    </div>

                    <div title={team.name}
                        style={{color:this.getTeamFontColor(team), fontSize:"12px", fontWeight:this.getTeamFontWeight(team), display:"inline-block", paddingTop:"10px", paddingLeft:"10px", overflow: 'hidden', textOverflow: 'ellipsis', width:'80%', verticalAlign:"top", whiteSpace:'nowrap'}}>
                        {strings.trim(team.name,34)}
                    </div>
                    
                </div>
                <div style={{display:"inline-block", width:"calc(70% - 50px)", height:"40px", verticalAlign:"top", textAlign:"right"}}>
                    {this.state.filtered.map((standard, index) => (
                        <div key={index} title={this.getTeamAdoptionStatusText(team, standard)}
                            style={{backgroundColor:this.getTeamAdoptionStatusIndicator(team, standard), borderLeft:this.getTeamBorder(team), height:"40px", width:'43px', maxWidth:'43px', display:"inline-block", cursor:"pointer"}}
                            onClick={() => this.openAdoptionEditor(team, standard)}
                            >
                            
                        </div>
                    ))}
                </div>
                <div style={{display:"inline-block", width:"50px", paddingTop:"6px", paddingLeft:"6px", verticalAlign:"top", textAlign:"center"}}>
                    {this.ownerCard(team.tpm)}
                </div>
            </div>
            <div style={{paddingLeft:"0px", whiteSpace:'nowrap', overflow:'hidden'}}>
                {team.children.map((team, index) => (
                    this.getDetailRow(team, index)
                ))}
            </div>
        </div>
    }

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }

        return (
            <div>        
                <Row style={{display:this.showFilters()}}>
                    <Col sm="6">
                        Tags <br />
                        <Tag type="standardType" icon="nc-bookmark-2" placeholder="All" readOnly
                            selected={this.state.tagFilter} 
                            onChange={(tags) => this.filterStandards('tags',tags)} />
                        <br />
                    </Col>
                    <Col sm="6">
                        Status <br />
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="" 
                            type="select" 
                            value={this.state.statusFilter} 
                            onChange={(e) => this.filterStandards("status", e.target.value)} >
                                <option value="all">All</option>
                                <option value="proposed">Proposed</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                                <option value="archived">Archived</option>
                            </Input>
                        </InputGroup>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div style={loadingDiv}>
                            <div style={{marginTop:"89px"}}>
                                <Spinner color="success" />
                            </div>
                        </div>
                        <div style={{display:this.showResults()}}>
                        
                            <Row>
                                <Col xs="12">

                                    <div style={{display:"inline-block", width:"30%", backgroundColor:"#fff", height:"200px"}}>

                                    </div>
                                    <div style={{display:"inline-block", width:"calc(70% - 50px)", verticalAlign:"top", textAlign:"right", whiteSpace:'nowrap', overflow:'hidden'}}>
                                        {this.state.filtered.map((standard, index) => (
                                            <div key={index} style={{backgroundColor:tables.rowColor(index), color:"green", maxWidth:'43px', height:'200px', display:"inline-block", textAlign:"left"}}>
                                                <div style={{
                                                        transform: "rotate(-90deg)", 
                                                        marginLeft:'-80px', 
                                                        overflow: 'hidden', 
                                                        textOverflow: 'ellipsis', 
                                                        whiteSpace:'nowrap', 
                                                        width:'200px',
                                                        marginTop:'80px',
                                                        fontWeight:600, 
                                                        fontSize:'12px',
                                                    }}
                                                    title={standard.title}
                                                    >
                                                    {strings.trim(standard.title,20)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{display:"inline-block", width:"50px"}}>

                                    </div>

                                    
                                    <div style={{display:"inline-block", backgroundColor:"#fff", width:"30%"}}>

                                    </div>
                                    <div style={{display:"inline-block", width:"calc(70% - 50px)", verticalAlign:"top", textAlign:"right", whiteSpace:'nowrap', overflow:'hidden'}}>
                                        {this.state.filtered.map((standard, index) => (    
                                            <div key={index} style={{width:'43px', maxWidth:'43px', display:"inline-block", textAlign:"center"}}>
                                                {this.ownerCard(standard.owner)}
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{display:"inline-block", width:"50px", fontSize:"10px", textAlign:"center"}}>
                                        TPM
                                    </div>
                                    
                                    {this.state.teams.map((team, index) => (
                                        this.getDetailRow(team, index)
                                    ))}

                                </Col>
                            </Row>
                            
                            <Row style={{marginTop:"50px"}}>
                                <Col xs="12">
                                    <Table style={{borderColor:"transparent"}}>
                                        <thead>
                                            <tr style={{fontSize:"11px", fontWeight:"700"}}>
                                                <td></td>
                                                <td>Title</td>
                                                <td style={{textAlign:"center"}}>Sponsor</td>
                                                <td style={{textAlign:"center"}}>Owner</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.filtered.map((standard, index) => (
                                            <tr key={index} style={{fontSize:"12px", backgroundColor:tables.rowColor(index)}}>
                                                <td style={{verticalAlign:"top"}}>
                                                    <StandardCard standard={standard} 
                                                        onAdoptionCreated={(adopter) => this.adoptionCreated(adopter)}
                                                        onAdoptionUpdated={(adopter) => this.adoptionUpdated(adopter)}
                                                        onUpdated={(standard) => this.standardUpdated(standard)} 
                                                        onDeleted={(standard) => this.standardDeleted(standard)} />
                                                </td>
                                                <td style={{verticalAlign:"top"}}>
                                                    <div style={{fontWeight:"600", color:"green"}}>
                                                        {standard.title}
                                                    </div>
                                                    {strings.trim(standard.description,123)}
                                                    <div style={{fontWeight:600, marginTop:"10px", fontSize:"10px", color:"#696969"}}>
                                                        {this.getStatusText(standard.status)}
                                                        {this.targetDate(standard.targetDate)}
                                                        {this.displayTags(standard.tags)}
                                                    </div>
                                                </td>
                                                <td style={{textAlign:"center", maxWidth:"50px"}}>
                                                    {this.teamCard(standard.team)}
                                                </td>
                                                <td style={{textAlign:"center", maxWidth:"50px"}}>
                                                    {this.ownerCard(standard.owner)}
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {this.adoptionEditorInsert()}
                {this.adoptionEditorUpdate()}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

StandardAdoptionMatrix = connect(mapStateToProps)(StandardAdoptionMatrix);
export default StandardAdoptionMatrix;
