import React from "react";

import { connect } from 'react-redux';

//firebase
import { db, storage } from 'assets/firebase';  

//search index
import { algolia } from "assets/algolia";

//video player
import ReactPlayer from 'react-player'

//utils
import { dateTime, slack, strings, tables, telemetry } from 'assets/utils';

//components
import { 
    CustomInput,
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
    Table
} from "reactstrap";

//fileUploader
import FileUploader from 'react-firebase-file-uploader';

//image viewer
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

//goConnect
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import DiscussionCard from "components/DiscussionCard/DiscussionCard.js";
import PersonSearch from "components/PersonSearch/PersonSearch.js";  
import PersonCard from "components/PersonCard/PersonCard.js";
import ReviewCard from 'components/Review/Review.js'
import Tag from "components/Tag/Tag.js"; 
import TeamSearch from "components/TeamSearch/TeamSearch.js"; 

const uuid = require('uuid');

class IssueEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            activeCareTab: 'careTickets',
            issue: {
                title: '',
                description: '',
                owner: '',
                severity: '',
                status: 'Active',
                attachments: [],
                links: [],
                discussion: [],
                history: [],
                responseTextEnglish: '',
                responseTextIndonesian: '',
                responseTextThai: '',
                responseTextVietnamese: ''
            },
            original: {
                owner: '',
                status: '',
                team: ''
            },
            tickets: [],
            reviews: [],
            jira: [],
            followers: [],
            issueReviews: [],
            owner: {},
            team: {},
            progress: 0,
            isUploading: false,
            showLightBox: false,
            commentText:"",
            openPersonSearch: false,
            openTeamSearch: false,
            issueFollower: false,
            notifications: [],
            newLinkName: '',
            newLinkURL: ''
        };

        if(this.props.issue) {
            //this.state.issue = this.props.issue;
            this.getIssueOwner();
        }

        if(this.props.type) {
            this.state.issue.type = this.props.type;
        }
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.issue !== this.props.issue) {
            this.getIssueOwner();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            this.getIssue();
            this.getIssueTeam();
            this.getIssueFollowers();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "issue", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getIssue() {
        
        db.getIssue(this.props.issue.id).then(issue => {
            
            console.log(issue)

            // capture original values for comparison
            this.setState({original: {
                owner: issue.owner,
                status: issue.status,
                team: issue.team
            }})

            if(!issue.attachments) issue.attachments = [];
            if(!issue.severity) issue.severity = "";
            if(!issue.responseTextEnglish) issue.responseTextEnglish = '';
            if(!issue.responseTextIndonesian) issue.responseTextIndonesian = '';
            if(!issue.responseTextThai) issue.responseTextThai = '';
            if(!issue.responseTextVietnamese) issue.responseTextVietnamese = '';
            
            if(!issue.links) issue.links = []; 
            issue.links.sort( (a,b) => { return b.created - a.created } );

            // get all the people
            var participants = [];

            if(!issue.discussion) issue.discussion = []
            for(var j = 0; j < issue.discussion.length; j++) {
                var isFound = false;
                for(var k = 0; k < participants.length; k++) {
                    if(issue.discussion[j].author === participants[k]) {
                        isFound = true;
                        break;
                    }
                }
                if(!isFound) participants.push(issue.discussion[j].author);
            }

            if(!issue.history) issue.history = [];
            issue.history.sort( (a,b) => { return b.created - a.created } );

            issue.history.forEach(event => {
                var isFound = false;
                for(var k = 0; k < participants.length; k++) {
                    if(event.personID === participants[k]) {
                        isFound = true;
                        break;
                    }
                }
                if(!isFound) participants.push(event.personID);
            })
           

            var promises = [];
            participants.forEach(participant => {
                promises.push(db.getPerson(participant));
            });

            Promise.all(promises).then(people => {

                for(var l = people.length; l > -1; l--)
                    if(!people[l])
                        people.splice(l,1);

                issue.discussion.forEach(comment => {
                    for(var p = 0; p < people.length; p++) {
                        if(people[p].id === comment.author) {
                            comment.person = people[p];
                            break;
                        }
                    }
                });
            
                issue.history.forEach(item => {
                    for(var p = 0; p < people.length; p++) {
                        if(people[p].id === item.personID) {
                            item.person = people[p];
                            break;
                        }
                    }
                });
                let jira = []
                if (issue.jira) {
                    jira.push({
                        label: 'Source issue',
                        link: `https://go-jek.atlassian.net/browse/${issue.jira.key}`
                    })
                }
                if (issue.toJira) {
                    jira.push({
                        label: 'Destination issue',
                        link: issue.toJira.link
                    })
                }

                this.setState({issue:issue, jira: jira});
            })
        })

        db.getIssueTickets(this.props.issue.id).then(tickets => {
            this.setState({tickets: tickets});
        });

        this.getIssueReviews();
    }

    getIssueOwner() {
        if(this.props.issue.owner) {   
            db.getPerson(this.props.issue.owner).then((owner => {
                if(owner)
                    this.setState({owner: owner, originalOwner: owner.id});
            }));
        }
    }
    getIssueTeam() {
        if(this.props.issue.fromTeam) {
            db.getTeam(this.props.issue.fromTeam).then((team => {
                this.setState({team: team});
            }));
        }
        else if(this.props.issue.team) {   
            db.getTeam(this.props.issue.team).then((team => {
                this.setState({team: team});
            }));
        } 
    }
    getIssueReviews() {
        db.getIssueReviews(this.props.issue.id).then(xrefs => {
            //console.log(xrefs);
            
            var promises = [];
            for(var i = 0; i < xrefs.length; i++) 
                promises.push(db.getReview(xrefs[i].reviewID));
            
            Promise.all(promises).then(results => { 
                
                var reviews = [];
                for(var i = 0; i < results.length; i++) {
                    if(results[i]) 
                        reviews.push(results[i])
                }

                this.setState({reviews: reviews, issueReviews: xrefs});
            })
        });
    }
    getIssueFollowers() {
        
        db.getIssueFollowers(this.props.issue.id).then(followers => {
            
            for(var i = 0; i < followers.length; i++) {
                if(followers[i].personID === this.props.user.personID) {
                    
                    this.setState({issueFollower: true, follower: followers[i]});
                    break;
                }
            }
            
            this.setState({followers: followers});            
        })
    }

    updateIssue(property, value) {
        var obj = this.state.issue;
        obj[property] = value;
        this.setState({issue: obj});
    }

    toggleFollow(value) {
        var followers = this.state.followers;

        if(value === 'on') {

            // create a follow object
            var follower = {
                id: uuid.v4(),
                personID: this.props.user.personID,
                issueID: this.state.issue.id,
                created: new Date().getTime()
            }
            // add it to the db
            db.updateIssueFollower(follower.id, follower);

            // add it to the collection
            followers.push(follower);
            
            //update state
            this.setState({follower: follower, issueFollower: true, followers: followers});

        } else if(value === 'off') {

            // remove from db
            db.deleteIssueFollower(this.state.follower.id);

            // remove from collection
            
            for(var i = 0; i < followers.length; i++)
                if(followers[i].id === this.state.follower.id) {
                    followers.splice(i,1);
                    break;
                }


            //update state
            this.setState({follower: {}, issueFollower: false })

        }
    }

    writeHistory(issue) {

        var original = this.state.original;
        
        if(original.owner !== issue.owner) {
            issue.history.push(
                {
                    text: 'Owner changed to ' + this.state.owner.name,
                    created: new Date().getTime(),
                    personID: this.props.user.personID
                });
        }
        if(original.team !== issue.team) {
            issue.history.push(
                {
                    text: 'Team changed to ' + this.state.team.name,
                    created: new Date().getTime(),
                    personID: this.props.user.personID
                });
        }
        if(original.status !== issue.status) {
            issue.history.push(
                {
                    text: 'Status changed to ' + this.state.issue.status,
                    created: new Date().getTime(),
                    personID: this.props.user.personID
                });
        }
        
    }

    saveIssue() {

        if(!this.state.isUploading) {
   
            var issue = this.state.issue;

            if(!issue.created) { 
                issue.created = new Date().getTime(); 
                issue.createdBy = this.props.user.person.name;
            }

            if(this.props.projectID) 
                issue.projectID = this.props.projectID;

            issue.modified = new Date().getTime(); 
            issue.modifiedBy = this.props.user.person.name;

            this.writeHistory(issue);

           
            issue.discussion.forEach(comment => {
                delete comment.person;
            });
            issue.history.forEach(item => {
                delete item.person;
            });
            
            db.updateIssue(issue.id, issue).then((result) => {

                if(this.props.onCreated) { // 
                    issue.id = result.id;
                    this.props.onCreated(issue);
                    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "issue", "createIssue");
                } else {
                    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "issue", "updateIssue");
                }

                // update the search index
                // algolia.upsertIssue(issue);

                // notify followers
                this.notifyFollowers();

                // notify owner
                if(issue.owner !== this.state.originalOwner) {
                    
                    var text = "You have been made the owner of an issue or task.";
                    var blocks = [
                            {
                                "type": "section",
                                "text": {
                                    "type": "mrkdwn",
                                    "text": "You have been made the owner of an issue or task.\n*" + issue.title + "*\n<https://www.gocnnct.com/issues/" + issue.id + "|Open Issue>\n"
                                }
                            }
                        ]
                    
                   // slack.sendMessage(this.state.owner.slackID, text, blocks);   
                }

                if(this.props.onCreated) { // reset for new issue

                    this.setState({ 
                        issue: {
                            title: '',
                            description: '',
                            owner: '',
                            attachments: [],
                            links: [],
                            discussion: [],
                            history: [],
                            severity: '',
                            status: 'Active',
                            responseTextEnglish:'',
                            responseTextIndonesian:'',
                            responseTextThai:'',
                            responseTextVietnamese:'',
                        },
                        tickets: [],
                        reviews: [],
                        jira: []
                    })

                    if(this.props.type) {
                        this.state.issue.type = this.props.type;
                    }
                    
                } else if (this.props.onUpdated) {
                    //issue.discussion = JSON.parse(discussion);
                    //issue.history = JSON.parse(history);
                    this.props.onUpdated(issue);
                }

                // close the dialog
                this.setState({modal:false});
            })
        }
    }

    deleteIssue() {
        db.deleteIssue(this.state.issue.id).then(() => {
            this.toggleModal();
            this.props.onDeleted(this.state.issue);
        });
    }

    addComment() {
        var issue = this.state.issue;
        issue.discussion.push({
            author: this.props.user.person.id,
            text: this.state.commentText,
            created: new Date().getTime(),
            person: this.props.user.person
        });
        issue.discussion.sort( (a,b) => { return b.created - a.created } );
        
        // notify followers                    
        var text = "New Comments";
        var blocks = [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "Issue: *" + issue.title + "*\n_" + this.props.user.person.name + "_\n" + this.state.commentText + "\n<https://www.gocnnct.com/issues/" + issue.id + "|Open Issue>\n"
                }
            }
        ]
        
        var notifications = this.state.notifications;
        notifications.push( {
            text: text,
            blocks: blocks
        });
        
        // done
        this.setState({issue: issue, commentText:"", notifications: notifications});
    }


    getReviewText(review) {
        if(review.platform === 'android')
            return review.review.comments[0].userComment.text;
        else if (review.platform === 'iOS')
            return review.review.title + " - " + review.review.description; 
    }

    deleteReview(review) {

        // remove from UX
        var reviews = this.state.reviews;
        for(var i = 0; i < reviews.length; i++) {
            if(reviews[i].id === review.id) {
                reviews.splice(i,1);
                break;
            }
        }
        
        // remove from history (db)
        var xrefs = this.state.issueReviews;
        for(var j = 0; j < xrefs.length; j++) {
            if(xrefs[j].reviewID === review.id) {
                db.deleteIssueReview(xrefs[j].id);
                xrefs.splice(j, 1);
                break;
            }
        }

        this.setState({reviews: reviews, issueReviews: xrefs});
    }

    notifyFollowers() {

        if(this.state.notifications.length > 0) {

            var notifications = this.state.notifications;
            
            var promises = [];
            for(var i = 0; i < this.state.followers.length; i++) 
                promises.push(db.getPerson(this.state.followers[i].personID));

            Promise.all(promises).then(people => {
                people.forEach(person => {
                    for(var j = 0; j < notifications.length; j++) 
                        slack.sendMessage(person.slackID, notifications[j].text, notifications[j].blocks);
                });
            });
        }
    }

    addAttachment(url) {
        var issue = this.state.issue;
        if(!issue.attachments) issue.attachments = [];
        issue.attachments.push(url);
        this.setState({issue: issue});
    }

    //  (attachments)
    uploadStart = () => {
        this.setState({isUploading: true, progress: 0});
    };
    uploadProgress = (progress) => {
        this.setState({progress});
    };
    uploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    uploadSuccess = (filename) => {
        this.setState({progress: 100, isUploading: false});
        storage.attachments.child(filename).getDownloadURL().then(url => this.addAttachment(url, false));
    };

    //links

    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "block";
        else
          return "none";
    }
    addLink() {
        var obj = this.state.issue;
        obj.links.splice(0,0,{id: uuid.v4(), name:this.state.newLinkName, url:this.state.newLinkURL, created: new Date().getTime() });
        this.setState({issue: obj, newLinkName: '', newLinkURL: ''});
    }
    updateLink(link, property, value) {
        var obj = this.state.issue;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({issue: obj});
    }


    buttonText() {
        if(this.props.onCreated)
            return "New";
        else
            return "Edit";
    }

    showOwnerThumb() {
        if(this.props.showOwnerThumb) 
            return "inline-block";
        else
            return "none";
    }

    showOwnerButton() {
        if(this.state.activeTab === 'overview')
            return "inline-block";
        else
            return "none";
    }

    showFollowSwitch() {
        if(this.state.activeTab === 'discussion')
            return "inline-block";
        else
            return "none";
    }

    showUploadButton() {
        if(this.state.activeTab === 'attachments' && !this.state.isUploading)
            return "inline-block";
        else
            return "none";
    }

    showUploadProgress() {
        if(this.state.activeTab === 'attachments' && this.state.isUploading)
            return "block";
        else
            return "none";
    }

    showNoAttachments() {
        if(this.state.issue.attachments.length === 0)
            return "block";
        else 
            return "none";
    }

    openPeopleSearch() {
        if(!this.isAsanaTask()) {   
            this.setState({openPersonSearch: true}, () => {
                setTimeout(() => {
                    this.setState({openPersonSearch: false});
                }, 500);
            });
        }
    }
    openTeamSearch() {
        this.setState({openTeamSearch: true}, () => {
            setTimeout(() => {
                this.setState({openTeamSearch: false});
            }, 500);
        });
    }

    setSearchTimer(queryText) {
        clearTimeout(this.searchIssues);
        this.setState({queryText}, () => {
            setTimeout(this.searchIssues,2300);
        });
    }

    assignOwner(person) {
        this.updateIssue("owner", person.id);
        this.setState({owner: person});
    }
    assignTeam(team) {
        this.updateIssue("team", team.id);
        this.setState({team: team});
    }

    lightBox() {
        var images = [];
        for(var i = 0; i < this.state.issue.attachments.length; i++) {
            images.push({url: this.state.issue.attachments[i], label:""});
        }
        if(images.length > 0 && this.state.showLightBox)
            return <Lightbox images={images} startIndex={this.state.lightBoxIndex} onClose={() => this.setState({showLightBox: false})} />
        else 
            return "";   
    }

    personCard(person) {
        if(person)
            return <PersonCard person={person} showThumb />
    }

    isAsanaTask() {
        if(this.state.issue.asanaTask) 
            return true;
        else 
            return false;
    }

    isNexusTask() {
        if(this.state.issue.jira) 
            return true;
        else 
            return false;
    }

    checkValidHueSeverity() {
        if(this.state.issue.severity.length === 0) return false;

        if(!isNaN(this.state.issue.severity))
            if(this.state.issue.severity > 0 && this.state.issue.severity <= 100)
                return false;
        
        return true;
    }

    showAdminControls() {
        if(this.props.user.isAdmin)
            return "inline-block";
        else 
            return "none";
    }
    getTitle() {
        if(this.state.issue.type === 'task')
            return 'Task';
        else 
            return 'Issue';
    }
    showNavItem() {
        if(this.state.issue.type !== 'task')
            return 'block';
        else 
            return 'none';
    }
    showIssueField() {
        if(this.state.issue.type !== 'task')
            return 'block';
        else 
            return 'none';
    }
    showTaskField() {
        if(this.state.issue.type === 'task')
            return 'block';
        else 
            return 'none';
    }
    
    ownerCard() {
        if(this.state.owner.id) {
            return (
                <PersonCard person={this.state.owner} showThumb />
            )
        }
    }

    

    render() {

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const iconTextArea = {
            fontSize:"17px",
            position:"absolute",
            top:"20px",
            color:"#000000"
        }
        const link = {
            color:"#434343",
            fontWeight:500,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeLink = {
            color:"green",
            fontWeight:700,
            fontSize:"12px"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
                <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                    <div style={{display:this.showOwnerThumb(), marginTop:"8px", marginLeft:"15px", verticalAlign:"middle"}}>
                        {this.ownerCard()}
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                {this.getTitle()}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'overview'}) }}
                                        >
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'attachments' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'attachments'}) }}
                                        >
                                            Attachments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'links' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'links'}) }}
                                        >
                                            Links
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'discussion' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'discussion'}) }}
                                        >
                                            Discussion
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:this.showNavItem()}}>
                                        <NavLink
                                            style={(this.state.activeTab === 'tickets' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'tickets'}) }}
                                        >
                                            Care Tickets <span style={{color:"green"}}>({this.state.tickets.length})</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:this.showNavItem()}}>
                                        <NavLink
                                            style={(this.state.activeTab === 'feedback' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'feedback'}) }}
                                        >
                                            Feedback <span style={{color:"green"}}>({this.state.reviews.length})</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{display:this.showNavItem()}}>
                                        <NavLink
                                            style={(this.state.activeTab === 'jira' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'jira'}) }}
                                        >
                                            Jira <span style={{color:"green"}}>({this.state.jira.length})</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={(this.state.activeTab === 'history' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'history'}) }}
                                        >
                                            History 
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab} >
                            <TabPane tabId="overview" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Row>
                                    <Col md="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input disabled={this.isAsanaTask()} value={this.state.issue.title} onChange={(e) => this.updateIssue("title", e.target.value)} />
                                        </InputGroup>
                                    </Col>            
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" style={{display:this.showIssueField()}}>
                                        Severity <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.issue.severity} onChange={(e) => this.updateIssue("severity", e.target.value)} >
                                                    <option selected></option>
                                                    <option value="critical">Critical</option>
                                                    <option value="high">High</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="low">Low</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>

                                    <Col xs="6" sm="3" style={{display:this.showTaskField()}}>
                                        Value
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.issue.value} onChange={(e) => this.updateIssue("value", e.target.value)} >
                                                    <option selected></option>
                                                    <option value="5">Critical</option>
                                                    <option value="4">High</option>
                                                    <option value="3">Medium</option>
                                                    <option value="2">Low</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="6" sm="3" style={{display:this.showTaskField()}}>
                                        Urgency
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.issue.urgency} onChange={(e) => this.updateIssue("urgency", e.target.value)} >
                                                    <option selected></option>
                                                    <option value="4">Critical</option>
                                                    <option value="3">High</option>
                                                    <option value="2">Medium</option>
                                                    <option value="1">Low</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>

                                    <Col xs="6" sm="3" >
                                        Type <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"23px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" style={{backgroundColor:"#ffffff00"}}
                                                value={this.state.issue.type} onChange={(e) => this.updateIssue("type", e.target.value)} >
                                                    <option selected></option>
                                                    <option value="app">App Stores</option>
                                                    <option value="care">Care</option>
                                                    <option value="market">Markets</option>
                                                    <option value="other">Other</option>
                                                    <option value="security">Security</option>
                                                    <option value="task">Task</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="6" sm="3">
                                        Status <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"29px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-alert-circle-i" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="select" 
                                                value={this.state.issue.status} onChange={(e) => this.updateIssue("status", e.target.value)} >
                                                    <option>Active</option>
                                                    <option>Closed</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        Description <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="what are the key things to know?" disabled={this.isAsanaTask()}
                                                type="textarea" style={{minHeight:"200px", lineHeight:"19px"}}
                                                value={this.state.issue.description || `${this.state.issue.jira ? this.state.issue.jira.description : ''}`} onChange={(e) => this.updateIssue("description", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {!this.isNexusTask() &&
                                    <Row>
                                        <Col sm="12">
                                            Product <br />
                                            <Tag type="product" icon="nc-shop" 
                                                selected={this.state.issue.product} 
                                                onChange={(tags) => this.updateIssue('product',tags)} />
                                        </Col>
                                    </Row>
                                }
                                {/* <Row style={{marginTop:"10px"}}>
                                    {!this.isNexusTask() &&
                                        <Col xs="6" sm="6" md="5">
                                            Owner <br />
                                            <div onClick={() => this.openPeopleSearch()} 
                                                style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                    {this.ownerCard()}
                                                </div>
                                                <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666"}}>
                                                    {strings.trim(this.state.owner.name,26)}
                                                </div>
                                                <div style={{display:"none"}}>
                                                    <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignOwner(p)} />
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    <Col xs="6" sm="6" md="7">
                                        Team <br />
                                        <div onClick={() => this.openTeamSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {strings.trim(this.state.team.name,34)}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.assignTeam(t)} />
                                            </div>
                                        </div>
                                    </Col> 
                                    
                                </Row> */}
                                {!this.isNexusTask() &&
                                    <Row style={{marginTop:"10px"}}>
                                    <Col md="12">
                                        Market(s) <br />
                                        <Tag type="market" icon="nc-pin-3" 
                                            selected={this.state.issue.market} 
                                            onChange={(tags) => this.updateIssue('market',tags)} />
                                    </Col>
                                </Row>
                                }
                                <Row style={{marginTop:"10px"}}>
                                    <Col sm="12">
                                        Tags <br />
                                        <Tag type="issueType" icon="nc-bookmark-2" 
                                            selected={this.state.issue.tags} 
                                            onChange={(tags) => this.updateIssue('tags',tags)} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="attachments" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Row>
                                    <Col sm="12">
                                        <div style={{display:this.showNoAttachments()}}>
                                        There are no attachments.
                                        </div>
                                    </Col>                                     
                                </Row>
                                <Row>
                                    {this.state.issue.attachments.map((attachment, index) => {
                                        return attachment.indexOf('.mp4') > -1 ?
                                        <Col sm="6" key={index}>
                                            <ReactPlayer url={attachment} controls={true} width='100%' height='100%' />
                                        </Col>
                                        :
                                        <span key={index}></span>
                                    })}
                                </Row>
                                <Row>
                                    {this.state.issue.attachments.map((attachment, index) => {
                                        return attachment.indexOf('.png') > -1 ?
                                        <Col md="3" key={index}>
                                            <img src={attachment} onClick={() => this.setState({showLightBox: true, lightBoxIndex: index})}
                                                style={{marginBottom:"17px", border:"2px solid #aaa", cursor:"pointer"}} 
                                                alt="attachment" />
                                        </Col>
                                        :
                                        <span key={index}></span>
                                    })}
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        {this.lightBox()}
                                    </Col>
                                </Row>           
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"11px"}}>
                                    <Row style={{marginBottom:"17px"}}>
                                        <Col xs="5">
                                            <input type="text" value={this.state.newLinkName} placeholder="name" 
                                                onChange={(e) => this.setState({newLinkName: e.target.value})} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%", marginTop:"7px"}} />
                                        </Col>
                                        <Col xs="5">
                                            <input type="text" value={this.state.newLinkURL} placeholder="url" 
                                                onChange={(e) => this.setState({newLinkURL: e.target.value})} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%", marginTop:"7px"}} />
                                        </Col>
                                        <Col xs="2" style={{textAlign:"right"}}>
                                            <Button size="sm" color="secondary" onClick={() => this.addLink()}>Add</Button>
                                        </Col>
                                    </Row>
                                    
                                    {this.state.issue.links.map((link, index) => (    
                                        <Row key={index} style={{fontSize:"12px"}}>
                                            <Col xs="1">
                                                <i style={icon} className="nc-icon nc-globe" title="link" />
                                            </Col>
                                            <Col xs="8">
                                                <a href={link.url} target="_new" style={{color:"#232323"}}>{link.name}</a><br />
                                            </Col>
                                            <Col xs="3" style={{textAlign:"right", color:"#898989"}}>
                                                {dateTime.dateConverter(link.created)}<br />
                                            </Col>
                                        </Row>
                                    ))}
                                </TabPane>
                            <TabPane tabId="discussion" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Row>
                                    <Col xs="12">
                                        <div style={{display:"inline-block", width:"40px", verticalAlign:"top"}}>
                                            <PersonCard person={this.props.user.person} showThumb />
                                        </div>
                                        <div style={{display:"inline-block", width:"calc(100% - 120px)"}}>
                                            <Input placeholder="" 
                                                type="textarea" style={{lineHeight:"14px", minHeight:"32px", padding:"10px", fontSize:"11px"}} 
                                                value={this.state.commentText} onChange={(e) => this.setState({commentText: e.target.value})} />
                                        </div>
                                        <div style={{display:"inline-block", width:"80px", verticalAlign:"top", textAlign:"right"}}>
                                            <Button size="sm" color="secondary" onClick={() => this.addComment()} style={{margin:"0px"}} >Post</Button>
                                        </div>
                                    </Col> 
                                    {/*
                                    <Col sm="1" style={{paddingTop:"5px"}}>
                                        <PersonCard person={this.props.user.person} showThumb />
                                    </Col>
                                    <Col sm="9" style={{verticalAlign:"middle"}}>    
                                        <Input placeholder="" 
                                            type="textarea" style={{lineHeight:"14px", minHeight:"32px", padding:"10px", fontSize:"11px"}} 
                                            value={this.state.commentText} onChange={(e) => this.setState({commentText: e.target.value})} />
                                    </Col>
                                    <Col sm="2">
                                        <div style={{marginTop:"-10px", textAlign:"right"}}>
                                            <Button size="sm" color="secondary" onClick={() => this.addComment()} >Post</Button>
                                        </div>
                                    </Col>
                                    */}
                                </Row>
                                <div style={{marginTop:"20px", maxHeight:"321px", overflow:"auto"}}>
                                {this.state.issue.discussion.map((discussion, index) => (
                                    <DiscussionCard key={index} discussion={discussion} />
                                ))}
                                </div>
                            </TabPane>
                            <TabPane tabId="tickets" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Row>
                                    <Col sm="2" style={{borderRight:"solid 1px #eeeeee", minHeight:"400px"}}>
                                        <Nav vertical style={{marginTop:"10px"}} >
                                            <NavItem>
                                                <NavLink
                                                    style={(this.state.activeCareTab === 'careTickets' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeCareTab: 'careTickets'}) }}
                                                >
                                                    Tickets
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={(this.state.activeCareTab === 'careTemplates' ? activeLink : link )}
                                                    onClick={() => { this.setState({activeCareTab: 'careTemplates'}) }}
                                                >
                                                    Templates
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                    <Col sm="10">
                                        <TabContent activeTab={this.state.activeCareTab} >
                                            <TabPane tabId="careTickets">
                                                <Row>
                                                    <Col sm="12" style={{fontSize:"12px"}}>
                                                        <br />
                                                        Coming soon, the ability to link and view specific tickets in Salesforce.
                                                    </Col>
                                                </Row>
                                                <Row style={{display:"none"}}>
                                                    <Col sm="12">
                                                        <Table >
                                                            <tbody>
                                                                {this.state.tickets.map((ticket, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {ticket.title}
                                                                    </td>
                                                                    <td>
                                                                        {dateTime.dateConverter(ticket.modified * 1000)}
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Col>                                     
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="careTemplates">
                                                <Row style={{marginTop:"10px"}}>
                                                    <Col sm="7" style={{fontSize:"12px"}}>
                                                        English
                                                    </Col>
                                                    <Col sm="5" style={{fontSize:"11px", textAlign:"right", color:"gray"}}>
                                                        {this.state.issue.responseTextEnglish.length} / 350
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={iconTextArea} className="nc-icon nc-chat-33" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input maxLength="350"
                                                                type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                                                                value={this.state.issue.responseTextEnglish} onChange={(e) => this.updateIssue("responseTextEnglish", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="7" style={{fontSize:"12px"}}>
                                                        Indonesian
                                                    </Col>
                                                    <Col sm="5" style={{fontSize:"11px", textAlign:"right", color:"gray"}}>
                                                        {this.state.issue.responseTextIndonesian.length} / 350
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={iconTextArea} className="nc-icon nc-chat-33" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input maxLength="350"
                                                                type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                                                                value={this.state.issue.responseTextIndonesian} onChange={(e) => this.updateIssue("responseTextIndonesian", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="7" style={{fontSize:"12px"}}>
                                                        Thai
                                                    </Col>
                                                    <Col sm="5" style={{fontSize:"11px", textAlign:"right", color:"gray"}}>
                                                        {this.state.issue.responseTextThai.length} / 350
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={iconTextArea} className="nc-icon nc-chat-33" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input maxLength="350"
                                                                type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                                                                value={this.state.issue.responseTextThai} onChange={(e) => this.updateIssue("responseTextThai", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="7" style={{fontSize:"12px"}}>
                                                        Vietnamese
                                                    </Col>
                                                    <Col sm="5" style={{fontSize:"11px", textAlign:"right", color:"gray"}}>
                                                        {this.state.issue.responseTextVietnamese.length} / 350
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText style={{width:"50px"}}>
                                                                    <i style={iconTextArea} className="nc-icon nc-chat-33" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input maxLength="350"
                                                                type="textarea" style={{lineHeight:"21px", minHeight:"100px", fontSize:"13px"}} 
                                                                value={this.state.issue.responseTextVietnamese} onChange={(e) => this.updateIssue("responseTextVietnamese", e.target.value)} />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="feedback" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Table>
                                    <tbody>
                                    {this.state.reviews.map((review, index) => (
                                        <tr key={index} style={{backgroundColor:tables.rowColor(index)}}>
                                            <td style={{verticalAlign: "top"}}>
                                                <ReviewCard review={review} />
                                            </td>
                                            <td >
                                                <div style={{color:"green", fontWeight:600, fontSize:"12px"}}>
                                                    {review.review.authorName}
                                                </div>
                                                <div style={{fontSize:"11px"}}>
                                                    {this.getReviewText(review)}
                                                </div>
                                                <div style={{fontSize:"11px", color:"gray"}}>
                                                    {dateTime.dateConverter(review.modified)} - {review.platform}
                                                </div>
                                            </td>
                                            <td style={{verticalAlign: "top"}}>
                                                <ConfirmDialog 
                                                    title="unlink review from this issue" 
                                                    header="CONFIRM" 
                                                    description="Are you sure you want to remove this link? This action cannot be undone." 
                                                    confirmed={() => this.deleteReview(review)} />
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </TabPane>
                            <TabPane tabId="jira" style={{minHeight:"400px", paddingTop:"0px"}}>
                                <Row>
                                    <Col sm="12">
                                        {this.state.jira.map(jira => <div>{jira.label}: <a target="_blank" href={jira.link}>{jira.link}</a></div>)}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="history" style={{minHeight:"400px", paddingTop:"0px"}}>
                                
                                {this.state.issue.history.map((item, index) => (
                                <Row key={index} style={{fontSize:"12px", color:"#232323"}}>
                                    <Col sm="2" >
                                        {this.personCard(item.person)}
                                    </Col>
                                    <Col sm="7" style={{marginTop:"8px"}}>
                                        {item.text}
                                    </Col>
                                    <Col sm="3" style={{marginTop:"8px"}}>
                                        {dateTime.timeConverter(item.created)} 
                                    </Col>
                                </Row>
                                ))}
                            </TabPane>
                        </TabContent>   
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col sm="6" >
                                <div style={{display:this.showAdminControls(), marginRight:"10px"}}>
                                    <Button color="danger" onClick={() => this.deleteIssue()} size="sm">Delete</Button>
                                </div>
                                <div style={{display:this.showFollowSwitch(), textAlign:"right", fontSize:"12px", color:"#434343", marginTop:"10px", marginRight:"10px"}} 
                                        title="You will get notified via Slack when changes are made.">
                                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                        <CustomInput type="switch" id="followIssue" name="customSwitch"
                                            checked={this.state.issueFollower} onChange={(e) => this.toggleFollow(e.target.checked)}
                                         />
                                    </div>
                                    <div style={{display:"inline-block", verticalAlign:"middle"}} >
                                        Follow Discussion
                                    </div>
                                </div>
                                <div style={{display:this.showUploadButton(), marginRight:"10px"}}>
                                    
                                    <label style={{marginTop:"11px", backgroundColor:"#888888", borderRadius:"6px", color:"white", fontSize:"11px", fontWeight:600, padding:"6px", paddingLeft:"9px", paddingRight:"9px", textAlign:"center", cursor:"pointer" }}>
                                        NEW ATTACHMENT
                                        <FileUploader
                                            hidden 
                                            accept="image/*, video/mp4"
                                            name="attachment"
                                            randomizeFilename
                                            storageRef={storage.attachments}
                                            onUploadStart={this.uploadStart}
                                            onUploadError={this.uploadError}
                                            onUploadSuccess={this.uploadSuccess}
                                            onProgress={this.uploadProgress}
                                        />
                                    </label>
                                    
                                </div>
                                <div style={{display:this.showUploadProgress(), marginTop:"19px", marginLeft:"10px", fontSize:"11px", color:"green"}}>
                                    Uploading: {this.state.progress}% complete
                                </div>
                                
                            </Col>
                            <Col sm="6" style={{textAlign:"right"}}>
                                
                                <Button color="secondary" onClick={() => this.toggleModal()} size="sm">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveIssue()} size="sm" disabled={this.isNexusTask()}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return state;
}

IssueEditor = connect(mapStateToProps)(IssueEditor);
export default IssueEditor;

