// IMPORTANT NOTE: Stop using me!
// Promote code reusability by using functions/functions/common/algoliaHelper.js :)

//algolia full text search
import { db, func } from 'assets/firebase';
import isProdEnv from 'assets/utils/env'

export const migrateIndexes = () => {

    //db.getTeams().then(teams => { migrateIndex('team', teams) });
    //db.getProjects().then(projects => { migrateIndex('project', projects) });
    //db.getPeople().then(people => { migrateIndex('person', people) });
    //db.getIssues().then(issues => { migrateIndex('issue', issues) });
    //db.getOKRs().then(okrs => { migrateIndex('okr', okrs) });

    //migrateBatch(0).then(() => { console.log('done') }); // feedback
}
/*
function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

function migrateIndex(name, rows) {
    
    console.log('rows: ' + rows.length);
    var counter = 0;
    rows.forEach(row => {
        var delay = Math.floor(Math.random() * 20000); 
        return sleep(delay).then(() => {
            func.upsertAlgoliaRow(name, row);
            counter = counter + 1;
            console.log(counter + " - " + delay);
        });
    })
}


var counter = 0;
function migrateBatch(index) {

    return new Promise((resolve, reject) => {

        db.getAllReviews(index, 200).then(reviews => {

            index = reviews[reviews.length-1].modified;
            console.log(index);

            var promises = [];

            reviews.forEach(review => {

                func.upsertAlgoliaRow('feedback', review);
            })

            Promise.all(promises).then(() => {
                
                counter = counter + 200;
                console.log('completed: ' + counter);

                sleep(2000).then(() => {
                    
                    migrateBatch(index);
                });
            });
        })
    });
}
*/

export const deleteFeedback = (feedback) => {
    func.deleteAlgoliaRow('feedback', feedback.id);
}
export const deleteOKR = (okr) => {
    func.deleteAlgoliaRow('okr', okr.id);
}
export const deletePerson = (person) => {
    func.deleteAlgoliaRow('person', person.id);
}

export const searchAll = (query) => {

    return func.searchAlgoliaIndexPPT(query);
}
export const searchAsanaProjects = (query) => {

    return func.searchAlgoliaIndex('asanaProject', query);
}
export const searchFeedback = (query) => {

    return func.searchAlgoliaIndex('feedback', query);
}
export const searchIssues = (query) => {

    return func.searchAlgoliaIndex('issue', query);
}
export const searchJiraProjects = (query) => {

    return func.searchAlgoliaIndex('jiraProject', query);
}
export const searchOKRs = (query) => {

    return func.searchAlgoliaIndex('okr', query);
}
export const searchPeople = (query, category) => {

    return func.searchAlgoliaIndex('person', query);
}
export const searchTeams = (query) => {

    return func.searchAlgoliaIndex('team', query);
}
export const searchProjects = (query) => {

    return func.searchAlgoliaIndex('project', query);
}


export const upsertFeedback = (feedback) => {
    if (!isProdEnv)
        return

    feedback.objectID = feedback.id;
    func.upsertAlgoliaRow('feedback', feedback);
}
export const upsertIssue = (issue) => {
    if (!isProdEnv)
        return

    issue.objectID = issue.id;
    func.upsertAlgoliaRow('issue', issue);
}
export const upsertOKR = (okr) => {
    if (!isProdEnv)
        return

    okr.objectID = okr.id;
    func.upsertAlgoliaRow('okr', okr);
}
export const upsertPerson = (person) => {
    if (!isProdEnv)
        return

    var p = person;
    p.objectID = person.id;
    func.upsertAlgoliaRow('person', p);
}
export const upsertProject = (project) => {
    if (!isProdEnv)
        return

    var p = {
        id: project.id,
        name: project.name,
        description: project.description,
        askUs: project.askUs,
        profilePhoto: project.profilePhoto,
        slug: project.slug,
    }
    p.objectID = project.id;
    func.upsertAlgoliaRow('project', p);
}
export const upsertTeam = (team) => {
    if (!isProdEnv)
        return

    var t = {
        id: team.id,
        name: team.name,
        description: team.description,
        askUs: team.askUs,
        profilePhoto: team.profilePhoto,
        slug: team.slug,
    }
    t.objectID = team.id;
    func.upsertAlgoliaRow('team', t);
}
