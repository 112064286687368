import React from 'react'
import FileUploader from 'react-firebase-file-uploader'
import { connect } from 'react-redux'
import {
  Button, Col,
  CustomInput,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalBody, ModalFooter,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap'

import { algolia } from '../../assets/algolia'
import { db, func, storage } from '../../assets/firebase'
import {
  arraySort, slack, strings, tables, telemetry,
} from '../../assets/utils'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import OKRSearch from '../OKRs/OKRSearch'
import PersonCard from '../PersonCard/PersonCard'
import PersonSearch from '../PersonSearch/PersonSearch'
import ProjectCard from '../ProjectCard/ProjectCard'
import ProjectSearch from '../ProjectSearch/ProjectSearch'
import TeamCard from '../TeamCard/TeamCard'
import TeamSearch from '../TeamSearch/TeamSearch'
import TextEditor from '../TextEditor/TextEditor'
import { getSanitisedSlugFromName } from '../utils'
// we are using this React Component to create another modal box to create subteam
// eslint-disable-next-line import/no-self-import
import SubTeamEditor from './TeamEditor'

const uuid = require('uuid')

class TeamEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      activeTab: '1',
      team: {
        name: '',
        description: '',
        askUs: '',
        confluence: '',
        photos: [],
        links: [],
        profilePhoto: `${process.env.PUBLIC_URL}/teamAvatar.png`,
      },
      homePage: '',
      tpm: {},
      teamMembers: [],
      teamProjects: [],
      teamOKRs: [],
      members: [],
      projects: [],
      subTeams: [],
      OKRs: [],
      filteredOKRs: [],
      okrStatusFilter: 'all',
      okrCycleFilter: '22h1',
      newMemberID: '',
      progress: 0,
      isUploading: false,
      openPersonSearch: false,
      openOKRSearch: false,
      newParentID: false,
    }

    if (props.team) {
      const { team } = props
      if (!team.links) team.links = []
      this.imageCheck(team)
      this.state.team = team
    }

    this.addMember = this.addMember.bind(this)
    this.addSubTeam = this.addSubTeam.bind(this)
    this.deleteMember = this.deleteMember.bind(this)
    this.getTeam = this.getTeam.bind(this)
    this.photoUploadSuccess = this.photoUploadSuccess.bind(this)
    this.saveTeam = this.saveTeam.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.updateLink = this.updateLink.bind(this)
    this.deleteLink = this.deleteLink.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.team !== this.props.team) {
      const { team } = this.props
      if (!team.links) team.links = []
      this.setState({ team }, this.getTeam)
    }
    if (prevProps.name !== this.props.name) {
      this.updateProject('name', this.props.name)
      this.updateProject('slug', getSanitisedSlugFromName(this.props.name))
    }
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal })
    this.getTeam()
  }

  imageCheck(team) {
    if (!team.profilePhoto) { team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png` }
    if (team.profilePhoto.indexOf('avatar') > -1) { team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png` }

    if (!team.photos) team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
    else if (team.photos.length === 0) team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
  }

  getTeam() {
    if (this.props.team) { // i.e. edit experience
      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'teamEditor', 'pageView')

      db.getTeam(this.props.team.id).then((team) => {
        if (!team.slug) {
          team.slug = team.name.replace(/[\s]/gi, '-').toLowerCase()
        }

        if (!team.links) team.links = []
        team.links.sort((a, b) => b.created - a.created)

        this.imageCheck(team)

        this.setState({ team })

        db.getTeamMembers(this.state.team.id).then((teamMembers) => {
          const promises = []
          for (let i = 0; i < teamMembers.length; i++) { promises.push(db.getPerson(teamMembers[i].personID)) }

          Promise.all(promises).then((people) => {
            const members = []
            people.forEach((person) => { // necessary to handle missing employees
              if (person) members.push(person)
            })

            members.sort(arraySort.byName)
            this.setState({ members, teamMembers })
          })
        })

        db.getTeamProjects(team.id).then((teamProjects) => {
          const promises = []
          for (let i = 0; i < teamProjects.length; i++) { promises.push(db.getProject(teamProjects[i].projectID)) }

          Promise.all(promises).then((projects) => {
            projects.sort(arraySort.byName)
            this.setState({ projects, teamProjects })
          })
        })

        db.getSubTeams(this.state.team.id).then((subTeams) => {
          const promises = []
          for (let i = 0; i < subTeams.length; i++) { promises.push(db.getTeam(subTeams[i].id)) }

          Promise.all(promises).then((teams) => {
            teams.sort(arraySort.byName)
            this.setState({ subTeams: teams })
          })
        })

        db.getTeamOKRs(team.id).then((OKRs) => {
          const OKRIDs = []
          OKRs.forEach((okr) => {
            OKRIDs.push(okr.id)
            this.setState({ teamOKRs: OKRIDs })
          })

          OKRs.sort((a, b) => a.number - b.number)

          const owners = []
          const promises = []
          OKRs.forEach((okr) => {
            okr.KRs.forEach((kr) => {
              if (kr.ownerID) {
                if (kr.ownerID.length > 0) {
                  let isFound = false
                  for (let o = 0; o < owners.length; o++) {
                    if (owners[o] === okr.ownerID) {
                      isFound = true
                      break
                    }
                  }
                  if (!isFound) promises.push(db.getPerson(kr.ownerID))
                }
              }
            })
          })

          Promise.all(promises).then((people) => {
            for (let q = people.length - 1; q > -1; q--) { if (!people[q]) people.splice(q, 1) }

            OKRs.forEach((okr) => {
              okr.KRs.forEach((kr) => {
                for (let p = 0; p < people.length; p++) {
                  if (kr.ownerID === people[p].id) {
                    kr.person = people[p]
                    break
                  }
                }
              })
            })
          })

          this.setState({ OKRs }, this.filterOKRs)
        })

        if (team.tpmID) {
          db.getPerson(team.tpmID).then((tpm) => {
            if (tpm) { this.setState({ tpm }) }
          })
        }
      })
    }
  }

  updateTeam(property, value) {
    const obj = this.state.team
    obj[property] = value
    this.setState({ team: obj })
  }

  // projects
  showNewRelatedProjectButton() {
    if (this.state.activeTab === '4') return 'block'
    return 'none'
  }

  addRelatedProject(project) {
    const { projects } = this.state
    projects.push(project)
    this.setState({ projects })
  }

  deleteProject(project) {
    const { projects } = this.state
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].id === project.id) {
        projects.splice(i, 1)
        break
      }
    }
    this.setState({ projects })
  }

  // tpm
  openTPMSearch() {
    this.setState({ openTPMSearch: true, tpmSelection: true }, () => {
      setTimeout(() => {
        this.setState({ openTPMSearch: false })
      }, 500)
    })
  }

  assignTPM(person) {
    this.setState({ tpm: person })
    this.updateTeam('tpmID', person.id)
  }

  tpmCard() {
    if (this.state.tpm) {
      if (this.state.tpm.id) {
        return (
          <PersonCard person={this.state.tpm} showThumb />
        )
      }
    }
  }

  // team members
  addMember(person) {
    const { members } = this.state
    const existingIds = members.map((member) => member.id)

    if (existingIds.includes(person.id)) {
      return
    }

    members.push(person)
    this.setState({ members })
  }

  deleteMember(person) {
    const { members } = this.state
    for (let i = 0; i < members.length; i += 1) {
      if (members[i].id === person.id) {
        members.splice(i, 1)
        break
      }
    }
    this.setState({ members })
  }

  showNewMemberButton() {
    if (this.state.activeTab === '3') return 'inline-block'
    return 'none'
  }

  // links
  addLink() {
    const obj = this.state.team
    obj.links.splice(0, 0, {
      id: uuid.v4(), name: '', url: '', created: new Date().getTime(),
    })
    this.setState({ team: obj })
  }

  updateLink(link, property, value) {
    const obj = this.state.team
    for (let i = 0; i < obj.links.length; i++) {
      if (obj.links[i].id === link.id) {
        obj.links[i][property] = value
        break
      }
    }
    this.setState({ team: obj })
  }

  deleteLink(index) {
    const newTeamObj = this.state.team
    delete newTeamObj.links[index]
    this.setState({ team: newTeamObj })
  }

  showNewLinkButton() {
    if (this.state.activeTab === '6') return 'inline-block'
    return 'none'
  }

  // OKRs
  addOKR() {
    const obj = this.state.OKRs
    obj.push({
      id: uuid.v4(),
      number: this.state.filteredOKRs.length + 1,
      teamID: this.state.team.id,
      KRs: [],
      created: new Date().getTime(),
      cycle: this.state.okrCycleFilter,
      status: 'draft',
    })
    this.setState({ OKRs: obj }, this.filterOKRs)
  }

  updateOKR(okr, property, value) {
    const objs = this.state.OKRs
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].id === okr.id) {
        objs[i][property] = value
        break
      }
    }
    this.setState({ OKRs: objs }, this.filterOKRs)
  }

  deleteOKR(okr) {
    const objs = this.state.OKRs
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].id === okr.id) {
        objs.splice(i, 1)
        break
      }
    }
    this.setState({ OKRs: objs }, this.filterOKRs)
  }

  addKR(okr) {
    const objs = this.state.OKRs
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].id === okr.id) {
        objs[i].KRs.push({
          id: uuid.v4(), number: objs[i].KRs.length + 1, description: '', ownerID: '', dependencies: [],
        })
        break
      }
    }
    this.setState({ OKRs: objs }, this.filterOKRs)
  }

  deleteKR(okr, kr) {
    const objs = this.state.OKRs
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].id === okr.id) {
        for (let j = 0; j < objs[i].KRs.length; j++) {
          if (objs[i].KRs[j].id === kr.id) {
            objs[i].KRs.splice(j, 1)
            break
          }
        }
      }
    }

    this.setState({ OKRs: objs }, this.filterOKRs)
  }

  updateKR(okr, kr, property, value) {
    const objs = this.state.OKRs
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].id === okr.id) {
        for (let j = 0; j < objs[i].KRs.length; j++) {
          if (objs[i].KRs[j].id === kr.id) {
            objs[i].KRs[j][property] = value
            break
          }
        }
      }
    }
    this.setState({ OKRs: objs }, this.filterOKRs)
  }

  assignKROwner(person) {
    this.updateKR(this.state.assignToOKR, this.state.assignToKR, 'ownerID', person.id)
    this.updateKR(this.state.assignToOKR, this.state.assignToKR, 'person', person)

    // if someone has a KR but isn't on the team, add them by default
    let isFound = false
    for (let i = 0; i < this.state.members.length; i++) {
      if (this.state.members[i].id === person.id) {
        isFound = true
        break
      }
    }
    if (!isFound) { this.addMember(person) }
  }

  openPeopleSearch(okr, kr) {
    this.setState({ openPersonSearch: true, assignToOKR: okr, assignToKR: kr }, () => {
      setTimeout(() => {
        this.setState({ openPersonSearch: false })
      }, 500)
    })
  }

  ownerName(kr) {
    if (kr.person) {
      return strings.trim(kr.person.name, 23)
    }
  }

  openOKRSearch(okr, kr) {
    this.setState({ openOKRSearch: true, assignToOKR: okr, assignToKR: kr }, () => {
      setTimeout(() => {
        this.setState({ openOKRSearch: false })
      }, 500)
    })
  }

  createKRDependency(okr, kr) {
    this.updateKR(this.state.assignToOKR, this.state.assignToKR, 'dependencies', [{ okrID: okr.id, krID: kr.id, type: 'upstream' }])

    // update the upstream OKR/KR

    db.getOKR(okr.id).then((okr) => {
      for (let j = 0; j < okr.KRs.length; j++) {
        if (okr.KRs[j].id === kr.id) {
          if (!okr.KRs[j].dependencies) okr.KRs[j].dependencies = []
          okr.KRs[j].dependencies.push({ okrID: this.state.assignToOKR.id, krID: this.state.assignToKR.id, type: 'downstream' })

          db.updateOKR(okr.id, okr)

          break
        }
      }
    })
  }

  filterOKRs(filter, value) {
    const filtered = []
    const okrs = this.state.OKRs

    let cycleFilter = this.state.okrCycleFilter
    if (filter === 'cycle') { cycleFilter = value }

    let statusFilter = this.state.okrStatusFilter
    if (filter === 'status') { statusFilter = value }

    this.setState({ okrCycleFilter: cycleFilter, okrStatusFilter: statusFilter })

    for (let i = 0; i < okrs.length; i++) {
      if (okrs[i].cycle === cycleFilter) { if (okrs[i].status === statusFilter || statusFilter === 'all') filtered.push(okrs[i]) }
    }

    this.setState({ filteredOKRs: filtered })
  }

  showNewOKRButton() {
    if (this.state.activeTab === 'OKRs') return 'inline-block'
    return 'none'
  }

  // photos
  photoUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 })
  }

  photoUploadProgress = (progress) => {
    this.setState({ progress })
  }

  photoUploadError = (error) => {
    this.setState({ isUploading: false })
    console.error(error)
  }

  photoUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false })
    storage.profilePhotos.child(filename).getDownloadURL().then((url) => this.addPhoto(url, false))
  }

  photoUploadSuccess2 = (filename) => {
    this.setState({ progress: 100, isUploading: false })
    storage.profilePhotos.child(filename).getDownloadURL().then((url) => {
      this.addPhoto(url, true)
      this.updateTeam('profilePhoto', url)
    })
  }

  addPhoto(url, isPrimary) {
    const obj = this.state.team
    if (!obj.photos) {
      obj.photos = []
    }

    const photo = {
      id: uuid.v4(), name: '', description: '', url, isPrimary,
    }

    obj.photos.push(photo)

    // remove the placeholder avatar if it's there
    for (let i = obj.photos.length - 1; i > -1; i--) {
      if (obj.photos[i].url.indexOf('/teamAvatar.png') > -1) { obj.photos.splice(i, 1) }
    }

    this.setState({ team: obj })
  }

  deletePhoto(photo) {
    const obj = this.state.team
    for (let i = 0; i < obj.photos.length; i++) {
      if (obj.photos[i].id === photo.id) {
        obj.photos.splice(i, 1)
        break
      }
    }
    this.setState({ team: obj })
    // todo, cleanup storage when photo is deleted
  }

  // hierarchy
  addSubTeam(team) {
    const { subTeams } = this.state
    subTeams.push(team)
    this.setState({ subTeams })
  }

  showNewSubTeamButton() {
    if (this.state.activeTab === '5') return 'block'
    return 'none'
  }

  moveTeam(parent) {
    this.setState({ newParentID: parent.id })
  }

  showAdmin() {
    if (this.props.user.isAdmin) return 'block'
    return 'none'
  }

  showStandardsSwitch() {
    if (this.props.user.isAdmin && this.state.activeTab === '1') return 'block'
    return 'none'
  }

  // delete
  showDeleteTeam() {
    if (this.state.subTeams.length === 0 && this.state.activeTab === '1' && !this.state.isLoading) { return 'inline-block' }
    return 'none'
  }

  deleteTeam() {
    const promises = []

    // remove member xrefs
    this.state.teamMembers.forEach((member) => {
      promises.push(db.deleteTeamMember(member.id))
    })

    // remove project xrefs
    this.state.teamProjects.forEach((project) => {
      promises.push(db.deleteTeamProject(project.id))
    })

    // remove OKRs
    this.state.teamOKRs.forEach((okr) => {
      promises.push(db.deleteOKR(okr))
    })

    Promise.all(promises).then(() => {
      db.deleteTeam(this.state.team.id).then(() => {
        this.setState({ modal: false })
        if (this.props.onDeleted) { this.props.onDeleted(this.state.team) }
      })
    })
  }

  // save
  saveTeam() {
    const { team } = this.state

    if (this.props.parentID) { team.parentID = this.props.parentID }
    if (this.state.newParentID) {
      team.parentID = this.state.newParentID
    }

    if (!team.created) {
      team.created = new Date().getTime()
      team.createdBy = this.props.user.person.name
    }
    if (!team.slug) {
      team.slug = getSanitisedSlugFromName(team.name)
    }
    team.modified = new Date().getTime()
    team.modifiedBy = this.props.user.person.name

    func.saveBlob(this.state.homePage, this.state.team.homePageBlobID).then((blobID) => {
      if (blobID) { team.homePageBlobID = blobID }

      db.updateTeam(team.id, team).then((result) => {
        if (this.props.onCreated) {
          team.id = result.id
          this.props.onCreated(team, this.state.members, this.state.projects, this.state.subTeams)
        }

        // update the search index
        // algolia.upsertTeam(team)

        const promises = []

        // add new members
        for (let i = 0; i < this.state.members.length; i++) {
          let isMemberFound = false
          for (let j = 0; j < this.state.teamMembers.length; j++) {
            if (this.state.members[i].id === this.state.teamMembers[j].personID) {
              isMemberFound = true
              break
            }
          }
          if (!isMemberFound) {
            const teamMember = { personID: this.state.members[i].id, teamID: team.id }
            promises.push(db.updateTeamMember(teamMember.id, teamMember))
            this.sendSlackMessage(this.state.members[i])
          }
        }

        // remove deleted members
        for (let k = 0; k < this.state.teamMembers.length; k++) {
          let isTeamMemberFound = false
          for (let l = 0; l < this.state.members.length; l++) {
            if (this.state.teamMembers[k].personID === this.state.members[l].id) {
              isTeamMemberFound = true
              break
            }
          }

          if (!isTeamMemberFound) { promises.push(db.deleteTeamMember(this.state.teamMembers[k].id)) }
        }

        // add new projects
        for (let m = 0; m < this.state.projects.length; m++) {
          let isProjectFound = false
          for (let n = 0; n < this.state.teamProjects.length; n++) {
            if (this.state.projects[m].id === this.state.teamProjects[n].projectID) {
              isProjectFound = true
              break
            }
          }
          if (!isProjectFound) {
            const teamProject = { projectID: this.state.projects[m].id, teamID: team.id }
            promises.push(db.updateTeamProject(teamProject.id, teamProject))
          }
        }

        // remove deleted projects
        for (var v = 0; v < this.state.teamProjects.length; v++) {
          let isTeamProjectFound = false
          for (var w = 0; w < this.state.projects.length; w++) {
            if (this.state.teamProjects[v].projectID === this.state.projects[w].id) {
              isTeamProjectFound = true
              break
            }
          }

          if (!isTeamProjectFound) { promises.push(db.deleteTeamProject(this.state.teamProjects[v].id)) }
        }

        // add / update OKRs
        this.state.OKRs.forEach((okr) => {
          okr.KRs.forEach((kr) => {
            delete kr.person
          })
          promises.push(db.updateOKR(okr.id, okr))
          // algolia.upsertOKR(okr)
        })

        // delete OKRs
        for (var v = 0; v < this.state.teamOKRs.length; v++) {
          let isOKRFound = false
          for (var w = 0; w < this.state.OKRs.length; w++) {
            if (this.state.teamOKRs[v] === this.state.OKRs[w].id) {
              isOKRFound = true
              break
            }
          }

          if (!isOKRFound) {
            promises.push(db.deleteOKR(this.state.teamOKRs[v]))
          }
        }

        Promise.all(promises).then(() => {
          // close the dialog
          this.setState({ modal: false })

          if (this.props.onUpdated) { this.props.onUpdated(team, this.state.members, this.state.projects, this.state.subTeams, this.state.OKRs, this.state.newParentID) }

          if (this.props.onCreated) { // reset for next new team
            this.setState({
              activeTab: '1',
              team: {
                name: '',
                description: '',
                askUs: '',
                confluence: '',
                photos: [],
                links: [],
              },
              teamMembers: [],
              members: [],
              projects: [],
              subTeams: [],
            })
          }

          telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'teamEditor', 'upsert')
        })
      })
    })
  }

  sendSlackMessage(person) {
    const text = 'You were added to a team on GoConnect.'

    const blocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `You were added to the ${this.state.team.name} team on GoConnect.\n\n`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: 'GoConnect is a community owned directory of people, teams and projects that makes it easier for GoTroops to find their way around. Please take a few moments to add or update to the profiles of the teams and projects you are a part of - add a description, links to useful files, OKRs, etc.!\n\n',
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: 'If you need GoConnect support, please visit our slack channel at #go-connect.\n\n',
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Click <https://www.gocnnct.com/team/${this.state.team.id}|here> to see the ${this.state.team.name} team profile.`,
        },
      },
    ]

    const { slackID } = person
    // slackID = 'U01C68P5D4P'; // testing

    slack.sendMessage(slackID, text, blocks, location.pathname)
  }

  isNewTeamSaved() {
    return this.props.parentID
  }

  buttonText() {
    if (this.props.team) return 'Edit'
    if (this.isNewTeamSaved()) return 'Add New Team'
  }

  getTeamURL() {
    return `https://gocnnct.com/teams/${this.state.team.slug}`
  }

  render() {
    const activeLink = {
      color: 'white',
      fontWeight: 600,
      fontSize: '12px',
    }
    const link = {
      color: '#434343',
      fontWeight: 400,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeTab = {
      backgroundColor: 'green',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: 'green',
    }
    const tab = {
      backgroundColor: '#efefef',
      marginLeft: '1px',
    }

    const icon = {
      fontSize: '17px',
      marginTop: '0px',
      color: '#000000',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }
    const iconTextArea = {
      fontSize: '17px',
      position: 'absolute',
      top: '20px',
      color: '#000000',
    }

    const inlineDiv = {
      display: 'inline-block',
    }

    return (
      <div style={this.props.isInline && inlineDiv}>
        {this.buttonText() && (
          <Button size="sm" color="secondary" onClick={this.toggleModal} style={{ padding: '10px' }}>
            {this.buttonText()}
          </Button>
        )}

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
          <ModalBody>
            <Row>
              <Col md="12">
                <Nav style={{ paddingTop: '17px' }}>
                  <NavItem style={(this.state.activeTab === '1' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '1' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: '1' }) }}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '3' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '3' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: '3' }) }}
                    >
                      Members
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'homePage' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'homePage' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'homePage' }) }}
                    >
                      Home Page
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '4' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '4' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: '4' }) }}
                    >
                      Projects
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '2' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '2' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: '2' }) }}
                    >
                      Photos
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === '5' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === '5' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: '5' }) }}
                    >
                      Subteams
                    </NavLink>
                  </NavItem>
                  <NavItem style={(this.state.activeTab === 'OKRs' ? activeTab : tab)}>
                    <NavLink
                      style={(this.state.activeTab === 'OKRs' ? activeLink : link)}
                      onClick={() => { this.setState({ activeTab: 'OKRs' }) }}
                    >
                      OKRs
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  style={{
                    borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green', paddingTop: '20px',
                  }}
                >
                  <TabPane tabId="1" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col md="5">
                        <label style={{ color: '#696969', cursor: 'pointer' }}>
                          <img src={this.state.team.profilePhoto} alt="profile" />

                          <FileUploader
                            hidden
                            accept="image/*"
                            name="photo"
                            randomizeFilename
                            storageRef={storage.profilePhotos}
                            onUploadStart={this.photoUploadStart}
                            onUploadError={this.photoUploadError}
                            onUploadSuccess={this.photoUploadSuccess2}
                            onProgress={this.photoUploadProgress}
                          />
                        </label>

                        <div
                          style={{
                            display: this.showStandardsSwitch(), fontSize: '12px', color: '#434343', marginTop: '10px', marginRight: '10px',
                          }}
                          title="Exclude this team for adherance to standards efforts."
                        >
                          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            <CustomInput
                              type="switch"
                              id="trackStandards"
                              name="customSwitch"
                              checked={this.state.team.excludeFromStandards}
                              onChange={(e) => this.updateTeam('excludeFromStandards', e.target.checked)}
                            />
                          </div>
                          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            Exclude from Standards Tracking
                          </div>
                        </div>

                      </Col>
                      <Col md="7">
                        <Row>
                          <Col md="12">
                            Name
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="What is your teams name?"
                                value={this.state.team.name}
                                onChange={(e) => this.updateTeam('name', e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Description
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={iconTextArea} className="nc-icon nc-badge" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="What is your teams high level goal or responsibility?"
                                type="textarea"
                                style={{ lineHeight: '23px', minHeight: '150px' }}
                                value={this.state.team.description}
                                onChange={(e) => this.updateTeam('description', e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Ask Us About
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-chat-33" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="What should people come to you for?" value={this.state.team.askUs} onChange={(e) => this.updateTeam('askUs', e.target.value)} />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Asana Link
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-globe" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Do you have a related Asana Project?" value={this.state.team.asana} onChange={(e) => this.updateTeam('asana', e.target.value)} />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            Confluence Link
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-globe" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Do you have a Confluence page?" value={this.state.team.confluence} onChange={(e) => this.updateTeam('confluence', e.target.value)} />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row style={{ display: this.showAdmin() }}>
                          <Col xs="12">
                            Email
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="What is your Gojek email?" value={this.state.team.email} onChange={(e) => this.updateTeam('email', e.target.value)} />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row style={{ display: this.showAdmin() }}>
                          <Col md="12">
                            Slack ID
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-badge" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="This is a number." value={this.state.team.slackID} onChange={(e) => this.updateTeam('slackID', e.target.value)} />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            URL Slug
                            {' '}
                            <br />
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ width: '50px' }}>
                                  <i style={icon} className="nc-icon nc-email-85" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="" value={this.state.team.slug} onChange={(e) => this.updateTeam('slug', e.target.value)} />
                            </InputGroup>
                            {this.state.team.slug && (
                              <div style={{ fontSize: '11px', color: '#898989' }}>
                                Team URL:
                                {' '}
                                {this.getTeamURL()}
                              </div>
                            )}
                          </Col>
                        </Row>
                        {/* <Row style={{ display: this.showAdmin() }}>
                          <Col md="12">
                            TPM Representative
                            <br />
                            <div
                              onClick={() => this.openTPMSearch()}
                              style={{
                                border: 'solid 1px #cdcdcd', borderRadius: '4px', paddingTop: '6px', paddingLeft: '10px', width: '100%', height: '41px', cursor: 'pointer',
                              }}
                            >
                              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {this.tpmCard()}
                              </div>
                              <div style={{
                                display: 'inline-block', marginLeft: '15px', verticalAlign: 'middle', color: '#666666',
                              }}
                              >
                                {this.state.tpm.name}
                              </div>
                              <div style={{ display: 'none' }}>
                                <PersonSearch label="Assign" isOpen={this.state.openTPMSearch} onPersonChosen={(p) => this.assignTPM(p)} />
                              </div>
                            </div>
                          </Col>
                        </Row> */}

                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="homePage" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col md="12">
                        Introduce your team - help readers understand your role and your scope.
                        {' '}
                        <br />
                        <br />
                        <TextEditor content={this.state.homePage} onChange={(e) => this.setState({ homePage: e })} />
                        <br />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      <Col sm="6">
                        <label style={{ color: '#696969', cursor: 'pointer' }}>
                          Upload a New Photo

                          <FileUploader
                            hidden
                            accept="image/*"
                            name="photo"
                            randomizeFilename
                            storageRef={storage.profilePhotos}
                            onUploadStart={this.photoUploadStart}
                            onUploadError={this.photoUploadError}
                            onUploadSuccess={this.photoUploadSuccess}
                            onProgress={this.photoUploadProgress}
                          />

                        </label>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.team.photos.map((photo, index) => (
                        <Col md="3" key={index}>
                          <img src={photo.url} style={{ marginBottom: '17px' }} alt="logo" />
                          <div style={{
                            display: 'inline-block', marginLeft: '10px', marginBottom: '16px', marginTop: '-8px',
                          }}
                          >
                            <ConfirmDialog
                              buttonText="Remove"
                              title="Remove Photo"
                              header="CONFIRM"
                              description="Are you sure you want to remove this photo from the teams profile?"
                              confirmed={() => this.deletePhoto(photo)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      {this.state.members.map((person, index) => (
                        <Col sm="3" key={index}>
                          <PersonCard person={person} hideExpander />
                          <div style={{ marginLeft: '10px', marginBottom: '16px', marginTop: '-8px' }}>
                            <ConfirmDialog
                              buttonText="Remove"
                              title="Remove Team Member"
                              header="CONFIRM"
                              description="Are you sure you want to remove this person from the team?"
                              confirmed={() => this.deleteMember(person)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="4" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      {this.state.projects.map((project, index) => (
                        <Col xs="6" sm="4" md="4" lg="3" key={index} style={{ color: '#898989' }}>
                          <ProjectCard project={project} />
                          <div style={{
                            display: 'inline-block', marginLeft: '10px', marginBottom: '16px', marginTop: '-8px',
                          }}
                          >
                            <ConfirmDialog
                              buttonText="Remove"
                              title="Remove Project"
                              header="CONFIRM"
                              description="Are you sure you want to remove this project from the teams profile?"
                              confirmed={() => this.deleteProject(project)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="5" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row>
                      {this.state.subTeams.map((team, index) => (
                        <Col sm="3" key={index}>
                          <TeamCard team={team} />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="OKRs" style={{ minHeight: '210px', paddingTop: '17px' }}>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs="6">
                        <Input
                          placeholder=""
                          type="select"
                          style={{ border: '1px solid #888888', fontSize: '11px' }}
                          value={this.state.okrCycleFilter}
                          onChange={(e) => this.filterOKRs('cycle', e.target.value)}
                        >
                          <option value="21h2">2021 H2</option>
                          <option value="22h1">2022 H1</option>
                          <option value="22h2">2022 H2</option>
                        </Input>
                      </Col>
                      <Col xs="6">
                        <Input
                          placeholder=""
                          type="select"
                          style={{ border: '1px solid #888888', fontSize: '11px' }}
                          value={this.state.statusFilter}
                          onChange={(e) => this.filterOKRs('status', e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="draft">Draft</option>
                          <option value="approved">Approved</option>
                          <option value="cancelled">Cancelled</option>
                          <option value="complete">Complete</option>
                        </Input>
                      </Col>
                    </Row>
                    {this.state.filteredOKRs.map((okr, index) => (
                      <Row
                        key={index}
                        style={{
                          padding: '10px', paddingTop: '20px', backgroundColor: tables.rowColor(index), borderTop: '1px solid #888888',
                        }}
                      >
                        <Col xs="1">
                          <input
                            type="text"
                            value={okr.number}
                            placeholder=""
                            onChange={(e) => this.updateOKR(okr, 'number', e.target.value)}
                            style={{
                              borderStyle: 'solid', borderColor: 'green', borderWidth: '1px', backgroundColor: 'white', padding: '6px', height: '28px', borderRadius: '6px', fontSize: '12px', width: '100%',
                            }}
                          />
                        </Col>
                        <Col sm="8">
                          <textarea
                            value={okr.description}
                            placeholder="objective"
                            onChange={(e) => this.updateOKR(okr, 'description', e.target.value)}
                            style={{
                              borderStyle: 'solid', borderColor: 'green', borderWidth: '1px', backgroundColor: 'white', padding: '6px', lineHeight: '17px', minHeight: '84px', borderRadius: '6px', fontSize: '12px', width: '100%',
                            }}
                          />
                        </Col>
                        <Col xs="3" style={{ fontSize: '11px' }}>

                          <Input
                            placeholder=""
                            type="select"
                            style={{ border: '1px solid green', fontSize: '11px' }}
                            value={okr.status}
                            onChange={(e) => this.updateOKR(okr, 'status', e.target.value)}
                          >
                            <option value="draft">Draft</option>
                            <option value="approved">Approved</option>
                            <option value="cancelled">Cancelled</option>
                            <option value="completed">Completed</option>
                          </Input>
                          <br />
                          <Input
                            placeholder=""
                            type="select"
                            style={{ border: '1px solid green', fontSize: '11px', width: '100%' }}
                            value={okr.cycle}
                            onChange={(e) => this.updateOKR(okr, 'cycle', e.target.value)}
                          >
                            <option value="21h2">2021 H2</option>
                            <option value="22h1">2022 H1</option>
                            <option value="22h2">2022 H2</option>
                          </Input>

                        </Col>

                        <Col xs="12">
                          {okr.KRs.map((kr, index) => (
                            <Row key={index} style={{ marginTop: '17px' }}>
                              <Col
                                xs="1"
                                style={{
                                  fontSize: '11px', color: 'green', textAlign: 'right', marginTop: '6px',
                                }}
                              >
                                {okr.number}
                                .
                              </Col>
                              <Col xs="1">
                                <input
                                  type="text"
                                  value={kr.number}
                                  placeholder="objective"
                                  onChange={(e) => this.updateKR(okr, kr, 'number', e.target.value)}
                                  style={{
                                    borderStyle: 'solid', borderColor: 'green', borderWidth: '1px', backgroundColor: 'white', padding: '6px', height: '28px', borderRadius: '6px', fontSize: '12px', width: '100%',
                                  }}
                                />
                              </Col>
                              <Col xs="10">
                                <textarea
                                  value={kr.description}
                                  placeholder="key result"
                                  onChange={(e) => this.updateKR(okr, kr, 'description', e.target.value)}
                                  style={{
                                    borderStyle: 'solid', borderColor: 'green', borderWidth: '1px', backgroundColor: 'white', padding: '6px', lineHeight: '17px', minHeight: '84px', borderRadius: '6px', fontSize: '12px', width: '100%',
                                  }}
                                />
                              </Col>
                              <Col xs="2" />
                              <Col xs="10" style={{ paddingTop: '5px' }}>
                                <textarea
                                  value={kr.notes}
                                  placeholder="notes"
                                  onChange={(e) => this.updateKR(okr, kr, 'notes', e.target.value)}
                                  style={{
                                    borderStyle: 'solid', borderColor: 'gray', borderWidth: '1px', backgroundColor: 'white', padding: '6px', lineHeight: '17px', minHeight: '84px', borderRadius: '6px', fontSize: '12px', width: '100%',
                                  }}
                                />
                              </Col>
                              <Col xs="2" />
                              <Col xs="10" style={{ fontSize: '11px', position: 'relative' }}>
                                {/* <div style={{ display: 'inline-block', width: '50%' }}>
                                  <div
                                    onClick={() => this.openPeopleSearch(okr, kr)}
                                    style={{
                                      cursor: 'pointer', borderStyle: 'solid', borderColor: 'green', borderWidth: '1px', borderRadius: '6px', fontSize: '12px', padding: '6px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'white', minHeight: '34px',
                                    }}
                                  >
                                    {this.ownerName(kr)}
                                  </div>
                                </div> */}
                                {/* <div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>
                                  <Button color="secondary" size="sm" onClick={() => this.openOKRSearch(okr, kr)}>Add Dependency</Button>
                                </div> */}
                                <div style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'top' }}>
                                  <Button color="danger" size="sm" onClick={() => this.deleteKR(okr, kr)}>Delete KR</Button>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </Col>

                        <Col xs="12" style={{ paddingTop: '11px' }}>
                          <hr />
                          <Row key={index} style={{ marginTop: '10px' }}>
                            <Col xs="12">
                              <div style={{ display: 'inline-block', fontSize: '11px', marginRight: '6px' }}>
                                <Button color="secondary" onClick={() => this.addKR(okr)}>Add Key Result</Button>
                              </div>
                              <div style={{ display: 'inline-block' }}>
                                <ConfirmDialog
                                  buttonText="Delete OKR"
                                  title="Delete OKR"
                                  header="CONFIRM"
                                  description="Are you sure you want to delete this OKR?"
                                  confirmed={() => this.deleteOKR(okr)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    ))}
                    {/* <div style={{ display: 'none' }}>
                      <OKRSearch isOpen={this.state.openOKRSearch} onKRChosen={(okr, kr) => this.createKRDependency(okr, kr)} />
                      <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignKROwner(p)} />
                    </div> */}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row style={{ width: '100%' }}>
              <Col sm={7}>
                <div style={{ display: this.showDeleteTeam(), marginLeft: '10px' }}>
                  <ConfirmDialog
                    buttonText="Delete"
                    title="Delete Team"
                    header="CONFIRM"
                    description="Are you sure you want to delete this team?"
                    confirmed={() => this.deleteTeam()}
                  />
                </div>
                {/* <div style={{ display: this.showNewMemberButton() }}>
                  <PersonSearch
                    onPersonChosen={this.addMember}
                    excludedObjectIDs={this.state.members.map((m) => m.id)}
                  />
                </div>
                <div style={{ display: this.showNewSubTeamButton() }}>
                  <div style={{ display: 'inline-block' }}>
                    <SubTeamEditor onCreated={this.addSubTeam} parentID={this.state.team.id} />
                  </div>
                  <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <TeamSearch buttonText="Move Team" dialogTitle="New Parent Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.moveTeam(t)} />
                  </div>
                </div>
                <div style={{ display: this.showNewRelatedProjectButton() }}>
                  <div style={{ display: 'inline-block' }}>
                    <ProjectSearch onProjectChosen={(p) => this.addRelatedProject(p)} />
                  </div>
                </div> */}
                <div style={{ display: this.showNewOKRButton() }}>
                  <Button onClick={() => this.addOKR()}>Add OKR</Button>
                </div>
                <div style={{ display: this.showNewLinkButton() }}>
                  <Button onClick={() => this.addLink()}>Add Link</Button>
                </div>
              </Col>
              <Col sm="5" style={{ textAlign: 'right' }}>
                <Button color="default" size="md" onClick={this.toggleModal}>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="fa fa-close" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>Cancel</div>
                </Button>
                <Button color="success" onClick={this.saveTeam} size="md">
                  <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <i style={navIcon} className="nc-icon nc-check-2" />
                  </div>
                  <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                    Save
                  </div>
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

TeamEditor = connect(mapStateToProps)(TeamEditor)
export default TeamEditor
