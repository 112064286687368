//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//db
import { db } from 'assets/firebase';

//date time
import ReactDatetime from "react-datetime";

// reactstrap components
import {
    Button,
    Modal, ModalBody, ModalFooter,
    Input,
    Row, Col,
  } from "reactstrap";

  //kahala
  import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

  
class MilestoneCard extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            milestone: {
                name: '',
                description: '',
                date: '',
                status: 'planned'
            },
        };

        if(this.props.milestone)
            this.state.milestone = this.props.milestone;
    }

    componentWillReceiveProps(nextProps){
        
        if(nextProps.projectID) {
            this.setState({projectID: nextProps.projectID});
        }
        if(nextProps.teamID) {
            this.setState({teamID: nextProps.teamID});
        }
    }

    componentDidMount() {
         
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x); 
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    updateMilestone(property, value) {
        var obj = this.state.milestone;
        obj[property] = value;
        this.setState({milestone: obj});
    }

    saveMilestone() {
    
        var milestone = this.state.milestone;
        if(this.props.projectID)
            milestone.projectID = this.props.projectID;
        if(this.props.teamID)
            milestone.teamID = this.props.teamID;

        if(!milestone.created)
            milestone.created = new Date().getTime();
            milestone.modified = new Date().getTime();
        
        db.updateMilestone(milestone.id, milestone).then((result) => {

            if(this.props.onCreated) { 

                milestone.id = result.id;

                this.props.onCreated(milestone);

                this.setState(
                    {
                        milestone:{
                        name: '',
                        description: '',
                        date: '',
                        status: 'planned'
                    }
                });

            } else {

                this.props.onUpdated(this.state.milestone);
            }

            this.handleClose("modal");
        });
    }

    deleteMilestone() {

        db.deleteMilestone(this.state.milestone.id).then(() => {
            this.setState({modal: false}, () => {
                this.props.onDeleted(this.state.milestone.id);
            });
        });
    
    }

    showCard() {
        if(this.state.milestone.id) 
            return "block"
        else 
            return "none";
    }

    showButton() {
        if(!this.state.milestone.id) 
            return "block"
        else 
            return "none";
    }
    
    getBadgeClass() {
        if(this.state.milestone.status === 'planned')
            return "timeline-badge secondary";
        else if (this.state.milestone.status === 'completed')
            return "timeline-badge success";
        else if (this.state.milestone.status === 'atRisk')
            return "timeline-badge warning";
        else if (this.state.milestone.status === 'overdue')
            return "timeline-badge danger";
    }

    getBadgeIcon() {
        if(this.state.milestone.status === 'planned')
            return "nc-icon nc-map-big";
        else if (this.state.milestone.status === 'completed')
            return "nc-icon nc-check-2";
        else if (this.state.milestone.status === 'atRisk')
            return "nc-icon nc-support-17";
        else if (this.state.milestone.status === 'overdue')
            return "nc-icon nc-ambulance";
    }

    getContent() {
        if(this.state.milestone.id) {
            return <li className="timeline-inverted"  onClick={() => this.handleClickOpen("modal")}>
                        <div className={this.getBadgeClass()} style={{display:this.showCard()}}>
                            <i className={this.getBadgeIcon()} />
                        </div>
                        <div className="timeline-panel" style={{display:this.showCard()}}>
                            <div className="timeline-heading" style={{fontSize:"16px", marginBottom:"3px"}}>
                                {/*}
                                <Badge color="success" pill>
                                    {this.state.milestone.name}
                                </Badge>*/}
                                {this.state.milestone.name}
                            </div>
                            <div className="timeline-body">
                                <p>
                                    {this.state.milestone.description}
                                </p>
                                <div style={{fontSize:"12px", color:"green", fontWeight:500, marginTop:"3px"}}>
                                    {this.state.milestone.date}
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.getModal()}
                        </div>
                    </li> 
        } else {
            return <div>
                <Button 
                    size="sm"
                    onClick={() => this.handleClickOpen("modal")}>
                    Add Milestone
                </Button>
                {this.getModal()}
            </div>
        }
    }

    getModal() {
        return <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="8" >
                                Name<br />
                                <Input placeholder="Name" value={this.state.milestone.name} onChange={(e) => this.updateMilestone("name", e.target.value)} />
                            </Col>
                            <Col xs="12" sm="4">
                                Date<br />
                                <ReactDatetime
                                    value={this.state.milestone.date}
                                    dateFormat="YYYY-MM-DD" timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        className: "form-control",
                                        placeholder: "",
                                    }}
                                    onChange={(e) => this.updateMilestone("date", e.format('YYYY-MM-DD'))} 
                                />
                            </Col>
                            <Col xs="12" sm="8">
                                <br />Description<br />
                                <Input placeholder="Description" 
                                    type="textarea" style={{lineHeight:"14px", minHeight:"100px", padding:"10px", fontSize:"12px"}} 
                                    value={this.state.milestone.description} 
                                    onChange={(e) => this.updateMilestone("description", e.target.value)} 
                                />
                            </Col>
                            <Col xs="12" sm="4" >
                                <br />Status<br />
                                <Input placeholder="Metric" 
                                    type="select" 
                                    value={this.state.milestone.status} onChange={(e) => this.updateMilestone("status", e.target.value)} >
                                    
                                    <option value="planned">Planned</option>
                                    <option value="atRisk">At Risk</option>
                                    <option value="overdue">Overdue</option>
                                    <option value="completed">Complete</option>
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="5" style={{textAlign:"left"}}>
                                
                                <ConfirmDialog 
                                    buttonText="Delete"
                                    title="Delete Milestone" 
                                    header="CONFIRM" 
                                    description="Are you sure you want to delete this milestone?" 
                                    confirmed={() => this.deleteMilestone()} /> 
                            </Col>
                            <Col xs="7" style={{textAlign:"right"}}>
                                <Button color="secondary"
                                    onClick={() => this.handleClose("modal")} >
                                    Cancel
                                </Button>
                                <Button 
                                    onClick={() => {
                                        this.saveMilestone();
                                        this.handleClose("modal");
                                    }}
                                    color="success">
                                    Save
                                </Button>
                            </Col>
                        </Row>

                        
                    </ModalFooter>
                </Modal>
    }
    

    render() {

        return this.getContent();
    }
  }
  
const mapStateToProps = state => {
  return state;
}

MilestoneCard = connect(mapStateToProps)(MilestoneCard);
export default MilestoneCard;