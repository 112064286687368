
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, 
     Row, Col } from "reactstrap";

class Proposal extends React.Component {
  render() {

    const cardHeader = {
      color: "green",
      marginBottom:"0px"
    };
    const sectionHeader = {
      marginTop:"20px",
      marginBottom:"6px"
    }
    const quotation = {
      fontStyle:"italic"
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
                <h3 style={sectionHeader}>Requirements</h3>
            </Col>
          </Row>
          <Row>
            <Col md="6">
                <Card>
                    <CardHeader >
                        <h6 style={cardHeader}>Improve Productivity</h6>
                    </CardHeader>
                    <CardBody>
                      Get new developers up-to-speed more quickly.
                      Help existing developrs stay on top of what's changing.
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                    <CardHeader>
                        <h6 style={cardHeader}>Standarize Everything</h6>
                    </CardHeader>
                    <CardBody>
                      Common approaches to architecture, deployments, monitoring, telemetry, 
                      personalization, shared libraries, etc.
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                    <CardHeader>
                        <h6 style={cardHeader}>Reduce Costs</h6>
                    </CardHeader>
                    <CardBody>
                      With 1,000 developers, a 10% productivity boost is worth 100 
                      developers and standardization means fewer mistakes and less 
                      downtime.
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                    <CardHeader>
                        <h6 style={cardHeader}>Engineering Community</h6>
                    </CardHeader>
                    <CardBody>
                      Create an engineering culture that recognizes and rewards both 
                      innovative new ideas and contributions outside of ones immediate team.
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
                <h3 style={sectionHeader}>Current State</h3>
                The following quotes from HoE's are paraphrased. <br /><br />
            </Col>
          </Row>
          <Row>
            <Col md="6">
                <Card>
                    <CardBody style={quotation}>
                      "Getting developers started isn't too bad but it takes six months to get them up-to-speed with best practices."
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                  <CardBody style={quotation}>
                      "Documentation is pretty simple, it's not very robust. There are no best practices."
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
              <Card>
                  <CardBody style={quotation}>
                      "Our platform is not mature. It's still early days."
                      <br /><br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
              <Card>
                  <CardBody style={quotation}>
                      "Wikis that are incomplete, quickly out of date and thought of as a chore."
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
              <Card>
                  <CardBody style={quotation}>
                      "Wikis are organized using opaque code names, making it hard to navigate."
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
              <Card>
                  <CardBody style={quotation}>
                      "Devs are completely reliant on tribal knowledge."
                      <br /><br /><br />
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
              <Card>
                  <CardBody style={quotation}>
                      "We need architectural patterns, samples for common tasks, overall onboarding and documentation."
                      <br /><br />
                    </CardBody>
                </Card>
            </Col>
            
          </Row>
          <Row>
            <Col md="12">
              <h3 style={sectionHeader}>Proposal</h3>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div>    
                <br />
                <h5 style={{color:"green"}}>
                  A rationalized, professionally organized and documented developer platform would significantly improve productivity for new and experienced developers.
                </h5>
                <br />
                <ul>
                  <li>Modeled after the platforms that AWS, Google, Microsoft, Stripe, etc. provide to developers.</li>
                  <li>Organized with devs in mind - the rationalized platform (don’t ship your org chart).</li>
                  <li>Acts as a logical construct and forcing function to create alignment between engineering teams.</li>
                  <li>Encourages dev teams to think of themselves as premium platform providers with SLAs, support processes, customer sat measurements, etc.</li>
                  <li>Beautifully designed, communicating to devs that it is officially sanctioned and something they can bet on.</li>
                  <li>Matures the platform so as to set us up for more future third party integrations.</li>
                  <li>Is a place place to create a community for engineers (e.g. profiles, skills, case studies).</li>
                  <li>Is a place for a community driven repository of libraries, snippets, services, etc.</li>                  
                </ul>
                <br />
                Like TDD, drafting the portal presence should be the first part of designing a platform component. Nothing at Gojek should be considred shipped without shipping on the platform portal.
                <br /><br />
                Like using Stripe, Firebase, AWS, etc. I should be able to setup an account, understand the API, 
                prototype something meaningful, test and deploy it in one day.
                <br /><br />
                Professional developer platforms have portals and documentation that are are organized 
                by what the developers needs to do, not necessarily by the engineering group. 
                They are started with the user (i.e. developer) in mind.
                <br /><br />
                
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h3 style={sectionHeader}>Content</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card>
                <CardHeader >
                    <h6 style={cardHeader}>Core Content</h6>
                </CardHeader>
                <CardBody>
                  <ul>
                    <li>Documentation </li>
                    <ul>
                        <li>Building a Web Service</li>
                        <li>Building an Android App</li>
                        <li>Building an iOS App</li>
                        <li>etc.</li>
                    </ul>
                    <li>
                        API Documentation 
                    </li>
                    <li>Sample code in Java, Swift, GoLang, etc.</li>
                  </ul>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardHeader >
                    <h6 style={cardHeader}>Learning</h6>
                </CardHeader>
                <CardBody>
                  <ul>
                    <li>Cookbooks / Recipes </li>
                    <ul>
                        <li>your first micro service</li>
                        <li>your first Android/iOS feature</li>
                        <li>your first ML model</li>
                    </ul>
                    <li>Brown Bag Events</li>
                    <li>Formal Training Options</li>
                  </ul>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardHeader >
                    <h6 style={cardHeader}>Community</h6>
                </CardHeader>
                <CardBody>
                  <ul>
                      <li>Developer Profiles </li>
                      <ul>
                          <li>My Profile</li>
                          <li>Skill Sets</li>
                          <li>Project Associations</li>
                          <li>Success Stories</li>
                          <li>etc.</li>
                      </ul>
                      <li>Team Profiles</li>
                      <ul>
                          <li>Team Members</li>
                          <li>Projects</li>
                          <li>Links</li>
                      </ul>
                      <li>News</li>
                  </ul>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
                
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Proposal;
