import React from 'react';

import { dateTime, tables } from 'assets/utils';
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { Row, Col, Table } from "reactstrap";

export default function JournalEntriesTable({year, yearlyJournalEntries, totalYearlyTime, totalMonthlyTime, getTypeDescriptor, deleteActivity, getHTML}) {
  return (
    <div>
        <Row style={{marginTop:"50px", marginBottom:"5px"}}>
            <Col xs="6">
                <h6>{year}</h6>
            </Col>
            <Col xs="6" style={{textAlign:"right", fontSize:"12px", color:"green"}}>
                Total Yearly Time Invested: <b>{dateTime.toHoursAndMinutes(totalYearlyTime)}</b>
            </Col>
        </Row>

        {yearlyJournalEntries.length > 0 && yearlyJournalEntries.map((entries, month) => (
            entries.length > 0 && <MonthlyEntries 
                key = {11-month}
                monthName = {dateTime.months[11-month]}
                entries = {entries}
                totalMonthlyTime = {totalMonthlyTime[11-month]}
                getTypeDescriptor = {getTypeDescriptor}
                deleteActivity = {(e) => deleteActivity(e)}
                getHTML = {getHTML}
            />
        ))}

        <hr/>
    </div>
  )
}

function MonthlyEntries({monthName, entries, totalMonthlyTime, getTypeDescriptor, deleteActivity, getHTML}) {
  return (
    <>
        <Row style={{marginTop:"50px", marginBottom:"5px"}}>
            <Col xs="6">
                <h6>{monthName}</h6>
            </Col>
            <Col xs="6" style={{textAlign:"right", fontSize:"12px", color:"green"}}>
                Total Monthly Time Invested: <b>{dateTime.toHoursAndMinutes(totalMonthlyTime)}</b>
            </Col>
        </Row>
        <Table style={{width:"100%", margin:"0px"}}>
            <thead>
                <tr style={{fontSize:"11px", fontWeight:"700"}}>
                    <td></td>
                    <td>Date</td>
                    <td>Resource</td>
                    <td>Description</td>
                    <td style={{textAlign:"right"}}>Time Spent</td>
                </tr>
            </thead>
            <tbody>
                {entries.map((entry, index) => (
                    <tr key={index} style={{backgroundColor:tables.rowColor(index), fontSize:"12px"}}>
                        <td style={{paddingLeft:"20px"}}>
                            <ConfirmDialog 
                                title="Delete Entry" 
                                header="CONFIRM" 
                                description="Are you sure you want to delete this entry?" 
                                confirmed={() => deleteActivity(entry)} /> 
                        </td>
                        <td style={{minWidth:"90px"}}>{entry.learningDate}</td>
                        <td>{getTypeDescriptor(entry.resource)}</td>
                        <td>{getHTML(entry.learningNotes)}</td>
                        <td style={{minWidth:"90px", textAlign:"right"}}>{entry.timeSpent} minutes</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <hr style={{margin:"0px"}}/>
    </>
  )
}
