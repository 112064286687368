import React from 'react'

class OrgChart extends React.Component {
  constructor(props) {
    super(props)

    this.onCollapse = this.onCollapse.bind(this)
    this.onExpand = this.onExpand.bind(this)
    this.onExpanded = this.onExpanded.bind(this)

    const { node } = this.props
    node.isExpanded = false

    if (!node.children) {node.children = []}

    this.state = {
      node,
      showOKRs: false,
    }
  }

  componentDidMount() {
    if (this.props.showOKRs) {
      this.setState({ showOKRs: true })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.node !== this.props.node) {
      const { node } = this.props

      if (!node.children) {
        node.children = []
      }

      // This is a HACK. Somehow, some of the children nodes were missing if we didn't check the children's length
      if (node.children.length > prevProps.node.children.length) {
        this.setState({ node })
      }
    }

    if (prevProps.showOKRs !== this.props.showOKRs) {
      this.setState({ showOKRs: this.props.showOKRs })
    }
  }

  onExpand() {
    const { node } = this.state
    if (node.preload) {
      node.children = node.preload
      delete node.preload
    } else if (node.children.length > 0) {
      node.preload = node.children
      node.children = []
    }

    this.setState({ node })

    if (this.props.onExpanded) {this.props.onExpanded(node)}
  }

  onExpanded(node) {
    if (this.props.onExpanded) {this.props.onExpanded(node)}
  }

  onParentChanged() {
    if (this.props.onParentChanged) {this.props.onParentChanged()}
  }

  onDeleted(node) {
    if (this.props.onDeleted) {
      this.props.onDeleted(node)
    }
  }

  onCollapse() {
    const { node } = this.state
    if (node.preload) {
      node.children = node.preload
      delete node.preload
    } else if (node.children.length > 0) {
      node.preload = node.children
      node.children = []
    }

    this.setState({ node })
  }

  showLines() {
    if (this.state.node.children.length > 0) return 'block'
    return 'none'
  }

  showExpandIcon() {
    if (this.state.node.preload) {
      if (this.state.node.preload.length > 0) return 'block'
      return 'none'
    }
    return 'none'
  }

  showCollapseIcon() {
    if (this.state.node.children.length > 0) return 'block'
    return 'none'
  }

  render() {
    const Template = this.props.template

    const centerDiv = {
      transform: `translate(${50}%, ${0}%)`,
      width: '50%',
      margin: 'auto',
      padding: '10px',
      paddingBottom: '0px',
    }

    const navIcon = {
      marginLeft: '80px',
      width: '100%',
      textAlign: 'center',
    }

    const branch = {
      height: '2px',
      background: 'linear-gradient(to right, #efefef 0%, green 50%, #efefef 100%)',
      width: '100%',
      display: this.showLines(),
      marginLeft: '80px',
    }

    return (
      <div>
        <style>
          {
            `
                      .navIcon {
                        color: #898989;
                        fontSize: 34px;
                        cursor: pointer;
                      }
                      .navIcon:hover {
                        color: green;
                      }
                      `
          }
        </style>

        <table style={{ border: 'none', padding: '10px' }}>
          <tbody>
            <tr>
              <td colSpan={this.state.node.children.length}>
                <div style={centerDiv}>
                  <Template
                    nodeData={this.state.node}
                    ref={this.state.node.id}
                    showOKRs={this.state.showOKRs}
                    onParentChanged={() => this.onParentChanged()}
                    onDeleted={(obj) => this.onDeleted(obj)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={this.state.node.children.length}>
                <div style={navIcon}>
                  <i style={{ display: this.showExpandIcon() }} className="navIcon fas fa-angle-down" onClick={this.onExpand} />
                  <i style={{ display: this.showCollapseIcon() }} className="navIcon fas fa-angle-up" onClick={this.onCollapse} />
                </div>
                <div style={branch} />
              </td>
            </tr>
            <tr>
              {this.state.node.children.map((node, index) => (
                <td key={index} style={{ verticalAlign: 'top' }}>
                  <OrgChart
                    node={node}
                    template={this.props.template}
                    onExpanded={this.onExpanded}
                    showOKRs={this.state.showOKRs}
                    onParentChanged={() => this.onParentChanged()}
                    onDeleted={(obj) => this.onDeleted(obj)}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default OrgChart

