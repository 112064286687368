import React from 'react'
import { Link } from 'react-router-dom'
import {
  Col,
  Row,
} from 'reactstrap'

import pageNotFound from '../../assets/img/pageNotFound.png'

export default function PageNotFound({ setLandingPageView }) {
  return (
    <Row>
      <Col
        xs="12"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
          textAlign: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <img
            src={pageNotFound}
            alt="Page Not Found"
            style={{
              height: '200px',
            }}
          />
        </div>
        <div>
          <p>
            The page you were looking for doesn’t exist.
            <br />
            Return to the
            {' '}
            <Link
              to="/"
              style={{ fontWeight: 'bold', color: 'green', cursor: 'pointer' }}
              onClick={() => setLandingPageView()}
            >
              home page
            </Link>
            .
          </p>
        </div>
      </Col>
    </Row>
  )
}
