
import StandUpFor from "assets/img/Appreciate/ValuesWall/StandUpFor.json";
import BeFast from "assets/img/Appreciate/ValuesWall/BeFast.json";
import CriticismGift from "assets/img/Appreciate/ValuesWall/CriticismGift.json";
import Collaborate from "assets/img/Appreciate/ValuesWall/Collaborate.json";
import Empower from "assets/img/Appreciate/ValuesWall/Empower.json";
import PutCustomerFirst from "assets/img/Appreciate/ValuesWall/PutCustomerFirst.json";
import ShootForGreatness from "assets/img/Appreciate/ValuesWall/ShootForGreatness.json";
import CommunicateWithPurpose from "assets/img/Appreciate/ValuesWall/CommunicateWithPurpose.json";
import ItsNotAboutYou from "assets/img/Appreciate/ValuesWall/ItsNotAboutYou.json";
import AlwaysBePrepared from "assets/img/Appreciate/ValuesWall/AlwaysBePrepared.json";
import BecomeAScientist from "assets/img/Appreciate/ValuesWall/BecomeAScientist.json";
import EarnYourTitle from "assets/img/Appreciate/ValuesWall/EarnYourTitle.json";

import ImageStandUpFor from "assets/img/Appreciate/ValuesProfile/StandUpFor.json";
import ImageBeFast from "assets/img/Appreciate/ValuesProfile/BeFast.json";
import ImageCriticismGift from "assets/img/Appreciate/ValuesProfile/CriticismGift.json";
import ImageCollaborate from "assets/img/Appreciate/ValuesProfile/Collaborate.json";
import ImageEmpower from "assets/img/Appreciate/ValuesProfile/Empower.json";
import ImagePutCustomerFirst from "assets/img/Appreciate/ValuesProfile/PutCustomerFirst.json";
import ImageShootForGreatness from "assets/img/Appreciate/ValuesProfile/ShootForGreatness.json";
import ImageCommunicateWithPurpose from "assets/img/Appreciate/ValuesProfile/CommunicateWithPurpose.json";
import ImageItsNotAboutYou from "assets/img/Appreciate/ValuesProfile/ItsNotAboutYou.json";
import ImageAlwaysBePrepared from "assets/img/Appreciate/ValuesProfile/AlwaysBePrepared.json";
import ImageBecomeAScientist from "assets/img/Appreciate/ValuesProfile/BecomeAScientist.json";
import ImageEarnYourTitle from "assets/img/Appreciate/ValuesProfile/EarnYourTitle.json";

let valuesList =
[
  {
    id: "12",
    title: "It's Not About You",
    description: "Puts the company above themselves, is obsessed with customer problems (not personal problems) and has a purpose beyond personal success.",
    image: ItsNotAboutYou,
    png: ImageItsNotAboutYou
  },
  {
    id: "13",
    title: "Stand Up For What You Believe In",
    description: "Says what they mean, has courage to disagree and has a strong moral compass.",
    image: StandUpFor,
    png: ImageStandUpFor
  },
  {
    id: "14",
    title: "Collaborate With Compassion",
    description: "Is a pleasure to work with, support others in areas beyond their scope and considers how their actions affect others.",
    image: Collaborate,
    png: ImageCollaborate
  },
  {
    id: "15",
    title: "Be Fast And Fearless",
    description: "Takes calculated risks, values failures as much as successes and has strong sense of urgency.",
    image: BeFast,
    png: ImageBeFast
  },
  {
    id: "16",
    title: "Earn Your Title",
    description: "Walks the talk, gets down in the trenches and trusts their team.",
    image: EarnYourTitle,
    png: ImageEarnYourTitle
  },
  {
    id: "17",
    title: "Become A Scientist",
    description: "Follows the numbers intensely, finds solutions in unexpected places, learns independently and share knowledge.",
    image: BecomeAScientist,
    png: ImageBecomeAScientist
  },
  {
    id: "18",
    title: "Always Be Prepared",
    description: "Does their homework, plans out every scenario and takes action to reduce risk.",
    image: AlwaysBePrepared,
    png: ImageAlwaysBePrepared
  },
  {
    id: "19",
    title: "Criticism Is A Gift",
    description: "Actively seeks feedback from others, genuinely acts on it, gives helpful, unprompted feedback to others.",
    image: CriticismGift,
    png: ImageCriticismGift
  },
  {
    id: "20",
    title: "Communicate With Purpose",
    description: "States objectives in every interaction, focuses on what is actionable, aligns early and consistently.",
    image: CommunicateWithPurpose,
    png: ImageCommunicateWithPurpose
  },
  {
    id: "21",
    title: "Shoot For Greatness",
    description: "Goes the extra mile, thinks big and loves to challenge themselves.",
    image: ShootForGreatness,
    png: ImageShootForGreatness
  },
  {
    id: "22",
    title: "Put Customers First",
    description: "Keeps a customer centric approach at all times and puts customers’ needs at the center of decision making.",
    image: PutCustomerFirst,
    png: ImagePutCustomerFirst
  },
  {
    id: "23",
    title: "Empower the Small",
    description: "Shows empathy, treats people with respect, encourages people to develop strengths and minimise weaknesses.",
    image: Empower,
    png: ImageEmpower
  }
];

export default valuesList;