import React from "react";

import { db } from "assets/firebase";

import { 
    Button, 
    Row, Col,
    Input, InputGroup, 
    Modal, ModalBody,
    NavItem, NavLink,
    Spinner
} from "reactstrap";

import CSVReader from 'react-csv-reader'

class LearningJournalAdmin extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            isLoading: false,
            year: 2022,
            month: String(String(new Date().getMonth()+1)),
            data: [],
            fileType: '',
            rowsImported: 0,
            isUploadComplete: false,
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
            fileType: '',
            month: String(new Date().getMonth()+1),
            isUploadComplete: false
        });
    }

    onFileLoaded(data) {

        var fileType = '';
        if(data[0].email_lil) {
            fileType = 'linkedIn';
        } else if (data[0].email_ft) {
            fileType = 'functionalTraining';
        } else if (data[0].email_gendev) {
            fileType = 'generalLearning';
        } else if (data[0].email_produgie) {
            fileType = 'produgie';
        } else if (data[0].email_ec) {
            fileType = 'english';
        } 

        this.setState({isLoading: true, data: []}, () => {
            this.setState({data: data}, () => {
                this.setState({
                    isLoading: false,
                    fileType,
                    isUploadComplete: false
                });
            });
        });
    }

    importLinkedInLogs() {
        const {data, month, year} = this.state;

        if(data.length > 0) {

            this.setState({isLoading: true});
            var promises = [];
            var rows = [];

            db.getJournalEntriesByMonthYearAuthor(month, year, 'linkedInImport').then(entries => {
               
                promises = [];

                entries.forEach(entry => { 
                    promises.push(db.deleteLearningJournal(entry.id));
                });

                console.log('deleting ' + promises.length + ' existing records');
                Promise.all(promises).then(() => {

                    promises = [];

                    for(var i = 0; i < data.length; i++) {

                        if(data[i].email_lil && data[i].employee_id && data[i].employee_id !== "null") {
                            const row = {
                                email: data[i].email_lil.toLowerCase(),
                                timeSpent: Number((parseFloat(data[i].learning_hours_lil)*60).toFixed(2)),
                                authorID: 'linkedInImport',
                                learningDate: this.state.year + '-' + String(month).padStart(2, '0') + '-01',
                                resource: "linkedIn",
                                created: new Date().getTime(),
                                type: 'learning',
                                month: String(month),
                                year: year,
                            }
        
                            if(row.timeSpent > 0) {
                                rows.push(row);
                                promises.push(db.getPersonByEmail(row.email));
                            }
                        }
                    }

                    Promise.all(promises).then((results) => {
                        
                        promises = [];

                        rows.forEach((row) => {
                            if(row) {
                                for(var i = 0; i < results.length; i++) {
                                    if(results[i]) {
                                        if(row.email.toLowerCase() === results[i].email.toLowerCase()) {
                                            row.personID = results[i].id;
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        for(var j = rows.length -1; j > -1; j--) {
                            if(!rows[j] || !rows[j].personID) {
                                rows.splice(j,1);
                            }
                        }

                        rows.forEach(row => {
                            delete row.email;
                            promises.push(db.updateLearningJournal(row.id, row));
                        })

                        Promise.all(promises).then(() => {
                            this.setState({
                                isLoading: false,
                                month: String(new Date().getMonth()+1),
                                fileType: '',
                                isUploadComplete: true,
                                rowsImported: promises.length
                            });
                        })
                    })
                })
            });
        }
    }

    importProdugieLogs() {
        const {data, month, year} = this.state;

        if(data.length > 0) {

            this.setState({isLoading: true});
            var promises = [];
            var rows = [];

            db.getJournalEntriesByMonthYearAuthor(month, year, 'produgieImport').then(entries => {
               
                promises = [];

                entries.forEach(entry => { 
                    promises.push(db.deleteLearningJournal(entry.id));
                });

                console.log('deleting ' + promises.length + ' existing records');
                Promise.all(promises).then(() => {

                    promises = [];

                    for(var i = 0; i < data.length; i++) {

                        const dates = data[i].classdate_produgie && data[i].classdate_produgie.split(" ")[1];
                        const learningDates = dates && dates.split(/[,/-]+/);

                        if(data[i].email_produgie && data[i].employee_id && data[i].employee_id !== "null") { 
                            for(let count = 0; count < data[i].numberofsession_produgie; count++) {
                                if(learningDates[count] !== "") {
                                    const row = {
                                        email: data[i].email_produgie.toLowerCase(),
                                        timeSpent: Number(data[i].session_min_produgie),
                                        authorID: 'produgieImport',
                                        learningDate: year + '-' + String(month).padStart(2, '0') + '-' + learningDates[count].padStart(2, '0'),
                                        resource: "produgie",
                                        created: new Date().getTime(),
                                        type: 'learning',
                                        month: String(month),
                                        year: year,
                                        learningNotes: data[i].classname_produgie
                                    }

                                    if(row.timeSpent > 0) {
                                        rows.push(row);
                                        promises.push(db.getPersonByEmail(row.email));
                                    }
                                }
                            }
                        }
                    }

                    Promise.all(promises).then((results) => {

                        promises = [];

                        rows.forEach((row) => {
                            if(row) {
                                for(var i = 0; i < results.length; i++) {
                                    if(results[i]) {
                                        if(row.email.toLowerCase() === results[i].email.toLowerCase()) {
                                            row.personID = results[i].id;
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        for(var j = rows.length -1; j > -1; j--) {
                            if(!rows[j] || !rows[j].personID) {
                                rows.splice(j,1);
                            }
                        }

                        rows.forEach(row => {
                            delete row.email;
                            promises.push(db.updateLearningJournal(row.id, row));
                        })

                        Promise.all(promises).then(() => {
                            this.setState({
                                isLoading: false,
                                month: String(new Date().getMonth()+1),
                                fileType: '',
                                isUploadComplete: true,
                                rowsImported: promises.length
                            });
                        })
                    })
                })
            });
        }
    }

    importEnglishCourseLogs() {
        const {data, month, year} = this.state;

        if(data.length > 0) {

            this.setState({isLoading: true});
            var promises = [];
            var rows = [];

            db.getJournalEntriesByMonthYearAuthor(month, year, 'englishCourseImport').then(entries => {
               
                promises = [];

                entries.forEach(entry => { 
                    promises.push(db.deleteLearningJournal(entry.id));
                });

                console.log('deleting ' + promises.length + ' existing records');
                Promise.all(promises).then(() => {

                    promises = [];

                    for(var i = 0; i < data.length; i++) {

                        const dates = data[i].classdate_ec && data[i].classdate_ec.split(" ")[1];
                        const learningDates = dates && dates.split(/[,/-]+/);
                        
                        if(data[i].email_ec && data[i].employee_id && data[i].employee_id !== "null") { 
                            for(let count = 0; count < data[i].numberofsession_ec; count++) {
                                if(learningDates[count] !== "") {
                                    const row = {
                                        email: data[i].email_ec.toLowerCase(),
                                        timeSpent: Number(data[i].sessionduration_ec)*60,
                                        authorID: 'englishCourseImport',
                                        learningDate: year + '-' + String(month).padStart(2, '0') + '-' + learningDates[count].padStart(2, '0'),
                                        resource: "english",
                                        created: new Date().getTime(),
                                        type: 'learning',
                                        month: String(month),
                                        year: year,
                                        learningNotes: data[i].classname_ec
                                    }

                                    if(row.timeSpent > 0) {
                                        rows.push(row);
                                        promises.push(db.getPersonByEmail(row.email));
                                    }
                                }
                            }
                        }
                    }

                    Promise.all(promises).then((results) => {

                        promises = [];

                        rows.forEach((row) => {
                            if(row) {
                                for(var i = 0; i < results.length; i++) {
                                    if(results[i]) {
                                        if(row.email.toLowerCase() === results[i].email.toLowerCase()) {
                                            row.personID = results[i].id;
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        for(var j = rows.length -1; j > -1; j--) {
                            if(!rows[j] || !rows[j].personID) {
                                rows.splice(j,1);
                            }
                        }

                        rows.forEach(row => {
                            delete row.email;
                            promises.push(db.updateLearningJournal(row.id, row));
                        })

                        Promise.all(promises).then(() => {
                            this.setState({
                                isLoading: false,
                                month: String(new Date().getMonth()+1),
                                fileType: '',
                                isUploadComplete: true,
                                rowsImported: promises.length
                            });
                        })
                    })
                })
            });
        }
    }

    importGeneralLearningLogs() {
        const {data, month, year} = this.state;

        if(data.length > 0) {

            this.setState({isLoading: true});
            var promises = [];
            var rows = [];

            db.getJournalEntriesByMonthYearAuthor(month, year, 'generalLearningImport').then(entries => {
               
                promises = [];

                entries.forEach(entry => { 
                    promises.push(db.deleteLearningJournal(entry.id));
                });

                console.log('deleting ' + promises.length + ' existing records');
                Promise.all(promises).then(() => {

                    promises = [];

                    for(var i = 0; i < data.length; i++) {

                        const dates = data[i].classdate_gendev && data[i].classdate_gendev.split(" ")[1];
                        const learningDates = dates && dates.split(/[,/-]+/);
                        
                        if(data[i].email_gendev && data[i].employee_id && data[i].employee_id !== "null") {
                            for(let count = 0; count < data[i].numberofsession_gendev; count++) {
                                if(learningDates[count] !== "") {
                                    const row = {
                                        email: data[i].email_gendev.toLowerCase(),
                                        timeSpent: Number(data[i].sessionduration_gendev)*60,
                                        authorID: 'generalLearningImport',
                                        learningDate: year + '-' + String(month).padStart(2, '0') + '-' + learningDates[count].padStart(2, '0'),
                                        resource: "generalLearning",
                                        created: new Date().getTime(),
                                        type: 'learning',
                                        month: String(month),
                                        year: year,
                                        learningNotes: data[i].classname_gendev
                                    }

                                    if(row.timeSpent > 0) {
                                        rows.push(row);
                                        promises.push(db.getPersonByEmail(row.email));
                                    }
                                }
                            }
                        }
                    }

                    Promise.all(promises).then((results) => {
                        
                        promises = [];

                        rows.forEach((row) => {
                            if(row) {
                                for(var i = 0; i < results.length; i++) {
                                    if(results[i]) {
                                        if(row.email.toLowerCase() === results[i].email.toLowerCase()) {
                                            row.personID = results[i].id;
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        for(var j = rows.length -1; j > -1; j--) {
                            if(!rows[j] || !rows[j].personID) {
                                rows.splice(j,1);
                            }
                        }

                        rows.forEach(row => {
                            delete row.email;
                            promises.push(db.updateLearningJournal(row.id, row));
                        })

                        Promise.all(promises).then(() => {
                            this.setState({
                                isLoading: false,
                                month: String(new Date().getMonth()+1),
                                fileType: '',
                                isUploadComplete: true,
                                rowsImported: promises.length
                            });
                        })
                    })
                })
            });
        }
    }

    importFunctionalTrainingLogs() {
        const {data, month, year} = this.state;

        if(data.length > 0) {

            this.setState({isLoading: true});
            var promises = [];
            var rows = [];

            db.getJournalEntriesByMonthYearAuthor(month, year, 'functionalTrainingImport').then(entries => {
               
                promises = [];

                entries.forEach(entry => { 
                    promises.push(db.deleteLearningJournal(entry.id));
                });

                console.log('deleting ' + promises.length + ' existing records');
                Promise.all(promises).then(() => {

                    promises = [];

                    for(var i = 0; i < data.length; i++) {

                        const dates = data[i].classdate_ft && data[i].classdate_ft.split(" ")[1];
                        const learningDates = dates && dates.split(/[,/-]+/);
                        
                        if(data[i].email_ft && data[i].employee_id && data[i].employee_id !== "null") { 
                            for(let count = 0; count < data[i].numberofsession_ft; count++) {
                                if(learningDates[count] !== "") {
                                            
                                    const row = {
                                        email: data[i].email_ft.toLowerCase(),
                                        timeSpent: Number(data[i].sessionduration_min_ft),
                                        authorID: 'functionalTrainingImport',
                                        learningDate: year + '-' + String(month).padStart(2, '0') + '-' + learningDates[count].padStart(2, '0'),
                                        resource: "functionalTraining",
                                        created: new Date().getTime(),
                                        type: 'learning',
                                        month: String(month),
                                        year: year,
                                        learningNotes: data[i].classname_ft
                                    }

                                    if(row.timeSpent > 0) {
                                        rows.push(row);
                                        promises.push(db.getPersonByEmail(row.email));
                                    }
                                }
                            }
                        }
                    }

                    Promise.all(promises).then((results) => {
                        
                        promises = [];

                        rows.forEach((row) => {
                            if(row) {
                                for(var i = 0; i < results.length; i++) {
                                    if(results[i]) {
                                        if(row.email.toLowerCase() === results[i].email.toLowerCase()) {
                                            row.personID = results[i].id;
                                            break;
                                        }
                                    }
                                }
                            }
                        })

                        for(var j = rows.length -1; j > -1; j--) {
                            if(!rows[j] || !rows[j].personID) {
                                rows.splice(j,1);
                            }
                        }

                        rows.forEach(row => {
                            delete row.email;
                            promises.push(db.updateLearningJournal(row.id, row));
                        })

                        Promise.all(promises).then(() => {
                            this.setState({
                                isLoading: false,
                                month: String(new Date().getMonth()+1),
                                fileType: '',
                                isUploadComplete: true,
                                rowsImported: promises.length
                            });
                        })
                    })
                })
            });
        }
    }

    showButtons() {
        if(this.state.data.length > 0) 
            return "block";
        else 
            return "none";
    }
    showLoading() {
        if(this.state.isLoading)
            return "block";
        else 
            return "none";
    }
    showOptions() {
        if(!this.state.isLoading)
            return "block";
        else 
            return "none";
    }

    render() {

        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header =>
              header
                .toLowerCase()
                .replace(/\W/g, '_')
        }
        
        const loadingDiv = {
            display: this.showLoading(),
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    
        return (
            <div>
                <NavItem onClick={this.toggleModal}>
                  <NavLink className="btn-magnify" style={{marginTop:"5px"}}>
                        <i className="nc-icon nc-settings" />
                  </NavLink>
                </NavItem>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody style={{minHeight:"350px"}}>
                        <Row>
                            <Col sm="12">
                               <h4>Learning Journal Admin Tools</h4>
                            </Col>
                        </Row>
                       
                        <Row>
                            <Col sm="12">
                                <div style={loadingDiv}>
                                    <div style={{marginTop:"89px", height:"123px"}}>
                                        <Spinner color="success" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{display:this.showOptions()}}>
                            <Row>
                                <Col md="6">
                                    Year <br />
                                    <InputGroup>
                                        <Input placeholder="" 
                                            type="select" 
                                            value={this.state.year} onChange={(e) => this.setState({year: e.target.value})} >
                                                <option>2022</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col md="6">
                                    Month <br />
                                    <InputGroup>
                                        
                                        <Input placeholder="" 
                                            type="select" 
                                            value={this.state.month} onChange={(e) => this.setState({month: e.target.value})} >
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <br />
                                    <div >
                                        <CSVReader
                                            cssClass="csv-reader-input"
                                            onFileLoaded={(data) => this.onFileLoaded(data)}
                                            parserOptions={papaparseOptions}
                                            inputStyle={{color: 'green'}}
                                        />
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row style={{marginTop:"20px", display:this.showButtons()}}>
                                {this.state.fileType === "linkedIn" && <Col sm="6" >
                                    <Button size="sm" color="secondary" onClick={() => this.importLinkedInLogs()}>Import LinkedIn Logs</Button>
                                </Col>}
                                {this.state.fileType === "produgie" && <Col sm="6" >
                                    <Button size="sm" color="secondary" onClick={() => this.importProdugieLogs()}>Import Produgie Logs</Button>
                                </Col>}
                                {this.state.fileType === "english" && <Col sm="6" >
                                    <Button size="sm" color="secondary" onClick={() => this.importEnglishCourseLogs()}>Import English Course Logs</Button>
                                </Col>}
                                {this.state.fileType === "generalLearning" && <Col sm="6" >
                                    <Button size="sm" color="secondary" onClick={() => this.importGeneralLearningLogs()}>Import General Learning Logs</Button>
                                </Col>}
                                {this.state.fileType === "functionalTraining" && <Col sm="6" >
                                    <Button size="sm" color="secondary" onClick={() => this.importFunctionalTrainingLogs()}>Import Functional Training Logs</Button>
                                </Col>}
                            </Row>
                            {this.state.isUploadComplete && 
                                <Row style={{marginTop:"20px", marginBottom:"20px", textTransform: "uppercase", textAlign: "center", fontSize: "15px", fontWeight: "bold", color: "green"}} >
                                    <Col sm="12">{this.state.rowsImported} learning journal entries have been successfully imported!</Col>
                                </Row> 
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default LearningJournalAdmin;