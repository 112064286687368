import React from 'react'
import { Col, Row } from 'reactstrap'

import { dateTime } from '../../../assets/utils'
import iconStyle from '../constants'

function LinkRow({ projectLink, index }) {
  const { created, name, url } = projectLink

  return (
    <Row key={index}>
      <Col xs="1">
        <i style={iconStyle} className="nc-icon nc-globe" title="link" />
      </Col>
      <Col xs="8">
        <a href={url} target="_new" style={{ color: '#232323' }}>{name}</a>
        <br />
      </Col>
      <Col xs="3" style={{ textAlign: 'right', color: '#898989' }}>
        {dateTime.dateConverter(created)}
        <br />
      </Col>
    </Row>
  )
}

function LinksTab({
  projectLinks, styles,
}) {
  return projectLinks && (
    <div style={styles}>
      <div style={{ marginBottom: '20px' }}>
        Links to the Project folders/documents (e.g. Confulence, Google Drive):
      </div>

      <div>
        <Row style={{ marginBottom: '10px', fontWeight: 'bold' }}>
          <Col xs="1" />
          <Col xs="8">
            Name
          </Col>
          <Col xs="3" style={{ textAlign: 'right', color: '#898989' }}>
            Added
          </Col>
        </Row>
        {projectLinks.map((projectLink, index) => (
          <LinkRow projectLink={projectLink} index={index} key={index} />
        ))}
      </div>

    </div>
  )
}
export default LinksTab
