
import React from "react";
import { Button, Card, CardBody, Input, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Parser from 'html-react-parser';

import ConfluencePage from "components/ConfluencePage/ConfluencePage.js";
import ContentEditor from "components/ContentEditor/ContentEditor.js";
import PersonCard from "components/PersonCard/PersonCard.js";

import { db } from 'assets/firebase'; 

class ContentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        activeTab: 'goCode',
        newPageID: '',
        showNewPageControls: false,
        page: {
          title: "",
          content: "",
          confluence:[]
        },
        owner: {},
        confluencePages: []
    }

    this.removePage = this.removePage.bind(this);
  }

  componentDidMount() {

    var path = this.props.location.pathname;
    var contentID = path.substr(path.lastIndexOf('/') + 1);
    
    db.getPage(contentID).then(page => {

      console.log(contentID,page)

      if(page) {

        if(!page.confluence) page.confluence = [];
        if(!page.content) page.content = "";
        console.log(page)

        this.setState({page: page});

        if(page.owner) {
          db.getPerson(page.owner).then(owner => {
            this.setState({owner: owner});
          });
        }
      }
    })    
  }

  onUpdated(e) {
    var page = this.state.page;
    page = e;
    this.setState({page: page});
    console.log(e);
  }

  addConfluencePage() {

  }

  showNewPageControls() {
    if(this.state.showNewPageControls)
      return "block";
    else
      return "none";
  }

  showNewPageButton() {
    if(!this.state.showNewPageControls)
      return "block";
    else
      return "none";
  }

  addNewPage() {
    var pageID = this.state.newPageID;

    var page = this.state.page;
    page.confluence.push(pageID);

    db.updatePage(page.id, page).then(() => {
      this.setState({newPageID:"", showNewPageControls: false, page: page, activeTab: page.confluence.length -1 });
    })
  }

  removePage(id) {
    var page = this.state.page;
    for(var i = 0; i < page.confluence.length; i++) {
      if(page.confluence[i] === id) {
        page.confluence.splice(i,1);
        break;
      }
    }
    db.updatePage(page.id, page).then(() => {
      this.setState({ page: page, activeTab: 0 });
    })
  }
  getHTML(value) {
    if(value)
        return Parser(value);
    else 
        return "";
  }
  getOwner() {
    if(this.state.owner && this.state.owner.id) {
      return <PersonCard person={this.state.owner} showThumb />
    } 
  }

  cpPageLoaded(id, title) {
    var cps = this.state.confluencePages;
    cps.push({id: id, title: title});
    this.setState({confluencePages: cps});
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md={10}>
            <br />
            <h3>{this.state.page.title}</h3>
            <div style={{marginTop:"-23px", marginBottom:"13px"}}>{this.state.page.subTitle}</div>
            <div>
              {this.getOwner()}
            </div>
          </Col>
          <Col md={2} style={{textAlign:"right"}}>
            <br />
            <ContentEditor page={this.state.page} onChange={(e) => this.onUpdated(e)} />
          </Col>
        </Row>
        {/*}
        <Row>
          <Col sm={8}>
            <Nav style={{marginTop:"17px"}}>
              <NavItem style={(this.state.activeTab === 'goCode' ? activeTab : tab )}>
                <NavLink
                      style={(this.state.activeTab === 'goCode' ? activeLink : link )}
                      onClick={() => { this.setState({activeTab: 'goCode'}) }}
                  >
                      GoCode
                  </NavLink>
              </NavItem>
              {this.state.confluencePages.map((cp, index) => (
                <NavItem key={cp.id} style={this.state.activeTab === cp.id ? activeTab : tab}>
                  <NavLink
                      style={(this.state.activeTab === cp.id ? activeLink : link )}
                      onClick={() => { this.setState({activeTab: cp.id}) }}
                  >
                      {cp.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
          <Col sm={4} >
            
            <div style={{textAlign:"right", display:this.showNewPageButton()}}>
              <Button outline color="secondary" onClick={() => this.setState({showNewPageControls: true})} size="sm"  >  
                + Add 
              </Button>
            </div>
            <div style={{textAlign:"right", display:this.showNewPageControls()}}>
              <div style={{display:"inline-block", width:"111px", marginRight:"10px"}}>
                <Input placeholder="id" bsSize="sm" value={this.state.newPageID} onChange={(e) => this.setState({newPageID:e.target.value})} />
              </div>
              <Button outline color="secondary" onClick={() => this.addNewPage()} size="sm"  >  
                Save
              </Button>
            </div>
          </Col>
        </Row>
        */}
        <Row>
          <Col md={12}>
            <TabContent activeTab={this.state.activeTab} >
                <TabPane tabId="goCode" style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>
                  
                  <Card>
                    <CardBody className="ql-editor" style={{minHeight:"350px", padding:"30px", fontSize:"14px"}}>
                      {this.getHTML(this.state.page.content)}
                    </CardBody>
                  </Card>
                </TabPane>
            {this.state.page.confluence.map((cp) => (
              <TabPane key={cp} tabId={cp} style={{minHeight:"210px", paddingTop:"17px", fontSize:"12px"}}>
                <ConfluencePage id={cp} onRemove={this.removePage} onLoaded={(id, title) => this.cpPageLoaded(id, title)} />
              </TabPane>
            ))}
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentPage;


