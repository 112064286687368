import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap'

import logo from '../../assets/img/goConnectIcon.png'
import PersonCard from '../PersonCard/PersonCard'
import ProfileMenuDropdown from '../ProfileMenuDropdown/ProfileMenuDropdown'

function NexusCollaborationNavbar(props) {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [othersPresent, setOthersPresent] = useState([])
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false)

  const mounted = useRef()
  useEffect((e) => {
    if (mounted.current) {
      if (
        window.outerWidth < 993 &&
          e.history.location.pathname !== e.location.pathname &&
          document.documentElement.className.indexOf('nav-open') !== -1
      ) {
        document.documentElement.classList.toggle('nav-open')
      }

      if (e.othersPresent !== props.othersPresent) {
        setOthersPresent(props.othersPresent)
      }
    }
  })

  const showOtherPeople = () => {
    if (othersPresent.length > 0) {
      return 'list-item'
    }
    return 'none'
  }

  const showTotalOthers = () => {
    if (props.totalOthers > 0) {
      return 'inline-block'
    }
    return 'none'
  }

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen)
  }

  const showPersonModal = () => {
    setIsPersonModalOpen(true)
  }

  const closePersonModal = () => {
    setIsPersonModalOpen(false)
  }

  return (
    <div>
      <Navbar
        className={classnames('navbar-absolute fixed-top', 'bg-white')}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper" style={{ cursor: 'pointer' }}>
            <div style={{ display: 'inline-block' }}>
              <img
                src={logo}
                alt="react-logo"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  marginLeft: '4px',
                  marginRight: '10px',
                  height: '34px',
                  paddingRight: '0px',
                }}
              />
            </div>
            <div style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold' }}>
              Nexus Collaboration
            </div>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            <Nav navbar>
              <NavItem
                style={{
                  display: showOtherPeople(),
                  backgroundImage: 'linear-gradient(to right, #ffffff, #efefef)',
                  borderRadius: '0px 10px 10px 0px',
                  marginRight: '20px',
                }}
                title="Other GoTroops here with you."
              >
                <NavLink>
                  {othersPresent.map((person) => (
                    <div style={{ marginTop: '5px', marginRight: '10px', display: 'inline-block' }} key={person.id}>
                      <PersonCard person={person} showThumb />
                    </div>
                  ))}
                  <div style={{
                    display: showTotalOthers(),
                    height: '27px',
                    width: '27px',
                    borderRadius: '4px',
                    verticalAlign: 'top',
                    marginTop: '5px',
                    marginRight: '0px',
                    backgroundColor: '#aaaaaa',
                    textAlign: 'center',
                    color: '#dedede',
                    fontSize: '10px',
                  }}
                  >
                    +
                    {props.totalOthers}
                  </div>
                </NavLink>
              </NavItem>
              <ProfileMenuDropdown user={props.user} showPersonModal={showPersonModal} />

              <PersonCard
                person={props.user.person}
                showPersonModal={isPersonModalOpen}
                closePersonModal={closePersonModal}
              />

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-func-assign
NexusCollaborationNavbar = connect(mapStateToProps)(NexusCollaborationNavbar)
export default NexusCollaborationNavbar
