// FIXME: Split me into smaller component. This file is too huge X.X

import { sub } from 'date-fns'
import Parser from 'html-react-parser'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
// reactstrap components
import {
  Button, Card, CardBody, CardHeader,
  CardTitle, Col, Input,
  Nav, NavItem, NavLink,
  Row, TabContent, Table,
  TabPane,
} from 'reactstrap'
import { isNumeric } from 'redoc'

import { db } from '../../assets/firebase'
import logo from '../../assets/img/goConnectIcon.png'
import {
  arraySort, dateTime, strings, tables, telemetry,
} from '../../assets/utils'
import LineSummaryChart from '../../components/Analytics/LineSummaryChart'
import TableSummaryChart from '../../components/Analytics/TableSummaryChart'
import IssueEditor from '../../components/IssueEditor/IssueEditor'
import MetricCard from '../../components/MetricCard/MetricCard'
import {
  CARE_TEAM_ID, CUSTOMER_PLATFORM_TEAM_ID, ENGINEERING_PLATFORM_TEAM_ID,
  GO_CLUB_TEAM_ID, GOJEK_PRODUCT_SECURITY_TEAM_ID,
} from '../../components/Nexus/constants'
import NoteEditor from '../../components/NoteEditor/NoteEditor'
import OKRs from '../../components/OKRs/OKRs'
import PersonCard from '../../components/PersonCard/PersonCard'
import ProjectCard from '../../components/ProjectCard/ProjectCard'
import StandardCard from '../../components/StandardCard/StandardCard'
import TagBadges from '../../components/Tag/TagBadges'
import TeamCard from '../../components/TeamCard/TeamCard'
import TeamEditor from '../../components/TeamEditor/TeamEditor'
import {
  getNoteTypeDescriptor, getStatusDescriptor, getUrgencyDescriptor, getValueDescriptor,
} from '../../components/utils'
// Nexus
import AverageDaysOfCompletion from '../nexusReports/AverageDaysOfCompletion'
import CreatedVsResolvedIssues from '../nexusReports/CreatedVsResolvedIssues'
import IssuesBySeverityBarGraph from '../nexusReports/IssuesBySeverityBarGraph'
import IssuesReportBySeverity from '../nexusReports/IssuesReportBySeverity'
import MonthlyAverageDaysToCompletion from '../nexusReports/MonthlyAverageDaysToCompletion'
import SLAResolutionResponseTimeBarGraph from '../nexusReports/SLAResolutionResponseTimeBarGraph'
import {
  careStatusOptions,
  ENGINEERING_PLATFORM_PODS_FILTER, EXCEPTIONS_FOR_FUNCTIONAL_TEAMS_PAGE,
  CUSTOMER_PLATFORM_PODS, getAverageDaysOfCompletion,
  getMonthlyAverageDaysOfCompletion,
  getMonthlyData,
  getMonthsForChart, getSLAResolutionPercentageDataBySeverity,
  getSLAResponsePercentageDataBySeverity,
  getSLARuleInMins, getTeamName, GOJEK_PRODUCT_SECURITY_ISSUE_TYPES,
  SEVERITY_COLORS, severityOptions, SLAOptions, sortByCount,
  statusMap, statusOptions,
} from '../nexusReports/utils'
import IssuesReportByPod from './IssuesReportByPod'
import IssuesReportByPodPieChart from './IssuesReportByPodPieChart'
import OpenIssuesByPod from './OpenIssuesByPod'
import ReassignedTicketsByPodPieChart from './ReassignedTicketsByPodPieChart'
import SLABreachedByTeam from '../nexusReports/SLABreachedByTeamPieChart'
import SLAReportByPod from './SLAReportByPod'
// Editor
import LinkTab from './tabs/LinkTab'
import TopPageViewsByPDG from './TopPageViewsByPDGPieChart'

let authors = []
const uniqueAuthorIds = new Set()

class TeamPage extends React.Component {
  constructor(props) {
    super(props)

    // FIXME: Mutating State is just not the way to go, use useState instead!
    this.state = {
      isLoading: true,
      activeTab: 'welcome',
      team: {
        name: '',
        links: [],
      },
      members: [],
      projects: [],
      issues: [],
      filtered: [],
      tags: [],
      standards: [],
      notes: [],
      filteredNotes: [],
      noteTypeFilter: 'all',
      metrics: [],
      filter: {
        type: [],
        severity: [],
        projectID: [],
        status: [],
        toPodID: [],
        sla: {},
        startDate: '',
        endDate: '',
        toTeamID: [],
      },
      issuesInResults: false,
      tasksInResults: false,
      standardsStatusFilter: 'all',
      filteredStandards: [],
      statusReports: [],
      subTeams: [],
      sortBy: 'value',
      nexusIssues: [],
      podOptions: [],
      teams: [],
      projectOptions: [],
      nexusIssuesPresent: false,
      customerRequestTypeOptions: [],
      initialLinks: [],
      PDGOptions: [],
      allPods: [],
      PDGs: {},
      issuetypeOptions: [],
      allRequestOptions: [],
      activities: [],
      visitors: [],
      pageViewSummary: [],
      uniqueUsersSummary: [],
      labels: [],
      chartData: {
        monthlySeverityData: {},
        weeklyCreatedIssues: [],
        weeklyResolvedIssues: [],
        averageDurationBySeverity: {},
        monthlyAverageDurationBySeverity: {},
        timeToResolution: [],
        timeToRespond: [],
      },
    }

    this.typeOptions = [
      { label: 'App/Play Stores', value: 'store' },
      { label: 'Care Issues', value: 'care' },
      { label: 'Collaboration Request', value: 'request' },
      { label: 'Project Tasks', value: 'task' },
      { label: 'Security Issues', value: 'security' },
    ]

    this.setDefaultImg = this.setDefaultImg.bind(this)
    this.onUpdated = this.onUpdated.bind(this)
    this.updateTeam = this.updateTeam.bind(this)
    this.updateInitialLinks = this.updateInitialLinks.bind(this)
  }

  async componentDidMount() {
    db.getTeams().then((teams) => {
      this.setState({
        teams,
      })
    })

    db.getTeam(this.props.teamID).then(async (team) => {
      document.title = `${team.name} - GoConnect`

      this.setDefaultImg(team)

      db.getTeamMembers(team.id).then((teamMembers) => {
        const promises = []
        for (let i = 0; i < teamMembers.length; i += 1) {promises.push(db.getPerson(teamMembers[i].personID))}

        Promise.all(promises).then((people) => {
          const members = []
          people.forEach((person) => { // necessary to handle missing employees
            if (person) members.push(person)
          })

          members.sort(arraySort.byName)
          this.setState({ team, members, isLoading: false })
          this.updateInitialLinks(_.cloneDeep(team.links))
        })
      })

      db.getTeamProjects(team.id).then((teamProjects) => {
        let promises = []
        for (let i = 0; i < teamProjects.length; i += 1) {
          if (teamProjects[i].projectID) promises.push(db.getProject(teamProjects[i].projectID))
        }

        Promise.all(promises).then((results) => {
          const projects = []
          const projectOptions = []
          results.forEach((project) => {
            if (project) {
              if (project.status === 'active') {
                projects.push(project)
                projectOptions.push({
                  label: project.name,
                  value: project.id,
                })
              }
            }
          })

          projects.sort(arraySort.byName)
          this.setState({
            projects,
            projectOptions: projectOptions.sort((a, b) => a.label.localeCompare(b.label)),
          }, () => {
            db.getTagsByType('issueType').then((tags) => {
              tags.forEach((tag) => {
                const tagRef = tag
                tagRef.name = tagRef.name.toLowerCase()
              })

              db.getIssuesByTeamID(team.id).then((issues) => {
                let filtered = []
                let tasksInResults = false
                issues.forEach((issue) => {
                  const issueRef = issue
                  if (!issue.value) issueRef.value = 0
                  if (!issue.urgency) issueRef.urgency = 0
                  filtered.push(issueRef)
                  if (!tasksInResults && issueRef.type === 'task') {
                    tasksInResults = true
                  }
                })

                let nexusIssues = []
                const toPodOptions = []
                let issuesInResults = false
                let nexusIssuesPresent = false
                const customerRequestTypeOptions = []
                const toPDGOptions = []
                const PDGs = {}
                const issuetypeOptions = []
                let allRequestOptions = []

                let issueType = 'toTeamID'
                if (EXCEPTIONS_FOR_FUNCTIONAL_TEAMS_PAGE.includes(team.id)) {
                  issueType = 'fromTeamID'
                }
                db.getNexusIssuesByTeamID(team.id, issueType).then((issues) => {
                  if (issues.length > 0) {
                    issuesInResults = true
                    nexusIssuesPresent = true
                  }
                  filtered = filtered.concat(issues)

                  const pods = {}
                  const customerRequestTypes = {}
                  const issuetypes = {}

                  issues.forEach((issue) => {
                    issue.toTeamName = getTeamName(this.state.teams, issue.toTeamID)
                    issue.toPodName = getTeamName(this.state.teams, issue.toPodID)

                    // Show 5 filters for Care
                    if (team.id === CARE_TEAM_ID) {
                      issue.nexusStatus = statusMap[issue.jira.status]
                    }

                    let { toPodID, toTeamID } = issue
                    if (team.id === CUSTOMER_PLATFORM_TEAM_ID && toPodID === CUSTOMER_PLATFORM_TEAM_ID) {
                      // Show other Pod options for Customer Platform
                      toPodID = issue.jira && issue.jira.toPod
                      if (issue.jira && issue.jira.toPod) {
                        issue.toPodName = issue.jira.toPod
                      }
                    }

                    if (team.id === ENGINEERING_PLATFORM_TEAM_ID) {
                      // Show fromPod options in the dropdown for EP
                      toPodID = issue.jira && issue.jira.fromPod
                      issue.toPodName = issue.jira.fromPod
                    }

                    if (!(toPodID in pods)) {
                      pods[toPodID] = true
                    }

                    const type = issue.jira &&
                    !['Submit a request or incident', 'Task'].includes(issue.jira.issuetype) ? issue.jira.issuetype : ''
                    if (type && !(type in issuetypes)) {
                      issuetypes[type] = true
                    }

                    if (team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
                      // Show PDG options for Prod Security
                      if (toTeamID in PDGs) {
                        if (PDGs[toTeamID].indexOf(toPodID) === -1) {
                          PDGs[toTeamID].push(toPodID)
                        }
                      } else {
                        PDGs[toTeamID] = [toPodID]
                      }
                    } else {
                      const requestType = issue.jira && issue.jira.care ? issue.jira.care.customerRequestType : ''
                      if (requestType && !(requestType in customerRequestTypes)) {
                        customerRequestTypes[requestType] = true
                      }
                    }
                  })

                  nexusIssues = issues

                  if (team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
                    Object.keys(PDGs).forEach((id) => {
                      PDGs[id].forEach((podId) => {
                        const teamName = getTeamName(this.state.teams, podId)

                        if (teamName) {
                          const teamOption = {
                            label: teamName,
                            value: podId,
                          }
                          toPodOptions.push(teamOption)
                        }
                      })
                    })

                    Object.keys(PDGs).forEach((id) => {
                      const teamName = getTeamName(this.state.teams, id)
                      if (teamName) {
                        const teamOption = {
                          label: teamName,
                          value: id,
                        }
                        toPDGOptions.push(teamOption)
                      }
                    })
                  } else {
                    Object.keys(pods).forEach((podId) => {
                      let teamName = ''
                      if ((team.id === CUSTOMER_PLATFORM_TEAM_ID && podId !== GO_CLUB_TEAM_ID) ||
                      team.id === ENGINEERING_PLATFORM_TEAM_ID) {
                        // If PDG == 'Customer Platform' and Pod !== 'GoClub Business'
                        teamName = podId
                      } else {
                        teamName = getTeamName(this.state.teams, podId)
                      }

                      if (teamName) {
                        const teamOption = {
                          label: teamName,
                          value: podId,
                        }
                        toPodOptions.push(teamOption)
                      }
                    })

                    Object.keys(customerRequestTypes).forEach((type) => {
                      if (type) {
                        const requestType = {
                          label: type,
                          value: type,
                        }
                        customerRequestTypeOptions.push(requestType)
                      }
                    })
                  }

                  Object.keys(issuetypes).forEach((type) => {
                    if (type) {
                      const issuetype = {
                        label: type,
                        value: type,
                      }
                      issuetypeOptions.push(issuetype)
                    }
                  })

                  allRequestOptions = [
                    ...customerRequestTypeOptions,
                    ...issuetypeOptions,
                  ]
                })

                promises = []

                projects.forEach((project) => {
                  const projectRef = project
                  projectRef.currentStatus = 'gray'
                  projectRef.currentStatusDetails = ''
                  projectRef.lastUpdated = 'no updates'
                  promises.push(db.getStatusReportsByProject(project.id))
                })

                const statusReports = []

                Promise.all(promises).then((results) => {
                  results.forEach((result) => {
                    if (result.length > 0) {
                      result.forEach((sr) => {
                        statusReports.push(sr)
                      })

                      for (let p = 0; p < projects.length; p += 1) {
                        if (result[0].projectID === projects[p].id) {
                          result.sort(arraySort.byPublishedDateString)

                          projects[p].statusReports = result
                          projects[p].currentStatus = result[0].status

                          const details = result[0].details.replace(/<\/?[^>]+(>|$)/g, '')
                          projects[p].currentStatusDetails = strings.trim(details, 89)

                          projects[p].lastUpdated = result[0].published
                          break
                        }
                      }
                    }
                  })

                  statusReports.forEach((sr) => {
                    for (let t = 0; t < projects.length; t += 1) {
                      if (sr.projectID === projects[t].id) {
                        sr.project = projects[t]
                        break
                      }
                    }
                  })

                  statusReports.sort(arraySort.byPublishedDateString)

                  promises = []
                  projects.forEach((project) => {
                    promises.push(db.getIssuesByProjectID(project.id))
                  })

                  Promise.all(promises).then((results) => {
                    results.forEach((result) => {
                      if (result) {
                        if (result.length > 0) {
                          // get project name for issue column
                          let projectName = ''
                          for (let x = 0; x < projects.length; x += 1) {
                            if (projects[x].id === result[0].projectID) {
                              projectName = projects[x].name
                              break
                            }
                          }

                          result.forEach((task) => {
                            let isFound = false
                            for (let j = 0; j < issues.length; j += 1) {
                              if (issues[j].id === task.id) {
                                isFound = true
                                break
                              }
                            }

                            if (!isFound) {
                              task.projectName = projectName
                              if (!task.value) task.value = 0
                              if (!task.urgency) task.urgency = 0
                              filtered.push(task)
                            }
                          })
                        }
                      }
                    })

                    db.getNotesByTeam(this.props.teamID).then((notes) => {
                      notes.forEach((note) => {note.dateStamp = new Date(note.published).getTime()})
                      notes.sort((a, b) => b.dateStamp - a.dateStamp)

                      this.findUniqueAuthors(notes)
                      this.findUniqueAuthors(statusReports)

                      const noteAuthorPromises = []
                      uniqueAuthorIds.forEach((authorId) => {
                        promises.push(db.getPerson(authorId))
                      })

                      Promise.all(noteAuthorPromises).then((people) => {
                        for (let p = people.length - 1; p > -1; p -= 1) // remove any missing people
                        {if (!people[p]) people.splice(p, 1)}

                        authors = people

                        this.attachAuthors(notes)
                        this.attachAuthors(statusReports)

                        if (nexusIssuesPresent) { // Default sort by severity (critical to low)
                          filtered = filtered.sort(arraySort.bySeverity).reverse()
                        }

                        this.setState({
                          statusReports,
                          issues: filtered,
                          filtered,
                          notes,
                          tags,
                          nexusIssues,
                          podOptions: toPodOptions.sort((a, b) => a.label.localeCompare(b.label)),
                          tasksInResults,
                          issuesInResults,
                          nexusIssuesPresent,
                          customerRequestTypeOptions:
                            customerRequestTypeOptions.sort((a, b) => a.label.localeCompare(b.label)),
                          PDGOptions: toPDGOptions.sort((a, b) => a.label.localeCompare(b.label)),
                          allPods: toPodOptions,
                          PDGs,
                          issuetypeOptions: issuetypeOptions.sort((a, b) => a.label.localeCompare(b.label)),
                          allRequestOptions: allRequestOptions.sort((a, b) => a.label.localeCompare(b.label)),
                        }, () => {
                          this.filterNotes()
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })

      db.getSubTeams(team.id).then((subTeams) => {
        const promises = []
        for (let i = 0; i < subTeams.length; i += 1) {promises.push(db.getTeam(subTeams[i].id))}

        Promise.all(promises).then((teams) => {
          teams.sort(arraySort.byName)
          for (let j = 0; j < teams.length; j += 1) {if (!teams[j].children) teams[j].children = []}

          this.setState({ subTeams: teams })
        })
      })

      const adoptions = await db.getStandardAdopterByTeam(team.id)
      const standardsAdoptionMap = {}

      adoptions.forEach((adoption) => {
        standardsAdoptionMap[adoption.standardID] = adoption
      })

      db.getStandardsByTeam(team.id).then((standards) => {
        const people = []
        const promises = []

        standards.forEach((standard) => {
          delete standard.owner

          if (standard.adopter) {
            if (standard.adopter.owner) {
              let isFound = false
              for (let i = 0; i < people.length; i += 1) {
                if (standard.adopter.owner === people[i]) {
                  isFound = true
                  break
                }
              }

              if (!isFound) {
                people.push(standard.adopter.owner)
                promises.push(db.getPerson(standard.adopter.owner))
              }
            }
          }

          standard.adoption = standardsAdoptionMap[standard.id] || {}
        })

        Promise.all(promises).then((people) => {
          standards.forEach((standard) => {
            if (standard.adopter) {
              for (let j = 0; j < people.length; j += 1) {
                if (people[j]) {
                  if (standard.adopter.owner === people[j].id) {
                    standard.owner = people[j]
                    break
                  }
                }
              }
            }
          })

          this.setState({ standards, filteredStandards: standards })
        })
      })

      db.getMetricsByTeam(team.id).then((metrics) => {
        this.setState({ metrics })
      })
    })

    // Show Page Analytics only for Gojek Product Security
    if (this.props.teamID === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
      const upperBound = new Date().getTime()
      const lowerBound = sub(new Date(), { days: 14 }).getTime()
      const activities =
      await db.getUserActivityByTypeAndPageIDBetween('pageView', 'nexusIssuesTabInTeamPage', lowerBound, upperBound)

      this.getTopPageViews(activities)
      this.setState({
        activities,
      })

      this.getSummaryData()
    }

    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'teamPage', 'pageView')
  }

  findUniqueAuthors(objs) {
    objs.forEach((obj) => {
      uniqueAuthorIds.add(obj.authorID)
    })
  }

  attachAuthors(objs) {
    objs.forEach((obj) => {
      for (let b = 0; b < authors.length; b += 1) {
        if (obj.authorID === authors[b].id) {
          const objRef = obj
          objRef.author = authors[b]
          break
        }
      }
    })
  }

  showLoading() {
    if (this.state.isLoading) return 'block'
    return 'none'
  }

  showResults() {
    if (!this.state.isLoading) return 'block'
    return 'none'
  }

  getSeverityDescriptor(severity) {
    if (Number.isNaN(severity)) {return severity}
    if (isNumeric(severity)) {
      if (severity > 90) return 'Critical'
      if (severity > 75) return 'High'
      if (severity > 60) return 'Medium'
      if (severity < 40) return 'Low'
    } else return severity
  }

  issueCreated(issue) {
    const issues = [...this.state.issues]
    issues.push(issue)
    this.setState({ issues }, () => this.filterIssues())
  }

  issueUpdated(issue) {
    const issues = [...this.state.issues]
    for (let i = 0; i < issues.length; i += 1) {
      if (issues[i].id === issue.id) {
        issues[i] = issue
        break
      }
    }
    this.setState({ issues }, () => this.filterIssues())
  }

  filterIssues(filterType, value) {
    if (filterType) { // Check for filtering out issues only when filter is applied, and not when tab is changed
      let filterValue

      if (filterType === 'startDate' || filterType === 'endDate') {
        filterValue = value || ''
      } else if (filterType === 'sla') {
        filterValue = value || {}
      } else {
        filterValue = value || []
      }

      const filterdata = {
        [filterType]: filterValue,
      }

      if (filterType === 'toTeamID') {
        const toPodOptions = []
        if (value && value.length > 0) {
          value.forEach((team) => {
            const pods = this.state.PDGs[team.value]
            pods.forEach((id) => {
              const teamName = getTeamName(this.state.teams, id)

              if (teamName) {
                const teamObj = {
                  label: teamName,
                  value: id,
                }
                toPodOptions.push(teamObj)
              }
            })
          })

          this.setState({
            podOptions: toPodOptions.sort((a, b) => a.label.localeCompare(b.label)),
          })
        } else {
          this.setState((prevState) => ({
            podOptions: prevState.allPods,
          }))
        }
      }

      if (filterType === 'type') {
        let requestOptions = []
        if (value && value.length === 1) {
          if (value[0].value === 'care') {
            requestOptions = [...this.state.customerRequestTypeOptions]
          } else if (value[0].value === 'security') {
            requestOptions = [...this.state.issuetypeOptions]
          }
        } else {
          requestOptions = [
            ...this.state.customerRequestTypeOptions,
            ...this.state.issuetypeOptions,
          ]
        }

        this.setState({
          allRequestOptions: requestOptions,
        })
      }

      const newFilterData = { ...this.state.filter, ...filterdata }
      const filteredIssues = this.applyFilter(newFilterData)
      let tasksInResults = false
      let issuesInResults = false

      for (let i = 0; i < filteredIssues.length; i += 1) {
        if (filteredIssues[i].type === 'task') {
          tasksInResults = true
          break
        }
        issuesInResults = true
      }

      const nexusIssues = filteredIssues.filter((issue) => issue.createdBy === 'Nexus')

      const { startDate, endDate } = this.state.filter
      const labels = getMonthsForChart(startDate, endDate, true)

      const chartData = this.computeChartData(nexusIssues, labels)

      this.setState({
        filtered: filteredIssues,
        filter: newFilterData,
        nexusIssues,
        tasksInResults,
        issuesInResults,
        chartData,
        labels,
      }, () => this.sortIssues())
    }
  }

  computeChartData(issues, months) {
    const monthlySeverityData = {
      critical: getMonthlyData(issues, months, 'critical'),
      high: getMonthlyData(issues, months, 'high'),
      medium: getMonthlyData(issues, months, 'medium'),
      low: getMonthlyData(issues, months, 'low'),
    }

    const weeklyCreatedIssues = Array(6).fill(0)
    const weeklyResolvedIssues = Array(6).fill(0)

    const currWeek = moment().isoWeek()

    issues.forEach((issue) => {
      const issueCreatedWeek = moment(issue.created).isoWeek()
      if (issueCreatedWeek > currWeek - 6) {
        weeklyCreatedIssues[5 - (currWeek - issueCreatedWeek)] += 1
      }

      if (issue.status === 'Closed' && issue.timeToResolution) {
        const issueResolutionWeek = moment(issue.timeToResolution).isoWeek()
        if (issueResolutionWeek > currWeek - 6) {
          weeklyResolvedIssues[5 - (currWeek - issueResolutionWeek)] += 1
        }
      }
    })

    const averageDurationBySeverity = {
      critical: getAverageDaysOfCompletion(issues, 'critical'),
      high: getAverageDaysOfCompletion(issues, 'high'),
      medium: getAverageDaysOfCompletion(issues, 'medium'),
      low: getAverageDaysOfCompletion(issues, 'low'),
    }

    const monthlyAverageDurationBySeverity = {
      low: getMonthlyAverageDaysOfCompletion(issues, months, 'low'),
      medium: getMonthlyAverageDaysOfCompletion(issues, months, 'medium'),
      high: getMonthlyAverageDaysOfCompletion(issues, months, 'high'),
      critical: getMonthlyAverageDaysOfCompletion(issues, months, 'critical'),
    }

    const timeToResolution = getSLAResolutionPercentageDataBySeverity(issues, months)
    const timeToRespond = getSLAResponsePercentageDataBySeverity(issues, months)

    return {
      monthlySeverityData,
      weeklyCreatedIssues,
      weeklyResolvedIssues,
      averageDurationBySeverity,
      monthlyAverageDurationBySeverity,
      timeToResolution,
      timeToRespond,
    }
  }

  applyFilter(filter) {
    const filteredIssues = this.state.issues.filter((issue) => {
      const SLA_RULES = getSLARuleInMins(issue.fromTeamID, issue.toPodID)
      const isPresent = Object.keys(filter).reduce((acc, key) => {
        if (key === 'startDate' && filter.startDate) {
          acc = acc && issue.created >= moment(filter[key]).valueOf()
          return acc
        }

        if (key === 'endDate' && filter.endDate !== '') {
          acc = acc && issue.created <= moment(filter[key]).valueOf()
          return acc
        }

        if (filter[key].length === 0) {
          return acc
        }

        if (key === 'sla') {
          const currTime = moment()
          const createdTime = moment(issue.created)

          let resolutionTimeDiff = 0
          let responseTimeDiff = 0
          let breachTime = 0

          if (issue.status === 'Closed' && issue.timeToResolution) {
            resolutionTimeDiff = moment(issue.timeToResolution).diff(createdTime)
          } else {
            resolutionTimeDiff = currTime.diff(createdTime)
          }

          if (issue.timeToRespond) {
            responseTimeDiff = moment(issue.timeToRespond).diff(createdTime)
          } else {
            responseTimeDiff = currTime.diff(createdTime)
          }

          if (issue.severity && issue.createdBy === 'Nexus') {
            switch (filter.sla.value) {
              case 'Resolution time Exceeded':
                breachTime = (SLA_RULES[issue.severity].timeToResolution) * (60 * 1000)
                acc = acc && resolutionTimeDiff >= breachTime
                break

              case 'Resolution time On-track':
                breachTime = (SLA_RULES[issue.severity].timeToResolution) * (60 * 1000)
                acc = acc && resolutionTimeDiff < breachTime
                break

              case 'Response time Exceeded':
                breachTime = (SLA_RULES[issue.severity].timeToRespond) * (60 * 1000)
                acc = acc && responseTimeDiff >= breachTime
                break

              case 'Response time On-track':
                breachTime = (SLA_RULES[issue.severity].timeToRespond) * (60 * 1000)
                acc = acc && responseTimeDiff < breachTime
                break

              default:
                break
            }
          }

          return acc
        }

        if (key === 'toPodID') {
          acc = acc && filter[key].find((filter) => {
            if (CUSTOMER_PLATFORM_PODS.includes(filter.value)) // Filter pods other than 'Go Club Business' of 'Customer Platform' using jira.toPod
            {return issue.jira.toPod === filter.value}
            if (ENGINEERING_PLATFORM_PODS_FILTER.includes(filter.value)) // Filter pods for EP using jira.fromPod as we consider source pod here
            {return issue.jira.fromPod === filter.value}
            return issue[key] === filter.value
          })
          return acc
        }

        if (key === 'customerRequestType') {
          acc = acc && filter[key].find((filter) => {
            if (GOJEK_PRODUCT_SECURITY_ISSUE_TYPES.includes(filter.value)) {
              return issue.jira && issue.jira.issuetype === filter.value
            }
            return issue.jira && issue.jira.care && issue.jira.care.customerRequestType === filter.value
          })
          return acc
        }

        if (key === 'issuetype') {
          acc = acc && filter[key].find((filter) => issue.jira && issue.jira.issuetype === filter.value)
          return acc
        }

        if (key === 'status') {
          acc = acc && filter[key].find((filter) => {
            if (this.state.team && this.state.team.id === CARE_TEAM_ID) {
              return issue.nexusStatus === filter.value
            }
            return issue.status === filter.value
          })
          return acc
        }

        acc = acc && filter[key].find((filter) => issue[key] && issue[key] === filter.value)
        return acc
      }, true)
      return isPresent
    })

    return filteredIssues
  }

  filterAttribute(value, filter) {
    if (value === filter || filter === 'all') {return true}

    return false
  }

  sortIssues(sortBy) {
    let issues = [...this.state.filtered]
    this.setState({ filtered: [] }, () => {
      if (sortBy === this.state.sortBy) { // reverse
        const reversed = []
        for (let i = issues.length - 1; i > -1; i -= 1) {reversed.push(issues[i])}

        issues = reversed
      } else {
        if (!sortBy) sortBy = this.state.sortBy

        if (sortBy === 'value') {
          issues.sort((a, b) => Number(b.value) - Number(a.value))
        } else if (sortBy === 'urgency') {
          issues.sort((a, b) => Number(b.urgency) - Number(a.urgency))
        } else if (sortBy === 'severity') {
          issues.sort(arraySort.bySeverity)
        } else if (sortBy === 'title') {
          issues.sort(arraySort.byTitle)
        } else if (sortBy === 'project') {
          issues.sort(arraySort.byProjectName)
        } else if (sortBy === 'pdg') {
          issues.sort((a, b) => (a.toTeamName ? a.toTeamName.localeCompare(b.toTeamName) : 1))
        } else if (sortBy === 'pod') {
          issues.sort((a, b) => (a.toPodName ? a.toPodName.localeCompare(b.toPodName) : 1))
        } else if (sortBy === 'status') {
          issues.sort((a, b) => a.status.localeCompare(b.status))
        } else if (sortBy === 'created') {
          issues.sort((a, b) => b.created - a.created)
        } else if (sortBy === 'jira') issues.sort((a, b) => a.jira.key.localeCompare(b.jira.key))
      }

      this.setState({ filtered: issues, sortBy })
    })
  }

  getOwner(person) {
    if (person) {
      return (
        <div>
          <PersonCard person={person} showThumb />
        </div>
      )
    }
  }

  getPersonCard(person) {
    if (person) {
      return <PersonCard person={person} showThumb />
    }
  }

  getJiraKey(issue) {
    if (EXCEPTIONS_FOR_FUNCTIONAL_TEAMS_PAGE.includes(this.state.team.id)) {
      return issue.jira.key
    }
    if (issue.toJira && issue.toJira.key && !issue.toJira.link.includes('asana')) {
      return issue.toJira.key
    }

    return issue.jira.key
  }

  getTicketLink(issue) {
    if (issue.toJira && issue.toJira.key) {
      return issue.toJira.link
    }

    return `https://go-jek.atlassian.net/browse/${issue.jira.key}`
  }

  getIssueStatus(issue) {
    if (this.state.team && this.state.team.id === CARE_TEAM_ID) {
      return issue.nexusStatus
    }

    return issue.status
  }

  getDefaultStatusValue() {
    if (this.props.teamID === CARE_TEAM_ID) {
      return []
    }
    return [{ label: 'Active', value: 'Active' }]
  }

  getStatusOptions() {
    if (this.props.teamID === CARE_TEAM_ID) {
      return careStatusOptions
    }

    return statusOptions
  }

  getIssuesList() {
    return (
      <div style={{
        padding: '13px', paddingTop: '0px', backgroundColor: 'white', minHeight: '89px',
      }}
      >

        <Row style={{ marginTop: '30px', marginBottom: '20px', fontSize: '12px' }}>
          <Col xs="12" sm="6" md="4" lg="3" style={{ display: this.showTypeFilter() }}>
            Type
            <Select
              defaultValue={[]}
              isMulti
              name="type"
              options={this.typeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('type', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3">
            Severity
            <Select
              defaultValue={[]}
              isMulti
              name="severity"
              options={severityOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('severity', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3" style={{ display: this.showTaskColumn() }}>
            Project
            <Select
              defaultValue={[]}
              isMulti
              name="projectID"
              options={this.state.projectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('projectID', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3" style={{ display: this.showCustomerRequestTypeFilter() }}>
            Request Type
            <Select
              defaultValue={[]}
              isMulti
              name="customerRequestType"
              options={this.state.allRequestOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('customerRequestType', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3" style={{ display: this.showProductSecurityFilter() }}>
            Issue Type
            <Select
              defaultValue={[]}
              isMulti
              name="issuetype"
              options={this.state.issuetypeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('issuetype', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3">
            Status
            <Select
              defaultValue={this.getDefaultStatusValue()}
              isMulti
              name="status"
              options={this.getStatusOptions()}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('status', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3" style={{ display: this.showProductSecurityFilter() }}>
            PDG
            <Select
              defaultValue={[]}
              isMulti
              name="toTeamID"
              options={this.state.PDGOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('toTeamID', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '30px', marginBottom: '20px', fontSize: '12px' }}>
          <Col xs="12" sm="6" md="4" lg="3">
            Pods
            <Select
              defaultValue={[]}
              isMulti
              name="toPodID"
              options={this.state.podOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('toPodID', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3">
            SLA
            <br />
            <Select
              defaultValue=""
              isClearable
              name="sla"
              options={SLAOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(value) => this.filterIssues('sla', value)}
              styles={{ option: (provided) => ({ ...provided, textTransform: 'capitalize' }) }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3">
            Start Date
            <br />
            <Input
              type="date"
              placeholder=""
              value={this.state.startDateFilter}
              onChange={(e) => this.filterIssues('startDate', e.target.value)}
              style={{ fontSize: '12px', padding: '10px', borderColor: 'hsl(0,0%,80%)' }}
            />
          </Col>
          <Col xs="12" sm="6" md="4" lg="3">
            End Date
            <br />
            <Input
              type="date"
              placeholder=""
              value={this.state.endDateFilter}
              onChange={(e) => this.filterIssues('endDate', e.target.value)}
              style={{ fontSize: '12px', padding: '10px', borderColor: 'hsl(0,0%,80%)' }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" style={{ textAlign: 'right' }}>
            <IssueEditor teamID={this.state.team.id} type="task" onCreated={(issue) => this.issueCreated(issue)} />
          </Col>
        </Row>

        <div style={{ display: this.showResults() }}>
          {(this.state.issuesInResults || this.state.tasksInResults) ?  (<Table>
            <thead>
              <tr style={{ fontSize: '11px', fontWeight: '700' }}>
                <td style={{ display: this.showTaskColumn() }} />
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                  }}
                  onClick={() => this.sortIssues('jira')}
                >
                  JIRA Ticket
                </td>
                <td
                  style={{ color: 'green', cursor: 'pointer' }}
                  onClick={() => this.sortIssues('title')}
                >
                  Title
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                  }}
                  onClick={() => this.sortIssues('severity')}
                >
                  Severity
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showPDGColumn(),
                  }}
                  onClick={() => this.sortIssues('pdg')}
                >
                  PDG
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                  }}
                  onClick={() => this.sortIssues('pod')}
                >
                  Pod
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                  }}
                  onClick={() => this.sortIssues('status')}
                >
                  Status
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                  }}
                  onClick={() => this.sortIssues('created')}
                >
                  Created
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showTaskColumn(),
                  }}
                  onClick={() => this.sortIssues('value')}
                >
                  Value
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: this.showTaskColumn(),
                  }}
                  onClick={() => this.sortIssues('urgency')}
                >
                  Urgency
                </td>
                <td
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                    display: this.showTaskColumn(),
                  }}
                  onClick={() => this.sortIssues('project')}
                >
                  Project

                </td>
                <td>Tags</td>
              </tr>
            </thead>
            <tbody>
              {this.state.filtered.map((issue, index) => (
                <tr key={index} style={{ fontSize: '12px', backgroundColor: tables.rowColor(index) }}>
                  <td style={{
                    padding: '3px', paddingLeft: '10px', minWidth: '120px', display: this.showTaskColumn(),
                  }}
                  >
                    <IssueEditor
                      issue={issue}
                      onUpdated={(issue) => this.issueUpdated(issue)}
                      onDeleted={(issue) => this.issueDeleted(issue)}
                      showOwnerThumb
                    />
                  </td>
                  <td style={{ width: '100px', textAlign: 'center', display: this.showIssueColumn() }}>
                    {issue.jira && (
                      <a
                        href={this.getTicketLink(issue)}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: 'black',
                        }}
                      >
                        {this.getJiraKey(issue)}
                      </a>
                    )}
                  </td>
                  <td>
                    {issue.title}
                  </td>
                  <td style={{
                    textAlign: 'center',
                    display: this.showIssueColumn(),
                    textTransform: 'capitalize',
                    color: SEVERITY_COLORS[this.getSeverityDescriptor(issue.severity)],
                  }}
                  >
                    {this.getSeverityDescriptor(issue.severity)}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      display: this.showPDGColumn(),
                      textTransform: 'capitalize',
                    }}
                    title={this.getPDGName(issue)}
                  >
                    {strings.trim(this.getPDGName(issue), 21)}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      display: this.showIssueColumn(),
                      textTransform: 'capitalize',
                    }}
                    title={issue.toPodName}
                  >
                    {strings.trim(issue.toPodName, 21)}
                  </td>
                  <td style={{ textAlign: 'center', display: this.showIssueColumn(), textTransform: 'capitalize' }}>
                    {this.getIssueStatus(issue)}
                  </td>
                  <td style={{ textAlign: 'center', display: this.showIssueColumn() }}>
                    {moment(issue.created).format('DD/MM/YYYY')}
                  </td>
                  <td style={{ textAlign: 'center', display: this.showTaskColumn() }}>
                    {getValueDescriptor(issue.value)}
                  </td>
                  <td style={{ textAlign: 'center', display: this.showTaskColumn() }}>
                    {getUrgencyDescriptor(issue.urgency)}
                  </td>
                  <td style={{ display: this.showTaskColumn() }}>
                    {issue.projectName}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <TagBadges tagIDs={issue.tags} tagsRef={this.state.tags} />
                  </td>
                </tr>
              ))}
            </tbody> 
          </Table>) : (
          <div style={{ 
                    color: 'green',
                    cursor: 'pointer',
                    textAlign: 'center',
                    fontSize: '18px', fontWeight: '700',
                    textTransform: 'uppercase'
                  }}> No issues found</div>
          ) }
        </div>
      </div>
    )
  }

  showIssueColumn() {
    if (this.state.issuesInResults) return 'table-cell'
    return 'none'
  }

  showPDGColumn() {
    if (this.state.issuesInResults && this.state.team &&
      [ENGINEERING_PLATFORM_TEAM_ID, GOJEK_PRODUCT_SECURITY_TEAM_ID].includes(this.state.team.id)) {
      return 'table-cell'
    }
    return 'none'
  }

  showTypeFilter() {
    if (this.state.team && this.state.team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
      return 'none'
    }
    return 'table-cell'
  }

  showProductSecurityFilter() {
    if (this.state.team && this.state.team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
      return 'table-cell'
    }
    return 'none'
  }

  showCustomerRequestTypeFilter() {
    if (this.state.tasksInResults) {
      return 'none'
    }
    if (this.state.team && this.state.team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
      return 'none'
    }
    return 'table-cell'
  }

  getPDGName(issue) {
    if (issue && issue.jira) {
      if (this.state.team && this.state.team.id === ENGINEERING_PLATFORM_TEAM_ID) {
        return issue.jira.fromPDG
      }
      if (this.state.team && this.state.team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID) {
        return issue.jira.toPDG
      }
    } return ''
  }

  showTaskColumn() {
    if (this.state.tasksInResults) return 'table-cell'
    return 'none'
  }

  // standards
  filterStandards(value) {
    let filtered = [...this.state.standards]
    if (value !== 'all') {
      if (value === '') {
        filtered = this.state.standards.filter((standard) => standard.adoption &&
        Object.keys(standard.adoption).length === 0)
      } else {
        filtered = this.state.standards.filter((standard) => standard.adoption &&
        standard.adoption.status === value)
      }
    }

    console.log('v', filtered)
    this.setState({
      filteredStandards: filtered,
      standardsStatusFilter: value,
    })
  }

  getStandardsList() {
    return (
      <div style={{
        padding: '13px', paddingTop: '0px', backgroundColor: 'white', minHeight: '89px',
      }}
      >

        <Row style={{ marginTop: '30px', marginBottom: '30px', fontSize: '12px' }}>
          <Col xs="12" sm="6" md="4" lg="3">
            Status
            <Input
              placeholder=""
              type="select"
              style={{ border: '1px solid #888888', fontSize: '11px' }}
              value={this.state.standardsStatusFilter}
              onChange={(e) => this.filterStandards(e.target.value)}
            >
              <option value="all">All</option>
              <option value="">Not Started</option>
              <option value="planning">Planning</option>
              <option value="agreed">Agreed</option>
              <option value="onTrack">On Track</option>
              <option value="atRisk">At Risk</option>
              <option value="adopted">Fully Adopted</option>
              <option value="notApplicable">Not Applicable</option>
              <option value="refused">Refused</option>
            </Input>
          </Col>

        </Row>

        {this.state.filteredStandards.map((standard, index) => (
          <Row key={index} style={{ backgroundColor: tables.rowColor(index), fontSize: '12px', paddingTop: '8px' }}>
            <Col xs="1">
              <StandardCard standard={standard} key={index} onUpdated={(standard) => this.standardUpdated(standard)} />
            </Col>
            <Col xs="8" style={{ paddingTop: '8px' }}>
              <div style={{ fontWeight: '600', color: 'green' }}>
                {standard.title}
              </div>
              {strings.trim(standard.description, 189)}
              <br />
              <br />
            </Col>
            <Col xs="2" style={{ paddingTop: '10px', textAlign: 'center' }}>
              {this.getStatusText(standard.adoption.status)}
            </Col>
            <Col xs="1" style={{ paddingTop: '10px', textAlign: 'right' }}>
              {this.getOwner(standard.owner)}
            </Col>
          </Row>
        ))}

      </div>
    )
  }

  standardUpdated(standard) {
    // todo
    /*
        var standards = this.state.standards;
        for(var i = 0; i < standards.length; i += 1) {
            if(standard.id === standards[i].id) {
                standards[i] = standard;
                break;
            }
        }
        this.setState({standards: standards});
        */
  }

  getStatusText(value) {
    switch (value) {
      case 'agreed':
        return <div style={{ color: 'lightGreen' }}>AGREED</div>
      case 'planning':
        return <div style={{ color: '#7DF65C' }}>PLANNING</div>
      case 'inProgress':
        return <div style={{ color: 'green' }}>IN PROGRESS</div>
      case 'onTrack':
        return <div style={{ color: 'green' }}>ON TRACK</div>
      case 'adopted':
        return <div style={{ color: 'darkGreen' }}>ADOPTED</div>
      case 'atRisk':
        return <div style={{ color: 'orange' }}>AT RISK</div>
      case 'refused':
        return <div style={{ color: 'maroon' }}>REFUSED</div>
      case 'notApplicable':
        return <div style={{ color: 'gray' }}>N/A</div>
      default:
        return <div style={{ color: 'maroon' }}>NOT STARTED</div>
    }
  }

  getLastUpdated(value) {
    if (value === 'no updates') return <span style={{ color: 'maroon' }}>{value}</span>
    return <span style={{ color: 'gray' }}>{value}</span>
  }

  getLastModifiedWithAuthor() {
    if (this.state.team.modifiedBy) {
      return `Last modified on ${dateTime.timeConverter(this.state.team.modified)} by ${this.state.team.modifiedBy}`
    }

    return `Last modified on ${dateTime.timeConverter(this.state.team.modified)}`
  }

  // notes
  filterNotes(filter, value) {
    let typeFilter = this.state.noteTypeFilter
    if (filter === 'type') {typeFilter = value}

    this.setState({ noteTypeFilter: typeFilter, filteredNotes: [] }, () => {
      const filtered = []
      for (let i = 0; i < this.state.notes.length; i += 1) {
        if (this.filterAttribute(this.state.notes[i].category, typeFilter)) {
          filtered.push(this.state.notes[i])
        }
      }

      this.setState({ filteredNotes: filtered }, this.sortNotes)
    })
  }

  sortNotes() {
    const notes = [...this.state.filteredNotes]
    notes.forEach((note) => {
      const noteRef = note
      noteRef.dateStamp = new Date(noteRef.published).getTime()
    })
    notes.sort(arraySort.byPublishedDateString)

    this.setState({ filteredNotes: notes })
  }

  getNotes() {
    return (
      <div style={{ minHeight: '89px' }}>

        <div style={{ display: this.showResults() }}>
          {this.state.filteredNotes.map((note, index) => (
            <Row key={index}>
              <Col sm="12">
                <Card>
                  <CardHeader style={{
                    height: '28px',
                    padding: '5px',
                    backgroundColor: 'gray',
                    fontWeight: 600,
                    borderTopLeftRadius: '9px',
                    borderTopRightRadius: '9px',
                    color: 'white',
                    paddingLeft: '20px',
                  }}
                  >
                    <Row>
                      <Col xs="8">
                        {note.title}
                      </Col>
                      <Col xs="4" style={{ textAlign: 'right', paddingRight: '30px', color: '#BDFF90' }}>
                        {getNoteTypeDescriptor(note.category)}
                      </Col>
                    </Row>

                  </CardHeader>
                  <CardBody style={{ padding: '20px', fontSize: '13px', fontWeight: 500 }}>

                    <div className="ql-editor" style={{ marginTop: '8px', marginBottom: '0px' }}>
                      {this.getHTML(note.details)}
                    </div>
                    <div style={{
                      marginTop: '-6px', marginBottom: '8px', fontSize: '12px', display: this.showLinks(note.links),
                    }}
                    >
                      <div style={{ color: 'gray', fontSize: '11px' }}>Related Links:</div>
                      {note.links.map((link, noteIdx) => (
                        <div key={noteIdx}>
                          -
                          {' '}
                          <a href={link.url} target="_new" style={{ color: 'green' }}>{link.name}</a>
                        </div>
                      ))}
                    </div>
                    <div>
                      <div style={{ display: 'inline-block' }}>
                        {this.getPersonCard(note.author)}
                      </div>
                      <div style={{
                        display: 'inline-block',
                        fontSize: '11px',
                        fontWeight: 400,
                        marginLeft: '10px',
                        marginTop: '34px',
                        marginBottom: '0px',
                        color: '#434343',
                      }}
                      >
                        {note.createdBy}
                        <br />
                        {dateTime.dateConverter(note.published)}
                      </div>
                    </div>

                    {this.getEditNoteButton(note)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    )
  }

  getEditNoteButton(note) {
    if (note.authorID === this.props.user.person.id) {
      return (
        <NoteEditor
          note={note}
          onDeleted={(currNote) => this.noteDeleted(currNote)}
          onUpdated={(currNote) => this.noteUpdated(currNote)}
        />
      )
    }
  }

  noteCreated(note) {
    note.author = this.props.user.person

    const notes = [...this.state.notes]
    this.setState({ notes: [] }, () => {
      notes.push(note)
      notes.sort(arraySort.byPublishedDateString)
      this.setState({ notes }, this.filterNotes)
    })
  }

  noteUpdated(note) {
    note.author = this.props.user.person

    const notes = [...this.state.notes]
    this.setState({ notes: [] }, () => {
      for (let i = 0; i < notes.length; i += 1) {
        if (notes[i].id === note.id) {
          notes[i] = note
          break
        }
      }
      notes.sort(arraySort.byPublishedDateString)
      this.setState({ notes }, this.filterNotes)
    })
  }

  noteDeleted(id) {
    const notes = [...this.state.notes]
    this.setState({ notes: [] }, () => {
      for (let i = notes.length - 1; i > -1; i -= 1) {
        if (notes[i].id === id) {
          notes.splice(i, 1)
          break
        }
      }
      this.setState({ notes }, this.filterNotes)
    })
  }

  // status reports
  getStatusReports() {
    return (
      <div style={{ minHeight: '89px' }}>

        <div style={{ display: this.showResults() }}>
          {this.state.statusReports.map((statusReport, index) => (
            <Row key={index}>
              <Col sm="12">
                <Card>
                  <CardHeader style={{
                    height: '5px',
                    padding: '5px',
                    backgroundColor: statusReport.status,
                    borderTopLeftRadius: '9px',
                    borderTopRightRadius: '9px',
                  }}
                  />
                  <CardBody style={{ padding: '20px', fontSize: '13px', fontWeight: 500 }}>
                    <div style={{ textAlign: 'right', fontWeight: 600, color: statusReport.status }}>
                      {getStatusDescriptor(statusReport.status)}
                    </div>
                    <div style={{ fontWeight: 600, display: 'inline-block', verticalAlign: 'top' }}>
                      <div style={{ display: 'inline-block' }}>
                        <ProjectCard project={statusReport.project} showThumb />
                      </div>
                      <div style={{
                        display: 'inline-block', verticalAlign: 'top', marginLeft: '20px', color: 'green',
                      }}
                      >
                        {statusReport.project.name}
                        <div style={{ fontWeight: 600, color: '#232323' }}>
                          {statusReport.title}
                        </div>
                      </div>
                    </div>

                    <div className="ql-editor" style={{ marginTop: '20px', marginBottom: '0px' }}>
                      {this.getHTML(statusReport.details)}
                    </div>
                    <div style={{
                      marginTop: '10px',
                      marginBottom: '8px',
                      fontSize: '12px',
                      display: this.showLinks(statusReport.links),
                    }}
                    >
                      <div style={{ color: 'gray', fontSize: '11px' }}>Related Links:</div>
                      {statusReport.links.map((link, linkIdx) => (
                        <div key={linkIdx}>
                          -
                          {' '}
                          <a href={link.url} target="_new" style={{ color: 'green' }}>{link.name}</a>
                        </div>
                      ))}
                    </div>
                    <div>
                      <div style={{ display: 'inline-block' }}>
                        {this.getPersonCard(statusReport.author)}
                      </div>
                      <div style={{
                        display: 'inline-block',
                        fontSize: '11px',
                        fontWeight: 400,
                        marginLeft: '10px',
                        marginTop: '34px',
                        marginBottom: '0px',
                        color: '#434343',
                      }}
                      >
                        {statusReport.createdBy}
                        <br />
                        {dateTime.dateConverter(statusReport.published)}
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    )
  }

  // subteams
  getSubTeams() {
    if (this.state.subTeams.length > 0) {
      return (
        <div>
          <h4>SubTeams</h4>
          <Row>
            <Col xs="12">
              {this.state.subTeams.map((team, index) => (
                <div style={{ display: 'inline-block', marginRight: '5px' }} key={index}>
                  <TeamCard team={team} showLargeThumb />
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )
    }
  }

  getHTML(value) {
    if (value) {
      return Parser(value)
    }
    return ''
  }

  changeTab(tab) {
    if (tab === 'nexus') {
      telemetry.logUserActivity(
          this.props.user.id,
          this.props.user.personID,
          'nexusIssuesTabInTeamPage',
          'pageView',
          this.props.teamID,
      )
    }
    this.setState({ activeTab: tab }, () => {
      if (this.state.activeTab === 'nexus') {
        // Default filter issues which are active
        this.filterIssues('status', this.getDefaultStatusValue())
      }
    })
  }

  showLinks(links) {
    if (links.length > 0) return 'block'
    return 'none'
  }

  metricCreated(metric) {
    const metrics = [...this.state.metrics]
    metrics.push(metric)
    this.setState({ metrics })
  }

  metricUpdated(metric) {
    const metrics = [...this.state.metrics]
    for (let i = 0; i < metrics.length; i += 1) {
      if (metrics[i].id === metric.id) {
        metrics[i] = metric
        break
      }
    }
    this.setState({ metrics })
  }

  openProjectPage(project) {
    window.open(`/projects/${project.slug}`)
  }

  updateTeam(newTeamObj) {
    this.setState({ team: newTeamObj })
  }

  onUpdated(team, members, projects, subTeams, _OKRs, _newParentID) {
    this.setDefaultImg(team)
    team.links.sort((a, b) => b.created - a.created)

    members.sort(arraySort.byName)
    projects.sort(arraySort.byName)

    this.setState({
      team,
      members,
      projects,
      subTeams,
    })

    this.updateTeam(team)
  }

  setDefaultImg(team) {
    if (!team.profilePhoto) {
      team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png`
    }
    if (team.profilePhoto.indexOf('avatar') > -1) {
      team.profilePhoto = `${process.env.PUBLIC_URL}/teamAvatar.png`
    }

    if (!team.photos) {
      team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
    } else if (team.photos.length === 0) {
      team.photos = [{ name: '', caption: '', url: team.profilePhoto }]
    }
  }

  updateInitialLinks(newlinks) {
    this.setState({ initialLinks: newlinks })
  }

  async getTopPageViews(activities) {
    const uniqueVisitors = {}
    const peoplePromises = []
    activities.forEach((activity) => {
      if (![''].includes(activity.personID)) {
        // Exclude Nexus Team Members from pageView metrics?
        const { personID } = activity
        if (personID in uniqueVisitors) {
          uniqueVisitors[personID].count += 1
        } else {
          uniqueVisitors[personID] = {
            personID,
            count: 1,
          }

          peoplePromises.push(db.getPerson(personID))
        }
      }
    })

    Promise.all(peoplePromises).then((people) => {
      for (let p = people.length - 1; p > -1; p -= 1) {
        if (!people[p]) {people.splice(p, 1)}
      }

      people.forEach((person) => {
        if (person.id in uniqueVisitors) {
          person.count = uniqueVisitors[person.id].count
        }
      })

      const sortedVisitors = sortByCount(people)
      this.setState({ visitors: sortedVisitors })
    })
  }

  getSummaryData() {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const promises = []

    let upperBound = new Date().getTime()

    for (let i = 0; i < 14; i += 1) {
      const lowerBound = upperBound - (24 * 60 * 60 * 1000)
      promises.push(
          db.getUserActivityByTypeAndPageIDBetween('pageView', 'nexusIssuesTabInTeamPage', lowerBound, upperBound),
      )
      upperBound = lowerBound
    }

    Promise.all(promises).then((results) => {
      const summaryData = []
      const labels = []
      const totalPageViewsList = []
      const uniqueUsersList = []

      results.forEach((result) => {
        if (result.length > 0) {
          const uniqueUsers = {}
          result.forEach((pageView) => {
            const { personID } = pageView
            if (!(personID in uniqueUsers)) {
              uniqueUsers[personID] = true
            }
          })

          const dateStamp = new Date(result[0].created)
          summaryData.push({
            label: days[dateStamp.getDay()],
            timeStamp: dateStamp.getTime(),
            totalPageViews: result.length,
            uniqueUsers: Object.keys(uniqueUsers).length,
          })
        }
      })

      summaryData.sort((b, a) => b.timeStamp - a.timeStamp)

      summaryData.forEach((data) => {
        labels.push(data.label)
        totalPageViewsList.push(data.totalPageViews)
        uniqueUsersList.push(data.uniqueUsers)
      })

      this.setState({
        pageViewSummary: { data: totalPageViewsList, labels },
        uniqueUsersSummary: { data: uniqueUsersList, labels },
      })
    })
  }

  // main logic
  // eslint-disable-next-line complexity
  render() {
    const loadingDiv = {
      display: this.showLoading(),
      position: 'absolute',
      top: '150px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
    const link = {
      color: '#434343',
      fontWeight: 500,
      fontSize: '12px',
      cursor: 'pointer',
    }
    const activeLink = {
      color: 'white',
      fontWeight: 700,
      fontSize: '12px',
    }
    const activeTab = {
      backgroundColor: 'green',
      borderStyle: 'none',
      borderWidth: '1px 1px 0px 1px',
      borderColor: 'green',
    }
    const tab = {
      backgroundColor: '#dedede',
      marginLeft: '1px',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    const {
      labels, chartData, nexusIssues, team, teams, nexusIssuesPresent,
    } = this.state
    const {
      monthlySeverityData, weeklyCreatedIssues, timeToResolution, timeToRespond,
      weeklyResolvedIssues, averageDurationBySeverity, monthlyAverageDurationBySeverity,
    } = chartData

    const teamChartSlug = `/teamCharts/${this.state.team.slug}`

    return (
      <div style={{ padding: '30px' }}>
        <style>
          {
            `
                        @keyframes spinning {
                            from { transform: rotate(0deg) }
                            to { transform: rotate(360deg) }
                        }
                        .spin {
                            animation-name: spinning;
                            animation-duration: 3s;
                            animation-iteration-count: infinite;
                            /* linear | ease | ease-in | ease-out | ease-in-out */
                            animation-timing-function: linear;
                        }
                        `
          }
        </style>

        <Row>
          <Col xs="12">
            <div style={loadingDiv}>
              <div className="spin">
                <img src={logo} alt="logo" style={{ height: '43px', width: '43px' }} />
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ display: this.showResults() }}>

          <Row>
            <Col xs="12" sm="12" md="9">
              <Card style={{ marginTop: '20px' }}>
                <div style={{
                  backgroundSize: 'cover',
                  height: '200px',
                  width: '100%',
                  backgroundColor: '#aaaaaa',
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px',
                  backgroundImage: `url(${this.state.team.profilePhoto})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  borderBottom: '1px solid green',
                }}
                />
                <CardBody style={{ padding: '50px', paddingTop: '0px' }}>

                  <Row>
                    <Col xs="12" sm="8" style={{ paddingBottom: '10px' }}>
                      <CardTitle tag="h4">{this.state.team.name}</CardTitle>
                      <span style={{ color: '#898989' }}>{this.getLastModifiedWithAuthor()}</span>
                    </Col>
                    <Col xs="12" sm="4" style={{ textAlign: 'right', marginTop: '20px', display: 'inline-block' }}>
                      <Link to={teamChartSlug}>
                        <Button size="sm" onClick={() => this.props.onTeamChartView()} style={{ marginRight: '10px' }}>
                          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            <i style={navIcon} className="nc-icon nc-vector" />
                          </div>
                          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                            Org Chart
                          </div>
                        </Button>
                      </Link>
                      <TeamEditor team={this.state.team} onUpdated={this.onUpdated} isInline />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Nav style={{ marginTop: '10px' }}>
                        <NavItem style={(this.state.activeTab === 'welcome' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'welcome' ? activeLink : link)}
                            onClick={() => this.changeTab('welcome')}
                          >
                            Welcome
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'nexus' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'nexus' ? activeLink : link)}
                            onClick={() => this.changeTab('nexus')}
                          >
                            Nexus
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'OKRs' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'OKRs' ? activeLink : link)}
                            onClick={() => this.changeTab('OKRs')}
                          >
                            OKRs
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'standards' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'standards' ? activeLink : link)}
                            onClick={() => this.changeTab('standards')}
                          >
                            Standardisation
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'status' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'status' ? activeLink : link)}
                            onClick={() => this.changeTab('status')}
                          >
                            Status Reports
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'notes' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'notes' ? activeLink : link)}
                            onClick={() => this.changeTab('notes')}
                          >
                            Notes
                          </NavLink>
                        </NavItem>
                        <NavItem style={(this.state.activeTab === 'links' ? activeTab : tab)}>
                          <NavLink
                            style={(this.state.activeTab === 'links' ? activeLink : link)}
                            onClick={() => this.changeTab('links')}
                          >
                            Links
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={this.state.activeTab}
                        style={{ borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: '#888888' }}
                      >
                        <TabPane tabId="welcome" style={{ minHeight: '210px', paddingTop: '17px' }}>
                          <br />
                          <Row>
                            <Col className="ql-editor" xs="12">
                              {this.getHTML(this.state.team.homePage)}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="nexus" style={{ minHeight: '210px' }}>
                          <Row style={{ marginTop: '20px' }}>
                            <Col xs="6">
                              <IssuesBySeverityBarGraph labels={labels} monthlySeverityData={monthlySeverityData} />
                            </Col>
                            <Col xs="6">
                              <OpenIssuesByPod issues={nexusIssues} teams={teams} team={team} />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              {this.getIssuesList()}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane
                          tabId="OKRs"
                          style={{
                            minHeight: '210px',
                            padding: '20px',
                            paddingTop: '17px',
                            fontSize: '12px',
                            backgroundColor: '#efefef',
                          }}
                        >
                          {this.state.team.id && <OKRs team={this.state.team} />}
                        </TabPane>
                        <TabPane
                          tabId="notes"
                          style={{
                            minHeight: '210px',
                            padding: '20px',
                            paddingTop: '17px',
                            fontSize: '12px',
                            backgroundColor: '#efefef',
                          }}
                        >
                          <Row>
                            <Col xs="6">
                              Category
                              <Input
                                placeholder=""
                                type="select"
                                style={{ border: '1px solid #888888', fontSize: '11px' }}
                                value={this.state.noteTypeFilter}
                                onChange={(e) => this.filterNotes('type', e.target.value)}
                              >
                                <option value="all">All</option>
                                <option value="faq">Frequently Asked Question (FAQ)</option>
                                <option value="meeting">Meeting Notes</option>
                                <option value="planning">Planning</option>
                                <option value="topic">Topic</option>
                                <option value="other">Other</option>
                              </Input>
                              <br />
                            </Col>
                            <Col xs="6" style={{ textAlign: 'right' }}>
                              <NoteEditor teamID={this.props.teamID} onCreated={(note) => this.noteCreated(note)} />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '20px' }}>
                            <Col xs="12">
                              {this.getNotes()}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="standards" style={{ minHeight: '210px' }}>
                          {this.getStandardsList()}
                        </TabPane>

                        <TabPane
                          tabId="status"
                          style={{
                            minHeight: '210px',
                            padding: '20px',
                            paddingTop: '17px',
                            fontSize: '12px',
                            backgroundColor: '#efefef',
                          }}
                        >
                          <Row style={{ marginTop: '20px' }}>
                            <Col xs="12">
                              {this.getStatusReports()}
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="links" style={{ minHeight: '210px' }}>
                          <LinkTab
                            initialLinks={this.state.initialLinks}
                            team={this.state.team}
                            updateInitialLinks={this.updateInitialLinks}
                            updateTeam={this.updateTeam}
                            user={this.props.user}
                          />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {(this.state.activeTab !== 'nexus' || !this.state.nexusIssuesPresent) && (
              <Col xs="12" sm="12" md="3">

                <h4>Team Members</h4>

                <Card style={{
                  marginTop: '20px', padding: '10px', paddingTop: '20px', paddingBottom: '20px',
                }}
                >
                  <CardBody style={{ paddingTop: '0px' }}>
                    <Row>
                      <Col xs="12">
                        {this.state.members.map((person, index) => (
                          <div key={index} style={{ display: 'inline-block', marginLeft: '2px', marginRight: '2px' }}>
                            <PersonCard person={person} showLargeThumb />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {this.getSubTeams()}

                <h4>Metrics</h4>
                {this.state.metrics.map((metric, index) => (
                  <Row key={index}>
                    <Col xs="12">
                      <MetricCard teamID={this.state.team.id} metric={metric} onUpdated={(m) => this.metricUpdated(m)} />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col xs="12">
                    <MetricCard teamID={this.state.team.id} onCreated={(m) => this.metricCreated(m)} />
                  </Col>
                </Row>

                <h4>Projects</h4>

                {this.state.projects.map((project, index) => (
                  <Card
                    key={index}
                    style={{ marginTop: '20px', cursor: 'pointer' }}
                    onClick={() => this.openProjectPage(project)}
                  >
                    <CardHeader style={{
                      backgroundColor: project.currentStatus,
                      height: '3px',
                      padding: '5px',
                      borderTopLeftRadius: '9px',
                      borderTopRightRadius: '9px',
                    }}
                    />
                    <CardBody style={{ paddingTop: '15px', paddingBottom: '13px' }}>
                      <div style={{ width: '100%' }}>
                        <div style={{ display: 'inline-block', width: '93px' }}>
                          <ProjectCard project={project} showLargeThumb />
                        </div>
                        <div style={{
                          display: 'inline-block',
                          marginLeft: '13px',
                          verticalAlign: 'top',
                          maxWidth: 'calc(100% - 130px)',
                        }}
                        >
                          <div style={{ fontWeight: 'bold' }}>{project.name}</div>
                          <div style={{ fontSize: '11px' }}>{project.currentStatusDetails}</div>
                          <div style={{ fontStyle: 'italic', fontSize: '10px', marginTop: '3px' }}>
                            Last Updated:
                            {this.getLastUpdated(project.lastUpdated)}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}

              </Col>
            )}
            {this.state.activeTab === 'nexus' && nexusIssuesPresent && (
              <Col xs="12" sm="12" md="3">
                <br />
                <IssuesReportByPod
                  issues={nexusIssues}
                  teams={teams}
                  labels={labels}
                  team={team}
                />
                <br />
                <IssuesReportByPodPieChart
                  issues={nexusIssues}
                  teams={teams}
                  team={team}
                />
                <br />
                <CreatedVsResolvedIssues
                  weeklyCreatedIssues={weeklyCreatedIssues}
                  weeklyResolvedIssues={weeklyResolvedIssues}
                />
                <br />
                <SLAResolutionResponseTimeBarGraph
                  timeToResolution={timeToResolution}
                  timeToRespond={timeToRespond}
                  labels={labels}
                />
                <br />
                <SLAReportByPod
                  issues={nexusIssues}
                  teams={teams}
                  team={team}
                  labels={labels}
                />
                <br />
                <SLABreachedByTeam
                  issues={nexusIssues}
                  teams={teams}
                  chartTitle="SLA BREACHED BY POD"
                />
                <br />
                <IssuesReportBySeverity
                  labels={labels}
                  monthlySeverityData={monthlySeverityData}
                  totalIssues={nexusIssues.length}
                />
                <br />
                <AverageDaysOfCompletion averageDurationBySeverity={averageDurationBySeverity} isDateRangeSelected />
                <br />
                <MonthlyAverageDaysToCompletion monthlyAverageDurationBySeverity={monthlyAverageDurationBySeverity} labels={labels} />
                <br />
                <ReassignedTicketsByPodPieChart
                  issues={this.state.nexusIssues}
                  teams={teams}
                  team={team}
                />

                {this.state.activeTab === 'nexus' && this.state.team && this.state.team.id === GOJEK_PRODUCT_SECURITY_TEAM_ID && (
                  <>
                    <TableSummaryChart
                      tableData={this.state.visitors}
                      cardHeading="TOP PAGE VIEWS - Last 2 weeks"
                      cardSummaryLabel="Unique Visitors:"
                      tableInfoHeading="Name"
                      countHeading="Page Views"
                      filename="topPageViews"
                      downloadIconTitle="Export Top Page Views"
                    />
                    <TopPageViewsByPDG teams={this.state.teams} activities={this.state.activities} />
                    <LineSummaryChart
                      summary={this.state.pageViewSummary}
                      label="Page Views"
                      lineColor="orange"
                      chartHeading="Page views - last 2 weeks"
                    />
                    <LineSummaryChart
                      summary={this.state.uniqueUsersSummary}
                      label="Unique Users"
                      lineColor="green"
                      chartHeading="unique users - last 2 weeks"
                    />
                  </>
                )}
              </Col>
            )}
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

// eslint-disable-next-line no-class-assign
TeamPage = connect(mapStateToProps)(TeamPage)
export default TeamPage
