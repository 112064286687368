import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { db } from '../assets/firebase'
import { presence } from '../assets/utils'
import Navbar from '../components/Navbars/OrgChartNavbar'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import { UserRedirected } from '../redux/actions'
import GoConnect from '../views/GoConnect'
import OKRsPage from '../views/OKRs/OKRsPage'
import ProjectPage from '../views/Project/ProjectPage'
import ProjectTracker from '../views/ProjectTracker/ProjectTracker'
import TeamPage from '../views/team/TeamPage'

class OrgChartLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchQuery: '',
      searchView: false,
      landingPageView: false,
      orgChartView: false,
      teamChartView: false,
      teamView: false,
      projectView: false,
      projectTrackerView: false,
      okrView: false,
      othersPresent: [],
      totalOthers: 0,
      isPageLoading: true,
    }

    if (this.props.projectTracker) {
      this.state.projectTrackerView = true
    } else if (this.props.team) {
      if (this.props.teamChartView) {
        this.state.teamChartView = this.props.teamChartView || false
      } else if (this.props.teamView) {
        this.state.teamView = this.props.teamView || false
      }

      if (this.props.match.params.teamID) {
        this.state.teamID = this.props.match.params.teamID
        this.state.isPageLoading = false
      } else if (this.props.match.params.teamSlug) {
        db.getTeamBySlug(this.props.match.params.teamSlug).then((team) => {
          this.state.teamID = team && team.id
          this.state.isPageLoading = false
        })
      }
    } else if (this.props.project) {
      this.state.projectView = true

      if (this.props.match.params.projectID) {
        this.state.projectID = this.props.match.params.projectID
      } else if (this.props.match.params.projectSlug) {
        db.getProjectBySlug(this.props.match.params.projectSlug).then((project) => {
          this.state.projectID = project && project.id
          this.state.isPageLoading = false
        })
      }
    } else if (this.props.okrs) {
      this.state.okrView = true
    } else {
      this.state.landingPageView = true
    }

    this.orgChartView = this.orgChartView.bind(this)
    this.searchText = this.searchText.bind(this)
    this.teamChartView = this.teamChartView.bind(this)
    this.teamPageView = this.teamPageView.bind(this)
    this.setLandingPageView = this.setLandingPageView.bind(this)
  }

  setLandingPageView() {
    this.setState({
      projectView: false,
      landingPageView: true,
      orgChartView: false,
      teamChartView: false,
      teamView: false,
      projectTrackerView: false,
      searchQuery: '',
      searchView: false,
      okrView: false,
    })
  }

  componentDidMount() {
    this.getPresence()
    setInterval(() => this.getPresence(), 60000)
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
    }
  }

  getPresence() {
    let pageID = 'orgChart'
    if (this.state.projectTrackerView) {
      pageID = 'projectTracker'
    } else if (this.state.teamView) {
      pageID = 'teamPage'
    } else if (this.state.projectView) {
      pageID = 'projectPage'
    } else if (this.state.okrView) {
      pageID = 'okrsPage'
    }

    presence.getOthersPresent(this.props.user.personID, pageID).then((result) => {
      this.setState({ othersPresent: [] }, () => {
        this.setState({ othersPresent: result.people, totalOthers: result.totalOthers })
      })
    })
  }

  handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini')
  }

  searchText(query) {
    this.setState({
      projectTrackerView: false, projectView: false, teamView: false, orgChartView: false, searchView: true,
    }, () => {
      this.setState({ searchQuery: query })
    })
  }

  orgChartView() {
    this.setState({
      landingPageView: false,
      orgChartView: true,
      teamView: false,
      projectView: false,
      teamChartView: false,
      searchQuery: '',
      searchView: false,
      okrView: false,
    })
  }

  teamChartView() {
    this.setState({
      landingPageView: false,
      orgChartView: false,
      teamView: false,
      projectView: false,
      teamChartView: true,
      searchQuery: '',
      searchView: false,
      okrView: false,
    })
  }

  // FIXME: This is a temporary hack to allow us to redirect to team's page from the main index route
  //
  //        Ideally, all of these shouldn't be in a single component. Each page needs to have a seperate component
  //        and hosted on different routes
  //
  //        We shouldn't need these states in order to pinpint which component to render
  teamPageView(teamID) {
    this.setState({
      teamID,
      teamView: true,
      isPageLoading: false,
    })
  }


  orgChart() {
    if (!this.state.projectTrackerView && !this.state.teamView && !this.state.projectView && !this.state.okrView) {
      return (
        <div style={{ marginTop: '63px' }}>
          <GoConnect
            landingPageView={this.state.landingPageView}
            onOrgChartView={this.orgChartView}
            onSearch={this.searchText}
            onTeamChartView={this.teamChartView}
            onTeamPageView={this.teamPageView}
            orgChartView={this.state.orgChartView}
            personID={this.props.match.params.personID}
            projectID={this.props.match.params.projectID}
            searchQuery={this.state.searchQuery}
            setLandingPageView={this.setLandingPageView}
            teamChartView={this.state.teamChartView}
            teamID={this.props.match.params.teamID}
            teamSlug={this.props.match.params.teamSlug}
          />
        </div>
      )
    }
  }

  projectTracker() {
    if (this.state.projectTrackerView) {
      return (
        <div style={{ marginTop: '48px' }}>
          <ProjectTracker teamID={this.props.match.params.teamID} />
        </div>
      )
    }
  }

  projectPage() {
    if (this.state.projectView && !this.state.isPageLoading) {
      return (
        <div style={{ marginTop: '48px' }}>
          {
            this.state.projectID ?
              <ProjectPage projectID={this.state.projectID} /> :
              <PageNotFound setLandingPageView={this.setLandingPageView} />
          }
        </div>
      )
    }
  }

  teamPage() {
    if (this.state.teamView && !this.state.isPageLoading) {
      return (
        <div style={{ marginTop: '48px' }}>
          {
            this.state.teamID ? (
              <TeamPage
                teamID={this.state.teamID}
                onTeamChartView={this.teamChartView}
              />
            ) : (
              <PageNotFound setLandingPageView={this.setLandingPageView} />
            )
          }
        </div>
      )
    }
  }

  okrsPage() {
    if (this.state.okrView) {
      return (
        <div style={{ marginTop: '20px' }}>
          <OKRsPage />
        </div>
      )
    }
  }

  render() {
    if (!this.props.user.authenticated) {
      const { personID } = this.props.match.params
      if (personID) {
        this.props.onUserRedirected(`/orgChart/${personID}`)
      } else {
        this.props.onUserRedirected('/orgChart')
      }
      return <Redirect to="/login" />
    }

    return (
      <div className="wrapper" style={{ backgroundColor: '#efefef' }}>
        <Navbar
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
          handleMiniClick={this.handleMiniClick}
          okrsView={this.state.okrView}
          onSearch={this.searchText}
          onLandingPageViewClick={() => this.setState({
            landingPageView: true,
            orgChartView: false,
            teamChartView: false,
            teamView: false,
            projectView: false,
            projectTrackerView: false,
            searchQuery: '',
            searchView: false,
            okrView: false,
          })}
          onOKRViewClick={() => this.setState({
            landingPageView: false,
            teamChartView: false,
            teamView: false,
            projectView: false,
            orgChartView: false,
            projectTrackerView: false,
            searchQuery: '',
            searchView: false,
            okrView: true,
          })}
          onOrgChartViewClick={() => this.setState({
            landingPageView: false,
            orgChartView: true,
            teamChartView: false,
            teamView: false,
            projectView: false,
            projectTrackerView: false,
            searchQuery: '',
            searchView: false,
            okrView: false,
          })}
          onTeamChartViewClick={() => this.setState({
            landingPageView: false,
            teamChartView: true,
            teamView: false,
            projectView: false,
            orgChartView: false,
            projectTrackerView: false,
            searchQuery: '',
            searchView: false,
            okrView: false,
          })}
          orgChartView={this.state.orgChartView}
          othersPresent={this.state.othersPresent}
          projectTrackerView={this.state.projectTrackerView}
          searchQuery={this.state.searchQuery}
          searchView={this.state.searchView}
          teamChartView={this.state.teamChartView}
          totalOthers={this.state.totalOthers}
        />

        {this.okrsPage()}
        {this.orgChart()}
        {this.projectTracker()}
        {this.projectPage()}
        {this.teamPage()}

      </div>
    )
  }
}

const mapStateToProps = (state) => state

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onUserRedirected: UserRedirected,
  }, dispatch)
}

// eslint-disable-next-line no-class-assign
OrgChartLayout = connect(mapStateToProps, mapDispatchToProps)(OrgChartLayout)
export default OrgChartLayout
