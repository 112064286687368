import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import { SEVERITY_COLORS, SEVERITY_TYPES } from './utils'

export default function AverageDaysOfCompletion({ isDateRangeSelected, averageDurationBySeverity }) {
  return (
    <Card>
      <CardBody>
        <h6 className="big-title">
          Average Days To Completion
          {!isDateRangeSelected && <span> - Last 30 Days</span>}
        </h6>
        <Table striped>
          <thead>
            <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
              <td>Status</td>
              <td style={{ textAlign: 'center' }}>Average duration (days/hours)</td>
            </tr>
          </thead>
          <tbody>
            {SEVERITY_TYPES.map((severity) => (
              <tr style={{ fontSize: '11px' }} key={severity}>
                <td style={{ textTransform: 'uppercase', fontWeight: 'bold', color: SEVERITY_COLORS[severity] }}>
                  {severity}
                </td>
                <td style={{ textAlign: 'center' }}>{averageDurationBySeverity[severity]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
