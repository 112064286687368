import React from "react";

import { 
    Button, CustomInput,
    Row, Col, 
    Modal, ModalBody, ModalFooter
} from "reactstrap";

import { db } from "assets/firebase";

class CleverTapEventEditor extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            owner: props.owner
        }

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.owner !== this.props.owner) {
           this.setState({owner: this.props.owner});
        }
    }

    toggleModal() {
        this.setState({modal: !this.state.modal});
    }

    updateOwner(property, value) {
        var obj = this.state.owner;
        obj[property] = value;
        this.setState({owner: obj});
    }

    saveOwner() {
        this.props.onUpdated(this.state.owner);
        db.updateCleverTapOwner(this.state.owner.id, this.state.owner).then(() => {

            this.toggleModal();
        })
    }

    render() {

        return (
            <div>
                <Button color="secondary" size="sm" onClick={this.toggleModal}>Edit</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col sm="12">
                               <h4>Event Editor</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div style={{color:"green", fontWeight:600, marginBottom:"14px"}}>
                                    {this.state.owner.eventName}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="thirdParty"
                                        checked={this.state.owner.thirdParty} onChange={(e) => this.updateOwner("thirdParty", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>3PP</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="adTech"
                                        checked={this.state.owner.adTech} onChange={(e) => this.updateOwner("adTech", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>AdTech</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="cartography" 
                                        checked={this.state.owner.cartography} onChange={(e) => this.updateOwner("cartography", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Cartography</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="commerceEnablement" 
                                        checked={this.state.owner.commerceEnablement} onChange={(e) => this.updateOwner("commerceEnablement", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Commerce Enablement</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="crm" 
                                        checked={this.state.owner.crm} onChange={(e) => this.updateOwner("crm", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>CRM</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="customer" 
                                        checked={this.state.owner.customer} onChange={(e) => this.updateOwner("customer", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Customer Platform</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="devx" 
                                        checked={this.state.owner.devx} onChange={(e) => this.updateOwner("devx", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>DevX</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="driver" 
                                        checked={this.state.owner.driver} onChange={(e) => this.updateOwner("driver", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Driver Platform</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="food"
                                        checked={this.state.owner.food} onChange={(e) => this.updateOwner("food", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Food</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="goclub" 
                                        checked={this.state.owner.goclub} onChange={(e) => this.updateOwner("goclub", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>GoClub</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="gofin" 
                                        checked={this.state.owner.gofin} onChange={(e) => this.updateOwner("gofin", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>GoFin</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="groceries" 
                                        checked={this.state.owner.groceries} onChange={(e) => this.updateOwner("groceries", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Groceries</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="icp" 
                                        checked={this.state.owner.icp} onChange={(e) => this.updateOwner("icp", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>ICP</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="logistics" 
                                        checked={this.state.owner.logistics} onChange={(e) => this.updateOwner("logistics", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Logistics Platform</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="marketplace" 
                                        checked={this.state.owner.marketplace} onChange={(e) => this.updateOwner("marketplace", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Marketplace</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="merchantPayment" 
                                        checked={this.state.owner.merchantPayment} onChange={(e) => this.updateOwner("merchantPayment", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Merchant Payment</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="merchant" 
                                        checked={this.state.owner.merchant} onChange={(e) => this.updateOwner("merchant", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Merchant Platform</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="ops" 
                                        checked={this.state.owner.ops} onChange={(e) => this.updateOwner("ops", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Ops Platform</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="pay" 
                                        checked={this.state.owner.pay} onChange={(e) => this.updateOwner("pay", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Pay</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="play" 
                                        checked={this.state.owner.play} onChange={(e) => this.updateOwner("play", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Play</div>
                            </Col>
                            <Col sm="6" md="4">
                                <div style={{display:"inline-block"}}>
                                    <CustomInput type="switch" id="transport" 
                                        checked={this.state.owner.transport} onChange={(e) => this.updateOwner("transport", e.target.checked)}
                                    />
                                </div>
                                <div style={{display:"inline-block"}}>Transport</div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{padding:"10px"}}>
                            <Button onClick={() => this.toggleModal()}>Cancel</Button>
                            <Button onClick={() => this.saveOwner()} color="success">Save</Button>
                        </div>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}

export default CleverTapEventEditor;