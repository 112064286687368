import {
  signInWithPopup,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { FaGoogle } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'

import { makeRequest } from '../../api/requests'
import {
  auth, db, gojekAuth,
} from '../../assets/firebase'
import wideLogo from '../../assets/img/goconnect.png'
import logo from '../../assets/img/goConnectIcon.png'
import { telemetry } from '../../assets/utils'
import { useToast } from '../../context/UseToast'
import { UserLoggedIn, UserLoggedOut } from '../../redux/actions'

function LoginPage({ onUserLoggedIn }) {
  const [loggedIn, setLoggedIn] = useState(false)
  const [missingPerson, setMissingPerson] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const { showToast } = useToast()
  const history = useHistory()

  const setBearerToken = (user) => {
    user.getIdToken().then((token) => {
      makeRequest('/auth/token', 'POST', { 'Content-Type': 'application/json' }, { token }).then((resp) => {
        localStorage.setItem('bearer-token', resp.token)
      })
    }).catch(() => {
      showToast('Unable to issue token!', 'error')
      auth.signOut()
      localStorage.removeItem('id-token')
    })
  }

  const completeLogin = (user) => {
    setBearerToken(user)

    db.getPersonByEmail(user.email).then((person) => {
      if (person) {
        if (!person.userID) {
          const personRef = person
          personRef.userID = user.uid
          db.updatePerson(person.id, person)
        }

        telemetry.logUserActivity(user.uid, person.id, 'login', 'login')

        onUserLoggedIn({
          id: user.uid,
          email: user.email,
          photo: user.photoURL,
          name: user.displayName,
          personID: person.id,
          isAdmin: person.isAdmin,
          person,
        })

        setLoggedIn(true)
      } else {
        setMissingPerson(true)
        showToast('Unable to login!', 'error')
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    auth.signOut()
    setLoading(false)
    showToast('You have been logged out. Please login to continue', 'error')
  }, [])

  const showWelcome = () => {
    if (!isLoading && !missingPerson) return 'block'
    return 'none'
  }

  const showLoading = () => {
    if (isLoading) return 'block'
    return 'none'
  }

  const showMissingPerson = () => {
    if (missingPerson) return 'block'
    return 'none'
  }

  const login = () => {
    setLoading(true)
    signInWithPopup(auth, gojekAuth).then((resp) => {
      completeLogin(resp.user)
    }).catch(() => {
      showToast('Unable to login!', 'error')
    })
  }

  if (loggedIn) {
    const lastLocation = localStorage.getItem('logoutLocation')
    if (lastLocation) {
      localStorage.removeItem('logoutLocation')
      history.push(lastLocation)
    } else {
      return <Redirect to="/" />
    }
  }

  return (
    <div>
      <style>
        {`
          @keyframes spinning {
            from { transform: rotate(0deg) }
            to { transform: rotate(360deg) }
          }
          .spin {
            animation-name: spinning;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        `}
      </style>

      <div style={{
        display: showLoading(),
        position: 'absolute',
        top:
         '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
      >
        <div className="spin">
          <img src={logo} alt="logo" style={{ height: '54px', width: '54px' }} />
        </div>
      </div>

      <div style={{
        display: showWelcome(),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
      >
        <img src={wideLogo} alt="logo" style={{ height: '50px' }} />
        <br />
        <div>
          <Button color="secondary" onClick={login} style={{ marginLeft: '25px' }}>
            <span style={{
              borderRight: '1px solid lightgray',
              paddingRight: '0.5rem',
              textAlign: 'left',
              marginRight: '18px',
              marginLeft: '-10px',
            }}
            >
              <FaGoogle size={15} />
            </span>
            <span>LOGIN</span>
          </Button>
        </div>
      </div>

      <div style={{
        display: showMissingPerson(),
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '13px',
        textAlign: 'center',
      }}
      >
        <img src={wideLogo} alt="logo" style={{ height: '50px' }} />
        <br />
        <br />
        You have logged in successfully but your employee profile was not found.
        <br />
        <br />
        If you are new to Gojek it may take a few days for your profile to be added.
        <br />
        <br />
        For support please send mail to
        {' '}
        <a href="mailto:tpm@gojek.com">tpm@gojek.com</a>
        .
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onUserLoggedIn: UserLoggedIn,
  onUserLoggedOut: UserLoggedOut,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
