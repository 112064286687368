import React from "react";
import classnames from "classnames";
import { connect } from 'react-redux'
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";

import logo from "assets/img/gojekIcon.png";

class PACNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      showSettings: false
    };

  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }

    if(e.othersPresent !== this.props.othersPresent) {
      this.setState({othersPresent: this.props.othersPresent});
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };


  searchText() {
    this.props.onSearch(this.state.query);
    
  }


  render() {
    return (
      <div>
        
        <Navbar
          className={classnames("navbar-absolute fixed-top", "bg-white")}
          expand="lg" 
        >
          <Container fluid>
            <div className="navbar-wrapper" style={{cursor:"pointer"}}  >
                <div style={{display:"inline-block"}}>
                    <img src={logo} alt="react-logo" style={{marginTop:"0px", marginBottom:"0px", marginLeft:"4px", marginRight:"10px", height:"34px", paddingRight:"0px" }} />
                </div>
                <div style={{display:"inline-block", fontSize:"24px", fontWeight:"bold"}}>
                  Gojek
                </div>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                
                <NavItem >
                  <NavLink>
                    <div style={{marginTop:"5px"}}>
                    <PersonCard person={this.props.user.person} showThumb />
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

PACNavbar = connect(mapStateToProps)(PACNavbar);
export default PACNavbar;
