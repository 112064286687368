import './NexusRequestData.css'

import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  Card, CardBody, Col, Row,
} from 'reactstrap'

import { getTeam } from '../../assets/firebase/db'
import { dateTime, tables } from '../../assets/utils'
import Chip from '../Chip/Chip'
import TeamCard from '../TeamCard/TeamCard'
import { getPriorityColor, getStatusColor } from './constants'

export default function NexusRequestData({ request }) {
  const {
    title,
    status,
    fromTeamID,
    toTeamID,
    severity,
    description,
    impactAssessment,
    countries,
    targetDate,
    links,
    attachments,
    created,
  } = request

  const [fromTeamData, setFromTeamData] = useState({})
  const [toTeamData, setToTeamData] = useState({})

  useEffect(() => {
    async function fetchData() {
      const fromTeamInfo = await getTeam(fromTeamID)
      const toTeamInfo = await getTeam(toTeamID)

      setFromTeamData(fromTeamInfo)
      setToTeamData(toTeamInfo)
    }
    fetchData()
  }, [])

  return (
    <Card className="request-data-container">
      <CardBody>
        <Row className="request-data">
          <Col md="12" className="request-data-heading">
            <h1 className="request-data-title">{title}</h1>
            <Chip text={status} color={getStatusColor(status)} />
            <Chip text={severity.label} color={getPriorityColor(severity.value)} />
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="6">
            <p className="request-data-info-title">Created Date</p>
            <p className="request-data-values">
              {format(
                  new Date(created),
                  'dd MMMM yyyy',
              )}
            </p>
          </Col>
          <Col xs="6">
            <p className="request-data-info-title">Target Date</p>
            <p className="request-data-values">
              {format(
                  new Date(targetDate),
                  'dd MMMM yyyy',
              ) || 'NA'}
            </p>
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="6">
            <p className="required request-data-info-title">Your team</p>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <TeamCard team={fromTeamData} showThumb />
            </div>
            <div
              style={{
                display: 'inline-block',
                marginLeft: '15px',
                verticalAlign: 'middle',
              }}
            >
              {fromTeamData.name}
            </div>
          </Col>
          <Col xs="6">
            <p className="required request-data-info-title">Assigned team</p>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <TeamCard team={toTeamData} showThumb />
            </div>
            <div
              style={{
                display: 'inline-block',
                marginLeft: '15px',
                verticalAlign: 'middle',
              }}
            >
              {toTeamData.name}
            </div>
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="12">
            <p className="required request-data-info-title">Description</p>
            <p className="request-data-values">{description}</p>
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="12">
            <p className="required request-data-info-title">Impact Assessment</p>
            <p className="request-data-values">{impactAssessment}</p>
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="12">
            <p className="request-data-info-title">Country</p>
            <div className="request-data-countries">
              {countries.map((country) => (
                <div className="country-tag" key={country.value}>
                  {country.label}
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row className="request-data">
          <Col xs="12">
            <p className="request-data-info-title">Links / Attachments</p>
          </Col>
          <Col xs="12">

            {links && links.map((link, index) => (
              <Row
                key={index}
                style={{
                  backgroundColor: tables.rowColor(index),
                  fontSize: '12px',
                  padding: '6px',
                  margin: 0,
                }}
              >
                <Col xs="1">
                  <i
                    style={{
                      fontSize: '17px',
                      marginTop: '0px',
                      color: '#000000',
                    }}
                    className="nc-icon nc-globe"
                    title="link"
                  />
                </Col>
                <Col xs="8">
                  <a href={link.url} target="_new" style={{ color: '#232323' }}>{link.name}</a>
                  <br />
                </Col>
                <Col xs="3" style={{ textAlign: 'right', color: '#898989' }}>
                  {dateTime.dateConverter(link.created)}
                  <br />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
