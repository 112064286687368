//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//db
import { db } from 'assets/firebase';

//dates
import moment from 'moment';

//charts
import { Bar, Line } from "react-chartjs-2";

//date time
import ReactDatetime from "react-datetime";

// reactstrap components
import {
    Button,
    Card, CardHeader, CardBody, 
    Modal, ModalBody, ModalFooter,
    Input,
    Row, Col,
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink,
  } from "reactstrap";


  //kahala
  import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

let chartColor = "#FFFFFF";

const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
};

function getFormattedDate(value) {

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
  
    var dt = new Date(value);


    return dt.getFullYear() + '.' + addZero(dt.getMonth()+1) + '.' + addZero(dt.getDate());
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  
class MetricCard extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            metric: {
                name: '',
                description: '',
                visualisation: 'bar',
                days: 7,
                label: ''
            },
            values: [],
            data: [],
            newDate: '',
            newValue: ''
        };

        if(this.props.metric)
            this.state.metric = this.props.metric;

        this.loadChartData = this.loadChartData.bind(this);
        this.saveMetric = this.saveMetric.bind(this);
        this.updateMetric = this.updateMetric.bind(this);
    }

    componentWillReceiveProps(nextProps){
        
        if(nextProps.projectID) {
            this.setState({projectID: nextProps.projectID});
        }
        if(nextProps.teamID) {
            this.setState({teamID: nextProps.teamID});
        }
    }

    componentDidMount() {
        this.loadChartData();   
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x); 
    }

    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadChartData() {

        if(this.state.metric.id) {
   

            db.getMetricValues(this.state.metric.id).then(values => {

                values.forEach(value => {
                    value.dateStamp = new Date(value.date).getTime();
                })

                values.sort( (a,b) => { return b.dateStamp - a.dateStamp } );

            
                var labels = [];
                var data = [];
                
                var today = moment();
                today.hour(0); today.minute(0); today.second(0); today.millisecond(0);
                today.add(1,'days').valueOf()
                var days = Number(this.state.metric.days);

                for(var d = days; d > -1; d--) {
            
                    var label = today.format("MM-DD");
                    labels.unshift(label);
                    
                    var dataFound = false;
                    for(var j = 0; j < values.length; j++) {
                        if(values[j].date === today.format("YYYY-MM-DD")) {
                            
                            var value = Number(values[j].value);
                            data.unshift(value);
        
                            dataFound = true;
        
                            break;
                        }
                    }
        
                    if(!dataFound)
                        data.unshift(0);
        
                    today.add(-1,'days');
                }

                this.setState({values: values, labels: labels, data: data });

            });
        }
    }

    deleteMetric() {

        db.deleteMetricValues(this.state.metric.id).then(() => {
            db.deleteMetric(this.state.metric.id).then(() => {
                this.setState({modal: false}, () => {
                    this.props.onDeleted(this.state.metric.id);
                });
            });
        });
    }

    updateMetric(property, value) {
        var obj = this.state.metric;
        obj[property] = value;
        this.setState({metric: obj});
        
        if(property === 'visualisation')
            this.loadChartData();
    }

    saveMetric() {
    
        var metric = this.state.metric;
        if(this.props.projectID)
            metric.projectID = this.props.projectID;
        if(this.props.teamID)
            metric.teamID = this.props.teamID;

        if(!metric.created)
            metric.created = new Date().getTime();
        metric.modified = new Date().getTime();
        
        db.updateMetric(metric.id, metric).then((result) => {

            if(this.props.onCreated) { 

                metric.id = result.id;

                this.props.onCreated(metric);
                this.setState({metric:{
                    name: '',
                    description: '',
                    visualisation: '',
                    days: 7
                }});

            } else {

                this.props.onUpdated(this.state.metric);
            }

            this.handleClose("modal");
            this.loadChartData();
        });
    }

    saveValue() {

        var value = {
            date: this.state.newDate,
            value: this.state.newValue,
            metricID: this.state.metric.id,
            created: new Date().getTime()
        }

        db.updateMetricValue(value.id, value).then((result) => {

            value.id = result.id;
            var values = this.state.values;
            values.push(value);

            this.setState({
                values: values,
                newDate: '',
                newValue: ''
            }, this.loadChartData);

        });
    }

    deleteValue(value) {

        db.deleteMetricValue(value.id).then(() => {

            var values = this.state.values;
            for(var i = values.length -1; i>-1; i--) {
                if(values[i].id === value.id) {
                    values.splice(i);
                    break;
                }
            }
            this.setState({values: values}, this.loadChartData);
        })
    }

    showCard() {
        if(this.state.metric.id) 
            return "block"
        else 
            return "none";
    }

    showButton() {
        if(!this.state.metric.id) 
            return "block"
        else 
            return "none";
    }
    
    getChart() {

        const chartConfig = {
            data: (canvas) => {
              let ctx = canvas.getContext("2d");
          
              let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
              gradientStroke.addColorStop(0, "#18ce0f");
              gradientStroke.addColorStop(1, chartColor);
          
              let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
              gradientFill.addColorStop(0, hexToRGB("#18ce0f", 0.5));
              gradientFill.addColorStop(1, hexToRGB("#18ce0f", 1));

              return {
                labels: this.state.labels, //["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
                datasets: [
                  {
                    label: this.state.metric.label,
                    borderColor: "#18ce0f",
                    pointHoverRadius: 0,
                    pointRadius: 0,
                    fill: false,
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                    barPercentage: 1,
                    data: this.state.data //[40, 500, 650, 700, 1200, 1250, 1300, 1900],
                  },
                ],
              };
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                intersect: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: "rgba(255,255,255,0.05)",
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(255,255,255,0.1)",
                      zeroLineColor: "transparent",
                      display: false,
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f",
                    },
                  },
                ],
              },
            },
        };

        if (this.state.metric.visualisation === 'bar') {
            return <Bar
                data={chartConfig.data}
                options={chartConfig.options}
                height={217}
            />
        } else if (this.state.metric.visualisation === 'line') {
            return <Line
                data={chartConfig.data}
                options={chartConfig.options}
                height={217}
            />
        } 
    }

    getValue() {
        
        if (this.state.metric.visualisation === 'value' && this.state.values.length > 0) {
            if(this.state.metric.valueFormat === 'number') {
                return <div style={{textAlign:"center"}}>
                    <h1 style={{marginBottom:"0px"}}>{numberWithCommas(this.state.values[0].value)}</h1>
                    <div style={{color:"green", fontSize:"12px"}}>
                        {this.state.metric.label}
                    </div>
                </div>
            } else {
                return <div style={{textAlign:"center"}}>
                    <h1 style={{marginBottom:"0px"}}>{this.state.values[0].value}</h1>
                    <div style={{color:"green", fontSize:"12px"}}>
                        {this.state.metric.label}
                    </div>
                </div>
            }
        }
    }
    showField() {
        if(this.state.metric.visualisation !== 'value')
            return 'block';
        else 
            return 'none';
    }

    render() {

        const navIcon = {
            fontSize:"13px",
            marginTop:"3px",
            cursor:"pointer",
            color:"#888"
        }
        const activeLink = {
            color:"white",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const activeTab = {
            backgroundColor:'green',
            borderStyle: 'none',
            borderWidth: '1px 1px 0px 1px',
            borderColor: 'green'
        }
        const tab = {
            backgroundColor:"#efefef",
            marginLeft:"1px",   
        }

      return (
        
        <Row>
            <Col xs="12">

                <Button 
                    size="sm"
                    style={{display:this.showButton()}} 
                    onClick={() => this.handleClickOpen("modal")}>
                    Add Metric
                </Button>
                
                <Card style={{display:this.showCard(), cursor:'pointer'}} onClick={() => this.handleClickOpen("modal")}>
                    
                    <CardHeader style={{paddingTop:"10px"}} >
                        <Row>
                            <Col xs="11">
                                <h5 style={{marginTop:"10px", marginBottom:"0px"}}>
                                    {this.state.metric.name}
                                </h5>
                            </Col>
                            <Col xs="1" style={{display:"none"}}>
                                <i style={navIcon} className="nc-icon nc-settings-gear-65" onClick={this.toggleModal} />  
                            </Col>
                        </Row>
                    </CardHeader>
                    
                    <CardBody style={{minHeight:"89px", paddingTop:"20px"}}>
                        {this.getChart()}
                        {this.getValue()}

                        <div style={{fontSize:"12px", marginTop:"23px", marginBottom:"10px"}}>
                            {this.state.metric.description}
                        </div>
                    </CardBody>
                </Card>
            
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col xs="12">
                                <Nav style={{paddingTop:"17px"}}>
                                    <NavItem style={(this.state.activeTab === 'overview' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'overview'}) }}
                                        >
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={(this.state.activeTab === 'data' ? activeTab : tab )}>
                                        <NavLink
                                            style={(this.state.activeTab === 'data' ? activeLink : link )}
                                            onClick={() => { this.setState({activeTab: 'data'}) }}
                                        >
                                            Data
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                          
                                <TabContent activeTab={this.state.activeTab} style={{borderStyle: 'solid', borderWidth: '1px 0px 0px 0px', borderColor: 'green', paddingTop:"20px"}}>
                                    <TabPane tabId="overview" style={{minHeight:"210px"}}>
                                        <Row>
                                            <Col xs="12" >
                                                Name<br />
                                                <Input placeholder="Name" value={this.state.metric.name} onChange={(e) => this.updateMetric("name", e.target.value)} />
                                            </Col>
                                            <Col xs="12" >
                                                <br />Description<br />
                                                <Input placeholder="Description" value={this.state.metric.description} onChange={(e) => this.updateMetric("description", e.target.value)} />
                                            </Col>
                                            <Col xs="12" sm="4" >
                                                <br />Visualisation<br />
                                                <Input placeholder="Metric" 
                                                    type="select" 
                                                    value={this.state.metric.visualisation} onChange={(e) => this.updateMetric("visualisation", e.target.value)} >
                                                    
                                                    <option value="bar">Bar Chart</option>
                                                    <option value="value">Latest Value</option>
                                                    <option value="line">Line Chart</option>
                                                </Input>
                                            </Col>
                                            <Col xs="12" sm="4" md="3" style={{display:this.showField()}}>
                                                <br />Days to Show<br />
                                                <Input placeholder="Days" 
                                                    type="select" 
                                                    value={this.state.metric.days} onChange={(e) => this.updateMetric("days", e.target.value)} >
                                                    
                                                    <option value="5">5</option>
                                                    <option value="7">7</option>
                                                    <option value="10">10</option>
                                                    <option value="14">14</option>
                                                    <option value="21">21</option>
                                                    <option value="28">28</option>
                                                </Input>
                                            </Col>
                                            <Col xs="12" sm="4" md="3" >
                                                <br />Format<br />
                                                <Input placeholder="Value Format" 
                                                    type="select" 
                                                    value={this.state.metric.valueFormat} onChange={(e) => this.updateMetric("valueFormat", e.target.value)} >
                                                    
                                                    <option value="number">Number</option>
                                                    <option value="string">Text</option>
                                                </Input>
                                            </Col>
                                            <Col xs="12" sm="4" md="3" >
                                                <br />Label<br />
                                                <Input placeholder="label" value={this.state.metric.label} onChange={(e) => this.updateMetric("label", e.target.value)} />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="data" style={{minHeight:"210px", paddingTop:"17px"}}>
                                        <Row style={{backgroundColor:"#efefef", borderRadius:"6px", paddingTop:"10px"}}>
                                            <Col xs="5">
                                                Date<br />
                                                <ReactDatetime
                                                    value={this.state.newDate}
                                                    dateFormat="YYYY-MM-DD" timeFormat={false}
                                                    closeOnSelect={true}
                                                    inputProps={{
                                                        className: "form-control",
                                                        placeholder: "",
                                                    }}
                                                    onChange={(e) => this.setState({newDate: e.format('YYYY-MM-DD')})} 
                                                />
                                            </Col>
                                            <Col xs="5">
                                                Number<br />
                                                <Input placeholder="Value" value={this.state.newValue} onChange={(e) => this.setState({newValue: e.target.value})} />
                                            </Col>
                                            <Col xs="2" style={{paddingTop:"17px", textAlign:"right"}}>
                                                <Button color="secondary" size="sm" 
                                                    onClick={() => this.saveValue()} >
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                        <br /><br />
                                        <Row>
                                            <Col xs="12">
                                                <Table>
                                                    <thead>
                                                        <tr style={{fontSize:"12px", fontWeight:"bold"}}>
                                                            <td>
                                                                Date
                                                            </td>
                                                            <td>
                                                                Value
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.values.map((value, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {getFormattedDate(value.date)}
                                                            </td>
                                                            <td>
                                                                {value.value}
                                                            </td>
                                                            <td style={{textAlign:"right"}}>
                                                                <ConfirmDialog 
                                                                    buttonText="Delete"
                                                                    title="Delete Value" 
                                                                    header="CONFIRM" 
                                                                    description="Are you sure you want to delete this value?" 
                                                                    confirmed={() => this.deleteValue(value)} /> 
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="5" style={{textAlign:"left"}}>
                                
                                <ConfirmDialog 
                                    buttonText="Delete"
                                    title="Delete Metric" 
                                    header="CONFIRM" 
                                    description="Are you sure you want to delete this metric? This will also delete all of the metric data that's been collected." 
                                    confirmed={() => this.deleteMetric()} /> 
                            </Col>
                            <Col xs="7" style={{textAlign:"right"}}>
                                <Button color="secondary" size="md" 
                                    onClick={() => this.handleClose("modal")} >
                                    Cancel
                                </Button>
                                <Button size="md"
                                    onClick={() => {
                                        this.saveMetric();
                                        this.handleClose("modal");
                                    }}
                                    color="success">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                        
                    </ModalFooter>
                </Modal>

            </Col>
        </Row>
        
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

MetricCard = connect(mapStateToProps)(MetricCard);
export default MetricCard;