import moment from 'moment'

import { dateTime } from '../../assets/utils'
import { CARE_TEAM_ID } from '../../components/Nexus/constants'
import {
  CUSTOMER_PLATFORM_PODS,
  getSLARuleInMins, getTeamName, GOJEK_PRODUCT_SECURITY_ISSUE_TYPES,
  isIssueBreachedSLA, monthNames, statusMap,
} from './utils'

const getMonthlySeverityData = (monthlySeverityData, severity, index, total) => {
  const monthlySeverity = monthlySeverityData
  if (index !== -1) {
    monthlySeverity[severity][index] += total
  }

  return monthlySeverity
}

const getTotalIssuesByMonth = (totalIssuesData, index, total) => {
  const totalIssues = totalIssuesData
  if (index !== -1) {
    totalIssues[index] += total
  }

  return totalIssues
}

const getTotalMonthlyPDGIssues = (totalPDGIssuesData, teamName, index, total, months) => {
  const totalIssues = totalPDGIssuesData
  if (teamName && !(teamName in totalIssues)) {
    if (teamName) {totalIssues[teamName] = new Array(months).fill(0)}
  }

  if (index !== -1 && teamName) {
    totalIssues[teamName][index] += total
  }

  return totalIssues
}

const getTotalDurationOfCompletion = (
    totalDurationOfCompletionData,
    severity,
    nexusStatus,
    timeToResolution,
    created,
    total,
    past30Days,
) => {
  const totalDurationOfCompletion = totalDurationOfCompletionData

  if (nexusStatus === 'Done' && timeToResolution && created >= past30Days) {
    totalDurationOfCompletion[severity].totalIssues += total
    totalDurationOfCompletion[severity].totalDuration += moment(timeToResolution).diff(moment(created))
  }

  return totalDurationOfCompletion
}

const getTotalDurationOfCompletionByMonth = (
    totalDurationOfCompletionByMonthData,
    index,
    severity,
    nexusStatus,
    timeToResolution,
    created,
    total,
) => {
  const totalDurationOfCompletionByMonth = totalDurationOfCompletionByMonthData
  if (nexusStatus === 'Done' && timeToResolution && index !== -1) {
    totalDurationOfCompletionByMonth[severity].totalIssues[index] += total
    totalDurationOfCompletionByMonth[severity].totalDuration[index] +=
        moment(timeToResolution).diff(moment(created))
  }

  return totalDurationOfCompletionByMonth
}

const getTotalIssuesAbidingSLA = (
    totalIssuesAbidingResolutionSLA,
    totalIssuesAbidingResponseSLA,
    timeToResolution,
    timeToRespond,
    nexusStatus,
    index,
    createdTimestamp,
    severity,
    SLA_RULES,
    total,
) => {
  const totalIssuesAbidingResolutionSLAData = totalIssuesAbidingResolutionSLA
  const totalIssuesAbidingResponseSLAData = totalIssuesAbidingResponseSLA

  let resolutionTimeDiff = moment().diff(moment(createdTimestamp))
  let responseTimeDiff = moment().diff(moment(createdTimestamp))

  if (index !== -1) {
    if (nexusStatus === 'Done' && timeToResolution) {
      resolutionTimeDiff = moment(timeToResolution).diff(moment(createdTimestamp))
    }

    if (timeToRespond) {
      responseTimeDiff = moment(timeToRespond).diff(moment(createdTimestamp))
    }
  }

  if (resolutionTimeDiff < (SLA_RULES[severity].timeToResolution) * (60 * 1000)) {
    totalIssuesAbidingResolutionSLAData[index] += total
  }

  if (responseTimeDiff < (SLA_RULES[severity].timeToRespond) * (60 * 1000)) {
    totalIssuesAbidingResponseSLAData[index] += total
  }

  return [totalIssuesAbidingResolutionSLAData, totalIssuesAbidingResponseSLAData]
}

const getAverageDaysOfCompletion = (totalDuration) => {
  const averageDurationBySeverity = {
    critical: '',
    high: '',
    medium: '',
    low: '',
  }
  Object.keys(totalDuration).forEach((severity) => {
    if (!totalDuration[severity].totalIssues) {
      averageDurationBySeverity[severity] = 'NA (No resolved issues)'
    } else {
      const averageDurationInMilliseconds =
        totalDuration[severity].totalDuration / (totalDuration[severity].totalIssues)
      averageDurationBySeverity[severity] = dateTime.toDaysAndHours(averageDurationInMilliseconds)
    }
  })

  return averageDurationBySeverity
}

const getAverageDaysOfCompletionByMonth = (totalDuration, months) => {
  const averageDurationBySeverity = {
    critical: new Array(months).fill(0),
    high: new Array(months).fill(0),
    medium: new Array(months).fill(0),
    low: new Array(months).fill(0),
  }
  Object.keys(totalDuration).forEach((severity) => {
    const severityData = totalDuration[severity]

    months.forEach((_, index) => {
      if (!severityData.totalIssues[index]) {
        averageDurationBySeverity[severity][index] = 0
      } else {
        const averageDurationInMilliseconds = severityData.totalDuration[index] / (severityData.totalIssues[index])
        averageDurationBySeverity[severity][index] = (averageDurationInMilliseconds / (24 * 60 * 60 * 1000)).toFixed(2)
      }
    })
  })

  return averageDurationBySeverity
}

const getMontlyPDGData = (teamName, monthlyPDGData, index, total, months) => {
  const monthlyPDG = monthlyPDGData
  if (teamName && !(teamName in monthlyPDG)) {
    if (teamName) {monthlyPDG[teamName] = new Array(months).fill(0)}
  }

  if (index !== -1 && teamName) {
    monthlyPDG[teamName][index] += total
  }

  return monthlyPDG
}

const getTotalIssuesAbidingSLAPDG = (
    totalIssuesAbidingResolutionSLAPDG,
    totalIssuesAbidingResponseSLAPDG,
    teamName,
    timeToResolution,
    timeToRespond,
    nexusStatus,
    index,
    created,
    severity,
    SLA_RULES,
    total,
    months,
) => {
  const totalIssuesAbidingResolutionSLAPDGData = totalIssuesAbidingResolutionSLAPDG
  const totalIssuesAbidingResponseSLAPDGData = totalIssuesAbidingResponseSLAPDG

  let resolutionTimeDiff = moment().diff(moment(created))
  let responseTimeDiff = moment().diff(moment(created))
  if (teamName) {
    if (!(teamName in totalIssuesAbidingResolutionSLAPDGData)) {
      totalIssuesAbidingResolutionSLAPDGData[teamName] = new Array(months).fill(0)
    }

    if (!(teamName in totalIssuesAbidingResponseSLAPDGData)) {
      totalIssuesAbidingResponseSLAPDGData[teamName] = new Array(months).fill(0)
    }
  }

  if (index !== -1 && teamName) {
    if (nexusStatus === 'Done' && timeToResolution) {
      resolutionTimeDiff = moment(timeToResolution).diff(moment(created))
    }

    if (timeToRespond) {
      responseTimeDiff = moment(timeToRespond).diff(moment(created))
    }
  }

  if (resolutionTimeDiff < (SLA_RULES[severity].timeToResolution) * (60 * 1000)) {
    totalIssuesAbidingResolutionSLAPDGData[teamName][index] += total
  }

  if (responseTimeDiff < (SLA_RULES[severity].timeToRespond) * (60 * 1000)) {
    totalIssuesAbidingResponseSLAPDGData[teamName][index] += total
  }

  return [totalIssuesAbidingResolutionSLAPDGData, totalIssuesAbidingResponseSLAPDGData]
}

const getSLADataByMonth = (totalIssuesAbidingSLAData, totalIssuesData, months) => {
  const SLAData = []
  months.forEach((_, index) => {
    if (totalIssuesData[index] === 0 || totalIssuesAbidingSLAData[index] === 0) {
      SLAData[index] = 0
    } else {
      const percentage = (totalIssuesAbidingSLAData[index] / totalIssuesData[index]) * 100
      SLAData[index] = Math.round(percentage)
    }
  })

  return SLAData
}

const getMontlySLAPDGResponseData = (
    totalIssuesAbidingResponseSLAPDG,
    totalMonthlyPDGIssues,
    months,
) => {
  const monthlySLAPDGResponsePercentages = {}

  Object.keys(totalIssuesAbidingResponseSLAPDG).forEach((PDG) => {
    monthlySLAPDGResponsePercentages[PDG] = getSLADataByMonth(
        totalIssuesAbidingResponseSLAPDG[PDG],
        totalMonthlyPDGIssues[PDG],
        months,
    )
  })

  return monthlySLAPDGResponsePercentages
}

const getMontlySLAPDGResolutionData = (
    totalIssuesAbidingResolutionSLAPDG,
    totalMonthlyPDGIssues,
    months,
) => {
  const monthlySLAPDGResolutionPercentages = {}

  Object.keys(totalIssuesAbidingResolutionSLAPDG).forEach((PDG) => {
    monthlySLAPDGResolutionPercentages[PDG] = getSLADataByMonth(
        totalIssuesAbidingResolutionSLAPDG[PDG],
        totalMonthlyPDGIssues[PDG],
        months,
    )
  })

  return monthlySLAPDGResolutionPercentages
}

const getOpenIssuesByTeamData = (nexusStatus, teamName, severity, openIssuesByTeamData, total) => {
  const openIssuesByTeam = openIssuesByTeamData
  if (nexusStatus !== 'Done') {
    if (teamName) {
      if (teamName in openIssuesByTeam) {
        if (severity in openIssuesByTeam[teamName]) {
          openIssuesByTeam[teamName][severity] += total
        } else {
          openIssuesByTeam[teamName][severity] = total
        }
        openIssuesByTeam[teamName].total += total
      } else {
        openIssuesByTeam[teamName] = {
          'critical': 0,
          'high': 0,
          'medium': 0,
          'low': 0,
          'total': 1,
        }
        openIssuesByTeam[teamName][severity] = total
      }
    }
  }

  return openIssuesByTeam
}

const getIssuesByPDGData = (issuesByPDGData, teamName, totalIssuesPast30Days, past30Days, created, total) => {
  const issuesByPDG = issuesByPDGData
  let totalIssues = totalIssuesPast30Days
  if (created >= past30Days) {
    totalIssues += total
    if (teamName) {
      if (teamName in issuesByPDG) issuesByPDG[teamName] += total
      else {
        issuesByPDG[teamName] = total
      }
    }
  }

  return [issuesByPDG, totalIssues]
}

const getWeeklyCreatedIssues = (created, currWeek, weeklyCreatedIssuesData, total) => {
  const weeklyCreatedIssues = [...weeklyCreatedIssuesData]
  const issueCreatedWeek = moment(created).isoWeek()
  const weekDiff = 5 - (currWeek - issueCreatedWeek)
  if (total && (issueCreatedWeek > (currWeek - 6) && weekDiff >= 0 && weekDiff < 6)) {
    weeklyCreatedIssues[weekDiff] += total
  }

  return weeklyCreatedIssues
}

const getWeeklyResolvedIssues = (timeToResolutionTimeStamp, currWeek, nexusStatus, weeklyResolvedIssuesData, total) => {
  const weeklyResolvedIssues = [...weeklyResolvedIssuesData]
  if (nexusStatus === 'Done' && timeToResolutionTimeStamp) {
    const issueResolutionWeek = moment(timeToResolutionTimeStamp).isoWeek()
    const weekDiff = 5 - (currWeek - issueResolutionWeek)
    if (total && (issueResolutionWeek > currWeek - 6 && weekDiff >= 0 && weekDiff < 6)) {
      weeklyResolvedIssues[weekDiff] += total
    }
  }

  return weeklyResolvedIssues
}

const getTopCareIssuesData = (
    topCareIssuesData,
    totalCareIssues,
    groupLevelIssueType,
    past30Days,
    created,
    fromTeamID,
    total,
) => {
  const topCareIssues = topCareIssuesData
  let totalIssues = totalCareIssues

  if (created >= past30Days && fromTeamID === CARE_TEAM_ID) {
    totalIssues += total
    if (groupLevelIssueType in topCareIssues) {
      topCareIssues[groupLevelIssueType] += total
    } else {
      topCareIssues[groupLevelIssueType] = total
    }
  }

  return [topCareIssues, totalIssues]
}

const getTopSLABreachedData = (
    issue,
    teamName,
    totalIssuesBreachedResponseSLAPDGData,
    total,
) => {
  const totalIssuesBreachedResponseSLAPDG = totalIssuesBreachedResponseSLAPDGData

  if (!teamName) return totalIssuesBreachedResponseSLAPDGData
  if (!isIssueBreachedSLA(issue)) return totalIssuesBreachedResponseSLAPDG

  if (teamName in totalIssuesBreachedResponseSLAPDGData) totalIssuesBreachedResponseSLAPDG[teamName] += total
  else {
    totalIssuesBreachedResponseSLAPDG[teamName] = total
  }

  return totalIssuesBreachedResponseSLAPDG
}

export const constructMonthlyChartData = (analyticsData, months, teams) => {
  const monthsLength = months.length
  let monthlySeverityData = {
    critical: new Array(monthsLength).fill(0),
    high: new Array(monthsLength).fill(0),
    medium: new Array(monthsLength).fill(0),
    low: new Array(monthsLength).fill(0),
  }

  let monthlyPDGData = {}

  let openIssuesByTeamData = {}

  let issuesByPDGData = {}
  let totalIssuesPast30Days = 0
  const today = new Date().getTime()
  const past30Days = today - (30 * 86400000)

  let weeklyCreatedIssuesData = new Array(monthsLength).fill(0)
  let weeklyResolvedIssuesData = new Array(monthsLength).fill(0)

  const currWeek = moment().isoWeek()
  const currWeekTimestamp = new Date(currWeek).valueOf()

  let topCareIssuesData = {}
  let totalCareIssues = 0

  let totalDurationOfCompletionData = {
    critical: {
      totalDuration: 0,
      totalIssues: 0,
    },
    high: {
      totalDuration: 0,
      totalIssues: 0,
    },
    medium: {
      totalDuration: 0,
      totalIssues: 0,
    },
    low: {
      totalDuration: 0,
      totalIssues: 0,
    },
  }
  let averageDurationBySeverityData = {
    critical: '',
    high: '',
    medium: '',
    low: '',
  }

  let totalDurationOfCompletionByMonthData = {
    critical: {
      totalDuration: new Array(monthsLength).fill(0),
      totalIssues: new Array(monthsLength).fill(0),
    },
    high: {
      totalDuration: new Array(monthsLength).fill(0),
      totalIssues: new Array(monthsLength).fill(0),
    },
    medium: {
      totalDuration: new Array(monthsLength).fill(0),
      totalIssues: new Array(monthsLength).fill(0),
    },
    low: {
      totalDuration: new Array(monthsLength).fill(0),
      totalIssues: new Array(monthsLength).fill(0),
    },
  }
  let monthlyAverageDurationBySeverityData = {
    critical: new Array(monthsLength).fill(0),
    high: new Array(monthsLength).fill(0),
    medium: new Array(monthsLength).fill(0),
    low: new Array(monthsLength).fill(0),
  }

  let totalIssuesAbidingResolutionSLA = new Array(monthsLength).fill(0)
  let totalIssuesAbidingResponseSLA = new Array(monthsLength).fill(0)
  let totalIssuesByMonth = new Array(monthsLength).fill(0)

  let timeToResolutionData = []
  let timeToRespondData = []

  let totalIssuesAbidingResolutionSLAPDG = {}
  let totalMonthlyPDGIssues = {}
  let totalIssuesAbidingResponseSLAPDG = {}
  let totalIssuesBreachedResponseSLAPDG = {}

  let monthlySLAPDGResponseData = {}
  let monthlySLAPDGResolutionData = {}

  if (!analyticsData.length) {
    return {
      monthlySeverityData,
      monthlyPDGData,
      openIssuesByTeamData,
      issuesByPDGData,
      totalIssuesPast30Days,
      weeklyCreatedIssuesData,
      weeklyResolvedIssuesData,
      topCareIssuesData,
      totalCareIssues,
      averageDurationBySeverityData,
      monthlyAverageDurationBySeverityData,
      timeToResolutionData,
      timeToRespondData,
      monthlySLAPDGResponseData,
      monthlySLAPDGResolutionData,
    }
  }

  analyticsData.forEach((record) => {
    const {
      severity, toTeamID, nexusStatus, created, timeToResolutionTimeStamp, timeToRespondTimeStamp,
      groupLevelIssueType, fromTeamID, toPodID, total, createdMonth,
    } = record
    const month = monthNames[createdMonth - 1]
    const index = months.indexOf(month)
    const teamName = getTeamName(teams, toTeamID)
    const SLA_RULES = getSLARuleInMins(fromTeamID, toPodID)

    monthlySeverityData = getMonthlySeverityData(monthlySeverityData, severity, index, total)
    monthlyPDGData = getMontlyPDGData(teamName, monthlyPDGData, index, total, monthsLength)
    totalIssuesByMonth = getTotalIssuesByMonth(totalIssuesByMonth, index, total)
    totalMonthlyPDGIssues = getTotalMonthlyPDGIssues(totalMonthlyPDGIssues, teamName, index, total, monthsLength)
    openIssuesByTeamData = getOpenIssuesByTeamData(nexusStatus, teamName, severity, openIssuesByTeamData, total);

    [issuesByPDGData, totalIssuesPast30Days] = getIssuesByPDGData(
        issuesByPDGData,
        teamName,
        totalIssuesPast30Days,
        past30Days,
        created,
        total,
    );

    [topCareIssuesData, totalCareIssues] = getTopCareIssuesData(
        topCareIssuesData,
        totalCareIssues,
        groupLevelIssueType,
        past30Days,
        created,
        fromTeamID,
        total,
    )

    totalDurationOfCompletionData = getTotalDurationOfCompletion(
        totalDurationOfCompletionData,
        severity,
        nexusStatus,
        timeToResolutionTimeStamp,
        created,
        total,
        past30Days,
    )

    totalDurationOfCompletionByMonthData = getTotalDurationOfCompletionByMonth(
        totalDurationOfCompletionByMonthData,
        index,
        severity,
        nexusStatus,
        timeToResolutionTimeStamp,
        created,
        total,
    )

    weeklyCreatedIssuesData = getWeeklyCreatedIssues(
        created,
        currWeek,
        weeklyCreatedIssuesData,
        total,
        currWeekTimestamp,
    )
    weeklyResolvedIssuesData = getWeeklyResolvedIssues(
        timeToResolutionTimeStamp,
        currWeek,
        nexusStatus,
        weeklyResolvedIssuesData,
        total,
        currWeekTimestamp,
    );

    [totalIssuesAbidingResolutionSLA, totalIssuesAbidingResponseSLA] = getTotalIssuesAbidingSLA(
        totalIssuesAbidingResolutionSLA,
        totalIssuesAbidingResponseSLA,
        timeToResolutionTimeStamp,
        timeToRespondTimeStamp,
        nexusStatus,
        index,
        created,
        severity,
        SLA_RULES,
        total,
    );

    [totalIssuesAbidingResolutionSLAPDG, totalIssuesAbidingResponseSLAPDG] = getTotalIssuesAbidingSLAPDG(
        totalIssuesAbidingResolutionSLAPDG,
        totalIssuesAbidingResponseSLAPDG,
        teamName,
        timeToResolutionTimeStamp,
        timeToRespondTimeStamp,
        nexusStatus,
        index,
        created,
        severity,
        SLA_RULES,
        total,
        monthsLength,
    )

    totalIssuesBreachedResponseSLAPDG = getTopSLABreachedData(
        record,
        teamName,
        totalIssuesBreachedResponseSLAPDG,
        total,
    )
  })

  averageDurationBySeverityData = getAverageDaysOfCompletion(
      totalDurationOfCompletionData,
  )

  monthlyAverageDurationBySeverityData = getAverageDaysOfCompletionByMonth(
      totalDurationOfCompletionByMonthData,
      months,
  )

  timeToResolutionData = getSLADataByMonth(totalIssuesAbidingResolutionSLA, totalIssuesByMonth, months)
  timeToRespondData = getSLADataByMonth(totalIssuesAbidingResponseSLA, totalIssuesByMonth, months)

  monthlySLAPDGResponseData = getMontlySLAPDGResponseData(
      totalIssuesAbidingResponseSLAPDG,
      totalMonthlyPDGIssues,
      months,
  )
  monthlySLAPDGResolutionData = getMontlySLAPDGResolutionData(
      totalIssuesAbidingResolutionSLAPDG,
      totalMonthlyPDGIssues,
      months,
  )

  return {
    monthlySeverityData,
    monthlyPDGData,
    openIssuesByTeamData,
    issuesByPDGData,
    totalIssuesPast30Days,
    weeklyCreatedIssuesData,
    weeklyResolvedIssuesData,
    topCareIssuesData,
    totalCareIssues,
    averageDurationBySeverityData,
    monthlyAverageDurationBySeverityData,
    timeToResolutionData,
    timeToRespondData,
    monthlySLAPDGResponseData,
    monthlySLAPDGResolutionData,
    totalIssuesBreachedResponseSLAPDG,
  }
}

export const buildIssue = (issue, teams) => {
  const updatedIssue = issue
  updatedIssue.fromTeamName = getTeamName(teams, issue.fromTeamID)
  updatedIssue.toTeamName = getTeamName(teams, issue.toTeamID)
  updatedIssue.toPodName = getTeamName(teams, issue.toPodID)
  updatedIssue.nexusStatus = statusMap[issue.jira.status]

  if (updatedIssue.toTeamName === 'Customer Platform' && updatedIssue.toTeamName === updatedIssue.toPodName) {
    // Set Customer Platform pods to different options from jira.toPod
    if (issue.jira && issue.jira.toPod) {
      updatedIssue.toPodName = issue.jira.toPod
    }
  }

  return updatedIssue
}

export const nexusStatusMap = {
  'To Do': ['Open', 'To Do', 'Backlog', 'Disputed', 'Reported', 'Awaiting-Sec-ACK', 'Risk-Rejected',
    'Sec-Risk-Declined', 'Awaiting-Risk-Acceptance', 'CP: To Do', 'Prioritised', 'Ready for Dev', 'Icebox',
    'Automate', 'Whiteboard', 'Kickoff', 'Waiting'],

  'In Progress': ['Start', 'In Progress', 'Work in progress', 'Pending', 'Ready-For-Review', 'In-progress',
    'CP: ESCALATED', 'CP: In Progress', 'In QA', 'Ready  For  QA', 'Waiting for PR Review', 'In Dev', 'IN DEV',
    'READY FOR DEV', 'IN PR REVIEW/DEVBOX', 'READY FOR QA', 'IN QA', 'READY FOR PROD', 'Blocked', 'QA Done',
    'PR Raised', 'Ready For QA', 'Dev Box Testing', 'QA Approved', 'TO DEPLOY'],

  'Waiting On Information': ['Waiting on information', 'Waiting for customer', 'Waiting On Information'],

  'Done': ['Done', 'Risk-Accepted', 'Resolved', 'False-Positive', 'N/A', 'Cancelled', 'DEPLOYED/LIVE',
    'Archived', 'CP: DONE', 'Released', 'Live in Production', 'Obsolete', 'Done/Completed', 'Graveyard', 'Not Required'],

  'Reopened': ['Reopened'],
}

export const applyQueryFilterOnNexusIssues = (filter, dbQuery, isChartFilter) => {
  const {
    severity,
    status,
    fromTeamID,
    toPodID,
    toTeamID,
    startDate,
    endDate,
    country,
    subTeam,
    customerRequestType,
  } = filter

  let query = dbQuery
  if (startDate) {
    const startTimestamp = new Date(startDate).getTime()
    query = query.where('created', '>=', startTimestamp)
  }

  if (endDate) {
    const endTimestamp = new Date(endDate).getTime() + (86400000) // Adding 1 day as endTimestamp is at 0:00am
    query = query.where('created', '<=', endTimestamp)
  }

  if (fromTeamID.length) {
    query = query.where('fromTeamID', 'in', fromTeamID)
  }
  if (severity.length) {
    query = query.where('severity', 'in', severity)
  }
  if (toTeamID.length) {
    query = query.where('toTeamID', 'in', toTeamID)
  }
  if (toPodID.length) {
    const isCustomerPlatformPod = toPodID.some((ele) => CUSTOMER_PLATFORM_PODS.includes(ele))

    const podField = isCustomerPlatformPod && !isChartFilter ? 'jira.toPod' : 'toPodID'
    query = query.where(podField, 'in', toPodID)
  }
  if (status.length) {
    const statusField = isChartFilter ? 'nexusStatus' : 'jira.status'
    query = query.where(statusField, 'in', status)
  }
  if (country.length) {
    const countryField = isChartFilter ? 'country' : 'jira.care.markets'
    query = query.where(countryField, 'in', country)
  }
  if (subTeam.length) {
    const subTeamField = isChartFilter ? 'subTeam' : 'jira.care.subTeam'
    query = query.where(subTeamField, 'in', subTeam)
  }
  if (customerRequestType && customerRequestType.length) {
    if (isChartFilter) {
      query = query.where('requestType', 'in', customerRequestType)
    } else {
      const issueType = []
      const requestType = []
      customerRequestType.forEach((type) => {
        if (GOJEK_PRODUCT_SECURITY_ISSUE_TYPES.includes(type)) {
          issueType.push(type)
        } else {
          requestType.push(type)
        }
      })
      if (issueType.length) {query = query.where('jira.issuetype', 'in', issueType)}

      if (requestType.length) {
        query = query.where('jira.care.customerRequestType', 'in', requestType)
      }
    }
  }

  return query
}
