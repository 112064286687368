import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText, 
} from "reactstrap";

import PersonCard from "components/PersonCard/PersonCard.js";
import PersonSearch from "components/PersonSearch/PersonSearch.js";  
import Tag from "components/Tag/Tag.js"; 
import TeamSearch from "components/TeamSearch/TeamSearch.js"; 

const uuid = require('uuid');

class StandardEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            standard: {
                title: '',
                description: '',
                value: '',
                ownerID: '',
                teamID: '',
                status: 'proposed',
                targetDate: '',
                tags: [],
                links: []
            },
            owner: {},
            team: {},
            adoption: [],
            openPersonSearch: false,
            openTeamSearch: false,
        }
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        if(!this.state.modal && !this.props.onCreated) {
            this.getStandard();
            this.getOwner();
            this.getTeam();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standardEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getStandard() {

        db.getStandard(this.props.standard.id).then(standard => {
            
            if(!standard.effort) standard.effort = '';
            if(!standard.value) standard.value = '';
            if(!standard.links) standard.links = [];

            this.setState({standard:standard});
        });
    }

    getOwner() {
        if(this.props.standard.ownerID) {   
            db.getPerson(this.props.standard.ownerID).then((owner => {
                if(owner)
                    this.setState({owner: owner, originalOwner: owner.id});
            }));
        }
    }
    getTeam() {
        if(this.props.standard.teamID) {   
            db.getTeam(this.props.standard.teamID).then((team => {
                this.setState({team: team});
            }));
        }
    }

    updateStandard(property, value) {
        var obj = this.state.standard;
        obj[property] = value;
        this.setState({standard: obj});
    }

    addLink() {
        var obj = this.state.standard;
        obj.links.splice(0,0,{id: uuid.v4(), name:"", url:"", created: new Date().getTime() });
        this.setState({standard: obj});
    }

    updateLink(link, property, value) {
        var obj = this.state.standard;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({standard: obj});
    }



    saveStandard() {

        var standard = this.state.standard;

        if(!standard.created) { 
            standard.created = new Date().getTime(); 
            standard.createdBy = this.props.user.person.name;
        }

        standard.modified = new Date().getTime(); 
        standard.modifiedBy = this.props.user.person.name;

        db.updateStandard(standard.id, standard).then((result) => {

            if(this.props.onCreated) { // 
                standard.id = result.id;
                this.props.onCreated(standard);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standard", "createStandard");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "standard", "updateStandard");
            }

            if(this.props.onCreated) { // reset for new standard

                this.setState({ 
                    standard: {
                        title: '',
                        description: '',
                        value: '',
                        ownerID: '',
                        teamID: '',
                        tags: [],
                        links: [],
                    },
                    adoption: []
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(standard);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }


    assignOwner(person) {
        this.updateStandard("ownerID", person.id);
        this.setState({owner: person});
    }
    assignTeam(team) {
        this.updateStandard("teamID", team.id);
        this.setState({team: team});
    }

    openPeopleSearch() {
        this.setState({openPersonSearch: true}, () => {
            setTimeout(() => {
                this.setState({openPersonSearch: false});
            }, 500);
        });
    }
    openTeamSearch() {
        this.setState({openTeamSearch: true}, () => {
            setTimeout(() => {
                this.setState({openTeamSearch: false});
            }, 500);
        });
    }

    ownerCard() {
        if(this.state.owner.id) {
            return (
                <PersonCard person={this.state.owner} showThumb />
            )
        }
    }

    buttonText() {
        if(this.props.onCreated)
            return "New";
        else
            return "Edit";
    }

    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "block";
        else
          return "none";
    }

    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const iconTextArea = {
            fontSize:"17px",
            position:"absolute",
            top:"20px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Standard
                            </Col>
                        </Row>   
                        <Row>
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'links' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'links'}) }}
                                    >
                                        References
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.standard.title} onChange={(e) => this.updateStandard("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" >
                                        Status <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.standard.status} 
                                            onChange={(e) => this.updateStandard("status", e.target.value)} >
                                                <option value="proposed">Proposed</option>
                                                <option value="approved">Approved</option>
                                                <option value="rejected">Rejected</option>
                                                <option value="archived">Archived</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12" sm="6" >
                                        Target Date <br />  
                                        <Input
                                            type="date"
                                            placeholder=""
                                            value={this.state.standard.targetDate}
                                            onChange={(e) => this.updateStandard("targetDate", e.target.value)}
                                            style={{height:"42px"}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Description <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="what are you proposing that we standardise? " 
                                                type="textarea" style={{minHeight:"120px", lineHeight:"19px"}}
                                                value={this.state.standard.description} onChange={(e) => this.updateStandard("description", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Business Value <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="what benefit would this standard provide?" 
                                                type="textarea" style={{minHeight:"110px", lineHeight:"19px"}}
                                                value={this.state.standard.value} onChange={(e) => this.updateStandard("value", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Expected Effort  <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="what specifically needs to be done and how much time and effort is it likely to involve?" 
                                                type="textarea" style={{minHeight:"100px", lineHeight:"19px"}}
                                                value={this.state.standard.effort} onChange={(e) => this.updateStandard("effort", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col sm="6" md="4">
                                        Owner <br />
                                        <div onClick={() => this.openPeopleSearch()} 
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                {this.ownerCard()}
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666"}}>
                                                {this.state.owner.name}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <PersonSearch label="Assign" isOpen={this.state.openPersonSearch} onPersonChosen={(p) => this.assignOwner(p)} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" md="8">
                                        Team <br />
                                        <div onClick={() => this.openTeamSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {this.state.team.name}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.assignTeam(t)} />
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row> */}
                                <Row style={{marginTop:"10px"}}>
                                    <Col xs="12">
                                        Tags <br />
                                        <Tag type="standardType" icon="nc-bookmark-2" 
                                            selected={this.state.standard.tags} 
                                            onChange={(tags) => this.updateStandard('tags',tags)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                        <Col sm="6">
                                            <b>Name</b>
                                        </Col>
                                        <Col sm="6">
                                            <b>URL</b>
                                        </Col>
                                </Row>
                                {this.state.standard.links.map((link, index) => (    
                                    <Row key={index} style={{marginBottom:"10px"}}>
                                        <Col sm="6"  >
                                            <input type="text" value={link.name} placeholder="name" 
                                                onChange={(e) => this.updateLink(link, "name", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="6"  >
                                            <input type="text" value={link.url} placeholder="url" 
                                                onChange={(e) => this.updateLink(link, "url", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                    </Row>
                                ))}
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6" >
                                <div style={{display:this.showNewLinkButton()}}>
                                    <Button onClick={() => this.addLink()}>Add Link</Button>
                                </div>
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveStandard()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    StandardEditor = connect(mapStateToProps)(StandardEditor);
    export default StandardEditor;
    