import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

import { strings } from '../../assets/utils'
import {
  COLORS,
} from './utils'

export default function SLAReportByPDG({
  labels, monthlySLAPDGResponseData, monthlySLAPDGResolutionData,
}) {
  const getAdherenceColor = (value) => {
    let adherenceColor = COLORS.green

    if (value === 0) {
      adherenceColor = COLORS.grey
    } else if (value < 75) {
      adherenceColor = COLORS.red
    } else if (value >= 75 && value < 90) {
      adherenceColor = COLORS.orange
    }

    return adherenceColor
  }

  return (
    <>
      <Card>
        <CardBody>
          <h6 className="big-title">
            SLA Adherence
            <br />
            <span style={{ fontSize: '10px', color: '#666666' }}>Time To Respond</span>
          </h6>
          <Table striped>
            <thead>
              <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
                <td>PDG</td>
                {labels.map((month) => <td key={month}>{month}</td>)}
              </tr>
            </thead>
            <tbody>
              {monthlySLAPDGResponseData && Object.keys(monthlySLAPDGResponseData).sort().map((team) => (
                <tr style={{ fontSize: '11px' }} key={team}>
                  <th title={team}>{strings.trim(team, 21)}</th>
                  {labels.map((month, index) => (
                    <td key={month} style={{ color: getAdherenceColor(monthlySLAPDGResponseData[team][index]) }}>
                      {monthlySLAPDGResponseData[team][index]}
                      <span>&#37;</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardBody>
          <h6 className="big-title">
            SLA Adherence
            <br />
            <span style={{ fontSize: '10px', color: '#666666' }}>Time To Resolution</span>
          </h6>
          <Table striped>
            <thead>
              <tr style={{ fontSize: '11px', fontWeight: 'bold' }}>
                <td>PDG</td>
                {labels.map((month) => <td key={month}>{month}</td>)}
              </tr>
            </thead>
            <tbody>
              {monthlySLAPDGResolutionData && Object.keys(monthlySLAPDGResolutionData).sort().map((team) => (
                <tr style={{ fontSize: '11px' }} key={team}>
                  <th title={team}>{strings.trim(team, 21)}</th>
                  {labels.map((month, index) => (
                    <td
                      key={month}
                      style={{ color: getAdherenceColor(monthlySLAPDGResolutionData[team][index]) }}
                    >
                      {monthlySLAPDGResolutionData[team][index]}
                      <span>&#37;</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
