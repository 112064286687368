
import React from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

import { db } from 'assets/firebase'; 


import NavEditor from "components/NavEditor/NavEditor";

class NavList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      navigation: []
    }

    this.insertNavItem = this.insertNavItem.bind(this);
    this.updateNavItem = this.updateNavItem.bind(this);

    this.onMoveDown = this.onMoveDown.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
  }

  componentDidMount() {
    this.getNavigation();
  }

  getNavigation() {
    db.getNavItems('root').then((results) => {

      results.sort(this.bySortIndex);

      for(var i = 0; i < results.length; i++) {
        if(!results[i].pages)
          results[i].pages = [];
        if(!results[i].sortIndex)
          results[i].sortIndex = i;
      }

      this.setState({navigation: results});
    });
  }

  insertNavItem(navItem) {
    var navigation = this.state.navigation;
    navItem.sortIndex = navigation.length;
    navigation.push(navItem);
    this.setState(navigation);
  }

  updateNavItem(navItem) {
    var navigation = this.state.navigation;
    for(var i = 0; i < navigation.length; i++) {
      if(navigation[i].id === navItem.id) {
        navigation[i] = navItem;
        break;
      }
    }
    this.setState(navigation);
  }

  bySortIndex(a,b) {

    var x = a.sortIndex;
    var y = b.sortIndex;
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  isLastItem(navItem) {
    if(navItem.sortIndex === this.state.navigation.length - 1) 
      return true;
    else 
      return false;
  }

  onMoveUp(navItem) {

    var targetIndex = navItem.sortIndex - 1;
    var navigation = this.state.navigation;

    console.log(navigation);

    for(var i = 0; i < navigation.length; i++) {
      if(navigation[i].id === navItem.id)
        navigation[i].sortIndex = navigation[i].sortIndex - 1;
      
      else if(navigation[i].sortIndex === targetIndex) 
        navigation[i].sortIndex = navigation[i].sortIndex + 1;
    }

    navigation.sort(this.bySortIndex);
    this.setState({navigation: navigation}, this.saveNavItems);

    console.log(navigation);
  }

  onMoveDown(navItem) {
    var targetIndex = navItem.sortIndex + 1;
    var navigation = this.state.navigation;

    for(var i = 0; i < navigation.length; i++) {
      if(navigation[i].id === navItem.id)
        navigation[i].sortIndex = navigation[i].sortIndex + 1;
      
      else if(navigation[i].sortIndex === targetIndex) 
        navigation[i].sortIndex = navigation[i].sortIndex - 1;
    }

    navigation.sort(this.bySortIndex);
    this.setState({navigation: navigation}, this.saveNavItems);
  }

  saveNavItems() {
    for(var i = 0; i < this.state.navigation.length; i++) 
      db.updateNavigation(this.state.navigation[i].id, this.state.navigation[i]);
    
  }

  render() {

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              
              <div style={{marginTop:"40px"}}>
                {this.state.navigation.map((navItem, index) => (
                  <NavEditor key={index} index={navItem.sortIndex} 
                    navItem={navItem} 
                    onUpdate={this.updateNavItem} 
                    onMoveUp={this.onMoveUp}
                    onMoveDown={this.onMoveDown}
                    isLastItem={this.isLastItem(navItem)}
                    />
                ))}  
              </div>                  
            </Col>
          </Row>
          <Row>
            <Col md="12" >
              <NavEditor onInsert={this.insertNavItem} parentID="root" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default NavList;
