import React from 'react'
import CSVReader from 'react-csv-reader'
import PerfectScrollbar from 'react-perfect-scrollbar'
// redux
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
// reactstrap components
import {
  Button, Card, CardBody, Col, Row,
} from 'reactstrap'

import { algolia } from '../assets/algolia'
import { db } from '../assets/firebase'
import feedbackImage from '../assets/img/feedbackSmall.jpg'
import logo from '../assets/img/goConnectIcon.png'
import kudosImage from '../assets/img/kudos.png'
import profileImage from '../assets/img/profile_strength.png'
import { arraySort, telemetry } from '../assets/utils'
import OrgChart from '../components/OrgChart/OrgChart'
import PersonCard from '../components/PersonCard/PersonCard'
import PersonEditor from '../components/PersonEditor/PersonEditor'
import ProjectCard from '../components/ProjectCard/ProjectCard'
import ProjectSearch from '../components/ProjectSearch/ProjectSearch'
import TeamCard from '../components/TeamCard/TeamCard'

const kevinEmployeeId = 'G0000023'
const andreEmployeeId = 'G0001007'
const patrickEmployeeId = 'G0014526'
const CEOEmployeeIds = [kevinEmployeeId, andreEmployeeId, patrickEmployeeId]

const groupCEOUserId = '13efc522-4cc5-421a-b98a-02569d356d6a'

// unique id generator
const uuid = require('uuid')

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

class GetStarted extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      person: {},
      profileStrength: 10,
      profileImprovements: [],

      orgChartData: {
        id: 'root',
        name: 'GoTo',
        isScaffolding: true,
        children: [],
      },
      teamChartData: {},

      showFilePicker: false,
      isLoading: true,

      peopleResults: [],
      teamResults: [],
      projectResults: [],
      importedPeople: [],
      products: [],
      projects: [],
      teams: [],
      users: [],
      recentReviews: [],
      recentlyUpdatedPeople: [],
      recentlyUpdatedTeams: [],
      recentlyUpdatedProjects: [],

      landingPageView: props.landingPageView,
      orgChartView: props.orgChartView,
      teamChartView: props.teamChartView,
      productView: false,
      searchResultsView: false,
      landingPageOpacity: 0,
      orgChartOpacity: 0,
      teamChartOpacity: 0,
      showOKRs: false,

      loopCounter: 0,
      redirectToFeedback: false,
      redirectToKudos: false,
    }

    this.getCEOs = this.getCEOs.bind(this)
    this.getManagerOrg = this.getManagerOrg.bind(this)
    this.getSpecificUser = this.getSpecificUser.bind(this)
    this.haveSearchResults = this.haveSearchResults.bind(this)
    this.importCSV = this.importCSV.bind(this)
    this.onExpanded = this.onExpanded.bind(this)
    this.onFileLoaded = this.onFileLoaded.bind(this)
    this.showSearchResults = this.showSearchResults.bind(this)
    this.showPeopleResults = this.showPeopleResults.bind(this)
    this.showProjectResults = this.showProjectResults.bind(this)
    this.showTeamResults = this.showTeamResults.bind(this)
  }

  componentDidMount() {
    document.documentElement.className += ' perfect-scrollbar-on'
    document.documentElement.classList.remove('perfect-scrollbar-off')

    if (this.props.orgChartView) {
      this.getCEOs()
    } else if (this.props.teamChartView) {
      this.getTeamsChart()
    } else {
      this.getRecentUpdates()
    }

    this.getLinkedPerson()
    this.getLinkedProject()
    this.getLinkedTeam()

    telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'orgChart', 'pageView').then((activity) => {
      setInterval(() => telemetry.trackDwellTime(activity), 60000)
    })
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.searchQuery !== this.props.searchQuery) {
    //   this.setState({ queryText: this.props.searchQuery }, () => {
    //     if (this.state.queryText.length > 0) {
    //       this.searchAll()
    //     }
    //   })
    // }

    if (prevProps.landingPageView !== this.props.landingPageView
            || prevProps.productView !== this.props.productView
            || prevProps.orgChartView !== this.props.orgChartView
            || prevProps.teamChartView !== this.props.teamChartView
            || prevProps.projectView !== this.props.projectView
            || prevProps.searchView !== this.props.searchView
    ) {
      // loadDefault = false;
      this.setState({ peopleResults: [], teamResults: [], projectResults: [] })

      if (this.props.teamChartView) {
        this.getTeamsChart()
      } else if (this.props.orgChartView) {
        this.getCEOs()
      } else if (this.props.landingPageView) { this.getRecentUpdates() }

      this.setState({
        landingPageView: this.props.landingPageView,
        productView: this.props.productView,
        orgChartView: this.props.orgChartView,
        teamChartView: this.props.teamChartView,
        searchResultsView: false,
      })
    }
  }

  getRecentUpdates() {
    this.setState({ isLoading: true }, () => {
      this.getProfileStrength()

      const promises = []
      let people = []
      let projects = []
      let reviews = []
      let teams = []

      promises.push(db.getRecentlyUpdatedPeople())
      promises.push(db.getRecentlyUpdatedProjects())
      promises.push(db.getRecentlyUpdatedTeams())
      promises.push(db.getRecentReviews())

      Promise.all(promises).then((results) => {
        for (let i = 0; i < results.length; i += 1) {
          if (results[i][0]) {
            if (results[i][0].employeeID) {
              people = results[i]
            } else if (results[i][0].parentID) {
              teams = results[i]
            } else if (results[i][0].review) { reviews = results[i] } else { projects = results[i] }
          }
        }

        this.setState({
          recentlyUpdatedPeople: people,
          recentlyUpdatedProjects: projects,
          recentlyUpdatedTeams: teams,
          recentReviews: reviews,
          landingPageOpacity: 1,
          isLoading: false,
        })
      })
    })
  }

  getLinkedPerson() {
    if (this.props.personID) {
      db.getPerson(this.props.personID).then((person) => {
        this.getSpecificUser(person)
      })
    }
  }

  getLinkedProject() {
    if (this.props.projectID && !this.props.searchQuery) {
      db.getProject(this.props.projectID).then((project) => {
        this.setState({ linkedProject: project })
      })
    }
  }

  getLinkedTeam() {
    if (this.props.teamID) {
      db.getTeam(this.props.teamID).then((team) => {
        this.getSpecificTeam(team)
      })
    } else if (this.props.teamSlug) {
      db.getTeamBySlug(this.props.teamSlug).then((team) => {
        this.getSpecificTeam(team)
      })
    }
  }

  getProfileStrength() {
    db.getPerson(this.props.user.person.id).then((person) => {
      let score = 10
      let suggestions = []

      if (person.responsibilities) score += 15
      else { suggestions.push("Tell us what we can 'ask me (you) about'!") }

      if (person.workingHours && person.timeZone) score += 8
      else { suggestions.push('Add you working hours so we know when to leave you alone.') }

      if (person.linkedIn) score += 10
      else { suggestions.push('Add a link to your LinkedIn profile.') }

      if (person.instagram || person.twitter) score += 6
      else { suggestions.push('Add a link to your Instagram or Twitter profile.') }

      if (!person.skills) person.skills = []
      if (person.skills.length > 0) score += person.skills.length
      else { suggestions.push("Add skills to share what you're good at.") }

      if (person.music) score += 8
      else { suggestions.push('Share with us your favorite musical artists or genres.') }

      if (person.hobbies) score += 8
      else { suggestions.push('Tell us about your hobbies.') }

      if (person.photos.length > 0) score += (person.photos.length * 3)
      else { suggestions.push('Add photos so we can get to know you better.') }

      if (score > 100) { score = 100 }

      if (suggestions.length > 3) { suggestions = suggestions.splice(0, 3) }

      this.setState({ profileStrength: score, profileImprovements: suggestions, person })
    })
  }

  getStrengthColor() {
    if (this.state.profileStrength > 76) return 'green'
    if (this.state.profileStrength > 60) return 'orange'
    return 'red'
  }

  getStrengthPercentage() {
    return `${this.state.profileStrength}%`
  }

  getCEOs() {
    this.setState({ isLoading: true }, () => {
      if (this.state.orgChartData.children.length === 0) {
        this.getPersonRoot(groupCEOUserId).then((patrick) => {
          const chart = this.state.orgChartData
          chart.children.push(patrick)

          this.setState({ orgChartData: chart, orgChartOpacity: 1, isLoading: false })
        })
      } else {
        this.setState({ orgChartOpacity: 1, isLoading: false })
      }
    })
  }

  getPersonRoot(id) {
    return new Promise((resolve) => {
      db.getPerson(id).then((manager) => {
        db.getPeopleByManagerID(manager.id).then((peerResults) => {
          for (let z = peerResults.length - 1; z > -1; z--) // special case, don't allow CEO to be added as his own subordinate
          {
            if (peerResults[z].id === id || CEOEmployeeIds.includes(peerResults[z].employeeID)) {
              peerResults.splice(z, 1)
            // break;
            }
          }

          peerResults.sort(arraySort.byName)
          manager.preload = peerResults
          resolve(manager)
        })
      })
    })
  }

  getSpecificUser(person) {
    this.props.onOrgChartView()
    this.setState({ selected: person, isLoading: true, orgChartOpacity: 0 }, () => {
      person.isSelected = true
      this.getManagerOrg(person)
    })
  }

  getSpecificTeam(team) {
    // FIXME: redirect to not found page
    if (!team) {
      return
    }

    this.setState({ selectedTeamID: team.id, teamChartData: {} }, () => {
      team.isSelected = true
      this.getTeamsChart()
      this.setState({ linkedTeam: team })
    })
  }

  getManagerOrg(person) {
    if ( CEOEmployeeIds.includes(person.employeeID)) {
      const orgChart = this.state.orgChartData
      // only replace the CEO that this person reports up to
      for (let x = 0; x < orgChart.children.length; x++) {
        if (orgChart.children[x].id === person.id) { orgChart.children[x] = person }
      }

      this.setState({
        orgChartData: orgChart,
        orgChartOpacity: 1,
        isLoading: false,
        landingPageView: false,
        productView: false,
        orgChartView: true,
        teamChartView: false,
        searchResultsView: false,
      })
    } else {
      db.getPerson(person.managerID).then((manager) => {
        db.getPeopleByManagerID(manager.id).then((peerResults) => {
          for (let z = peerResults.length - 1; z > -1; z--) // special case, don't allow CEO to be added as his own subordinate
          // if(peerResults[z].id === '60af56fb-84d2-4e6f-84dc-80ffef439fd8') {
          {
            if ( CEOEmployeeIds.includes(peerResults[z].employeeID)) {
              peerResults.splice(z, 1)
            } else if (peerResults[z].id === person.id) {
              peerResults[z] = person
            }
          }

          peerResults.sort(arraySort.byName)
          manager.children = peerResults
          const promises = []

          for (let i = 0; i < manager.children.length; i += 1) { promises.push(db.getPeopleByManagerID(manager.children[i].id)) }

          Promise.all(promises).then((results) => { // results are managers peers
            for (let j = 0; j < results.length; j += 1) {
              if (results[j].length > 0) { // i.e. if they have any directs
                for (let m = 0; m < manager.children.length; m++) {
                  if (manager.children[m].id === results[j][0].managerID) {
                    results[j].sort(arraySort.byName)

                    manager.children[m].preload = results[j]

                    break
                  }
                }
              }
            }

            this.getManagerOrg(manager)
          })
        })
      })
    }
  }

  onExpanded(person) {
    const promises = []

    if (person.children && !person.isExpanded) {
      for (let i = 0; i < person.children.length; i += 1) { promises.push(db.getPeopleByManagerID(person.children[i].id)) }

      Promise.all(promises).then((results) => {
        for (let j = 0; j < results.length; j += 1) {
          if (results[j].length > 0) { // i.e. if they have any directs
            for (let m = 0; m < person.children.length; m++) {
              if (person.children[m].id === results[j][0].managerID) {
                results[j].sort(arraySort.byName)
                person.children[m].preload = results[j]

                break
              }
            }
          }
        }

        person.isExpanded = true

        const root = this.state.orgChartData

        for (let x = 0; x < root.children.length; x++) {
          this.findNode(root.children[x], person)
        }

        this.setState({ orgChartData: root })
      })
    }
  }

  findNode(node, person) {
    if (node.id === person.id) {
      node = person
    } else {
      try {
        if (node.children) {
          for (let i = 0; i < node.children.length; i += 1) this.findNode(node.children[i], person)
        }
      } catch (exc) {
        console.log(exc)
      }
    }
  }

  getTeamsChart(forceRefresh) {
    this.setState({ isLoading: true }, () => {
      telemetry.logUserActivity(this.props.user.id, this.props.user.personID, 'teamChart', 'pageView')

      if (!this.state.teamChartData.id || forceRefresh) {
        const teamChart = {
          id: 'root',
          name: 'GoTo',
          isScaffolding: true,
          children: [
            {
              id: '4WIR9CpYkbHIWO8kF2b7',
              name: 'Gojek',
              isScaffolding: true,
              profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2Fb920872d-3d34-400d-acf7-1e56d15f71d7.png?alt=media&token=8558b6c8-1284-4286-ad2d-bfe781989182',
              preload: [{
                id: 'corporate',
                name: 'Corporate',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'functional',
                name: 'Functional Orgs',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'product',
                name: 'Product Groups',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'platform',
                name: 'Platforms',
                isScaffolding: true,
                preload: [],
                children: [],
              }],
              children: [],
            },
            {
              id: 'jx0y4iLV90d3bd4V6QjB',
              name: 'GTF',
              isScaffolding: true,
              profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2F44d337da-8dbe-4f57-80a2-c62c19780e2c.png?alt=media&token=fd638d61-5e6f-4371-a4a5-af3a56f89cb5',
              preload: [{
                id: 'gtfCorporate',
                name: 'Corporate',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'gtfFunctional',
                name: 'Functional Orgs',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'gtfProduct',
                name: 'Product Groups',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'gtfPlatform',
                name: 'Platforms',
                isScaffolding: true,
                preload: [],
                children: [],
              }],
              children: [],
            },
            {
              id: 'acvhvQ6uFgURgIDTHD1o',
              name: 'Tokopedia',
              isScaffolding: true,
              profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2F7253f94c-4546-4d3b-8ddd-4de9ec968272.png?alt=media&token=3bc052ef-107f-4064-a404-9dc74693c4fd',
              preload: [{
                id: 'tokopediaCorporate',
                name: 'Corporate',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'tokopediaFunctional',
                name: 'Functional Orgs',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'tokopediaProduct',
                name: 'Product Groups',
                isScaffolding: true,
                preload: [],
                children: [],
              },
              {
                id: 'tokopediaPlatform',
                name: 'Platforms',
                isScaffolding: true,
                preload: [],
                children: [],
              }],
              children: [],
            },
            {
              id: 'acvhvQ6uFgURgIDTHD1o',
              name: 'Tech Office',
              isScaffolding: true,
              profilePhoto: 'https://firebasestorage.googleapis.com/v0/b/goconnect.appspot.com/o/profilePhotos%2F486649d9-1d16-43c2-ae3e-7e0b3df184b0.svg?alt=media&token=99b5d177-c60c-4037-8585-6ca072195887',
              preload: [{
                id: 'horizontals',
                name: 'Horizontals',
                isScaffolding: true,
                preload: [],
                children: [],
              }],
              children: [],
            },
          ],
        }

        db.getTeams().then((teams) => {
          this.addTeams(teamChart, teams)
        })
      } else {
        this.setState({
          isLoading: false,
          landingPageView: false,
          productView: false,
          orgChartView: false,
          teamChartView: true,
          searchResultsView: false,
        })
      }
    })
  }

  addTeams(root, teams) {
    for (let i = teams.length - 1; i > -1; i--) {
      for (let x = 0; x < root.children.length; x++) {
        if (this.addTeamNode(root.children[x], teams[i]) > 0) {
          teams.splice(i, 1)
          break
        }
      }
    }

    if (teams.length > 0) {
      if (this.state.loopCounter < 10) { // shouldn't need this, temporary
        this.setState({ loopCounter: this.state.loopCounter + 1 }, () => {
          this.addTeams(root, teams)
        })
      } else {
        // temporary
        this.setState({
          teamChartData: root,
          teamChartOpacity: 1,
          isLoading: false,
          landingPageView: false,
          productView: false,
          orgChartView: false,
          teamChartView: true,
          searchResultsView: false,
          peopleResults: [],
          projectResults: [],
          teamResults: [],
        })
      }
    } else {
      this.setState({
        teamChartData: root,
        teamChartOpacity: 1,
        isLoading: false,
        landingPageView: false,
        productView: false,
        orgChartView: false,
        teamChartView: true,
        searchResultsView: false,
        peopleResults: [],
        projectResults: [],
        teamResults: [],
      })
    }
  }

  addTeamNode(node, team) {
    if (node.id === team.parentID) {
      if (!node.children) node.children = []

      if (team.id === this.state.selectedTeamID) { // selected team
        if (node.preload) {
          node.children = node.preload
          delete node.preload
        }

        team.isSelected = true
        node.children.push(team)
        return 2
      } if (node.children.length > 0) { // sibling of selected team
        node.children.push(team)
        node.children.sort(arraySort.byName)
        return 1
      } // all other teams

      if (!node.preload) node.preload = []
      node.preload.push(team)
      node.preload.sort(arraySort.byName)
      return 1
    }

    if (node.preload) {
      for (let i = 0; i < node.preload.length; i += 1) {
        const result = this.addTeamNode(node.preload[i], team)
        if (result === 2) {
          node.children = node.preload
          delete node.preload
        }
        if (result > 0) return result
      }
    }

    if (node.children) {
      for (let j = 0; j < node.children.length; j += 1) {
        if (this.addTeamNode(node.children[j], team) > 0) return 1
      }
    }

    return 0
  }

  // searchAll() {
  //   this.setState({
  //     isLoading: true,
  //     peopleResults: [],
  //     teamResults: [],
  //     projectResults: [],
  //     landingPageView: false,
  //     orgChartView: false,
  //     productView: false,
  //     teamChartView: false,
  //   }, () => {
  //     algolia.searchAll(this.state.queryText).then((result) => {
  //       this.setState({
  //         peopleResults: result.people,
  //         projectResults: result.projects,
  //         teamResults: result.teams,
  //         isLoading: false,
  //         searchResultsView: true,
  //       })
  //     })
  //   })
  // }

  linkedPerson() {
    if (this.state.linkedPerson) {
      return (
        <div style={{ display: 'none' }}>
          <PersonCard person={this.state.linkedPerson} openByDefault />
        </div>
      )
    }
  }

  linkedProject() {
    if (this.state.linkedProject) {
      return (
        <div style={{ display: 'none' }}>
          <ProjectCard project={this.state.linkedProject} openByDefault />
        </div>
      )
    }
  }

  linkedTeam() {
    if (this.state.linkedTeam) {
      return (
        <div style={{ display: 'none' }}>
          <TeamCard team={this.state.linkedTeam} />
        </div>
      )
    }
  }

  importCSV() {
    this.setState({ showFilePicker: true })
  }

  onFileError(error) {
    console.log(error)
  }

  onFileLoaded(data) {
    // console.log(data[0])
    // return

    this.setState({ showFilePicker: false })

    db.getPeople().then((people) => {
      for (let i = 0; i < data.length; i += 1) {
        const person = {
          companyID: data[i].company___id,
          company: data[i].company___name,
          costCenter: data[i].cost_center___id,
          costCenterName: data[i].cost_center___name,
          name: data[i].full_name,
          title: data[i].business_title,
          email: data[i].email___primary_work,
          location: data[i].work_location,
          employeeID: data[i].go_troop_id,
          employeeManagerID: data[i].manager_id,
        }

        if (person.name.indexOf('|')) { person.name = person.name.substr(person.name.indexOf('|') + 1) }

        if (person.name.indexOf('(') > -1) { person.name = person.name.substr(0, person.name.indexOf('(')) }

        person.name = person.name.trim()

        if (person.email) {
          person.email = person.email.toLowerCase()

          if (person.title === 'ENGINEERING LEAD') { person.title = 'Engineering Lead' }

          let isFound = false
          for (let x = 0; x < people.length; x++) {
            if (people[x].employeeID === person.employeeID) {
              people[x].isChanged = false

              if (people[x].name !== person.name) {
                console.log('NAME change')
                console.log(`${people[x].name} - ${person.name}`)
                people[x].name = person.name
                people[x].isChanged = true
              }

              if (people[x].title !== person.title) {
                console.log('TITLE change')
                console.log(`${people[x].title} - ${person.title}`)
                people[x].title = person.title
                people[x].isChanged = true
              }

              if (people[x].email !== person.email && person.email.indexOf('@aux') === -1) {
                console.log('EMAIL change')
                console.log(`${people[x].email} - ${person.email}`)
                people[x].email = person.email
                people[x].isChanged = true
              }

              if (people[x].location !== person.location) {
                console.log('LOCATION change')
                console.log(`${people[x].location} - ${person.location}`)
                people[x].location = person.location
                people[x].isChanged = true
              }

              if (people[x].employeeManagerID !== person.employeeManagerID) {
                console.log('MANAGER change')
                console.log(`${people[x].employeeManagerID} - ${person.employeeManagerID}`)
                people[x].employeeManagerID = person.employeeManagerID
                people[x].isChanged = true
              }

              isFound = true

              break
            }
          }

          if (!isFound) {
            person.id = uuid.v4()
            person.isChanged = true
            person.imported = new Date().getTime()
            people.push(person)
            console.log(`new employee: ${person.name}`)
          }
        } else {
          console.log('NO EMAIL?')
          console.log(person)
        }
      }

      people.forEach((person) => {
        if (person.employeeManagerID) {
          for (let j = 0; j < people.length; j += 1) {
            if (people[j].employeeID === person.employeeManagerID) {
              if (person.managerID !== people[j].id) { // if not CEO
                console.log(`MANAGER change for : ${person.name}`)
                person.managerID = people[j].id
                person.isChanged = true
              }
              break
            }
          }
        }

        // remove employees who have left
        let employeeFound = false
        for (let d = 0; d < data.length; d++) {
          if (data[d].go_troop_id === person.employeeID) {
            employeeFound = true
            break
          }
        }
        if (!employeeFound) {
          person.isDeleted = true
          console.log(`deleted: ${person.name}`)
        }
      })

      let counter = 0
      let deletions = 0
      people.forEach((person) => {
        const delay = Math.floor(Math.random() * 180000)

        if (person.isDeleted) {
          if (
            person.id !== 'DDNLkFNqVZwMUVPFGvoO'
                        && person.id !== '60hC26WJPcnrwqTbsJo6'
                        && person.id !== 'EsKb4YNdgjo40gX4GQH9'
                        && person.id !== 'KW7SASHHZPwnQkZVkkGn'
                        && person.id !== 'adhnHVXz1llmd0IzX603'
                        && person.id !== 'NRuMTUJNAzoN7PQvaK9j'
                        && person.id !== 'd8KuXHLGETYYDWO1EiEQ'
                        && person.id !== 'rFHN7Avon5PtqkQ7w9OE'
                        && person.id !== 'mQ0oGqzGEo5slGs3ZsdR'
                        && person.id !== 'hC1QwRqg0GKU0zaEH5eA'
                        && person.id !== 'gY1pSudfv1OtpT9ccRaC'
                        && person.id !== 'Lu3gA865KqVY5eIgypwu'
                        && person.id !== 'nBHGmRJt53oxgX6yDKQ1'
                        && person.id !== 'CUqw0asEYPsKGRt41Api'
                        && person.id !== 'FavMmeBw9ija6d5Is5Yz'
                        && person.id !== 'xxx'
          ) {
            return sleep(delay).then(() => {
              console.log(`${counter} - ${delay} - DELETE : ${person.name}`)
              // algolia.deletePerson(person);
              // db.deletePerson(person.id);
              deletions += 1
              counter += 1
            })
          }
        } else if (person.isChanged) {
          return sleep(delay).then(() => {
            // algolia.upsertPerson(person);
            // db.updatePerson(person.id, person);
            counter += 1
            console.log(`${counter} - ${delay} - UPSERT:${person.name}`)
          })
        }
      })

      return sleep(181000).then(() => {
        console.log(`total: ${counter}`)
        console.log(`deletions: ${deletions}`)
      })
    })
  }

  updateIndex() {
    let counter = 0
    db.getPeople().then((people) => {
      people.forEach((person) => {
        const delay = Math.floor(Math.random() * 180000)
        return sleep(delay).then(() => {
          // algolia.upsertPerson(person)
          // db.updatePerson(person.id, person);
          counter += 1
          console.log(`${counter} - ${delay} - ${person.name}`)
        })
      })
    })
  }

  updateTeamIndex() {
    let counter = 0
    db.getTeams().then((teams) => {
      teams.forEach((team) => {
        const delay = Math.floor(Math.random() * 180000)
        return sleep(delay).then(() => {
          // algolia.upsertTeam(team)
          // db.updatePerson(person.id, person);
          counter += 1
          console.log(`${counter} - ${delay} - ${team.name}`)
        })
      })
    })
  }

  byProduct(a, b) {
    const x = a.product.toLowerCase()
    const y = b.product.toLowerCase()

    if (x < y) { return -1 }
    if (x > y) { return 1 }
    return 0
  }

  addToImported(person) {
    const people = this.state.importedPeople
    this.state.importedPeople.push(person)
    this.setState({ importedPeople: people })
  }

  showFilePicker() {
    if (this.state.showFilePicker) return 'block'
    return 'none'
  }

  haveSearchResults() {
    if (this.state.peopleResults.length > 0
            || this.state.teamResults.length > 0
            || this.state.projectResults.length > 0) return true
    return false
  }

  showSearchResults() {
    if (this.state.searchResultsView) return 'block'
    return 'none'
  }

  showNoResults() {
    if (!this.haveSearchResults() && !this.state.isLoading) return 'block'
    return 'none'
  }

  showPeopleResults() {
    if (this.state.peopleResults.length > 0) return 'block'
    return 'none'
  }

  showTeamResults() {
    if (this.state.teamResults.length > 0) return 'block'
    return 'none'
  }

  showProjectResults() {
    if (this.state.projectResults.length > 0) return 'block'
    return 'none'
  }

  showOrgChart() {
    if (!this.haveSearchResults() && this.state.orgChartView) return 'block'
    return 'none'
  }

  showProductView() {
    if (!this.haveSearchResults() && this.state.productView) return 'block'
    return 'none'
  }

  showTeamChartView() {
    if (!this.haveSearchResults() && this.state.teamChartView) return 'block'
    return 'none'
  }

  showLandingView() {
    if (this.state.landingPageView && !this.state.isLoading) return 'block'
    return 'none'
  }

  showLoading() {
    if (this.state.isLoading) return 'block'
    return 'none'
  }

  showOKRs() {
    this.setState({ showOKRs: true })
  }

  showOKRButton() {
    if (!this.state.showOKRs) return 'block'
    return 'none'
  }

  redirectFeedback() {
    this.setState({ redirectToFeedback: true })
  }

  redirectKudos() {
    this.setState({ redirectToKudos: true })
  }

  onTeamChartChanged() {
    this.getTeamsChart()
  }

  onTeamDeleted(team) {
    this.getTeamsChart(true)
  }

  render() {
    const icon = {
      fontSize: '21px',
      marginTop: '3px',
      color: '#ffffff',
    }
    const navIcon = {
      fontSize: '17px',
      marginTop: '3px',
      cursor: 'pointer',
    }

    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header
        .toLowerCase()
        .replace(/\W/g, '_'),
    }

    const loadingDiv = {
      display: this.showLoading(),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }

    const landingPageViewDiv = {
      margin: 'auto',
      padding: '20px', // this is to disable the weird sidewards scroll
      overflow: 'hidden',
      opacity: this.state.landingPageOpacity,
      transition: 'opacity 0.23s',
      display: this.showLandingView(),
      position: 'relative',
      maxHeight: '96vh',
    }

    const teamChartViewDiv = {
      margin: 'auto',
      padding: '10px',
      overflow: 'hidden',
      opacity: this.state.teamChartOpacity,
      transition: 'opacity 0.23s',
      display: this.showTeamChartView(),
      position: 'relative',
      minHeight: '96vh',
    }

    const orgViewDiv = {
      margin: 'auto',
      padding: '10px',
      overflow: 'hidden',
      opacity: this.state.orgChartOpacity,
      transition: 'opacity 0.23s',
      display: this.showOrgChart(),
      position: 'relative',
      minHeight: '96vh',
    }

    const searchResultsViewDiv = {
      display: this.showSearchResults(),
      padding: '20px',
      overflow: 'hidden',
    }

    const searchResultsPeopleDiv = {
      display: this.showPeopleResults(),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
    const searchResultsTeamDiv = {
      display: this.showTeamResults(),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
    const searchResultsProjectDiv = {
      display: this.showProjectResults(),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }

    const productViewDiv = {
      display: this.showProductView(),
      padding: '20px',
    }

    if (this.state.redirectToFeedback) {
      return <Redirect to="/feedback" />
    }
    if (this.state.redirectToKudos) {
      return <Redirect to="/kudos" />
    }

    return (

      <div className="content" style={{ padding: '10px' }}>

        <style>
          {
                    `
                    @keyframes spinning {
                        from { transform: rotate(0deg) }
                        to { transform: rotate(360deg) }
                    }
                    .spin {
                        animation-name: spinning;
                        animation-duration: 3s;
                        animation-iteration-count: infinite;
                        /* linear | ease | ease-in | ease-out | ease-in-out */
                        animation-timing-function: linear;
                    }
                    `
                }
        </style>

        <div style={loadingDiv}>
          <div className="spin">
            <img src={logo} alt="logo" style={{ height: '54px', width: '54px' }} />
          </div>
        </div>

        {this.linkedPerson()}

        {this.linkedTeam()}

        {/* landing page */}
        <PerfectScrollbar style={landingPageViewDiv}>

          <Row>
            <Col xs={12} sm={12} md={9}>
              <Row>
                <Col xs={12}>
                  <h4>Recently Updated Colleagues</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                    {this.state.recentlyUpdatedPeople.map((person, index) => (
                      <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                        <PersonCard person={person} onShowOrg={this.getSpecificUser} />
                      </div>
                    ))}
                  </div>
                  <div style={{
                    position: 'absolute', right: '0px', top: '0px', bottom: '0px', width: '100px', pointerEvents: 'none', backgroundImage: 'linear-gradient(to left, #efefefff, #efefefff, #efefef00)',
                  }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button size="sm" onClick={this.props.onOrgChartView}>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                      <i style={navIcon} className="nc-icon nc-vector" />
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                      Org Chart
                    </div>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <h4>Recently Updated Teams</h4>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                    {this.state.recentlyUpdatedTeams.map((team, index) => (
                      <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                        <TeamCard
                          team={team}
                          onShowOrg={(t) => this.getSpecificTeam(t)}
                          updateStatesOnTeamPageRedirection={this.props.onTeamPageView}
                          redirectToTeamPage
                        />
                      </div>
                    ))}
                  </div>
                  <div style={{
                    position: 'absolute', right: '0px', top: '0px', bottom: '0px', width: '100px', pointerEvents: 'none', backgroundImage: 'linear-gradient(to left, #efefefff, #efefefff, #efefef00)',
                  }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button size="sm" onClick={this.props.onTeamChartView}>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                      <i style={navIcon} className="nc-icon nc-vector" />
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px' }}>
                      Team Hierarchy
                    </div>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <h4>Recently Updated Projects</h4>
                  <div style={{ overflowX: 'hidden', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                    {this.state.recentlyUpdatedProjects.map((project, index) => (
                      <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                        <ProjectCard project={project} />
                      </div>
                    ))}
                  </div>
                  <div style={{
                    position: 'absolute', right: '0px', top: '0px', bottom: '0px', width: '100px', pointerEvents: 'none', backgroundImage: 'linear-gradient(to left, #efefefff, #efefefff, #efefef00)',
                  }}
                  />
                </Col>
              </Row>
              {/* <Row style={{ display: 'none' }}>
                <Col xs={12}>
                  <ProjectSearch onProjectChosen={(p) => this.addToProject(p)} />
                </Col>
              </Row> */}

            </Col>

            <Col xs={12} sm={12} md={3} style={{ marginTop: '50px' }}>

              {/* <HomePageCard backgroundImageURL={nexusImage} cardTitle="Nexus" cardText={NEXUS_TEXT}
               addNewTag link="/nexus"/> */}

              <Card style={{ marginTop: '40px', maxWidth: '440px' }}>
                <CardBody style={{
                  backgroundSize: 'cover',
                  height: '250px',
                  width: '100%',
                  backgroundColor: '#aaaaaa',
                  borderRadius: '6px',
                  backgroundImage: `url(${profileImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'left',
                }}
                >
                  <div style={{
                    background: 'linear-gradient(to top, #000000ff, #000000cc, #00000000)', position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '6px',
                  }}
                  >

                    <div style={{
                      position: 'absolute', bottom: '20px', paddingLeft: '20px', paddingRight: '20px', color: 'white', fontSize: '12px',
                    }}
                    >

                      <div style={{ fontSize: '17px', fontWeight: 600 }}>
                        Profile Strength:
                        {' '}
                        {this.state.profileStrength}
                        {' '}
                        <span style={{ fontSize: '10px', fontWeight: 400 }}>/ 100</span>
                      </div>

                      <div>
                        Here are a few ways to improve your profile score:
                      </div>

                      {this.state.profileImprovements.map((suggestion, index) => (
                        <div key={index}>
                          -
                          {' '}
                          {suggestion}
                        </div>
                      ))}

                    </div>

                    <div style={{ display: 'none' }}>
                      <Row>
                        <Col xs="12" style={{ textAlign: 'center' }}>
                          <PersonEditor person={this.state.person} size="sm" />
                        </Col>
                      </Row>
                    </div>

                  </div>
                </CardBody>
              </Card>

              <Card style={{ marginTop: '40px', maxWidth: '440px', cursor: 'pointer' }} onClick={() => this.redirectKudos()}>
                <CardBody style={{
                  backgroundSize: 'cover',
                  height: '200px',
                  width: '100%',
                  backgroundColor: '#aaaaaa',
                  borderRadius: '6px',
                  backgroundImage: `url(${kudosImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                }}
                >
                  <div style={{
                    background: 'linear-gradient(to top, #000000ff, #000000cc, #00000000)', position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '6px',
                  }}
                  >
                    <div style={{
                      position: 'absolute', bottom: '20px', paddingLeft: '20px', paddingRight: '20px', color: 'white', fontSize: '12px',
                    }}
                    >

                      <div style={{ fontSize: '17px', fontWeight: 600 }}>
                        Kudos
                      </div>
                      Send kudos to say thanks to team players, customer advocates, mentors and anyone
                      else you think deserves recognition! Come check out the Kudos wall to start!
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card style={{ marginTop: '40px', maxWidth: '440px', cursor: 'pointer' }} onClick={() => this.redirectFeedback()}>
                <CardBody style={{
                  backgroundSize: 'cover',
                  height: '200px',
                  width: '100%',
                  backgroundColor: '#aaaaaa',
                  borderRadius: '6px',
                  backgroundImage: `url(${feedbackImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                }}
                >
                  <div style={{
                    background: 'linear-gradient(to top, #000000cc, #00000000, #00000000)', position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', borderRadius: '6px',
                  }}
                  >

                    <div style={{
                      position: 'absolute', bottom: '20px', paddingLeft: '20px', paddingRight: '20px', color: 'white', fontSize: '12px',
                    }}
                    >
                      <div style={{ fontSize: '17px', fontWeight: 600 }}>
                        Feedback
                      </div>
                      Read and research what users are saying in our App/Play Store reviews.
                      You can even reach out to say thanks for great reviews!
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </PerfectScrollbar>

        {/* search results */}
        <PerfectScrollbar style={searchResultsViewDiv}>

          <div style={{ display: this.showNoResults(), color: 'maroon', marginTop: '10px' }}>
            No results were found matching your query.
          </div>

          <div style={{ display: this.showPeopleResults() }}>
            <h4>People</h4>
            <PerfectScrollbar style={searchResultsPeopleDiv}>
              {this.state.peopleResults.map((person, index) => (
                <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                  <PersonCard person={person} onShowOrg={this.getSpecificUser} />
                </div>
              ))}
            </PerfectScrollbar>
          </div>

          <div style={{ display: this.showTeamResults() }}>
            <h4>Teams</h4>
            <PerfectScrollbar style={searchResultsTeamDiv}>
              {this.state.teamResults.map((team, index) => (
                <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                  <TeamCard
                    team={team}
                    onShowOrg={(t) => this.getSpecificTeam(t)}
                    updateStatesOnTeamPageRedirection={this.props.onTeamPageView}
                    redirectToTeamPage
                  />
                </div>
              ))}
            </PerfectScrollbar>
          </div>

          <div style={{ display: this.showProjectResults() }}>
            <h4>Projects</h4>
            <PerfectScrollbar style={searchResultsProjectDiv}>
              {this.state.projectResults.map((project, index) => (
                <div style={{ display: 'inline-block', verticalAlign: 'top' }} key={index}>
                  <ProjectCard project={project} />
                </div>
              ))}
            </PerfectScrollbar>
          </div>

        </PerfectScrollbar>

        {/* team view */}
        <PerfectScrollbar style={teamChartViewDiv}>
          <OrgChart
            node={this.state.teamChartData}
            template={TeamCard}
            showOKRs={this.state.showOKRs}
            onParentChanged={() => this.onTeamChartChanged()}
            onDeleted={(team) => this.onTeamDeleted(team)}
          />
          <div style={{
            position: 'fixed', right: '20px', bottom: '20px', display: this.showOKRButton(),
          }}
          >
            <Button color="secondary" size="sm" onClick={() => this.showOKRs()}>View OKRs</Button>
          </div>

        </PerfectScrollbar>

        {/* product view */}
        <div style={productViewDiv}>
          <Row>
            {this.state.products.map((product, productIndex) => (
              <Col sm="12" md="6" lg="6" xl="4" style={{ paddingBottom: '20px' }} key={productIndex}>
                <div style={{ padding: '6px' }}>
                  <h5 style={{ marginLeft: '10px' }}>{product.name}</h5>
                  <Row style={{ padding: '12px' }}>
                    {product.people.map((person, index) => (
                      <Col md={4} key={index}>
                        <PersonCard person={person} onShowOrg={this.getSpecificUser} hideExpander showRole />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/* normal org chart */}
        <PerfectScrollbar style={orgViewDiv}>
          <OrgChart
            node={this.state.orgChartData}
            selected={this.state.selectedPerson}
            template={PersonCard}
            onExpanded={this.onExpanded}
          />
        </PerfectScrollbar>

        {/* admin tools */}
        <div style={{ display: 'none', marginTop: '100px' }}>

          <Button onClick={this.updateIndex}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <i style={icon} className="nc-icon nc-vector" />
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '9px' }}>
              Update People Index
            </div>
          </Button>
          <Button onClick={this.updateTeamIndex}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <i style={icon} className="nc-icon nc-vector" />
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '9px' }}>
              Update Team Index
            </div>
          </Button>

          <Button onClick={this.importCSV}>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <i style={icon} className="nc-icon nc-vector" />
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '9px' }}>
              Import Org Chart Data
            </div>
          </Button>

          <style>
            {
                        `.csv-reader-input {
                            color: #696969;
                        }
                        `
                    }
          </style>

          <div style={{ display: this.showFilePicker() }}>
            <CSVReader
              cssClass="csv-reader-input"
              label=""
              onFileLoaded={this.onFileLoaded}
              onError={this.onFileError}
              parserOptions={papaparseOptions}
              inputId="ObiWan"
              inputStyle={{ color: 'green' }}
            />
          </div>

        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => state

GetStarted = connect(mapStateToProps)(GetStarted)
export default GetStarted
