import './Chip.css'

import React from 'react'

export default function Chip({ text, color }) {
  const pointColor = {
    background: `${color}`,
  }

  const backgroundColor = {
    background: `${color.slice(0, -1)},0.2)`,
  }

  return (
    <div className="tag-container" style={backgroundColor}>
      <div className="tag-point" style={pointColor} />
      <p className="tag-text">{text}</p>
    </div>
  )
}
