import React from "react";

import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';  

//utils
import { slack, telemetry } from 'assets/utils';

//components
import { 
    Button, 
    Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import Tag from "components/Tag/Tag.js"; 
import TeamSearch from "components/TeamSearch/TeamSearch.js"; 

const uuid = require('uuid');

class TipEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            activeTab: 'overview',
            tip: {
                title: '',
                day: '',
                description: '',
                teamID: '',
                status: 'draft',
                tags: [],
                links: []
            },
            team: {},
            openTeamSearch: false,
        }
    }

    componentDidMount() {
        if(this.props.openByDefault) {
            this.toggleModal();
        }
    }

    toggleModal() {
        
        if(!this.state.modal && !this.props.onCreated) {
            this.getTip();
            this.getTeam();
            telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "tipEditor", "pageView");
        }

        this.setState({modal: !this.state.modal});
    }

    getTip() {

        db.getTip(this.props.tip.id).then(tip => {
           
            this.setState({tip:tip});
        });
    }

    getTeam() {
        if(this.props.tip.teamID) {   
            db.getTeam(this.props.tip.teamID).then((team => {
                this.setState({team: team});
            }));
        }
    }

    updateTip(property, value) {
        var obj = this.state.tip;
        obj[property] = value;
        this.setState({tip: obj});
    }

    deleteTip() {
        db.deleteTip(this.state.tip.id).then(() => {
            this.props.onDeleted(this.state.tip);
            this.setState({modal: false});
        });
    }

    addLink() {
        var obj = this.state.tip;
        obj.links.splice(0,0,{id: uuid.v4(), name:"", url:"", created: new Date().getTime() });
        this.setState({tip: obj});
    }

    updateLink(link, property, value) {
        var obj = this.state.tip;
        for(var i = 0; i < obj.links.length; i++) {
            if(obj.links[i].id === link.id) {
                obj.links[i][property] = value;
                break;
            }
        }
        this.setState({tip: obj});
    }

    saveTip() {

        var tip = this.state.tip;

        if(!tip.created) { 
            tip.created = new Date().getTime(); 
            tip.createdBy = this.props.user.person.name;
            tip.createdByID = this.props.user.person.id;
        }

        if(!tip.createdByID) 
            tip.createdByID = this.props.user.person.id;
        if(!tip.createdBy) 
            tip.createdBy = this.props.user.person.name;

        tip.modified = new Date().getTime(); 
        tip.modifiedBy = this.props.user.person.name;

        tip.links.sort( (a,b) => { return a.sortIndex - b.sortIndex } )

        db.updateTip(tip.id, tip).then((result) => {

            if(this.props.onCreated) { // 
                tip.id = result.id;
                this.props.onCreated(tip);
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "tip", "createTip");
            } else {
                telemetry.logUserActivity(this.props.user.id, this.props.user.personID, "tip", "updateTip");
            }

            if(this.props.onCreated) { // reset for new tip

                this.setState({ 
                    tip: {
                        title: '',
                        day: '',
                        description: '',
                        teamID: '',
                        tags: [],
                        links: [],
                    },
                    
                })
            } else if (this.props.onUpdated) {
                this.props.onUpdated(tip);
            }

            // close the dialog
            this.setState({modal:false});
        });

    }

    sendPreview() {
        
        var tip = this.state.tip;

        var blocks = [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "_*" + tip.title + "*_ \n\n"
                } // `
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": tip.description + "\n\n"
                }
            }
        ]

        tip.links.sort( (a,b) => { return a.sortIndex - b.sortIndex }  )
        tip.links.forEach(link => {
            blocks.push( {
                "type": "section",
                text: {
                    "type": "mrkdwn",
                    "text": "<" + link.url + "|" + link.name + ">"
                }
            })
        })

        slack.sendMessage(this.props.user.person.slackID, this.state.tip.title, blocks)
    }

    assignTeam(team) {
        this.updateTip("teamID", team.id);
    }
    deleteTeam() {
        var tip = this.state.tip;
        delete tip.teamID;
        this.setState({tip:tip, team:{}});
    }

    openTeamSearch() {
        this.setState({openTeamSearch: true}, () => {
            setTimeout(() => {
                this.setState({openTeamSearch: false});
            }, 500);
        });
    }

    buttonText() {
        if(this.props.onCreated)
            return "New Tip";
        else
            return "Edit";
    }

    showNewLinkButton() {
        if(this.state.activeTab==='links')
          return "inline-block";
        else
          return "none";
    }
    showPreviewButton() {
        if(this.state.activeTab==='overview')
          return "inline-block";
        else
          return "none";
    }
    showDeleteButton() {
        if(this.props.user.isAdmin || this.state.tip.createdByID === this.props.user.person.id)
          return "inline-block";
        else
          return "none";
    }


    render() {

        const activeLink = {
            color:"green",
            fontWeight:600,
            fontSize:"12px"
        }
        const link = {
            color:"#434343",
            fontWeight:400,
            fontSize:"12px",
            cursor:"pointer"
        }
        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const deleteIcon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#888888"
        }
        const iconTextArea = {
            fontSize:"17px",
            position:"absolute",
            top:"20px",
            color:"#000000"
        }

        return (
            <div style={{whiteSpace:"normal"}}>
              <div>
                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                        <Button size="sm" onClick={() => this.toggleModal()} >
                            {this.buttonText()}
                        </Button>
                    </div>
                </div>
               
                <Modal isOpen={this.state.modal} size="lg" >
                    <ModalHeader>
                        <Row>
                            <Col sm="12" style={{textAlign:"left"}}>
                                Onboarding Tip
                            </Col>
                        </Row>   
                        <Row>
                            <Col xs="6">
                            <Nav style={{marginTop:"10px", marginBottom:"-17px"}}>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'overview' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'overview'}) }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={(this.state.activeTab === 'links' ? activeLink : link )}
                                        onClick={() => { this.setState({activeTab: 'links'}) }}
                                    >
                                        References
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            </Col>
                            <Col xs="6" style={{textAlign:"right", fontSize:"11px", color:"#888888", marginTop:"17px", paddingRight:"40px"}}>
                                Created by: <span style={{color:"green"}}>{this.state.tip.createdBy}</span>
                            </Col>
                        </Row> 
                    </ModalHeader>
                    <ModalBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                    <Col xs="12">
                                        Title <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-badge" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.tip.title} onChange={(e) => this.updateTip("title", e.target.value)} />
                                        </InputGroup>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        Body <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={iconTextArea} className="nc-icon nc-single-copy-04" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                                type="textarea" style={{minHeight:"120px", lineHeight:"19px"}}
                                                value={this.state.tip.description} onChange={(e) => this.updateTip("description", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col xs="12" sm="6" >
                                        Status <br />  
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px", verticalAlign:"top"}}>
                                                    <i style={icon} className="nc-icon nc-sound-wave" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="" 
                                            type="select" 
                                            value={this.state.tip.status} 
                                            onChange={(e) => this.updateTip("status", e.target.value)} >
                                                <option value="draft">Draft</option>
                                                <option value="published">Published</option>
                                                <option value="paused">Paused</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="12" sm="6">
                                        Day (1-90) <br />
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{width:"50px"}}>
                                                    <i style={icon} className="nc-icon nc-calendar-60" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input value={this.state.tip.day} onChange={(e) => this.updateTip("day", e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col xs="12" >
                                        Team <br />
                                        <div onClick={() => this.openTeamSearch()}
                                            style={{border:"solid 1px #cdcdcd", borderRadius:"4px", paddingTop:"6px", paddingLeft:"10px", width:"100%", height:"41px", cursor:"pointer"}}>
                                            <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                
                                            </div>
                                            <div style={{display:"inline-block", marginLeft:"15px", verticalAlign:"middle", color:"#666666", marginTop:"4px"}}>
                                                {this.state.team.name}
                                            </div>
                                            <div style={{display:"none"}}>
                                                <TeamSearch label="Assign Team" isOpen={this.state.openTeamSearch} onTeamChosen={(t) => this.assignTeam(t)} />
                                            </div>
                                        </div>
                                        <div style={{position:"absolute", right:"25px", top:"32px", cursor:"pointer"}} onClick={() => this.deleteTeam()}>
                                            <i style={deleteIcon} className="nc-icon nc-simple-remove" />
                                        </div>
                                    </Col>
                                    
                                </Row> */}
                                <Row style={{marginTop:"10px"}}>
                                    <Col xs="12">
                                        Tags <br />
                                        <Tag type="tipType" icon="nc-bookmark-2" 
                                            selected={this.state.tip.tags} 
                                            onChange={(tags) => this.updateTip('tags',tags)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <br />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="links" style={{minHeight:"210px", paddingTop:"17px"}}>
                                <Row>
                                        <Col sm="5">
                                            <b>Name</b>
                                        </Col>
                                        <Col sm="6">
                                            <b>URL</b>
                                        </Col>
                                        <Col sm="1">
                                            <b>Sort</b>
                                        </Col>
                                </Row>
                                {this.state.tip.links.map((link, index) => (    
                                    <Row key={index} style={{marginBottom:"10px"}}>
                                        <Col sm="5"  >
                                            <input type="text" value={link.name} placeholder="name" 
                                                onChange={(e) => this.updateLink(link, "name", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="6"  >
                                            <input type="text" value={link.url} placeholder="url" 
                                                onChange={(e) => this.updateLink(link, "url", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                        <Col sm="1"  >
                                            <input type="text" value={link.sortIndex} placeholder="index" 
                                                onChange={(e) => this.updateLink(link, "sortIndex", e.target.value)} 
                                                style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"28px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </Col>
                                    </Row>
                                ))}
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{width:"100%"}}>
                            <Col xs="6" >
                                <div style={{display:this.showDeleteButton(), marginRight:"6px"}}>
                                    <ConfirmDialog 
                                        buttonText="Delete"
                                        title="Delete Tip" 
                                        header="CONFIRM" 
                                        description="Are you sure you want to delete this tip?" 
                                        confirmed={() => this.deleteTip()} /> 
                                </div>
                                <div style={{display:this.showNewLinkButton()}} >
                                    <Button onClick={() => this.addLink()}>Add Link</Button>
                                </div>
                                <div style={{display:this.showPreviewButton()}}>
                                    <Button onClick={() => this.sendPreview()}>Send Preview</Button>
                                </div>
                            </Col>
                            <Col xs="6" style={{textAlign:"right"}}>
                                <Button color="secondary" onClick={() => this.toggleModal()} size="md">
                                    Cancel
                                </Button>
                                <Button color="success" onClick={() => this.saveTip()} size="md" >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
            )
        }
    }
    
    
    const mapStateToProps = state => {
        return state;
    }
    
    TipEditor = connect(mapStateToProps)(TipEditor);
    export default TipEditor;
    