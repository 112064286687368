import React from "react";

import { 
    Button, 
    Row, Col, 
    Modal, ModalBody, ModalFooter,
    Card, CardBody,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

//firebase
import { db } from 'assets/firebase';  

import PageEditor from 'components/PageEditor/PageEditor';


const uuid = require('uuid');

class NavEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            isLoaded: false,
            navItem: {
                id: uuid.v4(),
                title: '',
                subTitle: '',
                icon: ''
            },
            pages: [],
            navItems: [],
            children: []
        };

        if(props.navItem)
            this.state.navItem = props.navItem;

        this.insertPage = this.insertPage.bind(this);
        this.updatePage = this.updatePage.bind(this);

        this.onMoveDown = this.onMoveDown.bind(this);
        this.onMoveUp = this.onMoveUp.bind(this);

        this.insertChildNavItem = this.insertChildNavItem.bind(this);
        this.updateChildNavItem = this.updateChildNavItem.bind(this);
        this.saveNavItem = this.saveNavItem.bind(this);  

        this.toggleModal = this.toggleModal.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.navItem !== this.props.navItem) {
            this.setState({navItem: this.props.navItem});
        }
    }

    toggleModal() {

        if(!this.state.isLoaded) {

            var children = this.state.children;

            this.getNavItems().then((navItems) => {

                this.getPages().then((pages) => {

                    navItems.forEach(navItem => {
                        children.push({ title: navItem.title, navItem: navItem, sortIndex: navItem.sortIndex } );
                    })

                    pages.forEach(page => {
                        children.push({ title: page.title, page: page, sortIndex: page.sortIndex } );
                    })

                    children.sort(this.bySortIndex);

                    this.setState({
                        children: children,
                        isLoaded: true,
                        navItems: navItems,
                        pages: pages
                    })
                });
            });
        }
            
        this.setState({modal: !this.state.modal});
    }

    getNavItems() {

        return new Promise((resolve, reject) => {

            db.getNavItems(this.state.navItem.id).then((results) => {
        
                for(var i = 0; i < results.length; i++) {
                    if(!results[i].pages)
                    results[i].pages = [];
                    if(!results[i].sortIndex)
                    results[i].sortIndex = i;
                }
            
                resolve(results);
            });
        });
    }

    getPages() {
        
        return new Promise((resolve, reject) => {

            db.getPages(this.state.navItem.id).then(pages => {
                
                for(var i = 0; i < pages.length; i++) {
                    if(!pages[i].sortIndex)
                    pages[i].sortIndex = i;
                }

                resolve(pages);
            });
        });
    }

    updateNavItem(property, value) {
        var obj = this.state.navItem;
        obj[property] = value;
        this.setState({navItem: obj});
    }

    // save
    saveNavItem() {

        var navItem = this.state.navItem;
        if(this.props.parentID)
            navItem.parentID = this.props.parentID;
        
        db.updateNavigation(navItem.id, navItem).then(() => {

            if(this.props.onInsert) {

                this.props.onInsert(navItem);
                this.setState(
                    {navItem: {
                        id: uuid.v4(),
                        title: '',
                        subTitle: '',
                        icon: ''
                    }
                });

            } else if (this.props.onUpdate) {

                this.props.onUpdate(navItem);
            } 

            this.setState({modal:false});
        });
    }

    showMoveUp() {
        if(this.state.navItem.sortIndex === 0)
            return "none";
        else 
            return "block";
    }

    showMoveDown() {
        if(this.props.isLastItem)
            return "none";
        else 
            return "block";
    }

    showCard() {
        if(this.props.onInsert)
            return "none";
        else 
            return "block";
    }

    showButton() {
        if(this.props.onInsert)
            return "block";
        else 
            return "none";
    }

    getIcon() {
        return "nc-icon " + this.state.navItem.icon;
    }

    insertChildNavItem(navItem) {

        console.log('insertChildNavItem()');
        console.log(navItem);

        var navItems = this.state.navItems;
        navItems.push(navItem);

        var children = this.state.children;
        children.push({ title: navItem.title, navItem: navItem, sortIndex: navItem.sortIndex } );

        children.sort(this.bySortIndex);

        this.setState({
            children: children,
            navItems: navItems
        });
        
    }

    updateChildNavItem(navItem) {

        var navItems = this.state.navItems;
        for(var i = 0; i < navItems.length; i++) {
            if(navItems[i].id === navItem.id) {
                navItems[i] = navItem;
                break;
            }
          }

        var children = this.state.children;
        for(var j = 0; j < children.length; j++) {
            if(children[j].navItem) {   
                if(children[j].navItem.id === navItem.id) {
                    children[j].navItem = navItem;
                    break;
                }
            }
          }

        children.sort(this.bySortIndex);

        this.setState({
            children: children,
            navItems: navItems
        });
    }

    insertPage(page) {
        var pages = this.state.pages;
        page.sortIndex = pages.length;
        pages.push(page);

        var children = this.state.children;
        children.push({ title: page.title, page: page, sortIndex: page.sortIndex } );

        children.sort(this.bySortIndex);

        this.setState({pages: pages, children: children});
    }
    
    updatePage(page) {
        var pages = this.state.pages;
        for(var i = 0; i < pages.length; i++) {
          if(pages[i].id === page.id) {
            pages[i] = page;
            break;
          }
        }
        this.setState(pages);
    }

    

    bySortIndex(a,b) {

        var x = a.sortIndex;
        var y = b.sortIndex;
      
        if (x < y)
          return -1;
        if (x > y)
          return 1;
        return 0;
    }
    
    isLastItem(page) {
        if(page.sortIndex === this.state.pages.length - 1) 
          return true;
        else 
          return false;
    }

    onMoveUp(page) {

        var targetIndex = page.sortIndex - 1;
        var pages = this.state.pages;
    
        console.log(pages);
    
        for(var i = 0; i < pages.length; i++) {
          if(pages[i].id === page.id)
            pages[i].sortIndex = pages[i].sortIndex - 1;
          
          else if(pages[i].sortIndex === targetIndex) 
            pages[i].sortIndex = pages[i].sortIndex + 1;
        }
    
        pages.sort(this.bySortIndex);
        this.setState({pages: pages}, this.savePages);
    
        console.log(pages);
    }
    
    onMoveDown(page) {
        var targetIndex = page.sortIndex + 1;
        var pages = this.state.pages;
    
        for(var i = 0; i < pages.length; i++) {
          if(pages[i].id === page.id)
            pages[i].sortIndex = pages[i].sortIndex + 1;
          
          else if(pages[i].sortIndex === targetIndex) 
            pages[i].sortIndex = pages[i].sortIndex - 1;
        }
    
        pages.sort(this.bySortIndex);
        this.setState({pages: pages}, this.savePages);
    }

    savePages() {
        for(var i = 0; i < this.state.pages.length; i++) 
          db.updatePage(this.state.pages[i].id, this.state.pages[i]);
        
    }

    getChild(child, index) {
        if(child.navItem) {
            return (
                <NavEditor key={index} 
                    navItem={child.navItem} 
                    onUpdate={this.updateChildNavItem} 
                    onMoveUp={this.onMoveUp}
                    onMoveDown={this.onMoveDown}
                    isLastItem={this.isLastItem(child.navItem)}
                    />
            )
        } else if (child.page) {
            return (
                <PageEditor key={index} 
                    page={child.page}  
                    navItem={this.state.navItem}
                    onUpdate={this.updatePage} 
                    onMoveUp={this.onMoveUp}
                    onMoveDown={this.onMoveDown}
                    isLastItem={this.isLastItem(child.page)}
                />
            )
        }
    }

    render() {
     

        const icon = {
            fontSize:"17px",
            marginTop:"0px",
            color:"#000000"
        }
        const navIcon = {
            fontSize:"17px",
            marginTop:"3px",
            cursor:"pointer"
        }
       
        return (
            
            <div>
                <Button style={{display:this.showButton()}} onClick={this.toggleModal}>
                    Add Section
                </Button>
                <Card style={{display:this.showCard()}}>
                    <CardBody style={{background: 'linear-gradient(to right, #69aa69 0%, #89aa89 100%)', color:"white", borderRadius:"6px"}}>
                        <Row>
                            <Col sm="2">
                                <div onClick={this.toggleModal}>
                                    <i style={navIcon} className="nc-icon nc-settings-gear-65" />  
                                </div>
                            </Col>
                            <Col sm="1">
                                {<i style={navIcon} className={this.getIcon()} />  }
                            </Col>
                            <Col sm="7">{this.state.navItem.title}</Col>
                            <Col sm="1">
                                <div style={{display:this.showMoveUp()}}>
                                    <i style={navIcon} className="nc-icon nc-minimal-up" onClick={() => this.props.onMoveUp(this.state.navItem)} />  
                                </div>
                            </Col>
                            <Col sm="1">
                                <div style={{display:this.showMoveDown()}}>
                                    <i style={navIcon} className="nc-icon nc-minimal-down" onClick={() => this.props.onMoveDown(this.state.navItem)} />  
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg" >
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                
                                <br /><br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8">
                                Title <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-email-85" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.navItem.title} onChange={(e) => this.updateNavItem("title", e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col md="4">
                                Icon <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-email-85" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.navItem.icon} onChange={(e) => this.updateNavItem("icon", e.target.value)} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                Subtitle <br />
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{width:"50px"}}>
                                            <i style={icon} className="nc-icon nc-email-85" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="" value={this.state.navItem.subTitle} onChange={(e) => this.updateNavItem("subTitle", e.target.value)} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                Pages
                                <div style={{marginTop:"20px"}}>
                                    {this.state.children.map((child, index) => (
                                        this.getChild(child, index)
                                    ))}

                                    

                                    {/*
                                    {this.state.pages.map((page, index) => (
                                        <PageEditor key={index} 
                                            page={page}  
                                            navItem={this.state.navItem}
                                            onUpdate={this.updatePage} 
                                            onMoveUp={this.onMoveUp}
                                            onMoveDown={this.onMoveDown}
                                            isLastItem={this.isLastItem(page)}
                                        />
                                    ))}  
                                    */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" >
                                <div style={{display:"inline-block", marginRight:"10px"}}>
                                    <NavEditor onInsert={this.insertChildNavItem} parentID={this.state.navItem.id}  />
                                </div>
                                <div style={{display:"inline-block"}}>
                                    <PageEditor navItem={this.state.navItem} onInsert={this.insertPage} />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{padding:"20px"}}>
                            <Button color="success" onClick={this.saveNavItem}>
                                <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                    <i style={navIcon} className="nc-icon nc-check-2" />  
                                </div>
                                <div style={{display:"inline-block", verticalAlign:"middle", marginLeft:"4px"}}>
                                    Save
                                </div>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default NavEditor;