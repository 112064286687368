import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react'
import {
  FaExclamationCircle, FaExclamationTriangle, FaInfoCircle, FaTimes,
} from 'react-icons/fa'
import { Toast, ToastBody } from 'reactstrap'

export const ToastContext = createContext()

export const useToast = () => useContext(ToastContext)

function ToastProvider({ children }) {
  const [toastList, setToastList] = useState([])
  const [showToasts, setShowToasts] = useState(true)

  const showToast = (message, type = 'info') => {
    setToastList((prevList) => [...prevList, { message, type }])
  }

  const closeToast = (index) => {
    setToastList((prevList) => prevList.filter((_, i) => i !== index))
  }

  const clearToasts = () => {
    setShowToasts(false)
    setToastList([])
  }

  useEffect(() => {
    if (toastList.length > 0) {
      const timer = setTimeout(() => {
        clearToasts()
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [toastList])

  useEffect(() => {
    if (toastList.length === 0) {
      setShowToasts(true)
    }
  }, [toastList])

  const getToastIcon = (type) => {
    switch (type) {
      case 'success':
      case 'info':
        return <FaInfoCircle style={{ color: '#ffffff' }} />
      case 'warning':
        return <FaExclamationTriangle style={{ color: '#ffffff' }} />
      case 'error':
        return <FaExclamationCircle style={{ color: '#ffffff' }} />
      default:
        return <FaInfoCircle style={{ color: '#ffffff' }} />
    }
  }

  const getToastColor = (type) => {
    switch (type) {
      case 'success':
        return '#4caf50'
      case 'info':
        return '#2196f3'
      case 'warning':
        return '#ff9800'
      case 'error':
        return '#f44336'
      default:
        return '#2196f3'
    }
  }

  const toastValue = useMemo(() => ({
    showToast,
    closeToast,
    clearToasts,
  }), [])

  return (
    <ToastContext.Provider value={toastValue}>
      {children}
      {showToasts && toastList.map((toast, index) => (
        <Toast
          key={index}
          isOpen
          fade
          style={{
            zIndex: 9999,
            minWidth: '200px',
            maxWidth: '500px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'fixed',
            right: '3px',
            top: `${index * 60 + 3}px`, // Adjust top position to stack the toasts vertically
            padding: '0.5rem',
            backgroundColor: getToastColor(toast.type),
            color: '#ffffff',
          }}
        >
          <ToastBody style={{ display: 'flex', alignItems: 'center', padding: '0.5rem' }}>
            {getToastIcon(toast.type)}
            <span style={{ marginLeft: '0.5rem' }}>{toast.message}</span>
            <FaTimes
              onClick={() => closeToast(index)}
              style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '16px' }}
            />
          </ToastBody>
        </Toast>
      ))}
    </ToastContext.Provider>
  )
}

export default ToastProvider
