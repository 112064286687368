import React from 'react'

import {
  dateTime,
} from '../../assets/utils'
import PersonCard from '../PersonCard/PersonCard'

function ProjectAuthorCard({ author, createdBy, publishedAt }) {
  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        {author && <PersonCard person={author} showThumb />}
      </div>
      <div style={{
        display: 'inline-block',
        fontSize: '11px',
        fontWeight: 400,
        marginLeft: '10px',
        marginTop: '34px',
        marginBottom: '0px',
        color: '#434343',
      }}
      >
        {createdBy}
        <br />
        {dateTime.dateConverter(publishedAt)}
      </div>
    </div>
  )
}

export default ProjectAuthorCard
